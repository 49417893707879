/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { Txn } from 'src/app/model/txn';

@Injectable({
  providedIn: 'root'
})
export class BlTxnService extends AbstractHttpService {

  // protected baseUrl = this.ajaxPath + 'txns?typeId=' + Txn.TYPE.SALE.id;
  protected baseUrl = this.ajaxPath + Txn.LEDGER.BL.code;
  protected cache: Txn[];
  protected typeString = 'Txn';

  constructor(protected http: HttpClient, protected messageService: MessageService,) {
    super();
  }

}
