/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*
*/
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { DashboardChart } from 'src/app/shared/chart/dashboard-chart/dashboard-chart';

import * as Highcharts from 'highcharts';
import { HttpParams } from '@angular/common/http';
import { ServiceRequestPageComponent } from '../../crm/service-request-page/service-request-page.component';


enum TrafficColor { GREEN = 'green', AMBER = 'amber', RED = 'red' }

export class UnreadChart extends DashboardChart {
    dataUrl = AbstractHttpService.ajaxPath + 'agent/dashboard/unreadReqs';
    dataParams = new HttpParams().set('week', 0).set('owner', 'ownedBy');

    color: TrafficColor = TrafficColor.GREEN;
    counter = 0

    title = "My Unread Requests";

    //series: { name: string, y: number, pct: number, route: NavRoute, routeParams: Params }[] = [];


    getSubTitle(title = $localize`Requests assigned to me I have not read yet`) {
        return super.getSubTitle(title);
    }


    protected setupData(data: number) {
        this.counter = data['unread'];
        if (this.counter > 0) {
            this.color = TrafficColor.RED;
        }
    }

    clickEvent() {
        const url = ServiceRequestPageComponent.navRoute.url;
        const params = { ownedById: this.currentUser.id, ownerReadAt: '' };

        this.router.navigate([url, params]);
    }

    getDataLabels() {
        if (this.counter > 0) {
            return {
                enabled: true,
                format: '{point.y}',
                style: { fontSize: 36 },
                color: 'white',
                //distance: '-100%',
                distance: (0 - (this.width / 2)),
            }
        } else {
            return {
                enabled: true,
                format: '0',
                style: { fontSize: 36, textAlign: 'center' },
                //distance: 0 - (this.height / 2.5),
                distance: (0 - (this.width / 2)),
                color: 'white',
            }
        }
    }

    protected createChartOptions(): Highcharts.Options {

        return {
            colors: [this.color],
            chart: {
                backgroundColor: this.chartBackgroundColor,
                borderRadius: this.chartBorderRadius,
                plotBorderWidth: null,
                type: 'pie',
                height: this.height,
                width: this.width
            },
            title: this.getChartTitle(),
            subtitle: this.getSubTitle(),
            credits: { enabled: false },
            legend: { enabled: false },
            plotOptions: {
                pie: {
                    allowPointSelect: false,
                    cursor: 'pointer',
                    events: {
                        click: this.clickEvent.bind(this)
                    },
                    dataLabels: this.getDataLabels(),
                }
            },
            series: [{
                name: 'Request Analysis',
                colorByPoint: true,
                data: [this.counter ? this.counter : 1]
            }]
        } as unknown as Highcharts.Options;
    }
}
