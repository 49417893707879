/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Area } from 'src/app/model/area';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';

@Injectable({
    providedIn: 'root'
})
export class MailMergeService extends AbstractHttpService {

    protected baseUrl = this.ajaxPath + 'mailmerge';
    protected cache: Area[];
    protected typeString = 'MailMerge';

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super();
    }
}
