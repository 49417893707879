/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { FormConfig } from "src/app/shared/form/FormConfig";
import { FieldSet } from "src/app/shared/form/field-set/field-set.component";
import { FormButtonComponent } from "src/app/shared/form/form-button/form-button.component";
import { FormTextComponent } from "src/app/shared/form/form-text/form-text.component";
import { FormNumberComponent } from "src/app/shared/form/form-number/form-number.component";
import { Panel } from "./panel";
import { PersonPageComponent } from "src/app/pages/person-page/person-page.component";
import { MyInjector } from "src/app/app.module";
import { MatDialog } from "@angular/material/dialog";
import { BankInPageComponent } from "../bank-in-page/bank-in-page.component";
import { ConfirmDialogService } from "src/app/shared/dialogs/confirmDialog";
import { Person } from 'src/app/model/person';

export const peoplePanel: Panel = new Panel({
    type: 'people',
    icon: 'people',
    takeAction(person: Person) {
        const dialog = MyInjector.instance.get(MatDialog);
        dialog.open(BankInPageComponent, { data: { person, item: this.item } }).afterClosed().subscribe(result => {
            console.warn(result);
            if (result) {
                this.recItemsComponent.removeItem(this.item);
                this.reconciliationComponent.showPanel = null;
                MyInjector.instance.get(ConfirmDialogService).open('Remember Action',
                    "Next time there is a transactions with reference '01VT' should it be automatically applied to same account", () => {
                        console.error('Cannot Remember');
                    });
            }
        })
    },
    setTitle() {
        this._title = this._items?.length + ' People this receipt may be from'
    },
    setConfig() { return new FormConfig({
        fieldSet: new FieldSet({
            fields: [
                FormButtonComponent.makeDialogOpenButton('Person', 'fullName', 'personId', PersonPageComponent),
                FormButtonComponent.makeTableButton('Match', 'match', this.takeAction.bind(this)).override({
                    calculateValue: () => 'Pay'
                }),
                FormTextComponent.make('Unit Name(s)', 'unitName'),
                //FormTextComponent.make('Role', 'roleName'),
                FormNumberComponent.make('Invoices', 'invoices'),
                FormNumberComponent.makeCurrency('Outstanding', 'outstanding'),
                FormNumberComponent.makeCurrency('Difference', 'diffAmount'),
            ],
        }),
        mode: 'list'
    })}
})
