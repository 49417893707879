<mat-dialog-content [style.width.px]="width" [style.height.px]="height">
  <mat-stepper [linear]="true" #stepper (selectionChange)="onStepChange()">
    @for (step of steps; track step; let idx = $index) {
      <mat-step [stepControl]="step.fieldSet.getFormGroup()">
        <div [style.height.px]="height - 340" [style.max-height.px]="height - 340" formHolder style="padding-top: 20px">
          <p class="mat-body">{{step.help}}</p>
          <form [formGroup]="step.fieldSet.getFormGroup()">
            <ng-template matStepLabel>{{step.title}}</ng-template>
            <app-field-set [fieldSet]="step.fieldSet" [isPhone]="false" [initComplete]="true"></app-field-set>
          </form>
        </div>
        <div style="float: right">
          @if (idx > 0) {
            <button mat-raised-button color="accent" matStepperPrevious>Back</button>
          }
          @if (step.button) {
            <button mat-raised-button color="primary" [disabled]="step.button.disable" (click)="step.button.action()">{{step.button.label}}</button>
          }
          @if (idx < steps.length -1) {
            <button mat-raised-button color="primary" matStepperNext>Next</button>
          }
        </div>
      </mat-step>
    }
    <!-- mat-step [stepControl]="skipGridField.control" label="Requests Not Created">
    <div [style.height.px]="height - 340" [style.max-height.px]="height - 340" formHolder>
      <app-ctl-holder [control]="skipGridField.control"></app-ctl-holder>
    </div>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </div>
  </mat-step>
  <mat-step [stepControl]="gridField.control" label="Requests To Create">
    <div [style.height.px]="height - 340" [style.max-height.px]="height - 340" formHolder>
      <app-ctl-holder [control]="gridField.control"></app-ctl-holder>
    </div>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Done</ng-template>
    <p>You are now done.</p>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button (click)="stepper.reset()">Reset</button>
    </div>
    </mat-step -->
  </mat-stepper>
</mat-dialog-content>
