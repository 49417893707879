<div>
    @if (field.btnOpts.navigates) {
    <a [routerLink]="field.getNavRoute(focusItem)">
        {{field.getFormattedValue(focusItem)}}
    </a>
    }
    @if (field.btnOpts.navItem) {
    <a [routerLink]="['/' + field.btnOpts.navRouteCalculator(focusItem), field.btnOpts.navParmCalculator(focusItem)]">
        {{field.getFormattedValue(focusItem)}}
    </a>
    }
    @if (field.btnOpts.tableClickMethod) {
    <a mat-button (click)="$event.stopPropagation(); field.btnOpts.tableClickMethod(focusItem);"
        [color]="field.btnOpts.color">
        {{field.getValue(focusItem)}}
    </a>
    }
</div>
