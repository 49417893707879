import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FromNowPipe } from '../../pipes/from-now.pipe';
import { MatTooltip } from '@angular/material/tooltip';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-date-time-item',
  standalone: true,
  imports: [MatIconModule, FromNowPipe, MatTooltip, DatePipe],
  templateUrl: './date-time-item.component.html',
  styleUrl: '../simple-fields.scss'
})
export class DateTimeItemComponent {
    @Input() label: string
    @Input() date: number;
    @Output() editor = new EventEmitter<null>();
}
