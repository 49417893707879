/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
* Makes a range of standard fields...
*/

import { AppFormControl } from "../form/app-form-control";
import { FormButtonComponent } from "../form/form-button/form-button.component";
import { FormNumberComponent } from "../form/form-number/form-number.component";
import { FormTextAreaComponent } from "../form/form-text-area/form-text-area.component";
import { FormTextComponent } from "../form/form-text/form-text.component";
import { GridRow } from "../grid/grid-row";
import { ButtonField, BtnOpts } from "./ButtonField";
import { Field } from "./Field";

export class FieldMaker {
    public static notes(options: Partial<Field> = {}) {
        const field: Field = new Field({
            cellOpts: { heading: 'Notes' }, value: 'notes', type: 'limitTo',
            maxLength: 150, autoComplete: 'off'
        }, options);
        field.formControlFactory = FormTextAreaComponent.createComponent;
        return field;
    }

    public static primaryItem(primary: string, primaryCompare: string) {
        const field = new ButtonField({
            name: 'primary', type: 'primary', cellOpts: { heading: 'Main', width: '1%' }, value: primaryCompare,
            toolTip: 'Select main owner to receive correspondence and show on lists etc.',
            sendServer: false,
        });

        field.btnOpts.clickMethod = (gridRow: GridRow) => {
            if (!gridRow.isPrimary()) {
                gridRow.setPrimary();
            }
        }

        return field;
    }
    public static deleteGridRow(btnOpts: BtnOpts = {}, options: Partial<Field> = {}) {

        const field = new ButtonField({
            name: 'delete', type: 'icon', sendServer: false, label: '',
            cellOpts: { heading: '', width: '1%' },
            calculateValue: (o) => { if (o && o.deleted) { return 'plumbing'; } else { return 'delete'; } },
            toolTip: 'Click to delete or restore this item',
        }, options as ButtonField);

        field.btnOpts.clickMethod = (ctl: AppFormControl) => {
            if (ctl.isGridCell) {
                ctl.getRow().delete();
            } else {
                console.error('Delete Grid Button appears to be attached sometwhere other than on a grid', ctl);
            }
        }
        field.btnOpts.color = 'accent';

        field.btnOpts = { ...field.btnOpts, ...btnOpts };


        return FormButtonComponent.make('delete', '', field);
    }

    public static id(options: Partial<Field> = {}) {
        const field: Field = FormNumberComponent.make("Id", "id", {}, { readonly: true, visible: Field.noShow });
        for (const key of Object.getOwnPropertyNames(options)) {
            field[key] = options[key];
        }
        return field;
        /*
            const field: Field = new Field( {type: 'number', cellOpts: {heading: 'Id'}, value: 'id', disable: true,
                numberOpts: { format: 'currency', width: 9, formatParms: '1.2-2' } ,
                visible: { phone: false, computer: false, form: false }}, options );
            field.formControlFactory = FormNumberComponent.createComponent;
            return field;
            */
    }
    public static spacer() {
        const field = FormTextComponent.make(' ', 'spacer', { cellOpts: { width: '50%', minWidth: '0%' }, readonly: true, visible: Field.noPhone })
        return field;
    }

    public static idHolder(value: string, options: Partial<Field> = {}) {
        const field: Field = FormNumberComponent.make(value, value, {}, { readonly: true, visible: Field.noShow });
        for (const key of Object.getOwnPropertyNames(options)) {
            field[key] = options[key];
        }
        return field;
    }
    public static rev(options: Partial<Field> = {}) {
        /*
      const field: Field = new Field({ type: 'number',
        cellOpts: {heading: 'Rev'}, value: 'revision',
        visible: { phone: false, computer: false, form: false }}, options
      );
      field.formControlFactory = FormNumberComponent.createComponent;
      return field;*/
        const field: Field = FormNumberComponent.make("Versions", "revision", {}, { readonly: true, visible: Field.noShow });
        for (const key of Object.getOwnPropertyNames(options)) {
            field[key] = options[key];
        }
        return field;
    }
    public static nameControl(options: Partial<Field> = {}) {
        const field: Field = new Field({ cellOpts: { heading: 'Name' }, value: 'name' }, options);
        field.formControlFactory = FormTextComponent.createComponent;
        return field;
    }
}
