/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { SupplierTemplate } from 'src/app/model/supplier';

@Injectable({
    providedIn: 'root'
})
export class SupplierTemplateService extends AbstractHttpService {

    protected baseUrl = this.ajaxPath + 'supplierTemplate';
    protected cache: SupplierTemplate[];
    protected typeString = 'Supplier Templates';

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super();
    }
}
