/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UnitType } from 'src/app/model/unit-type';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
@Injectable({
  providedIn: 'root'
})
export class UnitTypeService extends AbstractHttpService {

  protected typeString = 'UnitTypes';
  protected baseUrl = this.ajaxPath + 'units/types';
  protected cache: UnitType[] = [];

  constructor(protected http: HttpClient,
    protected messageService: MessageService) {
    super();
  }
}
