/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { Field } from 'src/app/shared/field/Field';
import { PreferredSupplier, } from 'src/app/model/supplier';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { FormEmailComponent } from 'src/app/shared/form/form-email/form-email.component';
import { FormPhoneComponent } from 'src/app/shared/form/form-phone/form-phone.component';
import { FormTextAreaComponent } from 'src/app/shared/form/form-text-area/form-text-area.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormUrlComponent } from 'src/app/shared/form/form-url/form-url.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { SupplierService } from '../supplier.service';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { NavRoute } from 'src/app/shared/NavRoute';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { maxChars, minChars } from 'src/app/shared/validators';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { ServiceRequestPageComponent } from '../../crm/service-request-page/service-request-page.component';
import { ActionGridComponent } from 'src/app/shared/action/action-grid.component';
import { MemoField } from 'src/app/shared/memos/MemoField';

@Component({
    selector: 'app-preferred-supplier-page',
    templateUrl: './supplier-page.component.html',
    styleUrls: ['./supplier-page.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class SupplierPageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('suppliers', SupplierPageComponent, 'shopping_cart');

    nameField = FormTextComponent.make('Name', 'name', {
        hint: 'The name of the company or sole trader who supplies you a service'
    });
    contactField = FormTextComponent.make('Contact Person', 'contactPerson', {
        hint: 'The name of the person you deal with in the company'
    });
    addressField = FormTextAreaComponent.make('Address', 'address', { formColumn: 1, visible: Field.formOnly });
    phoneField = FormPhoneComponent.make('Telephone', 'phone', { formColumn: 2, hint: 'Your normal contact phone number' });
    emailField = FormEmailComponent.make('Email', 'email', { formColumn: 2, hint: 'Your normal contact email address' });
    urlField = FormUrlComponent.make('Web URL', 'web', { formColumn: 3, });

    emergencyPhoneField = FormPhoneComponent.make('Emergency Number', 'emergencyPhone', {
        formColumn: 2, hint: 'Phone number to use in emergencies', visible: Field.formOnly
    });
    accountsEmailField = FormEmailComponent.make('Email Accounts', 'emailAccounts', {
        formColumn: 2, hint: 'Email address to use for accounts queries', visible: Field.formOnly
    });
    vatField = FormTextComponent.make('VAT', 'VAT', {
        formColumn: 3, validators: [maxChars(12), minChars(8)], visible: Field.formOnly,
        hint: 'If supplier is registered for VAT, you should enter their VAT number here'
    });
    trnField = FormTextComponent.make('Tax Registration Number', 'taxRegistrationNr',
        {
            formColumn: 3, validators: [maxChars(12), minChars(8)], visible: Field.formOnly,
            hint: 'If a supplier is not registered for VAT, you still need to report them on form 46G, so you need their tax registration number. For sole traders, this is often their PPS number'
        });
    dunsField = FormTextComponent.make('DUNS', 'DUNS', {
        formColumn: 3, validators: [maxChars(9)],
        hint: 'A DUNS Number is a unique Dun & Bradstreet identifier that can be used to obtain additional company information, e.g. credit checks etc.',
        visible: Field.formOnly
    });

    codeField = FormTextComponent.make('Code', 'code', {
        formColumn: 4,
        hint: 'A unique code used by te agent to identify a supplier.',
        visible: Field.formOnly
    });

    memoField = new MemoField(this.dataSvc);

    bicField = FormTextComponent.make('BIC', 'BIC', { formColumn: 4, validators: [maxChars(11), minChars(7)], visible: Field.formOnly });
    ibanField = FormTextComponent.make('IBAN', 'IBAN', { formColumn: 4, validators: [maxChars(34), minChars(16)], visible: Field.formOnly });
    bankField = FormTextComponent.make('Bank Account Name', 'bankAccountName', { formColumn: 4, validators: [maxChars(40)], visible: Field.formOnly });
    bankDetailField = FormTextAreaComponent.make('Bank Name & Address', 'bankNameAddress', { formColumn: 5, visible: Field.formOnly });

    requestsLink = FormButtonComponent.makeNavButton('Requests',
        ServiceRequestPageComponent.navRoute, o => ({ supplierId: o.id,  _sort: 'createdAt' }),
        {
            formColumn: 5, value: 'requests_count', cellOpts: {
                style: 'text-align: center; max-width: 3em',
            }
        }
    );
    openRequestsLink = FormButtonComponent.makeNavButton('Open Requests',
        ServiceRequestPageComponent.navRoute, o => ({ supplierId: o.id, srStatus: 'open', _sort: 'createdAt' }),
        {
            formColumn: 5, value: 'open_requests_count', cellOpts: {
                style: 'text-align: center; max-width: 3em',
            }
        }
    );

    actionGrid = ActionGridComponent.make('Supplier');

    configReady = new ReplaySubject<null>(1);

    config = new FormConfig({
        navRoute: SupplierPageComponent.navRoute,
        title: $localize`Supplier`,
        help: $localize`Agent supplier list`,
        fieldSet: new FieldSet({
            fields: [
                FieldMaker.id(),
                FieldMaker.rev(),
                this.nameField,
                this.contactField,
                this.addressField,
                this.phoneField,
                this.emailField,
                this.emergencyPhoneField,
                this.accountsEmailField,
                this.urlField,
                this.vatField,
                this.trnField,
                this.dunsField,
                this.codeField,
                this.bicField,
                this.ibanField,
                this.bankField,
                FieldMaker.notes({ formColumn: 5, visible: Field.formOnly }),
                //this.bankDetailField,
                this.requestsLink,
                this.openRequestsLink,
            ],
            formLayout: [{ cells: [{ width: '20%' }, { width: '20%' }, { width: '20%' }, { width: '20%' }, { width: '20%' }] },
            { cells: [{ colspan: 5, pageTab: 'yes' }] }],
        }),
        service: this.dataSvc,
        mode: 'list',
        objectFactory: this.objectFactory.bind(this),
        tabFields: [this.actionGrid, this.memoField],
    });

    constructor(public dataSvc: SupplierService) {
        super();
    }

    objectFactory(): Observable<PreferredSupplier> {
        return of(new PreferredSupplier());
    }
}
