<!-- p>Here's JSON: {{control.value | json}}</p -->
<!--div id="{{control.field.name}}">{{control.field.name}}</div -->
<div>{{control?.field.name}}</div>
<div [id]="chartRandomId"></div>
<!-- div id="chart-conTainer"></div -->
<!--div> style="height: 74px; width: 74px"
  <table width="100%">
    <tr>
      <td width="50%">
        <h1>1. HightCharts Here</h1>
        <div id="container" style="width:100%;"></div>
      </td>
      <td width="50%">
        <div class="chart">
          <h1>2. NG2 Chart</h1>

        </div>
      </td>
    </tr>
  </table>

</div -->
