/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { forkJoin, Observable, of, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { AbstractObject, AbstractObjectList } from "src/app/model/abstract-object";
import { Person } from "src/app/model/person";
import { PersonUnitRole } from "src/app/model/person-unit-role";
import { Unit } from "src/app/model/unit";
import { UnitType } from "src/app/model/unit-type";
import { User } from "src/app/model/user";
import { AbstractHttpService } from "src/app/shared/abstract-http.service";
import { Field } from "src/app/shared/field/Field";
import { UnitTypeService } from "../../unit/unit-type-page/unit-type.service";
import { UnitService } from "../../unit/unit.service";
import { CurrentUserService } from "../../user/current-user.service";
import { ImportParser } from '../import-page/Import-parser-interface';
import { ImportDoc } from "../ImportDoc";
import { ImportField } from "../ImportField";
import { ImportRow } from "../ImportRow";
import { Role } from "src/app/model/role";
import { RoleService } from "src/app/pages/role-page/role.service";
import { TeamUserService } from "../../user/team-user-page/team-user.service";
import { TeamUser } from "src/app/model/team-user";
import { UnitPageComponent } from "../../unit/unit-page/unit-page.component";
import { ButtonField } from "src/app/shared/field/ButtonField";
import { MatDialog } from "@angular/material/dialog";
import { ImportInput } from "../ImportInput";
import { ConfirmDialogService } from "src/app/shared/dialogs/confirmDialog";

type importData = { url: string, data: AbstractObject[] }

@Injectable({
    providedIn: 'root'
})
export class BM1ParserService implements ImportParser {

    rowsIn: unknown[][];
    importDoc: ImportDoc = new ImportDoc();

    importData: importData[];

    peopleMap: Map<string, Person[]>;

    headerRow: ImportRow;
    headerRow2: ImportRow;

    currentUser: User;

    unitTypes: UnitType[];
    unitRoles: Role[];

    people: Person[];
    units: Unit[];
    tusers: TeamUser[];

    jointOwners: number;
    ownerOccupied: number;

    constructor(currentUserSvc: CurrentUserService,
        private unitTypeSvc: UnitTypeService,
        private unitRoleSvc: RoleService,
        private unitSvc: UnitService,
        private teamUserSvc: TeamUserService,
        private dialog: MatDialog,
        private cds: ConfirmDialogService,
        private http: HttpClient) {

        currentUserSvc.getCurrentUser().subscribe ( u => this.currentUser = u);
    }

    setUp() {
        this.importDoc = new ImportDoc();
        this.people = [];
        this.units = [];
        this.peopleMap = new Map();

        this.jointOwners = 0;
        this.ownerOccupied = 0;

        return forkJoin({
            types: this.unitTypeSvc.get<UnitType>(false),
            roles: this.unitRoleSvc.get<Role>(false),
            units: this.unitSvc.get<Unit>(true),
            tusers: this.teamUserSvc.get<TeamUser>(true),
        }).pipe(map(result => {
            this.unitTypes = result.types;
            this.unitRoles = result.roles;
            this.units = result.units;
            this.tusers = result.tusers
            return true;
        }));
    }


    parseRows(rows: unknown[][]): ImportDoc {
        this.rowsIn = rows;
        // Row 0 Is Blank line!
        if (this.getHeaderRow(this.rowsIn[0])) {
            this.headerRow2 = this.getHeaderRow2(rows[2]);

            let nextRow = 4;
            while (nextRow < (rows.length - 1)) {
                this.getUnitRow(this.rowsIn[nextRow]);
                nextRow++;
            }
            this.importDoc.setFirstRow(1);
        }
        this.addFileNotes();
        this.checkUnits();
        this.checkPeople();

        //const data = this.getData();

        return this.importDoc;
    }

    private getUnitLink(unitName: string, row: ImportRow, msg: string) {

        const id = row.getStringValue('unitId');
        const field = new ButtonField({ value: 'unitId', label: 'Unit', type: 'link'});
        const input = new ImportInput(`Unit ${unitName} ${msg}`, [field], `Unit ${unitName} ${msg}`);
        const importDoc = this.importDoc;
        field.btnOpts.clickMethod = () => {
            this.dialog.open(UnitPageComponent, { data: { id, condensed: true } }).afterClosed().subscribe(() => {
                this.cds.open('Fixed', "Was this item fixed and can be removed from list?", () => {
                    importDoc.removeInput(input);
                });
            })
        }
        field.control.setValue('Edit ' + unitName + ' Contacts');

        return input;
    }
    private getPersonDtl(row: ImportRow) {
        const ownerName	= row.get<string>('ownerName');
        const email = row.get<string>('email');
        const phone = row.get<string>('phone');
        return `${ownerName} : ${email} : ${phone}`
    }

    private checkPeople() {
        const d = { noOwner: 0, emailMisMatch: 0, bmEmailMissing: 0, phoneNoMatch: 0}
        this.importDoc.getGeneralRows().forEach(row => {
            const name = row.getStringValue('unitName');
            const email = row.getStringValue('email');

            const peeps = this.tusers.filter(tu => tu.unit === name);
            if (peeps.length === 0) {
                d.noOwner += 1;
                const msg = this.getPersonDtl(row) + ' No people associated to unit'
                this.importDoc.addInput(this.getUnitLink(name, row, msg));
            } else if (email) {
                const peep = peeps.find(p => p.email === email);
                if (!peep) {
                    d.emailMisMatch += 1;
                    const msg = this.getPersonDtl(row) + ` does not have email ${email} linked to it`
                    this.importDoc.addInput(this.getUnitLink(name, row, msg));
                }
            } else {
                const availableEmails = [];
                const availablePhone = [];
                peeps.forEach( p => {
                    if (p.globalRoleId === Role.ROLE.OWNER.id || p.globalRoleId === Role.ROLE.OWNER_OCCUPIER.id) {
                        if (p.email) {
                            availableEmails.push(p.email);
                        }
                        if (p.phone) {
                            availablePhone.push(p.phone);
                        }
                    }
                });
                if (availableEmails.length > 0) {
                    d.bmEmailMissing += 1;
                    const msg = this.getPersonDtl(row) + ` No owner email, we have ` + availableEmails.join(', ')
                    this.importDoc.addInput(this.getUnitLink(name, row, msg));
                } else if (availablePhone.length > 0) {
                    const phone = row.getStringValue('phone');
                    let matchP = 0;
                    availablePhone.forEach ( p => {
                        if (phone.includes(p)) {
                            matchP += 1;
                        }
                    })
                    if (matchP === 0) {
                        d.phoneNoMatch += 1;
                        const msg = this.getPersonDtl(row) + `Phone numbers do not match, BM has none of ` + availablePhone.join(', ');
                        this.importDoc.addInput(this.getUnitLink(name, row, msg));
                    }
                }
            }
        });
        const m = `${d.noOwner} Units no owner, ${d.emailMisMatch} emails not match,
            ${d.bmEmailMissing} BM Missing Email, ${d.phoneNoMatch} no phone match`;
        const n = this.importDoc.addNote(m);
        n.needsFix = true; // Always, we never actually import anything here...
    }

    private checkUnits() {
        const d = {found: 0, matched: 0, exists: this.units.length}
        const noMatch = [];
        this.importDoc.getGeneralRows().forEach(row => {
            d.found += 1
            const name = row.getStringValue('unitName');
            const match = this.units.find(u => u.name === name);
            const uIdFld = new ImportField('unitId', 'Unit ID');
            row.add(uIdFld)
            if (match) {
                d.matched += 1;
                uIdFld.setValue(match.id);
            } else {
                noMatch.push(name);
                row.addError('Unit Does not exist');
            }
        });
        const m = `${d.exists} Exist in OMC, ${d.found} in input file, ${d.matched} matches`;
        const n = this.importDoc.addNote(m);
        if (d.matched !== d.found) {
            n.needsFix = true;
        }
    }

    private addFileNotes() {
        let msg = 'File contains ' + this.importDoc.getGeneralRows().length;
        msg += ' units for : ' + this.headerRow.getStringValue('blockName');
        this.importDoc.addNote(msg);
    }

    private getHeaderRow(data: unknown[]) {
        this.headerRow = this.getHeaderFields().parse(data);
        if (this.headerRow.fullErrorCount() > 0) {
            this.importDoc.add(this.headerRow);
            this.importDoc.addNote('Headers Invalid - File not importable');
            return false;
        } else {
            return true;
        }
    }

    private getHeaderFields() {
        const row = new ImportRow();
        const fileTitle = 'Block Unit Data (Part 1 of 5)';
        row.add(new ImportField('date').require());
        row.add(new ImportField('title').validateUsing(value =>
            value === fileTitle ? null : `Error not a BM1 File, expected ${fileTitle} found ${value}`)
        );
        row.add(new ImportField('blockName').require());
        row.add(new ImportField('legalName').require());
        return row;
    }

    private getHeaderRow2(data: unknown[]) {
        const row = new ImportRow();
        row.add(new ImportField('ID').validateUsing(val => val === 'ID' ? null : 'Bad Heading'));
        row.add(new ImportField('unitName').validateUsing(val => val === 'Unit Code' ? null : 'Bad Heading'));
        row.add(new ImportField('partAddress').validateUsing(val => val === 'Unit Name' ? null : 'Bad Heading'));
        row.add(new ImportField('unitType').validateUsing(val => val === 'Unit Description' ? null : 'Bad Heading'));
        row.add(new ImportField('status').validateUsing(val => val === 'Status' ? null : 'Bad Heading'));
        row.add(new ImportField('ownerName').validateUsing(val => val === "Owner's Name" ? null : 'Bad Heading'));
        row.add(new ImportField('ownerAddress').validateUsing(val => val === 'Address' ? null : 'Bad Heading'));
        row.add(new ImportField('email').validateUsing(val => val === 'Email' ? null : 'Bad Heading'));
        row.add(new ImportField('phone').validateUsing(val => val === 'Phone' ? null : 'Bad Heading'));
        row.add(new ImportField('leaseDate').validateUsing(val => val === 'Lease Date' ? null : 'Bad Heading'));
        row.add(new ImportField('interested').validateUsing(val => val === 'Interested Parties' ? null : 'Bad Heading'));

        row.parse(data);

        this.importDoc.add(row);

        return row;
    }

    private getUnitRow(data: unknown[]) {
        const row = new ImportRow();

        row.add(new ImportField('ID').require());
        row.add(new ImportField('unitName').require());
        row.add(new ImportField('partAddress').require());
        row.add(new ImportField('unitType').require()); /*.validateUsing((unitType) => {
            if (this.unitTypes.find(o => o.name === unitType) === undefined) {
                console.log({ types: this.unitTypes, found: this.unitTypes.find(o => o.name === unitType), unitType });
                return `Unknown value [${unitType}]`;
            } else {
                return null;
            }
        }));*/
        row.add(new ImportField('status').require());
        row.add(new ImportField('ownerName').require());
        row.add(new ImportField('ownerAddress'));
        row.add(new ImportField('email'));
        row.add(new ImportField('phone').validateUsing(phoneText => this.parsePhone(phoneText) ? null : 'Phone parse error'));
        row.add(new ImportField('leaseDate'));
        row.add(new ImportField('interested'));

        row.parse(data);
        this.importDoc.add(row);
        return row;
    }

    private parsePhone(phoneText: string,) {
        const firstIndex = phoneText.indexOf(String.fromCharCode(10));
        const firstEntry = phoneText.substring(0, firstIndex);
        const phone = firstEntry.replace('Mobile: ', '');
        const notes = phoneText.replace(firstEntry + String.fromCharCode(10), '').replace('Other:', '');
        return { phone, notes };
    }

    private savePerson(row: ImportRow) {
        const ownerName = row.getStringValue('ownerName');

        this.nameSplitter(ownerName, row);
    }

    private checkFirstAndFirstSurname(parts) {
        if (parts.length === 2 && parts[0].indexOf(' ') < 0) {
            const secondNameParts = parts[1].trim().split(' ');
            parts[0] += ' ' + secondNameParts[1];
        }
    }

    private nameSplitter(ownerName: string, row: ImportRow) {
        const companyRegex = ' Limited| Ltd.| Ltd| Council| Fund| Partners| Association| Pension|  Group';
        const companyParts = ownerName.trim().split(new RegExp(companyRegex, 'i'));
        if (companyParts.length > 1) {
            this.createPersonAs('', '', ownerName, row);
        } else {
            const splitRegex = ' & | and ';
            const parts = ownerName.trim().split(new RegExp(splitRegex, 'i'));
            this.checkFirstAndFirstSurname(parts);
            this.nameSimpleSplit(parts[0].trim(), row, true);
            if (parts.length > 1) {
                this.nameSimpleSplit(parts[1].trim(), row, false);
            }
        }
        /*
                if (parts.length > 1) {
                    const parts = ownerName.split(' & ');
                    parts.forEach (part => this.nameSimpleSplit(part, row));
                } else if (ownerName.indexOf (' AND ') > 0) {
                    const parts = ownerName.split(' & ');
                    parts.forEach (part => this.nameSimpleSplit(part, row));
                } else {
                    this.nameSimpleSplit(ownerName, row);
                }
                */
    }
    private nameSimpleSplit(name, row: ImportRow, isFirst = true) {
        let names = name.split(' ');
        let title = ''

        const titles = "Mr |Mrs |Dr |Miss |Ms |Prof |Rt Hon ";
        const titleParts = name.split(new RegExp(titles, 'i'));
        if (titleParts.length === 2) {
            title = name.replace(titleParts[1], '').trim();
            name = name.replace(title, '').trim()
            names = name.split(' ');
        }

        let firstName = '';
        let surname = name;
        if (names.length > 1) {
            firstName = names[0].trim();
            surname = name.replace(firstName + ' ', '').trim();
        }
        this.createPersonAs(title, firstName, surname, row, isFirst);
    }

    private createPersonAs(title, firstName, lastName, row, isFirst = true) {

        const emailAddr = row.getStringValue('email');
        const phone = isFirst ? this.parsePhone(row.getStringValue('phone')).phone : null;
        const email = isFirst ? emailAddr : null;
        const person: Person = { id: null, title, firstName, lastName, phone, email, personUnits: [] };
        if (!this.isOwnerOccupied(row)) {
            person.address = row.getStringValue('ownerAddress');
        }
        this.people.push(person);
        this.addUnitToPerson(row, person);
        if (!Field.isEmpty(emailAddr)) {
            if (!this.peopleMap.has(emailAddr)) {
                this.peopleMap.set(emailAddr, []);
            }
            this.peopleMap.get(emailAddr).push(person);
        }
        if (!isFirst) {
            this.jointOwners = this.jointOwners + 1;
        }
    }

    private addUnitToPerson(row: ImportRow, person: Person) {
        //console.log(this.unitRoles, this.isOwnerOccupied(row));
        let unitRoleId = this.unitRoles.find(o => o.globalRoleId === Role.ROLE.OWNER.id).id;
        if (this.isOwnerOccupied(row)) {
            unitRoleId = this.unitRoles.find(o => o.globalRoleId === Role.ROLE.OWNER_OCCUPIER.id).id;
        }
        const unitName = row.getStringValue('unitName');
        const pur = { unitRoleId, unitName } as unknown as PersonUnitRole;
        person.personUnits.push(pur);
    }

    private isOwnerOccupied(row: ImportRow): boolean {
        const address = row.getStringValue('partAddress');
        if (row.getStringValue('ownerAddress').indexOf(address) >= 0) {
            //console.log(address + 'Owner Occupied');
            return true;
        } else {
            //console.log(address + ' tennanted', { address, ownerAddress: row.getStringValue('ownerAddress') })
            return false;
        }
    }

    private getUnits() {
        this.importDoc.getGeneralRows().forEach(row => {
            const unit = new Unit();
            unit.name = row.getStringValue('unitName');
            unit.address = row.getStringValue('partAddress');
            unit.ownerOccupied = this.isOwnerOccupied(row);
            if (unit.ownerOccupied) {
                //console.log('INCREMENTING ' + this.ownerOccupied);
                this.ownerOccupied += 1;
            } else {
                //console.log(unit.name + ' Not  owner Occupied', unit);
            }

            const phoneNotes = this.parsePhone(row.getStringValue('phone'));
            unit.notes = phoneNotes.notes;
            /*
            const type = this.unitTypes.find(ut => ut.name === row.getStringValue('unitType'));
            if (type) {
                unit.typeId = type.id;
            } else {
                console.log('Found invalid type', {unit, row})
            }
*/
            this.units.push(unit);
            //TODO::::unit.people = this.setPeople(row);
        });
    }
/*
    private getData() {
        this.summarisePeople();
        this.getUnits();

        this.importDoc.addNote(`File contains ${this.people.length} people records will need to be created before creating units`);
        this.importDoc.addNote(`File contains ${this.jointOwners} units that appear to be jointly owned and will have 2 persons created and linked`);
        const oo = this.units.filter(o => o.ownerOccupied).length;
        this.importDoc.addNote(`There are ${this.ownerOccupied} owner occupied units, ${oo} expected`);

        return [{ url: '/api/units', data: this.units }, { url: '/api/people', data: this.people }];
    }

    private summarisePeople() {

        this.importDoc.getGeneralRows().forEach(row => {
            const emailAddr = row.getStringValue('email');
            if (!Field.isEmpty(emailAddr)) {
                if (!this.peopleMap.has(emailAddr)) {
                    this.savePerson(row);
                } else {
                    const people = this.peopleMap.get(emailAddr);
                    people.forEach(person => {
                        this.addUnitToPerson(row, person);
                    });
                }
            }
        });

        //this.peopleMap.forEach( (value, emailAddr) => {
        //    if (value.length > 1) {
        //        console.warn('multi persn', {value, emailAddr});
        //        this.importDoc.addNote(`Email ${emailAddr} has ${value.length} entries, only first created and all their units will be linked`);
        //    }
        })

    }
*/
    postToServer(): Observable<AbstractObjectList> {
        //const postItems = [];
        return throwError(new Error('BM1 Parser validate, does not post'));
        const url = AbstractHttpService.ajaxPath + '/import';
        this.http.post(url, {}).subscribe(response => console.log(response));
        return of({ itemList: [] } as AbstractObjectList);
    }
}
