/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { AbstractObject } from "src/app/model/abstract-object";
import { AbstractChart } from "./charts/abstract-chart";
import { PicklistField } from "../field/PicklistField";
import { NumberField } from "../field/NumberField";
import { Field } from "../field/Field";
import { AppFilterNavigator, ChartType } from "./chart-analysis";
import { PieChart } from "./charts/pie-chart";
import { BarChart } from "./charts/bar-chart";
import { TrendField } from "../field/TrendField";
import { TrendLine } from "./charts/trend-line";

export class ChartHolder {
    chartType: ChartType
    private width = 0;
    appChart: AbstractChart;
    data: AbstractObject[] = [];
    refresher: AppFilterNavigator;
    showing = false;

    private picklist: PicklistField;
    private numberField: NumberField;
    private trendField: TrendField;

    public excludeZero = false;

    private readonly divId = 'chart-' + Math.round(Math.random() * 1000000);

    constructor(c: ChartType) {
        this.chartType = c;
        this.setWidth();
        this.appChart = this.newChart(c);
    }

    getDivId() {
        return this.divId;
    }

    getName() {
        if (this.numberField) {
            return this.picklist.label + ' by ' + this.numberField.label + ' Chart';
        } else {
            return this.picklist.label + ' Chart';
        }
    }

    setRefresher(refresher: AppFilterNavigator) {
        this.refresher = refresher;
    }

    setupData(items: AbstractObject[]) {
        this.data = items;
        this.showChart();
    }

    switchPicklist(picklist: Field) {
        if (!(picklist instanceof PicklistField)) {
            console.warn('Expected a picklist field, not this', picklist);
        }
        this.picklist = picklist as PicklistField;
        this.showChart();
    }

    setPicklist(picklist: PicklistField) {
        this.picklist = picklist;
        return this;
    }

    getPicklist() {
        return this.picklist;
    }

    // Should be numberField or Duration...
    setMetric(numberField: Field ){
        this.numberField = numberField as NumberField;
        return this;
    }

    setTrend(trendField: TrendField ){
        this.trendField = trendField;
        return this;
    }

    switchMetric(numberField: Field){
        if (!(numberField instanceof NumberField)) {
            console.warn('Expected a number field, not this', numberField);
        }
        this.numberField = numberField as NumberField;
        this.showChart();
    }

    getMetric() : NumberField {
        return this.numberField;
    }

    private showChart() {
        this.showing = true;
        this.appChart.setupData({
            data: this.data,
            divId: this.divId,
            picklist: this.picklist,
            numberField: this.numberField,
            trendField: this.trendField,
            refresher: this.refresher,
            excludeZero: this.excludeZero
        });
    }

    setWidth() {
        if (this.chartType === 'bar' || this.chartType === 'trend') {
            this.width = 2;
        } else {
            this.width = 1;
        }
    }

    getWidth() {
        return this.width;
    }

    newChart(type: ChartType) {
        if (type === 'pie' ) {
            return new PieChart();
        } else if (type === 'bar') {
            return new BarChart();
        } else if (type === 'trend') {
            return new TrendLine();
        } else {
            console.error('Well I dunno how to make a ', type);
        }
    }
}
