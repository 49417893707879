/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { FormConfig } from "src/app/shared/form/FormConfig";
import { FieldSet } from "src/app/shared/form/field-set/field-set.component";
import { UnitPageComponent } from "../../unit/unit-page/unit-page.component";
import { FormButtonComponent } from "src/app/shared/form/form-button/form-button.component";
import { FormNumberComponent } from "src/app/shared/form/form-number/form-number.component";
import { Unit } from 'src/app/model/unit';
import { Panel } from "./panel";
import { FormDateComponent } from "src/app/shared/form/form-date/form-date.component";
import { InvoicePageComponent } from "../invoice-page/invoice-page.component";
import { PersonPageComponent } from "src/app/pages/person-page/person-page.component";

export const invoicesPanel: Panel = new Panel({
    type: 'invoices',
    icon: 'sell',
    takeAction(u: Unit) {
        console.warn(u);
    },
    setTitle() {
        this._title = this._items?.length + ' Invoices this might be a payment against'
    },
    setConfig() { return new FormConfig({
        fieldSet: new FieldSet({
            fields: [
                FormButtonComponent.makeDialogOpenButton('Invoice', 'refNr', 'id', InvoicePageComponent),
                FormDateComponent.make('Date', 'txnDate'),
                FormButtonComponent.makeDialogOpenButton('Unit', 'unitName', 'unitId', UnitPageComponent),
                FormButtonComponent.makeDialogOpenButton('Owner', 'personFullName', 'personId', PersonPageComponent),
                FormNumberComponent.makeCurrency('Total', 'debit'),
                FormNumberComponent.makeCurrency('Outstanding', 'outstanding'),
                FormNumberComponent.makeCurrency('Difference', 'diffAmount')
            ],
        }),
        mode: 'list'
    })}
})
