
/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { Component, OnInit, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { MatCardModule } from "@angular/material/card";
import { CtlHolderComponent } from "src/app/shared/form/ctl-holder/ctl-holder.component";
import { MatButtonModule } from "@angular/material/button";
import { FormRichTextComponent } from "src/app/shared/form/form-rich-text/form-rich-text.component";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DialogBannerComponent } from "src/app/shared/dialogs/dialog-banner-component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CDSData, ConfirmDialogService } from "src/app/shared/dialogs/confirmDialog";
import { MessageService } from "src/app/shared/message.service";
import { HttpClient } from "@angular/common/http";
import { AbstractHttpService } from "src/app/shared/abstract-http.service";
import { Billing } from "src/app/model/billing";
import { BillingService } from "../../billing.service";
import { IsNarrowService } from "src/app/shared/is-narrow.service";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSliderModule } from '@angular/material/slider';
import { formatPercent } from "@angular/common";
import { CommsTemplateType } from "src/app/model/CommsTemplate";
import { FormTextComponent } from "src/app/shared/form/form-text/form-text.component";
import { maxChars, minChars, required } from "src/app/shared/validators";
import { MailMerge, MergeDestination, MergeType } from "src/app/model/mailMerge";
import { uuid } from "src/app/model/abstract-object";

@Component({
    selector: 'app-invoicing-action',
    standalone: true,
    imports: [MatDialogContent, MatCardModule, CtlHolderComponent, MatButtonModule, MatSliderModule,
        FormsModule, ReactiveFormsModule, DialogBannerComponent, MatTooltipModule, MatRadioModule,
        MatSelectModule],
    styleUrl: './invoicing-action-component.scss',
    templateUrl: './invoicing-action-component.html',
})
export class InvoicingActionComponent implements OnInit {

    cds = inject(ConfirmDialogService);
    msgSvc = inject(MessageService);
    http = inject(HttpClient);
    dialogRef = inject(MatDialogRef<InvoicingActionComponent>);
    data = inject(MAT_DIALOG_DATA) as {billing: Billing, mailMerge: MailMerge};
    billingSvc = inject(BillingService);
    screenSvc = inject(IsNarrowService);

    minPaid = new FormControl<number>(0, {validators: [this.setRangeDescription.bind(this)]});
    maxPaid = new FormControl<number>(0, {validators: [this.setRangeDescription.bind(this)]});
    target = new FormControl<string>('');

    sliderTip = `Adjust the slider to target people who have paid nothing, some or all of their charges.`

    mergeDestination = new FormControl<MergeDestination>('PDF');

    ownerTip = $localize `Emails will be sent directly to owners where their email is on file`;
    userTip = $localize `PDF will be generated of invoices and cover letters to be sent, and mailed to you in groups of up to 50 at a time`;
    noEmailToMeTip = $localize`Only invoices for units with no email address will be put in a PDF and mailed to you in groups of up to 50 at a time`;

    mailText = FormRichTextComponent.make('Mail Text', 'mailText', {
        validators: [required, minChars(10)]
    }).setupControl().setCommsTemplate(CommsTemplateType.BILLING);

    nameField = FormTextComponent.make('Subject', 'name', {
        validators: [maxChars(30), required],
        hint: 'A unique name for this mailing, that will be used as subject for mails sent directly'
    }).setupControl();

    formGroup: FormGroup = this.createFormGroup();

    createFormGroup() {
        const formGroup = new FormGroup({
            id: new FormControl<uuid>(null),
            revision: new FormControl<number>(0),
            modelId: new FormControl<uuid>(this.data.billing.id),
            name: this.nameField.control,
            type: new FormControl<MergeType>('Billing'),
            params: new FormGroup({
                minPaid: this.minPaid,
                maxPaid: this.maxPaid,
                target: this.target,
            }),
            content: this.mailText.control,
            destination: this.mergeDestination
        })
        if (this.data.mailMerge) {
            formGroup.patchValue(this.data.mailMerge);
            formGroup.markAsPristine();
            formGroup.markAsUntouched();
        }

        return formGroup;
    }

    setRangeDescription() {
        if (this.target) {
            let d = "People who have paid ";
            if ((this.minPaid.value > 0 && this.minPaid.value < 1) || (this.maxPaid.value > 0 && this.maxPaid.value < 1)) {
                if (this.minPaid.value > 0) {
                    d += "at least " + formatPercent(this.minPaid.value, 'en-ie') + ' ';
                    d += (this.maxPaid.value > 0) ? ' and ' : ''
                }
                if (this.maxPaid.value > 0) {
                    d+= 'up to ' + formatPercent(this.maxPaid.value, 'en-ie') + ' ';
                }
                d += 'of their service charge.'
            } else if (this.minPaid.value === 0 && this.maxPaid.value === 1){
                d = 'All invoices, regardless if they have been paid'
            } else if (this.minPaid.value === 1) {
                d += 'in full.';
            } else {
                d+= 'nothing so far.'
            }
            this.target.setValue(d);
        }
        return null;
    }

    getTip() {
        let tip;
        switch (this.mergeDestination.value) {
            case 'Email': { tip = this.ownerTip; break}
            case 'NoEmailPDF': {tip = this.noEmailToMeTip; break}
            case 'PDF': {tip = this.userTip; break}
            default: { console.error('Where To'); tip = 'No idea where these are going'}
        }
        return tip;
    }

    getData() {
        return {
            id: this.data.mailMerge?.id,
            modelId: this.data.billing.id,
            content: this.mailText.control.value,
            name: this.nameField.control.value,
            destination: this.mergeDestination.value,
            type: 'Billing',
            params: {
                minPaid: this.minPaid.value,
                maxPaid: this.maxPaid.value,
                target: this.target.value,
            }
        } as MailMerge;
    }

    replaceMM(mm: MailMerge) {
        const oldMM = this.data.billing.mailMerges.find( o => o.id === mm.id)
        if (oldMM) {
            Object.assign(oldMM, mm);
        } else{
            this.data.billing.mailMerges.push(mm);
        }
    }

    submitChanges(mm: MailMerge) {
        const url = AbstractHttpService.ajaxPath + `mailmerge/${mm.id}/${mm.revision}/submit`;
        this.http.put<MailMerge>(url, null).subscribe( x => {
            if (x) {
                this.replaceMM(x);
                this.dialogRef.close(this.data.billing);
            }
        }, error => {
            this.msgSvc.showError(error, 'Submitting MailMerge Job');
        })
    }
    
    saveChanges() {
        const mm = this.formGroup.getRawValue() as MailMerge;
        if (!mm.id || this.formGroup.dirty) {
            this.saveTheChanges(mm);
        } else if (!this.formGroup.dirty) {
            const title = $localize `Generate ${mm.destination} now`;
            const msg = $localize`Generate the ${mm.destination} for the ${mm.todo} matching ${mm.params.target}`
            this.cds.open(title, msg, () => this.submitChanges(mm));
        }
    }
    saveTheChanges(data: MailMerge) {
        const url = AbstractHttpService.ajaxPath + `budgets/billing/invoices/${this.data.billing.id}/${this.data.billing.revision}/send`;
        this.http.post<MailMerge>(url, data).subscribe( (mm) => {
            if (mm) {
                const title = $localize`Generate Now`;
                const msg = $localize`Your request has been saved.
                    There where ${mm.todo} invoices matching query for ${mm.params?.target}.
                    Do you want to generate the required emails/pdfs now?`
                const choice = { title, msg, options: [
                    { name: $localize`Yes`, action: () => this.submitChanges(mm) },
                    { name: $localize`No`, action: () => {
                        this.replaceMM(mm);
                        this.dialogRef.close(this.data.billing);
                    }}
                ]} as CDSData;

                return this.cds.openChoice(choice,false);
            }
        }, error => {
            this.msgSvc.showError(error, 'Updating merge request');
        });
    }

    ngOnInit(): void {
        this.mailText.richTextHeight = '500';
    }

}
