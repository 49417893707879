@if (on === 'form') {
  <mat-form-field style="height: 148px">
    @if (control.field.hint) {
      <mat-icon matSuffix color="primary" [matTooltip]="control.field.hint">info</mat-icon>
    }
    <mat-label>{{control.field.label}}</mat-label>
    <textarea id="{{control.field.name}}-TextAreaField" matInput [formControl]="control" [readonly]="control.field.readonly ? '' : null"
      style="line-height: 125%; height: 98px;resize: none">
    </textarea>
    <!-- cdkTextareaAutosize [cdkAutosizeMaxRows]="maxRows" [cdkAutosizeMinRows]="minRows" -->
    <mat-error>
      <app-form-error [(control)]="control"></app-form-error>
    </mat-error>
  </mat-form-field>
}
@if (on !=='form') {
  <div [matTooltip]="control.value">
    @if (control.field.readonly) {
      <span>{{control.value | limitTo:50}}</span>
    }
    @if (!control.field.readonly) {
      <input matInput [formControl]="control"
        [matTooltip]="control.getErrorMessage()"
        [ngClass]="{'error': control.invalid && control.touched}"
        [autocomplete]="control.field.autoComplete"
        [style]="control.field.cellOpts?.style">
    }
  </div>
}
