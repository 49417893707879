<h2 class="dialog-banner">Reopen Request {{sr?.refNr}}</h2>
<mat-dialog-content class="mat-typography" style="max-width: 400px">
    <app-ctl-holder [control]="reasonField.control"></app-ctl-holder>
    <p style="font-size:small; line-height: 1em; max-width: 300px; padding-left: 10px">
        Please enter an explanation why we need to keep request open.
        It will be mailed to request owner and followers notifying them request has reopened.
    </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-raised-button (click)="reopenRequest()" [disabled]="!reasonField.control?.valid" color="primary"
        cdkFocusInitial>Reopen</button>
</mat-dialog-actions>

