<!--
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*
-->

<!--div mat-dialog-title>
    Persons
  </div -->
<div class="dialogTitleBar primary-background-color ">
  <span>{{config.title}}</span>
</div>
<button mat-raised-button class="dialog-close-button" color="primary" (click)="closeDialog()">
  <mat-icon class="dialog-close-icon">close</mat-icon>
</button>
<mat-dialog-content [style.width.px]="width">
  <app-table [config]="config" [drilldown]="false"></app-table>
</mat-dialog-content>