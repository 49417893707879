/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { AbstractObject, uuid } from './abstract-object';
import { Cycle } from './cycle';
import { MailMerge } from './mailMerge';
import { Schedule } from './schedule';
import { Txn } from './txn';
import { Unit } from './unit';

export class Billing extends AbstractObject {
  constructor(o: Partial<Billing> = {}) {
    super(o);
  }

  items: BillingItem[] = [];
  bills: Txn[] = [];
  statusId: uuid;
  status: AbstractObject;
  cycleId: uuid;
  cycle: Cycle;

  billedTotal: number;
  totalItems: number;

  outstandingTotal: number;
  outstandingItems: number;

  units: Unit[];
  mailMerges: MailMerge[] = [];
}

export class BillingItem extends AbstractObject {
  constructor(o: Partial<Billing> = {}) {
    super(o);
  }

  billingId: uuid;
  scheduleId: uuid;
  scheduleName: string;
  bCodeId: uuid;
  bCodeName: string;
  schedule: Schedule;
  total: number;
  billed: number;

  myTotal: number; //  Calculated
}
