/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/model/user';
import { IsNarrowService } from 'src/app/shared/is-narrow.service';
import { CurrentUserService } from '../current-user.service';
import { NavRoute } from 'src/app/shared/NavRoute';
import { UnknownUserComponent } from '../unknown-user/unknown-user.component';
import { NewAgentComponent } from '../../agent/new-agent/new-agent.component';
import { Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { AvatarComponent } from '../avatar/avatar.component';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
    standalone: true,
    imports: [MatCardModule, AvatarComponent, MatButtonModule]
})
export class UserProfileComponent implements OnInit {

    static readonly navRoute = new NavRoute('my/profile', UserProfileComponent, 'contact_page');

    currentUser: User;

    isMobile = false;

    constructor(private currentUserSvc: CurrentUserService,
        private dialog: MatDialog, isNarrowSvc: IsNarrowService,
        private router: Router) {
        isNarrowSvc.detectVeryNarrow().subscribe(result => this.isMobile = result);
    }

    ngOnInit(): void {
        this.currentUser = this.currentUserSvc.currentUser;
        if (!this.currentUser) {
            console.log('Need to be logged in');
            const queryParams = { returnUrl: UserProfileComponent.navRoute.openRoute.path };
            this.router.navigate(['/login'], { queryParams });
            console.log('Did I');
        } else {
            console.log({ user: this.currentUser });
        }
        //this.toggleFields(false);
    }

    createAgencyAllowed(): boolean {
        if (this.currentUser?.email === 'greg@coburn.ie' || this.currentUser?.email === 'kieron.connolly@gmail.com') {
            return true;
        }
        return false;
    }

    requestOmcAccess() {
        this.dialog.open(UnknownUserComponent).afterClosed().subscribe(nr => {
            if (nr) {
                this.router.navigate(['/']);
            }
        });
    }

    createAgency() {
        this.dialog.open(NewAgentComponent);
    }
    /*
        selectTeam(team: Team) {
            this.currentUserSvc.switchTeam(team.id, team.name);
        }

        validateForm() {
            if (!this.omcField.control || !this.ownersField.control || !this.residentField.control || !this.dunnoField.control) {
                return null;
            }
            const omcPick = this.omcField.control.value;
            if (omcPick) {
                if (!this.residentField.control.value && !this.ownersField.control.value) {
                    return FormError.reportError('mustLiveOrOwn',
                        $localize`You must own a unit or live within the development managed by this OMC`);
                }

                if (this.dunnoField.control.value) {
                    return FormError.reportError('unsureOfSend', $localize`You should not send this request to the OMC unless this is the one you need to be associated to.`);
                }
            } else {
                if (!this.dunnoField.control.value) {
                    return FormError.reportError('unsureOfOMC', $localize`Pick an OMC`);
                }

            }

            return null;
        }

        dunnoChanges(newValue, field: Field) {
            console.log({ newValue, fValue: field.control.value });
            if (newValue) {
                this.omcField.control.setValue(null, { emitEvent: false });
                this.toggleFields(true);

            } else {
                this.toggleFields(false);
            }
        }

        toggleFields(to: boolean) {
            this.omcField.visible = to ? Field.noShow : Field.formOnly;
            this.noFindTextField.visible = to ? Field.formOnly : Field.noShow;
            this.newOmcSizeField.visible = to ? Field.formOnly : Field.noShow;
            this.newOmcField.visible = to ? Field.formOnly : Field.noShow;
            this.agentName.visible = to ? Field.formOnly : Field.noShow;
            this.agentPhone.visible = to ? Field.formOnly : Field.noShow;
            this.agentEmail.visible = to ? Field.formOnly : Field.noShow;
            this.director.visible = to ? Field.formOnly : Field.noShow;
            this.directorEmail.visible = to ? Field.formOnly : Field.noShow;
            this.directorPhone.visible = to ? Field.formOnly : Field.noShow;
        }

        addMeToAnOMC() {
            const fields = [];
            fields.push(this.noFindTextField);
            fields.push(this.omcField);
            fields.push(this.omcTextName);
            fields.push(this.residentField);
            fields.push(this.ownersField);
            fields.push(this.dunnoField);
            fields.push(this.newOmcField);
            fields.push(this.newOmcSizeField);
            fields.push(this.agentName);
            fields.push(this.agentEmail);
            fields.push(this.agentPhone);
            fields.push(this.director);
            fields.push(this.directorEmail);
            fields.push(this.directorPhone);

            fields.push(FormTextAreaComponent.make('Note to the directors and/or administrators', 'description',
                { formRow: 4, validators: [required] })
            );

            const dialogFormConfig = new FormConfig(
                {
                    title: $localize` Request to associate with OMC`,
                    fieldSet: new FieldSet(
                        {
                            fields,
                            formLayout: [
                                { cells: [{ colspan: 3 }] },
                                { cells: [{ width: '33%' }, { width: '33%' }, { width: '33%' }] },
                                { cells: [{ width: '33%' }, { width: '33%' }, { width: '33%' }] },
                                { cells: [{ colspan: 3 }] }
                            ],
                            formValidator: this.validateForm.bind(this)
                        }
                    ),
                    mode: 'new',
                    objectFactory: () => of(new ServiceRequest),

                }
            );

            const dialogRef = this.dialog.open(EditDialogComponent,
                {
                    data:
                    {
                        config: dialogFormConfig,
                        service: this.omcs
                    }
                }
            );

            dialogRef.afterClosed().pipe(first()).pipe<ServiceRequest>(map(o => {
                console.log('Got response back as ', o);

                return o;
            })).subscribe();
        }
        */
}
