/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { BCodeService } from 'src/app/modules/budget/bcode.service';
import { of } from 'rxjs';
import { Txn } from 'src/app/model/txn';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { TxnService } from '../txn.service';
import { NavRoute } from 'src/app/shared/NavRoute';
import { ChartParms } from 'src/app/model/sys/nav-item';
import { User } from 'src/app/model/user';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';

@Component({
    selector: 'app-txn-page',
    templateUrl: './txn-page.component.html',
    styleUrls: ['./txn-page.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class TxnPageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('txns', TxnPageComponent, 'receipt_long').setCharts([
        { type: 'bar', picklist: 'typeId' } as ChartParms,
        { type: 'pie', picklist: 'bCodeId', number: 'credit', excludeZero: true } as ChartParms,
        { type: 'pie', picklist: 'bCodeId', number: 'debit', excludeZero: true } as ChartParms
    ]).setViews((u: User) => {
        return [
            {
                id: 'currentYear',
                name: 'Current Year',
                filterFields: {
                    txnCycleId: u.currentTeam.currentPeriod?.cycleId
                },
            }
        ]
    });

    config = new FormConfig({
        navRoute: TxnPageComponent.navRoute,
        title: 'Transactions',
        help: $localize`Financial transactions`,
        fieldSet: new FieldSet({
            fields: this.dataSvc.getFields(),
            formLayout: [
                { cells: [{ width: '25%' }, { width: '25%' }, { width: '25%' }, { width: '25%' }] },
                { cells: [{ colspan: 4, pageTab: 'yes' }] }
            ],
        }),
        service: this.dataSvc,
        mode: 'list',
        objectFactory: () => of(new Txn()),
        //pathEdit: (o: Txn) => Txn.getType(o.typeId).code + '/' + o.id
        pathEdit: (o: Txn) => Txn.getTxnLink(o)
    });

    constructor(public dataSvc: TxnService, public bCodeSvc: BCodeService) {
        super();
    }
}
