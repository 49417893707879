/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { MatDialog } from "@angular/material/dialog";
import { of } from "rxjs";
import { first, map } from "rxjs/operators";
import { AbstractObject, uuid } from "src/app/model/abstract-object";
import { Attachment } from "src/app/model/attachment";
import { Field } from "src/app/shared/field/Field";

import { AbstractHttpService } from "src/app/shared/abstract-http.service";
import { ConfirmDialogService } from "src/app/shared/dialogs/confirmDialog";
import { EditDialogComponent } from "src/app/shared/dialogs/edit-dialog/edit-dialog.component";
import { GridControl } from "src/app/shared/grid/grid-control";
import { GridField } from "src/app/shared/grid/grid-field";
import { ByteCountPipe } from "src/app/shared/pipes/byte-count.pipe";
import { AppFormControl } from "../../app-form-control";
import { FieldSet } from "../../field-set/field-set.component";
import { FormButtonComponent } from "../../form-button/form-button.component";
import { FormDateTimeComponent } from "../../form-date-time/form-date-time.component";

import { FormTextAreaComponent } from "../../form-text-area/form-text-area.component";
import { FormTextComponent } from "../../form-text/form-text.component";
import { FormConfig } from "../../FormConfig";
import { AttachmentService } from "../attachment.service";
import { AttachmentGridComponent } from "./attachment-grid.component";
import { FieldMaker } from "src/app/shared/field/FieldMaker";
import { FileOpts } from "src/app/shared/field/AttachmentField";

export class AttachmentGridField extends GridField {

    attachToId: uuid;
    attachFromAttribute = 'id';
    fileAttachmentService: AbstractHttpService; // Used to upload and download the actual files
    attachmentEditService: AttachmentService; // The service used to edit attachment records in the DB

    showAllFields = true;
    showGrid = true;
    showSimple = false;
    attachmentList?: Attachment[] = [];

    dialog: MatDialog;
    cds: ConfirmDialogService;

    fileOpts: FileOpts;
    tabIcon = 'attach_file'

    setValue(item: AbstractObject, readonly: boolean) {
        this.attachToId = item[this.attachFromAttribute];
        this.attachmentList = this.getValue(item);
        super.setValue(item, readonly) as unknown as Attachment[];
    }

    makeControl() {
        this.config.rowFactory = this.attachmentGridRowFactory.bind(this);
        this.control = new GridControl(this);
        // Reset it, grid control overrides it...
        this.formControlFactory = AttachmentGridComponent.createComponent;

        return this.control;
    }

    hideGrid() {
        this.showGrid = false;
        return this;
    }
    /*
    fields.push(FormButtonComponent.make('', '', {
        name: 'plannedSpend_indicator', type: 'icon', sendServer: false, label: '',
        cellOpts:{heading: '', width: '1%', style: 'padding: 0px 0px 0px 0px; '},
        calculateValue: (o: any, f: Field) => this.calcIndicatorField(f, o.budget, o.prevBudget)
      }));
*/

    addAttachment() {
        console.log('adding');
        console.log(this);
        return of(null);
    }

    getFileIcon(a: Attachment) {
        switch (a.ext.toLowerCase()) {
            case 'pdf': return 'file-types/file_type_pdf_icon_32.png';
            case 'msg': return 'file-types/outlook.png';
            case 'doc': return 'file-types/word_32.png';
            case 'docx': return 'file-types/word_32.png';
            case 'xls': return 'file-types/excel_32.png';
            case 'xlsx': return 'file-types/excel_32.png';

            case 'png': return 'file-types/icon-image.png';
            case 'jpg': return 'file-types/icon-image.png';
            case 'jpeg': return 'file-types/icon-image.png';
            case 'gif': return 'file-types/icon-image.png';

            default: return 'attachment';
        }
    }
    attachmentGridRowFactory() {
        const fields = [
            FieldMaker.id({ visible: Field.noShow }),
            FormButtonComponent.make('', '', {
                name: 'fileTypeIcon',
                type: 'icon',
                label: '',
                cellOpts: { heading: '', width: '50px' },
                btnOpts: { clickMethod: this.downloadAttachment.bind(this) },
                calculateValue: (o) => {
                    const a = (o as Attachment)
                    switch (a.ext.toLowerCase()) {
                        case 'pdf': return 'image:file-types/file_type_pdf_icon_32.png';
                        case 'msg': return 'image:file-types/outlook.png';
                        case 'doc': return 'image:file-types/word_32.png';
                        case 'docx': return 'image:file-types/word_32.png';
                        case 'xls': return 'image:file-types/excel_32.png';
                        case 'xlsx': return 'image:file-types/excel_32.png';

                        case 'png': return 'image:file-types/icon-image.png';
                        case 'jpg': return 'image:file-types/icon-image.png';
                        case 'jpeg': return 'image:file-types/icon-image.png';
                        case 'gif': return 'image:file-types/icon-image.png';

                        default: return 'attachment';
                    }
                }
            }),
            FormButtonComponent.make('File name', 'name', {
                readonly: true, type: 'link', sendServer: false,
                btnOpts: { clickMethod: this.downloadAttachment.bind(this) },
            }),
        ];
        if (this.showAllFields) {
            this.addAllFields(fields);
        }
        return fields;
    }

    addAllFields(fieldAry: Field[]) {

        fieldAry.push(FormTextAreaComponent.make('Notes', 'notes',
            { readonly: true, cellOpts: { style: 'opacity:.75; font-size: small' }, }
        ));

        if (!this.readonly) {
            fieldAry.push(FormButtonComponent.make('', '', {
                name: 'editButton',
                type: 'icon',
                label: '',
                cellOpts: { heading: '' },
                btnOpts: { clickMethod: this.editAttachment.bind(this) },
                calculateValue: () => 'edit_note'
            }));
        }
        fieldAry.push(FormDateTimeComponent.make('Created', 'createdAt', { readonly: true }));
        fieldAry.push(FormTextComponent.make('Size', 'size', {
            readonly: true,
            cellOpts: { style: 'opacity:.75; font-size: small; width: 5em' },
            // Surely never needed... btnOpts: { style: 'font-size:small' },
            calculateValue: o => new ByteCountPipe().formatBytes((o as Attachment).size)
        }));
        if (!this.readonly) {
            fieldAry.push(FormButtonComponent.make('', '', {
                name: 'deleteButton',
                type: 'icon',
                label: '',
                cellOpts: { heading: '' },
                btnOpts: { clickMethod: this.deleteAttachment.bind(this), color: 'accent' },
                calculateValue: () => 'delete'
            }));
        }
    }

    /*
        downloadAttachment(ctl: AppFormControl, $event) {
            console.log(ctl, $event);
            const gridRow = ctl.getRow()
            this.fileAttachmentService.getAttachment(gridRow.focus.id).subscribe(data => {
                const url = window.URL.createObjectURL(data);
                window.open(url, '_blank').focus();
            })
        }

        downloadAttachment(ctl: AppFormControl, $event) {

            console.log(ctl, $event);
            const gridRow = ctl.getRow()
            this.fileAttachmentService.getAttachment(gridRow.focus.id).subscribe(fileResponse => {

                const contentType = fileResponse.headers.get('content-type');
                const blob = new Blob([fileResponse.body], { type: contentType });
                const fileName = fileResponse.headers.get('content-disposition');
                const file = new File([blob], fileName, { type: contentType });
                console.log({contentType, fileName, file});

                const fileURL = URL.createObjectURL(file);
                window.open(fileURL, '_blank');

                const url = window.URL.createObjectURL(fileResponse);
                window.open(url, '_blank').focus();
            })
        }
    */

    /*
        downloadAttachmentFile(a: Attachment) {
            //const svc = this.control.field.fileOpts.service; // as PurchaseService);
            const svc = this.fileAttachmentService;
            svc.getAttachment(a.id).subscribe(data => {
                const url = window.URL.createObjectURL(data);
                window.open(url, '_blank').focus();
            });
        }
    */
    downloadAttachment(ctl: AppFormControl, $event) {
        console.log(ctl, $event);
        const gridRow = ctl.getRow();
        Attachment.download((gridRow.getFormValue() as Attachment), this.fileAttachmentService);
    }

    removeAttachmentFromList(o: Attachment) {
        const index = this.control.value.indexOf(o);
        if (index >= 0) {
            this.control.value.splice(index, 1);
            this.control.setValue(this.control.value);
            console.warn('Removed attachment', o);
        } else {
            console.error('Could not remove attachment form list', { o, index, arry: this.control.value })
        }
    }

    deleteAttachment(ctl: AppFormControl) {
        const editRow = ctl.getRow();
        const attachment = editRow.focus as Attachment;

        this.cds.open($localize`Delete ${attachment.name}`,
            $localize`Are you sure that you want to delete the attachment ${attachment.name}`,
            () => {
                this.fileAttachmentService.detach(attachment).subscribe((o: Attachment) => {
                    if (o) {
                        this.control.removeGridRow(ctl.getRow());
                    }
                });
            }, $localize`Delete`);
    }

    editAttachment(ctl: AppFormControl) {
        const editRow = ctl.getRow();
        const attachment = editRow.focus as Attachment;

        const fields = [];

        fields.push(FieldMaker.id());
        fields.push(FieldMaker.rev());
        fields.push(FormTextComponent.make('Name', 'name'));
        fields.push(FormTextAreaComponent.make('Notes', 'notes'));

        const dialogFormConfig = new FormConfig(
            {
                title: $localize`Attachment`,
                fieldSet: new FieldSet(
                    {
                        fields,
                        formLayout: [{ cells: [{}] }]
                    }
                ),
            }
        );

        const dialogRef = this.dialog.open(EditDialogComponent,
            {
                data:
                {
                    config: dialogFormConfig,
                    service: this.attachmentEditService,
                    id: attachment.id,
                    width: 420,
                    hideTabs: true,
                }
            }
        );

        dialogRef.afterClosed().pipe(first()).pipe<Attachment>(map(o => {
            if (o === 1) { // Gone to another OMC/Team
                this.removeAttachmentFromList(attachment);
            } else {
                if (o) {
                    editRow.refreshFrom(o);
                }
            }
            return o;
        })).subscribe();
    }
}
