
/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { MatDialog } from "@angular/material/dialog";
import { first, map } from "rxjs/operators";
import { AbstractHttpService } from "../shared/abstract-http.service";
import { ConfirmDialogService } from "../shared/dialogs/confirmDialog";
import { EditDialogComponent } from "../shared/dialogs/edit-dialog/edit-dialog.component";
import { AttachmentField } from "../shared/field/AttachmentField";
import { FieldMaker } from "../shared/field/FieldMaker";
import { FieldSet } from "../shared/form/field-set/field-set.component";
import { AttachmentService } from "../shared/form/file/attachment.service";
import { FormPicklistComponent } from "../shared/form/form-picklist/form-picklist.component";
import { FormTextAreaComponent } from "../shared/form/form-text-area/form-text-area.component";
import { FormTextComponent } from "../shared/form/form-text/form-text.component";
import { FormConfig } from "../shared/form/FormConfig";
import { AbstractObject, uuid } from "./abstract-object";
import { Omc } from './Omc';

export class Attachment extends AbstractObject {
    constructor(o: Partial<Attachment> = {}) {
        super(o);
    }
    teamId?: uuid;
    //txnId?: uuid;
    relatedId: uuid;
    size: number;
    ext: string;

    static download(attach: Attachment, svc: AbstractHttpService, forceTeam: uuid = null) {
        svc.getAttachment(attach.id, forceTeam).subscribe(fileResponse => {
            const contentType = fileResponse.headers.get('our-mime-type');
            const blob = new Blob([fileResponse.body], { type: contentType });
            const fileName = fileResponse.headers.get('our-file-name');
            const file = new File([blob], fileName, { type: contentType });
            console.log({ contentType, fileName, file });

            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style.display = "none";

            const fileURL = URL.createObjectURL(file);

            a.href = fileURL;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(fileURL);

            //window.open(fileURL, '_blank');
        });
    }

    static editAttachment(attachment: Attachment, dialog: MatDialog, field: AttachmentField,
        attachSvc: AttachmentService, omcs: Omc[] = []) {
        const fields = [];
        if (field.fileOpts.allowMove) {
            fields.push(FormPicklistComponent.make($localize`OMC Name`, 'teamId', 'team',
                { items: omcs, optionValue: o => o.omcTeamId },
                { cellOpts: { minWidth: '30px', maxWidth: '120px' } }
            ));
        }

        fields.push(FieldMaker.id());
        fields.push(FieldMaker.rev());
        fields.push(FormTextComponent.make('Name', 'name'));
        fields.push(FormTextAreaComponent.make('Notes', 'notes'));

        const dialogFormConfig = new FormConfig(
            {
                title: $localize`Attachment`,
                fieldSet: new FieldSet(
                    {
                        fields,
                        formLayout: [{ cells: [{}] }]
                    }
                ),
            }
        );

        const dialogRef = dialog.open(EditDialogComponent,
            {
                data:
                {
                    config: dialogFormConfig,
                    service: attachSvc,
                    id: attachment.id
                }
            }
        );

        dialogRef.afterClosed().pipe(first()).pipe<Attachment>(map(o => {
            if (o === 1) { // Gone to another OMC/Team
                field.removeAttachment(attachment);
            } else {
                if (o) {
                    attachment.name = o.name;
                    attachment.notes = o.notes;
                    attachment.revision = o.revision;
                }
            }
            return o;
        })).subscribe();
    }

    static deleteAttachment(attachment: Attachment, cds: ConfirmDialogService, field: AttachmentField) {
        cds.open($localize`Delete File`,
            $localize`Delete ${attachment.name}?`,
            () => {
                field.fileOpts.service.detach(attachment).subscribe((o: Attachment) => {
                    if (o) {
                        field.removeAttachment(attachment);
                    }
                })
            }
        );
    }
}
