/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*
*/
import { Params } from '@angular/router';
import moment from 'moment';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { DashboardChart } from 'src/app/shared/chart/dashboard-chart/dashboard-chart';

import * as Highcharts from 'highcharts';
import { HttpParams } from '@angular/common/http';
import { NavRoute } from 'src/app/shared/NavRoute';
import { ServiceRequestPageComponent } from '../../crm/service-request-page/service-request-page.component';
import { uuid } from 'src/app/model/abstract-object';

class RbuData {

    startDate: string;
    startTime: number;

    endDate: string;
    endTime: number;

    rows: { actionDate: string; userId: uuid, userName: string, actions: number }[];
}

type SeriesDataItem = { y: number, name: string, route: NavRoute, routeParams: Params };

export class RbuChart extends DashboardChart {
    dataUrl = AbstractHttpService.ajaxPath + 'agent/dashboard/rbu';
    dataParams = new HttpParams().set('week', 0);

    title = "Request Creation";

    categoryMap: Map<uuid, { name: string, dates: Map<string, number> }> = new Map();
    categories: { title: string, date: string }[] = [];
    datesMap: Map<string, string> = new Map();

    startDate = '';
    endDate = '';
    options = [
        { name: 'Previous Week', action: this.previousWeek.bind(this) },
        { name: 'Following Week', action: this.nextWeek.bind(this) }
    ];

    getRouteParams(userId: uuid, date: string) {
        return this.getShowParms({ reportedById: userId, createdAt: date });
    }

    getSeries() {
        const route = ServiceRequestPageComponent.navRoute;

        const series: { name: string, data: SeriesDataItem[] }[] = [];
        for (const key of this.categoryMap.keys()) {
            const c = this.categoryMap.get(key)
            const seriesItem = { name: c.name, data: [] };
            for (const d of this.categories) {
                const routeParams = this.getRouteParams(key, d.date)
                if (c.dates.get(d.date)) {
                    seriesItem.data.push({ y: c.dates.get(d.date), name: d.date, route, routeParams })
                } else {
                    seriesItem.data.push({ y: 0, name: d.date, route, routeParams });
                }
            }
            series.push(seriesItem);
        }
        return series;
    }

    previousWeek() {
        const currWeek = this.dataParams.get('week');
        this.dataParams = this.dataParams.set('week', Number(currWeek) - 1);
        this.getData();
    }

    resetChart() {
        this.categoryMap = new Map();
        this.categories = [];
        this.datesMap = new Map();
    }

    nextWeek() {
        const currWeek = this.dataParams.get('week');
        this.dataParams = this.dataParams.set('week', Number(currWeek) + 1);
        this.getData();
    }

    getSubTitle() {
        return super.getSubTitle(this.startDate + ' to ' + this.endDate)
    }

    protected setupData(data: RbuData) {
        this.startDate = data.startDate;
        this.endDate = data.endDate;

        for (const i of data.rows) {
            this.datesMap.set(i.actionDate, i.actionDate);
            if (!this.categoryMap.get(i.userId)) {
                this.categoryMap.set(i.userId, { name: i.userName, dates: new Map() });
            }
            this.categoryMap.get(i.userId).dates.set(i.actionDate, i.actions);
        }

        this.categories = [];
        for (const d of this.datesMap.keys()) {
            const theDate = moment(d, 'YYYY-MM-DD');
            const today = moment();
            let title = theDate.format('dddd');
            if (d === today.format('YYYY-MM-DD')) {
                title = "TODAY";
            } else if (theDate.diff(today, 'days') < -7) {
                title = title.substring(0, 3) + ' ' + d.substring(8, 11);
            }
            this.categories.push({ title, date: d });
        }
    }

    clickEvent($event) {
        this.router.navigate([$event.point.route.url, $event.point.routeParams]);
    }

    protected createChartOptions(): Highcharts.Options {

        return {
            chart: {
                backgroundColor: this.chartBackgroundColor,
                borderRadius: this.chartBorderRadius,
                plotBorderWidth: null,
                type: 'column',
                height: this.height,
                width: this.width
            },
            title: this.getChartTitle(),
            subtitle: this.getSubTitle(),
            credits: { enabled: false },
            tooltip: {
                pointFormat: '{series.name}: {point.y} <br/> {point.comt}'
            },
            xAxis: {
                categories: this.categories.map(o => o.title),
                labels: { enable: false }
                //stackLabels: {enable: true, format: '{series.name}'}
            },
            yAxis: {
                min: 0,
                title: { text: 'Count Requests Created' },
            },
            legend: { enabled: true },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: false
                    }
                },
                series: {
                    events: {
                        click: this.clickEvent.bind(this)
                    },
                }
            },
            series: this.getSeries()
        } as unknown as Highcharts.Options;
    }
}
