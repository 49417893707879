/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { Txn } from 'src/app/model/txn';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { uuid } from 'src/app/model/abstract-object';

@Injectable({
    providedIn: 'root'
})
export class BankInService extends AbstractHttpService {

    // protected baseUrl = this.ajaxPath + 'txns?typeId=' + Txn.TYPE.SALE.id;
    protected baseUrl = this.ajaxPath + Txn.TYPE.BANK_IN.code;
    protected cache: Txn[];
    protected typeString = this.ajaxPath + Txn.TYPE.BANK_IN.name;

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super();
    }

    getOutstanding(id: uuid): Observable<Txn[]> {
        return this.http.get<Txn[]>(this.baseUrl + '/' + id + '/outstanding')
            .pipe(
                catchError(this.handleOneError<Txn[]>('getOutstanding' + this.typeString, []))
            );
    }

}
