<div style="margin: 1% auto auto 1%;">
    <div style="display:flex; flex-wrap: wrap; margin-left:auto; margin-right:auto; max-width: 960px">
        <div class="message">
            <h1>Contact Profile Form</h1>
            <p style="margin-top: -20px; margin-bottom: 36px; color: grey">
                Please tell us about your interest in OurOMC software.
            </p>
            <app-field-set [fieldSet]="profileFieldSet" [initComplete]="true"></app-field-set>
        </div>

        <div class="message">
            @if(isFreeTrial) {
                <h1>Request Your Free Trial</h1>
            } @else {
                <h1>Get In Touch</h1>
            }

            <p style="margin-top: -20px; margin-bottom: 20px; color: grey">
                We would love to hear from you, but please complete our contact profile form so we can help you more effectively
            </p>

            <div class="text">
                @if(!msgValid) {
                    <p style="color:red">Please enter a message for us.</p>
                }
                <textarea placeholder="Your Message" rows="10" resize="none"
                    [(ngModel)]="message"
                    (ngModelChange)="changeMsg()"
                    ></textarea>

                @if (!isFreeTrial) {
                    <p>Please only use this form to contact us about our software. If you have a query relating to
                        your OMC, <a routerLink="/newUserRequest"> please contact them directly</a>.
                    </p>
                }
            </div>

            <div style="text-align: center; padding-top: 10px">
                <button mat-raised-button color="primary" (click)="sendMessage()">
                    @if(isFreeTrial) {
                        Request Free Trial
                    } @else {
                        Send
                    }
                </button>
            </div>

            <h1 style="margin-top: 30px;">Our Office Location</h1>

            <p>
                OurOMC.ie, Unit K2, Swords Enterprise Park, Dublin, K67 V583
            </p>

            <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d133927.7612862142!2d-6.216223177878141!3d53.42883074561162!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4867108a37cee3f9%3A0xe1294e7578a0a75a!2sDrinan%2C%20Swords%2C%20Co.%20Dublin%2C%20K67%20V583!5e0!3m2!1sen!2sie!4v1722945804278!5m2!1sen!2sie"
                style="border:0; width: 400px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

        </div>
    </div>
</div>
