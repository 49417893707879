<div>
    <div style="display:flex">
        <div style="padding: 10px; ; align-content: center">
            <mat-icon color="primary" style="font-size: 72px; height: 72px; width:72px">{{icon}}</mat-icon>
        </div>
        <div style="flex:1; min-height: 72px; align-content: center">
            <p><b>{{head}}</b> {{text}}</p>
        </div>
    </div>
</div>
