/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { MyInjector } from 'src/app/app.module';
import { ServiceRequest } from 'src/app/model/serviceRequest';
import { Field } from 'src/app/shared/field/Field';
import { ActionColor, IFormAction } from 'src/app/shared/form/form.component';
import { IsNarrowService } from 'src/app/shared/is-narrow.service';
import { MessageService } from 'src/app/shared/message.service';
import { UnitPageComponent } from '../../unit/unit-page/unit-page.component';


export class UnitDetailsAction implements IFormAction {
    name = $localize`Unit`;
    color: ActionColor = 'primary';
    show = true;
    icon = 'apartment';
    approvalNeeded = false;
    disabled = false;
    approvalText = $localize``;
    tipText = "Click to view or edit details of the unit";
    sr: ServiceRequest;

    private msgSvc = MyInjector.instance.get(MessageService);
    private dialog = MyInjector.instance.get(MatDialog);

    isNarrow = MyInjector.instance.get(IsNarrowService);

    constructor(private unitIdField: Field, private teamField: Field) {
    }

    // sr: ServiceRequest, config: FormConfig
    action() {
        const dialogRef = this.dialog.open(UnitPageComponent,
            {
                data: {
                    id: this.unitIdField.control.value,
                    forceTeamId: this.teamField.control.value,
                    height: (this.isNarrow.screenHeight * 0.9),
                    hideTabs: true
                }
            }
        );

        return dialogRef.afterClosed().pipe( map( () => this.sr ));
    }

    //  setup(serviceRequest: ServiceRequest) {
    setup(sr: ServiceRequest) {
        this.sr = sr;
        if (sr.unitId) {
            this.disabled = false;
        } else {
            this.disabled = true;
        }
    }
}
