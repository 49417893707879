/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { Injectable } from "@angular/core";
import { forkJoin } from "rxjs";
import { first, map } from "rxjs/operators";
import { Schedule } from "src/app/model/schedule";
import { Unit } from "src/app/model/unit";
import { User } from "src/app/model/user";
import { ConfirmDialogService } from "src/app/shared/dialogs/confirmDialog";
import { ScheduleService } from "../../budget/schedule.service";
import { CurrentUserService } from "../../user/current-user.service";
import { UserService } from "../../user/user.service";
import { ImportParser } from '../import-page/Import-parser-interface';
import { ImportDoc } from "../ImportDoc";
import { ImportField } from "../ImportField";
import { ImportRow } from "../ImportRow";
@Injectable({
    providedIn: 'root'
})
export class BM2ParserService implements ImportParser {

    rowsIn: unknown[][];
    importDoc: ImportDoc = new ImportDoc();

    headerRow: ImportRow;
    headerRow2: ImportRow;
    schedules: number = null;

    currentUser: User;
    users: User[];

    constructor(private currentUserSvc: CurrentUserService, private dataImportService: ScheduleService,
        private userSvc: UserService, private cds: ConfirmDialogService) {
    }

    setUp() {
        this.importDoc = new ImportDoc();
        return forkJoin({
            currentUser: this.currentUserSvc.getCurrentUser().pipe(first()),
            users: this.userSvc.getUsers(),
            schedules: this.dataImportService.get(true)
        }).pipe(map(result => {
            this.currentUser = result.currentUser;
            this.users = result.users as User[];
            if (result.schedules.length > 0) {
                const msg = result.schedules.length + ' schedules already exist for ' + this.currentUser.currentTeam.name;
                this.importDoc.addNote(msg, null, true);
            }
            return true;
        }));
    }

    parseRows(rows: unknown[][]): ImportDoc {
        this.rowsIn = rows;
        // Row 0 Is Blank line!
        if (this.getHeaderRow(this.rowsIn[0])) {
            this.headerRow2 = this.getHeaderRow2(rows[2]);

            let nextRow = 4;
            while (nextRow < (rows.length - 1)) {
                this.getUnitRow(this.rowsIn[nextRow]);
                nextRow++;
            }
            this.importDoc.setFirstRow(1);
        }
        this.addFileNotes();
        return this.importDoc;
    }

    addFileNotes() {
        let msg = 'File contains ' + this.importDoc.getGeneralRows().length;
        msg += ' units for : ' + this.headerRow.getStringValue('blockName');
        this.importDoc.addNote(msg);
        this.importDoc.addNote('File contains ' + this.schedules + ' schedules');

        for (let i = 1; i <= this.schedules; i++) {
            const schedName = 'schedule_' + i;
            let total = 0;
            let included = 0;
            let excluded = 0;
            this.importDoc.getGeneralRows().forEach(row => {
                const share = row.getNrValue(schedName);
                total += share;
                if (share > 0) {
                    included += 1;
                } else {
                    excluded += 1;
                }
            })
            const note = `${schedName} - ${this.headerRow2.getStringValue(schedName)} has a total of ${total}
                ${included} units included, ${excluded} units excluded`;
            this.importDoc.addNote(note);
        }
    }

    private getHeaderRow(data: unknown[]) {

        this.headerRow = this.getHeaderFields().parse(data);
        if (this.headerRow.fullErrorCount() > 0) {
            this.importDoc.add(this.headerRow);
            this.importDoc.addNote('Headers Invalid - File not importable');
            return false;
        } else {
            return true;
        }
    }

    getHeaderFields() {
        const row = new ImportRow();
        const fileTitle = 'Block Unit Data (Part 2 of 5)';

        row.add(new ImportField('date').require());
        row.add(new ImportField('title').validateUsing(value =>
            value === fileTitle ? null : `Error not a BM1 File, expected ${fileTitle} found ${value}`)
        );
        row.add(new ImportField('blockName').require());
        row.add(new ImportField('legalName').require());
        return row;
    }

    getHeaderRow2(data: unknown[]) {
        const row = new ImportRow();
        row.add(new ImportField('ID').validateUsing(val => val === 'ID' ? null : 'Bad Heading'));
        row.add(new ImportField('unitName').validateUsing(val => val === 'Unit Code' ? null : 'Bad Heading'));
        row.add(new ImportField('unitAddress').validateUsing(val => val === 'Unit Name' ? null : 'Bad Heading'));
        row.add(new ImportField('carSpaces').validateUsing(val => val === 'Number Of Car Spaces' ? null : 'Bad Heading'));
        row.add(new ImportField('carSpaceNames').validateUsing(val => val === 'Car Space Id' ? null : 'Bad Heading'));

        this.schedules = data.length - 5 - 1;

        for (let i = 1; i <= this.schedules; i++) {
            const schedName = 'schedule_' + i;
            const nif = new ImportField(schedName).require();
            row.add(nif);
        }
        row.add(new ImportField('').expectEmpty());

        row.parse(data);

        this.importDoc.add(row);

        return row;
    }

    getUnitRow(data: unknown[]) {
        const row = new ImportRow();
        row.add(new ImportField('ID').require());
        row.add(new ImportField('unitName').require());
        row.add(new ImportField('unitAddress').require());
        row.add(new ImportField('carSpaces').require());
        row.add(new ImportField('carSpaceNames').require());

        this.schedules = data.length - 5 - 1;

        for (let i = 1; i <= this.schedules; i++) {
            const schedName = 'schedule_' + i;
            const nif = new ImportField(schedName).validateUsing((val, setValue) => {
                if (val === 'EQUAL') {
                    setValue(1);
                    return null;
                } else if (val === null || val == undefined || val === '') {
                    setValue(0);
                    return null;
                } else {
                    if (val && val.indexOf('%') < 0) {
                        return `Expected a percentage found [` + val + ']';
                    }
                    const newVal = val.replace('%', '');
                    if (isNaN(newVal)) {
                        return `Expected 'equal' or percentage value, found ${val}`;
                    } else {
                        setValue(Number(newVal));
                    }
                }
            });
            row.add(nif);
        }
        row.add(new ImportField('').expectEmpty());

        row.parse(data);
        this.importDoc.add(row);
        return row;
    }

    /*
        postToServer() : Observable<AbstractObjectList> {
            const postItems = this.gps.getItemsToPost(() => new ServiceRequest());
            return this.dataImportService.postItems( postItems );
        } */
    prepareSchedules() {
        const scheds: Schedule[] = [];
        this.scheduleFor((scheduleName) => {
            const sched = new Schedule();
            sched.name = this.headerRow2.getStringValue(scheduleName);
            scheds.push(sched);
        }, this.headerRow2);
    }

    scheduleFor(action: (scheduleName: string, row?: ImportRow) => void, row: ImportRow) {
        for (let i = 1; i <= this.schedules; i++) {
            const schedName = 'schedule_' + i;
            action(schedName, row);
        }
    }

    prepareData() {
        this.importDoc.getGeneralRows().forEach(row => {
            const unit = new Unit();
            unit.name = row.getStringValue('Code');
            unit.carSpaces = row.getStringValue('carSpaceNames');
            unit.numberCarSpaces = row.getNrValue('carSpaces');
            unit.address = row.getStringValue('unitAddress');

            for (let i = 1; i <= this.schedules; i++) {
                const schedName = 'schedule_' + i;
                console.log(schedName);

            }
        })
    }
}
