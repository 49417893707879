/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { MyInjector } from "src/app/app.module";
import { Cycle } from "src/app/model/cycle";
import { ActionColor, IFormAction } from "src/app/shared/form/form.component";
import { CycleService } from "../cycle.service";
import { Period } from "src/app/model/period";

export class YearEndAction implements IFormAction {
    name = $localize`Year End`;
    color: ActionColor = 'primary';
    show = true;
    icon = null;
    approvalNeeded = true;
    disabled = false;
    approvalText = "Are you sure you are ready to perform the year end process and carry forward all balances into following year? Please note you should be certain that all transactions have neen entered for the year before proceeding.";
    tipText = '';

    action(cycle: Cycle) {
        const cs = MyInjector.instance.get(CycleService);
        return cs.yearEnd(cycle);
    }

    //  setup(serviceRequest: ServiceRequest) {
    setup(cycle: Cycle) {
        if (cycle.periods) {
            const op = cycle.periods.find(p => p.statusId === Period.STATUS.OPEN.id);
            if (op) {
                this.disabled = true;
                this.tipText = $localize`Cannot perform year end processing until all period have been closed`;
            } else {
                this.tipText = $localize`Perform Year End Processing `;
                this.disabled = false;
            }
        } else {
            this.disabled = true;
            this.tipText = $localize `You will need to establish periods before you can run year end`;
        }
        this.show = true;
    }
}
