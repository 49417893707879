<!--
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*
-->

<mat-dialog-content [style.width.px]="width">
    <div class="dialogTitleBar primary-background-color ">
        <span>{{getTitle()}}</span>
    </div>
    <div style="float: right; position: relative; margin-top: -32px; margin-right: -22px">
        <button (click)="closeDialog()" class="primary-background-color" style="border:none">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <app-chat-followers [chatField]="data.chatField" [allowRemove]="false"></app-chat-followers>
    <div style="margin-left: 10px; margin-bottom:20px">
        <app-chat-reply [chatItem]="data.replyTo"></app-chat-reply>
    </div>
    <app-ctl-holder [control]="commentControl"></app-ctl-holder>
    <button mat-button mat-raised-button color="primary" style="margin:10px" data-save-comment
        [disabled]="commentControl.pristine" (click)="saveComment()">
        <mat-icon>save</mat-icon>Save
    </button>
</mat-dialog-content>