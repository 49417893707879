/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Field } from "src/app/shared/field/Field";
import { ImportParser } from '../import-page/Import-parser-interface';
import { ImportDoc } from "src/app/modules/importer/ImportDoc";
import { ImportField } from "src/app/modules/importer/ImportField";
import { ImportRow } from "src/app/modules/importer/ImportRow";

@Injectable({
    providedIn: 'root'
})
export class BMPurchaseInvoiceParserService implements ImportParser {

    setUp(): Observable<boolean> {
        return of(true);
    }

    parseRows(rows: unknown[][]): ImportDoc {

        let nextRow = 5
        const importDoc = new ImportDoc();

        let totalCR = 0;
        //let totalDB = 0;

        try {
            this.getHeaderFields().parse(rows[nextRow]);
            nextRow += 2
            while (nextRow < (rows.length - 3)) {
                if (Field.isEmpty(rows[nextRow][0])) {
                    const d = this.getChildFields().parse(rows[nextRow]);
                    importDoc.add(d);
                    //totalDB += d.getNrValue('amount');
                } else {
                    const d = this.getDataField().parse(rows[nextRow]);
                    importDoc.add(d);
                    totalCR += d.getNrValue('total');
                    //totalDB += d.getNrValue('amount');
                }
                nextRow++;
            }
            this.totalCheckFields(totalCR).parse(rows[nextRow + 1])
        } catch (e) {
            console.error(e);
        }

        importDoc.finalise();

        console.log(importDoc);
        return importDoc;
    }

    private getHeaderFields() {

        const row = new ImportRow();

        row.addHeaderField('Date');
        row.addHeaderField('Entered By');
        row.addHeaderField('Reference');
        row.addHeaderField('Type');
        row.addHeaderField('Status');
        row.addHeaderField('Customer Code');
        row.addHeaderField('Creditor Name');
        row.addHeaderField('Total');
        row.addHeaderField('Account Name');
        row.addHeaderField('Amt');
        row.addHeaderField('Net');
        row.addHeaderField('Vat');
        row.addHeaderField('Rate%');
        row.addHeaderField('Description');

        return row;
    }

    private getDataField() {
        const row = new ImportRow();

        row.add(new ImportField('date').fromBMDate().require());
        row.add(new ImportField('entered_by').require());
        row.add(new ImportField('reference').require());
        row.add(new ImportField('type').require().allow(new Map([['Inv', 'Invoice'], ['C/N', 'Credit Note']])));
        row.add(new ImportField('status').require().allow(new Map([['Normal', 'Normal']])));
        row.add(new ImportField('customer_code').require());
        row.add(new ImportField('creditor_name').require());
        row.add(new ImportField('total').require().asCurrency());
        row.add(new ImportField('account_name').require());
        row.add(new ImportField('amount').require().asCurrency());
        row.add(new ImportField('nett').asCurrency());
        row.add(new ImportField('vat'));
        row.add(new ImportField('vat%'));
        row.add(new ImportField('description').require());
        row.add(new ImportField('')); // Trailing Comma!

        return row;
    }

    private getChildFields() {
        const row = new ImportRow();

        row.add(new ImportField('f1').expectEmpty());
        row.add(new ImportField('f2').expectEmpty());
        row.add(new ImportField('f3').expectEmpty());
        row.add(new ImportField('f4').expectEmpty());
        row.add(new ImportField('f5').expectEmpty());
        row.add(new ImportField('f6').expectEmpty());
        row.add(new ImportField('f7').expectEmpty());
        row.add(new ImportField('f8').expectEmpty());
        //row.add (new ImportField('total').require().asCurrency());
        row.add(new ImportField('account_name').require());
        row.add(new ImportField('amount').require().asCurrency());
        row.add(new ImportField('nett').asCurrency());
        row.add(new ImportField('vat'));
        row.add(new ImportField('vat%'));
        row.add(new ImportField('description').require());
        // No trailing comma! row.add( new ImportField('')); // Trailing Comma!

        return row;
    }

    private totalCheckFields(total: number) {
        const row = new ImportRow();

        row.add(new ImportField('f1').expectEmpty());
        row.add(new ImportField('f2').expectEmpty());
        row.add(new ImportField('f3').expectEmpty());
        row.add(new ImportField('f4').expectEmpty());
        row.add(new ImportField('f5').expectEmpty());
        row.add(new ImportField('f6').expectEmpty());
        row.add(new ImportField('f7').expectEmpty());
        row.add(new ImportField('f8').expectEmpty());
        row.add(new ImportField('total').require().asCurrency().allow(new Map([[total, total]])));
        row.add(new ImportField('f10'));
        row.add(new ImportField('amount').require().asCurrency().allow(new Map([[total, total]])));
        //row.add (new ImportField('f12'));
        //row.add (new ImportField('f13'));
        //row.add (new ImportField('f14'));
        //row.add (new ImportField('f15'));
        // No trailing comma! row.add( new ImportField('')); // Trailing Comma!

        return row;
    }
}
