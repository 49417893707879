/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Cycle } from 'src/app/model/cycle';
import { Field } from 'src/app/shared/field/Field';
import { Person } from 'src/app/model/person';
import { Txn } from 'src/app/model/txn';
import { PersonService } from 'src/app/pages/person-page/person.service';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { AppPicklistControl, FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { GridField } from 'src/app/shared/grid/grid-field';
import { BCodeService } from '../../budget/bcode.service';
import { CycleService } from '../../budget/cycle.service';
import { PeriodService } from '../../budget/period.service';
import { UnitService } from '../../unit/unit.service';
import { BlTxnService } from '../bl-txn.service';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { NavRoute } from 'src/app/shared/NavRoute';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { User } from 'src/app/model/user';
import { BankAccountService } from '../bank-account.service';
import { ChartParms } from 'src/app/model/sys/nav-item';

@Component({
    selector: 'app-bank-page',
    templateUrl: './bank-page.component.html',
    styleUrls: ['./bank-page.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class BankPageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute(Txn.LEDGER.BL.code, BankPageComponent, 'euro_symbol').setCharts([
        { type: 'bar', picklist: 'typeId', number: 'total' } as ChartParms,
        { type: 'trend', picklist: 'typeId', trend: 'txnPeriodId', number: 'total' } as ChartParms,
    ]).setViews((u: User) => {
        return [
            {
                id: 'currentPeriod',
                name: 'Current Period',
                filterFields: {
                    txnCycleId: u.currentTeam.currentPeriod?.cycleId,
                    txnPeriodId: u.currentTeam.currentPeriod?.id,
                    bankAccountId: 'All',
                    typeId: 'All',
                },
            },
            {
                id: 'unmatched',
                name: 'Unmatched Items',
                filterFields: {
                    bankItemId: 'null',
                    typeId: 'All',
                    bankAccountId: 'All',
                    _sort: 'txnDate'
                },
            }
        ];
    });


    readonly path = Txn.LEDGER.BL.code;

    config = new FormConfig({
        navRoute: BankPageComponent.navRoute,
        title: Txn.LEDGER.BL.name,
        help: $localize`Bank transactions`,
        fieldSet: new FieldSet({
            fields: [
                FieldMaker.id(),
                FormNumberComponent.make('Reference', 'refNr', {}, { cellOpts: { heading: 'Ref' }, readonly: true}),
                FieldMaker.rev(),
                FormPicklistComponent.make('Type', 'typeId', 'type', { items: Txn.TYPES }),
                FormDateComponent.make('Transaction Date', 'txnDate', { cellOpts: { width: '2%' }}),
                FormPicklistComponent.make('Cycle', 'txnCycleId', 'txnCycle', { service: this.cycleSvc, refreshes: ['periodId'] }),
                FormPicklistComponent.make('Period', 'txnPeriodId', 'txnPeriod', { service: this.periodSvc }, {
                    formColumn: 2, refresh: (o: Cycle, control: AppPicklistControl) => {
                        if (o) {
                            control.field.picklist.items = o.periods;
                            control.setValue(null);
                        }
                    },
                }),
                FormPicklistComponent.make('Bank Account', 'bankAccountId', null, {service: this.BankAccountSvc}, {formColumn: 3}),
                FormPicklistComponent.make('Budget Code', 'bCodeId', 'bCode', { service: this.bCodeSvc }, { formColumn: 3 }),
                FormPicklistComponent.make('Unit', 'unitId', 'unit', { service: this.unitSvc }, { formColumn: 3 }),
                FormButtonComponent.makeLink('Owner', 'ownerName', '/people/${ownerId}', {
                    cellOpts: { width: '20%' }, label: 'Primary Owner',
                    calculateValue: (o: Txn) => Person.fullName(o.person),
                    formColumn: 3
                }),
                //FormPicklistComponent.make('Unit', 'unitId', 'unit', { service: this.unitSvc }, { formColumn: 3 }),
                FormNumberComponent.make("Debit", "debit", { format: 'currency', width: 9, formatParms: '1.2-2', blankZero:true }, ),
                FormNumberComponent.make("Credit", "credit", { format: 'currency', width: 9, formatParms: '1.2-2', blankZero: true }),

                FormNumberComponent.make("Total", "total", { format: 'currency', width: 9, formatParms: '1.2-2' }, {
                    cellOpts: {style: 'white-space:nowrap; text-align:right'},
                    calculateValue: (o: Txn) => o.debit - o.credit, visible: Field.noShow
                }),
                FormButtonComponent.make('Matched', 'matched', {
                    type: 'icon', sendServer: false, label: 'Matched', visible: Field.noForm,
                    tableIconStyler: (o: Txn) => o.bankItemId ? 'color:green' : 'color:red',
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    calculateValue: (o: Txn) => o.bankItemId ? 'check' : 'close'
                }),
                FieldMaker.idHolder('bankItemId'),

                new GridField({
                    field:
                        { label: 'Details', value: 'details', visible: Field.formOnly, formRow: 2 },
                    rowFactory: () => [
                        FieldMaker.id({ label: 'Ref', visible: { phone: true, computer: true, form: true }, footer: { text: 'Totals', style: 'text-align: right' } }),
                        FormPicklistComponent.make('Account', 'bCodeId', 'bCode',
                            { service: this.bCodeSvc },
                            { cellOpts: { width: '50%' } }
                        ),
                        FormNumberComponent.make("Debit", "debit", { format: 'currency', width: 9, formatParms: '1.2-2' }),
                        FormNumberComponent.make("Credit", "credit", { format: 'currency', width: 9, formatParms: '1.2-2' }),
                        FieldMaker.notes(),
                        FieldMaker.rev(),
                        FieldMaker.deleteGridRow(),
                    ],
                    hasFooter: true
                }),
            ],
            formLayout: [
                { cells: [{ width: '25%' }, { width: '25%' }, { width: '25%' }, { width: '25%' }] },
                { cells: [{ colspan: 4, pageTab: 'yes' }] }
            ],
        }),
        service: this.dataSvc,
        mode: 'list',
        objectFactory: () => of(new Txn()), // this.newFactory.bind(this),
        newOptions: [
            { name: $localize`New ${Txn.TYPE.BANK_IN.name}`, basePath: `/${Txn.TYPE.BANK_IN.code}/NEW`, params: {} },
            { name: $localize`New ${Txn.TYPE.BANK_OUT.name}`, basePath: `/${Txn.TYPE.BANK_OUT.code}/NEW`, params: {} }
        ],
        pathEdit: (o: Txn) => Txn.getType(o.typeId).code + '/' + o.id
    });

    constructor(public dataSvc: BlTxnService, public bCodeSvc: BCodeService,
        protected activeRoute: ActivatedRoute, protected BankAccountSvc: BankAccountService,
        protected unitSvc: UnitService, public personSvc: PersonService,
        protected cycleSvc: CycleService, protected periodSvc: PeriodService) {
        super();
    }

    newFactory(): Observable<Txn> {
        const txn = new Txn();
        txn.typeId = +this.activeRoute.snapshot.queryParamMap.get('typeId');
        console.warn(txn, this.activeRoute.snapshot);
        return of(txn);
    }
}
