<!--
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*
-->

<app-dialog-banner [text]="config.getTitle()" [dialogRef]="dialogRef"></app-dialog-banner>

<mat-dialog-content [style.width.px]="width" [style.height.px]="height">
  <app-form [config]="config" [dialogRef]="dialogRef"></app-form>
</mat-dialog-content>
