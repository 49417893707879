<!-- app-form-page [config]="config"></app-form-page -->
<div style="margin:20px" formPageHolder>
    <h1>OMC Settings</h1>
    <div style="display:flex; flex-flow: row wrap;">
        <div style="width:330px;">
            <mat-card style="padding:20px; margin:10px">
                <app-text-edit label="Name" [textValue]="team.name" (editor)="editName()"></app-text-edit>
                <app-text-edit label="Legal Name" [textValue]="team.legalName" (editor)="editLegalName()"></app-text-edit>
                <app-text-edit label="Default Address" [textValue]="team.defaultAddress" (editor)="editAddress()"></app-text-edit>
                <app-text-edit label="Primary Agent" [textValue]="team.primaryAgentName"></app-text-edit>
                <app-text-edit label="Defaut Bank Account" [textValue]="team.bank_account?.name"></app-text-edit>

            </mat-card>
        </div>
        <div style="width:330px;">
            <mat-card style="padding:20px; margin:10px">
                <h4>Agent Permissions</h4>
                @for (a of team.agents; track $index) {
                    <app-text-edit label="{{a.agentName}} - {{a.agentRoleName}}" [textValue]="a.omcRoleName"></app-text-edit>
                }
            </mat-card>
        </div>
        <div style="width:330px;">
            <mat-card style="padding:20px; margin:10px">
                <h4>Parameters</h4>
                <app-text-edit label="Payment Terms" [textValue]="team.paymentTerms" (editor)="editPaymentTerms()"></app-text-edit>
                <app-text-edit label="Sending Email" [textValue]="team.fromEmail" (editor)="editFromEmail()"></app-text-edit>
                <app-text-edit label="Billing Address" [textValue]="team.billingAddress" (editor)="editBillingAddress()"></app-text-edit>
            </mat-card>
        </div>
        <div style="width:330px;">
            <mat-card style="padding:20px; margin:10px">
                <h4>Default Budget Codes</h4>
                @for (dbc of team.defaultBCodes; track $index) {
                    <app-text-edit [label]="dbc.type" textValue="{{dbc.bCodeName}} ({{dbc.bCodeSort}})"></app-text-edit>
                }
            </mat-card>
        </div>
    </div>
</div>
