/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Input, ViewContainerRef } from '@angular/core';
import moment from 'moment';
import { AbstractObject } from 'src/app/model/abstract-object';
import { Field } from 'src/app/shared/field/Field';
import { AppFormControl, ControlOn } from '../app-form-control';
import { FormControlInterface } from '../form-control-interface';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgClass } from '@angular/common';
import { FormErrorComponent } from '../form-error/form-error.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

export class DateTimeField extends Field {
    formatValue(value) {
        return value ? moment(value * 1000).fromNow() : '';
    }
    asDate(o: AbstractObject) {
        if (o) {
            const val = this.getValue(o);
            if (val) {
                return new Date(val * 1000);
            }
        }
        return null;
    }

    public getFormValue() {
        if (this.control.value) {
            return new Date(this.control.value).getTime() / 1000;
        } else {
            return null;
        }

    }

    setValue(item: AbstractObject, readonly: boolean) {
        if (readonly) {
            this.readonly = true;
        } else {
            this.readonly = this.defaultReadonly;
        }
        const startTime = this.getValue(item);
        if (startTime > 0) {
            const startValue = this.toDatetimeLocal(startTime);
            this.control.setValue(startValue, { emitEvent: false });
        } else {
            this.control.setValue(null, { emitEvent: false });
        }
        // Surely Not Needed? this.control.field.setLink(item);
        this.control.markAsPristine();
        this.control.markAsUntouched();
    }

    toLongDate(o: AbstractObject) {
        if (o) {
            const val = this.getValue(o);
            if (val) {
                const theDate = new Date(val * 1000);
                return (
                    theDate.toLocaleDateString(undefined,
                        { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
                    ) + ' ' + theDate.toLocaleTimeString([], { hour12: true, hour: 'numeric', minute: '2-digit' })
                );
            } else {
                return '';
            }
        } else {
            return '';
        }

    }

    setToCurrentDateTime(emitEvent = false) {
        this.control.setValue(new Date().getTime(), { emitEvent });
    }

    // Thanks to: https://gist.github.com/WebReflection/6076a40777b65c397b2b9b97247520f0
    toDatetimeLocal(value) {
        const date = new Date(value * 1000);
        const ten = function (i) {
            return (i < 10 ? '0' : '') + i;
        };
        const YYYY = date.getFullYear();
        const MM = ten(date.getMonth() + 1);
        const DD = ten(date.getDate());
        const HH = ten(date.getHours());
        const II = ten(date.getMinutes());
        //SS = ten(date.getSeconds())

        return YYYY + '-' + MM + '-' + DD + 'T' +
            HH + ':' + II; // + ':' + SS;
    }
    fromDatetimeLocal(BST) {
        // BST should not be present as UTC time
        return new Date(BST).toISOString().slice(0, 16) === BST ?
            // if it is, it needs to be removed
            function () {
                return new Date(
                    this.getTime() +
                    (this.getTimezoneOffset() * 60000)
                ).toISOString();
            } :
            // otherwise can just be equivalent of toISOString
            Date.prototype.toISOString;
    }
}


@Component({
    selector: 'app-form-date-time',
    templateUrl: './form-date-time.component.html',
    styleUrls: ['./form-date-time.component.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, FormErrorComponent, NgClass, MatTooltipModule]
})
export class FormDateTimeComponent implements FormControlInterface {

    @Input() control: AppFormControl;
    @Input() on: ControlOn = 'form';

    public static make(heading: string, value: string, options: Partial<Field> = {}) {
        const field: Field = new DateTimeField({ cellOpts: { heading }, value, type: 'dateTime' }, options);
        field.formControlFactory = FormDateTimeComponent.createComponent;
        return field;
    }

    public static createComponent(vcr: ViewContainerRef, ctl: AppFormControl, on: ControlOn) {
        const componentRef = vcr.createComponent(FormDateTimeComponent);
        componentRef.instance.control = ctl;
        componentRef.instance.on = on;
    }

    public getCurrentFromNowValue() {
        //return this.control.value;
        return this.control.value ? moment(this.control.value).fromNow() : '';
    }

    public getCurrentFullDate() {
        return this.control.value ? moment(this.control.value) : '';
    }

    public getDateDescription() {
        if (this.control.value) {
            const m = moment(this.control.value * 1000);
            return m.format('ddd MMM DD hh:mm a')
        }
        return '';
    }

    saveDateTimeValue($event) {
        console.log({ value: $event.target.value, event: $event });
    }

}
