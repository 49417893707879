/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Agent } from "./Agent";
import { BankAccount } from "./bankAccount";
import { Team } from "./team";

export class OmcSettings extends Team {
    bank_account: BankAccount;
    paymentTerms: string;
    fromEmail: string;
    billingAddress: string;
    agents: Agent[] = [];
}
