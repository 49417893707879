<!-- app-form-page [config]="config"></app-form-page -->
<div style="display: flex; flex-wrap: wrap;  margin: 1% auto auto 1%;">
    <div class="main">
        <mat-card appearance="raised">
            <div class="roleDetailCard">
                <h1 class="mat-headline-5">{{role.title}}</h1>
                @if (role.mainText) {
                    <div [innerHTML]="role.mainText"></div>
                }
                @if (role.lottieUrl) {
                    <div style="max-width:100%">
                        <lottie-player 
                        [src]="role.lottieUrl" 
                        loop autoplay direction="1" mode="normal" speed=".5">
                    </lottie-player>
                    </div>

                }
                @if (role.beforeText) {
                    <div [innerHTML]="role.beforeText"></div>
                }
                @if (role.afterText) {
                    <div [innerHTML]="role.afterText"></div>
                }  
            </div>              
        </mat-card>
    </div>
    <div class="roleDetail">
        <mat-card appearance="raised">
            <div class="roleDetailCard">
                <h2 class="mat-headline-6">{{role.benefitTitle}}</h2>
                @for (benefit of role.benefits; track $index) {
                    <app-benefit [head]="benefit.head" [text]="benefit.text" [icon]="benefit.icon"></app-benefit>
                }
            </div>
        </mat-card>
    </div>
</div>
<div class="navButtons" >
    <div class="prev-button">
        @if(role.prevPageTitle) {
            <button mat-button style="text-decoration: underline;" 
                [routerLink]="role.prevPageRoute"><< {{role.prevPageTitle}}
            </button>            
        }
    </div>
    <div style="flex:1;"></div>
    <div class="next-button">
        @if(role.nextPageTitle) {
            <button mat-button style="text-decoration: underline;" 
            [routerLink]="role.nextPageRoute">{{role.nextPageTitle}} >>
        </button>              
        }      
    </div>
</div>
