<app-dialog-banner text="eMail / Print Service Request" [dialogRef]="dialogRef"></app-dialog-banner>

<mat-dialog-content style="padding-left: 10px; padding-right: 10px">
    <div style="display:flex; width: 840px">
        <div style="flex:1"><app-ctl-holder [control]="mailText.control"></app-ctl-holder></div>
        <div style="width: 160px; align-content: center;">
            <div>
                <div style="width:100%; text-align: center;">
                    <p>Suppliers To Email</p>
                    @for (s of toSuppliers; track $index) {
                        <ng-container [ngTemplateOutlet]="supplierChip" [ngTemplateOutletContext]="{s}"></ng-container>
                    }
                    <button mat-mini-fab color="primary" style="margin-top:10px" (click)="addSupplier()">
                        <mat-icon>add</mat-icon>
                    </button>
                    <button mat-raised-button color="primary" [disabled]="!mailText.control.valid" (click)="mailSupplier()"
                        style="margin:10px">
                        Send Emails
                    </button>
                    <button mat-raised-button color="primary" [disabled]="!mailText.control.valid"
                     (click)="printPDF()" style="margin: 10px">
                        Print It
                    </button>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<ng-template #supplierChip let-s="s">
    <div class="chip">
        @if (s.in) {
            <p [matTooltip]="s.supplier.email">{{s.supplier.name}}</p>
        } @else {
            <p><del matTooltip="Not included - No Email Address">{{s.supplier.name}}</del></p>
        }
        <mat-icon style="font-size: 14px; height:14px; width: 14px" color="warn" (click)="removeSupplier(s)">delete</mat-icon>
    </div>
</ng-template>

