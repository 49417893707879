/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, inject, Input } from '@angular/core';
import { uuid } from 'src/app/model/abstract-object';
import { User } from 'src/app/model/user';
import { UserService } from '../user.service';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
    standalone: true,
    imports: [MatTooltipModule]
})
export class AvatarComponent {
    userService = inject(UserService);

    myUser: User = {id: 'rubbish', name: '', avatar: null} as User;

    myId: uuid;
    @Input() set id(value: number) {
        this.myId = value;
        if (value) {
            this.userService.getUser(this.myId).subscribe(user => {
                this.myUser = user;
            });
        }
    }
}
