/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { AbstractObject, uuid } from 'src/app/model/abstract-object';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';


export class FixedAssetType extends AbstractObject {

}

@Injectable({
  providedIn: 'root'
})
export class FixedAssetTypeService extends AbstractHttpService {
  protected baseUrl = this.ajaxPath + 'fassetTypes';
  protected http = inject(HttpClient);
  protected messageService = inject(MessageService);
  protected cache: FixedAssetType[];
  protected typeString = 'Fixed Asset';

  agentMap : Map<uuid, FixedAssetType[]> = new Map();

  getAgentTypes(agentTeamId: uuid) : Observable<FixedAssetType[]>{
    if (this.agentMap.has(agentTeamId)) {
        return of(this.agentMap.get(agentTeamId));
    } else {
        return this.http.get<FixedAssetType[]>(this.baseUrl + '/agentTypes/' + agentTeamId).pipe(
            map( types => {
                this.agentMap.set(agentTeamId, types);
                return types;
            })
        );
    }
  }

}
