/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Inject, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { Field } from 'src/app/shared/field/Field';
import { UnitCategory } from 'src/app/model/unit-category';
import { UnitType } from 'src/app/model/unit-type';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet, LAYOUT_OPTIONS } from 'src/app/shared/form/field-set/field-set.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { GridField } from 'src/app/shared/grid/grid-field';
import { STD_ANIMATION } from 'src/app/shared/std-animation';
import { required, uniqueGrid } from 'src/app/shared/validators';
import { UnitTypeService } from 'src/app/modules/unit/unit-type-page/unit-type.service';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { NavRoute } from 'src/app/shared/NavRoute';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { UnitPageComponent } from '../unit-page/unit-page.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogOptions } from 'src/app/shared/dialogs/pick-dialog/pick-dialog.component';

@Component({
    selector: 'app-unit-type-page',
    templateUrl: './unit-type-page.component.html',
    styleUrls: ['./unit-type-page.component.scss'],
    animations: [STD_ANIMATION],
    standalone: true,
    imports: [FormPageComponent]
})
export class UnitTypePageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('settings/unit-types', UnitTypePageComponent, 'workspaces');

    unitsLink = FormButtonComponent.makeNavButton('Units', UnitPageComponent.navRoute, o => ({ typeId: o.id }),
    {formColumn: 2, value: 'units_count'}
  );

  ownerOccupiedLink = FormButtonComponent.makeNavButton('Owner Occupied', UnitPageComponent.navRoute, 
    o => ({ typeId: o.id, ownerOccupied: 1 }),
    {formColumn: 2, value: 'owner_occupied_count'}
  );
  
  nonOwnerOccupiedLink = FormButtonComponent.makeNavButton('Non Owner Occupied', UnitPageComponent.navRoute, 
    o => ({ typeId: o.id, ownerOccupied: 0  }),
    {formColumn: 2, value: 'non_owner_occupied_count'}
  );  

    config = new FormConfig({
        navRoute: UnitTypePageComponent.navRoute,
        title: $localize`Unit Types`,
        help: $localize`Unit types are the different types of units. Unit types can be suvdivided into categories.
    You can then levy charges based on the category and/or type of units owned`,
        fieldSet: new FieldSet({
            fields: [
                FieldMaker.id(),
                FieldMaker.rev(),
                FieldMaker.nameControl({ validators: [required] }),
                this.unitsLink,
                this.ownerOccupiedLink,
                this.nonOwnerOccupiedLink,                 
                FieldMaker.notes(),
                new GridField({
                    field: { value: 'categories', cellOpts: { heading: 'Categories' }, visible: Field.formOnly },
                    rowFactory: () => [
                        FieldMaker.id({ visible: Field.noShow }),
                        FieldMaker.nameControl({ validators: [required], cellOpts: { heading: 'Categories', width: '20%' } }),
                        FieldMaker.notes(),
                        FieldMaker.rev(),
                        FieldMaker.deleteGridRow(),
                    ],
                    objFactory: () => of(new UnitCategory()),
                    gridValidator: [uniqueGrid()]
                })
            ],
            formLayout: LAYOUT_OPTIONS.doubleColOver1
        }),
        service: this.dataSvc,
        mode: 'list',
        objectFactory: this.newUnitType.bind(this),
    });

    constructor(public dataSvc: UnitTypeService, protected activeRoute: ActivatedRoute,
        @Optional() public dialogRef: MatDialogRef<UnitTypePageComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public dialogOptions: DialogOptions
    ) {
        super();
    }
    newUnitType() {
        const ut = new UnitType();
        if (this.dialogOptions) {
            ut.name = this.dialogOptions['name'];
        }
        return of(ut);
    }
}
