/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { AbstractObject, uuid } from './abstract-object';
import { UnitCategory } from './unit-category';
import { UnitType } from './unit-type';

export class ScheduleCriteria extends AbstractObject {
  scheduleId: uuid;
  type: UnitType;
  typeId: uuid;
  category: UnitCategory;
  categoryId: uuid;
  include = true;
  portion = 1;
  matches?: number;
  constructor(o: Partial<ScheduleCriteria> = {}) {
    super(o);
  }
}
