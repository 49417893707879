/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { FormConfig } from "src/app/shared/form/FormConfig";
import { FieldSet } from "src/app/shared/form/field-set/field-set.component";
import { FormButtonComponent } from "src/app/shared/form/form-button/form-button.component";
import { FormNumberComponent } from "src/app/shared/form/form-number/form-number.component";
import { Panel } from "./panel";
import { SupplierPageComponent } from "../../supply/supplier-page/supplier-page.component";
import { MyInjector } from "src/app/app.module";
import { MatDialog } from "@angular/material/dialog";
import { PurchasePageComponent } from "../purchase-page/purchase-page.component";
import { BankOutPageComponent } from "../bank-out-page/bank-out-page.component";

export const supplierPanel: Panel = new Panel({
    type: 'suppliers',
    icon: 'shopping_cart',
    setTitle() {
        this._title = this._items?.length + ' Suppliers this payment might be to'
    },
    setConfig() { return new FormConfig({
        fieldSet: new FieldSet({
            fields: [
                FormButtonComponent.makeDialogOpenButton('Supplier Name', 'name', 'id', SupplierPageComponent),
                FormNumberComponent.make('Items', 'items'),
                FormNumberComponent.makeCurrency('Outstanding', 'outstanding'),
                FormNumberComponent.makeCurrency('Difference', 'diffAmount'),
                FormButtonComponent.makeTableButton('New', 'newInvoice', this.takeAction2.bind(this)).override({
                    calculateValue: () => 'New Invoice'
                }),
                FormButtonComponent.makeTableButton('Match', 'match', this.takeAction.bind(this)).override({
                    calculateValue: () => 'Pay'
                }),
            ],
        }),
        mode: 'list'
    })},

    takeAction2() {
        const dialog = MyInjector.instance.get(MatDialog);
        dialog.open(PurchasePageComponent);
    },
    takeAction() {
        const dialog = MyInjector.instance.get(MatDialog);
        dialog.open(BankOutPageComponent);
    }
})
