import { Component, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CtlHolderComponent } from 'src/app/shared/form/ctl-holder/ctl-holder.component';

import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';

import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { BankAccount, BankItem, } from 'src/app/model/bankAccount';
import { FormComponent } from 'src/app/shared/form/form.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { BankAccountService, ManualRec } from '../../bank-account.service';
import { Router, RouterLink } from '@angular/router';
import { BankPageComponent } from '../../bank-page/bank-page.component';
import { uuid } from 'src/app/model/abstract-object';
import { MyInjector } from 'src/app/app.module';
import { MessageService } from 'src/app/shared/message.service';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { DateHelper } from 'src/app/shared/dateHelper';

@Component({
  selector: 'app-manual-reconciliation',
  standalone: true,
  imports: [MatDialogModule, MatCardModule, CtlHolderComponent, FormComponent, MatIconModule,
        MatButtonModule, CurrencyPipe, DatePipe, RouterLink],
  templateUrl: './manual-reconciliation.component.html',
  styleUrl: './manual-reconciliation.component.scss'
})
export class ManualReconciliationComponent {

    bankAccounts: BankAccount[] = [];

    fromDate: string;

    closingBalanceCalc = 0;
    closingBalanceDiff = 0;
    closingBalanceItems = 0;

    toDate: string;

    bankAccountSvc = inject(BankAccountService);
    router = inject(Router);
    cds = inject(ConfirmDialogService);

    dialogRef = inject(MatDialogRef<ManualReconciliationComponent>);
    dialogOptions = inject(MAT_DIALOG_DATA);

    bankAccountField = FormPicklistComponent.make('Bank Account', 'bankAccountId', null, {items: this.bankAccounts}).setupControl();

    fromField = FormDateComponent.make('From Date', 'fromDate', {
        hint: $localize `Starting date of the reconciliation, transactions on this date are included.
        If you have already performed a bank reconciliation on this account,
        the start date must be the day after your last reconciliation.
        If you have never performed a bank reconciliation, this date is blank`,
        disable: true,
    }).setupControl();

    openingBalField = FormNumberComponent.makeCurrency('Opening Balance Per Your Bank Statement', 'openingBal', {
        hint: $localize `The opening bank account balance on your statement.
        This must match the closing balance of your previous statement, or be zero if you have never performed a bank rec`,
        disable: true,
    }).setupControl();


    toField = FormDateComponent.make('Reconciliation Date', 'fromDate', {
        hint: $localize`End date of the reconciliation, transactions up to and including this date are included`,
        valueChanges: this.updateClosingBalance.bind(this)
    }).setupControl();

    closingBalField = FormNumberComponent.makeCurrency('Closing Balance Per Your Bank Statement', 'closingBal', {
        hint: $localize`Enter the bank account balance, AFTER the last transaction of this period occured`,
        formColumn: 2,
        valueChanges: this.updateClosingBalanceEntered.bind(this)
    }).setupControl();

    closingBalCalculated = FormNumberComponent.makeCurrency('Closing Balance Calculated', 'closingBalCalc', {
        disable: true,
        formColumn: 2
    }).setupControl();

    constructor() {
        // Update the start date
        // Update the opening Balance
        const ba = this.dialogOptions.bankAccount;
        if (ba.lastRecDate) {
            this.fromField.control.setValue(DateHelper.addDays(ba.lastRecDate, 1));
            this.openingBalField.control.setValue(ba.lastRecBalance);
        } else {
            this.fromField.control.setValue('1999-01-01');
            this.openingBalField.control.setValue(0.00);
        }

    }

    updateClosingBalance(newToDate) {
        this.bankAccountSvc.getBalance(this.dialogOptions.bankAccount.id, newToDate, true).subscribe(bal => {
            this.closingBalanceCalc = +bal[0]['balance'];
            this.closingBalanceDiff = this.closingBalanceCalc - this.closingBalField.control.value;
            this.closingBalanceItems = +bal[0]['items'];
            this.toDate = newToDate;
        })
    }

    updateClosingBalanceEntered(newStatementBal) {
        this.closingBalanceDiff = this.closingBalanceCalc - newStatementBal;
    }

    closeDialog() {
        this.dialogRef.close();
    }

    getAfterNav() {
        const fromDt = this.fromField.control.value;

        const parms = {
            bankAccountId: this.dialogOptions.bankAccount.id,
            txnDate: `><${fromDt}:${this.toDate}`,
            _sort: 'txnDate',
        }
        return ['/' + BankPageComponent.navRoute.listRoute.path, parms]
    }

    getTitle(match: ManualRec) {
        const toDate = this.toField.getCurrentFormattedValue();
        if (match.fromDate > match.toDate) {
            return $localize`WIND BACK Reconcilitation Date To: ${toDate}`;
        }
        return $localize`Mark bank reconciled up to ${toDate}`;
    }

    getMsg(match: ManualRec) {
        const toDate = this.toField.getCurrentFormattedValue();
        if (match.fromDate > match.toDate) {
            return $localize`ARE YOU SURE you want to back date the last reconciliation date for
                ${this.dialogOptions.bankAccount.name} back to ${toDate}.`;
        }
        return $localize `If you continue, you will no longer be able to enter bank transactions
        for your ${this.dialogOptions.bankAccount.name} account before ${toDate}.`;
    }

    postReconciliation() {
        const match = {
            bankAccountId: this.dialogOptions.bankAccount.id as uuid,
            fromDate: this.fromField.control.value,
            openingBal: this.openingBalField.control.value as number,
            toDate: this.toDate,
            closingBal: this.closingBalField.control.value as number
        }


        this.cds.open(this.getTitle(match), this.getMsg(match), () => {
            this.bankAccountSvc.postReconciliation(match).subscribe((response: BankItem) => {
                if (response) {
                    const msgSvc = MyInjector.instance.get(MessageService);
                    const ba = this.dialogOptions.bankAccount;
                    ba.statements.push(response);
                    ba.lastRecDate = response.data.toDate;
                    ba.lastRecBalance = response.data.closingBal;
                    this.dialogRef.close(ba);
                    msgSvc.show('Reconciliation has been saved');
                }
            })
        })
    }
}
