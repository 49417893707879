<h2 class="dialog-banner">Resolve Request {{sr?.refNr}}</h2>
<mat-dialog-content class="mat-typography" style="max-width: 400px">
    <app-field-set [fieldSet]="fieldSet" [isPhone]="true" [initComplete]="ready"></app-field-set>
    <p style="margin-top: -20px; font-size:small; line-height: 1em; max-width: 300px; padding-left: 10px">
        If you include comments above about what was done,
        they will be added as a comment on the request and
        included in the notification to followers that request has been closed.
    </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-raised-button (click)="closeRequest()" [disabled]="!fieldSet.formGroup?.valid" color="primary"
        cdkFocusInitial>Close Request</button>
</mat-dialog-actions>
