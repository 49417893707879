/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ServiceRequest } from 'src/app/model/serviceRequest';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { FieldSet, FieldSetComponent } from 'src/app/shared/form/field-set/field-set.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormRichTextComponent } from 'src/app/shared/form/form-rich-text/form-rich-text.component';
import { RequestService } from '../../request.service';
import { MatButtonModule } from '@angular/material/button';
import { AbstractObject } from 'src/app/model/abstract-object';
import { MessageService } from 'src/app/shared/message.service';

@Component({
  selector: 'app-resolve-request',
  standalone: true,
  imports: [MatDialogModule, FieldSetComponent, MatButtonModule],
  templateUrl: './resolve-request.component.html',
  styleUrl: './resolve-request.component.scss'
})
export class ResolveRequestComponent {
    dialogRef = inject(MatDialogRef<ResolveRequestComponent>);
    sr = inject(MAT_DIALOG_DATA);
    srSvc = inject(RequestService);
    msgSvc = inject(MessageService);

    ready = false;

    fieldSet = new FieldSet();
    closeStatusField = FormPicklistComponent.make('Close Status', 'srCloseStatus', null,
        { items: ServiceRequest.srCloseStates }
    )
    closeTextField = FormRichTextComponent.make('What was done?', 'closeText');

    fields = [
        FieldMaker.idHolder('id'),
        FieldMaker.rev(),
        this.closeStatusField,
        this.closeTextField,
    ]

    constructor() {
        this.fieldSet.setFields(this.fields);
        this.closeStatusField.setValue({srCloseStatus: ServiceRequest.srComplete.id} as unknown as AbstractObject);
        this.ready = true;
    }

    closeRequest() {
        const s = this.closeStatusField.control.value;
        const t = this.closeTextField.control.value;
        this.srSvc.closeRequest(this.sr, s, t).subscribe ( response => {
            if (response) {
                this.dialogRef.close(response);
            }
        }, error => this.msgSvc.showError(error, 'Resolving Request'));
    }
}
