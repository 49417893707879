/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Inject, Optional } from '@angular/core';
import { AreaService } from 'src/app/modules/unit/area.service';
import { of } from 'rxjs';
import { Area } from 'src/app/model/area';
import { STD_ANIMATION } from 'src/app/shared/std-animation';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { FieldSet, LAYOUT_OPTIONS } from 'src/app/shared/form/field-set/field-set.component';
import { required, uniqueGrid } from 'src/app/shared/validators';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { NavRoute } from 'src/app/shared/NavRoute';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { UnitPageComponent } from '../unit-page/unit-page.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogOptions } from 'src/app/shared/dialogs/pick-dialog/pick-dialog.component';
import { Field } from 'src/app/shared/field/Field';
import { Core } from 'src/app/model/core';
import { GridField } from 'src/app/shared/grid/grid-field';

@Component({
  selector: 'app-area-page',
  templateUrl: './area-page.component.html',
  styleUrls: ['./area-page.component.scss'],
  animations: [STD_ANIMATION],
  standalone: true,
  imports: [FormPageComponent],
})

export class AreaPageComponent extends AbstractPageComponent {

  static readonly navRoute = new NavRoute('settings/areas', AreaPageComponent, 'area_chart');

  unitsLink = FormButtonComponent.makeNavButton('Units', UnitPageComponent.navRoute, o => ({ areaId: o.id }),
    {formColumn: 3, value: 'units_count'}
  );

  ownerOccupiedLink = FormButtonComponent.makeNavButton('Owner Occupied', UnitPageComponent.navRoute,
    o => ({ areaId: o.id, ownerOccupied: 1 }),
    {formColumn: 3, value: 'owner_occupied_count'}
  );

  nonOwnerOccupiedLink = FormButtonComponent.makeNavButton('Non Owner Occupied', UnitPageComponent.navRoute,
    o => ({ areaId: o.id, ownerOccupied: 0  }),
    {formColumn: 3, value: 'non_owner_occupied_count'}
  );

  coresGrid =  new GridField({
        field: { value: 'cores', cellOpts: { heading: 'Cores' }, visible: Field.formOnly, formColumn:2 },
        rowFactory: () => [
            FieldMaker.id(),
            FieldMaker.nameControl({ validators: [required], cellOpts: { heading: 'Cores'}}),
            FieldMaker.rev(),
            FieldMaker.deleteGridRow(),
        ],
        objFactory: () => of(new Core()),
        gridValidator: [uniqueGrid()]
    })

  config = new FormConfig({
    navRoute: AreaPageComponent.navRoute,
    title: $localize`Areas`,
    help: $localize`The different areas within a development units are in or that service requests might relate to.`,
    fieldSet: new FieldSet({
      fields: [
        FieldMaker.id(),
        FieldMaker.rev(),
        FieldMaker.nameControl({ validators: [required] }),
        this.coresGrid,
        this.unitsLink,
        this.ownerOccupiedLink,
        this.nonOwnerOccupiedLink,
        FieldMaker.notes(),
      ],
      formLayout: LAYOUT_OPTIONS.threeCol,
    }),
    service: this.dataSvc,
    mode: 'list',
    objectFactory: this.newArea.bind(this),
  });

  constructor(public dataSvc: AreaService,
    @Optional() public dialogRef: MatDialogRef<AreaPageComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogOptions: DialogOptions) {
        super();
  }
  newArea() {
    const o = new Area();
    if (this.dialogOptions) {
        o.name = this.dialogOptions['name'];
    }
    return of(o);
}
}
