<app-form-page [config]="config"></app-form-page>

@if (config.mode === 'list') {
    <div style="padding:20px; width:calc(100%-40px)">
        <div style="display: flex; width: 100%">
            <div style="flex: 0 0 auto">
                @if (hasGocAccesses < 0) {
                    <mat-spinner></mat-spinner>                    
                } @else {
                    <button mat-raised-button color="primary" (click)="checkAccesses()">
                        <mat-icon>data_check</mat-icon>Check Access</button>
                }
            </div>
            <div style="flex:1; text-align:center">
                {{linkedBanks}} of your {{totalBanks}} Bank Accounts have been linked
            </div>
            <div style="flex: 0 0 auto">
                <button mat-raised-button color="primary" (click)="linkBank()">
                    <mat-icon>link</mat-icon>Link Bank Account</button>
            </div>
        </div>
        @if (hasGocAccesses > 0) {
            <ng-container *ngTemplateOutlet="gocAccessTemplate"></ng-container>
        }
    </div>
}
<ng-template #gocAccessTemplate>
    <h2>Account Access Granted (needs to be refreshed every 90 days)</h2>
    <table style="width:100%">
        <tr>
            <th></th>
            <th>Bank Name</th>
            <th>Created</th>
            <th>Status</th>
            <th>Approval Link</th>
            <th colspan="10">Accounts</th>
        </tr>
        <tr>
            <th colspan="5"></th>
            <th>Created</th>
            <th>Last Accessed</th>
            <th>Status</th>
            <th>IBAN</th>
            <th>Matched</th>
        </tr>
        @for (goca of gocAccesses; track $index) {
            <tr>
                <td [rowSpan]="goca.accounts.length + 1">
                    <button mat-icon-button (click)="removeAccess(goca)"><mat-icon color="warn">delete</mat-icon></button>
                </td>
                <td [rowSpan]="goca.accounts.length + 1">{{goca.bankName}}</td>
                <td [rowSpan]="goca.accounts.length + 1">{{goca.created | GmtAgo}}</td>
                <td [rowSpan]="goca.accounts.length + 1">{{goca.status}}</td>
                <td [rowSpan]="goca.accounts.length + 1"><a [href]="goca.gocLink" mat-button color="primary">(re)approve now</a></td>
            </tr>
            @for (gocacct of goca.accounts; track $index) {
                <tr>
                    <td>{{gocacct.created | GmtAgo}}</td>
                    <td>{{gocacct.last_accessed | GmtAgo}}</td>
                    <td>{{gocacct.status}}</td>
                    <td>{{gocacct.iban}}</td>
                    <td>
                        @if (gocacct.matched) {
                            <mat-icon color="primary">done</mat-icon>
                        } @else {
                            <mat-icon color="warn" [matTooltip]="unLinked">close</mat-icon>
                        }
                    </td>
                </tr>
            }
        }
        <tr>

        </tr>
    </table>
</ng-template>
