

<!-- Toolbar -->
<div class="mat-app-background basic-container fxFill">

    <app-navigation></app-navigation>
<!--
    hiding navigation from here caused issues, so doing it from inside of navigation instead.
<app-navigation *ngIf="currentUser"></app-navigation>

     *ngIf="!currentUser"></app-login>
-->
<div class="content" role="main">

</div>

</div>
