/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*
*/

import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AvailableDashboardChart, ChartChosen } from 'src/app/modules/agent/agent-dashboard-page/agent-dashboard-page.component';
import { DashboardChart } from './dashboard-chart';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-dashboard-chart',
    templateUrl: './dashboard-chart.component.html',
    styleUrls: ['./dashboard-chart.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, MatIconModule]
})
export class DashboardChartComponent implements OnInit {

    dashboardChart: DashboardChart;
    @Input() availCharts: AvailableDashboardChart[];
    @Input() chartChosen: ChartChosen;

    @Output() removeChart: EventEmitter<ChartChosen> = new EventEmitter();
    @Output() changeChart: EventEmitter<{ chosen: ChartChosen, to: AvailableDashboardChart }> = new EventEmitter();
    @Output() changeWidth: EventEmitter<{ chosen: ChartChosen, widthChange: number }> = new EventEmitter();

    chartRandomId: string = 'chartId-' + Math.floor(Math.random() * 1000000000);

    constructor(private http: HttpClient) {

    }

    ngOnInit(): void {
        if (this.availCharts && this.chartChosen) {
            this.showChart();
        }
    }

    changeTheChart(to: AvailableDashboardChart) {
        this.chartChosen.code = to.code;
        this.chartChosen.chartParms = to.chartParms;
        this.showChart();
        this.changeChart.emit();
    }

    showChart() {
        this.dashboardChart = this.availCharts.find(ac => ac.code === this.chartChosen.code)?.chart;
        if (this.dashboardChart) {
            this.dashboardChart.getData(this.chartRandomId);
        }
    }
}
