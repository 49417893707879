/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { OmcAgent } from 'src/app/model/OmcAgent';

@Injectable({
    providedIn: 'root'
})
export class OmcAgentService extends AbstractHttpService {

    protected baseUrl = this.ajaxPath + 'omcAgents';
    protected cache: OmcAgent[];
    protected typeString = 'OmcAgent';

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super();
    }

    /* Transformation that now happens server side..
    protected enhanceList(items: OmcAgent[]) {
        const map: Map<uuid, OmcAgent> = new Map();
        const newItems: OmcAgent[] = [];
        items.forEach( oa => {
            if (!map.has(oa.agentTeamId)) {
                const newItem = { id: oa.agentTeamId, name: oa.agentTeam.name, roles: [] } as OmcAgent;
                map.set(oa.agentTeamId, newItem);
                newItems.push(newItem);
                oa.name = oa.agentTeam.name;
            }
            map.get(oa.agentTeamId).roles.push(oa);
        });
        return newItems;
    }
    */
}
