/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { AbstractObject } from 'src/app/model/abstract-object';
import { AuditEntry } from 'src/app/model/auditEntry';
import { TableSettingsComponent } from '../../table/table-settings/table-settings.component';
import { FormConfig } from "../FormConfig";
import moment from 'moment';
import { FromNowPipe } from 'src/app/shared/pipes/from-now.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss'],
    standalone: true,
    imports: [MatDialogContent, MatTooltipModule, FromNowPipe]
})
export class HistoryComponent {

    config: FormConfig;
    auditTrail: AuditEntry[];
    moment = moment;

    constructor(private dialogRef: MatDialogRef<TableSettingsComponent>,
        @Inject(MAT_DIALOG_DATA) data: { config: FormConfig }) {
        this.config = data.config;

        if (this.config.mode === 'edit') {
            this.config.service.getAuditItemTrail(this.config.itemId).pipe(first()).subscribe(auditTrail => {
                this.auditTrail = auditTrail;
                const prev = new Map<string, AbstractObject>();
                for (const ae of this.auditTrail) {
                    const prevOne = prev.get(ae.model + '-' + ae.rowId);
                    if (ae.action === 'created') {
                        ae.change = 'New Entry';
                    } else {
                        ae.change = '<table>'
                        Object.getOwnPropertyNames(ae.version).forEach((prop) => {
                            this.formatChangeMsg(prop, prevOne, ae);
                        })
                        ae.change += '</table>'
                    }
                    prev.set(ae.model + '-' + ae.rowId, ae.version);
                }
            });
        } else if (this.config.mode === 'list') {
            this.config.service.getAuditListTrail().pipe(first()).subscribe(auditTrail => {
                this.auditTrail = auditTrail;
                for (const ae of this.auditTrail) {
                    if (ae.action === 'created') {
                        ae.change = `<a href='${this.config.navRoute.getIdUrl(ae.objectId)}'>New Entry <a>`;
                    } else if (ae.action === 'deleted') {
                        ae.change = '<table>';
                        Object.getOwnPropertyNames(ae.version).forEach((prop) => {
                            this.formatChangeMsg(prop, ({} as AbstractObject), ae);
                        })
                        ae.change += '</table>'
                    } else {
                        ae.change = `<a href='${this.config.navRoute.getIdUrl(ae.objectId)}'>Updated <a>`;
                    }
                }
            });
        }
        console.log(this.config);
    }

    formatChangeMsg(prop: string, prevOne: AbstractObject, ae: AuditEntry) {
        if (prop !== 'updatedAt' && prop !== 'createdAt' && prop !== 'active'
            && prop != 'revision'
            && ae.version[prop] !== prevOne[prop]
            && !(prevOne[prop] === undefined && ae.version[prop] === null)) {
            //if (ae.change.length > 0) {
            //    ae.change += '<br/>'
            //}
            const fld = this.config.fieldSet.getFieldByValue(prop);
            let prevValue = '';
            if (prevOne[prop]) {
                if (fld) {
                    prevValue = fld.formatValue(prevOne[prop]);
                } else {
                    prevValue = prevOne[prop];
                }

            }
            let newValue = '';
            if (ae.version[prop]) {
                if (fld) {
                    newValue = fld.formatValue(ae.version[prop]);
                } else {
                    newValue = ae.version[prop];
                }

            }
            ae.change += `<tr><td>${prop}</td><td>${prevValue}</td><td>=></td><td>${newValue}</td>`;
        }
    }
}
