<ng-container>
  <button mat-icon-button #menuTrigger="matMenuTrigger"  [matMenuTriggerFor]="menu" style="z-index: 50">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item #menuTrigger="matMenuTrigger"  [matMenuTriggerFor]="menuChooseChart">Change Chart</button>
    <button mat-menu-item #menuTrigger="matMenuTrigger"  [matMenuTriggerFor]="menuChooseOptions">Change Options</button>
    <button mat-menu-item (click)="removeChart.emit(chartChosen)">Remove This Chart</button>
    <button mat-menu-item (click)="changeWidth.emit({ chosen: chartChosen, widthChange: 1})">Increase Width</button>
    <button mat-menu-item (click)="changeWidth.emit({ chosen: chartChosen, widthChange: -1})" [disabled]="chartChosen.widthWeight <=1">Decrease Width</button>
  </mat-menu>
  <mat-menu #menuChooseChart="matMenu">
    @for (avail of availCharts; track avail) {
      @if (avail.code === chartChosen?.code) {
        <button mat-menu-item disabled="true">
          {{avail.name}} <mat-icon>check</mat-icon>
        </button>
      }
      @if (avail.code !== chartChosen?.code) {
        <button mat-menu-item (click)="changeTheChart(avail)">{{avail.name}}</button>
      }
    }
  </mat-menu>
  <mat-menu #menuChooseOptions="matMenu">
    @for (option of dashboardChart?.options; track option) {
      <button mat-menu-item (click)="option.action()">
        {{option.name}}
      </button>
    }
  </mat-menu>
</ng-container>
<div [id]="chartRandomId" style="margin-top: -40px"></div>
