@if (on === 'form') {
  <mat-form-field style="height: 74px">
    @if (control.field.hint) {
      <mat-icon matSuffix color="primary" [matTooltip]="control.field.hint">info</mat-icon>
    }
    <mat-label>{{control.field.label}}</mat-label>
    <input id="{{control.field.name}}-textField"
      matInput [formControl]="control" [autocomplete]="control.field.autoComplete"
      [readonly]="control.field.readonly ? '' : null">
    <mat-error style="margin-top: -5px">
      <app-form-error [(control)]="control"></app-form-error>
    </mat-error>
  </mat-form-field>
}
@if (on === 'grid' && !control.field.readonly) {
  <div>
    <input matInput [formControl]="control" type="text"
      [matTooltip]="control.getErrorMessage()"
      [ngClass]="{'error': control.invalid && control.touched}"
      [autocomplete]="control.field.autoComplete"
      [style]="control.field.cellOpts?.style"
      [readonly]="control.field.readonly ? '' : null">
  </div>
}
<!-- [disabled]="control.field.disable ? '' : null" with formControl creates warning errors... -->
<!-- input above had  *ngIf="!control.field.cellOpts.displayText"
span *ngIf="control.field.cellOpts.displayText">{{control.field.cellOpts.displayText}}</span -->
@if (on !== 'form' && control.field.readonly) {
  <span [matTooltip]="control.field.hint">{{control.field.formatValue(control.value)}}</span>
}
