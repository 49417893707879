/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { Action } from 'src/app/model/action';

import { RequestService } from './request.service';
import { ActivatedRoute } from '@angular/router';
import { User } from 'src/app/model/user';

@Injectable({
    providedIn: 'root'
})
export class ActionService extends AbstractHttpService {

    protected baseUrl = this.ajaxPath + 'crm/actions';
    protected cache: Action[];
    protected typeString = 'Action';

    constructor(protected http: HttpClient, protected messageService: MessageService, private activeRoute: ActivatedRoute,
        private reqSvc: RequestService) {
        super();
    }

    complete(action: Action, user: User) {
        const newAction = { id: action.id, revision: action.revision } as Action;
        newAction.completedAt = new Date().getTime() / 1000;
        newAction.completedById = user.id;
        return this.put(newAction)
    }
}
