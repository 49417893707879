<div>
  <div style="float: right">
    <button mat-icon-button color="primary" [mat-menu-trigger-for]="downloadMenu" [disabled]="config.mode === 'new'">
      <mat-icon>download</mat-icon>
    </button>
    <mat-menu #downloadMenu="matMenu">
      <button mat-menu-item (click)="downloadJSON()" i18n>
        Download JSON
      </button>
      <button mat-menu-item (click)="downloadCSV()" i18n>
        Download CSV
      </button>
    </mat-menu>
    <!-- *ngIf="config.mode === 'edit'" -->
    <button (click)="print()" mat-icon-button color="primary" [disabled]="config.mode === 'new'">
      <mat-icon>print</mat-icon>
    </button>
    <button (click)="showHistory()" mat-icon-button color="primary" [disabled]="config.mode === 'new'">
      <mat-icon>history</mat-icon>
    </button>
    <button (click)="myTableSettings()" mat-icon-button color="primary">
      <mat-icon>settings</mat-icon>
    </button>
  </div>
  <!-- div *ngIf="id">
  <button mat-stroked-button routerLink="..">{{title}}</button>
  <span> / {{id}}</span>
  </div -->
  <div style="display:inline; float:left; margin-top: 5px; margin-right: 3px">
    @if (config.mode === 'list') {
      <mat-icon>{{config.navRoute?.icon}}</mat-icon>
    }
    @if (config.mode !== 'list') {
      <mat-icon color="primary">{{config.navRoute?.icon}}</mat-icon>
    }
  </div>
  <h1 class="mat-h1" id='h1-{{id}}' data-table-header style="margin-bottom: 5px">
    @if (config.mode === 'list') {
      <span>{{config.getTitle()}}</span>
      <!-- style="padding-left: 5px;margin-left: 5px;margin-bottom: 2px;padding-bottom: 4px;padding-top:  5px;vertical-align: bottom;" -->
      @if (configHasViews()) {
        <select [(ngModel)]="selectedFilter"
          (change)="applyPresetFilter($event)"
          class="view">
          <option disabled selected [ngValue]="customFilter" style="display:none">{{customFilter.name}}</option>
          @for (f of config.navRoute.userViews; track f) {
            <option [ngValue]="f">{{f.name}}</option>
          }
        </select>
      }
      @if (!isPhone && config.charts) {
        <input type="checkbox"
          style="width: 20px; margin-bottom: 10px" [(ngModel)]="showCharts"
          matTooltip="Show/Hide Charts"
          (change)="toggleTheCharts()">
      }
      <!--mat-checkbox [checked]="showCharts" color="primary" indeterminate="false"
      (change)="toggleCharts($event)" matTooltip="Show/Hide Charts">
      </mat-checkbox -->
    }
    @if (config.mode !== 'list') {
      <a class="primary-font-color" (click)="goBack()">{{config.title}}</a> >
      <span data-page-title>{{config.getItemTitle()}}</span>
    }
  </h1>
  <!-- app-filter *ngIf="config.mode === 'list'" [config]="config"></app-filter -->
  <p class="mat-body" i18n>
    <ng-content></ng-content>
  </p>
</div>
