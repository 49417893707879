/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { AbstractObject, uuid } from './abstract-object';
import { Role } from './role';
import { User } from './user';

export class ForumMember extends AbstractObject {
  forum: Forum;
  forumId: uuid;
  user: User;
  userId: uuid;
  constructor(o: Partial<ForumMember> = {}) {
    super(o);
  }
}

export type ForumPrivName = 'canView' | 'notified' | 'canCreate' | 'canEdit' | 'canComment' | 'canVote';
export class ForumPriv extends AbstractObject {

}
export const FORUM_PRIVS: ForumPriv[] = [
  { id: 'canView', name: 'View' },
  { id: 'notified', name: 'Notified' },
  { id: 'canCreate', name: 'Create New' },
  { id: 'canComment', name: 'Add Comments' },
  { id: 'canVote', name: 'Vote' },
  { id: 'canEdit', name: 'Edit Existing' },
];

export class ForumRole extends AbstractObject {
  forumId: uuid;
  forum: Forum;
  roleId: uuid;
  role: Role;
  notified = true;
  canView = true;
  canCreate = true;
  canEdit = false;
  canComment = true;
  canVote = true;
  forumName?: string;
  roleName?: string;
}

export class Forum extends AbstractObject {
  constructor(o: Partial<Forum> = {}) {
    super(o);
  }
  name: string;
  owner: User;
  ownerId: uuid;
  members: ForumMember[] = [];
  forumRoles: ForumRole[] = [];
  residents: boolean;
  owners: boolean;
}
