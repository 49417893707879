@if (on==='form' && !control.field.readonly && !control.field.disable) {
  <mat-form-field class="full-width" style="height: 74px;">
    @if (control.field.hint) {
      <mat-icon matSuffix color="primary" [matTooltip]="control.field.hint">info</mat-icon>
    }
    <mat-label>{{control.field.label}}</mat-label>
    <!-- input type="text" matInput [formControl]="control" style="display:none" -->
    <input type="text" matInput [formControl]="control.field.filterControl" [matAutocomplete]="myAutoComplete">
    <mat-hint style="margin-top: -5px"><app-form-error [(control)]="control"></app-form-error></mat-hint>
    <mat-autocomplete #myAutoComplete="matAutocomplete" [displayWith]="this.control.field.picklist.optionDisplayValue">
      @for (option of filteredOptions | async; track $index) {
        <mat-option [value]="option">
          {{this.control.field.picklist.optionDisplayValue(option)}}
        </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
}
@if (on==='form' && (control.field.readonly || control.field.disable)) {
  <mat-form-field class="full-width" style="height: 74px;">
    <mat-label>{{control.field.label}}</mat-label>
    <input type="text" matInput [formControl]="control.field.filterControl" style="display:none">
    {{control.field.filterString}}
  </mat-form-field>
}
<!-- mat-form-field class="full-width" *ngIf="on==='form'" style="height: 74px;">
<mat-label>{{control.field.label}}</mat-label>
<input matInput type="text" [formControl]="control" style="display:none" tabindex="-1" [value]="filterFieldFocused()">
<input type="text" matInput autocomplete="off" style="width:calc(100% - 30px)"
  [(ngModel)]="control.field.filterString"
  (ngModelChange)="getFilteredList()"
  class="combobox-input"
  (keyup)="onKeyPress($event)"
  (click)="showList()"
  (blur)="focusOut()">
<div style="float:right" (click)="showList()">
  <mat-icon style="margin-top: -10px; margin-right: -15px">arrow_drop_down</mat-icon>
</div>
<mat-error>ass FormCombo
  <app-form-error [(control)]="control"></app-form-error>
</mat-error>
<div class="combobox-options" *ngIf="!listHidden" id="{{control.field.name}}-options">
  <list-item *ngFor="let item of filteredList;let i = index"
    id="{{control.field.name}}-{{control.field.picklist.optionValue(item)}}" (click)="pickItem(item)"
    [ngClass]="{'selected': item===selectedItem}">{{control.field.picklist.optionDisplayValue(item)}}
  </list-item>
</div>
</mat-form-field -->
@if (on!=='form' && !control.field.readonly && !control.field.disable) {
  <div>
    <!-- input type="text" attr.list="{{control.field.name}}-ListOptions"
    [style.max-width]="control.field.cellOpts.maxWidth" *ngIf="!control.field.readonly" class="combo"
    (click)="$event.target.select()" [ngModel]="getDisplayValue(control)"
    (change)="itemChanges($event, control.field)" (focus)="itemFocused(control.field)"
    (blur)="loseFocus(control.field, $event)" -->
    <input type="text" [formControl]="control.field.filterControl"
      [matTooltip]="control.getErrorMessage()"
      [ngClass]="{'error': control.invalid && control.touched}"
      [matAutocomplete]="myAutoComplete" style="padding: 5px; padding-right: 3px">
    <mat-autocomplete #myAutoComplete="matAutocomplete" [displayWith]="this.control.field.picklist.optionDisplayValue">
      @for (option of filteredOptions | async; track option) {
        <mat-option [value]="option">
          {{this.control.field.picklist.optionDisplayValue(option)}}
        </mat-option>
      }
    </mat-autocomplete>
  </div>
}
@if (on!=='form' && (control.field.readonly || control.field.disable)) {
  <span >{{control.field.filterString}}</span>
}
