/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Input } from '@angular/core';
import { ChatField } from '../chat-field';
import { IsNarrowService } from '../../is-narrow.service';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { UserNameComponent } from 'src/app/modules/user/user-name/user-name.component';
@Component({
    selector: 'app-chat-followers',
    templateUrl: './chat-followers.component.html',
    styleUrls: ['./chat-followers.component.scss'],
    standalone: true,
    imports: [MatChipsModule, MatIconModule, UserNameComponent]
})
export class ChatFollowersComponent {

    @Input() chatField: ChatField;
    @Input() allowRemove = true;

    newStyle = true;

    isPhone = false;

    remove(follower) {
        const chat = this.chatField.chat;
        chat.service.stopFollowing(follower.relatedId, follower.userId, true, chat.teamId).subscribe((success) => {
            if (success) {
                chat.followers.splice(this.chatField.chat.followers.findIndex((f) => f === follower), 1);
            }
        });
    }
    constructor(isNarrow: IsNarrowService) {
        isNarrow.detectVeryNarrow().subscribe((r) => this.isPhone = r);
    }
}
