<div style="display: flex; flex-wrap: wrap; flex-direction: column; margin: 1% auto auto 1%;">
    <div class="heading">
        <div class="image" style="padding-left: 30px;">
            <img src="{{assets}}ouromc-logo.png" />
            <img style="padding-top: 40px;" src="{{assets}}clg-logo.png" />
        </div>
        <div class="description">
            <h1>About OurOMC.ie</h1>
            <p>
                OurOMC.ie is the worlds first software platform designed for Owners' Management Companies
                (OMCs) first.
                The closest related products available in the marketplace, are designed for the Property
                Management Agents who provide services to OMCs. Most of these systems have been around for
                many years and are starting to show their age. Whilst they are now attempting to bolt on
                functionality for OMCs, it is still very clear their primary customers are the agents.
            </p>
            <p>
                OurOMC.ie is the first platform designed for the connected world in which OMCs exist.
                It is the first system that was designed with the expectation that owners, residents,
                directors, contractors and agents all needed a single platform that allows them to
                collaborate.
            </p>
        </div>
    </div>
    <div class="people">
        <div class="person">
            <ng-container [ngTemplateOutlet]="imageHolderDiv"
                [ngTemplateOutletContext]="{front:'greg.png', back:'greg-back.png'}">
            </ng-container>
            <div class="bio">
                <p><span class="name">Gregory Coburn</span>
                    has been designing, building, implementing and scaling technical business solutions for
                    over 30 years. From individually building solutions for micro companies,
                    through helping some of the world's largest technology organisations
                    implement some of the worlds largest business systems.
                </p>
                <p>
                    Gregory has brought together his experiences of accounting, CRM and payment processing
                    solutions to design a solution for himself, as an OMC director, a solution that has
                    now become OurOMC.ie
                </p>
            </div>
        </div>
        <div class="person">
            <ng-container [ngTemplateOutlet]="imageHolderDiv"
                [ngTemplateOutletContext]="{front:'kieron.png', back:'kieron-back.png'}">
            </ng-container>

            <div class="bio">
                <p><span class="name">Kieron Connolly</span>
                    is a seasoned technology executive who
                    has been providing technology services to Irish SME businesses for almost 30 years.
                    He has a proven track record in helping organisations to successfully adopt new
                    technologies and reap the maximum benefit from their investments.
                </p>
                <p>
                    Whilst a people orientated person, Kieron also brings expert technical skills to
                    the table, especially in the areas of cloud services, networking and web development.
                </p>
            </div>

        </div>
        <!-- div class="person">
            <ng-container [ngTemplateOutlet]="imageHolderDiv"
                [ngTemplateOutletContext]="{front:'john.png', back:'john-back.png'}">
            </ng-container>

            <div class="bio">
                <p>
                    <span class="name">John Kennedy</span> has over 25 years of experience in the real estate industry,
                    A distinguished fellow and former president of the Institute of Professional
                    Auctioneers and Valuers (IPAV)
                    John has established
                    himself as a leader in property management, valuation, and real estate technology.
                </p>
                <p>
                    Regularly speaking at conferences, his strategic vision, innovative mindset, and
                    commitment to professional integrity have earned him respect among peers and stakeholders, making him a driving
                    force in
                    the real estate industry.
                </p>

            </div>
        </div -->
    </div>
</div>

<ng-template #imageHolderDiv let-front='front' let-back='back'>
    <div class="imageHolder">
        <div style="flex:1"></div>
        <div class="card-container">
            <div class="card">
                <div class="image front">
                    <!-- img src="{{assets}}greg.png" / -->
                     <img src="{{assets}}{{front}}" />

                </div>
                <div class="image back">
                    <!-- img src="{{assets}}greg.png" / -->
                     <img src="{{assets}}{{back}}" />

                </div>
            </div>
        </div>
        <div style="flex:1"></div>
    </div>

</ng-template>
