<!--mat-expansion-panel>
<mat-expansion-panel-header>
  <mat-panel-title>Analysis</mat-panel-title>
</mat-expansion-panel-header-->
<table style="width: 100%; border-radius: 1em;">
  <!--tr>
  <td [width]="config.charts.chLeft.getWidth()">
    <app-chart-holder [chartHolder]="config.charts.chLeft" [fieldSet]="config.fieldSet"></app-chart-holder>
  </td>
  <td [style.padding-left.px]="getMiddlePadding()">
    <app-chart-holder [chartHolder]="config.charts.chMiddle" [fieldSet]="config.fieldSet"></app-chart-holder>
  </td>
  <td [width]="config.charts.chRight.getWidth()">
    <app-chart-holder [chartHolder]="config.charts.chRight" [fieldSet]="config.fieldSet"></app-chart-holder>
  </td>
  </tr -->
  <tr>
    @for (ch of this.config.charts.chartHolders; track ch) {
      <td [style.width.px]="getChartWidth(ch)">
        <app-chart-holder [chartHolder]="ch" [config]="config"></app-chart-holder>
      </td>
    }
  </tr>
</table>
<!--/mat-expansion-panel-->
