/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, CUSTOM_ELEMENTS_SCHEMA, inject, Input } from '@angular/core';
import { NextButtonComponent } from '../next-button/next-button.component';
import { PrevButtonComponent } from '../prev-button/prev-button.component';
import { BenefitComponent } from '../benefit/benefit.component';
import { MatCardModule } from '@angular/material/card';
import { STD_ANIMATION } from 'src/app/shared/std-animation';
import { Router, RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

export class RoleHomePage {
    title: string;
    mainText: string;
    lottieUrl: string;
    beforeText: string;
    afterText: string;
    benefitTitle: string;
    prevPageTitle: string;
    prevPageRoute: string;
    nextPageTitle: string;
    nextPageRoute: string;
    benefits: {
        icon: string;
        head: string;
        text: string;
    }[];
}

@Component({
  selector: 'app-role-page',
  standalone: true,
  imports: [NextButtonComponent, PrevButtonComponent, BenefitComponent, MatCardModule, RouterLink, MatButtonModule],
  templateUrl: './role-home-page.component.html',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  styleUrl: '../home.scss',
  animations: [STD_ANIMATION],
})
export class RoleHomePageComponent {
    @Input() role: RoleHomePage;

    router = inject(Router)
    constructor() {
        this.router.events.subscribe(() => {
            const el = document.getElementById('anonymousToolbar');
            if (el) {
                el.scrollIntoView();
            } else {
                const el2 = document.getElementById('loggedInToolbar');
                if (el2) {
                    el2.scrollIntoView();
                }
            }
        });
    }
}
