/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { Observable } from 'rxjs';
import { OmcSettings } from 'src/app/model/OmcSettings';
import { AbstractObject, uuid } from 'src/app/model/abstract-object';
import { MessageService } from 'src/app/shared/message.service';

@Injectable({
    providedIn: 'root'
})
export class OmcSettingsService extends AbstractHttpService{

    protected baseUrl = AbstractHttpService.ajaxPath + 'omcSettings';
    protected cache: OmcSettings[];
    protected typeString = 'OMCSettings';
    
    protected http = inject(HttpClient);
    protected messageService = inject(MessageService);

    getAgencies() : Observable<Agency[]> {
        return this.http.get<Agency[]>(this.baseUrl + '/agencies')
    }    
}

export class Agency extends AbstractObject {
    agentTeamId: uuid;
    agentRoleId: uuid;
    omcRoleId: uuid;
}
