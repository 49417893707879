/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { FormConfig } from "src/app/shared/form/FormConfig";
import { FieldSet } from "src/app/shared/form/field-set/field-set.component";
import { UnitPageComponent } from "../../unit/unit-page/unit-page.component";
import { FormButtonComponent } from "src/app/shared/form/form-button/form-button.component";
import { FormTextComponent } from "src/app/shared/form/form-text/form-text.component";
import { FormNumberComponent } from "src/app/shared/form/form-number/form-number.component";
import { Unit } from 'src/app/model/unit';
import { Panel } from "./panel";
import { Frequency } from "src/app/model/frequency";
import { FormPicklistComponent } from "src/app/shared/form/form-picklist/form-picklist.component";
import { MyInjector } from "src/app/app.module";
import { MatDialog } from "@angular/material/dialog";
import { BankInPageComponent } from "../bank-in-page/bank-in-page.component";
import { ConfirmDialogService } from "src/app/shared/dialogs/confirmDialog";
import { BankReconiliationService } from "./bank-reconciliation.service";

export const unitPanel: Panel = new Panel({
    type: 'units',
    icon: 'apartment',
    takeAction(unit: Unit) {
        const dialog = MyInjector.instance.get(MatDialog);
        dialog.open(BankInPageComponent, { data: { unit, item: this.item } }).afterClosed().subscribe( result => {
            console.warn(result);
            if (result) {
                this.recItemsComponent.removeItem(this.item);
                this.reconciliationComponent.showPanel = null;
                MyInjector.instance.get(ConfirmDialogService).open('Remember Action',
                    `Next time there is a transactions with ${this.item.field}
                        equal '${this.item.matches}' should it be automatically applied to unit ${unit.name}`, () => {
                        MyInjector.instance.get(BankReconiliationService).createRule(this.item, unit);
                });
            }
        })
    },
    setTitle() {
        this._title = this._items?.length + ' Units this receipt might be for'
    },
    setConfig() { return new FormConfig({
        fieldSet: new FieldSet({
            fields: [
                FormButtonComponent.makeDialogOpenButton('Unit Name', 'name', 'id', UnitPageComponent),
                FormButtonComponent.makeTableButton('Match', 'match', this.takeAction.bind(this)).override({
                    calculateValue: () => 'Pay'
                }),
                FormTextComponent.make('Owner(s)', 'ownerFullName'),
                FormNumberComponent.make('Invoices', 'invoices'),
                FormNumberComponent.makeCurrency('Outstanding', 'outstanding'),
                FormNumberComponent.makeCurrency('Difference', 'diffAmount'),
                FormPicklistComponent.make('Frequency', 'frequencyId', null, { items: Frequency.ALL_OPTIONS }, { readonly: true }),
                FormNumberComponent.makeCurrency('Expected', 'payment'),
            ],
        }),
        mode: 'list'
    })}
})
