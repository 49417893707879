import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractHttpService } from '../../abstract-http.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { uuid } from 'src/app/model/abstract-object';

export class editItem {
    service: AbstractHttpService;
    id: uuid;
    revision: number;
}
@Component({
  selector: 'app-text-edit',
  standalone: true,
  imports: [MatIconModule, MatButtonModule],
  templateUrl: './text-edit.component.html',
  styleUrl: '../simple-fields.scss'
})
export class TextEditComponent implements OnChanges{

    @Input() label = '';
    @Input() textValue = '';
    @Input() isHtml = false;
    @Output() editor = new EventEmitter<unknown>();
    @Output() deleter: EventEmitter<null>;

    textValueToShow = '';

    ngOnChanges(simpleChanges: SimpleChanges) {
        this.textValueToShow = this.doNl2br(simpleChanges.textValue.currentValue);
    }

    doNl2br(newValue: string) {
        if (newValue) {
            return this.textValue.replace(/\n/g, '<br/>');
        }
        return '';
    }
}
