<div class="mainDiv chatItemContent">
  <mat-stepper [linear]="true" #stepper  (selectionChange)="stepChanged($event)" style="width:390px;">
    <mat-step [stepControl]="omcForm" [completed]="false">
      <h2>OMC You Wish to Access</h2>
      <app-ctl-holder [control]="omcForm.controls.omcId"></app-ctl-holder>
      <app-ctl-holder [control]="omcForm.controls.omcAddress"></app-ctl-holder>
      <app-ctl-holder [control]="omcForm.controls.omcUnknown"></app-ctl-holder>
    </mat-step>
    <mat-step [stepControl]="unitForm">
      <h2>Unit Details</h2>
      @if (!unitAddressRequired) {
        <app-ctl-holder [control]="unitForm.controls.unitId"></app-ctl-holder>
      }
      <app-ctl-holder [control]="unitForm.controls.unitAddress"></app-ctl-holder>
      <app-ctl-holder [control]="unitForm.controls.owner"></app-ctl-holder>
      <app-ctl-holder [control]="unitForm.controls.resident"></app-ctl-holder>
      <app-ctl-holder [control]="unitForm.controls.involvement"></app-ctl-holder>

    </mat-step>

    @if (!unitForm.controls.owner.value) {
      <mat-step [stepControl]="data.controls.nonMemberAcknowledged">
        @if (unitForm.controls.resident.value) {
          <h2>Information for Residents</h2>
          <p>Thank you for registering as a resident of {{omcPicklist().picklist.valueObject?.name}}</p>
          <p>By registering you will be able to receive any information and updates.
            If issues arise in the common areas, you will be able to report them to us and receive follow up.
          </p>
          <p>
            Unfortunately, for everyones security,
            we cannot provide access to the system until the unit owner approves
            your request. We will forward your details to the owner of
            of <b>{{getUnitName()}}</b> for approval.
          </p>
        <p>
            Neither the OMC nor their agents can assist you in relation to your residency of
            <b>{{getUnitName()}}</b> or any internal issues unrelated to the common areas of the development.
        </p>
        } @else {
            <h2>Information</h2>
          <p>
            {{omcPicklist().picklist.valueObject?.name}} is a volunteer run organisation charged with maintaining the common areas of the development.
            Unforunately there is little time for members to respond, or fund an agent to respond, to requests from non members.
            So you may not receive a timely, or any, response.
          </p>
        }
        <p>To be compliant with GDPR,
          the OMC will not be able to discuss or share any details relating to a unit or it's
          ownership, with anyone but the unit owner.
        </p>
        <app-ctl-holder [control]="data.controls.nonMemberAcknowledged"></app-ctl-holder>
      </mat-step>
    }

    <mat-step [stepControl]="personForm">
      <h2>Personal Details</h2>
      <app-ctl-holder [control]="personForm.controls.title"></app-ctl-holder>
      <app-ctl-holder [control]="personForm.controls.firstName"></app-ctl-holder>
      <app-ctl-holder [control]="personForm.controls.lastName"></app-ctl-holder>
      <app-ctl-holder [control]="personForm.controls.phone"></app-ctl-holder>
      <app-ctl-holder [control]="personForm.controls.gdprConsent"></app-ctl-holder>
    </mat-step>
    @if (!unitForm.controls.resident.value && unitForm.controls.owner.value) {
      <mat-step [stepControl]="personAddressField.control">
        <h2>Personal Address</h2>
        <p>As you are an owner, but not resident, we will need you to provide your home postal address for the OMC to use in future correspondence</p>
        <app-ctl-holder [control]="personAddressField.control"></app-ctl-holder>
      </mat-step>
    }

    <mat-step [stepControl]="requestForm">
      <h2>Reason for contacting OMC</h2>
      <app-ctl-holder [control]="requestForm.controls.title"></app-ctl-holder>
      <app-ctl-holder [control]="requestForm.controls.description"></app-ctl-holder>
    </mat-step>
  </mat-stepper>
    <ng-container *ngTemplateOutlet="actions"></ng-container>
</div>

<ng-template #actions>
    <div style="padding-left: 20px">
    @if (currentForm !== omcForm) {
        <button mat-raised-button (click)="stepper.previous()" color="accent">Back</button>
    }
    @if (currentForm !== requestForm) {
        <button mat-raised-button (click)="stepper.next()" [disabled]="!this.currentForm.valid" color="primary">Next</button>
    }
    @if (currentForm === requestForm) {
        <button mat-raised-button (click)="submitRequest()" [disabled]="!this.data.valid" color="primary">Submit</button>
    }
    </div>
</ng-template>
