<h2 class="dialog-banner">Create New Request</h2>
<mat-dialog-content class="mat-typography">
    <app-field-set [fieldSet]="fieldSet" [isPhone]="false" [initComplete]="ready"></app-field-set>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="save()" [disabled]="!fieldSet.formGroup?.valid || !fieldSet.formGroup.dirty"
        color="primary" cdkFocusInitial>Save</button>
</mat-dialog-actions>
