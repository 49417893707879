/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { MyInjector } from "src/app/app.module";
import { EditDialogComponent } from "src/app/shared/dialogs/edit-dialog/edit-dialog.component";
import { RequestService } from "../request.service";
import { ServiceRequest } from "src/app/model/serviceRequest";
import { FormConfig } from "src/app/shared/form/FormConfig";
import { FormTextComponent } from "src/app/shared/form/form-text/form-text.component";
import { BCode } from "src/app/model/bcode";
import { Schedule } from "src/app/model/schedule";
import { FieldSet, LAYOUT_OPTIONS } from "src/app/shared/form/field-set/field-set.component";
import { MatDialog } from "@angular/material/dialog";
import { FieldMaker } from "src/app/shared/field/FieldMaker";

export class RequestParentEditor {
    injector = MyInjector.instance;
    dialog = this.injector.get(MatDialog);
    requestService = this.injector.get(RequestService);
    parentField = ServiceRequest.getParentField();

    bCodes: BCode[] = [];
    schedules: Schedule[] = [];
    sr: ServiceRequest;

    getConfig() {
        return new FormConfig({
            fieldSet: new FieldSet({
                fields: [
                    FieldMaker.id(),
                    FieldMaker.rev(),
                    FormTextComponent.make('Request Title', 'title', {readonly: true}),
                    this.parentField,

                ],
                formLayout: LAYOUT_OPTIONS.simpleDialog
            }),
            mode: 'edit',
            title: $localize`Update Parent`
        })
    }    

    editParent(sr: ServiceRequest) {
        this.sr = sr;
        this.requestService.refreshParents(sr.omcId, this.parentField);       

        return this.dialog.open(EditDialogComponent,
            {
                data: {
                    //teamId: rj.teamId,enable
                    config: this.getConfig(),
                    id: sr.id,
                    service: this.requestService,
                    hideTabs: true,
                    width: 440,
                }
            }
        ).afterClosed()
    }
}