/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { Component } from '@angular/core';
import { RoleHomePage } from '../role-home-page/role-home-page.component';
import { RoleHomePageComponent } from "../role-home-page/role-home-page.component";

@Component({
  selector: 'app-directors-component',
  standalone: true,
  imports: [RoleHomePageComponent],
  templateUrl: './directors.component.html',
  styleUrl: '../home.scss'
})
export class DirectorsHomePageComponent {
    role: RoleHomePage = {
        title: `Directors`,
        mainText: `<p>The directors of an OMC are unusual in that they are all volunteers
        Being the director of an OMC is a thankless job.
        Unlike a film director, you do not rule by dictat.
        You are there to serve your community and lead by example.</p>`,
        lottieUrl: 'https://clgassets.fra1.cdn.digitaloceanspaces.com/lottie-files/directors.json',
        prevPageTitle: 'Owners',
        prevPageRoute: '/owners',
        nextPageTitle: 'Agents',
        nextPageRoute: '/agents',
        beforeText: `<p>
            In the bad old days, directors had no visibility,
            they could not see what was happening in the company they were legally responsible for.
            If they were lucky, an agent would manually produce a report for them monthly,
            or more likely quarterly or even annually.
            The only way to know how things were going was how many of your neighbours were
            shouting at you as you took the groceries out of your car.
            The only way to make a decision was to have yet another meeting
        </p>`,
        afterText: `<p>
            OurOMC gives modern directors modern tools to do their job.
            All information is available at their fingertips.
        </p>`,
        benefitTitle: `Director Benefits`,
        benefits: [{
            icon: 'account_balance',
            head: 'Up To Date Bank Data',
            text: ` We let you see what has come in and what has gone out of your bank account.
                    No more flying blind. The data a director deserves, in near realtime.
                    `,
        },{
            icon: 'visibility',
            head: 'See your own data',
            text: `All of the contact details, phone numbers, email addresses of all owners and residents.
            You can see the requests raised and actions taken. You're a director - You need to know what is going on!
            `,
        },{
            icon: 'database',
            head: 'Own Your Data - OurOMC is the only system that lets you take your data with you!',
            text: `Where is your data? Usually in accounting systems, email systems and document shares owned by your agent.
            With no easy way to give it back to you.
            `,
        },{
            icon: 'shopping_basket',
            head: 'Understand Purchases and Expenditure',
            text: `See spend vs budget. See who is owed.
            See the invoices and approve what is to be paid. See how much is spent with each supplier.
            `,
        },{
            icon: 'sports',
            head: 'Debtors Analysis - Increase Service Charge Compliance',
            text: `
            OurOMC has seen repeatedly, that a quiet word from a director is the number one way to get members to pay their service charges.
            We give you the data to help get the money in.
            `,
        },{
            icon: 'no_meeting_room',
            head: 'Meet less - Do More ',
            text: `There is no need to have a meeting every time a decision is needed.
                Share online, discuss online, vote online. At a time that suits you.
            `,
        }
    ]
    }
}
