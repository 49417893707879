import { of } from "rxjs";
import { MyInjector } from "src/app/app.module";
import { Txn } from "src/app/model/txn";
import { ActionColor, IFormAction } from "src/app/shared/form/form.component";
import { InvoiceService } from "../invoice.service";

export class InvoicePDFAction implements IFormAction {
    name = $localize`Print PDF`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'picture_as_pdf';
    approvalNeeded = false;
    disabled = false;
    approvalText = 'Produce a PDF copy';
    tipText: string;
    txn: Txn;

    invoiceSvc = MyInjector.instance.get(InvoiceService)

    action(txn: Txn) {
        this.invoiceSvc.getPDF(txn);
        return of(this.txn)
    }

    setup(txn: Txn) {
        this.txn = txn;
        this.show = true;
    }
}
