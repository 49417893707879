/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { AbstractObject, uuid } from './abstract-object';
import { Schedule } from './schedule';
import { Unit } from './unit';

export class SchedulePortion extends AbstractObject {
    scheduleId: uuid;
    schedule?: Schedule;
    unitId: uuid;
    unit: Unit;
    portion: number;
    percent: number;
    constructor(data: Partial<SchedulePortion>) {
        super(data);
    }
}
