/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent } from '@angular/material/dialog';
import { Memo } from 'src/app/model/memo';
import { IsNarrowService } from '../../is-narrow.service';
import { MemoField } from "../MemoField";
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { EditorComponent } from '@tinymce/tinymce-angular';

@Component({
    selector: 'app-new-memo',
    templateUrl: './new-memo.component.html',
    styleUrls: ['./new-memo.component.scss'],
    standalone: true,
    imports: [MatDialogContent, EditorComponent, FormsModule, ReactiveFormsModule, MatButtonModule, MatIconModule]
})
export class NewMemoComponent implements OnInit {

    width = 500;
    height = 300;

    noteControl = new FormControl();

    editorOptions = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        height: "300",
        menubar: false,
        relative_urls: false,
        remove_script_host: false,

        plugins: [
            'advlist autolink lists link charmap print preview anchor',
            'searchreplace visualblocks code',
            'insertdatetime paste help'
        ],

        toolbar: 'bold italic link | outdent indent bullist numlist',

        setup: (ed) => {
            ed.on('init', () => {

                this.data.field.control.markAsPristine();
                ed.getBody().style.backgroundColor = 'rgba(243, 242, 241, 1)';
            });
        }
    };

    constructor(@Inject(MAT_DIALOG_DATA) public data: { field: MemoField, memo: Memo },
        private dialogRef: MatDialogRef<NewMemoComponent>, private isNarrowSvc: IsNarrowService) {
        this.isNarrowSvc.resizeObservable$.subscribe(() => this.setSize());
        if (data.memo) {
            this.noteControl.setValue(data.memo.memo);
        }

    }

    ngOnInit(): void {
        this.setSize();
    }

    setSize(): void {
        this.width = this.isNarrowSvc.screenWidth * .75;
        this.height = this.isNarrowSvc.screenHeight * .65;
    }

    saveNote() {
        const fld = this.data.field;

        if (this.data.memo) {
            this.data.memo.memo = this.noteControl.value;
            fld.service.putMemo(this.data.memo).subscribe(updatedMemo => {
                this.data.memo.memo = updatedMemo.memo;
                this.dialogRef.close(updatedMemo);
            })
        } else {
            fld.service.postMemo(this.noteControl.value, fld.relatedId, fld.relatedTeamId).subscribe(newNote => {
                //fld.control.addRow(newNote, true,true);
                this.dialogRef.close(newNote);
            })
        }

    }

    getTitle() {
        return this.data.field.getTitle(this.data.memo);
    }

    closeDialog() {
        console.log('Close Dialog');
        this.dialogRef.close(null);
    }
}
