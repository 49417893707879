<div class="basePage">
  <h1 class="mat-h1" color="primary-font-color">
    Search
  </h1>
  <div style="float:left; margin-left: 20px">
    <p style="font-size: smaller; margin-bottom: 2px; margin-left: 20px">Person Search</p>
    <p style="margin-top: 2px">
      <input [(ngModel)]="searchTermPerson"  (keyup)="handleKeyUp($event)" placeholder="Person Search"
        style="height:1.5em; border-radius: 10px; margin-left: 20px; width: 20em; padding-left: 10px">
    </p>
  </div>
  <div style="float:left; margin-left: 20px">
    <p style="font-size: smaller; margin-top: 35px">and/or</p>
  </div>
  <div style="float:left">
    <p style="font-size: smaller; margin-bottom: 2px; margin-left: 20px">Unit Search</p>
    <p  style="margin-top: 2px">
      <input [(ngModel)]="searchTermUnit"  (keyup)="handleKeyUp($event)" placeholder="Unit Search"
        style="height:1.5em; border-radius: 10px; margin-left: 20px; width: 20em; padding-left: 10px">
    </p>
  </div>
  <div style="float:left; margin-left: 20px; padding-top: 20px;">
    @if (!searching) {
      <button mat-raised-button color="primary" style="margin-left: 20px" (click)="navigateResult()">Search</button>
    }
    @if (searching) {
      <button mat-raised-button color="primary" style="margin-left: 20px" disabled>Searching</button>
    }
  </div>
  @if (config.mode === 'list') {
    <app-table [config]="config" [drilldown]="true"></app-table>
  }
  <p  class="mat-body" style="font-weight: lighter;">Tips:</p>
  <ul class="mat-body"  style="font-weight: lighter;">
    <li>Search for whole words, results matching <b>any of the words</b> will be returned, e.g. searching "this that" will return results containing either "this" or "that"</li>
    <li>If you wanted result to contain all words, use + symbol,<b>e.g. "+this +that" only returns results containing both this and that</b>
  </li>
  <li>To search unit 5 in OurStreet, search +5 +OurStreet, note that if the number 5 appears in the notes etc., then that unit will also be returned, e.g. if OurStreet was in Dublin 5, then all units would contain a 5 and be returned</li>
  <li>Search words in person field are matched against person first name, last name, title, phone email, notes and personal address (if not resident at the unit)</li>
  <li>Search words in the unit field are matched against the unit name, address, postcode, notes and car spaces</li>
  <li>If you enter both fields, the search will only show records that match both the person and the unit search criteria</li>
  <li>To search part of a word, use * to match any characters, e.g. Wor* to match any word starting with "wir"</li>
</ul>
</div>
