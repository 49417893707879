/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { inject, Injectable} from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class OurAppTrackerService {

    private router = inject(Router);

    private events: string[] = [];

    constructor() {
        console.log('Setup tracker Service');
        this.setUp();
    }

    getUrl(evt: NavigationStart|NavigationEnd) {
        return '['+evt.url+']';
    }

    setUp() {
        this.router.events.subscribe( (evt) => {
            if (evt instanceof NavigationStart) {
                this.events.unshift('Navigating to ' + this.getUrl(evt));
            }
            if (evt instanceof NavigationEnd) {
                if (this.events[0].indexOf(this.getUrl(evt)) > 0) {
                    this.events[0] += ' SUCCEEDED';
                } else {
                    this.events.unshift('Navigated to ' + this.getUrl(evt));
                }
            }
        });
    }

    logAction(action)  {
        this.events.unshift(action);
        console.log(this.events);
    }
    private saveMsg(title) {
        return 'Saving ' + title;
    }

    startSave(title) {
        this.events.unshift(this.saveMsg(title))
    }
    completeSave(title, success: boolean) {
        if (this.events[0] === this.saveMsg(title)) {
            this.events[0] === this.saveMsg(title) + success ? ' SUCCEEDED' : ' FAILED';
        } else {
            this.events.unshift(this.saveMsg(title) + success ? ' SUCCEEDED' : ' FAILED');
        }
    }

    printEvents() {
        return "<br/>\n<br/>" + this.events.join("<br/>\n");
    }
}
