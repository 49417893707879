
/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { ChartHolder } from '../chart-holder';
import { ChartHolderComponent } from '../chart-holder/chart-holder.component';

@Component({
    selector: 'app-chart-analysis',
    templateUrl: './chart-analysis.component.html',
    styleUrls: ['./chart-analysis.component.scss'],
    standalone: true,
    imports: [ChartHolderComponent]
})
export class ChartAnalysisComponent implements OnInit {

    @Input() config: FormConfig;

    resizeObservable$: Observable<Event>;
    resizeSubscription$: Subscription;

    width = 1000;

    constructor(private myElement: ElementRef) {
        this.resizeObservable$ = fromEvent(window, 'resize');
        this.resizeSubscription$ = this.resizeObservable$.subscribe(() => {
            this.setWidth();
        });
    }

    ngOnInit(): void {
        this.setWidth();
    }

    private setWidth() {
        const e = this.myElement.nativeElement;
        const formElement = e.closest('div[formPageHolder]');
        this.width = formElement?.getBoundingClientRect().width;
    }

    getChartWidth(ch: ChartHolder) {
        const totalUnits = this.config.charts.getWidth();
        const unitSize = this.width / totalUnits;
        return Math.round(unitSize * ch.getWidth()); // will round bite me for a pixel?
    }
/*
    getMiddlePadding() {
        const availWidth = this.width - 40 - this.config.charts.chLeft.getWidth() - this.config.charts.chRight.getWidth();
        return (availWidth - this.config.charts.chMiddle.getWidth()) /2;
    }
    */
}
