/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, HostBinding, OnInit, OnDestroy, ViewChild, inject } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { NavItem } from 'src/app/model/sys/nav-item';
import { CurrentUserService } from 'src/app/modules/user/current-user.service';
import { NavigationService } from 'src/app/shared/navigation/navigation.service';
import { User } from 'src/app/model/user';
import { Router, ActivatedRoute, RouterOutlet, RouterLink, ChildrenOutletContexts } from '@angular/router';
import { IsNarrowService } from '../is-narrow.service';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { uuid } from 'src/app/model/abstract-object';
import { NavItemComponent } from './nav-item/nav-item.component';
import { MatListModule } from '@angular/material/list';
import { NewButtonComponent } from '../new-button/new-button.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { UserNavButtonComponentComponent } from '../user-nav-button-component/user-nav-button-component.component';
import { LogoComponent } from '../logo/logo.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PersonUnitRole } from 'src/app/model/person-unit-role';
import { slideInAnimation } from '../std-animation';

const THEME_DARKNESS_SUFFIX = `-dark`;

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    standalone: true,
    imports: [MatSidenavModule, MatToolbarModule, LogoComponent, UserNavButtonComponentComponent,
        RouterOutlet, NgTemplateOutlet, MatButtonModule, MatIconModule, MatTooltipModule,
        MatMenuModule, MatCheckboxModule, MatGridListModule, NewButtonComponent, MatListModule,
        NavItemComponent, RouterLink, NgClass],
    animations: [slideInAnimation]
})
export class NavigationComponent implements OnInit, OnDestroy {

    @HostBinding('class') activeThemeCssClass: string;

    @ViewChild('drawer') drawer: MatSidenav;

    private contexts = inject(ChildrenOutletContexts)

    defaultDarkness = false;
    defaultTheme = 'traffic-light';

    isThemeDark = this.defaultDarkness;
    activeTheme = this.defaultTheme;

    isWide = false;
    isPhone = false;

    showMenuText = true;
    menuWidth = 230;
    marginWidth = 230;

    navigateMode: 'user' | 'anonymous' | 'unknown' = 'unknown';

    themes: string[] = [
        'traffic-light',
        'indigo-pink',
        'deeppurple-amber',
        'pink-bluegrey',
        'purple-green',
        'brown-grey',
    ];
    navItems: NavItem[];
    selectedNavItem: NavItem;
    currentUser: User;
    currentTeam: uuid;
    currentTeamName: string;
    //teams: Team[];
    activePositions: PersonUnitRole[];

    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
        .pipe(
            map(result => result.matches),
            shareReplay()
        );

    private subscribeUser: Subscription;

    inBeta = false;

    constructor(private breakpointObserver: BreakpointObserver,
        private currentUserService: CurrentUserService,
        private overlayContainer: OverlayContainer,
        private currentRoute: ActivatedRoute,
        private router: Router,
        private navigationService: NavigationService,
        public isNarrowService: IsNarrowService) {
        this.setTheme();
        isNarrowService.detectNarrow().subscribe(result => {
            this.isWide = !result;
            this.setMenuStyle();
        });
        isNarrowService.detectVeryNarrow().subscribe(result => {
            this.isPhone = result;
            this.setMenuStyle();
        });
        this.navigationService.navComponent = this;

        if (document.location.host.includes('localhost') || document.location.host.toLowerCase().includes('devdo')) {
            this.inBeta = true;
        }
    }

    getRouteAnimationData() {
        const animation = this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
        return animation;
    }

    checkChildren(u: User, ni: NavItem) {
        if (ni.children) {
            this.checkPermissions(u, ni.children);
            const visibleChildren = ni.children.filter(nic => nic.visible);
            if (visibleChildren.length === 0) {
                delete ni.children;
                if (!ni.url) {
                    ni.visible = false;
                }
            }
        }
    }
    checkPermissions(u: User, nis: NavItem[]) {
        let header: NavItem;
        let headerCnt = 0;
        for (const ni of nis) {

            this.checkChildren(u, ni);
            if (ni.model) {
                const p = User.privilege(u, ni.model);
                if (p.getIndex || (ni.showToPoster && p.post)) {
                    ni.visible = true;
                    headerCnt++
                } else {
                    ni.visible = false;
                }
            } else {
                if (header && headerCnt === 0 && !header.children) {
                    header.visible = false;
                }
                header = ni;
                ni.visible = true;
                headerCnt = 0;
            }
            if (ni.isAdmin && !u.currentRole?.isAdmin) {
                ni.visible = false; // Hide admin only headers, even if children might otherwise be visible
            }
        }
        if (header && !header.url && !header.children && headerCnt === 0) {
            header.visible = false;
        }
        return nis;
    }

    ngOnInit(): void {
        this.navigateMode = 'anonymous';
        this.navItems = this.navigationService.anonymousItems();
        this.subscribeUser = this.currentUserService.getCurrentUser().subscribe(currentUser => {
            this.currentUser = currentUser;
            this.activePositions = currentUser?.activePositions;
            if (currentUser) {
                this.navigateMode = 'user';
                if (currentUser.currentTeam) {
                    this.currentTeam = currentUser.current_team_id;
                    this.currentTeamName = currentUser.currentTeam?.name;
                    this.navigationService.setItems(currentUser);
                    const nis = this.navigationService.getItems();
                    this.checkPermissions(currentUser, nis);
                    this.navItems = nis;
                }
                this.setTheme();
            }
        });
        this.currentUserService.httpGetCurrentUser(this.router.routerState.snapshot.url);
    }

    ngOnDestroy() {
        this.subscribeUser.unsubscribe();
    }

    selectOption(niOpen: NavItem) {
        // Highlight selected option
        this.selectedNavItem = niOpen;
        // If menu was opened with burger menu (on phone or tablet) close it when navigating
        if (!this.isWide && niOpen.url) {
            this.drawerToggle();
        }
    }

    expandOption(niOpen: NavItem) {
        this.navItems.forEach(ni => {
            if (ni === niOpen) {
                ni.expanded = true;
            } else {
                ni.expanded = false;
            }
        })
    }

    setDarkness(darkParm: boolean) {
        if (!this.currentUser) {
            this.isThemeDark = this.defaultDarkness;
            return;
        }
        if (darkParm === null) {
            const darkStored = localStorage.getItem('darkTheme');
            if (darkStored === null) {
                this.isThemeDark = this.defaultDarkness;
            } else {
                this.isThemeDark = (darkStored === 'true' ? true : false);
            }
        } else {
            localStorage.setItem('darkTheme', darkParm ? 'true' : 'false');
            this.isThemeDark = darkParm;
        }
    }

    setActiveTheme(theme: string) {
        if (!this.currentUser) {
            this.activeTheme = this.defaultTheme;
            return;
        }
        if (theme === null) {
            theme = localStorage.getItem('themeName');
            if (theme === null) {
                theme = this.defaultTheme;
            }
        } else {
            localStorage.setItem('themeName', theme);
        }
        this.activeTheme = theme;
    }

    drawerToggle() {
        this.menuWidth = 230;
        this.drawer.toggle().then((sidenavIsOpen) => {
            if (sidenavIsOpen === 'open') {
                this.menuWidth = 230
                this.marginWidth = 0;
            }
        });
    }
    closeDrawer() {
        this.menuWidth = 0;
    }

    setMenuStyle() {
        const menuWidth = window.localStorage.getItem('navigationMenu');
        if (menuWidth === 'icon' && this.isWide) {
            this.iconMenu();
        } else {
            this.textMenu();
        }

    }

    textMenu() {
        this.showMenuText = true;
        this.menuWidth = this.isWide ? 230 : 0;
        this.marginWidth = this.menuWidth;
        window.localStorage.setItem('navigationMenu', 'text');
    }

    iconMenu() {
        this.showMenuText = false;
        this.menuWidth = this.isWide ? 60 : 0;
        this.marginWidth = this.menuWidth;
        window.localStorage.setItem('navigationMenu', 'icon');
    }

    setTheme(themeParm: string = null, darkParm: boolean = null) {

        this.setDarkness(darkParm);
        this.setActiveTheme(themeParm);

        const cssClass = (this.isThemeDark === true ? this.activeTheme + THEME_DARKNESS_SUFFIX : this.activeTheme);

        const classList = this.overlayContainer.getContainerElement().classList;
        if (classList.contains(this.activeThemeCssClass)) {
            classList.replace(this.activeThemeCssClass, cssClass);
        } else {
            classList.add(cssClass);
        }

        this.activeThemeCssClass = cssClass;
    }
    getDarkIndicator() {
        return this.isThemeDark ? 'darkTheme' : 'lightTheme';
    }

    toggleDarkness() {
        this.setTheme(this.activeTheme, !this.isThemeDark);
    }

}
