/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Input } from '@angular/core';
import { ButtonField } from '../../field/ButtonField';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AbstractObject } from 'src/app/model/abstract-object';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-icon-table-cell',
  standalone: true,
  imports: [MatIconModule, MatTooltipModule, MatButtonModule],
  templateUrl: './icon-table-cell.component.html',
  styleUrl: './icon-table-cell.component.scss'
})
export class IconTableCellComponent {
    @Input({ required: true }) field: ButtonField;
    @Input({ required: true }) focusItem: AbstractObject;
}
