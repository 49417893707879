<p i18n> Use this option to make a single payment,
    for an amount of your choosing which will be creditted against the account of unit {{unit.name}} for which there is
    currently {{unit.outstanding | currency}} outstanding</p>

<mat-card-subtitle i18n>When you click the button, you will be brought to our payment partners site (stripe)
    Where you can enter your bank account details. You will need an IBAN number, name and address details for the
    account holder. A request for the funds will go directly to your bank. Usually for Irish bank it will show up within
    a day, but potentially it can take up to a few days before the request reaches your bank. After the funds leave your
    account, it can also potentially take several days before your account balance gets updated on OurOMC. You will
    however be able to come back to this page to see the latest status of the request.
    You can choose if you want your bank details to be stored to make future payments more easily. If you choose to do
    so, the information will be stored securely on stripe servers. OurOMC will never hold your payment details.
</mat-card-subtitle>
<table width="100%" style="margin-top: 20px; margin-bottom: 40px">
    <tr>
        <td>
            <app-form-number [control]="otherPayCtl"></app-form-number>
        </td>
    </tr>
    <tr>
        <td width="100%" style="text-align: center;">
            <button mat-raised-button color="primary" i18n (click) = "partPay()">Pay Now</button>
        </td>
    </tr>

</table>
