<div [style.width.px]="width" style="margin-left:auto; margin-right:auto">
    @if (dialogParms) {
        <div class="dialogTitleBar primary-background-color ">
            <span i18n="ImageReport">{{titleToShow}}</span>
        </div>
    } @else if (noHeader === false) {
        <h1 class="mat-h1" style="text-align: center">Create Quick Request</h1>
    }
  <div style="padding-left: 10px; padding-right: 10px">
    <div  class="preview" style="margin-top: 10px; padding-left: 20px; padding-right: 20px">
      <img #previewImgTag style="height:auto; width:auto;"
        [style.max-height.px]="width-60" [style.max-width.px]="width - 60" (click)="changeImage()"/>
    </div>
    <input type="file" #fileUploadTag style="display:none;" accept="image/*" (change)="previewImage($event)"/>
    @if (imageChosen) {
      <p>
        <span i18n="previewOnlyScaled" class="mat-body" style="font-size: smaller; font-weight: lighter;">
          Click to change image,
          @if (originalSize === uploadSize) {
            <span>current file size {{ originalSize | byteCount}} </span>
          }
          @if (originalSize > uploadSize) {
            <span>
              File compressed from {{ originalSize | byteCount}} to {{uploadSize | byteCount}}
            </span>
          }
        </span>
      </p>
    }
    <img id="resized-image">
    <div style="text-align: center; padding-bottom: 10px; padding-right: 10px; margin-top: 10px; margin-bottom: 10px">
      @if (!imageChosen) {
        <button mat-button mat-raised-button color="primary" (click)="changeImage();" i18n>
            @if (lastSR) {
                Add another picture to request {{lastSR.refNr}}
            } @else {
                Upload Picture
            }
        </button>
        @if (lastSR && noHeader) {
            <button mat-raised-button style="margin-top:20px" color="primary"
                routerLink="/myRequests/{{lastSR.id}}" i18n>View Request {{lastSR.refNr}}</button>
        }
      }
      @if (imageChosen && lastSR) {
        <button color="primary" mat-raised-button [disabled]="uploadRunning"
          (click)="addImage()">
          @if (!uploadRunning) {
            <span>Add To Request {{lastSR.refNr}} ({{lastSR.title}})</span>
          }
          @if (uploadRunning) {
            <span>Uploading, please wait...</span>
          }
        </button>
      }
    </div>
    @if(imageChosen && !lastSR) {
        @if(cUserSvc.currentUser.inAgency) {
            <app-ctl-holder [control]="omcField.control"></app-ctl-holder>
        } @else {
            <app-ctl-holder [control]="agentField.control"></app-ctl-holder>
        }
    }
    @if (imageChosen && (allowCreateChild || !lastSR)) {
      <app-ctl-holder [control]="titleField.control"></app-ctl-holder>
      <app-ctl-holder [control]="descField.control"></app-ctl-holder>
    }
  </div>
  <div style="text-align: center; padding-bottom: 10px; padding-right: 10px; margin-top: 10px; margin-bottom: 10px">
    @if(dialogParms) {
        <button color="accent" mat-raised-button (click)="cancel()">Close</button>
    }

    @if (imageChosen && allowCreateChild) {
      <button color="primary" mat-raised-button [disabled]="!isSaveable()"
        (click)="saveRequest()">
        @if (!uploadRunning) {
          <span>Create New Request</span>
        }
        @if (uploadRunning) {
          <span>Uploading, please wait</span>
        }
      </button>
    }
  </div>
</div>
