/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class IsNarrowService {

    resizeObservable$: Observable<Event>;
    resizeSubscription$: Subscription;

    screenWidth: number = window.innerWidth;
    screenHeight: number = window.innerHeight;
    remainingHeight: number

    constructor(private breakpointObserver: BreakpointObserver) {
        this.resizeObservable$ = fromEvent(window, 'resize');
        this.resizeSubscription$ = this.resizeObservable$.subscribe(() => {
            this.screenWidth = window.innerWidth;
            this.screenHeight = window.innerHeight;
        });
    }

    isPhone() {
        return this.screenWidth <= 599 ? true : false;
    }
    
    isTablet() {
        return (this.screenWidth > 599 && this.screenWidth < 1239) ? true : false;
    }

    detectNarrow() {
        return this.breakpointObserver.observe('(max-width: 1239px)')
            .pipe(
                map(result => result.matches),
                shareReplay()
            );
    }

    detectVeryNarrow() {
        return this.breakpointObserver.observe('(max-width: 599px)')
            .pipe(
                map(result => result.matches),
                shareReplay()
            );
    }
}
