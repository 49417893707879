@if (formControl.invalid && formControl.touched) {
  <!-- https://stackoverflow.com/questions/53684763/how-to-remove-space-bottom-mat-form-field
  Can remove space, but it is used for hints...-->
  <!-- span *ngFor="let error of formControl.errors | keyvalue" style="color: red">
  {{showErrorMsg(error)}}.
  </span -->
  <span style="color: red" [matTooltip]="tipMsg()">
    {{shortMsg()}}.
  </span>
}
