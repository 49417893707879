/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Field } from 'src/app/shared/field/Field';
import { CurrentUserService } from 'src/app/modules/user/current-user.service';
import { ConfirmDialogService } from '../dialogs/confirmDialog';
import { ControlOn } from '../form/app-form-control';
import { FormControlInterface } from '../form/form-control-interface';
import { GridControl } from '../grid/grid-control';
import { ActionGridField, LinksTo } from './action-grid-field';
import { GridComponent } from '../grid/grid.component';

@Component({
    selector: 'app-action-grid',
    templateUrl: './action-grid.component.html',
    styleUrls: ['./action-grid.component.scss'],
    standalone: true,
    imports: [GridComponent]
})
export class ActionGridComponent implements OnInit, FormControlInterface {
    @Input() control: GridControl;
    @Input() on: ControlOn = 'form';
    actionGridField: ActionGridField;

    constructor(private cds: ConfirmDialogService, currentUserSvc: CurrentUserService,
        private dialog: MatDialog) {

    }

    ngOnInit(): void {
        this.actionGridField.dialog = this.dialog;
        this.actionGridField.cds = this.cds;
    }

    public static createComponent(vcr: ViewContainerRef, ctl: GridControl, on: ControlOn) {
        const componentRef = vcr.createComponent(ActionGridComponent);
        componentRef.instance.control = ctl;
        componentRef.instance.on = on;
        componentRef.instance.actionGridField = ctl.field as ActionGridField;
    }

    public static make(linkedTo: LinksTo, options: Partial<ActionGridField> = {}) {

        const actionGridField: ActionGridField = new ActionGridField(options);
        actionGridField.formControlFactory = ActionGridComponent.createComponent;
        actionGridField.linksTo = linkedTo;

        return actionGridField;
    }
}
