<mat-dialog-content width="100%">
    <table width="100%">
        <tr>
            <th colspan="99">
                <div style="display:flex; align-items: center">
                    <p>{{forum?.name}} Profile List</p>
                    <div class="input">
                        <p class="label">Privilege</p>
                        <p>
                            <select [(ngModel)]="selectedPriv" (ngModelChange)="changePriv()">
                                @for (p of forumPrivs; track $index) {
                                <option [ngValue]="p">{{p.name}}</option>
                                }
                            </select>
                        </p>
                    </div>
                    <div class="input">
                        <p class="label">Area</p>
                        <p>
                            <select [(ngModel)]="selectedArea" (ngModelChange)="changeArea()">
                                <option [value]="null"></option>
                                @for (a of areas; track $index) {
                                <option [ngValue]="a">{{a.name}}</option>
                                }
                            </select>
                        </p>
                    </div>
                    <div class="input">
                        <p class="label">Core</p>
                        <p>
                            <select [(ngModel)]="selectedCore" (ngModelChange)="changeCore()">
                                <option [value]="null"></option>
                                @for (c of selectedArea?.cores; track $index) {
                                <option [ngValue]="c">{{c.name}}</option>
                                }
                            </select>
                        </p>
                    </div>
                    <div><p class="label">Matches</p>
                        <p>{{people?.length}}</p>
                    </div>
                </div>


            </th>
        </tr>
        <tr>
            <th>
                Name
            </th>
            <th>
                Email
            </th>
            <th>
                Role
            </th>
            <th>
                Unit
            </th>
            <th>
                Area
            </th>
        </tr>
        @for (person of people; track $index) {
            @if ($index < 50) {
                <tr>
                    <td>
                        {{fullName(person)}}
                    </td>
                    <td>
                        {{person.email}}
                    </td>
                    <td>
                        {{person['roleName']}}
                    </td>
                    <td>
                        {{person['unitName']}}
                    </td>
                    <!-- td>
                        {{person['areaName']}}
                    </td -->
                    <!-- td>
                        {{person['agencyName']}}
                    </td -->
                </tr>
            }
        }
        @if (people.length > 50) {
            <tr>
                <td colspan="99" style="text-align: center;">only showing first 50...</td>
            </tr>
        }
    </table>
</mat-dialog-content>
