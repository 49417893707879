@if (on === 'form' && !control?.field.asRadio) {
  <mat-form-field
    style="height: 74px;">
    @if (control.field.hint) {
      <mat-icon matSuffix color="primary" [matTooltip]="control.field.hint">info</mat-icon>
    }
    <mat-label>{{control.field.label}}</mat-label>
    @if (!control.field.readonly) {
      <div>
        <mat-select [attr.data-field]="control.field.name" [formControl]="control" style="width:calc(100% - 9px)"
          (selectionChange)="itemChanges($event)" [compareWith]="matchById">
          @for (item of this.control.field.picklist.items; track item) {
            <mat-option id='{{control.field.name}}-{{this.control.field.picklist.optionDisplayValue(item)}}'
              [value]="this.control.field.picklist.optionValue(item)"
              >
              {{this.control.field.picklist.optionDisplayValue(item)}}
            </mat-option>
          }
        </mat-select>
        @if (this.control.field.picklist.detailView && this.control.value) {
          <div style="float:right"
            matTooltip="Click to see details" (click)="viewDetails($event)">
            <mat-icon color="primary" style="margin-right: -15px">chevron_right</mat-icon>
          </div>
        }
      </div>
    }
    <!-- mat-hint [id]="null" *ngIf="!control.field.readonly">{{control.field.hint}}</mat-hint -->
    @if (control.field.readonly) {
      <input matInput [value]="this.control.field.getDisplayValueFromId(control.value)" [disabled]="control.field.disable"
        style="padding-top: 3px; padding-bottom: 3px" [matTooltip]="control.field.hint" readonly>
    }
    <mat-error style="margin-top: -5px">
      <app-form-error [(control)]="control"></app-form-error>
    </mat-error>
  </mat-form-field>
}
<!-- div style="font-size: 75%; margin-top: -18px; padding-left: 18px; margin-bottom: 4px; font-weight: 300; min-height: 1.2em;"
*ngIf="on === 'form'">
<span *ngIf="on === 'form' && !control.field.readonly && control.field.hint">{{control.field.hint}}</span>
</div -->
@if (on === 'grid' && !control.field.readonly) {
  <mat-select style="font-size: 14px"
    [ngClass]="{'error': control.invalid && control.touched}"
    [matTooltip]="control.getErrorMessage()"
    [formControl]="control" [compareWith]="matchById" (selectionChange)="itemChanges($event)">
    @for (item of this.control.field.picklist.items; track item) {
      <mat-option [value]="this.control.field.picklist.optionValue(item)"
        >
      {{this.control.field.picklist.optionDisplayValue(item)}}</mat-option>
    }
  </mat-select>
}
@if (on === 'grid' && control.field.readonly) {
  <span>{{control.field.getDisplayValueFromId(control.value)}}</span>
}
<!-- input *ngIf="on === 'grid' && control.field.readonly" matInput
[value]="control.value ? this.control.field.getDisplayValueFromId(control.value) : ''"
[matTooltip]="control.field.hint" readonly -->
