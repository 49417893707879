/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { AbstractObject, uuid } from './abstract-object';
import { Attachment } from './attachment';
import { Forum } from './forum';

export class Document extends AbstractObject {
  title: string;
  content: string;
  forumId: uuid;
  forum: Forum;
  areaId: uuid;
  areaName: string;
  coreId: uuid;
  coreName: string;

  attachments: Attachment[] = [];
  childPages: Document[] = [];
  parentId: uuid;
  parent: Document;

  // Counts returned from server side... not sure they are numbers, remove comment if confirmed...
  child_docs_count?: number;
  attachments_count?: number;

  constructor(o: Partial<Document> = {}) {
    super(o);
  }
}
