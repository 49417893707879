<div class="basePage">
  <div class="registerCard">
    <mat-card appearance="raised">
      <mat-card-header>
        <mat-card-title>Signup for your free trial of OurOMC</mat-card-title>
        @if (!screenSize.isPhone()) {
          <mat-card-subtitle>The online collaboration platform for Owners, Residents, Directors and Agents</mat-card-subtitle>
        }
      </mat-card-header>
      <mat-card-content style="margin-top: 20px">
        <div class="tickboxes"> <!--  *ngIf="!screenSize.isPhone()" -->
          <div style="flex:1;"><mat-icon>check_circle</mat-icon>No Credit Card Required</div>
          <div style="flex:1; vertical-align: middle;"><mat-icon>check_circle</mat-icon>Cancel Anytime</div>
          <div style="flex:1;"><mat-icon>check_circle</mat-icon>Invite Members</div>
        </div>
        <app-field-set [fieldSet]="fs" [isPhone]="screenSize.isPhone()" [initComplete]="true"></app-field-set>
      </mat-card-content>
      <mat-card-footer style="margin-left: 20px; margin-bottom: 20px; margin-right: 20px">
        <div style="display:flex">
          <div style="flex: 1"></div>
          <div style="flex: 1" matTooltip="You will be required to accept our terms of service before you start using the software">
            <button style="width:100%" mat-raised-button [disabled]="!fs.formGroup?.valid" color="primary">Sign Me Up</button>
          </div>
          <div style="flex: 1"></div>
        </div>
      </mat-card-footer>
    </mat-card>
  </div>
</div>
