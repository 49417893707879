<app-dialog-banner text="eMail / Print Service Charges" [dialogRef]="dialogRef"></app-dialog-banner>

<mat-dialog-content style="padding-left: 10px; padding-right: 10px">
    <div style="display:flex; width: 840px">
        <div style="flex:1">
            <app-ctl-holder [control]="nameField.control"></app-ctl-holder>
            <app-ctl-holder [control]="mailText.control"></app-ctl-holder>
        </div>
        <div style="width: 160px; align-content: center;">
            <div>
                <p style="font-size: small; line-height: 1em; margin: 20px 0px 0px 0px">Where to send emails</p>
                <mat-radio-group aria-label="Select an option" style="font-size: small;"
                color="primary" [formControl]="mergeDestination">
                    <mat-radio-button value="Email" [matTooltip]="ownerTip">Send Emails</mat-radio-button>
                    <mat-radio-button value="NoEmailPDF" [disabled]="true" [matTooltip]="noEmailToMeTip">PDF for no email</mat-radio-button>
                    <mat-radio-button value="PDF" [matTooltip]="userTip">All items in PDF</mat-radio-button>
                </mat-radio-group>
                <p style="margin: 20px 0px 0px 0px">Target</p>
                <p style="font-size: small; line-height: 1em; margin: 0px"></p>
                <mat-slider min="0" max="1" step=".01" style="width: calc(100% - 20px)">
                    <input [formControl]="minPaid" matSliderStartThumb>
                    <input [formControl]="maxPaid" matSliderEndThumb>
                </mat-slider>
                <p style="font-size: small; line-height: 1em; margin: 0px 0px 0px 0px">
                    {{target.value}}
                </p>
                <button mat-raised-button color="primary" class="edit"
                    (click)="saveChanges()" style="margin:20px 10px"
                    [disabled]="!mailText.control.valid || !nameField.control.valid">
                    @if (this.formGroup.dirty) {
                        Save
                    } @else {
                        Submit
                    }

                </button>

            </div>
        </div>
    </div>
</mat-dialog-content>
