/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { User } from 'src/app/model/user';
import { CurrentUserService } from 'src/app/modules/user/current-user.service';
import { NewButtonService, NewButtonState } from './new-button.service';
import { AsyncPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-new-button',
  templateUrl: './new-button.component.html',
  styleUrls: ['./new-button.component.scss'],
  standalone: true,
  imports: [MatButtonModule, RouterLink, MatIconModule, MatMenuModule, AsyncPipe]
})
export class NewButtonComponent implements OnInit {

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  state: NewButtonState; // = {actionName: '', link: '', showButton: false};
  ready = false;
  routerLink: unknown[];

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(result => result.matches),
    shareReplay()
  );

  currentUser: User;

  constructor(private breakpointObserver: BreakpointObserver,
    private currentUserService: CurrentUserService,
    private newButtonService: NewButtonService) { }

  ngOnInit(): void {
    this.currentUserService.getCurrentUser().subscribe(user => { this.currentUser = user; });
    this.newButtonService.buttonUpdates().subscribe(state => {
      this.ready = true;

      window.setTimeout(() => {
        this.state = state;
        this.routerLink = [state.link, state.actionParms];
      }, 200); // delay to avoid ExpressionChangedAfterCheckChangesError...
    });
  }

  clicked() {
    console.log('clicked');
  }

}
