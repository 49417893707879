import { HttpClient } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Person } from 'src/app/model/person';
import { PersonUnitRole } from 'src/app/model/person-unit-role';
import { Role } from 'src/app/model/role';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { Field } from 'src/app/shared/field/Field';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { FieldSet, FieldSetComponent } from 'src/app/shared/form/field-set/field-set.component';
import { FormEmailComponent } from 'src/app/shared/form/form-email/form-email.component';
import { FormPhoneComponent } from 'src/app/shared/form/form-phone/form-phone.component';
import { FormTextAreaComponent } from 'src/app/shared/form/form-text-area/form-text-area.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { MessageService } from 'src/app/shared/message.service';
import { required } from 'src/app/shared/validators';

@Component({
  selector: 'app-edit-person',
  standalone: true,
  imports: [MatDialogModule, FieldSetComponent, MatButtonModule],
  templateUrl: './edit-person.component.html',
  styleUrl: './edit-person.component.scss'
})
export class EditPersonComponent {
    dialogRef = inject(MatDialogRef<EditPersonComponent>);
    pur: PersonUnitRole = inject(MAT_DIALOG_DATA);
    http = inject(HttpClient);
    msgSvc = inject (MessageService);

    ready = false;
    fieldSet = new FieldSet();
    fields : Field[] = [FieldMaker.id(), FieldMaker.idHolder('unitId'), FieldMaker.rev()];

    constructor() {
        const gri = this.pur.role.globalRoleId;
        const email = FormEmailComponent.make('Email', 'email', { disable: true });

        if (gri !== Role.ROLE.OWNER.id && gri !== Role.ROLE.OWNER_OCCUPIER.id && !this.pur.person.userId) {
            this.fields.push(Person.getTitleField());
            this.fields.push(FormTextComponent.make('First Name', 'firstName'));
            this.fields.push(FormTextComponent.make('Last Name', 'lastName', { validators: [required] }));
        } else {
            this.fields.push(FormTextComponent.make('Name', 'fullName', { disable: true }));
        }
        if (!this.pur.person.userId) {
            email.disable = false;
        }

        this.fields.push(email);
        this.fields.push(FormPhoneComponent.make('Telephone', 'phone'));

        if (gri === Role.ROLE.OWNER.id) {
            this.fields.push(FormTextAreaComponent.make('Address', 'address', { validators: [required] }));
        }

        this.pur.person.address = this.replaceAll(this.pur.person.address, '<br/>', String.fromCharCode(10));
        this.fieldSet.setFields(this.fields);
        this.pur.person['unitId'] = this.pur.unitId;
        this.fieldSet.setValue(this.pur.person);
        this.ready = true;
    }

    replaceAll(str, find, replace) {
        if (str) {
            return str.replace(new RegExp(find, 'g'), replace);
        }
        return str;
    }
    savePerson() {
        const url = AbstractHttpService.ajaxPath + `/units/myUnits/${this.pur.unitId}/person`
        this.http.put(url, this.fieldSet.getFormValue()).subscribe ( p => {
            this.dialogRef.close(p);
        }, error => this.msgSvc.showError(error, 'Editting Contact Details'))
    }
}
