import { Observable, of } from "rxjs";
import { ActionColor, IFormAction } from "../form/form.component";
import { ActionGridField } from "./action-grid-field";
import { FieldMaker } from "../field/FieldMaker";
import { CurrentUserService } from "src/app/modules/user/current-user.service";
import { MyInjector } from "src/app/app.module";
import { Field } from "../field/Field";
import { PicklistField } from "../field/PicklistField";
import { FormPicklistComponent } from "../form/form-picklist/form-picklist.component";
import { OmcAgentService } from "src/app/modules/user/omc-agent-service";
import { OmcAgent } from "src/app/model/OmcAgent";
import { Omc } from 'src/app/model/Omc';
import { maxChars, minChars, required } from "../validators";
import { OmcService } from "src/app/modules/agent/omc.service";
import { FormTextComponent } from "../form/form-text/form-text.component";
import { uuid } from "src/app/model/abstract-object";
import { Person } from "src/app/model/person";
import { Action } from "src/app/model/action";
import { FieldSet } from "../form/field-set/field-set.component";
import { FormConfig } from "../form/FormConfig";
import { EditDialogComponent } from "../dialogs/edit-dialog/edit-dialog.component";
import { ActionService } from "src/app/modules/crm/action.service";
import { MatDialog } from "@angular/material/dialog";
import { FormRichTextComponent } from "../form/form-rich-text/form-rich-text.component";

/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
export class SendEmailFormAction implements IFormAction {
    name = $localize`Send Email`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'forward_to_inbox';
    approvalNeeded = false;
    disabled = false;
    approvalText = '';
    tipText: string;

    currentUser = MyInjector.instance.get(CurrentUserService).currentUser;
    actionService = MyInjector.instance.get(ActionService);
    dialog = MyInjector.instance.get(MatDialog);

    omcAgents: OmcAgent[] = [];
    omcs: Omc[] = [];

    recipient: string;

    teamId: uuid;
    personId: uuid;

    //constructor(private addFn: () => Observable<AbstractObject>, private linksTo: LinksTo) { }
    constructor(private actionGrid: ActionGridField) { }

    action() { // config: FormConfig
        this.openSendMailDialog();
        return of(null);
    }

    setup(item: Person) {
        if (!this.currentUser.inAgency) {
            MyInjector.instance.get(OmcAgentService).get(true).subscribe(agents => {
                this.omcAgents.length = 0;
                agents.forEach((oa: OmcAgent) => this.omcAgents.push(oa));
            })
        } else {
            MyInjector.instance.get(OmcService).get(true).subscribe(omcs => {
                this.omcs.length = 0;
                omcs.forEach((omc: Omc) => this.omcs.push(omc));
            });
        }
        this.teamId = item['teamId'];
        this.personId = item.id;
        this.recipient = item.fullName + '<' + item.email + '>';
        this.show = true;
        if (item.email) {
            this.disabled = false;
            this.tipText = "Send a mail to " + item.fullName;
        } else {
            this.disabled = true;
            this.tipText = "We have no email address for " + item.fullName + " cannot send email";
        }
    }

    private getEmailFields(): Field[] {
        const flds = [];
        const omcHolder = FieldMaker.idHolder('omcId');
        if (!this.currentUser.inAgency) {
            const agentField: PicklistField = FormPicklistComponent.make('Agent', 'agentTeamId', 'agentTeam',
                {
                    items: this.omcAgents, refreshes: [(oa: OmcAgent) => {
                        omcHolder.control.setValue(oa.omc.id, { emitEvent: false });
                    }]
                },
                { validators: [required], readonly: false }
            );
            flds.push(agentField);
        }
        flds.push(FormTextComponent.make('To', 'recipient', {readonly: true}));
        flds.push(FieldMaker.idHolder('teamId'));
        flds.push(FieldMaker.idHolder('personId'));
        flds.push(FieldMaker.idHolder('asEmail'));
        flds.push(omcHolder);
        flds.push(FormTextComponent.make('Subject', 'title', { validators: [required, maxChars(200), minChars(10)], }));
        flds.push(FormRichTextComponent.make('Content', 'description', { validators: [required] }));
        return flds;
    }

    createNewMail(): Observable<Action> {
        const a = new Action();
        a.asEmail = true;
        a.personId = this.personId;
        a.teamId = this.teamId;
        a['recipient'] = this.recipient;
        if (this.currentUser.inAgency) {
            const omc = this.omcs.find(o => o.omcTeamId === this.teamId);
            if (omc) {
                a.omcId = omc.id;
            } else {
                alert('Omc Does not seem to be configured');
            }
        }

        if (this.omcAgents.length === 1) {
            a.agentTeamId = this.omcAgents[0].id;
            a.agentTeam = this.omcAgents[0];
            a.omcId = this.omcAgents[0]['omc']['id'];
        }

        return of(a);
    }

    openSendMailDialog() {

        const dialogFormConfig = new FormConfig(
            {
                title: $localize`Send Email`,
                fieldSet: new FieldSet(
                    {
                        fields: this.getEmailFields(),
                        formLayout: [{ cells: [{ width: '100%' }]}]
                    }
                ),
                mode: 'new',
                objectFactory: this.createNewMail.bind(this),
            }
        );
// Do not set forceTeamId, create from the agency if needs be
        const dialogRef = this.dialog.open(EditDialogComponent,
            {
                data:
                {
                    config: dialogFormConfig,
                    service: this.actionService,
                    hideTabs: true,
                    width: 800,
                }
            }
        );

        return dialogRef.afterClosed().subscribe( action => {
            if (action) {
                this.actionGrid.control.addRow(action, true, true);
            }
        });
    }
}
