/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { AbstractObject, uuid } from 'src/app/model/abstract-object';

export class BankOption {
    id: string;
    name: string;
    bic: string;
    transaction_total_days: string; // numeric value as string...
    countries: string[];
    logo: string;
}

export class GoCardlessAccount {
    id: uuid;
    created: string;
    last_accessed: string;
    iban: string;
    institution_id: uuid;
    status: string;
    owner_name: string;
    matched: boolean;
}

export class GoCardlessAccess extends AbstractObject {
    created: string;
    gocInstitutionId: uuid;
    bankName: string;
    status: string;
    accounts: GoCardlessAccount[];
    gocLink: string;
}

@Injectable({
    providedIn: 'root'
})
export class GoCardlessOpenBankingService {

    // protected baseUrl = this.ajaxPath + 'txns?typeId=' + Txn.TYPE.SALE.id;
    protected baseUrl = AbstractHttpService.ajaxPath + 'openbank';


    constructor(protected http: HttpClient) { }

    requestAccess(bo: BankOption) {
        return this.http.post<GoCardlessAccess>(this.baseUrl + '/access', { bankId: bo.id, bankName: bo.name })
    }

    getBanks() {
        return this.http.get<BankOption[]>(this.baseUrl + '/banks');
    }

    getAccesses() {
        return this.http.get<GoCardlessAccess[]>(this.baseUrl + '/access');
    }

    removeAcess(goca: GoCardlessAccess) {
        return this.http.delete<GoCardlessAccess[]>(this.baseUrl + '/access/' + goca.id);
    }

}
