@if (chartHolder.showing) {
  <button mat-icon-button #menuTrigger="matMenuTrigger"  [matMenuTriggerFor]="menu" style="z-index: 50">
    <mat-icon>more_vert</mat-icon>
  </button>
}
<mat-menu #menu="matMenu">
  @for (f of config.fieldSet.fields; track f) {
    @if (f.type==='picklist') {
      @if (f === chartHolder.getPicklist()) {
        <button mat-menu-item disabled="true">
          {{f.label}} <mat-icon>check</mat-icon>
        </button>
      }
      @if (f !== chartHolder.getPicklist()) {
        <button mat-menu-item (click)="chartHolder.switchPicklist(f)">{{f.label}}</button>
      }
    }
  }
  <mat-divider></mat-divider>
  @if (chartHolder.getMetric() === null) {
    <button mat-menu-item  disabled="true">
      Count <mat-icon>check</mat-icon>
    </button>
  }
  @if (chartHolder.getMetric() !== null) {
    <button mat-menu-item  (click)="chartHolder.switchMetric(null)">Count</button>
  }
  @for (f of config.fieldSet.fields; track f) {
    @if (isMetric(f)) {
      @if (f === chartHolder.getMetric()) {
        <button mat-menu-item  disabled="true">
          Sum {{f.label}} <mat-icon>check</mat-icon>
        </button>
      }
      @if (f !== chartHolder.getMetric()) {
        <button mat-menu-item  (click)="chartHolder.switchMetric(f)">
          Sum {{f.label}}
        </button>
      }
    }
  }
  <mat-divider></mat-divider>
  <button mat-menu-item>Pie Chart</button>
  <button mat-menu-item>Bar Chart Chart</button>
  <button mat-menu-item>Trendline</button>
  <mat-divider></mat-divider>
  <button mat-menu-item (click)="removeChart()">Remove Chart</button>
</mat-menu>
<div [id]="chartHolder.getDivId()" style="margin-top: -42px">
</div>
