/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { NavRoute } from 'src/app/shared/NavRoute';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { FormConfig } from 'src/app/shared/form/FormConfig';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet, LAYOUT_OPTIONS } from 'src/app/shared/form/field-set/field-set.component';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { UnitPageComponent } from '../../unit/unit-page/unit-page.component';
import { PersonPageComponent } from 'src/app/pages/person-page/person-page.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { Frequency } from 'src/app/model/frequency';
import { DebtorsListService } from './debtors-list.service';
import { FormPageComponent } from 'src/app/shared/form/form-page/form-page.component';
import { User } from 'src/app/model/user';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { Field } from 'src/app/shared/field/Field';
import { Unit } from 'src/app/model/unit';
import { CurrencyPipe } from '@angular/common';
import { DateHelper } from 'src/app/shared/dateHelper';

@Component({
    selector: 'app-debtors-list',
    standalone: true,
    imports: [FormPageComponent, CurrencyPipe],
    templateUrl: './debtors-list.component.html',
    styleUrl: './debtors-list.component.scss'
})
export class DebtorsListComponent extends AbstractPageComponent {
    static readonly navRoute = new NavRoute('debtorsList', DebtorsListComponent, 'sports').setViews((u: User) => {
        return [
            {
                id: 'scoredYear',
                name: 'Scored Debtors List',
                filterFields: {
                    frequencyId: 'any'
                },
            },
            {
                id: 'inArrears',
                name: '>1 charge owing',
                filterFields: {
                    invoices: '>=2',
                    frequencyId: 'any'
                },
            },
            {
                id: 'inArrears2',
                name: '>2 Charges owing',
                filterFields: {
                    invoices: '>=3',
                    frequencyId: 'any'
                },
            },
            {
                id: 'paymentPlan',
                name: 'This years payment plan',
                filterFields: {
                    frequencyId: '12',
                    invoices: '1'
                },
            },
            {
                id: 'asAtDate',
                name: 'Debtors as at Date',
                filterFields: {
                    asAtDate: u.currentTeam?.currentPeriod?.from ?
                        DateHelper.adjustDate(u.currentTeam?.currentPeriod?.from, -1) : '1999-12-31'
                },
            }
        ]
    });

    config = new FormConfig({
        navRoute: DebtorsListComponent.navRoute,
        title: $localize`Debtors List`,
        help: $localize`Whatever`,
        fieldSet: new FieldSet({
            fields: [
                FieldMaker.id(),
                FieldMaker.rev(),
                FormButtonComponent.makeNavDetailButton('Unit', 'unitName', 'unitId', UnitPageComponent.navRoute),
                FormButtonComponent.makeNavDetailButton('Person', 'fullName', 'ownerId', PersonPageComponent.navRoute),
                FormNumberComponent.makeCurrency('Outstanding', 'outstanding'),
                FormNumberComponent.make('Items', 'invoices'),
                FormNumberComponent.makePercent('Debtor Score', 'score'),
                FormPicklistComponent.make('Frequency', 'frequencyId', null, { items: Frequency.ALL_OPTIONS, }),
                FormDateComponent.make('asAtDate', 'asAtDate', { visible: Field.noShow })

            ],
            formLayout: LAYOUT_OPTIONS.singleCol,
        }),
        service: this.debtorsListSvc,
        mode: 'list',
        beforeList: this.beforeList.bind(this)
    });
    totalDebtors = 0;

    constructor(public debtorsListSvc: DebtorsListService) {
        super();
    }

    beforeList(items: Unit[]) {
        this.totalDebtors = 0;
        items.forEach(u => this.totalDebtors += +u.outstanding);
        return items;
    }

    dayBefore(from: string) {
        return DateHelper.adjustDate(from, -1);
    }

}
