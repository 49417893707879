/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { of } from 'rxjs';
import { Cycle } from 'src/app/model/cycle';
import { Field } from 'src/app/shared/field/Field';
import { Period } from 'src/app/model/period';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { GridField } from 'src/app/shared/grid/grid-field';
import { maxChars, numericRange, required } from 'src/app/shared/validators';
import { CycleService } from '../cycle.service';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { AppFormControl } from 'src/app/shared/form/app-form-control';
import { PeriodService } from '../period.service';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { ValidationErrors } from '@angular/forms';
import { FormError } from 'src/app/shared/form/form-error/form-error.component';
import { DateHelper } from 'src/app/shared/dateHelper';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { NavRoute } from 'src/app/shared/NavRoute';
import { NextCycleAction } from './next-cycle-action';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { YearEndAction } from './year-end-action';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { BCodePageComponent } from '../b-code-page/b-code-page.component';
import { JournalPageComponent } from '../../txn/journal-page/journal-page.component';
import { FormDateTimeComponent } from 'src/app/shared/form/form-date-time/form-date-time.component';

@Component({
    selector: 'app-cycle-page',
    templateUrl: './cycle-page.component.html',
    styleUrls: ['./cycle-page.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class CyclePageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('budgets/cycles', CyclePageComponent, 'compost');

    showCellOpts = { heading: '', width: '1%', style: 'font-size:small' };

    childGrid: GridField = new GridField({
        field:
            { label: 'Period Breakdown', value: 'periods', formColumn: 3, visible: Field.formOnly },
        rowFactory: (o: Period) => [
            FieldMaker.id(),
            FieldMaker.rev(),
            FieldMaker.nameControl({ validators: [required, maxChars(20)] }),
            FormDateComponent.make('From', 'from'),
            FormDateComponent.make('To', 'to'),

            FormPicklistComponent.make('Status', 'statusId', 'status', { items: Period.STATUSES }, { readonly: true }),

            FormButtonComponent.make('', '', {
                name: 'action', label: '', type: 'icon', sendServer: false,
                cellOpts: this.showCellOpts,
                disable: o ? o.statusId === Period.STATUS.OPEN.id ? false : true : true,
                calculateValue: () => 'block',
                toolTip: $localize`Block any further transactions for this period (other than by directors)`,
                btnOpts: { clickMethod: this.blockPeriod.bind(this) }
            }),
            FormButtonComponent.make('', '', {
                name: 'action2', label: '', type: 'icon', sendServer: false,
                cellOpts: this.showCellOpts,
                disable: o ? o.statusId === Period.STATUS.CLOSED.id ? false : true : true,
                calculateValue: () => 'lock',
                toolTip: $localize`Lock this period for good and prevent any new or changed transactions being processed`,
                btnOpts: { clickMethod: this.lockPeriod.bind(this) }
            }),
            FormButtonComponent.make('', '', {
                name: 'action3', label: '', type: 'icon', sendServer: false,
                cellOpts: this.showCellOpts,
                disable: o ? o.statusId === Period.STATUS.CLOSED.id ? false : true : true,
                calculateValue: () => 'edit',
                toolTip: $localize`Reopen this period to allow editting of financial data`,
                btnOpts: { clickMethod: this.unBlockPeriod.bind(this) }
            }),
            FieldMaker.notes(),
            FieldMaker.deleteGridRow(),
        ],
        objFactory: () => of(new Period())
    });

    fromField = FormDateComponent.make('From', 'from', { validators: [required] });
    toField = FormDateComponent.make('To', 'to', { validators: [required] });
    config = new FormConfig({
        navRoute: CyclePageComponent.navRoute,
        title: $localize`Budget Cycle`,
        help: $localize`The budget cycle, budgets are planned, approved and billed for one cycle at a time.`,
        fieldSet: new FieldSet({
            fields: [
                FieldMaker.id(),
                FieldMaker.rev(),
                FieldMaker.nameControl({ validators: [required, maxChars(20)] }),
                this.fromField,
                this.toField,

                FormNumberComponent.make('Discount for Direct Debit Payments', 'mandateDiscount',
                    { format: 'percent', formatParms: '2.3-3', step: .01 },
                    { formColumn: 2, validators: [numericRange(0, 100)] }
                ),
                FormDateComponent.make('First DD Payments Taken', 'firstPayments', { formColumn: 2 }),
                FormDateComponent.make('Last DD Payments Taken', 'lastPayments', { formColumn: 2 }),

                FormNumberComponent.make('Discount for full automatic payment in advance', 'fullPayDiscount',
                    { format: 'percent', formatParms: '2.3-3', step: .01 },
                    { formColumn: 2, validators: [numericRange(0, 100)] }
                ),
                FormDateComponent.make('Date to qualify for full payment discount', 'fullPayBy', { formColumn: 2 }),

                FieldMaker.notes(),
                this.childGrid
            ], formLayout: [{ cells: [{ width: '25%' }, { width: '25%' }, { width: '50%', pageTab: 'yes' }] }],
            formValidator: [this.validatePeriods.bind(this)]
        }),
        tabFields: [this.getYearEndsGrid()],
        service: this.dataSvc,
        mode: 'list',
        actions: [new NextCycleAction(), new YearEndAction()],
        objectFactory: () => of(new Cycle()),
    });

    constructor(public dataSvc: CycleService,
        public cds: ConfirmDialogService, private periodSvc: PeriodService) {
        super();
    }

    private yearEndRowFactory() {
        return [
            FormButtonComponent.makeNavDetailButton('Journal', 'parentRef', 'parentId', JournalPageComponent.navRoute),
            FormDateTimeComponent.make('Created', 'createdAt', {readonly: true}),
            FormButtonComponent.makeNavDetailButton('Budget Code', 'bCodeName', 'bCodeId', BCodePageComponent.navRoute),
            FormTextComponent.make('Sort', 'bCodeSort').override({readonly: true}),
            FormNumberComponent.makeCurrency('Credit', 'credit').override({ readonly: true }),
            FormNumberComponent.makeCurrency('Debit', 'debit').override({ readonly: true }),
            FieldMaker.spacer()
        ];
    }

    private getYearEndsGrid() {
        return new GridField({
            field:
                { label: 'Year End Balances', value: 'yearEnds', visible: Field.formOnly, readonly: true },
            rowFactory: this.yearEndRowFactory.bind(this)
        });
    }

    validatePeriods(): ValidationErrors {
        if (!this.childGrid.control) {
            return null;
        }
        let prevEnd = '';
        for (const gridRow of this.childGrid.control.gridRows()) {
            if (!gridRow.get('from') || !gridRow.get('to')) {
                return null; // wasn't initialized...
            }
            if (Field.isEmpty(prevEnd)) {
                if (gridRow.get('from').value !== this.fromField.control.value) {
                    return FormError.reportError('StartDateNotMatching',
                        $localize`Start Date of first period does not match start date of cycle`);
                }
            } else {
                if (DateHelper.isoDaysBetween(prevEnd, gridRow.get('from').value) !== 1) {
                    return FormError.reportError('DatesNonContiguous',
                        $localize`Start data of period does not follow directly after last day of previous period`);
                }
            }
            prevEnd = gridRow.get('to').value
        }
        if (prevEnd !== this.toField.control.value) {
            return FormError.reportError('EndDateNotMatching',
                $localize`End date of last period does not match end date of cycle`);
        }

        return null;
    }

    calcToolTip(o: Period) {
        if (o) {
            if (o.statusId === Period.STATUS.OPEN.id) {
                return $localize`Block any further transactions for this period (other than by directors)`;
            } else if (o.statusId === Period.STATUS.CLOSED.id) {
                return $localize`Lock this period for good and prevent any new or changed transactions being processed`
            } else {
                return $localize`Period locked and closed - No new transactions or changes possible`;
            }
        }
    }

    calcPeriodIcon(o: Period) {
        if (o.statusId === Period.STATUS.OPEN.id) {
            return 'block';
        } else if (o.statusId === Period.STATUS.CLOSED.id) {
            return 'lock'
        } else {
            return 'lock';
        }
    }
    /*
        lockPeriod(period: Period) {

            const options: ConfirmDialogData = {
                title: $localize `Lock or Open Period`,
                msg: $localize `Lock period, so not even directors can make any further changes, or reopen period to allow editting?`,
                options: [{name: 'crap', () => {
                    this.periodSvc.block(period).subscribe( period => {
                        if (period) {
                            this.page.form.reloadForm();
                        }
                    });
                }}];
            }
            this.cds.open(action, text, () => {
                this.periodSvc.block(period).subscribe( period => {
                    if (period) {
                        this.page.form.reloadForm();
                    }
                });
            });
        }
    */

    blockPeriod(ctl: AppFormControl) {
        const period = ctl.getRow().focus as Period;
        const action = $localize`Close Period`;
        const text = $localize`Close period now and do not allow any further new or changed transactions?`
        this.cds.open(action, text, () => {
            this.periodSvc.block(period).subscribe(period => {
                if (period) {
                    this.page.form.reloadForm();
                }
            });
        });
    }

    unBlockPeriod(ctl: AppFormControl) {
        const period = ctl.getRow().focus as Period;
        const action = $localize`Open Period`;
        const text = $localize`Reopen period now and allow editting of financial data?`
        this.cds.open(action, text, () => {
            this.periodSvc.unblock(period).subscribe(period => {
                if (period) {
                    this.page.form.reloadForm();
                }
            });
        });
    }

    lockPeriod(ctl: AppFormControl) {
        const period = ctl.getRow().focus as Period;
        const action = $localize`Lock Period`;
        const text = $localize`Lock period, so not even directors can make any further changes? n.b. THIS CANNOT BE UNDONE?`
        this.cds.open(action, text, () => {
            this.periodSvc.lock(period).subscribe(period => {
                if (period) {
                    this.page.form.reloadForm();
                }
            });
        });
    }
}
