/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, inject, Input } from '@angular/core';
import { ServiceRequest } from 'src/app/model/serviceRequest';
import { ChatComponent } from 'src/app/shared/chat/chat-component/chat.component';
import { ChatField } from 'src/app/shared/chat/chat-field';
import { AttachmentGridComponent } from 'src/app/shared/form/file/attachment-grid/attachment-grid.component';
import { IsNarrowService } from 'src/app/shared/is-narrow.service';
import { MyRequestService } from '../my-request.service';
import { CtlHolderComponent } from '../../../shared/form/ctl-holder/ctl-holder.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { FromNowPipe } from 'src/app/shared/pipes/from-now.pipe';
import { MatCardModule } from '@angular/material/card';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { AddPersonUnitDialogComponent } from '../add-person-unit-dialog/add-person-unit-dialog.component';
import { ReopenRequestComponent } from '../service-request-page/reopen-request/reopen-request.component';
import { CurrentUserService } from '../../user/current-user.service';
import { ServiceRequestPageComponent } from '../service-request-page/service-request-page.component';
import { MyInjector } from 'src/app/app.module';
import { RequestService } from '../request.service';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { Role } from 'src/app/model/role';
@Component({
    selector: 'app-my-request',
    templateUrl: './my-request.component.html',
    styleUrls: ['./my-request.component.scss'],
    standalone: true,
    imports: [CtlHolderComponent, FromNowPipe, MatCardModule,
        RouterLink, NgTemplateOutlet, MatButtonModule, NgClass]
})
export class MyRequestComponent {

    @Input() request: ServiceRequest;
    dialog = inject(MatDialog);
    activatedRoute = inject(ActivatedRoute);
    router = inject(Router);

    fullRequest: ServiceRequest;
    descriptionText: string;

    isPhone = false;
    formHeight = this.getFormHeight();

    chatField: ChatField = ChatComponent.make(this.myRequestService).hideFollowers();

    attachmentGrid = AttachmentGridComponent.make(this.myRequestService).hideGrid();

    openRequests : ServiceRequest[]= [];
    isUnitOwnerRequest = false;

    constructor(private myRequestService: MyRequestService,
    isNarrowSvc: IsNarrowService, private cus: CurrentUserService) {
        isNarrowSvc.detectVeryNarrow().subscribe(result => { this.isPhone = result; });
        isNarrowSvc.resizeObservable$.subscribe(() => {
            this.formHeight = this.getFormHeight();
        })
        this.chatField.makeControl();
        this.attachmentGrid.makeControl();
        this.attachmentGrid.showAllFields = false;
        this.activatedRoute.params.subscribe( parms => {
            cus.getCurrentUser().subscribe(u => {
                if (u?.inAgency) {
                    this.router.navigate([ServiceRequestPageComponent.navRoute.url, parms.itemId], { replaceUrl: true });
                } else {
                    this.myRequestService.getOne(parms.itemId, null).subscribe(fullRequest => {
                        if (fullRequest) {
                            this.fullRequest = fullRequest;
                            this.descriptionText = this.replaceAll(fullRequest.description, String.fromCharCode(10), '<br/>');
                            this.chatField.setValue(fullRequest, false);
                            this.attachmentGrid.setValue(fullRequest, false);
                            if (this.fullRequest.srStatus === ServiceRequest.srOpen.id) {
                                this.chatField.allowComments();
                                this.attachmentGrid.setReadonly(false);
                            } else {
                                this.chatField.disableComments();
                                this.attachmentGrid.setReadonly(true);
                            }
                            this.checkForUnitOwner();                            
                        }
                        this.myRequestService.get(true).subscribe((r: ServiceRequest[]) => {
                            this.openRequests = r.filter(o => o.srStatus === ServiceRequest.srOpen.id);
                        })
                    });
                }
            })
        })
    }

    checkForUnitOwner() {
        this.isUnitOwnerRequest = false;
        const ap = this.cus.getActivePositions();
        const accessRequestFor = (this.fullRequest?.data ? this.fullRequest?.data['person'] : null);
        if (accessRequestFor) {
            if (accessRequestFor.unit?.resident && !accessRequestFor.unit?.owner) {
                const apu = ap.filter(p => p.unitId === this.fullRequest.unitId);
                apu.forEach( pur => {
                    if  (pur.role.globalRoleId === Role.ROLE.OWNER.id
                    || pur.role.globalRoleId === Role.ROLE.OWNER_OCCUPIER.id) {
                        this.isUnitOwnerRequest = true;
                    }
                })
            }
        }
    }
    canDeactivate() {
        return true;
    }

    replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    }

    getFormHeight() {
        return window.innerHeight - 276;
    }

    addToUnit() {
        this.dialog.open(AddPersonUnitDialogComponent, {data: {sr: this.fullRequest}}).afterClosed().subscribe( r => {
            if (r) {
                this.fullRequest = r;
                this.chatField.setValue(r, false);
                this.chatField.disableComments();
                this.attachmentGrid.setReadonly(true);
            }
        })
    }
    reopenRequest() {
        this.dialog.open(ReopenRequestComponent, { data: this.fullRequest }).afterClosed().subscribe(r => {
            if (r) {
                this.fullRequest = r;
                this.chatField.setValue(r, false);
                this.chatField.allowComments();
                this.attachmentGrid.setReadonly(false);
            }
        })
    }
    acceptRequest() {
        const t = $localize `Accept Request Is Completed`;
        const m = $localize `Click OK to accept request is complete.
            It will no longer be possible to reopen or add comments to it.`
        const cds = MyInjector.instance.get(ConfirmDialogService);
        cds.open(t,m, () => {
            const rs = MyInjector.instance.get(RequestService);
            rs.acceptRequest(this.fullRequest).subscribe(r => {
                this.fullRequest = r;
            });
        })
    }
}
