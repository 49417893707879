<div>
  @for (chartRow of chartsToShow; track chartRow) {
    <table style="width: calc(100%-40px);">
      <tr>
        @for (chartChosen of chartRow; track chartChosen) {
          <td>
            <app-dashboard-chart
              [availCharts]="availCharts"
              [chartChosen]="chartChosen"
              (changeWidth)="changeWidth($event)"
              (changeChart)="changeChart()"
              (removeChart)="removeChart(chartChosen, chartRow)">
            </app-dashboard-chart>
          </td>
        }
        <td style="vertical-align: top; padding-right: 10px;">
          <button mat-icon-button #menuTrigger="matMenuTrigger"  [matMenuTriggerFor]="menuDashboardOptions" style="z-index: 50">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menuDashboardOptions="matMenu">
            <button mat-menu-item #menuTrigger="matMenuTrigger"  [matMenuTriggerFor]="menuChooseChart">Add Chart</button>
            <button mat-menu-item (click)="toggleAutoRefresh()">Auto Refresh@if (autoRefreshIntervalId) {
              <mat-icon>check</mat-icon>
            }</button>
            <button mat-menu-item (click)="defaultCharts()">Reset to default charts</button>
          </mat-menu>
          <mat-menu #menuChooseChart="matMenu">
            @for (avail of stillAvailCharts(); track avail) {
              <button mat-menu-item (click)="addChart(avail, chartRow)">{{avail.name}}</button>
            }
          </mat-menu>
        </td>
      </tr>
    </table>
  }
</div>
