<div class="basePage">
    <div>
        <ng-container [ngTemplateOutlet]="titleTemplate"></ng-container>
        <ng-container [ngTemplateOutlet]="breadCrumbTemplate"></ng-container>
    </div>
    <div>
        <div style="margin-top:10px; margin-bottom:10px; margin-left: 20px; margin-right: 20px; width: 80%"
            [innerHTML]="document?.content">
        </div>
        <ng-container [ngTemplateOutlet]="headingsTemplate"></ng-container>

        @for (child of document.childPages; track $index) {
            <ng-container [ngTemplateOutlet]="childPageTemplate" [ngTemplateOutletContext]="{child}"></ng-container>
        }
        <ng-container [ngTemplateOutlet]="attachmentsTemplate"></ng-container>
        <ng-container [ngTemplateOutlet]="newButtonTemplate"></ng-container>
    </div>
    <div width="100%">
        @if (document?.childPages.length === 0 && document?.attachments.length === 0 && !document.content) {
        <p>
            Click buttons to edit page content, add child content and/or add files to this page
        </p>
        }
    </div>
</div>

<ng-template #newButtonTemplate>
    @if (allowNew && !printMode) {
        <div style="width:100%; text-align:center; margin-top: 20px">
            <button mat-mini-fab [matMenuTriggerFor]="matAddMenu" >
                <mat-icon>add</mat-icon>
            </button>
            <div style="margin-left: 10px; margin-top: -20px">
                <mat-menu #matAddMenu="matMenu">
                    <button mat-menu-item  (click)="addChild()">Add New Page</button>
                    <button mat-menu-item (click)="addAttachment()">Attach File</button>
                </mat-menu>
            </div>
        </div>
        <div style="display:none">
            <app-file-upload [control]="control" [attachToId]="document?.id" [forceTeam]="forceTeamId"></app-file-upload>
        </div>
    }
</ng-template>

<ng-template #childPageTemplate let-child="child">
    <div [id]="child.id" cdkDropList (cdkDropListDropped)="dropInPage($event, child)" class="page">
        <div style="display: flex">
            <div class="icon" matTooltip="Child Page">
                <mat-icon color="primary">docs</mat-icon>
            </div>
            <div class="itemTitle">
                <a [routerLink]="routerDetailLink(child.id)">{{child.title}}</a> <span
                    class="excerpt">{{getExcerpt(child)}}</span>
            </div>

            @if (allowEdit && !printMode) {
                <div class="edits">
                    <mat-icon class="edits" color="warn" (click)="deletePage(child)">delete</mat-icon>
                </div>
            }
            <div class="small-info doc-count"
                matTooltip="{{child.attachments_count}} files attached {{child.child_docs_count}} child pages">
                {{child.attachments_count}}<mat-icon class="edits">attachment</mat-icon>
                {{child.child_docs_count}}<mat-icon class="edits">docs</mat-icon>
            </div>
            <div class="small-info date-item">
                @if (child.updatedAt) {
                    {{child.updatedAt | fromNow}}
                } @else {
                    {{child.createdAt | fromNow}}
                }
            </div>
        </div>
    </div>
</ng-template>

<ng-template #attachmentsTemplate let-attach="attach">
    <div cdkDropList #todoList="cdkDropList" [cdkDropListConnectedTo]="childPageIds" style="width: 100%">
        @for (attach of document.attachments; track $index) {
            @if (attach?.name?.toLowerCase().includes(filter.toLowerCase())) {
                <!-- ng-container [ngTemplateOutlet]="attachmentTemplate" [ngTemplateOutletContext]="{attach}"></ng-container -->
                <div style="display:flex" cdkDrag [cdkDragData]="attach" class="attach">
                    <div>
                        <ng-container [ngTemplateOutlet]="iconTemplate" [ngTemplateOutletContext]="{attach}"></ng-container>
                    </div>
                    <div class="itemTitle">
                        <a href='#' (click)="downloadAttachmentFile($event, attach)">{{attach.name}}</a> <span
                            class="excerpt">{{attach.notes}}</span>
                    </div>
                    @if (allowEdit && !printMode) {
                        <div class="edits" (click)="editAttachment(attach)">
                            <mat-icon class="edits" color="primary">edit</mat-icon>
                        </div>
                        @if(control.field.fileOpts.allowDelete) {
                            <div class="edits" (click)="deleteAttachment(attach)">
                                <mat-icon class="edits" color="warn">delete</mat-icon>
                            </div>
                        }
                    }
                    <div class="small-info doc-count">{{attach.size | byteCount}}</div>
                    <div class="small-info date-item">{{attach.createdAt | fromNow}}</div>
                </div>
            }
        }
    </div>
</ng-template>

<ng-template #headingsTemplate>
    <div>
        @if(document.childPages.length > 0 || document.attachments.length > 0) {
            <div style="display:flex" class="heading">
                <div class="icon small-info click" (click)="sortOn('ext')">
                    <ng-container [ngTemplateOutlet]="sortIcon" [ngTemplateOutletContext]="{parm: 'ext'}"></ng-container>
                </div>
                <div class="small-info click" style="flex-grow: 1; padding-left: 10px" (click)="sortOn('name')">
                    Name<ng-container [ngTemplateOutlet]="sortIcon" [ngTemplateOutletContext]="{parm: 'name'}"></ng-container>
                    <input [(ngModel)]="filter" class="filter" (click)="$event.stopPropagation()" placeholder="Filter...">
                </div>

                <div class="small-info doc-count click" (click)="sortOn('size')">
                    Size/Items<ng-container [ngTemplateOutlet]="sortIcon"
                        [ngTemplateOutletContext]="{parm: 'size'}"></ng-container>
                </div>
                <div class="small-info date-item click" (click)="sortOn('createdAt')">
                    Date<ng-container [ngTemplateOutlet]="sortIcon"
                        [ngTemplateOutletContext]="{parm: 'createdAt'}"></ng-container>
                </div>
            </div>
        }
    </div>
</ng-template>

<ng-template #titleTemplate>
    <div style="flex-grow: 1">
        <h1 class="mat-h1">
            <span class="primary-font-color">{{title}}</span>
            @if (!printMode) {
                @if (allowEdit) {
                    <button mat-icon-button color="primary" (click)="editDocument()">
                        <mat-icon>edit</mat-icon>
                    </button>
                }
                <button (click)="print()" mat-icon-button color="primary">
                    <mat-icon>print</mat-icon>
                </button>
            }
        </h1>
        <p style="margin-top: -15px">
            Shared In {{document?.forum?.name}}
            @if(document?.areaName) {
                - {{document.areaName}}
                @if (document.coreName) {
                    ({{document.coreName}})
                }
            }
        </p>
    </div>
</ng-template>

<ng-template #breadCrumbTemplate>
    <a [routerLink]="routerLink">Documents</a>
    @if (document?.parent) {
        @if (!document.parent.parentId) {
            <span> > </span>
        }
        @if (document.parent.parentId) {
            <span> ... </span>
        }
        <a [routerLink]="routerDetailLink(document.parentId)" [id]="document.parentId" cdkDropList
            (cdkDropListDropped)="dropInPage($event, document.parentId)">
            <span class="primary-font-color">{{document?.parent?.title}}</span>
        </a>
    }
    > <span class="primary-font-color">{{document?.title}}</span>
</ng-template>


<ng-template #iconTemplate let-attach="attach">
    <div class="icon">
        @switch (attach.ext?.toLowerCase()) {
        @case ('pdf') {
            <img src="./assets/file-types/file_type_pdf_icon_32.png" height="28px" width="28px" />
        }
        @case ('doc') {
            <img src="./assets/file-types/word_32.png" height="24px" width="24px" />
        }
        @case ('docx') {
            <img src="./assets/file-types/word_32.png" height="24px" width="24px" />
        }
        @case ('xls') {
            <img src="./assets/file-types/excel_32.png" height="24px" width="24px" />
        }
        @case ('xlsx') {
            <img src="./assets/file-types/excel_32.png" height="24px" width="24px" />
        }
        @case ('msg') {
            <img src="./assets/file-types/outlook.png" height="24px" width="24px" />
        }
        @case ('jpg') {
            <img src="./assets/file-types/icon-image.png" height="20px" width="20px" style="margin-top: 3px"/>
        }
        @case ('png') {
            <img src="./assets/file-types/icon-image.png" height="20px" width="20px" style="margin-top: 3px"/>
        }
        @case ('jpeg') {
            <img src="./assets/file-types/icon-image.png" height="20px" width="20px" style="margin-top: 3px"/>
        }
        @case ('gif') {
            <img src="./assets/file-types/icon-image.png" height="20px" width="20px" style="margin-top: 3px"/>
        }
        @default {
            <mat-icon color="primary" height="28px" width="28px">attachment</mat-icon>
        }
        }
    </div>
</ng-template>

<ng-template #sortIcon let-parm="parm">
    @if(parm === this.sortedOn) {
        @if(this.sortedDir > 0) {
            <mat-icon style="font-size: 18px; margin-bottom: -10px">arrow_upward</mat-icon>
        } @else {
            <mat-icon style="font-size: 18px; margin-bottom: -10px">arrow_downward</mat-icon>
        }
    }
</ng-template>
