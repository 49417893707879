/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

/**
 * Thank you to: https://github.com/progtarek/angular-drag-n-drop-directive
 */
import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Attachment } from 'src/app/model/attachment';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { ControlOn } from '../../app-form-control';
import { FormControlInterface } from '../../form-control-interface';
import { UpFile } from './UpFile';
import { AttachmentControl } from 'src/app/shared/field/AttachmentField';
import { uuid } from 'src/app/model/abstract-object';
import { ByteCountPipe } from '../../../pipes/byte-count.pipe';
import { UploadProgressComponent } from '../upload-progress/upload-progress.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgClass } from '@angular/common';
import { UploadDragAndDropDirective } from './upload-drag-and-drop.directive';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    standalone: true,
    imports: [UploadDragAndDropDirective, NgClass, MatIconModule, MatTooltipModule,
        MatButtonModule, UploadProgressComponent, ByteCountPipe]
})
export class FileUploadComponent implements FormControlInterface, OnChanges {

    @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
    @Input() label: string;
    @Input() control: AttachmentControl;
    @Input() on: ControlOn = 'form';
    @Input() attachToId = 0;
    @Input() forceTeam: uuid = null;

    files: UpFile[]; // = [];

    constructor(protected cds: ConfirmDialogService) {

    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.control) {
            this.files = changes.control.currentValue.field.fileOpts.deferredFiles;
        }
    }

    /**
     * handle file dropped on control
     */
    onFileDropped($event) {
        this.uploadFiles($event);
    }

    /**
     * handle file user selected from file System browsing
     */
    fileBrowseHandler(files) {
        this.uploadFiles(files);
    }

    /**
     * Delete file from files list
     * @param index (File index)
     */
    deleteFile(index: number) {
        if (this.files[index].progress < 100 && !this.files[index].failed && this.files[index].progress > 0) {
            console.log("Upload in progress.");
            return;
        }
        this.files.splice(index, 1);
    }
    /**
     * Simulate the upload process
     */
    uploadSimulator(upf: UpFile) {
        const averageUploadSpeed = 10 * 1024 * 1024; // 10MB/s
        const intervalMS = 200;
        const intervalUpload = (averageUploadSpeed / 1000) * intervalMS; // ((upf.size / ((averageUploadSpeed / 1000) * intervalMS) / 100));
        const intervalUploadPct = (1 / (upf.size / intervalUpload)) * 100;

        const progressInterval = setInterval(() => {
            if (upf.progress >= 100) {
                if (upf.completed) {
                    const arry = this.control.value;
                    arry.push(upf.attachment);
                    this.control.setValue(arry);
                    this.files.splice(this.files.indexOf(upf), 1);
                }
                clearInterval(progressInterval);
            } else { // The ajax upload returning should mark it 100%!
                if (upf.completed || upf.progress + intervalUploadPct < 100) {
                    upf.progress = Math.min(100, upf.progress += intervalUploadPct);
                } else {
                    if (!upf.completed) {
                        upf.progress = Math.max(0, upf.progress - (intervalUpload * 3));
                    }
                }
            }
        }, intervalMS);
    }

    /**
     * Convert Files list to normal array list
     * @param files (Files List)
     */
    uploadFiles(files: Array<UpFile>) {
        let refused = '';
        let refusedSize = 0;

        if (this.control.field.fileOpts.onDrop) {
            this.control.field.fileOpts.onDrop(files);
        } else {
            for (const item of files) {
                item.progress = 0;
                // PHP.ini max_file_size controls how much server willa accept...
                // On nginx it is nginx.conf, server, location or http setting client_max_body_size 20M;
                if (item.size > (10 * 1024 * 1024)) {
                    console.log(item, item.size, 10 * 1024 * 1024)
                    if (refused.length > 0) {
                        refused += ', '
                    }
                    refused += item.name;
                    refusedSize += item.size;
                } else {
                    this.files.push(item);
                    this.uploadSimulator(item);
                    if (this.attachToId) {
                        const theId = this.attachToId === -1 ? 0 : this.attachToId;
                        this.control.field.fileOpts.service.attach(item, theId, this.forceTeam).subscribe((o: Attachment) => {
                            if (o) {
                                console.log('Success', o);
                                //item.progress = 100;
                                if (!this.control.value) {
                                    this.control.setValue([]);
                                }
                                item.completed = true;
                                item.attachment = o;
                            } else {
                                item.failed = true;
                                console.log('Failed');
                            }
                        })
                    } else {
                        this.control.updateValueAndValidity();
                    }
                }
            }
            if (refused.length > 0) {
                const rSize = Math.round(refusedSize / 1024 / 1024) + 'MB';
                this.cds.alert($localize`Files Refused`, $localize`Files of ${rSize} too large to upload ${refused}`);
            }
        }
        this.fileDropEl.nativeElement.value = "";

    }


}
