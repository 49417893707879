@if (unit.payzMandate) {
  <table class="ouromc">
    <tr>
      <td class="label">Mandate was created</td>
      <td>{{unit.payzMandate.completed | GmtAgo}}</td>
    </tr>
    <tr>
      <td class="label">By</td>
      <td>{{unit.payzMandate.user.name}} (<a href="mailto:{{unit.payzMandate.user.email}}">{{unit.payzMandate.user.email}}</a>)</td>
    </tr>
    <tr>
      <td class="label">For</td>
      <td>{{unit.payzMandate.description}}</td>
    </tr>
    <tr>
      <td class="label">Paid</td>
      <td>{{getFrequency(unit.payzMandate.frequencyId)}}</td>
    </tr>
    <tr>
      <td colspan="2" style="text-align: center;">
        <button type="button" color="primary" mat-raised-button class="deleteButton">
          <mat-icon>delete</mat-icon>Cancel Mandate
        </button>
      </td>
    </tr>
  </table>
}
