<div style="display: flex; flex-wrap: wrap" id="welcome_to_our_omc">
    <div style="flex: 1;"></div>
    <div class="main">
        <mat-card appearance="raised" style="padding: 30px; margin-top: 10%;">
            <h1 class="mat-headline-5">Welcome to OurOMC</h1>
            <p>
                The only software solution in the world, specifically designed for
                Owners' Management Companies (OMCs) who manage Multi Unit Dwellings
                on behalf of the unit owners, designed for Desktop, Laptop, Tablets and Phone!
            </p>
            <lottie-player
                src="https://clgassets.fra1.cdn.digitaloceanspaces.com/lottie-files/homePage.json"
                loop autoplay direction="1" mode="normal">
            </lottie-player>

            <p>Select your role to learn more about how we can help you build your community</p>
            <div style="display:flex; flex-wrap: wrap; margin: 0 auto;">
                <div class="rolePanel" routerLink="residents">Resident</div>
                <div class="rolePanel" routerLink="owners">Owner</div>
                <div class="rolePanel" routerLink="directors">Director</div>
                <div class="rolePanel" routerLink="agents">Agent</div>
                <div class="rolePanel" routerLink="accountants">Accountant</div>
                <div class="rolePanel" routerLink="contractors">Contractor/Caretaker</div>
            </div>
        </mat-card>
    </div>
    <div style="flex: 1"></div>

</div>
