
  <mat-sidenav-container coverscreen
    (backdropClick)="closeDrawer()">
    <!-- z-index of 1 stops menu from overlaying full screen tinyMCE editor...-->
    <mat-sidenav #drawer class="ouromc-sidenav" [style.width.px]="menuWidth" fixedInViewport
      [style.z-index]="isWide ? 1 : 3"
      [attr.role]="!isWide ? 'dialog' : 'navigation'" (keydown.escape)="closeDrawer()"
      [mode]="!isWide ? 'over' : 'side'" [opened]="!isWide === false" color="accent">
      <ng-container *ngTemplateOutlet="sideMenu"></ng-container>
    </mat-sidenav>
    <mat-sidenav-content [style.margin-left.px]="marginWidth">
        @if (navigateMode === 'anonymous') {
            <ng-container *ngTemplateOutlet="topBarAnonymous"></ng-container>
        } @else {
            <ng-container *ngTemplateOutlet="topBar"></ng-container>
        }

      <div class="routerContent"  [@routeAnimations]="getRouteAnimationData()" [ngClass]="getDarkIndicator()">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #burgerButton>
  @if (!isWide) {
    <div style="flex: 1" data-menu-burger>
        <button type="button"
          aria-label="Toggle sidenav" mat-icon-button (click)="drawerToggle()">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
    </div>
  }
</ng-template>
<ng-template #themeButton>
  @if (!isPhone) {
    <button mat-mini-fab id="theme-selector" [mat-menu-trigger-for]="themeMenu" color="accent"
      i18n-matTooltip="@@Select-a-theme" matTooltip="Select a theme!" tabindex="-1">
      <mat-icon class="auto-flip">format_color_fill</mat-icon>
    </button>
  }
  <mat-menu #themeMenu="matMenu">
    <mat-checkbox [checked]="isThemeDark" (click)="$event.stopPropagation()" (change)="toggleDarkness()"
      class="mat-menu-item theme-selector-is-dark" i18n="@@Dark">Dark
    </mat-checkbox>
    <mat-grid-list cols="2" class="theme-selectors-container">
      @for (theme of themes; track theme) {
        <mat-grid-tile>
          <button mat-button class="theme-selector"
            (click)="$event.stopPropagation(); setTheme(theme)">
            <div [attr.class]="theme + ' theme-primary center'">
              <div [attr.class]="theme + ' theme-accent'"></div>
              <div [attr.class]="theme + ' theme-warn'"></div>
              @if (activeTheme===theme) {
                <mat-icon class="center theme-check">check</mat-icon>
              }
            </div>
          </button>
        </mat-grid-tile>
      }
    </mat-grid-list>
  </mat-menu>
</ng-template>

<ng-template #topBarAnonymous>
    <mat-toolbar class="baseToolbar" style="margin-bottom: 20px;">
        <div id="anonymousToolbar" style="display: flex; width:100%;">
        <ng-container *ngTemplateOutlet="burgerButton"></ng-container>
        @if (isWide ? !showMenuText : !isPhone) {
            <div style="flex: 10">
                <app-logo></app-logo>
            </div>
        }
          <div style="flex: 100"></div>
          <div style="flex: 1;" [style.padding-top.px] = "isPhone ? 4 : !isWide ? 12 : null">
            <a routerLink="/login">
                <button mat-raised-button>Login</button>
            </a>

          </div>
        </div>
      </mat-toolbar>
  </ng-template>

<ng-template #topBar>
  <mat-toolbar class="baseToolbar">
    <div id="loggedInToolbar" style="display: flex; width: 100%; align-items: center;">
      <ng-container *ngTemplateOutlet="burgerButton"></ng-container>
      @if (isWide ? !showMenuText : !isPhone) {
        <div style="flex: 10">
          <app-logo></app-logo>
        </div>
      }
      <div style="flex: 100; text-align: center;">
        <span class="spacer" [matTooltip]='currentUser?.environmentComment'></span>
      </div>
      <div style="flex: 10; text-align: center;">
        <app-new-button></app-new-button>
      </div>
      <div style="flex: 10; text-align: center;padding-left: 20px;">
        <app-user-nav-button-component></app-user-nav-button-component>
      </div>
      <div style="flex: 10; text-align: center;">
        <ng-container *ngTemplateOutlet="themeButton"></ng-container>
      </div>
    </div>
  </mat-toolbar>
  <!-- div style="width: 100%; float:left">
  <div style="float:right;margin-bottom: 20px;"
    [style.width]="isWide ? (showMenuText ? '100%' : 'calc(100% - 230px)') : (isPhone ? 'calc(100% - 50px)' : 'calc(100% - 280px)')">
    <mat-toolbar class="baseToolbar">
      <span *ngIf="!isWide === false">{{sitename}}</span>
      <span class="spacer" [matTooltip]='currentUser?.environmentComment'>
      </span>
    </mat-toolbar>
  </div>
</div-->
</ng-template>
<ng-template #sideMenu>
  @if (showMenuText) {
    <app-logo></app-logo>
  }
  @if (!showMenuText) {
    <div style="float: left" class="primaryColor"
      style="height: 63px; vertical-align: middle;">
    </div>
  }
  <mat-nav-list [style.height.px]="isNarrowService.screenHeight - 120" style="overflow-y:auto">
    @for (item of navItems; track item) {
      @if (item.visible) {
        <app-nav-item href="#" [item]="item" [showMenuText]="showMenuText"
        (opened)="expandOption($event)" (selected)="selectOption($event)" [selectedItem]="selectedNavItem"></app-nav-item>
      }
    }
    @if (showMenuText && isWide) {
      <mat-icon color="primary" matTooltip="Hide Menu Text" (click)="iconMenu()"
      style="padding-left: 10px;  margin-bottom: -6px; margin-right:10px">arrow_back</mat-icon>
    }
    @if (!showMenuText && isWide) {
      <mat-icon color="primary" matTooltip="Show Menu Text" (click)="textMenu()"
      style="padding-left: 10px;  margin-bottom: -6px; margin-right:10px">arrow_forward</mat-icon>
    }
  </mat-nav-list>
  @if (showMenuText) {
    <div
      style="position: absolute; bottom: 0; left: 0; padding-left: 30px; padding-right: 10px">
      <div class="mat-typography mat-small">
        Powered By <a href="http://www.clgSystems.ie">CLG Systems</a>
      </div>
    </div>
  }
</ng-template>
<ng-template #content>
</ng-template>
