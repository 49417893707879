<div>
    <ng-container *ngTemplateOutlet="categoryHeadings"></ng-container>
    <ng-container *ngTemplateOutlet="categoryList"></ng-container>

    @if (showingSubCategory) {
        @if(showingSubCategory.fileName === 'photo.png') {
            <app-image-upload-request noHeader="true"></app-image-upload-request>
        } @else {
            <div style="width: 100%; margin-bottom: 20px">
                <div style="max-width:600px; margin-left: auto; margin-right:auto; justify-content: center;">
                    <ng-container *ngTemplateOutlet="requestTemplate"></ng-container>
                </div>
            </div>
        }
    }
</div>

<ng-template #categoryHeadings>
    @if (showingCategories.length > 0) {
        <p style="padding-left: 20px; margin-top: 8px">
            <span (click)="showAll()" class="primary-font-color">All Categories </span>
            @for (cat of showingCategories; track cat; let idx = $index) {
                @if (idx === (showingCategories.length - 1)) {
                    <span>> {{cat.name}}</span>
                }
                @if (idx < (showingCategories.length - 1)) {
                    <span class="primary-font-color" (click)="selectItem(cat)">> {{cat.name}}</span>
                }
            }
        </p>
    }
</ng-template>

<ng-template #categoryList>
    @if (!showingSubCategory) {
        @if(showingCategories.length === 0 && catsToShow?.length === 0) {
            <p style="margin-top : 40px; text-align: center;">
                Sorry - Your agent is not currently configured to accept requests over the web
            </p>
        }

        <div class="pictures">
            @for (item of catsToShow; track $index) {
                <div class="picture-item">
                    <img [src]="getFileName(item)" (click)="selectItem(item.item)">
                    <p><b>{{item.name}}</b></p>
                </div>
            }
        </div>

        <p style="height: 1em; text-align:right; padding-right:20px; font-size: 10px">
            <a href="https://www.flaticon.com/">Icons courtesy of Flaticon</a>
        </p>
    }
</ng-template>


<ng-template #requestTemplate>
    <div style="padding-left: 20px; padding-right:20px">
        <h4 mat-h4>Request Details</h4>
        <table width="100%">
            @if (showOmcField) {
                <tr>
                    <td>
                        <app-ctl-holder [control]="omcField.control"></app-ctl-holder>
                    </td>
                </tr>
            }
            <tr>
                <td>
                    <app-ctl-holder [control]="titleCtl"></app-ctl-holder>
                </td>
            </tr>
            @for (row of showingQuestions; track row) {
                <tr>
                    <td>
                        <app-ctl-holder [control]="row.control"></app-ctl-holder>
                    </td>
                </tr>
            }
            <tr>
                <td>
                    <app-ctl-holder [control]="descriptionCtl"></app-ctl-holder>
                </td>
            </tr>
            <tr>
                <td>
                    <button (click)="postNewRequest()" mat-raised-button color="primary">Submit</button>
                </td>
            </tr>
        </table>
    </div>
</ng-template>
