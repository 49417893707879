/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*
*/
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { DashboardChart } from 'src/app/shared/chart/dashboard-chart/dashboard-chart';
import { ActionPageComponent } from '../../crm/action-page/action-page.component';

import * as Highcharts from 'highcharts';
import { HttpParams } from '@angular/common/http';
import { uuid } from 'src/app/model/abstract-object';

class AboRow {
    omcId: uuid;
    omcName: string;
    actions: number;
    planned: number;
    done: number;
}

class AboData {
    startDate: string;
    startTS: number;
    rows: AboRow[];
}

export class AboChart extends DashboardChart {
    dataUrl = AbstractHttpService.ajaxPath + 'agent/dashboard/abo';
    dataParams = new HttpParams().set('week', 0);
    readonly route = ActionPageComponent.navRoute;

    readonly yAxisText: string = "Count Actions";

    title = "Busiest OMCs by Actions";

    categories: string[] = [];

    //series: { name: string, y: number, route: NavRoute, routeParams: Params, comt: string }[] = [];
    toDoSeries = { name: 'To Do', data: [] };
    plannedSeries = { name: 'Upcoming To Do', data: [] };
    doneSeries = { name: 'Completed', data: [] }
    series = [this.toDoSeries, this.plannedSeries, this.doneSeries];

    startDate = '';

    options = [];

    resetChart() {
        this.categories = [];
        this.toDoSeries = { name: 'Unplanned Items', data: [] };
        this.plannedSeries = { name: 'Planned Items', data: [] };
        this.doneSeries = { name: 'Items Completed', data: [] };
        this.series = [this.toDoSeries, this.plannedSeries, this.doneSeries];
    }

    getSubTitle() {
        return super.getSubTitle('Includes all Actions to do and done since ' + this.startDate);
    }

    getRouteParamsUnplanned(i: AboRow): unknown {
        return this.getShowParms({ omcId: i.omcId, planned: 0, completedAt: '', _sort: 'createdAt' });
    }

    getRouteParamsPlanned(i: AboRow): unknown {
        return this.getShowParms({ omcId: i.omcId, planned: 1, completedAt: '', _sort: 'plannedDate' });
    }

    getRouteParamsDone(i: AboRow, since: string): unknown {
        return this.getShowParms({ omcId: i.omcId, completedAt: '>=' + since, _sort: 'completedAt' });
    }

    protected setupData(data: AboData) {
        const route = this.route;
        this.startDate = data.startDate;

        for (const i of data.rows) {
            this.categories.push(i.omcName);
            i.done = Number(i.done);
            i.planned = Number(i.planned); // PHP considers a MYSQL Sum Result to be a string...

            this.toDoSeries.data.push({ name: i.omcName, y: (i.actions - i.done - i.planned), route, routeParams: this.getRouteParamsUnplanned(i) });
            this.plannedSeries.data.push({ name: i.omcName, y: (i.planned), route, routeParams: this.getRouteParamsPlanned(i) });
            this.doneSeries.data.push({ name: i.omcName, y: (i.done), route, routeParams: this.getRouteParamsDone(i, data.startDate) });
        }
    }

    clickEvent($event) {
        this.router.navigate([$event.point.route.url, $event.point.routeParams]);
    }

    protected createChartOptions(): Highcharts.Options {

        return {
            colors: ['red', 'orange', 'green'],
            chart: {
                backgroundColor: this.chartBackgroundColor,
                borderRadius: this.chartBorderRadius,
                plotBorderWidth: null,
                type: 'column',
                height: this.height,
                width: this.width
            },
            title: this.getChartTitle(),
            subtitle: this.getSubTitle(),
            credits: { enabled: false },
            tooltip: {
                pointFormat: '{series.name}: {point.y}'
            },
            xAxis: {
                categories: this.categories,
            },
            yAxis: {
                min: 0,
                title: { text: this.yAxisText }
            },
            legend: { enabled: false },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: false
                    }
                },
                series: {
                    events: {
                        click: this.clickEvent.bind(this)
                    },
                }
            },
            series: this.series
        } as unknown as Highcharts.Options;
    }
}
