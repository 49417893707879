/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { AbstractObject, uuid } from './abstract-object';
import { Attachment } from './attachment';
import { BCode } from './bcode';
import { Cycle } from './cycle';
import { Person } from './person';
import { PersonUnitRole } from './person-unit-role';
import { Supplier } from './supplier';
import { Txn } from './txn';
import { Unit } from './unit';

export class BankAnalysis extends AbstractObject { // Doesn't really extend, no id etc....
    bankAccountId: uuid;
    periodName: string;
    txnPeriodId: uuid;
    debits: number;
    credits: number;
    balance: number;
    openBalance: number;
}

export class BankRule extends AbstractObject {
    bankId: uuid;
    unitId?: uuid;
    unitName?: string;
    supplierId?: uuid;
    supplierName?: string;
    field: string;
    matches: string;
    isActive?: boolean;
    notes?: string;
}

export class BankItem extends AbstractObject {
    bankId: uuid;
    transactionId: uuid; // Is not a laravel UUID, but looks somewhat similiar, can be much longer
    bookingDate: string;
    transactionAmount: number;
    /** Calculated fields */
    description: string;
    field: string;
    matches: string;

    //data: unknown; // Whatever data the bank could provide...
    data: {
        remittanceInformationUnstructured: string;
        creditorName: string;
        remittanceInformationUnstructuredArray: string[];

        fromDate: string;
        openingBal: number;
        toDate: string;
        closingBal: number;
    }

    static assignDescription(bi: BankItem) {

        if (bi.transactionAmount < 0 && bi.data.creditorName) {
            bi.description = bi.data.creditorName;
            bi.field = 'creditorName';
            bi.matches = bi.data.creditorName;
        } else if (bi.data.remittanceInformationUnstructured) {
            bi.description = bi.data.remittanceInformationUnstructured;
            bi.field = 'remittanceInformationUnstructured';
            bi.matches = bi.data.remittanceInformationUnstructured;
        } else if (bi.data.remittanceInformationUnstructuredArray) {
            bi.description = bi.data.remittanceInformationUnstructuredArray.join(':');
            bi.field = 'remittanceInformationUnstructuredArray';
            bi.matches = bi.data.remittanceInformationUnstructuredArray.join(':');
        } else {
            bi.description = "No Description";
            bi.field = null;
            bi.matches = null;
        }
    }

}

export class BankAccount extends AbstractObject {

    IBAN: string;
    BIC: string;
    agentTeamId: uuid;
    bCodeId: uuid;
    bCode: BCode;
    chargesBCodeId: uuid;
    gocId: uuid;
    defaultAcct: boolean;
    txnAnalysis: BankAnalysis[] = [];
    /** Reconciliation Items... */
    openRecItems: BankItem[];
    units: Unit[];
    unitPeople: PersonUnitRole[];
    people: Person[];
    suppliers: Supplier[];
    outstandingInvoices: Txn[];
    purchaseInvoices: Txn[];
    
    statements: BankItem[] = [];
    rules: BankRule[] = [];
    attachments: Attachment[] = [];

    payments: Txn[];
    receipts: Txn[];
    currentCycle: Cycle;

    constructor(o: Partial<BankAccount> = {}) {
        super(o);
    }

    /*
    * Never Validated from: https://stackoverflow.com/questions/21928083/iban-validation-check
    * This could be better: https://github.com/Simplify/ibantools/blob/master/src/IBANTools.ts
    */
    /*
     * Returns 1 if the IBAN is valid
     * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
     * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
     */
    isValidIBANNumber(input) {
        const CODE_LENGTHS = {
            AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
            CH: 21, CR: 22, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
            FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
            HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
            LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
            MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
            RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,
            AL: 28, BY: 28, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
            SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
        };
        const iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''); // keep only alphanumeric characters
        const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest

        // check syntax and length
        if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
            return false;
        }
        // rearrange country code and check digits, and convert chars to ints
        const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (match) => String(match.charCodeAt(0) - 55));

        // final check
        return this.mod97(digits);
    }

    mod97(string) {
        let checksum = string.slice(0, 2), fragment;
        for (let offset = 2; offset < string.length; offset += 7) {
            fragment = String(checksum) + string.substring(offset, offset + 7);
            checksum = parseInt(fragment, 10) % 97;
        }
        return checksum;
    }

    static validateIBAN(value: string): boolean {
        if (value.length !== 22) {
            return false;
        }
        if (value.substring(0, 2) !== "IE") {
            return false;
        }

        return true;
    }
}
