@if (on==='form') {
    @if (!control.field.readonly && !control.field.disable) {
        <div class="mat-form-field-wrapper">
            <editor [init]="editorOptions" data-id="{{control.field.name}}-RichTextField" [formControl]="control"
                placeholder="control.field.label" tinyMatFormControl>
            </editor>
            <mat-error>
                <app-form-error [(control)]="control"></app-form-error>
            </mat-error>
        </div>
    } @else {
        <mat-form-field style="height: 312px" floatLabel="always">
            @if (control.field.hint) {
            <mat-icon matSuffix color="primary" [matTooltip]="control.field.hint">info</mat-icon>
            }
            <mat-label>{{control.field.label}}</mat-label>
            <input matInput type="text" [formControl]="control" style="display:none">
            <div [innerHTML]="control.value" style="overflow: auto; max-height: 280px;"></div>
        </mat-form-field>
    }
} @else {
    <div style="margin-top:14px" (click)="showFullContent()" matTooltip="Click to see full content">{{control.field.formatValue(control.value)}}</div>
}
