
/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { trigger, state, style, transition, animate, query, group, animateChild } from '@angular/animations';

export const STD_ANIMATION = trigger('StdAnimation', [
  // the "in" style determines the "resting" state of the element when it is visible.
  state('in', style({ opacity: 1 })),

  // fade in when created. this could also be written as transition('void => *')
  transition(':enter', [
    style({ opacity: 0 }),
    animate(600)
  ]),

  // fade out when destroyed. this could also be written as transition('void => *')
  transition(':leave',
    animate(300, style({ opacity: 0 })))
]);

export const slideInAnimation =
  trigger('routeAnimations', [
    transition(':increment', [
        style({position: 'relative'}),
        query(
          ':enter, :leave',
          [
            style({
              position: 'absolute',
              top: 0,
              right: 0,
              width: '100%',
            }),
          ],
          {optional: true},
        ),
        query(':enter', [style({right: '-100%'})], {optional: true}),
        query(':leave', animateChild(), {optional: true}),
        group([
          query(':leave', [animate('1000ms ease-out', style({right: '100%', opacity: 0}))], {
            optional: true,
          }),
          query(':enter', [animate('1000ms ease-out', style({right: '0%'}))], {optional: true}),
          query('@*', animateChild(), {optional: true}),
        ]),
      ]),
      transition(':decrement', [
        style({position: 'relative'}),
        query(
          ':enter, :leave',
          [
            style({
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
            }),
          ],
          {optional: true},
        ),
        query(':enter', [style({left: '-100%'})], {optional: true}),
        query(':leave', animateChild(), {optional: true}),
        group([
          query(':leave', [animate('1000ms ease-out', style({left: '100%', opacity: 0}))], {
            optional: true,
          }),
          query(':enter', [animate('1000ms ease-out', style({left: '0%'}))], {optional: true}),
          query('@*', animateChild(), {optional: true}),
        ]),
      ]),
      transition('listRoute => itemRoute', [
          style({ opacity: 1 }),
          query(':enter', [
              style({ opacity: 0 }),
              animate(1000)
          ], { optional: true })
      ]),
      transition('* => *', [
          style({ opacity: 1 }),
          query(':enter', [
              style({ opacity: 0 }),
              animate(1000)
          ], { optional: true })
      ]),
  ]);
