/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Field } from 'src/app/shared/field/Field';
import { FormConfig } from "../../form/FormConfig";
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
    standalone: true,
    imports: [NgTemplateOutlet, MatTooltipModule, MatMenuModule, MatButtonModule, MatIconModule, FormsModule]
})
export class FilterComponent {

    @Input() config: FormConfig;

    @Output() navigateEvent = new EventEmitter();

    constructor(protected router: Router) { }

    toggleFilter(field: Field) {
        const wasFiltered = field.filter.active && field.filter.value !== 'any';

        field.filter.active = !field.filter.active;
        field.filter.value = 'any';
        if (!field.filter.active) {
            this.resetFieldVisible(field);
        }

        if (wasFiltered) {
            this.navigateEvent.emit();
        }
    }
    resetFieldVisible(field: Field) {
        field.visible.phone = field.filter.priorPhone;
        field.visible.computer = field.filter.priorComputer;
    }

    filterToolTip(field: Field) {
        if (field.filter.value) {
            return field.label + ' = ' + field.filter.value;
        } else {
            return field.label;
        }
    }

    itemChanges($event, field: Field) {
        let theValue = $event.target.value;
        if (field.value === 'refNr') {
            theValue = theValue.replaceAll(',', '');
        }
        field.filter.value = theValue;
        if (field.filter.value === 'any') {
            this.resetFieldVisible(field);
        }
        this.navigateEvent.emit();
    }

    filterable(f: Field) {
        if (!f) return false;
        if (!f.allowFiltering) return false;
        if (f.value === 'refNr') return true;

        if (f.type === 'picklist'
            || (f?.type === 'text' && !f?.value?.includes('.'))
            || f.type === 'dateTime'
            || f.type === 'date'
            || f.type === 'number'
            || f.type === 'email') {
            return true;
        } else {
            return false;
        }
    }

    isMultiPL(f: Field) {
        if (f.filter.value === null || f.filter.value === undefined || f.filter.value === '' || typeof f.filter.value === 'number') {
            return false;
        } else if (typeof f.filter.value === 'string') {
            if (f.filter.value.indexOf(',') > 0) {
                return true
            }
        } else {
            console.warn('what is this...')
        }
        return false;
    }
}
