/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { ServiceRequest } from "src/app/model/serviceRequest";
import { AbstractHttpService } from "src/app/shared/abstract-http.service";
import { ServiceRequestPageComponent } from "../../crm/service-request-page/service-request-page.component";
import { UnreadChart } from "./UnreadChart";

export class OverdueRequestsChart extends UnreadChart {
    dataUrl = AbstractHttpService.ajaxPath + 'agent/dashboard/overdueReqs';
    title = "My Overdue Requests";

    getSubTitle() {
        return super.getSubTitle($localize`Requests planned to complete on or before today`);
    }

    clickEvent() {
        const url = ServiceRequestPageComponent.navRoute.url;
        const params = { ownedById: this.currentUser.id, completedAt: '', subStatus: 'overdue', srStatus: ServiceRequest.srOpen.id };

        this.router.navigate([url, params]);
    }
}
