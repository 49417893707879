/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Input, ViewContainerRef } from '@angular/core';
import { FormControlInterface } from 'src/app/shared/form/form-control-interface';
import { ControlOn, AppFormControl } from 'src/app/shared/form/app-form-control';
import { Field } from 'src/app/shared/field/Field';
import { NgClass } from '@angular/common';
import { FormErrorComponent } from '../form-error/form-error.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-form-text',
  templateUrl: './form-text.component.html',
  styleUrls: ['./form-text.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, MatIconModule, MatTooltipModule, MatInputModule, FormsModule, ReactiveFormsModule, FormErrorComponent, NgClass]
})
export class FormTextComponent implements FormControlInterface {

  @Input() label: string;
  @Input() control: AppFormControl;
  @Input() on: ControlOn = 'form';

  public static make(label: string, value: string, options: Partial<Field> = {}) {
    const field: Field = new Field({ label, value }, options);
    field.formControlFactory = FormTextComponent.createComponent;
    return field;
  }

  public static createComponent(vcr: ViewContainerRef, ctl: AppFormControl, on: ControlOn) {
    const componentRef = vcr.createComponent(FormTextComponent);
    componentRef.instance.control = ctl;
    componentRef.instance.on = on;
  }
}
