/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';

@Component({
    selector: 'app-o-auth-callback',
    templateUrl: './o-auth-callback.component.html',
    styleUrls: ['./o-auth-callback.component.scss'],
    standalone: true
})
export class OAuthCallbackComponent {

}
