<div cdkDropList id="{{control.field.name}}" cdkDropListSortingDisabled="true" [cdkDropListData]="control.value"
    [cdkDropListConnectedTo]="control.field.fileOpts.dragsTo" (cdkDropListDropped)="drop($event)" width="100%">
    <!--  class="files-list" -->
    @if (on === 'form') {
    <mat-form-field [style.width.px]="control.field.fileOpts.widthPx + 4" floatLabel="always">
        <mat-label>{{control.field.label}}</mat-label>
        <input matInput type="text" [value]="control.value" [formControl]="control" style="display:none" tabindex="-1">
        @if (!control.value || control.value.length === 0) {
        <div style="height: 16px"></div>
        }
        @if (control.value && control.value.length > 0) {
        <table width="100%" ngClass="{'mat-elevation-z8 ouromc-table file-table': on!=='form'">
            @for (attachment of control.value; track attachment) {
            <tr>
                <td>
                    @switch (attachment.ext) {
                    @case ('pdf') {
                    <img src="./assets/file-types/file_type_pdf_icon_32.png" />
                    }
                    @case ('doc') {
                    <img src="./assets/file-types/word_32.png" height="28px" width="28px" />
                    }
                    @case ('docx') {
                    <img src="./assets/file-types/word_32.png" height="28px" width="28px" />
                    }
                    @case ('xls') {
                    <img src="./assets/file-types/excel_32.png" height="28px" width="28px" />
                    }
                    @case ('xlsx') {
                    <img src="./assets/file-types/excel_32.png" height="28px" width="28px" />
                    }
                    @case ('msg') {
                    <img src="./assets/file-types/outlook.png" height="28px" width="28px" />
                    }
                    @case ('jpg') {
                    <img src="./assets/file-types/icon-image.png" height="28px" width="28px" />
                    }
                    @case ('jpeg') {
                    <img src="./assets/file-types/icon-image.png" height="28px" width="28px" />
                    }
                    @case ('gif') {
                    <img src="./assets/file-types/icon-image.png" height="28px" width="28px" />
                    }
                    @default {
                    <mat-icon color="primary">attachment</mat-icon>
                    }
                    }
                </td>
                <td>
                    <!-- a routerLink="/crm/documents/{{attachment.id}}">{{attachment.name}}</a -->
                    <a href="" (click)="downloadFile($event, attachment)"
                        [matTooltip]="attachment.notes">{{attachment.name}}</a>
                    @if (!control.field?.fileOpts?.smallAddBox) {
                    <p class="notes">
                        {{attachment.notes}}
                    </p>
                    }
                </td>
                <td class="size" [matTooltip]="moment(attachment.createdAt * 1000)">
                    {{attachment.createdAt | fromNow}}
                </td>
                <td class="size" style="padding-left:10px">
                    {{ attachment.size | byteCount }}
                </td>
                <td>
                    @if (!control.field?.fileOpts?.smallAddBox) {
                    <button (click)="editAttachment(attachment)" mat-raised-button color="primary">
                        Edit
                    </button>
                    } @else {
                    <button (click)="editAttachment(attachment)" mat-icon-button color="primary" style="font-size: 16px; width:20px">
                        <mat-icon style="font-size: 16px;">edit</mat-icon>
                        @if (!control.field?.fileOpts?.smallAddBox) {
                        <span>Edit</span>
                        }
                    </button>
                    }
                </td>
                @if (control.field.fileOpts.allowInvoice && attachment.teamId === currentUser.current_team_id && !currentUser.inAgency) {
                <td>
                    <button mat-raised-button class="normal" color="primary" (click)="invoiceNow(attachment)"
                        style="font-size: 14px; margin-left: 10px;">
                        <mat-icon style="font-size: 16px; padding-top: 4px;">shopping_basket</mat-icon>Invoice
                    </button>
                </td>
                }
                <td>
                    @if (control.field.fileOpts.allowDelete) {
                    <button (click)="deleteAttachment(attachment)" mat-icon-button color="primary"
                        style="font-size: 16px; width:20px">
                        <mat-icon style="font-size: 16px;">delete</mat-icon>
                    </button>
                    }
                </td>
            </tr>
            }
        </table>
        }
        <!-- #doneList="cdkDropList"-->
        <!-- span>{{control.field.label}}</span -->
        <!-- div class="single-file" *ngFor="let attachment of control.value; let i = index" cdkDrag
      [cdkDragData]="{ attachment: attachment, ctl: control}" [style.width]="control.field.fileOpts.widthPx">
      <button (click)="editAttachment(attachment)" mat-icon-button color="primary"
        style="font-size: 16px; width:20px">
        <mat-icon style="font-size: 16px;">edit</mat-icon>
      </button>
      <div class="info">
        <h4 class="name" (click)="downloadFile(attachment)">
          {{ attachment.name }}
        </h4>
        <p class="notes">
          {{attachment.notes}}
        </p>
        <p class="size">
          {{ attachment.size | byteCount }}
        </p>
      </div>
      <button *ngIf="control.field.fileOpts.allowInvoice && attachment.teamId === currentUser.current_team_id" mat-raised-button  class="normal" color="primary"
        (click)="invoiceNow(attachment)" style="font-size: 14px; margin-left: 10px;">
        <mat-icon style="font-size: 16px; padding-top: 4px;">shopping_basket</mat-icon>Invoice
      </button>
      <button *ngIf="control.field.fileOpts.allowDelete" (click)="deleteAttachment(attachment)" mat-icon-button color="primary" style="font-size: 16px; width:20px">
        <mat-icon style="font-size: 16px;">delete</mat-icon>
      </button>
      </div -->
        <table width="100%">
            <tr>
                <td></td>
                <td>@if (control.field.fileOpts.allowNew && control.field.fileOpts.deferredFiles?.length < 1 && false) {
                        <p>
                        </p>
                        }
                        @if (control.field.fileOpts.allowNew) {
                        <app-file-upload [control]="control" [attachToId]="getAttachTo()">
                        </app-file-upload>
                        }
                </td>
                <td></td>
            </tr>
        </table>
    </mat-form-field>
    }
</div>
