/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { AbstractObject, uuid } from './abstract-object';

export class Frequency extends AbstractObject {
    name: string;
    perAnnum: number;

    static readonly NEVER = { id: 0, name: $localize`Never`, perAnnum: 0 };
    static readonly YEARLY = { id: 1, name: $localize`Annually`, perAnnum: 1 };
    static readonly BIANNUAL = { 'id': 2, name: $localize`Twice Per Year`, perAnnum: 2 };
    static readonly TRIANNUAL = { 'id': 3, name: $localize`Three Per Year`, perAnnum: 3 };
    static readonly QUARTERLY = { 'id': 4, name: $localize`Quarterly`, perAnnum: 4 };
    static readonly BIMONTHLY = { 'id': 6, name: $localize`Every 2 Months`, perAnnum: 6 };
    static readonly MONTHLY = { 'id': 12, name: $localize`Monthly`, perAnnum: 12 };
    static readonly QUADWEEKLY = { 'id': 13, name: $localize`Every 4 weeks`, perAnnum: 13 };
    static readonly BIWEEKLY = { 'id': 26, name: $localize`Every 2 weeks`, perAnnum: 26 };
    static readonly WEEKLY = { 'id': 52, name: $localize`Weekly`, perAnnum: 52 };

    static readonly PAYMENT_OPTIONS: Frequency[] = [
        Frequency.YEARLY,
        Frequency.MONTHLY,
    ]
    static readonly ALL_OPTIONS: Frequency[] = [
        Frequency.NEVER,
        Frequency.YEARLY,
        Frequency.BIANNUAL,
        Frequency.TRIANNUAL,
        Frequency.QUARTERLY,
        Frequency.BIMONTHLY,
        Frequency.MONTHLY,
        Frequency.QUADWEEKLY,
        Frequency.BIWEEKLY,
        Frequency.WEEKLY
    ]

    static getFrequency(id: uuid): Frequency {
        return this.ALL_OPTIONS.find(f => f.id === id);
    }
}
