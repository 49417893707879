<div class="basePage">
  <div class="registerCard">
    <mat-card appearance="raised">
      <div style="text-align:center; padding-top: 20px">
        <div class="title">
          <img src="./assets/ouromc-32.png">
        </div>
      </div>
      <p class="title">Welcome to OurOMC</p>
      <p class="subtitle">We are delighted to see you registering for our service to participate in your Owner
      Management Company</p>
      <div class="registerForm">
        <mat-form-field class="login-full-width">
          <mat-label>Username</mat-label>
          <input data-login-username matInput [(ngModel)]="username" name="username" required>
          <mat-hint>Please enter your username, as you would like it to be displayed to others in the system
          </mat-hint>
        </mat-form-field>
        @if (username && username.length < 5) {
          <p class="error">Please enter a longer user name</p>
        }
        <mat-form-field style="padding-top: 3em">
          <input data-login-password matInput placeholder="Password" [(ngModel)]="password"
            [type]="hide ? 'password' : 'text'" name="password" required
            (ngModelChange)="calcPasswordStrength()">
          <button mat-icon-button matSuffix (click)="showHidePassword()">
            <mat-icon>
              {{hide ? 'visibility_off' : 'visibility'}}
            </mat-icon>
          </button>
          <mat-hint>Please enter a reasonably strong password. The indicator below shows password strength {{hide}}
          </mat-hint>
        </mat-form-field>
        <p></p>
        <div class="passwordBar">
          <div class="passwordStrength" [style.background]="passwordStrengthColor"
            [style.width.%]="passwordStrengthPct">
            {{passwordStrengthText}}
          </div>
        </div>
        <mat-form-field class="login-full-width">
          <input data-login-password matInput placeholder="Confirm Password" [(ngModel)]="passwordConfirm"
            [type]="hide ? 'password' : 'text'" name="passwordConfirm" required>
          <button mat-icon-button matSuffix  (click)="showHidePassword()">
            <mat-icon>
              {{hide ? 'visibility_off' : 'visibility'}}
            </mat-icon>
          </button>
        </mat-form-field>
        @if (password !== '' && passwordConfirm !== '' && password !== passwordConfirm) {
          <p class="error">
          Passwords do not match</p>
        }
        <button mat-raised-button data-login (click)="register()" color="primary" style="margin-bottom: 20px"
        [disabled]="(passwordStrengthPct <= 40 || password !== passwordConfirm)!">Register</button>
      </div>
    </mat-card>
  </div>
</div>
