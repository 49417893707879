/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, inject, Injector } from '@angular/core';
import { NavigationComponent } from './shared/navigation/navigation.component';
import { MyInjector } from './app.module';
import { MatIconRegistry } from '@angular/material/icon';
import { ActivatedRoute, ActivationStart, Router } from '@angular/router';
import { AbstractHttpService } from './shared/abstract-http.service';
import { HttpClient } from '@angular/common/http';
import { LeadProfileService } from './modules/public/lead-profile.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NavigationComponent]
})
export class AppComponent {
  title = 'ouromc';
  injector = inject(Injector);
  iconRegistry = inject(MatIconRegistry);
  router = inject(Router);
  activatedRoute = inject(ActivatedRoute)
  http = inject(HttpClient);
  //currentUser: User;

  static readonly appName = $localize`OurOMC`;

  constructor() {
    MyInjector.instance = this.injector;
    this.iconRegistry.setDefaultFontSetClass('material-symbols-outlined');

    const subsy = this.router.events.subscribe( e => {
        if (e instanceof ActivationStart) {
            let campaignId;
            // It seems it can apprear in either...
            if (e.snapshot.paramMap.has('_campaignId')) {
                campaignId = e.snapshot.params._campaignId;
            }
            if (e.snapshot.queryParamMap.has('_campaignId')) {
                campaignId = e.snapshot.queryParams._campaignId;
            }

            if ( campaignId ) {
                const r = e.snapshot;
                const np = Object.assign({}, e.snapshot.queryParams);
                delete np._campaignId;
                this.sendMessage(campaignId);
                const lps = MyInjector.instance.get(LeadProfileService);
                lps.markAccessValidated();
                this.router.navigate([r.routeConfig.path], {queryParams: np});
            }
            subsy.unsubscribe();
        }
    })
  }
    sendMessage(campaignId: string) {
        const url = AbstractHttpService.ajaxPath + 'informus';

        const page = window.location.href;
        const message = `<p>Go on, they are interested in ${campaignId} chase them down and make them buy</p>
                        <p>They are currently on ${page}</p>
        `
        const data = {
            message,
            subject: `Good News - Someone clicked on campaign ${campaignId}`,
            sendTo: `sales`
        }

        this.http.post(url, data).subscribe(() => {
            console.log('wow!');
        }, () => {
            console.log('surprise!')
        })
    }

}
