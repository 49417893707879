/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*
* Parent Class of all dashboard charts.
*/

import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import { first } from 'rxjs/operators';
import { User } from 'src/app/model/user';

export abstract class DashboardChart {
    name: string;
    dataUrl: string;
    dataParams = new HttpParams();
    divId: string;

    private highChart: Highcharts.Chart;

    abstract title: string;

    options: { name: string, action: () => void }[] = [];

    chartBackgroundColor = 'rgba(240, 239, 238, 1)';
    chartBorderRadius = 20;

    constructor(protected router: Router, protected http: HttpClient, protected currentUser: User,
        protected width: number, protected height: number) {
    }

    getShowParms(parms: unknown) {
        if (this.dataParams.has('show')) {
            const what = this.dataParams.get('show');
            if (what === 'myrequests') {
                parms['ownedById'] = this.currentUser.id;
            } else if (what === 'myomcs') {
                parms['omc.managerId'] = this.currentUser.id;
            }
        }
        return parms;
    }

    getTooltip() {
        return {
            pointFormat: '{series.name}: {point.y} <br/><b>{point.pct:.1f}%</b>'
        };
    }

    getChartTitle() {
        return { text: this.title, style: { fontSize: '14px', fontWeight: 'bold' } };
    }

    getSubTitle(subTitle: string) {
        //return { text: this.getSeriesName(), style: {fontSize: '12px'} }
        return { text: subTitle };
    }

    protected abstract createChartOptions(): Highcharts.Options;
    protected abstract setupData(data: unknown): void;

    protected resetChart() {
        // Override me.
    }

    public getData(divId: string = this.divId) {
        this.divId = divId;
        this.http.get(this.dataUrl, { params: this.dataParams }).pipe(first()).subscribe((data: unknown[]) => {
            this.resetChart();
            this.showChart(data, divId);
        })
    }

    protected showChart(data: unknown, divId: string) {
        this.setupData(data);
        this.highChart = Highcharts.chart(divId, (this.createChartOptions() as Highcharts.Options));
    }

    reSize(width: number, height: number) {
        this.width = width;
        this.height = height;
        if (this.highChart) {
            this.highChart.setSize(width, height);
            //this.highChart.redraw();
        }
    }
}
