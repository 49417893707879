<div class="basePage">
  <!-- div class="registerCard">
  <mat-card appearance="raised">
    <mat-card-header>
      <mat-card-title>Trial Balance - {{cycle?.name}}</mat-card-title>
      <mat-card-subtitle>{{companyName}}</mat-card-subtitle>
      <mat-card-subtitle>{{cycle?.from}} - {{cycle?.to}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content style="margin-top: 20px" *ngIf="ready">
      <ng-container *ngTemplateOutlet="details"></ng-container>
    </mat-card-content>
  </mat-card>
  </div -->
  <div [ngClass]="{registerCard: !showSchedules}">
    <h1>
      Income Expenditure - {{cycle?.name}}
    </h1>
    <h2>
      {{companyName}}
    </h2>
    <table width="100%" style="border-spacing: 0px;">
      <tr>
        <td>
          <app-ctl-holder [control]="cycleField.control"></app-ctl-holder>
        </td>
        <td style="max-width: 10em">
          <app-ctl-holder [control]="fromField.control"></app-ctl-holder>
        </td>
        <td style="max-width: 10em">
          <app-ctl-holder [control]="toField.control"></app-ctl-holder>
        </td>
        <td>
          <app-ctl-holder [control]="zerosField.control"></app-ctl-holder>
        </td>
        <td>
          <app-ctl-holder [control]="totalsField.control"></app-ctl-holder>
        </td>
        @if (schedulesToShow) {
            <td>
                <app-ctl-holder [control]="schedulesField.control"></app-ctl-holder>
            </td>
        }
      </tr>
    </table>
    <!-- ng-container [ngTemplateOutlet]="detailsOld" *ngIf="ready"></ng-container -->
    <ng-container [ngTemplateOutlet]="details"></ng-container>
  </div>
</div>
<ng-template #details>
  <mat-card style="margin-bottom: 20px">
    <mat-card-content style="max-height: calc(100vh - 350px); overflow: auto; padding:20px; padding-top: 0px" >
      <table class="balances" width="100%" style="border-collapse: collapse;">
        <ng-container [ngTemplateOutlet]="headers"></ng-container>
        @for (type of types; track type) {
          <ng-container [ngTemplateOutlet]="typeHeader" [ngTemplateOutletContext]="{type: type}"></ng-container>
          @for (bcode of type['bcodes']; track bcode) {
            <ng-container [ngTemplateOutlet]="lineItem"
              [ngTemplateOutletContext]="{bcode: bcode}">
            </ng-container>
          }
          <ng-container [ngTemplateOutlet]="typeFooter"
          [ngTemplateOutletContext]="{type: type}"></ng-container>
        }
        <ng-container [ngTemplateOutlet]="totals"></ng-container>
      </table>
    </mat-card-content>
  </mat-card>
</ng-template>
<ng-template #headers>
  <thead>
    <tr>
      <th></th>
      <th></th>
      <th></th>
      @if (showTotals) {
        <th colspan="5" style="border-bottom: 1px solid">Totals</th>
      }
      @if (showSchedules) {
        @for (schedule of myData?.schedules; track schedule) {
          <th></th>
          <th colspan="4" style="border-bottom: 1px solid;">{{schedule.name}}</th>
        }
      }
    </tr>
    <tr>
      <th class="mat-header-cell">
        Account
      </th>
      <th>
        Sort Code
      </th>
      <th style="min-width:20px"></th>
      @if (showTotals) {
        <th>
          Count
        </th>
        <th colspan="2">
          {{cycle?.name}}
        </th>
        <th>
          {{previousCycle?.name}}
        </th>
        <th>
          Budget
        </th>
      }
      @if (showSchedules) {
        @for (schedule of myData?.schedules; track schedule) {
          <th style="min-width:20px"></th>
          <th colspan="2">
            {{cycle.name}}
          </th>
          <th>
            {{previousCycle?.name}}
          </th>
          <th>
            Budget
          </th>
        }
      }
    </tr>
  </thead>
</ng-template>
<ng-template #typeHeader let-type="type">
  <tr>
    <td colspan="3" style="text-align: left; border-bottom: 1px inset; ">
      <h3>{{type.name}}</h3>
    </td>
    <td></td>
  </tr>
</ng-template>
<ng-template #lineItem let-bcode="bcode">
  <tr class="detail">
    <td class="detail" style="padding-left: 20px">
      <a [routerLink]="getAccountLink(bcode)">{{bcode.name}}</a>
    </td>
    <td class="detail" style="text-align: right; "> {{bcode.sort}} </td>
    <td></td>
    @if (showTotals) {
      <td style="text-align: right;">
        <a [routerLink]="getAccountTxn(bcode)">{{bcode.count}}</a>
      </td>
      <td class="detail" style="text-align: right"> {{bcode.total | currency : 'EUR' : 'symbol' : '1.0-0'}} </td>
      <td class="detail" style="text-align: left; width: 10px; vertical-align:bottom">
        <mat-icon style="font-size: 16px; margin-top: 8px">
          {{getArrow(bcode.total, bcode.prevTotal)}}
        </mat-icon>
      </td>
      <td class="detail" style="text-align: right">
        <span [ngClass]="{zero: bcode.prevTotal === 0}">{{bcode.prevTotal | currency : 'EUR' : 'symbol' : '1.0-0'}}</span>
      </td>
      <td style=" text-align: right; padding-right: 5px">
        @if (bcode.typeId === 5) {
          <span>{{ bcode.budget | currency : 'EUR' : 'symbol' : '1.0-0'}}</span>
        }
      </td>
    }
    @if (showSchedules) {
      @for (s of myData?.schedules; track s) {
        <td></td>
        <td class="detail" style="text-align: right">
          <span [ngClass]="{zero: bcode[totalName(s.id)] === 0}">
            {{bcode[totalName(s.id)] | currency : 'EUR' : 'symbol' : '1.0-0'}}
          </span>
        </td>
        <td class="detail" style="text-align: left; width: 10px; vertical-align:bottom">
          <mat-icon style="font-size: 16px; margin-top: 8px">
            {{getArrow(bcode[totalName(s.id)], bcode[prevName(s.id)])}}
          </mat-icon>
        </td>
        <td class="detail" style="text-align: right">
          <span [ngClass]="{zero: bcode[prevName(s.id)] === 0}">
            {{bcode[prevName(s.id)] | currency : 'EUR' : 'symbol' : '1.0-0'}}
          </span>
        </td>
        <td class="detail" style=" text-align: right; padding-right: 5px">
          @if (bcode.typeId === 5) {
            <span [ngClass]="{zero: bcode[budgetName(s.id)] === 0}">
              {{ bcode[budgetName(s.id)] | currency : 'EUR' : 'symbol' : '1.0-0'}}
            </span>
          }
        </td>
      }
    }
  </tr>
</ng-template>
<ng-template #typeFooter let-type="type">
  <tr>
    <td class="detail" style="text-align:right; " colspan="2">
      {{type.name}}
    </td>
    <td style="border-bottom:1px"></td>
    @if (showTotals) {
      <td></td>
      <td class="detail" style="text-align: right; border-bottom: 1px inset">
        {{type.total | currency : 'EUR' : 'symbol' : '1.0-0'}}
      </td>
      <td class="detail" style="text-align: left; width: 10px; vertical-align:bottom">
        <mat-icon style="font-size: 16px; margin-top: 8px">
          {{getArrow(type.total, type.prevTotal)}}
        </mat-icon>
      </td>
      <td class="detail" style="text-align: right; border-bottom: 1px inset">
        {{type.prevTotal | currency : 'EUR' : 'symbol' : '1.0-0'}}
      </td>
      <td class="detail" style="text-align: right; border-bottom: 1px inset">
        @if (type.id === 5) {
          <span>{{budget | currency : 'EUR' : 'symbol' : '1.0-0'}}</span>
        }
      </td>
    }
    @if (showSchedules) {
      @for (s of myData?.schedules; track s) {
        <td></td>
        <td class="detail" style="text-align: right">{{type[totalName(s.id)] | currency : 'EUR' : 'symbol' : '1.0-0'}} </td>
        <td class="detail" style="text-align: left; width: 10px; vertical-align:bottom">
          <mat-icon style="font-size: 16px; margin-top: 8px">
            {{getArrow(type[totalName(s.id)], type[prevName(s.id)])}}
          </mat-icon>
        </td>
        <td class="detail" style="text-align: right"> {{type[prevName(s.id)] | currency : 'EUR' : 'symbol' : '1.0-0'}} </td>
        <td class="detail"  style="text-align: right; border-bottom: 1px inset">
          @if (type.id === 5) {
            <span>{{s.budget | currency : 'EUR' : 'symbol' : '1.0-0'}}</span>
          }
        </td>
      }
    }
  </tr>
</ng-template>
<ng-template #totals>
  <tr>
    <td colspan="3" class="total">
      @if (profit > 0) {
        <span>Surplus</span>
      }
      @if (profit < 0) {
        <span class="bad">Deficit</span>
      }
    </td>
    @if (showTotals) {
      <td class="total"></td>
      <td class="total totalBorder" [ngClass]="{'bad': profit < 0}">{{profit | currency : 'EUR' : 'symbol' : '1.0-0'}}</td>
      <td></td>
      <td class="total totalBorder" [ngClass]="{'bad': prevProfit < 0}">{{prevProfit | currency : 'EUR' : 'symbol' : '1.0-0'}}</td>
      <td></td>
    }
    @if (showSchedules) {
      @for (s of myData?.schedules; track s) {
        <td></td>
        <td class="total totalBorder" [ngClass]="{'bad': s.total < 0}">{{s.total | currency : 'EUR' : 'symbol' : '1.0-0'}}</td>
        <td class="detail" style="text-align: left; width: 10px; vertical-align:bottom">
          <mat-icon style="font-size: 16px; margin-top: 8px">
            {{getArrow(s.total, s.prevTotal)}}
          </mat-icon>
        </td>
        <td class="total totalBorder">
          {{s.prevTotal | currency : 'EUR' : 'symbol' : '1.0-0'}}
        </td>
        <td></td>
      }
    }
  </tr>
  <tr>
    <td> </td>
  </tr>
</ng-template>
