/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { RequestCategory } from 'src/app/model/RequestCategory';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RequestCategoriesService extends AbstractHttpService {

    protected baseUrl = this.ajaxPath + 'crm/categories';
    protected cache: RequestCategory[];
    protected typeString = 'Request Category';

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super();
    }

    getAvailable(): Observable<RequestCategory[]> {
        return this.http.get(this.baseUrl + '/available') as Observable<RequestCategory[]>;
    }
}
