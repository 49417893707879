
<mat-card appearance="raised" class="basecard'" style="margin:20px;">
  <div style="display: none;">
    <!--I am forcing matInput CSS to get included... -->
    <!-- app-form-text [control]="control"></app-form-text -->
  </div>
  <mat-card-title style="margin-bottom: 2px; padding-left: 20px; padding-top: 10px">Purchase Uploads Page</mat-card-title>
  <mat-card-subtitle style="margin-top: 6px; padding-left: 20px; padding-right: 20px">
    Upload all incoming invoices here, you can raise the purchase invoice in the system later.
    @if(currentUser?.inAgency) {
        You can load all of the incoming invoices, for all OMCs on this page.
        Click the edit button to edit comments and/or move the file into the OMC for processing.
        Once assigned to an OMC, items are removed from this list.
    }
  </mat-card-subtitle>
  <div width= "80%" style="padding-left:10%; padding-right: 10%; padding-top: 30px;">
    <app-attachment-list [control]="control" [attachToId]=-1></app-attachment-list>
    <!-- app-file-upload [control]="control"></app-file-upload -->
    @if (this.files.length > 0) {
      <span i18n >Select the company to upload to and edit the name and notes if desired. No files are sent to server until you press save.</span>
    }
    <!-- app-grid [control]="uploadGrid.control" *ngIf="this.files.length > 0"></app-grid -->
  </div>
  <!--
  <button (click)="saveFiles()" mat-raised-button color="primary" *ngIf="this.files.length > 0" class="normal">
    <mat-icon>save</mat-icon><span i18n>Save All</span>
  </button>
  -->
</mat-card>
