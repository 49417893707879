/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { Supplier } from 'src/app/model/supplier';
import { uuid } from 'src/app/model/abstract-object';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SupplierService extends AbstractHttpService {

    protected http = inject(HttpClient);
    protected messageService = inject(MessageService);
    protected baseUrl = this.ajaxPath + 'suppliers';
    protected cache: Supplier[];
    protected typeString = 'Supplier';

    agentMap: Map<uuid, Supplier[]> = new Map();

    getAgentTypes(agentTeamId: uuid): Observable<Supplier[]> {
        if (this.agentMap.has(agentTeamId)) {
            return of(this.agentMap.get(agentTeamId));
        } else {
            return this.http.get<Supplier[]>(this.baseUrl + '/agentTypes/' + agentTeamId).pipe(
                map(types => {
                    this.agentMap.set(agentTeamId, types);
                    return types;
                })
            );
        }
    }
}
