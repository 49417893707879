
@if (dialogOptions) {
  <mat-dialog-content style="width:100%; min-width: 80vw; height: 100%">
    <ng-container *ngTemplateOutlet="chatContent"></ng-container>
  </mat-dialog-content>
}
@if (!dialogOptions) {
  <ng-container *ngTemplateOutlet="chatContent"></ng-container>
}
<ng-template #chatContent>
  <div class="chatContent" style="width: 100%">
    @if ( (!chatField.chat.allowComments || chatField.chat.hideFollowers)  && chatField.chat.comments.length > 0) {
        <h1>{{chatField.chat.comments.length}} Comment(s)</h1>
    }
    @if (chatField.chat.allowComments && !chatField.chat.hideFollowers) {
        <ng-container *ngTemplateOutlet="optionMenu"></ng-container>
        <app-chat-followers [chatField]="control.field"></app-chat-followers>
    }
    <table>
      @for (ci of getChatItems(); track ci) {
        <ng-container *ngTemplateOutlet="recursive; context: {$implicit: {chatItem:ci, depth: 0}}"></ng-container>
      }
    </table>
    @if (chatField.chat.allowComments) {
        <button mat-button mat-raised-button color="primary" style="margin:10px" (click)="chatUpsert({})" data-add-comment>
            <mat-icon>add_circle</mat-icon>Add Comment
        </button>
    }
  </div>
</ng-template>
<ng-template #recursive let-myparms let-depth let-isFirst='isFirst' let-isLast='isLast'>
  <div app-chat-entry
    [chatItem]="myparms.chatItem"
    (replyButton)="chatUpsert($event)"
    [allowReply]="chatField.chat.allowReplies"
    [depth]="myparms.depth"
    [isFirst]="myparms.isFirst"
    [isLast]="myparms.isLast">
  </div>
</ng-template>
<ng-template #optionMenu>
  <!-- div style="float:left; z-index: 100; position: absolute; text-align:right; width: calc(100% - 20px); max-height: 5px"
  (click)="selectInput()" -->
  <div style="float: right; margin-left: 10px">
    <button mat-mini-fab color="primary" style="margin-top: 20px; margin-right: 0px" i18n-matTooltip
      matTooltip="Click to show presentation options" [mat-menu-trigger-for]="viewMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #viewMenu="matMenu">
      @if (!isFollowing()) {
        <button mat-menu-item (click)="follow()" i18n>
          <mat-icon color="primary">follow_the_signs</mat-icon>Follow This Item
        </button>
      }
      @if (isFollowing()) {
        <button mat-menu-item (click)="stopFollowing()" i18n>
          <mat-icon color="warn">cancel</mat-icon>Stop Following
        </button>
      }
      @if (addOthers) {
        <button mat-menu-item (click)="addPersonAsFollower()" i18n>
          <mat-icon color="primary">follow_the_signs</mat-icon>Add OMC Follower
        </button>
        <button mat-menu-item (click)="addPersonAsFollower(true)" i18n>
            <mat-icon color="primary">signpost</mat-icon>Add Agency Follower
        </button>
      }
      <button mat-menu-item (click)="escalateToManagement()" i18n>
        <mat-icon color="accent">diversity_3</mat-icon>Add Management Team
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item [ngClass]="chatField?.chat.mode.order === 'ascending' ? 'selectedTeamMenuItem' : ''"
        [disabled]="chatField?.chat.mode.order === 'ascending'" (click)="setSortMode()" i18n>
        <mat-icon>watch_later</mat-icon>Oldest On Top@if (chatField?.chat.mode.order === 'ascending') {
        <mat-icon>check
        </mat-icon>
      }
    </button>
    <button mat-menu-item [ngClass]="chatField?.chat.mode.order === 'descending' ? 'selectedTeamMenuItem' : ''"
      [disabled]="chatField?.chat.mode.order === 'descending'" (click)="setSortMode(false)" i18n>
      <mat-icon>arrow_upward</mat-icon>Newest On Top@if (chatField?.chat.mode.order === 'descending') {
      <mat-icon>
      check</mat-icon>
    }
  </button>
  @if (!isPhone) {
    <button mat-menu-item
      [ngClass]="chatField?.chat.mode.nested ? 'selectedTeamMenuItem' : ''"
      [disabled]="chatField?.chat.mode.nested" (click)="setNested(true)" i18n>
      <mat-icon>account_tree</mat-icon>Nested Conversations@if (chatField?.chat.mode.nested) {
      <mat-icon>check
      </mat-icon>
    }
  </button>
}
@if (!isPhone) {
  <button mat-menu-item
    [ngClass]="!chatField?.chat.mode.nested ? 'selectedTeamMenuItem' : ''"
    [disabled]="!chatField?.chat.mode.nested" (click)="setNested(false)" i18n>
    <mat-icon>trending_flat</mat-icon>Flat List@if (!chatField?.chat.mode.nested) {
    <mat-icon>check</mat-icon>
  }
</button>
}
</mat-menu>
</div>
</ng-template>
