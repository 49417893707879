/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { ServiceRequest } from "src/app/model/serviceRequest";
import { ChartParms } from "src/app/model/sys/nav-item";
import { User } from "src/app/model/user";

export const requestCharts : ChartParms[] = [
    { type: 'pie', picklist: 'srStatus' } as ChartParms,
    { type: 'pie', picklist: 'omc_managerId' } as ChartParms,
    { type: 'bar', picklist: 'omcId' } as ChartParms,
    { type: 'bar', picklist: 'templateId' } as ChartParms,
    { type: 'bar', picklist: 'ownedById', metric: 'timeSpent'} as ChartParms
];

export function requestViews(u: User) {
    if (!u.currentTeam) {
        return null;
    }

    if (!u.inAgency) {
        return [{
                id: 'omc_default_open',
                name: $localize`All Open Requests`,
                filterFields: { srStatus: ServiceRequest.srOpen.id, subStatus: 'All', keyword: null },
                sort: 'id',
                charts: [
                    { type: 'bar', picklist: 'monthOpened' } as ChartParms,
                    { type: 'pie', picklist: 'subStatus' } as ChartParms,
                ]
        }, {
                id: 'open_agenda_items',
                name: $localize`Open Agenda Items`,
                filterFields: { srStatus: ServiceRequest.srOpen.id, subStatus: 'All', keyword: null, ballotId: 'NOT NULL' },
                sort: 'id',
                charts: [
                    { type: 'bar', picklist: 'monthOpened' } as ChartParms,
                    { type: 'pie', picklist: 'subStatus' } as ChartParms,
                ]
            },
        ];
    }

    return [
        {
            id: 'my_default',
            name:  $localize `My Open Requests`,
            filterFields: {ownedById: u.id, srStatus: ServiceRequest.srOpen.id, subStatus: 'All', omcId: 'All', keyword: null},
            sort: 'id',
            charts: [
                { type: 'pie', picklist: 'omc_managerId' } as ChartParms,
                { type: 'bar', picklist: 'omcId' } as ChartParms,
                { type: 'bar', picklist: 'templateId' } as ChartParms,
                { type: 'pie', picklist: 'priorityId' } as ChartParms,
            ]
        },
        {
            id: 'my_omc_default',
            name: $localize `My OMCs Open Requests`,
            filterFields: { omc_managerId: u.id, srStatus: ServiceRequest.srOpen.id, subStatus: 'All', omcId: 'All', keyword: null },
            sort: 'id',
            charts: [
                { type: 'pie', picklist: 'categoryId' } as ChartParms,
                { type: 'bar', picklist: 'omcId' } as ChartParms,
                { type: 'bar', picklist: 'templateId' } as ChartParms,
                { type: 'pie', picklist: 'priorityId' } as ChartParms,
            ]
        },
        {
            id: 'all_default',
            name: $localize `All Open Requests`,
            filterFields: { srStatus: ServiceRequest.srOpen.id, subStatus: 'All',  omcId: 'All', keyword: null},
            sort: 'id',
            charts: [
                { type: 'pie', picklist: 'omc_managerId' } as ChartParms,
                { type: 'bar', picklist: 'omcId' } as ChartParms,
                { type: 'bar', picklist: 'templateId' } as ChartParms,
                { type: 'pie', picklist: 'priorityId' } as ChartParms,
            ]
        },
        {
            id: 'search_by_ref',
            name: $localize`Search By Reference`,
            filterFields: { refNr: '----', omcId: 'All', keyword: null },
            sort: 'id',
        },
        {
            id: 'all_analysis',
            name: $localize `All Requests`,
            filterFields: {srStatus: 'All', subStatus: 'All', omcId: 'All', keyword: ''},
            sort: 'id',
            charts: [
                { type: 'pie', picklist: 'omc_managerId' } as ChartParms,
                { type: 'trend', picklist: 'srStatus', trend: 'weekCreated' } as ChartParms,
                //{ type: 'bar', picklist: 'templateId' } as ChartParms,
                { type: 'pie', picklist: 'priorityId' } as ChartParms,
            ]
        },
        {
            id: 'bill_check',
            name: $localize`Billing Check`,
            filterFields: { srStatus: '!=open', billingStatus: 'null' },
            sort: 'id',
            charts: [
                { type: 'pie', picklist: 'omc_managerId' } as ChartParms,
                { type: 'trend', picklist: 'srStatus', trend: 'weekCreated' } as ChartParms,
                { type: 'bar', picklist: 'omcId' } as ChartParms,
                { type: 'pie', picklist: 'categoryId' } as ChartParms,
            ]
        },
        {
            id: 'bill_todo',
            name: $localize`Items To Be Billed`,
            filterFields: { srStatus: '!=open', billingStatus: 'omc,owner' },
            sort: 'id',
            charts: [
                { type: 'pie', picklist: 'omc_managerId' } as ChartParms,
                { type: 'trend', picklist: 'srStatus', trend: 'weekCreated' } as ChartParms,
                { type: 'bar', picklist: 'omcId' } as ChartParms,
                { type: 'pie', picklist: 'categoryId' } as ChartParms,
            ]
        },
    /*
    [ 'name' => 'My OMCs Unowned',
      'filterFields' => ['omc.managerId' => Auth::user()->id, 'ownedById' => ''],
    ],
    [ 'name' => 'All Unowned',
      'filterFields' => ['ownedById' => ''],
    ],
    [
        'id' => 'all',
        'name' => 'All Requests',
        'filterFields' => []
    ]
*/
]}
