/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { Txn } from 'src/app/model/txn';

@Injectable({
    providedIn: 'root'
})
export class JournalService extends AbstractHttpService {

    // protected baseUrl = this.ajaxPath + 'txns?typeId=' + Txn.TYPE.SALE.id;
    protected baseUrl = this.ajaxPath + Txn.TYPE.JRNL.code;
    protected cache: Txn[];
    protected typeString = this.ajaxPath + Txn.TYPE.JRNL.name;

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super();
    }
}
