/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { UntypedFormArray, UntypedFormGroup } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { Observable, Subject } from "rxjs";
import { AbstractObject } from "src/app/model/abstract-object";
import { Field } from "src/app/shared/field/Field";
import { AppFormControl } from "../form/app-form-control";
import { GridRow } from "./grid-row";
import { GridComponent } from "./grid.component";
import { GridField } from "./grid-field";

export class GridControl extends UntypedFormArray {
    rowFactory: (item: AbstractObject) => Field[];
    objFactory: () => Observable<AbstractObject>;
    rowNomenclator(row: GridRow) {
        if (row.get('name')) {
            return row.get('name').value;
        } else if (row.get('title')) {
            return row.get('title').value;
        }
        const visibleFields = row.fields.filter(f => f.visible.form);
        let ret = "";
        for (const f of visibleFields) {
            if (f.name !== 'memo') {
                if (ret.length > 0) {
                    ret += ', ';
                }
                ret += f.label + ': ' + f.getFormattedValue(row.focus) // f.getDisplayValue(o);
            }
        }
        return ret;
    }

    data: Subject<GridRow[]> = new Subject();
    dataSource = new MatTableDataSource<GridRow>();
    columns: Field[] = [];
    columnNames: string[] = [];
    field: GridField;
    primaryId: string = null;
    primaryCompareId: string = null;
    onPrimarySet?: (form: UntypedFormGroup, gridRow: GridRow) => void;
    hasFooter = false;
    totals = new Map<string, number>();
    displayMode?: 'grid' | 'accordion' = 'accordion';
    newOptionText = '';

    constructor(fld: GridField) {
        super([], fld.config.gridValidator, fld.config.gridASyncValidator);
        this.rowFactory = fld.config.rowFactory;
        this.objFactory = fld.config.objFactory;
        if (!fld.config.displayMode) {
            this.displayMode = 'grid';
        } else {
            this.displayMode = fld.config.displayMode
        }
        if (fld.config.rowNomenclator) {
            this.rowNomenclator = fld.config.rowNomenclator;
        }
        if (fld.config.newOptionText) {
            this.newOptionText = fld.config.newOptionText;
        }
        this.field = fld;
        this.field.formControlFactory = GridComponent.createComponent;
        if (fld.config.primaryId) {
            this.primaryId = fld.config.primaryId;
            this.primaryCompareId = 'id';
        }
        if (fld.config.primaryCompareId) {
            this.primaryCompareId = fld.config.primaryCompareId;
        }
        if (fld.config.onPrimarySet) {
            this.onPrimarySet = fld.config.onPrimarySet;
        }
        if (fld.config.hasFooter) {
            this.hasFooter = fld.config.hasFooter;
        }
    }
    refresh() {
        if (this.columns.length === 0) {
            this.setColumns();
        }
        this.dataSource.data = (this.controls as GridRow[]);
        //this.data.next(this.controls as GridRow[]);
    }

    gridRows(): GridRow[] {
        return (this.controls as GridRow[]);
    }

    clear() {
        super.clear();
        this.totals.clear();
    }

    setColumns() {
        const controlArray = this.rowFactory(null);
        const fieldArray = [];
        const nameArray = [];
        controlArray.forEach((field) => {
            fieldArray.push(field);
            if (field.visible.computer || field.visible.phone) {
                nameArray.push(field.name);
            }
        });
        this.columns = fieldArray;
        this.columnNames = nameArray;
    }

    public addRow(item: AbstractObject, refreshGrid = true, readonly: boolean): GridRow {
        const arry = this.rowFactory(item);
        const newRow = new GridRow(item, {}, arry);
        this.push(newRow);
        arry.forEach(cellField => {
            if (cellField.control) {
                console.error('Row Factories should not create fields with controls, are fields being reused instead of manufactured?'
                    , cellField, arry);
            }
            const ctl = cellField.makeControl() as AppFormControl;
            newRow.addControl(cellField.name, ctl);
            ctl.isGridCell = true;
            cellField.setValue(item, readonly);
        });
        this.recalculateTotals();
        if (refreshGrid) {
            this.refresh();
        }
        return newRow;
    }

    recalculateTotals() {
        let setZeroDone = false;
        for (const row of (this.controls as GridRow[])) {
            for (const fld of row.fields) {
                if (!setZeroDone) {
                    this.totals.set(fld.name, 0);
                }
                if (fld.type === 'number') {
                    this.totals.set(fld.name, this.totals.get(fld.name) + fld.control.value);
                } else {
                    this.totals.set(fld.name, this.totals.get(fld.name) + 1);
                }
            }
            setZeroDone = true;
        }
    }

    removeGridRow(gridRow: GridRow) {
        let removed = false;
        this.controls.forEach((control, index) => {
            if (gridRow === control) {
                this.removeAt(index);
                removed = true;
            }
        });
        if (!removed) {
            console.warn('Could not find grid row to remove it ', { gridControl: this, gridRow });
        }
        this.refresh();
    }

    getFormValue(getAll = false): AbstractObject[] {

        const formValue: AbstractObject[] = [];
        this.gridRows().forEach(gridRow => {
            formValue.push(gridRow.getFormValue(getAll));
            /*
            const newRow = {} as AbstractObject;
            gridRow.fields.forEach(cell => {
                if (cell.sendServer || getAll) {
                    newRow[cell.name] = cell.control.value;
                }
            });
            if (gridRow.deleted) {
                newRow.deleted = true;
            }
            */
        });

        return formValue;
    }
}
