@if(myUnits?.length > 1) {
    <p>
        @for (u of myUnits; track $index) {
            @if (u.id === myUnit.id) {
                <!--span style="padding-left: 10px; padding-right: 10px">{{u.name}}</span -->
                <button mat-raised-button disabled>{{u.name}}</button>
            } @else {
                <button mat-raised-button (click)="selectUnit(u)">{{u.name}}</button>
            }
        }
    </p>
}
<div class="main">
    @if (myUnit) {
        <ng-container [ngTemplateOutlet]="UnitTemplate"></ng-container>
        <ng-container [ngTemplateOutlet]="PeopleTemplate"></ng-container>

        <ng-container [ngTemplateOutlet]="TransactionTemplate"></ng-container>
    } @else {
        <p>You have no units to display...</p>
    }

</div>

<ng-template #UnitTemplate>
    <div class="item">
        <mat-card style="padding:20px; margin-bottom:20px">
            <h1>Unit Details</h1>
            <p class="label">Unit Name</p>
            <p>{{myUnit.name}}</p>
            <p class="label">Address</p>
            <p [innerHTML]="myUnit.address"></p>
            <div style="display:flex">
                <div style="flex:1">
                    <p class="label">Type</p>
                    <p>{{myUnit.type?.name}}</p>
                    <p class="label">Category</p>
                    <p>{{myUnit.category?.name}}</p>
                    <p class="label">Area</p>
                    <p>{{myUnit.area?.name}}</p>
                    <p class="label">Core</p>
                    <p>{{myUnit.core?.name}}</p>
                    <p class="label">Size</p>
                    <ng-container [ngTemplateOutlet]="info" [ngTemplateOutletContext]="{item: myUnit.size}"></ng-container>
                </div>
                <div style="flex:1">
                    <p class="label">Floor</p>
                    <ng-container [ngTemplateOutlet]="info" [ngTemplateOutletContext]="{item: myUnit.floor}"></ng-container>
                    <p class="label">Bedrooms</p>
                    <ng-container [ngTemplateOutlet]="info" [ngTemplateOutletContext]="{item: myUnit.bedrooms}"></ng-container>
                    <p class="label" matTooltip="The number of car spaces can be used to apportion budget">#Car Spaces</p>
                    <ng-container [ngTemplateOutlet]="info" [ngTemplateOutletContext]="{item: myUnit.numberCarSpaces}"></ng-container>
                    <p class="label" matTooltip="The names of spaces allocated to this unit">Car Spaces</p>
                    <ng-container [ngTemplateOutlet]="info" [ngTemplateOutletContext]="{item: myUnit.carSpaces}"></ng-container>
                </div>
            </div>
            <p class="label">Last Fire Safety Check</p>
            <ng-container [ngTemplateOutlet]="info" [ngTemplateOutletContext]="{item: myUnit.fireSafetyCheck}"></ng-container>
            <p class="label">Billing Address</p>
            <p [innerHTML]="myUnit.billingAddress"></p>
        </mat-card>
        <ng-container [ngTemplateOutlet]="LeaseTemplate"></ng-container>
    </div>
</ng-template>

<ng-template #info let-item="item">
    <p>
        @if (item) {
            {{item}}
        } @else {
            <span style="font-style: italic">Unknown</span>
        }
    </p>
</ng-template>

<ng-template #PeopleTemplate>
    <div class="item">

        @if(!hasResident) {
            <mat-card style="padding:20px; margin-bottom:20px">
                <p class="warning">
                    There is no resident recorded.
                    It is important for the OMC to have resident details, especially in case of emergencies.
                </p>
                <p style="text-align:center">
                    <button mat-raised-button color="accent" (click)="addPersonToUnit()">Add resident</button>
                </p>
            </mat-card>
        }
        @if(!hasKeyholder) {
            <mat-card style="padding:20px; margin-bottom:20px">
                <p class="warning">
                    There is no keyholder recorded.
                    It is important for the OMC to have a keyholder contact that can be reached in the case of an emergency when the normal resident is not available.
                </p>
                <p style="text-align:center">
                    <button mat-raised-button color="accent" (click)="addPersonToUnit()">Add Keyholder</button>
                </p>
            </mat-card>
        }


        @if(myUnit.people) {
            @for (pur of myUnit.people; track $index) {
                <ng-container [ngTemplateOutlet]="PersonTemplate" [ngTemplateOutletContext]="{pur}"></ng-container>
            }
        }
        <div style="width:100%; text-align: center; margin-bottom: 20px">
            <div style="margin: auto">
                <button mat-fab color="primary" (click)="addPersonToUnit()" matTooltip="Add person to this unit">
                    <mat-icon style="font-size: 48px; height: 48px; width: 48px">add</mat-icon></button>
            </div>
        </div>
    </div>

</ng-template>


<ng-template #PersonHeader let-pur="pur">
    <div class="person-header"  (click)="selectedPerson = pur">
        <div style="display:flex">
            <h3 style="flex: 1">
                {{pur.person.fullName}}
            </h3>

                <mat-icon color="primary" matTooltip="Click to see more details" (click)="selectedPerson = pur">
                    keyboard_double_arrow_down
                </mat-icon>

        </div>
        <p class="suffix">
            {{pur.role.name}}
            @if (pur.startDate) {From: {{pur.startDate | date: 'dd/MM/yy'}}}
            @if (pur.endDate) { Ending on {{pur.endDate | date: 'dd/MM/yy'}}}
        </p>
        @if(pur.person['needsAddress']) {
            <p class="warning">Please add mailing address</p>
            <p style="text-align:center" [matTooltip]="ownerOnlyAddressTip">
                <button mat-raised-button color="accent" (click)="editPerson(pur)">Add Address</button>
            </p>
        }
    </div>
</ng-template>

<ng-template #PersonDetail let-pur="pur">
    <div style="display:flex;">
        <h1 style="width: 100%">{{pur.role.name}}</h1>
        @if (!pur?.isOwner) {
            <button mat-mini-fab color="nocolor" style="margin-right: 5px" (click)="removePersonFromUnit(pur)">
                <mat-icon color="warn" matTooltip="Remove this person from the unit">delete</mat-icon>
            </button>
            <button mat-mini-fab color="nocolor" (click)="editPerson(pur)">
                <mat-icon color="primary" matTooltip="Remove this person from the unit">edit</mat-icon>
            </button>
        } @else {
            <button mat-mini-fab color="nocolor" (click)="editPerson(pur)">
                <mat-icon color="primary" matTooltip="Remove this person from the unit">edit</mat-icon>
            </button>
        }
    </div>
        <p class="suffix">
            @if (!pur?.isOwner) {
            {{pur.role.name}}
            }
            @if (pur.startDate) {From: {{pur.startDate | date: 'dd/MM/yy'}}}
            @if (pur.endDate) { Ending on {{pur.endDate | date: 'dd/MM/yy'}}}
        </p>

        <p class="label">Name</p><p>{{pur.person.fullName}}</p>

        <p class="label">Phone</p><p>{{pur.person.phone}}</p>
        <p class="label">email</p><p>{{pur.person.email}}</p>

        @if (pur.person.address) {
            <p class="label">Address</p>
            <p [innerHTML]="pur.person.address"></p>
        } @else {
            @if(pur.person['needsAddress']) {
                <p class="warning">Please add mailing address</p>
                <p style="text-align:center" [matTooltip]="ownerOnlyAddressTip">
                    <button mat-raised-button color="accent" (click)="editPerson(pur)">Add Address</button>
                </p>
            }
        }
</ng-template>

<ng-template #PersonTemplate let-pur="pur">

    <mat-card style="padding:20px; margin-bottom:20px">

        @if (selectedPerson !== pur) {
            <ng-container [ngTemplateOutlet]="PersonHeader" [ngTemplateOutletContext]="{pur}"></ng-container>
        } @else {
            <ng-container [ngTemplateOutlet]="PersonDetail" [ngTemplateOutletContext]="{pur}"></ng-container>
        }
    </mat-card>

</ng-template>
<ng-template #LeaseTemplate>
    @if (myUnit.portions?.length > 0) {
        <mat-card style="padding:20px; margin-bottom:20px">
            <h1>Lease Schedule Portions</h1>
            <p class="label" style="margin-top: -15px; margin-bottom: 15px">The percentage of the overall budget that is allocated to this unit</p>
            <table>
                @for (portion of myUnit.portions; track $index) {
                    <tr [matTooltip]="portion['tooltip']">
                        <td>{{portion.schedule?.name}}</td>
                        <td>{{portion.percent | percent : '1.3-3'}}</td>
                    </tr>
                }
            </table>
        </mat-card>
    } @else {
        <mat-card style="padding:20px; margin-bottom:20px">
            <p>
                There are no financial details shown here as your OMC has not as yet implemented the OurOMC financial modules.
                Once implemented you would be able to see the overall budget for your OMC.
                How much of that is allocated to your unit, if the spend is over or under budget in any category.
                Along with the history of all transactions on your unit.
            </p>
        </mat-card>
    }
</ng-template>
<ng-template #TransactionTemplate>
    <div class="item">
        <mat-card style="padding:20px; margin-bottom:20px">
            <div style="display:flex">
                <h1 style="width:100%">Accounts Statement</h1>
                <button mat-mini-fab color="nocolor" matTooltip="Get a printable copy of your full statement">
                    <mat-icon color="primary">picture_as_pdf</mat-icon>
                </button>
            </div>
            <table>
                <tr>
                    <th>Date</th>
                    <th></th>
                    <th style="text-align:right">Amount</th>
                    <th style="text-align:right">Balance</th>
                </tr>
                <tr>
                    <td style="text-align:right">
                        @if (ownerStartDate && ownerStartDate > currentCycle.from) {
                            {{ownerStartDate | date:'dd/MM/yy'}}
                        } @else {
                            {{currentCycle.from | date:'dd/MM/yy'}}
                        }
                    </td>
                    <td colspan="2">Opening Balance</td>
                    <td style="text-align:right">{{myUnit.arrears | currency}}</td>
                </tr>
                @for (t of myUnit.currentTxn; track $index) {
                    <tr>
                        <td style="text-align:right">{{t.txnDate | date:'dd/MM/yy'}}</td>
                        <td>{{t.type.name}}</td>
                        <td style="text-align:right">{{(t.debit - t.credit) | currency}}</td>
                        <td style="text-align:right">{{t.balance | currency}}</td>
                        <td>
                            @if (t.typeId === 10) {
                                <mat-icon color="primary" class="pdf-button"
                                (click)="getInvoicePdf(t)" matTooltip="Get a printable copy of this charge">picture_as_pdf</mat-icon>
                            }
                        </td>
                    </tr>
                }

                <tr>
                    <td colspan="3" style="text-align:right">Current Balance</td>
                    <td style="text-align:right; font-weight:bold">{{myUnit.outstanding | currency}}</td>
                </tr>
            </table>
        </mat-card>
        @if (myUnit.billingItems?.length > 0) {
            <ng-container [ngTemplateOutlet]="BillingItemsTemplate"></ng-container>
        }
    </div>
</ng-template>

<ng-template #BillingItemsTemplate>
    <mat-card style="padding:20px; margin-bottom:20px">
        <h1>Upcoming Proposed Charges</h1>
        <table>
            <tr>
                <th>Schedule</th>
                <th>Account</th>
                <th>Total</th>
                <th>This Unit</th>
            </tr>
            @for (bi of myUnit.billingItems; track $index) {
                <tr>
                    <td>{{bi.scheduleName}}</td>
                    <td>{{bi.bCodeName}}</td>
                    <td style="text-align: right">{{bi.total | currency }}</td>
                    <td style="text-align: right">{{bi.myTotal | currency }}</td>
                </tr>
            }
            <tr style="font-weight:bold">
                <td colspan="2" style="text-align: right">Grand Totals</td>
                <td style="text-align: right">{{myUnit.proposedBillingTotal | currency }}</td>
                <td style="text-align: right">{{myUnit.myProposedBillingTotal | currency }}</td>
            </tr>
        </table>
    </mat-card>
</ng-template>
