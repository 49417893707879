<div>
  <div class="request-card">
    <ng-container *ngTemplateOutlet="unknownUser"></ng-container>
    <p class="disclaimer" style="text-align: center">
      By clicking any of the buttons above, you acknowledge that you have read,
      understood and agree to OurOMC.ie's <a href="http://ouromc.ie/terms">terms of service</a>
      and <a href="http://ouromc.ie/privacy">privacy policy</a>.
    </p>
  </div>
</div>
<ng-template #unknownUser>
  <mat-card appearance="raised" style="margin-top: 40px">
    <mat-card-header  style="margin-bottom: 40px; text-align: center">
      <mat-card-title style="margin-bottom: 20px;">
        Request to join OMC
      </mat-card-title>
      @if (whoami==='noidea') {
        <mat-card-subtitle>
          To manage your request we need to know who you are
        </mat-card-subtitle>
      }
      @if (whoami==='newuser') {
        <mat-card-subtitle >
          We can create a new user by sending you an email to verify your email address, or if your email address is related to google, just click the SignIn with Google button and you can get straight in.
        </mat-card-subtitle>
      }
      @if (whoami==='passwordNeeded') {
        <mat-card-subtitle>
          Please enter your email address and click button to receive setup mail
        </mat-card-subtitle>
      }
      @if (whoami==='requestedPassword') {
        <mat-card-subtitle>
          Account Requested
        </mat-card-subtitle>
      }
    </mat-card-header>
    <mat-card-content>
      @if (whoami==='noidea') {
        <div style="flex:2; flex-wrap: wrap;">
          <p>Please note Owner Management Companies (OMCs) are responsible for managing the common areas within a Multi
            unit
            Development.
          </p>
          <p><b>OMCs are not responsible for the internal areas of a dwelling.</b>
          If you are a resident and have an issue relating to the internals of your dwelling,
          to your residency or tenancy, then you should contact the apartment owner, your landlord or your letting agent
        </p>
        <p><b>To be compliant with GDPR, the OMC will not be able to discuss or share any details relating to a unit or its ownership, with anyone but the unit owner.</b></p>
        <p>If you own a unit within a development, please login or register so that you can make requests to the OMC you are a member of</p>
      </div>
    }
    @if (whoami==='noidea') {
      <div style="display: flex">
        <div style="flex: 1"><button (click)="logMeIn()" mat-raised-button color="primary">Login Existing User</button></div>
        <div style="flex: 1"><button (click)="createNew()" mat-raised-button color="primary">Create New User</button></div>
      </div>
    }
    @if (whoami==='newuser') {
      <div style="display:flex; align-items: center;" >
        <div style="flex: 1">
          <button (click)="passwordNeeded()" mat-raised-button color="primary">Request Password
          </button>
        </div>
        <div style="flex: 1">
          <button (click)="oAuthLogin('google')" mat-button style="background:white">
            <img height="34" width="142" class="images" ngSrc="./assets/google_signin_logo.png" style="padding-top:12px"/>
          </button>
        </div>
      </div>
    }
    @if (whoami==='passwordNeeded') {
      <mat-form-field class="login-full-width">
        <mat-label>Enter your email address</mat-label>
        <input data-reset-username [formControl]="emailCtl" matInput email
          name="username" required type="email" >
        <mat-error>
          <app-form-error [(control)]="emailCtl"></app-form-error>
        </mat-error>
      </mat-form-field>
      <button (click)="requestPassword()" mat-raised-button color="primary" [disabled]="requesting || !emailCtl.valid || !emailCtl.dirty">
        Request password setup
      </button>
    }
    @if (whoami==='requestedPassword') {
      <p>We have sent a reset password message to {{emailCtl.value}}</p>
      <p>You should receive it in the next few minutes</p>
      <p>If you do not see it, perhaps check your SPAM folder</p>
      <p>When you click on the mail to confirm you own this email account, you will be able to make a request</p>
    }
  </mat-card-content>
</mat-card>
</ng-template>
