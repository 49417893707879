/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable, Output, EventEmitter } from '@angular/core';
import { uuid } from 'src/app/model/abstract-object';
import { NavItem } from 'src/app/model/sys/nav-item';
import { User } from 'src/app/model/user';
import { NavigationComponent } from './navigation.component';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    @Output() pageRefresh = new EventEmitter();

    private static instance: NavigationService;

    navItemMap: Map<string, NavItem> = null;
    modelMap: Map<string, NavItem> = null;
    navItems: NavItem[] = [];

    demos = { id: 'demos', title: 'Demonstrations', icon: 'videocam', url: 'demos', visible: true, open: true };

    constructor() {
        if (NavigationService.instance) {
            console.error('WTF??????????????');
        } else {
            NavigationService.instance = this;
        }
    }

    static getInstance() {
        return NavigationService.instance;
    }

    setPageRefresh(o) {
        this.pageRefresh.subscribe(o);
    }

    doPageRefresh() {
        this.pageRefresh.emit();
    }

    getItems(): Array<NavItem> {
        return this.navItems;
        //return navItems;
    }

    setItems(user: User) {
        this.navItemMap = null;
        this.navItems = user.navItems;
        this.mapAllItems(user);
        this.navItems.push(this.demos);
    }

    anonymousItems(user: User = null) {
        const items = [
            { id: 'solutionsFor', title: 'Software solutions for ', visible: true, open: true},
            { id: 'residents', title: 'Residents', icon: 'apartment', url: 'residents', open: true, visible: true },
            { id: 'owners', title: 'Unit Owners', icon: 'key', url: 'owners', open: true, visible: true },
            { id: 'directors', title: 'OMC Directors', icon: 'location_away', url: 'directors', open: true, visible: true },
            { id: 'agents', title: 'PSRA D License Holders', icon: 'support_agent', url: 'agents', open: true, visible: true },
            { id: 'accountants', title: 'Accountants', icon: 'library_books', url: 'accountants', open: true, visible: true },
            { id: 'contractors', title: 'Caretakers & Contractors', icon: 'construction', url: 'contractors', open: true, visible: true },
            { id: 'breaker', title: '', visible: true, open: true },
            this.demos,
            { id: 'pricing', title: 'Pricing Calculator', icon: 'euro', url: 'pricing', visible: true, open: true },
            { id: 'contactUs', title: 'Contact OurOMC.ie', icon: 'live_help', url: 'contact-us', visible: true, open: true },
            { id: 'aboutUs', title: 'About OurOMC.ie', icon: 'info', url: 'about-us', visible: true, open: true },
        ];
        if (user) {
            items.push({ id: 'unknownUser', title: 'Request to join an OMC', icon: 'join', url: 'unknownUser', visible: true, open: true });
        } else {
            items.push({ id: 'unknownUser', title: 'Request to join an OMC', icon: 'join', url: 'newUserRequest', visible: true, open: true });
            items.push({ id: 'breaker', title: '', visible: true, open: true }),
            items.push({ id: 'Login', title: 'Login', icon: 'login', url: 'login', open: true, visible: true },);
        }

        return items;
    }

    getModelUrl(model: string, id: uuid) {
        return this.modelMap.get(model).url + '/' + id;
    }

    getItem(url: string) {
        if (this.navItemMap) { // If not in a team yet, no NavItemMap
            return this.navItemMap.get(url);
        }
        return null;
    }

    private mapAllItems(user: User) {
        this.navItemMap = new Map();
        this.modelMap = new Map();
        this.mapItems(this.getItems(), user);
    }

    private mapItems(navs: NavItem[], user: User) {
        for (const n of navs) {
            if (n.url) {
                this.navItemMap.set(n.url, n);
            }
            if (n.model) {
                this.modelMap.set(n.model, n);
            }
            if (n.url && n.model) { // Sneaky, modelToPath should be maintained server side
                user.pathToModel[n.url] = n.model;
            }
            if (n.children) {
                this.mapItems(n.children, user);
            }
        }
    }

    public navComponent: NavigationComponent;

}
