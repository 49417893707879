@if (on === 'form') {
  <mat-form-field style="height: 74px">
    <mat-label>{{control.field.label}}</mat-label>
    <input matInput [formControl]="control" [autocomplete]="control.field.autoComplete" type="url"
      [matTooltip]="control.field.hint" [readonly]="control.field.readonly ? '' : null">
    <!-- input above had: *ngIf="!control.field.cellOpts.displayText"
    input *ngIf="control.field.cellOpts.displayText" matInput [formControl]="control" style="display: none">
    <span *ngIf="control.field.cellOpts.displayText">{{control.field.cellOpts.displayText}}</span -->
      <mat-error>
        <app-form-error [(control)]="control"></app-form-error>
      </mat-error>
      <!--mat-hint>{{control.field.hint}}</mat-hint -->
    </mat-form-field>
  }
  @if (on === 'grid') {
    <input matInput [formControl]="control" type="url"
      [matTooltip]="control.getErrorMessage()"
      [ngClass]="{'error': control.invalid && control.touched}"
      [autocomplete]="control.field.autoComplete"
      [readonly]="control.field.readonly ? '' : null">
    <!-- input above had  *ngIf="!control.field.cellOpts.displayText"
    span *ngIf="control.field.cellOpts.displayText">{{control.field.cellOpts.displayText}}</span -->
  }
  