import { Component, inject } from '@angular/core';
import { NavRoute } from 'src/app/shared/NavRoute';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { FormConfig } from 'src/app/shared/form/FormConfig';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet, LAYOUT_OPTIONS } from 'src/app/shared/form/field-set/field-set.component';
import { FormPageComponent } from 'src/app/shared/form/form-page/form-page.component';
import { required } from 'src/app/shared/validators';
import { FixedAssetType, FixedAssetTypeService } from '../fixed-asset-type.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-fixed-asset-types-page',
  standalone: true,
  imports: [FormPageComponent],
  templateUrl: './fixed-asset-types-page.component.html',
  styleUrl: './fixed-asset-types-page.component.scss'
})
export class FixedAssetTypesPageComponent extends AbstractPageComponent {
    static readonly navRoute = new NavRoute('fassetTypes', FixedAssetTypesPageComponent, 'inventory');

    private dataSvc = inject(FixedAssetTypeService);

    config = new FormConfig({
        navRoute: FixedAssetTypesPageComponent.navRoute,
        title: $localize`Fixed Asset Type`,
        fieldSet: new FieldSet({
            fields: [
                FieldMaker.id(),
                FieldMaker.rev(),
                FieldMaker.nameControl({ validators: [required] }),
                FieldMaker.notes()
            ],
            formLayout: LAYOUT_OPTIONS.singleCol,
        }),
        service: this.dataSvc,
        mode: 'list',
        objectFactory: () => of(new FixedAssetType()),
    });
}
