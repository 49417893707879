/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { RequestTemplatesService } from '../modules/crm/request-templates.service';
import { FormButtonComponent } from '../shared/form/form-button/form-button.component';
import { FormDateComponent } from '../shared/form/form-date/form-date.component';
import { FormPicklistComponent } from '../shared/form/form-picklist/form-picklist.component';
import { required } from '../shared/validators';
import { AbstractObject, uuid } from './abstract-object';
import { Frequency } from './frequency';
import { RequestTemplate } from './RequestTemplate';
import { ServiceRequest } from './serviceRequest';
import { Omc } from './Omc';

export class RecurringJob extends AbstractObject {
    constructor(o: Partial<RecurringJob> = {}) {
        super(o);
    }

    omcId: uuid;
    omc: Omc;

    agentTeamId: uuid;

    templateId: uuid;
    template: RequestTemplate;

    lastId: uuid;
    lastRequest: ServiceRequest;

    occurences: ServiceRequest[] = [];
    nextOccurence: string;

    occurences_count: string; // server passes as string, bizarre...

    frequencyId: uuid;
    frequency: Frequency;

    timesDone = 0;

    static lastDoneMessage(sr: ServiceRequest) {
        if (sr) {
            return sr.refNr + ' - ' + sr.title; // + ' (' + sr.srStatus + ')';
        }
        return '';
    }

    static getTmplField(tmplSvc: RequestTemplatesService) {
        return FormPicklistComponent.make('Template', 'templateId', 'template', { service: tmplSvc }, { validators: [required] });

    }

    static getFrequencyField() {
        return FormPicklistComponent.make('Frequency', 'frequencyId', 'frequency',
            { items: Frequency.ALL_OPTIONS, optionDisplayValue: o => o.name },
            { validators: [required], formColumn: 2 });
    }

    static getLastDoneField() {
        return FormButtonComponent.makeLink('Last Request Created', 'title', '/crm/requests/${lastId}', {
            calculateValue: (o: RecurringJob) => RecurringJob.lastDoneMessage(o.lastRequest),
            formColumn: 3
        });
    }

    static getNextField() {
        return FormDateComponent.make('Next Needs To Be Planned', 'nextOccurence');
    }

    static getIdField() {
        return FormButtonComponent.makeLink('Ref', 'idField', '/crm/recurringJobs/${id}', { calculateValue: () => 'View' });
    }
    /*
        static views(u: User) : TableView[]; {
            [
                [
                    'id' => 'mine',
                    'name' => 'My OMCs Recurring Jobs',
                    'filterFields' => [
                        'omc.managerId' => Auth::user()->id
                    ],
                    'sort' => 'nextOccurence'
                ]
            ]
        }
        charts?: ChartParms[];
        */
}
