<div style="display: flex">
    <p class="label">{{label}}</p>
    @if (editor.observers.length > 0) {
        <mat-icon color="primary" (click)="editor.emit()">edit</mat-icon>
    }
</div>
<p>
    @if(navRoute && navParms) {
        <a [routerLink]="['/' + navRoute.url, navParms]">{{value}}</a>
    } @else {
        {{value}}
    }
</p>