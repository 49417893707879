
<mat-radio-group aria-label="Select a payment option" [(ngModel)]="paymentOption">
  <mat-radio-button value='repeating' [disabled]="unit.payzMandate"><span style="margin-right: 1em;">Pay Each Year Annually in advance</span></mat-radio-button>
  <mat-radio-button value='oneOff' [disabled]="unit.outstanding <= 0">Pay This Year Only</mat-radio-button>
</mat-radio-group>
@if (unit.outstanding > 0) {
  <p>There is {{unit.outstanding | currency}} outstanding on unit {{unit.name}}
    @if (unit.arrears) {
      <span>this includes arrears of {{unit.arrears | currency}} from previous years</span>
    }
  </p>
}
@if (unit.outstanding === 0) {
  <p>There are no monies outstanding for unit {{unit.name}}</p>
}
@if (unit.outstanding < 0) {
  <p>There is a credit of {{ 0 - unit.outstanding | currency}} on unit {{unit.name}}</p>
}
@if (paymentOption === 'repeating') {
  @if (unit.mandateDiscount) {
    <p>
      A discount of <b>{{cycle.mandateDiscount | percent}} ({{unit.mandateDiscount | currency}})</b>
      applies if you setup a mandate to pay your charges by direct debit every year.
      If you pay in full now, the total outstanding is reduced to {{ unit.mandateFullPayAmount | currency}}
    </p>
  }
  @if (unit.outstanding > 0) {
    <p  i18n>
      Click the button below to <b>pay {{unit.mandateFullPayAmount | currency}}
    and setup an annual direct debit to pay the service charge in full every year.</b>
  </p>
}
@if (unit.outstanding <= 0) {
  <p i18n>
    Click the button below to setup an annual direct debit mandate
    to create a direct debit to pay the full service charge at the start of every year {{unit.name}}
  </p>
}
@if (paymentOption === 'repeating') {
  <p  i18n>
    When you click the button, you will be taken to stripe, our payment partner, to provide your acccount and billing details.
    @if (unit.outstanding > 0) {
      <span>When you create this mandate this years payment is requested from your bank account immediately.
        For SEPA Direct Debits, it may take several days
        before you see the transaction in your account and the balance reflected in OurOMC.
      </span>
    }
    Your payment details will be automatically saved and used to pay your service charges in future.
    Your account details will be held securely by Stripe, OurOMC will never hold your payment account details.
    You will be notified before payment is taken next year.
    You will have the opportunity to change your payment method before next years payment is taken
    Only authorised charge amount(s) approved at AGM will be requested from your account.
    Any SEPA Direct Debit taken by OurOMC can be disputed by you within 8 weeks, and your bank will refund the amount.
    There will however be a dispute charged back to the OMC which will in turn be charged to your account if a payment is reversed.
  </p>
}
<table width="100%">
  <tr>
    @if (unit.outstanding > 0) {
      <td width="100%" style="text-align: center;" >
        <button mat-raised-button color="primary"  [disabled]="unit.payzMandate"
          (click)="setupMandateAndPay()" i18n>Pay {{unit.mandateFullPayAmount | currency}} and setup mandate
        </button>
      </td>
    }
    @if (unit.outstanding <= 0) {
      <td width="100%" style="text-align: center;" >
        <button mat-raised-button color="primary"  [disabled]="unit.payzMandate" (click)="setupMandateOnly()" i18n>
          Setup Mandate Now
        </button>
      </td>
    }
  </tr>
</table>
}
@if (paymentOption === 'oneOff') {
  @if (unit.fullPayDiscount) {
    <p>
      A discount of <b>{{cycle.fullPayDiscount | percent}} ({{unit.fullPayDiscount | currency}}</b>
      applies until {{cycle.fullPayBy | date}} on current charges.
      If you pay in full now, the total outstanding is reduced to {{ unit.fullPayAmount | currency}}
    </p>
  }
  <p>
    When you click the button, you will be take to stripe, our payment partner, to provide your acccount and billing details.
    When you make this payment this years payment is requested from your bank account immediately.
    For SEPA Direct Debits, it may take several days however, before you see the transaction in your account and the balance reflected in OurOMC.
    Any SEPA Direct Debit taken by OurOMC can be disputed by you within 8 weeks, and your bank will refund the amount.
    There will however be a dispute charged back to the OMC which will be charged to your account if a payment is reversed.
  </p>
  <table width="100%">
    <tr>
      @if (paymentOption === 'oneOff') {
        <td width="100%" style="text-align: center;" >
          <button mat-raised-button color="primary" (click)="payInFullOnly()" i18n>Pay {{unit.fullPayAmount | currency}} Now</button>
        </td>
      }
    </tr>
  </table>
}
