/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { NgTemplateOutlet } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { AppFormControl } from 'src/app/shared/form/app-form-control';
import { CtlHolderComponent } from 'src/app/shared/form/ctl-holder/ctl-holder.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { MessageService } from 'src/app/shared/message.service';
import { GmtAgoPipe } from 'src/app/shared/pipes/gmt-ago.pipe';
import { FieldSetComponent } from "../../../../shared/form/field-set/field-set.component";
import { LeadProfileService } from '../../lead-profile.service';
import { ApprovalStatus } from '../demos.component';
@Component({
    selector: 'app-get-demo-access',
    standalone: true,
    imports: [CtlHolderComponent, MatButtonModule, NgTemplateOutlet, GmtAgoPipe, FieldSetComponent],
    templateUrl: './get-demo-access.component.html',
    styleUrl: './get-demo-access.component.scss'
})
export class GetDemoAccessComponent implements OnChanges {
    @Output() enterRoom = new EventEmitter();
    @Output() backToVideos = new EventEmitter();
    @Input() approval: ApprovalStatus;

    http = inject(HttpClient);
    msgSvc = inject(MessageService);
    lps = inject(LeadProfileService);

    profileFieldSet = this.lps.fieldSet;
    emailField = this.profileFieldSet.get('email');

    profileName = 'leadProfile';
    accessCode = FormTextComponent.make('Please enter your access code to gain entry', 'accessCode',
        { validators: [this.validateAccessCode.bind(this)] }).setupControl();

    hasAccessCode = this.lps.profile.codeSent ? true : false;
    requestAgain = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.approval.currentValue === 'hasCode') {
            this.hasAccessCode = true;
        }
    }
    allowAccessToVideos() {
        this.enterRoom.emit()
    }

    giveUp() {
        this.backToVideos.emit()
    }

    requestAccess() {
        const url = AbstractHttpService.ajaxPath + 'videos';
        this.lps.saveUserData();
        this.lps.setCode();

        this.http.post(url, this.lps.profile).subscribe(() => {
            this.msgSvc.show($localize`An email has been sent to ${this.lps.profile.email} with your access code.
                Please enter the access code to gain entry to demo room`);
            this.hasAccessCode = true;
            this.lps.markCodeSent();
        }, failure => {
            this.msgSvc.showError(failure, ' Requesting your access code - please try again later');
        })
    }

    requestAccessAgain() {
        this.hasAccessCode = false;
        this.requestAgain = true;
    }

    validateAccessCode(ctl: AppFormControl) {
        if (this.lps.profile.code === ctl.value) {
            this.lps.markAccessValidated();
            this.allowAccessToVideos();
            return null;
        } else if (!this.lps.profile.codeSent && this.emailField.control.value) {
            const code = this.lps.calcCode(this.emailField.control.value);
            if (code === ctl.value) {
                this.lps.markAccessValidated();
                this.allowAccessToVideos();
                this.msgSvc.show('You now have full access to all of our video content, we hope you enjoy');
            } else {
                console.log('121-' + code + '-62457');
            }
        }
        return { invalid: 'Invalid access code' };
    }
}
