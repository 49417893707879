/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { combineLatest, Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';
import { BCode } from 'src/app/model/bcode';
import { Cycle } from 'src/app/model/cycle';
import { Field } from 'src/app/shared/field/Field';
import { Person } from 'src/app/model/person';
import { Schedule } from 'src/app/model/schedule';
import { Txn } from 'src/app/model/txn';
import { Unit } from 'src/app/model/unit';
import { PersonService } from 'src/app/pages/person-page/person.service';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { AppFormControl } from 'src/app/shared/form/app-form-control';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { FormComboBoxComponent } from 'src/app/shared/form/form-combo-box/form-combo-box.component';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { AppPicklistControl, FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormTextAreaComponent } from 'src/app/shared/form/form-text-area/form-text-area.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { GridField } from 'src/app/shared/grid/grid-field';
import { GridControl } from 'src/app/shared/grid/grid-control';
import { validateTxnDate, required, validateDate, nonZero } from 'src/app/shared/validators';
import { BCodeService } from 'src/app/modules/budget/bcode.service';
import { CycleService } from 'src/app/modules/budget/cycle.service';
import { PeriodService } from 'src/app/modules/budget/period.service';
import { ScheduleService } from 'src/app/modules/budget/schedule.service';
import { UnitService } from 'src/app/modules/unit/unit.service';
import { CurrentUserService } from 'src/app/modules/user/current-user.service';
import { CreditNoteService } from 'src/app/modules/txn/credit-note.service';
import { PicklistField } from 'src/app/shared/field/PicklistField';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { NavRoute } from 'src/app/shared/NavRoute';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';

@Component({
    selector: 'app-credit-note-page',
    templateUrl: './credit-note-page.component.html',
    styleUrls: ['./credit-note-page.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class CreditNotePageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute(Txn.TYPE.CREDIT_NOTE.code, CreditNotePageComponent, 'money_off');

    readonly path = Txn.TYPE.CREDIT_NOTE.code

    cycleField: PicklistField = FormPicklistComponent.make('Cycle', 'txnCycleId', 'txnCycle',
        { service: this.cycleSvc, refreshes: ['periodId'] }, { formColumn: 2, visible: Field.noShow }
    );

    periodField: PicklistField = FormPicklistComponent.make('Period', 'txnPeriodId', 'txnPeriod',
        { service: this.periodSvc },
        {
            formColumn: 2, visible: Field.noShow,
            refresh: (o: Cycle, control: AppPicklistControl) => {
                if (o) {
                    control.field.picklist.items = o.periods;
                    control.setValue(null);
                }
            },
        }
    );

    txnDateField: Field = FormDateComponent.make('Transaction Date', 'txnDate', {
        cellOpts: { width: '2%' }, formColumn: 3,
        validators: [validateTxnDate(this.cycleField, this.periodField)],
        valueChanges: this.dateValueChanges.bind(this)
    });

    schedules: Schedule[] = [];

    childTxn = new GridField({
        field:
            { label: $localize`Credit Details`, value: 'details', visible: Field.formOnly, formRow: 2 },
        rowFactory: () => [
            FieldMaker.id(),
            FormTextComponent.make('ledgerId', 'ledgerId', { visible: Field.noShow }),
            FormTextComponent.make('typeId', 'typeId', { visible: Field.noShow }),
            FormTextComponent.make('txnDate', 'txnDate', { visible: Field.noShow }),
            FormTextComponent.make('txnCycleId', 'txnCycleId', { visible: Field.noShow }),
            FormTextComponent.make('txnPeriodId', 'txnPeriodId', { visible: Field.noShow }),

            FormPicklistComponent.make('Account', 'bCodeId', 'bCode',
                {
                    service: this.bCodeSvc, serviceFilter:
                        new HttpParams().set('typeId', BCode.TYPE.INCOME.id + ',' + BCode.TYPE.EXPENSE.id)
                },
                { cellOpts: { width: '30%' }, validators: [required] }),

            FormPicklistComponent.make('Schedule', 'scheduleId', 'schedule',
                { items: this.schedules },
                { cellOpts: { width: '30%' }, validators: [required] }),

            FormNumberComponent.make("Amount", "debit",
                { format: 'currency', width: 9, formatParms: '1.2-2' },
                { valueChanges: this.creditValueChanges.bind(this) }
            ),

            FieldMaker.notes({ cellOpts: { width: '30%' } }),
            FieldMaker.rev(),
            FieldMaker.deleteGridRow(),
        ],
        objFactory: this.newChildTxn.bind(this),
    });

    creditField = FormNumberComponent.make("Total", "credit",
        { format: 'currency', width: 9, formatParms: '1.2-2' },
        { formRow: 1, formColumn: 4, readonly: true, validators: [nonZero] }
    );

    idField = FieldMaker.id();
    refField = FormNumberComponent.make('Reference', 'refNr', {}, { cellOpts: { heading: 'Ref' }, readonly: true, formColumn: 3 });

    revField = FieldMaker.rev({ visible: Field.showAll, readonly: true, formColumn: 3 });

    outstandingField = FormNumberComponent.make("Outstanding", "outstanding",
        { format: 'currency', width: 9, formatParms: '1.2-2' },
        { formRow: 1, formColumn: 4, readonly: true, disable: true }
    );

    config = new FormConfig({
        navRoute: CreditNotePageComponent.navRoute,
        title: Txn.TYPE.CREDIT_NOTE.name,
        help: $localize`Service Credit Notes`,
        readonly: true,
        fieldSet: new FieldSet({
            fields: [

                this.idField,
                this.refField,
                this.revField,

                FormTextComponent.make('ledgerId', 'ledgerId', { visible: Field.noShow }),
                FormTextComponent.make('typeId', 'typeId', { visible: Field.noShow }),
                FormTextComponent.make('bCodeId', 'bCodeId', { visible: Field.noShow }),

                /* txtDateField Validator overwrites period info, need this order! */
                this.cycleField,
                this.periodField,
                this.txnDateField,

                FormComboBoxComponent.make('Unit', 'unitId', 'unit',
                    {
                        service: this.unitSvc, optionDisplayValue: (o: Unit) => Unit.getFullName(o),
                        refreshes: ['personId', 'personFullName', 'Address']
                    },
                    { formColumn: 1, validators: [required] },
                ),

                FormDateComponent.make('Services From', 'svcFrom', {
                    cellOpts: { width: '2%' }, formColumn: 1,
                    validators: [validateDate]
                }),
                FormDateComponent.make('Services To', 'svcTo', {
                    cellOpts: { width: '2%' }, formColumn: 1,
                    validators: [validateDate]
                }),

                FormTextComponent.make('Person', 'personId', {
                    visible: Field.noShow,
                    refresh: (o: Unit, ctl: AppFormControl) => ctl.setValue(o?.owner?.id),
                    formColumn: 3
                }),

                FormButtonComponent.makeLink('Person', 'personFullName', '/people/${personId}', {
                    cellOpts: { width: '20%' }, disable: true,
                    calculateValue: (o: Txn) => Person.fullName(o.person),
                    refresh: (o: Unit, ctl: AppFormControl) => ctl.setValue(Person.fullName(o.owner)),
                    formColumn: 2
                }),

                FormTextAreaComponent.make('Address', 'unit.address',
                    {
                        name: 'Address', formColumn: 2, readonly: true, disable: true, sendServer: false,
                        refresh: (o: Unit, ctl: AppFormControl) => ctl.setValue(o.address),
                    }),

                this.creditField,
                this.outstandingField,
                this.childTxn,
            ],
            formLayout: [
                { cells: [{ width: '25%' }, { width: '25%' }, { width: '25%' }, { width: '25%' }] },
                { cells: [{ colspan: 4, pageTab: 'yes' }] }
            ],
        }),
        service: this.dataSvc,
        mode: 'list',
        objectFactory: this.newFactory.bind(this),
        configReady: this.configReady,
        beforeEdit: this.enhanceObject.bind(this),
        newOptions: [
            { name: $localize`New ${Txn.TYPE.INVOICE.name}`, basePath: `${Txn.TYPE.INVOICE.code}/NEW` },
            { name: $localize`New ${Txn.TYPE.CREDIT_NOTE.name}`, basePath: this.path + '/NEW', params: { typeId: Txn.TYPE.CREDIT_NOTE.id } }
        ]
    });

    constructor(public dataSvc: CreditNoteService, public bCodeSvc: BCodeService,
        protected activeRoute: ActivatedRoute,
        protected unitSvc: UnitService, public personSvc: PersonService,
        protected cycleSvc: CycleService, protected periodSvc: PeriodService,
        protected scheduleSvc: ScheduleService,
        private currentUserSvc: CurrentUserService) {
        super();

        combineLatest([currentUserSvc.getCurrentUser(),
        this.scheduleSvc.get(true).pipe(first()),
        this.cycleSvc.get(true), this.currentUserSvc.getDefaultBCodes()
        ]).subscribe(
            ([currentUser, schedules, cycles]) => {
                this.currentUser = currentUser;
                this.schedules = schedules as Schedule[];
                this.configReady.next(null);
                this.currentCycle = this.cycleSvc.getCurrentCycle();
                this.cycleField.picklist.items = cycles;
            });
    }


    enhanceObject(o: Txn) {
        return o;
    }

    newFactory(): Observable<Txn> {
        this.revField.visible = Field.noShow;

        const txn = new Txn();
        this.config.readonly = false;

        txn.ledgerId = Txn.LEDGER.AR.id;
        txn.typeId = Txn.TYPE.CREDIT_NOTE.id;

        //txn.bCode = this.currentUser.currentTeam.debtorsBCode;
        txn.bCodeId = this.currentUserSvc.getDefaultBCode('Debtors').bCodeId;

        txn.txnDate = moment().toISOString().substring(0, 10);
        txn.txnCycleId = this.cycleSvc.getCurrentCycle().id;
        txn.txnPeriodId = this.cycleSvc.getCurrentPeriod().id;

        txn.svcFrom = this.cycleSvc.getCurrentCycle().from;
        txn.svcTo = this.cycleSvc.getCurrentCycle().to;

        // txn.led
        // txn.typeId = Txn.TYPE.INVOICE.id;
        // txn.bCodeId = Defaulted on the server?
        return of(txn);
    }

    createChildItem(): Txn {
        const txn = new Txn();
        txn.ledgerId = Txn.LEDGER.AR.id;
        txn.typeId = Txn.TYPE.CREDIT_NOTE_ITEM.id;
        txn.txnDate = this.txnDateField.control.value;
        txn.txnPeriodId = this.periodField.control.value;
        txn.txnCycleId = this.cycleField.control.value;
        if (this.schedules.length === 1) {
            txn.scheduleId = this.schedules[0].id;
        }
        return txn;
    }

    newChildTxn(): Observable<Txn> {

        return of(this.createChildItem());
    }

    dateValueChanges(): void {
        const allocations = (this.childTxn.control as GridControl).controls;
        if (allocations) {
            for (const txnRow of allocations) {
                txnRow.get('txnDate').setValue(this.txnDateField.control.value, { emitEvent: false });
                txnRow.get('txnCycleId').setValue(this.cycleField.control.value, { emitEvent: false });
                txnRow.get('txnPeriodId').setValue(this.periodField.control.value, { emitEvent: false });
            }
        }
    }

    creditValueChanges(): void {
        const txns = (this.childTxn.control as GridControl).controls;
        let total = 0;

        if (txns) {
            for (const tRow of txns) {
                total += tRow.get('debit').value;
            }
        }
        this.creditField.control.setValue(total);
        this.outstandingField.control.setValue(0 - total);
    }
}
