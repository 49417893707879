
/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { formatPercent } from '@angular/common';
import { UntypedFormGroup, } from '@angular/forms';
import { Schedule } from 'src/app/model/schedule';
import { ScheduleCriteria } from 'src/app/model/schedule-criteria';
import { SchedulePortion } from 'src/app/model/schedule-portion';
import { Unit } from 'src/app/model/unit';
import { UnitType } from 'src/app/model/unit-type';
import { AppFormControl } from 'src/app/shared/form/app-form-control';
import { ErrorCode, FormError } from 'src/app/shared/form/form-error/form-error.component';
import { GridRow } from 'src/app/shared/grid/grid-row';
import { GridControl } from 'src/app/shared/grid/grid-control';
import { Field } from '../../shared/field/Field';
import { uuid } from 'src/app/model/abstract-object';

class ZeroCount {
    cnt = 0;
    portions = 0;
}

export class ActionHandler {

    units: Unit[];
    unitTypes: UnitType[];
    calculated = false;
    calculating = false;
    formGroup: UntypedFormGroup;
    errors: FormError[] = [];
    errorMap = new Map<string, FormError>();

    scope = this.newScope();

    beforeSave() {
        this.formGroup = null;
        this.calculating = true;
    }

    afterSave(formGroup: UntypedFormGroup) {
        this.calculating = false;
        this.calculated = false;
        this.formGroup = formGroup;
        this.calculate();
    }

    newScope() {
        return {
            includeTypes: {},
            excludeTypes: {},
            includeCategories: {},
            excludeCategories: {},
            typeCounts: {},
            categoryCounts: {},
            portionMap: new Map<uuid, GridRow>(),
            typesIncludeCategories: {},
            unitCount: new ZeroCount(),
            matchCount: { cnt: 0, portions: 0, percentage: 0 },
        };
    }

    setupCounters() {
        this.scope.unitCount.cnt = this.units.length;
        this.unitTypes.forEach((unitType) => {
            this.scope.typeCounts[unitType.id] = new ZeroCount();
            unitType.categories.forEach((category) => {
                this.scope.categoryCounts[category.id] = new ZeroCount();
            });
        });
        this.scope.typeCounts['null'] = new ZeroCount();
    }

    refreshCounters() {
        this.formGroup.get('totalUnitCount').setValue(this.scope.matchCount.cnt + ' of ' + this.scope.unitCount.cnt, { emitEvent: false });
        const portionCnt = (Math.round(this.scope.matchCount.portions * 1000) / 1000)
            + ' (' + formatPercent(this.scope.matchCount.percentage, Field.locale, '3.3-3') + ')';
        this.formGroup.get('totalPortionCount').setValue(portionCnt, { emitEvent: false });
        (this.formGroup.get('criteria') as GridControl).controls.forEach((gridRow: GridRow) => {
            if (gridRow.get('unitCount')) {
                (gridRow.get('unitCount') as AppFormControl).setValue(this.getUnitCount(gridRow.value), { emitEvent: false });
            }
            if (gridRow.get('portionCount')) {
                (gridRow.get('portionCount') as AppFormControl).setValue(this.getPortionCount(gridRow.value), { emitEvent: false });
            }
        });
    }

    changeChargeType(newChargeType) {
        this.enableCriteriaPortions(newChargeType);
        this.enablePortionPortions(newChargeType);
        //this.needsToBeApplied(newChargeType, changedField);
        this.needsToBeApplied();
    }

    enableCriteriaPortions(scheduleChargeType: number) {
        let enabled = false;
        if (scheduleChargeType === Schedule.chargeId.byType) {
            enabled = true;
        }

        ((this.formGroup.get('criteria') as GridControl).controls as GridRow[]).forEach(gridRow => {
            const portion = gridRow.get('portion');
            if (enabled && portion.disabled) {
                portion.enable({ emitEvent: false });
            } else if (!enabled && portion.enabled) {
                portion.disable({ emitEvent: false });
            }
        });
    }

    enablePortionPortions(scheduleChargeType: number) {
        let enabled = false;
        if (scheduleChargeType === Schedule.chargeId.custom) {
            enabled = true;
        }

        ((this.formGroup.get('portions') as GridControl).controls as GridRow[]).forEach(gridRow => {
            const portion = gridRow.get('portion');
            if (enabled && portion.disabled) {
                portion.enable({ emitEvent: false });
            } else if (!enabled && portion.enabled) {
                portion.disable({ emitEvent: false });
            }
        });
    }

    portionMap() {
        (this.formGroup.get('portions') as GridControl).gridRows().forEach((portion: GridRow) => {
            this.scope.portionMap.set(portion.get('unitId').value, portion);
        });
    }

    calculate() {
        if (!this.calculating) {
            const schedule: Schedule = this.formGroup.getRawValue();
            this.calculating = true;
            this.clearErrors();
            //const start = new Date().getTime();
            this.scope = this.newScope();
            this.portionMap();
            this.setupCounters();
            this.scopeCriteria(schedule, this.scope);
            this.scopeDefaultIncludes(this.unitTypes, this.scope);
            this.createUnitPortions(schedule);
            this.updatePortionPercentages();
            this.refreshCounters();
            this.calculated = true;
            this.calculating = false;
            //const completed = new Date().getTime();
            //console.log('Calculation Completed in ' + (completed - start) + 'ms', this.scope, schedule);
        }
    }

    clearErrors() {
        this.errors = [];
        this.errorMap.clear();
    }

    reportError(eCode: string, eMsg: string) {
        if (!this.errorMap.has(eCode)) {
            const e = new FormError(new ErrorCode(eCode, eMsg));
            this.errors.push(e);
            this.errorMap.set(e.code, e);
        }
    }

    validate() {
        const eCode = 'scheduleNoMatch';
        if (this.scope.matchCount.cnt === 0 && !this.calculating) {
            this.reportError(eCode, 'Schedule does not match any units');
        } else {
            if (this.errorMap.has(eCode)) {
                //console.log('Removing Error', this.errors, this.errorMap, new Date().getTime());
                this.errorMap.delete(eCode);
            }
        }
        if (this.errors.length === 0) {
            return null;
        } else {
            return this.errors;
        }
    }

    needsToBeApplied() {
        //console.log('needs to apply');
        this.calculated = false;
    }

    applyIfNeeded() {
        if (this.formGroup && !this.calculated && !this.calculating) {
            this.calculate();
        }
    }

    getPortionCount(criteria: ScheduleCriteria) {
        return Math.round(this.getCount(criteria).portions * 1000) / 1000;
    }

    getUnitCount(criteria: ScheduleCriteria) {
        return this.getCount(criteria).cnt;
    }

    getCount(criteria: ScheduleCriteria): ZeroCount {
        if (criteria.categoryId) {
            return this.scope.categoryCounts[criteria.categoryId];
        } else if (criteria.typeId) {
            return this.scope.typeCounts[criteria.typeId];
        } else {
            return this.scope.unitCount;
        }
    }

    incrementCounters(unit: Unit, portion: number) {
        const typeId = unit.typeId ? unit.typeId : 'null';
        this.scope.typeCounts[typeId].cnt++;
        this.scope.typeCounts[typeId].portions += portion;
        if (unit.categoryId) {
            this.scope.categoryCounts[unit.categoryId].cnt++;
            this.scope.categoryCounts[unit.categoryId].portions += portion;
        }
        this.scope.matchCount.cnt++;
        this.scope.matchCount.portions += portion;
    }

    calculatePortion(schedule: Schedule, unit: Unit, typePortion: number, catPortion: number): number {
        let portion = 0;
        if (schedule.chargeTypeId === Schedule.chargeId.fixed) {
            portion = 1;
        } else if (schedule.chargeTypeId === Schedule.chargeId.bySize) {
            //TODO: Throw no size
            portion = unit.size;
            if (!unit.size) {
                this.reportError('missingSize', 'A schedule by size must only include units with a specified size. ');
            }
        } else if (schedule.chargeTypeId === Schedule.chargeId.byCarSpace) {
            portion = unit.numberCarSpaces ? unit.numberCarSpaces : 0;
        } else if (schedule.chargeTypeId === Schedule.chargeId.byType) {
            portion = catPortion ? catPortion : typePortion;
        } else if (schedule.chargeTypeId === Schedule.chargeId.custom) {
            const currentPortion = this.scope.portionMap.get(unit.id);
            if (!currentPortion) {
                console.log('No Portion');
                portion = 0; // will not be shown on grid, for now cannot add custom portion to unit, only import...
            } else if (currentPortion.get('portion').value === 0) {
                portion = 0; // will not be shown on grid, for now cannot add custom portion to unit, only import...
            } else {
                portion = currentPortion.get('portion').value;
            }
        }
        return portion;
    }

    updatePortionPercentages() {
        const portions = this.formGroup.get('portions') as GridControl;
        const totalPortions = this.scope.matchCount.portions;
        portions.gridRows().forEach(gridRow => {
            const sPercent = ((gridRow.get('portion').value / totalPortions)).toFixed(8);
            const pctCtl = gridRow.get('percent');
            if (pctCtl) {
                pctCtl.setValue(sPercent);
            }
            this.scope.matchCount.percentage += +sPercent;
        });
    }

    createUnitPortions(schedule: Schedule) {
        const portions = this.formGroup.get('portions') as GridControl;

        this.units.forEach((unit) => {
            const typePortion = unit.typeId ? this.scope.includeTypes[unit.typeId] : this.scope.includeTypes['null'];
            const catPortion = this.scope.includeCategories[unit.categoryId];
            let portion = 0;
            if (!this.scope.excludeTypes[unit.typeId] && (!unit.categoryId || !this.scope.excludeCategories[unit.categoryId])) {
                if (typePortion && (!unit.categoryId || catPortion)) {
                    //const newPortion = new SchedulePortion({ unit, portion });
                    portion = this.calculatePortion(schedule, unit, typePortion, catPortion);
                    this.incrementCounters(unit, portion);

                    if (!this.scope.portionMap.has(unit.id)) {
                        this.scope.portionMap.set(unit.id, portions.addRow(new SchedulePortion({ unit }), true, false));
                        if (!this.formGroup.dirty) {
                            this.formGroup.markAsDirty();
                        }
                    }
                    this.scope.portionMap.get(unit.id).get('portion').setValue(portion);
                }
            }
            const gridRow = this.scope.portionMap.get(unit.id);
            if (portion === 0) {
                if (gridRow && !gridRow.deleted) {
                    gridRow.delete();
                }
            } else {
                if (gridRow && gridRow.deleted) {
                    gridRow.delete(); // Undelete it, it has a portion again!
                }
            }
        });

        return true;
    }

    scopeDefaultIncludes(unitTypes: UnitType[], scope) {
        // Unless one type or category included, all get included!
        if (Object.getOwnPropertyNames(scope.includeTypes).length === 0) {
            unitTypes.forEach((unitType) => {
                if (!scope.excludeTypes[unitType.id]) {
                    scope.includeTypes[unitType.id] = 1;
                }
            });
            scope.includeTypes['null'] = 1; // Including Nulls!
        } else {
            scope.includeTypes['null'] = 0; // Including Nulls!
        }

        unitTypes.forEach((unitType) => {
            if (!scope.typesIncludeCategories[unitType.id]) {
                unitType.categories.forEach((cat) => {
                    if (!scope.excludeCategories[cat.id]) {
                        scope.includeCategories[cat.id] = scope.includeTypes[unitType.id];
                    }
                });
            }
        });
    }

    scopeCriteria(schedule: Schedule, scope) {
        schedule.criteria.forEach((criteria) => {
            if (!criteria.deleted) {
                if (criteria.include) {
                    if (criteria.typeId) {
                        scope.includeTypes[criteria.typeId] = criteria.portion;
                    }
                    if (criteria.categoryId && criteria.typeId) {
                        scope.typesIncludeCategories[criteria.typeId] = criteria.portion;
                        scope.includeCategories[criteria.categoryId] = criteria.portion;
                    }
                } else {
                    if (criteria.categoryId) {
                        scope.excludeCategories[criteria.categoryId] = true;
                    } else {
                        scope.excludeTypes[criteria.typeId] = true;
                    }
                }
            }
        });
    }
}
