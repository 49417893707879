<!--mat-card class="basecard"-->
<h1 class="mat-headline-5">Account Overview</h1>
<mat-card appearance="raised">
  <!-- [(selectedIndex)]="selectedTabIndex" -->
  <mat-tab-group  mat-align-tabs="start" mat-stretch-tabs="false">
    @for (unit of units; track unit) {
      <mat-tab label="Unit {{unit.name}}">
        @if (unit && unit.inProgressPayz && unit.inProgressPayz.length > 0) {
          <div class="inProgress">
            <h2 class="mat-h2">Payment(s) in progress</h2>
            <ul>
              @for (inProg of unit.inProgressPayz; track inProg) {
                <li>
                  A payment has been created {{getMoment(inProg.completed)}} for {{(inProg.amount / 100) |
                  currency}}
                  by {{inProg.user.name}} ({{inProg.user.email}}) which has not yet been cleared by your bank.
                  Typically it takes several days for a payment to clear.
                  This amount will be reflected in your account balance when confirmation is received.
                </li>
              }
            </ul>
          </div>
        }
        <mat-accordion>
          <mat-expansion-panel hideToggle [expanded]="payInFullOpen" (opened)="payInFullOpen=true"
            (closed)="payInFullOpen=false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon color="primary">{{payInFullOpen ? 'chevron_right' : 'expand_more'}}</mat-icon>
                Pay In Full
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="margin-left:40px">
              <app-pay-in-full [unit]="unit" [cycle]="currentCycle"></app-pay-in-full>
            </div>
          </mat-expansion-panel>
          @if (unit.payzMandate) {
            <mat-expansion-panel hideToggle [expanded]="mandateOpen"
              (opened)="mandateOpen=true" (closed)="mandateOpen=false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon color="primary">{{mandateOpen ? 'chevron_right' : 'expand_more'}}</mat-icon>
                  Current Mandate
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div style="margin-left:40px">
                <app-unit-mandate [unit]="unit"></app-unit-mandate>
              </div>
            </mat-expansion-panel>
          }
          <mat-expansion-panel hideToggle [expanded]="setupPlanOpen" (opened)="setupPlanOpen=true"
            (closed)="setupPlanOpen=false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon color="primary">{{setupPlanOpen ? 'chevron_right' : 'expand_more'}}</mat-icon>
                Setup payment plan
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="margin-left:40px">
              <app-pay-plan [unit]="unit" [cycle]="currentCycle"></app-pay-plan>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel hideToggle [expanded]="paySomethingOpen" (opened)="paySomethingOpen=true"
            (closed)="paySomethingOpen=false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon color="primary">{{paySomethingOpen ? 'chevron_right' : 'expand_more'}}
                </mat-icon>Part Payment
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="margin-left:40px">
              <app-part-pay [unit]="unit"></app-part-pay>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel hideToggle [expanded]="sepaMethodsOpen" (opened)="sepaMethodsOpen=true"
            (closed)="sepaMethodsOpen=false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon color="primary">{{sepaMethodsOpen ? 'chevron_right' : 'expand_more'}}
                </mat-icon>Payment Methods on file
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="margin-left:40px">
              <ng-container *ngTemplateOutlet="sepaMethodsTemplate"></ng-container>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel hideToggle [expanded]="transactionsOpen" (opened)="transactionsOpen=true"
            (closed)="transactionsOpen=false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon color="primary">{{transactionsOpen ? 'chevron_right' : 'expand_more'}}
                </mat-icon>Show All Transactions
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="margin-left:40px">
              @if (unit.transactions) {
                <table class="ouromc">
                  <tr>
                    <th>Ref</th>
                    <th i18n>Date</th>
                    <th i18n>Type</th>
                    <th i18n>Debit</th>
                    <th i18n>Credit</th>
                    <th i18n>Outstanding</th>
                    <th i18n>Notes</th>
                  </tr>
                  @for (txn of unit.transactions; track txn) {
                    <tr>
                      <td><a href="{{getTxnLink(txn)}}">{{getTxnRef(txn)}}</a></td>
                      <td>{{txn.txnDate}}</td>
                      <td>{{getTxnType(txn.typeId)}}</td>
                      <td>{{txn.debit | currency}}</td>
                      <td>{{txn.credit | currency}}</td>
                      <td>{{txn.outstanding | currency}}</td>
                      <td>{{txn.notes}}</td>
                    </tr>
                  }
                </table>
              }
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-tab>
    }
  </mat-tab-group>
</mat-card>
<ng-template #unitsTemplate>
  <mat-card appearance="raised">
    <mat-card-title i18n>
      Units
    </mat-card-title>
    <mat-card-content>
      <table class="ouromc">
        <tr>
          <th>Unit</th>
          <th i18n>Payment Frequency</th>
          <th i18n>Payment Method</th>
          <th i18n>Payment Day</th>
          <th i18n>Outstanding</th>
          <th i18n>Last Payment</th>
        </tr>
        @for (unit of units; track unit) {
          <tr>
            <td>{{unit.name}}</td>
            <td>
              <mat-select [value]="unit.frequencyId">
                @for (freq of frequencies; track freq) {
                  <mat-option [value]="freq.id">{{freq.name}}
                  </mat-option>
                }
              </mat-select>
            </td>
          </tr>
        }
      </table>
    </mat-card-content>
  </mat-card>
</ng-template>
<ng-template #sepaMethodsTemplate>
  <mat-card-subtitle i18n>Storing your details allows discounted payment schedule</mat-card-subtitle>
  @if (sepaMethods) {
    <table class="ouromc">
      <tr>
        <th i18n>last4</th>
        <th i18n>Bank</th>
        <th i18n>Sort Code</th>
        <th i18n>Name</th>
        <th i18n>Email</th>
        <th i18n>Address</th>
        <th i18n></th>
      </tr>
      @for (method of sepaMethods.data; track method) {
        <tr>
          <td>{{method.sepa_debit.last4}}</td>
          <td>{{method.sepa_debit.bank_code}}</td>
          <td>{{method.sepa_debit.branch_code}}</td>
          <td>{{method.billing_details.name}}</td>
          <td>{{method.billing_details.email}}</td>
          <td>{{method.billing_details.address.line1}},
            {{method.billing_details.address.line2}},
            {{method.billing_details.address.city}},
            {{method.billing_details.address.country}},
            {{method.billing_details.address.post_code}}
          </td>
          <td>
            <button type="button" color="primary" mat-icon-button class="deleteButton">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </tr>
      }
    </table>
  }
  <button mat-raised-button color="primary" i18n>Add Payment Method</button>
</ng-template>
