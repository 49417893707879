<div class="basePage">
  <div class="registerCard">
    <div style="display:flex; width: 100%">
        <div style="flex:1">
            <h1>
                Trial Balance - {{cycle?.name}}
            </h1>            
        </div>
        @if (myData) {
            <div>
                <button mat-raised-button color="primary" (click)="gotoImport()">Import</button>
            </div>
        }
    </div>
    <h2>
      {{companyName}}
    </h2>
    <table width="100%">
      <tr>
        <td>
          <app-ctl-holder [control]="cycleField.control"></app-ctl-holder>
        </td>
        <td style="max-width: 10em">
          <app-ctl-holder [control]="fromField.control"></app-ctl-holder>
        </td>
        <td style="max-width: 10em">
          <app-ctl-holder [control]="toField.control"></app-ctl-holder>
        </td>
        <td>
          <app-ctl-holder [control]="zerosField.control"></app-ctl-holder>
        </td>
      </tr>
    </table>
    <!-- ng-container [ngTemplateOutlet]="detailsOld" *ngIf="ready"></ng-container -->
    <ng-container [ngTemplateOutlet]="details"></ng-container>
  </div>
</div>
<ng-template #details>
  <mat-card style="margin-bottom: 20px">
    <mat-card-content>
      <table width="100%">
        <ng-container [ngTemplateOutlet]="headers"></ng-container>
        @for (type of types; track type) {
          <ng-container [ngTemplateOutlet]="typeHeader" [ngTemplateOutletContext]="{type: type}"></ng-container>
          @for (bcode of type['bcodes']; track bcode) {
            <ng-container
              [ngTemplateOutlet]="lineItem" [ngTemplateOutletContext]="{bcode: bcode}">
            </ng-container>
          }
        }
        <ng-container [ngTemplateOutlet]="totals"></ng-container>
      </table>
    </mat-card-content>
  </mat-card>
</ng-template>
<ng-template #typeHeader let-type="type">
  <tr>
    <th colspan="99" style="text-align: left; border-bottom: 1px inset">
      {{type.name}}
    </th>
  </tr>
</ng-template>
<ng-template #totals>
  <tr>
    <td colspan="2" class="total">
      Totals
    </td>
    <td class="total">{{totalCount}}</td>
    <td class="total">{{totalDebits | currency}}</td>
    <td class="total">{{totalCredits | currency}}</td>
  </tr>
</ng-template>
<ng-template #headers>
  <tr>
    <th>
      Account
    </th>
    <th>
      Sort Code
    </th>
    <th>
      Count
    </th>
    <th>
      Debits
    </th>
    <th>
      Credits
    </th>
  </tr>
</ng-template>
<ng-template #lineItem let-bcode="bcode">
  <tr class="detail">
    <td class="detail" style="padding-left: 20px">
      <a [routerLink]="getAccountLink(bcode)">{{bcode.name}}</a>
    </td>
    <td class="detail" style="text-align: right"> {{bcode.sort}} </td>
    <td class="detail" style="text-align: right">
      <a [routerLink]="getAccountTxn(bcode)">{{bcode.count}}</a>
    </td>
    <td class="detail" style="text-align: right"> {{bcode.debits | currency}} </td>
    <td class="detail" style="text-align: right"> {{bcode.credits | currency}} </td>
  </tr>
</ng-template>
