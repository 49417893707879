/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Omc } from 'src/app/model/Omc';
import { RequestTemplate } from 'src/app/model/RequestTemplate';
import { ActionColor, IFormAction } from 'src/app/shared/form/form.component';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { User } from 'src/app/model/user';
import { MyInjector } from 'src/app/app.module';
import { MessageService } from 'src/app/shared/message.service';
import { of } from 'rxjs';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { CtlHolderComponent } from 'src/app/shared/form/ctl-holder/ctl-holder.component';
import { required } from 'src/app/shared/validators';
import { DateHelper } from 'src/app/shared/dateHelper';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';


export class CreateNewTeam implements IFormAction {
    name = $localize`Complete Setup`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'build_circle';
    approvalNeeded = false;
    disabled = false;
    _approvalText = 'Setup OMC to ';
    availTemplates: RequestTemplate[];
    omc: Omc;
    tipText: string;

    public get approvalText() {
        return 'Create team to setup data for ' + this.omc.name;
    }

    constructor(private http: HttpClient, private currentUser: User) {
    }

    setTemplates(at: RequestTemplate[]) {
        this.availTemplates = at;
    }

    action(omc: Omc) {
        return MyInjector.instance.get(MatDialog).open(CreateOmcTeamComponent, {data: omc}).afterClosed().pipe( map(result => {
            if (result && result.id) {
                return result;
            } else {
                return this.omc;
            }
        }));
    }
    //  setup(serviceRequest: ServiceRequest) {
    setup(omc: Omc) {
        this.omc = omc;
        if (omc.id && !omc.omcTeamId && this.currentUser.currentRole?.isAdmin) {
            this.show = true;
        } else {
            this.show = false;
        }
    }
}
@Component({
    selector: 'app-omc-page',
    template: `
    <mat-dialog-content style="width: 400px">
        <h2>Create Team</h2>
        <h3>{{omc.name}}</h3>
        <app-ctl-holder [control]="slugCtl"></app-ctl-holder>
        <app-ctl-holder [control]=dateCtl></app-ctl-holder>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-raised-button color="accent" [mat-dialog-close]="true">cancel</button>
        <button mat-raised-button color="primary" (click)="completeSetup()">save</button>
    </mat-dialog-actions>
    
    `,
    standalone: true,
    imports: [MatButtonModule, MatDialogContent, MatDialogTitle, MatDialogActions, CtlHolderComponent, MatDialogClose]
})
export class CreateOmcTeamComponent {
    matDialogRef = inject(MatDialogRef<CreateOmcTeamComponent>);
    omc = inject(MAT_DIALOG_DATA) as Omc;
    http = inject(HttpClient);

    slugCtl = FormTextComponent.make('Slug', 'slug', {
        validators: [required],
        hint : `This is identifier used when making a request for the OMC. IT CANNOT BE CHANGED LATER, make sure you are happy with this value before you proceed`
    }).makeControl();

    dateCtl = FormDateComponent.make('First Cycle Starts On', 'firstCycleStart', {
        validators: [required],
        hint: `Please enter date of first cycle to be created. \
                This should be early enough to allow you to import any transaction data required for ${this.omc.name} \
                but all the periods created will need to be closed manually afterwards`
    }).makeControl();

    constructor() {
        let starter = 1;
        if (this.omc.yearEndMonth === 12 && this.omc.yearEndDay === 31) {
            starter = 2;
        }
        const year = new Date().getFullYear() - starter;
        let startDate = `${year}-${this.omc.yearEndMonth}-${this.omc.yearEndDay}`;
        startDate = DateHelper.addDays(startDate, 1);
        this.dateCtl.setValue(startDate);
        this.slugCtl.setValue(this.omc.slug);
    }

    completeSetup() {
        const url = AbstractHttpService.ajaxPath + 'teams/' + this.omc.id;
        this.omc['firstPeriodStart'] = this.dateCtl.value;
        this.omc.slug = this.slugCtl.value;
        const msgSvc = MyInjector.instance.get(MessageService);

        console.log('Completing setup...');
        this.http.post<Omc>(url, this.omc).pipe( map( success => {
            console.log(success);
            //window.location.href = ImportPageComponent.navRoute.url
            msgSvc.show('Team Setup Successful for ' + this.omc.name);
            this.matDialogRef.close(success);
        }), catchError(failure => {
                msgSvc.showError(failure, 'Team Setup');
                return of(this.omc)
        })).subscribe();
    }

}