/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { Component } from '@angular/core';
import { RoleHomePage } from '../role-home-page/role-home-page.component';
import { RoleHomePageComponent } from "../role-home-page/role-home-page.component";

@Component({
  selector: 'app-agents-component',
  standalone: true,
  imports: [RoleHomePageComponent],
  templateUrl: './agents.component.html',
})
export class AgentsHomePageComponent {
    role: RoleHomePage = {
        title: `Agents`,
        mainText: `<p>
            Property Management Agents are licensed professionals who make multi unit developments work.
        </p>`,
        lottieUrl: 'https://clgassets.fra1.cdn.digitaloceanspaces.com/lottie-files/agents.json',
        prevPageTitle: 'Directors',
        prevPageRoute: '/directors',
        nextPageTitle: 'Accounting',
        nextPageRoute: '/accountants',
        beforeText: `<p>
            For years now agents have struggled with antiquated systems that require lots of manual work.
            Far too much time is spent doing the basics. Not enough time is left to proactively manage properties.
        </p>`,
        afterText: `<p>
            OurOMC writes software for OMC's however we understand that the OMC does not work without the agent.
            So a huge part of our time is focused on helping the agent, automating the mundane stuff,
            leaving agents with more time to focus on managing the property.
        </p>`,
        benefitTitle: `Agent Benefits`,
        benefits: [{
            icon: 'account_balance',
            head: 'Automated Banking Feeds',
            text: ` OurOMC connects to all of the major Irish Banks, and pulls in transactions automatically.
                    You can create rules, to ensure that all transactions are automatically logged into the
                    accounts correctly and reconciled, saving hours of effort.
                    `,
        },{
            icon: 'payments',
            head: 'Budgeting, Billing & Chasing All Automated',
            text: `Automatically produce a budget based on last year's budget or actual spend.
                    Approve it at the AGM and send the charges at the click of a button.
                    Send automated follow-ups on overdue accounts.
            `,
        },{
            icon: 'live_help',
            head: 'Request Management',
            text: `
                Track, plan and prioritise the work you do.
                Understand how much work each of your OMCs generates.
                Avoid anything falling through the cracks.
            `,
        },{
            icon: 'balance',
            head: 'Get paid for what you do',
            text: `
                Track the work you do to demonstrate the value to your clients.
                Make sure that you have billed correctly for any work done over and above your SLA.
            `,
        },{
            icon: 'event_repeat',
            head: 'Recurring contracts and events',
            text: `
                AGMs, Budgets, Fire Safety, PSRA Contracts, Window Cleaning, Insurance Renewals.
                OurOMC helps you track and plan repeating events so nothing needs ever be missed again..
            `,
        },{
            icon: 'euro',
            head: 'Save money on IT Systems ',
            text: `
                Accounting, service, email and websites are expensive.
                For agents who bring their OMCs on as customers, we can help eliminate all of these IT costs.
            `,
        }
    ]
    }
}
