import { Component, inject } from '@angular/core';
import { FormConfig } from 'src/app/shared/form/FormConfig';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { BankReconciliationComponent } from '../bank-reconciliation.component';
import { FieldSet, LAYOUT_OPTIONS } from 'src/app/shared/form/field-set/field-set.component';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { BankAccountService } from '../../bank-account.service';
import { BankReconiliationService } from '../bank-reconciliation.service';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormPageComponent } from 'src/app/shared/form/form-page/form-page.component';

@Component({
  selector: 'app-list',
  standalone: true,
  imports: [FormPageComponent],
  templateUrl: './list.component.html',
  styleUrl: './list.component.scss'
})
export class ListComponent extends AbstractPageComponent {
    bankSvc = inject(BankAccountService);
    bankRecSvc = inject(BankReconiliationService);

    bankField = FormPicklistComponent.make('Bank', 'id', null, { service: this.bankSvc }).setupControl();

    config = new FormConfig({
        navRoute: BankReconciliationComponent.navRoute,
        title: $localize`Bank Reconciliation`,
        help: $localize`Whatever`,
        fieldSet: new FieldSet({
            fields: [
                FormButtonComponent.makeNavDetailButton('Bank Account', 'bankName', 'bankId',
                    BankReconciliationComponent.navRoute, true
                ), FormTextComponent.make('OMC', 'legalName', { readonly: true }),
                FormDateComponent.make('Oldest Item', 'oldest', { readonly: true }),
                FormDateComponent.make('Newest Item', 'newest', { readonly: true }),
                FormNumberComponent.make('Items', 'items', {}, { readonly: true }),
                FormNumberComponent.makeCurrency('To Be Reconciled', 'value'),
            ],
            formLayout: LAYOUT_OPTIONS.singleCol,
        }),
        service: this.bankRecSvc,
        mode: 'list',
    });
}
