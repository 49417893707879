/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent } from '@angular/material/dialog';
import { FormConfig } from "../../form/FormConfig";
import { IsNarrowService } from '../../is-narrow.service';
import { DialogOptions } from '../pick-dialog/pick-dialog.component';
import { FormComponent } from '../../form/form.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DialogBannerComponent } from '../dialog-banner-component';

@Component({
    selector: 'app-edit-dialog',
    templateUrl: './edit-dialog.component.html',
    styleUrls: ['./edit-dialog.component.scss'],
    standalone: true,
    imports: [MatTooltipModule, MatIconModule, MatDialogContent, FormComponent, DialogBannerComponent]
})
export class EditDialogComponent implements OnInit {

    config: FormConfig;
    width = 500;
    height = 300;

    constructor(protected isNarrowSvc: IsNarrowService,
        public dialogRef: MatDialogRef<EditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogOptions: DialogOptions) {

        this.config = dialogOptions.config;
        this.config.service = dialogOptions.service;
        if (!dialogOptions.config.mode) {
            this.config.mode = 'edit';
        }
        this.dialogRef.disableClose = true;
        //TODO - Override Params?....
    }

    ngOnInit(): void {
        this.width = this.dialogOptions.width ? this.dialogOptions.width : this.isNarrowSvc.screenWidth * .8;
        this.height = this.dialogOptions.height ? this.dialogOptions.height : this.isNarrowSvc.screenHeight * .8;
    }

    closeDialog() {
        this.dialogRef.close(null);
    }
}
