/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs';

import { Ballot } from 'src/app/model/ballot';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { FormDateTimeComponent } from 'src/app/shared/form/form-date-time/form-date-time.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormRichTextComponent } from 'src/app/shared/form/form-rich-text/form-rich-text.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { NavRoute } from 'src/app/shared/NavRoute';
import { required } from 'src/app/shared/validators';
import { UserService } from '../../user/user.service';
import { BallotEditorComponent } from '../ballot-editor/ballot-editor.component';
import { QuestionService } from '../question-page/question.service';
import { BallotService } from './ballot.service';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { CurrentUserService } from '../../user/current-user.service';
import { CommsTemplateService } from '../comms-template-page/comms-templates.service';
import { NavOption } from 'src/app/model/sys/navOption';
import { CommsTemplate, CommsTemplateType } from 'src/app/model/CommsTemplate';
import { User } from 'src/app/model/user';

@Component({
  selector: 'app-ballot-page',
  templateUrl: './ballot-page.component.html',
  styleUrls: ['./ballot-page.component.scss'],
  standalone: true,
  imports: [FormPageComponent]
})
export class BallotPageComponent extends AbstractPageComponent {

  static readonly navRoute = new NavRoute('social/ballots', BallotPageComponent, 'ballot')
    .setNewComponent(BallotEditorComponent)
    .setItemComponent(BallotEditorComponent)

  forumField = FormPicklistComponent.make('Channel where this vote will happen', 'forumId', 'forum',
    { items: this.currentUserService.getForums() }, { validators: [required] }
  );

  newItems : NavOption[] = [
      { name: $localize`New Ballot`, basePath: BallotPageComponent.navRoute.newUrl }
  ]


  config = new FormConfig({
    navRoute: BallotPageComponent.navRoute,
    title: $localize`Ballot Administration`,
    help: $localize`Ballot of one or more members`,
    fieldSet: new FieldSet({
      fields: [
        FieldMaker.id(),
        FieldMaker.rev(),
        FormTextComponent.make('Title', 'title', { validators: [required] }),
        FormRichTextComponent.make('Details', 'content', { name: 'ballotContent', formColumn: 2 }),
        FormDateTimeComponent.make('Meeting Start', 'opensAt'),
        FormDateTimeComponent.make('Meeting End', 'closesAt'),
        this.forumField,
        FormTextComponent.make('Area', 'areaName'),
        FormTextComponent.make('Core', 'coreName'),
      ],
      formLayout: [{ cells: [{ width: '33%' }, { width: '66%' }] },
      { cells: [{ colspan: 2 }] }],
    }),
    service: this.dataSvc,
    newOptions: this.newItems,
    mode: 'list',
    objectFactory: () => of(new Ballot())
  });

  constructor(public dataSvc: BallotService,
    public dialog: MatDialog,
    protected questionSvc: QuestionService,
    private currentUserService: CurrentUserService,
    protected userSvc: UserService, commsTmplSvc : CommsTemplateService) {
    super();
    this.currentUserService.getCurrentUser().subscribe( u => {
        const bp = User.getModelPrivilege(u, 'Ballot');
        if (bp.post) {
            commsTmplSvc.get(true).subscribe((ts: CommsTemplate[]) => {
                ts.forEach(t => {
                    if (t.type === CommsTemplateType.BALLOT.id) {
                        this.newItems.push(
                            { name: $localize`New ${t.title.substring(0, 30)}`, basePath: BallotPageComponent.navRoute.newUrl, params: { template: t.id } }
                        );
                    }
                })
            })
        }
    })

  }
}
