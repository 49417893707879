@if (item.children) {
  <mat-expansion-panel class="nav-item ouromc-sidenav" (opened)="onOpen()" [expanded]="item.expanded"
    style="padding-right: 5px; padding-top: 5px">
    <mat-expansion-panel-header style="height:32px;">
      <mat-panel-title style="margin-top: -5px">
        <a [routerLink]="myRouterLink(item)"
          style="text-decoration: none; color:inherit">
          <div class="menuItem"  attr.data-nav-menu="{{item.id}}" (click)="selectOption(item)" style="width:100%"
            [ngClass]="{'childMenuItem': asChild, 'selectedMenuItem': item === selectedItem}">
            <mat-icon [matTooltip]="item.title" color="{{item.color ? item.color : 'primary'}}" class="color_green">{{itemIcon(item)}}</mat-icon>
            @if (showMenuText) {
              <span style="font-size: 14px;">{{item.title}}</span>
            }
          </div>
        </a>
      </mat-panel-title>
    </mat-expansion-panel-header>
    @for (child of item.children; track child) {
      @if (child.visible) {
        <app-nav-item [item]="child" [asChild]=true [showMenuText]="showMenuText"
        (opened)="opened.emit($event)" (selected)="selectChild($event)" [selectedItem]="selectedItem"></app-nav-item>
      }
    }
  </mat-expansion-panel>
} @else {
  @if (item.url || item.disable) {
    <a [routerLink]="item.views?.length > 0 ? ['/' + item.url, {_view: item.views[0].id}] : ['/' + item.url]"
      style="text-decoration: none; color:inherit">
      @if (!item.disable) {
        <div class="menuItem" (click)="selectOption(item)" attr.data-nav-menu="{{item.id}}"
          [ngClass]="{'childMenuItem': asChild, 'selectedMenuItem': item === selectedItem}">
          <mat-icon [matTooltip]="item.title" color="{{item.color ? item.color : 'primary'}}" class="color_green">{{itemIcon(item)}}</mat-icon>
          @if (showMenuText) {
            <span style="font-size: 14px;">{{item.title}}</span>
          }
        </div>
      }
    </a>
    @if (item.disable) {
      <div class="disabled-item nav-item">
        <a class="nav-item" attr.data-nav-menu="{{item.id}}" [ngClass]="asChild ? 'child' : ''">
          <mat-icon>{{itemIcon(item)}}</mat-icon>@if (showMenuText) {
          <span>{{item.title}}</span>
        }
      </a>
    </div>
  }
} @else {
  <div class="group-header body-1"><a class="nav-item" attr.data-nav-menu="{{item.id}}">
    <mat-icon>{{itemIcon(item)}}</mat-icon>@if (showMenuText) {
    <span>{{item.title}}</span>
  }
</a></div>
}
}
