<h2 mat-dialog-title>
    Link Bank Account {{bankAccount.name}}
</h2>
<p style="margin-left: 30px">
    Click on your banking provider to connect. Linking your bank account will enable you to import transaction data and hugely simplify reconciliation process
</p>
<mat-dialog-content>
    <div style="display: flex; flex-wrap: wrap; align-items: stretch;">
        @for (option of options; track $index) {
            <div class="option-box">
                <div style="display:flex" (click)="linkAccount(option)">
                    <div style="flex: 0">
                        <img [src]="option.logo" [alt]="option.name" style="max-height:58px"/>
                    </div>
                    <div style="flex: 1; padding-left: 20px; vertical-align: middle; font-size: 14px">
                        {{option.name}}
                    </div>
                </div>          
            </div>
        }
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
