<div>
  <div class="profileCard">
    <mat-card appearance="raised">
      <mat-card-title style="text-align: center;">User Profile</mat-card-title>
      <mat-card-content>
        <table width="100%">
          <tr>
            <td class="label">
              Username
            </td>
            <td class="value">
              {{currentUser?.name}}
            </td>
            <td>
              <!-- button mat-icon-button color="primary" class="edit" disabled="true">
              <mat-icon style="font-size: 14px;">edit</mat-icon>
              </button -->
            </td>
            @if (!isMobile) {
              <td class="help">
                This is the name that you will be known by in the system. It is displayed when someone
                hovers on your avatar.
              </td>
            }
          </tr>
          <tr>
            <td class="label">
              Email Address
            </td>
            <td class="value">
              <div style="float:left">
                {{currentUser?.email}}
              </div>
            </td>
            <td></td>
            @if (!isMobile) {
              <td class="help" >
                Used to login and for all communications from OurOMC to you.
              </td>
            }
          </tr>
          <tr>
            <td class="label">
              Avatar
            </td>
            <td class="value">
              <app-avatar [id]="currentUser?.id"></app-avatar>
            </td>
            <td>
              <!-- button mat-icon-button color="primary" class="edit" disabled="true">
              <mat-icon style="font-size: 14px;">edit</mat-icon>
              </button -->
            </td>
            @if (!isMobile) {
              <td class="help" >
                This is displayed in the top left corner of screen and on any posts and comments you make in
                the system
              </td>
            }
          </tr>
          <!-- tr>
          <td class="label">
            Fullname
          </td>
          <td class="value">
          </td>
          <td>
            <button mat-icon-button color="primary" class="edit" disabled="true">
              <mat-icon style="font-size: 14px;">edit</mat-icon>
            </button>
          </td>
          <td class="help"  *ngIf="!isMobile">
            Your full name the OMC should use in official correspondence
          </td>
        </tr>
        <tr>
          <td class="label">
            Telephone Number
          </td>
          <td class="value">
          </td>
          <td>
            <button mat-icon-button color="primary" class="edit" disabled="true">
              <mat-icon style="font-size: 14px;">edit</mat-icon>
            </button>
          </td>
          </tr -->
          <!-- tr>
          <td></td>
          <td><button mat-raised-button color="primary" [routerLink]="['/myRequests']">Create New Request</button></td>
          <td></td>
          </tr -->
        </table>
        @if (createAgencyAllowed()) {
          <button
          mat-raised-button (click)="createAgency()" color='primary'>Create Agency</button>
        }
      </mat-card-content>
    </mat-card>
  </div>
  <!-- div class="profileCard">
  <mat-card>
    <mat-card-content>
      <table width="100%">
        <tr *ngIf="currentUser?.teams.length > 0">
          <td class="label" *ngIf="!isMobile">
            Your Organisation(s):
          </td>
          <td class="value" colspan="2">
            <p *ngFor="let team of currentUser?.teams">
              <a href="" (click)="selectTeam(team)">{{team.name}}</a> ({{team.legalName}})
            </p>
          </td>
        </tr>
        <tr>
          <td class="label" *ngIf="!isMobile">
            <span *ngIf="currentUser?.teams.length > 0">Your OMC Not Listed?</span>
          </td>
          <td class="value">
            <button mat-raised-button color="primary" class="edit" (click)="addMeToAnOMC()">
              <mat-icon style="font-size: 24px;">add</mat-icon><span class="button">Add me to an OMC</span>
            </button>
          </td>
          <td class="help"  *ngIf="!isMobile">
            <span *ngIf="currentUser?.teams.length === 0">You need to join an OMC to use the system</span>
          </td>
        </tr>
      </table>
    </mat-card-content>
  </mat-card>
</div>
<app-new-agent *ngIf="currentUser.id === 1 || currentUser.id === 10 || currentUser.id === 7"></app-new-agent>
</div-->
