/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Input } from '@angular/core';
import { Field } from '../../field/Field';
import { AbstractObject } from 'src/app/model/abstract-object';
import { MatIconModule } from '@angular/material/icon';
import { IconTableCellComponent } from "../icon-table-cell/icon-table-cell.component";
import { LinkTableCellComponent } from "../link-table-cell/link-table-cell.component";
import { PicklistTableCellComponent } from "../picklist-table-cell/picklist-table-cell.component";
import { DateTimeTableCellComponent } from "../date-time-table-cell/date-time-table-cell.component";
import { LimitToPipe } from '../../pipes/limit-to.pipe';

@Component({
    selector: 'app-table-cell',
    standalone: true,
    templateUrl: './table-cell.component.html',
    styleUrl: './table-cell.component.scss',
    imports: [MatIconModule, LimitToPipe,
        IconTableCellComponent, LinkTableCellComponent, PicklistTableCellComponent, DateTimeTableCellComponent]
})
export class TableCellComponent {
    @Input({required: true}) field: Field;
    @Input({required: true}) focusItem: AbstractObject;
}
