/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewContainerRef } from '@angular/core';
import { AbstractObject } from 'src/app/model/abstract-object';
import { STD_ANIMATION } from 'src/app/shared/std-animation';
import { Sort, MatSortModule } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TableSettingsComponent } from '../table/table-settings/table-settings.component';
import { ControlOn } from 'src/app/shared/form/app-form-control';
import { GridControl } from './grid-control';
import { GridRow } from './grid-row';
import { first } from 'rxjs/operators';
import { IsNarrowService } from '../is-narrow.service';
import { Subscription } from 'rxjs';
import { MatExpansionModule } from '@angular/material/expansion';
import { CtlHolderComponent } from '../form/ctl-holder/ctl-holder.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { NgFor, NgSwitch, NgSwitchCase, NgClass, NgSwitchDefault, NgTemplateOutlet } from '@angular/common';
import { MatTableModule } from '@angular/material/table';

@Component({
    selector: 'app-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss'],
    animations: [STD_ANIMATION],
    standalone: true,
    imports: [
        MatTableModule,
        MatSortModule,
        NgFor,
        NgSwitch,
        NgSwitchCase,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        NgClass,
        NgSwitchDefault,
        CtlHolderComponent,
        NgTemplateOutlet,
        MatExpansionModule,
    ],
})
export class GridComponent implements AfterViewInit, OnDestroy {

    @Input() control: GridControl;
    @Input() on: ControlOn = 'form';
    @Input() label: string;
    @Input() heads = true;

    //@Input() data: GridRow[];

    @Output() sorter = new EventEmitter();
    @Output() editor = new EventEmitter<AbstractObject>();

    //columns: Field[] = [];

    subscriptions: Subscription[] = [];
    focusItem: AbstractObject | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sortedData: any[];
    selectedRow: GridRow; // Only set in accordion mode for now...
    isVeryNarrow = false;

    isPrint = false;

    protected sorters = {}; // Custom Sort Objects
    gridHeight = 150;

    private currentSort: Sort = { active: '', direction: 'asc' };

    constructor(private matDialog: MatDialog, private isNarrowSvc: IsNarrowService, private myElement: ElementRef) {
        this.subscriptions.push(this.isNarrowSvc.detectVeryNarrow().subscribe(result => this.isVeryNarrow = result));
        this.subscriptions.push(this.isNarrowSvc.resizeObservable$.subscribe(() => { this.setGridHeight() }));
    }

    public static createComponent(vcr: ViewContainerRef, ctl: GridControl, on: ControlOn) {
        const componentRef = vcr.createComponent(GridComponent);
        componentRef.instance.control = ctl;
        componentRef.instance.on = on;
    }

    ngAfterViewInit(): void {
        /**
         * Changing grid height immediately would cause ExpressionChangedAfterItHasBeenCheckedError
         * So do it on the next cycle
         * */
        setTimeout(() => {
            this.setGridHeight();
        })
    }

    setGridHeight() {
        const minGridDisplaySize = 160;

        const gridElement = this.myElement.nativeElement;

        const formElement = gridElement.closest('div[formHolder]');
        /* If in an unselected tab, we have not yet been attached to the DOM, so formElement comes back null
        this sorta gets around, but as gridElement not yet attached, it gets co-ordinates wrong
        and needs an event to resize it... bit jumpy...
        if (formElement === null) {
            const maybes = document.querySelectorAll('div[formHolder]');
            if (maybes.length > 0) {
                //formElement = maybes.item(maybes.length -1);
            }
            gridElement.addEventListener("mouseenter", this.setGridHeight.bind(this) );
        }
        */

        if (formElement?.getAttribute('data-is-print') === 'true') {
            console.log('Printing grid');
            this.isPrint = true;
        } else {
            this.isPrint = false;
        }
        /* || !formElement.style['max-height'] */
        if (formElement === null || this.isPrint) {
            console.log('No DIV[formHolder] element, cannot set grid height properly', { control: this.control, formElement });
            this.gridHeight = null;
        } else {
            //console.log({ formElement, style: formElement.style, mh: formElement.style['max-height'], isPrint: formElement.getAttribute('data-is-print') });
            const spaceAboveForm = formElement.getBoundingClientRect().top;
            const topOfGrid = gridElement.getBoundingClientRect().top;

            const formHeight = parseInt(formElement.style['max-height'], 10); // formElement.clientHeight;
            const formSpaceAboveGrid = topOfGrid - spaceAboveForm;

            let gridHeight = formHeight - formSpaceAboveGrid - 26;
            if (this.control.field.config.objFactory && !this.control.field.readonly) { // Has an add button
                gridHeight -= 40;
            }
            gridHeight = Math.max(gridHeight, minGridDisplaySize);

            if (this.gridHeight !== gridHeight) {
                this.gridHeight = gridHeight;
                /*
                console.log('Set Grid Height for ' + this.control.field.name + ' to '
                    + this.gridHeight,
                    { gridHeight, gridElement, formElement, formHeight, spaceAboveForm, topOfGrid, formSpaceAboveGrid,
                        field: this.control?.field
                });
                */
            }
        }
    }

    ngOnDestroy(): void {
        for (const s of this.subscriptions) {
            s.unsubscribe();
        }
    }

    logit(obj, desc = 'Object') {
        console.log(desc, obj);
        return 'logged';
    }

    addSorter(name: string, sorter: AbstractObject[], key = 'id', value = 'name') {
        this.sorters[name] = {};
        sorter.forEach(o => {
            this.sorters[name][o[key]] = o[value];
        });
    }

    onEdit(object: AbstractObject) {
        this.focusItem = object;
    }

    onUpdate(object, prop, value) {
        console.log('Update ', object, prop, value);
        object[prop] = value;
    }

    public addNew() {
        this.control.objFactory().pipe(first()).subscribe(item => {
            if (item) {
                console.log(item);
                const newRow = this.control.addRow(item, true, false);
                if (this.control.controls.length === 1) {
                    //console.log('First Child Item', this.primaryId, this.primaryCompareId, newRow.controls[this.primaryCompareId] );
                    if (this.control.primaryId && this.control.primaryCompareId) {
                        //console.log('needs prim');
                        if (newRow.controls[this.control.primaryCompareId]) {
                            //console.log('setting  primary');
                            newRow.setPrimary();
                        }
                    }
                }
                this.selectedRow = newRow;
                console.log('Added ', newRow);
                if (this.control.field.sendServer) {
                    this.control.markAsDirty();
                }
            }
        });
    }

    tableSettings(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = { object: this.constructor.name, columns: this.control.columns };
        this.matDialog.open(TableSettingsComponent, dialogConfig);
    }
}
