<!-- app-form-page [config]="config"></app-form-page -->
 <div class="title-div">
    <h1 class="mat-h1" style="font-weight:bold">OurOMC - The only software in the world designed for Owners' Management Companies</h1>
    <p style="max-width:600px; margin-left: auto; margin-right:auto">
        And it's just as useful for the property management agents who look after them. <br/>
    </p>
 </div>

<div class="mainDiv" id="pricing_page">
    <div class="priceDiv">
        <mat-card appearance="raised">
            <mat-card-header>
                <mat-card-title>Owner Management Companies</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div style="display:flex; padding-top: 20px; height: 110px">
                    <div style="flex:1">
                        <p class="inputLabel">Number of units</p>
                        <input type="number" class="input" [(ngModel)]="unitCount" min="0" max="2000"
                            (input)="checkUnits($event)"
                            matTooltip="Enter the number of units in your development, 1 to 2000"/>
                        <p matTooltip="OMCs who self manage will need to cover the cost of the agent license "
                        class="inputLabel" style="margin-top:10px">
                            <input type="checkbox" placeholder= "#Units" [(ngModel)]="agentLinked"/>
                            Do you have a PSRA Certified agent?
                        </p>
                    </div>
                    <div style="width:190px" matTooltip="Enter number of units and specify if you have an agent to calculate your price">
                        <p style="text-align: center;">
                        <span style="font-size: 72px">{{calcOmcPrice()}}<sup><span style="font-size:36px">€</span></sup></span>
                        </p>
                        @if (unitCount) {
                            <p class="price-tag">
                                EUR/Month
                            </p>
                            @if (tooManyUnits) {
                                <p class="too-many">Call for over 2,000 units</p>
                            }
                        } @else {
                            <p class="price-tag">
                                from per unit/month
                            </p>
                        }
                    </div>
                </div>
                <div>
                    <hr/>
                    <div class="feature">
                        <mat-icon color="primary">check</mat-icon>
                        <div>Owner Login & Functions</div>
                    </div>
                    <div class="feature">
                        <mat-icon color="primary">check</mat-icon>
                        <div>Resident Login & Functions</div>
                    </div>
                    <div class="feature">
                        <mat-icon color="primary">check</mat-icon>
                        <div>Director Login & Functions</div>
                    </div>
                    <div class="feature">
                        <mat-icon color="primary">check</mat-icon>
                        <div>Agent Login & Functions</div>
                    </div>
                    <div class="feature">
                        <mat-icon color="primary">check</mat-icon>
                        <div>Accountant Login & Functions</div>
                    </div>
                    <div class="feature">
                        <mat-icon color="primary">check</mat-icon>
                        <div>Contractor Login & Functions</div>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions align="end" style="margin-top: 20px;"
            matTooltip="Ensure you have entered the correct number of units, and confirmed if you have an agent, to calculate your price correctly">
                <button mat-raised-button color="primary" [disabled]="unitCount === 0"
                    [routerLink]="['/contact-us', {units: unitCount, isAgent: false, hasAgent: agentLinked, trial: true}]">
                    Request Free Trial
                </button>
            </mat-card-actions>
        </mat-card>
    </div>

    <div class="priceDiv">
        <mat-card appearance="raised">
            <mat-card-header>
                <mat-card-title>Property Management Agents</mat-card-title>
                <mat-card-subtitle>For internal use only</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div style="display:flex; padding-top: 30px; height: 100px">
                    <div style="width: 110px; margin-top: -20px;">
                            <div style="width:120px; margin-right: 3px; margin-top:8px">
                                <p class="inputLabel">Office Users</p>
                                <div style="align-items: center; vertical-align: middle;">
                                    <input type="number" class="input" #agentUserTip="matTooltip"
                                        [(ngModel)]="agentUsers" min="1" max="20"
                                        (input)="checkAgentUsers($event)"
                                        [matTooltip]="agentUserTipText" />
                                    <mat-icon color="primary" class="info" (click)="agentUserTip.show()"
                                        [matTooltip]="agentUserTipText">info</mat-icon>
                                </div>
                            </div>

                            <div style="width:120px; margin-right: 3px">
                                <p class="inputLabel">Total Units</p>
                                <div style="align-items: center; vertical-align: middle;">
                                <input type="number" class="input"
                                    #agentUnitTip="matTooltip"
                                    (blur)="changeAgentUnits()"
                                    (input)="checkAgentUnits($event)"
                                    [(ngModel)]="agentUnitCount" min="0" max="9999" step="50"
                                    [matTooltip]="agentUnitTipText" />
                                <mat-icon color="primary" class="info"  (click)="agentUnitTip.show()"
                                    [matTooltip]="agentUnitTipText">info</mat-icon>
                                </div>
                            </div>

                            <div style="display:flex">

                            </div>
                        <p class="inputLabel" style="margin-top: 8px">
                            Own license units
                        </p>
                        <div style="align-items: center; vertical-align: middle;">
                            <input type="number" class="input" #unitsOut="matTooltip"
                                [(ngModel)]="agentUnitsOut"
                                (input)="checkAgentOwnLicense($event)"
                                min="0" [max]="agentUnitCount" [step]="agentUnitCount / 5"
                                [matTooltip]="agentUnitsOutTip" />
                            <mat-icon class="info" color="primary" (click)="unitsOut.show()" [matTooltip]="agentUnitsOutTip" >info</mat-icon>
                        </div>
                    </div>
                    @if(calcAgentPrice() > 0) {
                        <div style="flex:1">
                            <p style="text-align: center;">
                            <span style="font-size: 72px">{{calcAgentPrice()}}<sup><span style="font-size:36px">€</span></sup></span>
                            </p>
                            @if(agentUsers === 0) {
                                <p class="price-tag">
                                    From per user/month
                                </p>
                            } @else {
                                @if (tooManyAgentUnits) {
                                    <p class="too-many">Call for over 9,999 units or over 20 users</p>
                                } @else {
                                <p class="price-tag">
                                    EUR/Month
                                </p>
                                }
                            }
                        </div>
                    } @else {
                        <div style="flex:1">
                            <p style="text-align: center;"><span style="font-size: 42px">Free</span></p>
                            <p class="free-text">
                                Agents use the system free when they have sufficient own license units
                            </p>
                        </div>
                    }
                </div>
                <div>
                    <hr/>
                    <div class="feature">
                        <mat-icon color="primary">check</mat-icon>
                        <div>Owner Login (unit account only)</div>
                    </div>
                    <div class="feature">
                        <mat-icon color="warn">close</mat-icon>
                        <div>Communications, Voting, Document Sharing</div>
                    </div>
                    <div class="feature">
                        <mat-icon color="warn">close</mat-icon>
                        <div>Director Login & Functions</div>
                    </div>
                    <div class="feature">
                        <mat-icon color="primary">check</mat-icon>
                        <div>Agent Login & Functions</div>
                    </div>
                    <div class="feature">
                        <mat-icon color="primary">check</mat-icon>
                        <div>Accountant Login & Functions</div>
                    </div>
                    <div class="feature">
                        <mat-icon color="primary">check</mat-icon>
                        <div>Contractor Login & Functions</div>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions align="end" style="margin-top: 20px;"
            matTooltip="Ensure you have entered the correct number of units & users to calculate your price correctly">
                <button mat-raised-button color="primary"  [disabled]="agentUsers === 0"
                    [routerLink]="['/contact-us', {users: agentUsers, units: agentUnitCount, isAgent: true, trial: true}]">Request Free Trial</button>
            </mat-card-actions>
        </mat-card>
    </div>
    <div class="priceDiv">
        <mat-card appearance="raised">
            <mat-card-header>
                <mat-card-title>Full Service Agencies</mat-card-title>
                <mat-card-subtitle>The complete IT Service</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div style="display:flex; padding-top: 30px; height: 100px">
                    <div style="flex:1">
                    </div>
                    @if (calcFullAgentPrice() > 0) {
                        <div style="flex:1">
                            <p style="text-align: center;">
                            <span style="font-size: 72px">{{calcFullAgentPrice()}}<sup><span style="font-size:36px">€</span></sup></span>
                            </p>
                            @if(agentUsers === 0) {
                                <p class="price-tag">
                                    From per user/month
                                </p>
                            } @else {
                                <p class="price-tag">
                                    EUR/Month
                                </p>
                            }
                        </div>
                    } @else {
                        <div style="flex:1">
                        <div style="width:180px">
                            <p style="text-align: center;"><span style="font-size: 42px">Free</span></p>
                            <p class="free-text">
                                Agents use the system free when they have sufficient own license units
                            </p>
                        </div>
                        </div>
                    }
                    <div style="flex:1">
                    </div>
                </div>
                <div>
                    <hr/>
                    <div class="feature">
                        <mat-icon color="primary">check</mat-icon>
                        <img src="./assets/ouromc-v4.png" height="14px" />
                        <div> All agent Functions</div>
                    </div>
                    <div class="feature">
                        <mat-icon color="primary">check</mat-icon>
                        <img src="./assets/microsoft.png" height="14px" />
                        <div>Outlook Email Services</div>
                    </div>
                    <div class="feature">
                        <mat-icon color="primary">check</mat-icon>
                        <img src="./assets/microsoft.png" height="14px" />
                        <div>Office 365 & OneDrive</div>
                    </div>
                    <div class="feature">
                        <mat-icon color="primary">check</mat-icon>
                        <img src="./assets/wordpress.png" height="32px" />
                        <div>website hosting</div>
                    </div>
                    <div class="feature">
                        <mat-icon color="primary">check</mat-icon>
                        <div>Shared Email Account for each OMC</div>
                    </div>
                    <div class="feature">
                        <mat-icon color="primary">check</mat-icon>
                        <div>Backup & Virus Protection</div>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions align="end" style="margin-top: 20px;">
                <button mat-raised-button color="primary"  [routerLink]="['/contact-us',
                    {users: agentUsers, units: agentUnitCount, isAgent: true, trial: true, full: true}]">
                    Contact Us
                </button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>
