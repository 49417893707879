/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { AbstractObject, uuid } from "src/app/model/abstract-object";
import { ImportDoc, ImportDocNote } from "../ImportDoc";
import { ImportField } from "../ImportField";
import { ImportRow } from "../ImportRow";

export class ParseValidator {
    optionSelected?: (pob: ParseOptionBase, importDoc: ImportDoc) => void;
    validateRow: (field: ImportField) => string | null;
    prepareOption?: (pob: ParseOptionBase, importDoc: ImportDoc) => void;
}

export class ParseOptionReady {
    id: uuid;
    name: string;
    mappedFieldName: string;
    repeatable: boolean;
    actionFn: (row: ImportRow, por: ParseOptionReady) => void;
    unSelectFn: (row: ImportRow) => void;
    customUnSelectFn: (cell: ImportField) => void;
}

type actionFnDef = (fieldName: string) => (row: ImportRow, por: ParseOptionReady) => void;

export class ParseOptionBase {
    repeatable = false;

    private validatorMap : Map<ParseValidator, ImportDocNote> = new Map();

    protected mappedToColumn: string = null;

    setItemValueFn: (pob: ParseOptionBase, row: ImportRow, item: AbstractObject) 
        => void = (pob: ParseOptionBase, row: ImportRow, item: AbstractObject) => this.setItemValue(row, item);

    unSelectFn: (fieldName: string) => (row: ImportRow) => void = (fieldName: string) => (row: ImportRow) => {
        const cell = row.getField(fieldName);
        cell.setHeading(fieldName);
        cell.clearErrors();
        if (this.customUnSelectFn) {
            this.customUnSelectFn(cell);
        }
    }

    actionFn: actionFnDef = (fieldName: string) => (row: ImportRow, por: ParseOptionReady) => {
        let cell = row.getField(fieldName);
        if (!cell) {
            cell = new ImportField(fieldName);
            row.add(cell); // Number of columns in input was inconsistent, add an empty value
        }

        cell.setHeading(por.name);
        this.parseValidator.forEach( vfn => {
            const result = vfn.validateRow(cell);
            if (result) {
                cell.addError(result);
            }
        })
    }    

    constructor(
        public readonly name: string,
        public readonly fieldName: string,
        private parseValidator : ParseValidator[] = [],
        public readonly customUnSelectFn : (cell: ImportField) => void = null) {}

    as( customItemSetter: (pob: ParseOptionBase, row: ImportRow, item: AbstractObject) => void) {
        this.setItemValueFn = customItemSetter;
        return this;
    }

    addValidatorNote(pv: ParseValidator, note: ImportDocNote, importDoc: ImportDoc) {
        this.validatorMap.set(pv, note);
        importDoc.addNoteItem(note);
    }

    getMappedColumn() {
        return this.mappedToColumn;
    }

    hasValidatorNote(pv) {
        return this.validatorMap.has(pv);
    }

    setMappedTo(fieldName: string) {
        this.mappedToColumn = fieldName;
    }

    getMappedTo() {
        return this.mappedToColumn;
    }

    setItemValue(row : ImportRow, item: AbstractObject) {
        if (this.mappedToColumn) {
            item[this.fieldName] = this.getRowValue(row);
        }
    }

    getRowValue(row : ImportRow) {
        return row.getValue(this.mappedToColumn);
    }
        

    removeValidatorNote(pv, importDoc: ImportDoc) {
        if (this.validatorMap.has(pv)) {
            importDoc.deleteNote(this.validatorMap.get(pv));
            this.validatorMap.delete(pv);
        } else {
            console.warn('Validator note not found', {pob: this, pv, importDoc})
        }
    }

    setRepeatable(repeatable: boolean) {
        this.repeatable = repeatable;
    }

    hasValidator(vfn: ParseValidator) {
        if (this.parseValidator.indexOf(vfn) >= 0) {
            return true;
        } else {
            return false;
        }
    }

    addValidator(vfn: ParseValidator) {
        this.parseValidator.push(vfn);
        return this;
    }

    prepareOption(importDoc: ImportDoc) {
        this.parseValidator.forEach( vfn => {
            if (vfn.prepareOption) {
                vfn.prepareOption(this, importDoc)
            }
        })
    }

    optionSelected(importDoc: ImportDoc) {
        this.parseValidator.forEach( vfn => {
            if (vfn.optionSelected) {
                vfn.optionSelected(this, importDoc)
            }
        })
    }
}

