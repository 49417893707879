import { of } from "rxjs";
import { MyInjector } from "src/app/app.module";
import { Txn } from "src/app/model/txn";
import { ActionColor, IFormAction } from "src/app/shared/form/form.component";
import { InvoiceService } from "../invoice.service";

export class MailInvoicePDFAction implements IFormAction {
    name = $localize`Mail PDF to Unit Owner`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'mail';
    approvalNeeded = true;
    disabled = false;
    approvalText: string;
    tipText: string;
    txn: Txn;

    invoiceSvc = MyInjector.instance.get(InvoiceService)

    action(txn: Txn) {
        this.invoiceSvc.getPDF(txn, true);
        return of(this.txn)
    }
    //  setup(serviceRequest: ServiceRequest) {
    setup(txn: Txn) {
        this.show = true;
        this.txn = txn;
        let email = null;
        if (txn['person'] && txn['person']['email']) {
            email = txn['person']['email'].trim();
        }
        if (email && email !== '') {
            this.approvalText = $localize`Mail PDF to ${email}`;
            this.disabled = false;
        } else {
            this.disabled = true;
        }

    }
}
