<h2 class="dialog-banner">Edit Person Details</h2>
<mat-dialog-content class="mat-typography" style="max-width: 400px">
    <app-field-set [fieldSet]="fieldSet" [isPhone]="true" [initComplete]="ready"></app-field-set>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="savePerson()"
    [disabled]="!fieldSet.formGroup?.valid || !fieldSet.formGroup.dirty" color="primary"
        cdkFocusInitial>Save</button>
</mat-dialog-actions>

