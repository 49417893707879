/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { of, ReplaySubject, combineLatest } from 'rxjs';
import { BCode } from 'src/app/model/bcode';
import { BudgetSchedule } from 'src/app/model/budget-schedule';
import { Cycle } from 'src/app/model/cycle';
import { Field } from 'src/app/shared/field/Field';
import { Schedule } from 'src/app/model/schedule';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { ButtonControl, FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { GridField } from 'src/app/shared/grid/grid-field';
import { GridRow } from 'src/app/shared/grid/grid-row';
import { GridControl } from 'src/app/shared/grid/grid-control';
import { CycleService } from '../cycle.service';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { ChartComponent, ChartDataItem } from 'src/app/shared/chart/chart.component';
import { required } from 'src/app/shared/validators';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { ButtonField } from 'src/app/shared/field/ButtonField';
import { CurrentUserService } from '../../user/current-user.service';
import { NavRoute } from 'src/app/shared/NavRoute';
import { formatCurrency } from '@angular/common';
import { uuid } from 'src/app/model/abstract-object';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';

@Component({
    selector: 'app-budgeting-page',
    templateUrl: './budgeting-page.component.html',
    styleUrls: ['./budgeting-page.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class BudgetingPageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('budgets/budgeting', BudgetingPageComponent, 'business');

    schedules: Schedule[];
    budgetBCodes: BCode[];
    currentName: string;
    previousName: string;

    currentStatus: uuid;
    hidePreviousActualSchedules = false;
    hidePreviousBudgetSchedules = false;
    hidePreviousTotals = false;

    configReady = new ReplaySubject<null>(1);

    scheduleGridField: GridField = new GridField({
        field:
            { label: 'Item Details', value: 'schedule', sendServer: true, formRow: 2, visible: Field.noShow },
        rowFactory: () => [
            FieldMaker.id(),
            FieldMaker.rev(),
            FormTextComponent.make('', 'scheduleId'), // {visible:Field.noShow}
            FormTextComponent.make('', 'bCodeId'),
            FormNumberComponent.makeCurrency('Planned Spend', 'plannedSpend'),
        ]
    });

    bCodeGrid = new GridField({
        field:
            { label: 'Budget Codes', value: 'budgetBCodes', visible: Field.formOnly, sendServer: false, formRow: 2 },
        rowFactory: this.bcodeRowFactory.bind(this),
        hasFooter: true
    });

    discountRow: GridRow = null;
    maxDiscount = 0;

    totalField: Field = FormNumberComponent.make('Total Budget', 'budgetTotal',
        { format: 'currency', width: 6, formatParms: '1.0-0' },
        { formColumn: 4, visible: Field.formOnly, disable: true }
    );

    billedField: Field = FormNumberComponent.make('Total Billed', 'billedTotal',
        { format: 'currency', width: 6, formatParms: '1.0-0' },
        { formColumn: 5, visible: Field.formOnly, disable: true, sendServer: false }
    );

    totalIndicator = FormButtonComponent.make('', '', {
        name: 'total_indicator', type: 'icon', sendServer: false, label: '', formColumn: 3, visible: Field.formOnly,
        cellOpts: { heading: '', width: '1%', style: 'height: 74px, width: 32px, padding: 0px 0px 0px 0px;' },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        calculateValue: (o: any, f: ButtonField) => this.calcIndicatorField(f, o.budgetTotal, o.prevTotal)
    });

    fieldSet = new FieldSet({
        fields: [
            FieldMaker.id(),
            FieldMaker.rev(),
            FieldMaker.nameControl({ validators: [required] }),
            FormPicklistComponent.make('Status', 'statusId', '', { items: Cycle.statuses }, { disable: true, formColumn: 2 }),
            // FormDateComponent.make('From', 'from', { formColumn: 3 }),
            // FormDateComponent.make('To', 'to', { formColumn: 4 }),
            this.totalIndicator,
            this.totalField,
            this.billedField,
            FormTextComponent.make('Notes', 'notes', { formColumn: 6 }),
            this.bCodeGrid,
            this.scheduleGridField,
            this.getScheduleDiffGrid(),
            ChartComponent.make('Analysis', this.createChartData.bind(this), this.createChartOptions(),
                { formRow: 2, visible: Field.formOnly }),
        ], formLayout: [{ cells: [{ width: '10%' }, { width: '5%' }, { width: '1%' }, { width: '5%' }, { width: '5%' }, { width: '35%' }] },
        { cells: [{ colspan: 7, pageTab: 'yes' }] }
        ],
    });
    /*
      ], formLayout: [[{ width: '10%' }, { width: '5%' }, { width: '1%' }, { width: '5%' }, { width: '5%' }, { width: '35%' }],
                     [{ colspan: 7, pageTab: 'yes' }]],
    */

    config = new FormConfig({
        navRoute: BudgetingPageComponent.navRoute,
        title: $localize`Budgeting`,
        help: $localize`The budget for the cycle`,
        fieldSet: this.fieldSet,

        service: this.dataSvc,
        mode: 'list',
        allowNew: false,
        objectFactory: () => of(new Cycle()),
        beforeEdit: this.enhanceCycle.bind(this),
        // configReady: this.configReady,
        actions: this.dataSvc.getActions(),
        configReady: this.configReady
    });

    constructor(public dataSvc: CycleService, private currentUserSvc: CurrentUserService) {

        super();
        combineLatest([currentUserSvc.getCurrentUser(), currentUserSvc.getDefaultBCodes()]).subscribe(
            ([user]) => {
                this.currentUser = user;
                this.configReady.next(null);
            }
        )
    }

    // Doesn't really receive chartField...
    createChartData(cycle: Cycle): ChartDataItem[] {
        // Remember the Cycle has been through thius.enhanceCycle() since retrieved from server
        const categories: string[] = [];
        const current: number[] = [];
        const previous = [];

        cycle.budgetBCodes.forEach(bCode => {
            categories.push(bCode.name);
            current.push(bCode.budget);
            previous.push(bCode.prevBudget);
        });

        //console.log('Updated chart with data', chartField.chart, items);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const dataForChart: any = {
            xAxis: { categories, crosshair: true },
            series: [
                { type: 'column', name: this.previousName, data: previous },
                { type: 'column', name: this.currentName, data: current }
            ]
        };

        return dataForChart;

    }

    createChartOptions(): Highcharts.Options {
        return {
            chart: {
                type: 'column'
            },
            credits: { enabled: false },
            title: null,

            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: { enabled: false },
                },
            },
            legend: {
                enabled: true
            },
            series: [
                { type: 'column', name: this.previousName, data: [] },
                { type: 'column', name: this.currentName, data: [] }
            ]
        };
        /*
          series: [{
            name: 'Schedule Share',
            colorByPoint: true,
            //data: this.data,
            type: 'pie',
          }],
        */
    }

    calculateBCodeTotal(bCode: BCode) {
        //return 0;
        const cycle = this.page.form.focusItem as Cycle;
        let total = 0;
        cycle.schedule.forEach(bs => {
            if (bs.bCodeId === bCode.id) {
                total += bs.plannedSpend;
            }
        });
        return total;
    }

    calculateBCodePrevious(bCode: BCode) {
        //return 0;
        const cycle = this.page.form.focusItem as Cycle;
        let total = 0;
        cycle.previous.schedule.forEach(bs => {
            if (bs.bCodeId === bCode.id) {
                total += bs.plannedSpend;
            }
        });
        return total;
    }

    saveBCodeTotal(value, field: Field) {
        const scheduleGrid = this.page.form.formGroup.get('schedule') as GridControl;

        // cycle.schedule.push(new BudgetSchedule({schedule, bCode}));
        const bCodeRow = field.control.parent as GridRow;
        const bCode = bCodeRow.focus as BCode;

        if (bCode.schedule.length > 0) {
            bCode.schedule.forEach(schedule => {
                const pSpend = value * schedule.percent;

                /* Update the corrseponding schedule item on this gridRow for display purposes */
                const colName = `sp-${schedule.scheduleId}`;
                bCodeRow.get(colName).setValue(pSpend, { emitEvent: false });

                /* Update the data to be sent to the server */
                const exists = scheduleGrid.gridRows().find(row => (
                    row.get('bCodeId').value === bCode.id && row.get('scheduleId').value === schedule.scheduleId));

                if (exists) {
                    exists.get('plannedSpend').setValue(pSpend, { emitEvent: false });
                } else {
                    const bs = new BudgetSchedule({ scheduleId: schedule.scheduleId, bCodeId: bCode.id, plannedSpend: pSpend });
                    scheduleGrid.addRow(bs, true, false);
                }

            });

            this.updateBudgetTotal(bCodeRow);

        } else {
            console.error('What? Ya cannot enter value for budget total unless schedules setup to auto allocate');
        }
    }


    updateBudgetTotal(bCodeRow: GridRow) {

        if (this.previousName) { // If there is data to compare to.
            this.updateComparisonIndicator(bCodeRow);
        }

        this.updateDiscounts();
        this.bCodeGrid.control.recalculateTotals();

        const totalBudget = this.bCodeGrid.control.gridRows().reduce((a, b) => a + b.get('budget').value, 0);
        const prevTotal = (this.page.form.focusItem as Cycle).prevTotal;

        this.totalField.control.setValue(totalBudget);
        this.totalIndicator.control.setValue(this.calcIndicatorField(this.totalIndicator, totalBudget, prevTotal));

    }

    private updateComparisonIndicator(bCodeRow: GridRow) {
        const iCtl = (bCodeRow.get('plannedSpend_indicator') as ButtonControl);
        const b = bCodeRow.get('budget').value;
        const p = bCodeRow.get('prevBudget').value;
        //const s = bCodeRow.get('prevSpend').value;

        iCtl.setValue(this.calcIndicatorField(iCtl.field, b, p));

        //bCodeRow.get('choosePrevBudget').setValue(this.radioSelected(b, p));
        //bCodeRow.get('choosePrevSpend').setValue(this.radioSelected(b, s));
    }

    public radioSelected(num1: number, num2: number) {
        if (num1 === null && num2 === null) {
            return '';
        }
        if (this.currentStatus === Cycle.statusIds.EDITTING) {
            if (Math.round(num1) === Math.round(num2)) {
                return 'radio_button_checked';
            } else {
                return 'radio_button_unchecked'
            }
        } else {
            return '';
        }
    }

    private updateDiscounts() {
        const discountId: uuid = this.currentUserSvc.getDefaultBCode('InvoiceDiscounts').bCodeId;

        if (!this.discountRow) { // && this.maxDiscount > 0
            this.discountRow = this.bCodeGrid.control.gridRows().find(gridRow => gridRow.focus.id === discountId);
        }
        //if (this.maxDiscount > 0) { // Even if discount zero do it, in case we need to clear the discount!
        let totalDiscounts = 0;
        for (const schedule of this.schedules) {
            totalDiscounts += this.updateScheduleDiscount(schedule, discountId)
        }
        /* Update the corresponding schedule item on this gridRow for display purposes */
        this.discountRow.get('budget').setValue(totalDiscounts, { emitEvent: false });
        //}
    }

    private updateScheduleDiscount(schedule: Schedule, discountId: uuid): number {
        const colName = `sp-${schedule.id}`;
        const scheduleBudget = this.calculateScheduleBudgetWithoutDiscount(discountId, colName)
        const scheduleDiscount = ((scheduleBudget / (1 - this.maxDiscount)) - scheduleBudget);


        /* Update the corresponding schedule item on this gridRow for display purposes */
        this.discountRow.get(colName).setValue(scheduleDiscount, { emitEvent: false });

        /* Update the data to be sent to the server */
        const exists = this.scheduleGridField.control.gridRows().find(row => (
            row.get('bCodeId').value === discountId && row.get('scheduleId').value === schedule.id)
        );

        if (exists) {
            exists.get('plannedSpend').setValue(scheduleDiscount, { emitEvent: false });
        } else {
            const bs = new BudgetSchedule({ scheduleId: schedule.id, bCodeId: discountId, plannedSpend: scheduleDiscount });
            this.scheduleGridField.control.addRow(bs, true, false);
        }
        return scheduleDiscount;
    }

    private calculateScheduleBudgetWithoutDiscount(discountId: uuid, colName: string): number {
        const scheduleBudget = this.bCodeGrid.control.gridRows().reduce((a, b) => {
            let retValue = a;
            if (b.focus.id !== discountId) {
                retValue += b.get(colName).value;
            }
            return retValue;
        }, 0);
        return scheduleBudget;
    }

    calculateBCodeSchedule(bCode: BCode, field: Field) {
        //return 0;
        const cycle = this.page.form.focusItem as Cycle;
        let total = 0;
        cycle.schedule.forEach(bs => {
            if (bs.bCodeId === bCode.id && bs.scheduleId === field.name.substring(3)) {
                total += bs.plannedSpend;
            }
        });
        return total;
    }

    saveBCodeSchedule(value, field: Field) {

        const scheduleGrid = this.page.form.formGroup.get('schedule') as GridControl;
        const bCodeRow = field.control.parent as GridRow;
        const bCode = bCodeRow.focus as BCode;
        //const bCodeGrid = bCodeRow.getParent();
        const schedId = field.name.substring(3);
        const exists = scheduleGrid.gridRows().find(row => (
            row.get('bCodeId').value === bCode.id && row.get('scheduleId').value === schedId)
        );
        let deltaValue = value;

        if (exists) {
            deltaValue -= exists.get('plannedSpend').value;
            exists.get('plannedSpend').setValue(value, { emitEvent: false });
        } else {
            const bs = new BudgetSchedule({ scheduleId: schedId, bCodeId: bCode.id, plannedSpend: value });
            scheduleGrid.addRow(bs, true, false);
        }

        const totalCtl = bCodeRow.get('budget');
        totalCtl.setValue(totalCtl.value + deltaValue, { emitEvent: false });

        this.updateBudgetTotal(bCodeRow);

    }

    /*
    choosePrevious(bCodeRow: GridRow, budget = true) {
        if ((bCodeRow.focus as BCode).schedule.length > 0) {

            let prevValue = bCodeRow.get('prevBudget').value;

            if (!budget) {
                prevValue = bCodeRow.get('prevSpend').value;
            }

            const ctl = bCodeRow.get('budget');
            if (ctl.value !== prevValue) {
                ctl.setValue(prevValue);
                ctl.markAsDirty();
            }

        } else {
            this.schedules.forEach(schedule => {
                let prevAttrib = 'prev-' + schedule.id;
                if (!budget) {
                    prevAttrib = 'sp-' + schedule.id;
                }
                const ctl = bCodeRow.get('sp-' + schedule.id);
                const newValue = bCodeRow.focus[prevAttrib];

                if (ctl.value !== newValue) {
                    ctl.setValue(newValue);
                    ctl.markAsDirty();
                }

            });
        }
    }
    */

    bcodeRowFactory(bCode: BCode) {
        const width = 5;
        const formatParms = '1.0-0';
        const discountId: uuid = this.currentUserSvc.getDefaultBCode('InvoiceDiscounts').bCodeId;
        if (bCode === null) {
            bCode = new BCode(); // Doesn't matter, factory was only called to get the column headings!
        }
        const fields = [
            FieldMaker.id(),
            FieldMaker.rev(),
            FormButtonComponent.makeLink('name', 'name', '/budgets/bcodes/${id}', {
                cellOpts: { heading: 'Budget Code' },
                footer: { text: 'Totals', style: 'text-align: right' }
            }),
            FormNumberComponent.make(this.currentName + ' Budget', 'budget', { format: 'currency', width, formatParms },
                {
                    disable: bCode.schedule.length < 2 || discountId === bCode.id,
                    //calculateValue: this.calculateBCodeTotal.bind(this),
                    valueChanges: this.saveBCodeTotal.bind(this),
                    cellOpts: { width: '7em' },
                    visible: this.hidePreviousTotals ? Field.noShow : Field.showAll,
                }
            ),
        ];
        if (this.previousName) {

            fields.push(FormButtonComponent.make('', '', {
                name: 'plannedSpend_indicator', type: 'icon', sendServer: false, label: '',
                cellOpts: { heading: '', width: '1%', style: 'padding: 0px 0px 0px 0px; ' },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                calculateValue: (o: any, f: ButtonField) => this.calcIndicatorField(f, o.budget, o.prevBudget),
            }));

            fields.push(FormNumberComponent.make(this.previousName + ' Budget', 'prevBudget',
                { format: 'currency', width, formatParms },
                { disable: true, visible: this.hidePreviousTotals ? Field.noShow : Field.showAll,
                    cellOpts: { width: '7em', style: 'font-weight: 100; font-size: 12px; text-align:right' }
                }

            ));

            fields.push(FormNumberComponent.make(this.previousName + ' Actual', 'prevSpend',
                { format: 'currency', width, formatParms },
                {
                    readonly: true, visible: this.hidePreviousTotals ? Field.noShow : Field.showAll,
                    cellOpts: { width: '7em', style: 'font-weight: 100; font-size: 12px; text-align: right'}
                }
            ));

        }
        this.schedules.forEach(schedule => {
            const bcodeSchedule = bCode.schedule.find(sched => sched.scheduleId === schedule.id);
            let editable = bCode.schedule.length === 0 || (bcodeSchedule && bcodeSchedule.percent === 1);
            if (discountId === bCode.id) {
                editable = false;
            }
            /* Careful - Names need to match to update values */
            // `ps-${schedule.id}-${bCode.id}`  'ps' + Math.round(Math.random() * 100000)
            fields.push(FormNumberComponent.make(schedule.name, 'sp-' + schedule.id,
                { format: 'currency', width, formatParms },
                {
                    disable: !editable,
                    name: 'sp-' + schedule.id,
                    //calculateValue: this.calculateBCodeSchedule.bind(this),
                    valueChanges: this.saveBCodeSchedule.bind(this),
                    cellOpts: { width: '7em' }
                }
            ));

            if (this.previousName) {
                if (!this.hidePreviousBudgetSchedules) {
                    fields.push(FormNumberComponent.make(this.previousName + ' Budget', 'prev-' + schedule.id,
                        { format: 'currency', width, formatParms },
                        { readonly: true, cellOpts: { width: '7em', style: 'font-weight: 100; font-size: 12px; text-align:right' } }
                    ));
                }
                if (!this.hidePreviousActualSchedules) {
                    fields.push(FormNumberComponent.make(this.previousName + ' Actual', 'prevSp-' + schedule.id,
                        { format: 'currency', width, formatParms },
                        { readonly: true, cellOpts: { width: '7em', style: 'font-weight: 100; font-size: 12px; text-align:right' } }
                    ));
                }
            }
        });
        fields.push(FieldMaker.spacer())
        return fields;
    }

    calcIndicatorField(f: ButtonField, budget: number, prevBudget: number) {
        const pct = (((budget - prevBudget) / prevBudget));
        let size = 12 * (1 + Math.abs(pct));
        if (size > 28) {
            size = 28;
        }
        const padLeft = (28 - size);
        if (budget < prevBudget && budget > 0) {
            f.btnOpts.iconStyle = 'font-size: ' + size + 'px; color:green; padding-left:' + padLeft + 'px';
            const amt = formatCurrency(prevBudget - budget, 'EN-ie', '€', undefined, '1.0-0');
            f.toolTip = 'Budget decreased by ' + amt + ' (' + (pct * 100).toFixed(2) + '%)';
            return 'arrow_downward';
        } else if (budget > prevBudget + 0.51) {
            f.btnOpts.iconStyle = 'font-size: ' + size + 'px; color:red;'; // padding-left:' + padLeft + 'px';
            const amt = formatCurrency(budget - prevBudget, 'EN-ie', '€', undefined, '1.0-0');
            f.toolTip = 'Budget increased by ' + amt + ' (' + (pct * 100).toFixed(2) + '%)';
            return 'arrow_upward';
        } else if (budget === 0 && prevBudget > 0) {
            f.btnOpts.iconStyle = 'font-size: 14px; color:orange; padding-left:7px';
            f.toolTip = 'Budget not entered this cycle';
            return 'circle';
        } else {
            return '';
        }
    }

    enhanceCycle(cycle: Cycle) {
        console.log('Enhancing', cycle);

        if (cycle.schedules.length === 1) {
            this.hidePreviousTotals = true;
        }
        if (cycle.schedules?.length > 3) {
            this.hidePreviousActualSchedules = true;
        }
        if (cycle.schedules.length > 5) {
            this.hidePreviousBudgetSchedules = true;
        }
        const retainedProfitId: uuid = this.currentUserSvc.getDefaultBCode('RetainedProfit').bCodeId;
        cycle.budgetBCodes = cycle.budgetBCodes.filter( o => o.id !== retainedProfitId);

        this.budgetBCodes = cycle.budgetBCodes;
        this.schedules = cycle.schedules.sort((a, b) => a.name.localeCompare(b.name));
        this.config.readonly = cycle.statusId !== 0;

        this.currentStatus = cycle.statusId;

        this.maxDiscount = Math.max(cycle.mandateDiscount, cycle.fullPayDiscount);
        this.discountRow = null;

        cycle.budgetTotal = 0;
        cycle.prevTotal = 0;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const map = new Map<uuid, any>();
        cycle.budgetBCodes.forEach((bcode) => {
            map.set(bcode.id, bcode);
            map.get(bcode.id).budget = 0;
            map.get(bcode.id).prevBudget = 0;
            map.get(bcode.id).prevSpend = 0;
            cycle.schedules.forEach(sched => {
                map.get(bcode.id)['sp-' + sched.id] = 0;
                map.get(bcode.id)['prev-' + sched.id] = 0;
                map.get(bcode.id)['prevSp-' + sched.id] = 0;
            });
            this.setPreviousSpend(cycle, bcode);
        });

        cycle.schedule.forEach(bs => {
            cycle.budgetTotal += bs.plannedSpend;
            map.get(bs.bCodeId).budget += bs.plannedSpend;
            map.get(bs.bCodeId)['sp-' + bs.scheduleId] += bs.plannedSpend;
            this.setPrevious(bs, cycle.previous);
        });

        if (cycle.previous && cycle.previous.schedule) {
            cycle.previous.schedule.forEach(bs => {
                cycle.prevTotal += bs.plannedSpend;
                map.get(bs.bCodeId).prevBudget += bs.plannedSpend;
                map.get(bs.bCodeId)['prev-' + bs.scheduleId] += bs.plannedSpend;
            });
        }

        this.currentName = cycle.name;
        if (cycle.previous) {
            this.previousName = cycle.previous.name;
        } else {
            cycle.prevTotal = cycle.budgetTotal; // Display no indicator;
        }

        let billedTotal = 0;
        for (const b of cycle.billings) {
            for (const i of b.items) {
                billedTotal += i.billed;
            }
        }
        cycle.billedTotal = billedTotal;

        const diffs = cycle.schedule.filter(o => o.previousBudget !== o.plannedSpend);
        cycle.sortedDiffs = diffs.sort(this.sortByAbsoluteDiff.bind(this));

        return cycle;
    }

    percentDiff(bs: BudgetSchedule) {
        if (bs.previousBudget > 0) {
            return (bs.plannedSpend - bs.previousBudget) / bs.previousBudget;
        }
        return 1;
    }

    sortByAbsoluteDiff(a: BudgetSchedule, b: BudgetSchedule) {
        return Math.abs(this.percentDiff(b)) - Math.abs(this.percentDiff(a));
    }

    setPreviousSpend(cycle: Cycle, bCode: BCode) {
        const previous = cycle.previous
        if (previous && cycle.previousBalances) {
            const ps = cycle.previousBalances.filter( o => o.bCodeId === bCode.id);
            ps.forEach( psi => {
                if (bCode.typeId === BCode.TYPE.CAPITAL.id) {
                    bCode['prevSpend'] += (psi.credits - psi.debits);
                    bCode['prevSp-' + psi.scheduleId] =  (psi.credits - psi.debits);
                } else {
                    bCode['prevSpend'] += (psi.debits - psi.credits);
                    bCode['prevSp-' + psi.scheduleId] = (psi.debits - psi.credits);
                }
            } );
        }
    }

    setPrevious(item: BudgetSchedule, previous: Cycle) {
        if (previous && previous.schedule) {
            const pi = previous.schedule.find(o => o.bCodeId === item.bCodeId && o.scheduleId === item.scheduleId);
            if (pi) {
                item.previousBudget = pi.plannedSpend;
            } else {
                item.previousBudget = 0;
            }
        }
    }

    getScheduleName(o: BudgetSchedule) {
        const sched = this.schedules.find(sched => sched.id === o.scheduleId);
        if (sched) {
            return sched.name;
        } else {
            return '*** Schedule Name Error ***'
        }
    }
    getBCodeName(o: BudgetSchedule) {
        const bc = this.budgetBCodes.find(bc => bc.id === o.bCodeId);
        if (bc) {
            return bc.name;
        } else {
            return '*** Schedule Name Error ***'
        }
    }

    getScheduleDiffName(row: GridRow) {
        return row.get('bCode').value + ' ' + row.get('schedule').value + ' '
            + (Math.round(row.get('percent').value * 10000) / 100).toFixed(2) + '%';
    }

    getScheduleDiffGrid() {
        return new GridField({
            field:
                { label: 'Changes', value: 'sortedDiffs', sendServer: false, formRow: 2, visible: Field.formOnly },
            rowFactory: () => [
                FieldMaker.id(),
                FieldMaker.rev(),
                FormTextComponent.make('Schedule', 'schedule', { calculateValue: this.getScheduleName.bind(this), readonly: true }),
                FormTextComponent.make('Budget Code', 'bCode', { calculateValue: this.getBCodeName.bind(this), readonly: true }),
                FormNumberComponent.makeCurrency('Planned Spend', 'plannedSpend', { readonly: true, cellOpts: { width: '7em' } }),
                FormNumberComponent.makeCurrency('Previous Budget', 'previousBudget', { readonly: true, cellOpts: { width: '7em' } }),
                FormNumberComponent.makeCurrency('Difference', 'difference', {
                    readonly: true, cellOpts: { width: '7em' },
                    calculateValue: (o: BudgetSchedule) => {
                        return o.plannedSpend - o.previousBudget
                    }
                }),
                FormNumberComponent.makePercent('Percent', 'percent', {
                    readonly: true, cellOpts: { width: '6em' },
                    calculateValue: this.percentDiff
                }),
                FieldMaker.spacer()
            ],
            rowNomenclator: this.getScheduleDiffName.bind(this)
        });
    }
}
