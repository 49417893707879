/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { first } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';

import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService, MsgDef } from 'src/app/shared/message.service';
import { Router } from '@angular/router';
import { Team } from 'src/app/model/team';
import { Omc } from 'src/app/model/Omc';
import { Role } from 'src/app/model/role';
import { NavRoute } from 'src/app/shared/NavRoute';
import { NavItem } from 'src/app/model/sys/nav-item';
import { TermsOfServiceComponent } from 'src/app/shared/terms-of-service/terms-of-service.component';
import { User } from 'src/app/model/user';
import { PersonUnitRole } from 'src/app/model/person-unit-role';
import { Forum, ForumPrivName } from 'src/app/model/forum';
import { DefaultBCode, DefaultBCodeType } from 'src/app/model/bcode';

@Injectable({
    providedIn: 'root'
})

export class CurrentUserService extends AbstractHttpService {

    currentUser: User; // = new User();
    currentTeam: Team;
    currentRole: Role;

    gotUser = false;
    gettingUser = false;

    static readonly returnMe = "returnMeTo";

    protected cache;
    protected typeString = 'CurrentUser';
    // wpOnly protected baseUrl = this.ajaxPath + 'wp/v2/users/me';
    protected baseUrl = this.ajaxPath + 'user';

    //private subjectUser = new BehaviorSubject<User | null>(null);
    private subjectUser = new ReplaySubject<User | null>();
    private subjectDefaultBCodes = new ReplaySubject<DefaultBCode[] | null>;

    constructor(protected http: HttpClient,
        protected messageService: MessageService,
        protected router: Router) {
        super();
    }

    private checkLoaded() {
        if (!this.currentUser || !this.gotUser || this.gettingUser) {
            console.error('Getting forums before user retrieved');
        }
    }

    getForums(withPriv: ForumPrivName = null) {
        this.checkLoaded()
        return this.currentUser.forumRoles.reduce((unique, current) => {
            if (unique.find(o => o.id === current.forumId && o.teamId === current.teamId)) {
                //already have it;
            } else {
                if (current.teamId === this.currentTeam.id && (withPriv === null || current[withPriv])) {
                    //console.log({ forum: current.forumName, withPriv, current});
                    unique.push({ id: current.forumId, name: current.forumName, teamId: current.teamId } as Forum);
                }
            }
            return unique;
        }, [] as Forum[]);
    }

    getActivePositions() {
        this.checkLoaded()
        return this.currentUser.activePositions.reduce((unique, current) => {
            if (unique.find(o => o.roleId === current.roleId && o.teamId === current.teamId)) {
                //already fo it;
            } else {
                unique.push(current);
            }
            return unique;
        }, [] as PersonUnitRole[]);
    }

    private initNavItem(ni: NavItem) {
        if (ni.model && ni.model !== null) {
            const nr = NavRoute.getRoute(ni.url);
            if (nr && this.currentUser && this.currentUser.currentTeam) {
                ni.views = nr.getViews(this.currentUser);
                ni.charts = nr.getCharts();
            }
        }

        if (ni.children) {
            for (const nic of ni.children.filter(nicf => nicf.model)) {
                this.initNavItem(nic);
            }
        }
    }

    pathToModel(modelToPath: { [key: string]: string }) {
        const pathToModel = {};
        for (const [key, value] of Object.entries(modelToPath)) {
            pathToModel[value] = key;
        }
        return pathToModel;
    }

    httpGetCurrentUser(goto: string = null) {
        this.gettingUser = true;
        this.gotUser = false;

        this.http.get<User>(AbstractHttpService.ajaxPath + 'currentUser', this.httpOptions).pipe(first()).subscribe(currentUser => {

            TermsOfServiceComponent.checkTermsOfService(currentUser);

            this.currentUser = currentUser;
            this.currentTeam = currentUser.currentTeam;

            if (!this.currentUser.navItems) {
                this.currentUser.navItems = [];
            }
            for (const ni of this.currentUser.navItems) {
                this.initNavItem(ni);
            }
            this.currentUser.pathToModel = this.pathToModel(currentUser.modelToPath);

            this.gotUser = true;
            this.gettingUser = false;

            this.subjectUser.next(this.currentUser);

            if (goto) {
                this.router.navigate([goto]);
            } else {
                // goto lost by oAuth... Let's try get there anyway...
                const returnMePath = window.localStorage.getItem(CurrentUserService.returnMe);
                if (returnMePath) {
                    const retO = JSON.parse(returnMePath);
                    if (retO['time'] && retO['time'] > new Date().getTime() - (60 * 15 * 1000)) {
                        window.localStorage.removeItem(CurrentUserService.returnMe);
                        this.router.navigate([retO['path']]);
                    }
                }
            }
        }, error => {
            this.gettingUser = false;
            this.subjectUser.next(null);
            if (error.status === 503) {
                window.location.href = "https://clgsystems.ie/maintenance-mode?site=" + window.location.hostname + "&url=" + window.location.href;
            } else if (error.status !== 401) {
                console.error('Error occured retrieving current user details', error);
            }

        });
    }

    logoutCurrentUser() {
        this.http.post('/api/logout', {}).subscribe(() => {
            this.httpGetCurrentUser();
            // TODO this.gotUser = false;
            this.router.navigate(['/']);
            window.location.reload();
        }, error => console.log('Logout Error', error));
    }

    switchTeamRole(pur: PersonUnitRole) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.http.put(AbstractHttpService.ajaxPath + 'user/teams', { positionId: pur.id }).subscribe(
            () => {
                this.router.navigate(['/']).then(() => window.location.reload());
            },
            error => {
                console.error('Failed to switch teams', error);
                const msg = $localize`Unable to switch to team ${pur?.team?.name} (${pur?.role?.name})`;
                this.messageService.show(new MsgDef(msg, 'fail'));
            }
        );
    }

    switchTeamOmc(omc: Omc) {
        this.http.put(AbstractHttpService.ajaxPath + 'user/teams', { teamId: omc.omcTeamId }).subscribe(
            () => {
                this.router.navigate(['/']).then(() => window.location.reload());
            },
            error => {
                console.error('Failed to switch teams', error);
                const msg = $localize`Unable to switch to team ${omc?.name}`;
                this.messageService.show(new MsgDef(msg, 'fail'));
            }
        );
    }

    getDefaultBCodes() {
        if (this.currentTeam && !this.currentTeam.defaultBCodes) {
            this.http.get(AbstractHttpService.ajaxPath + 'currentUser/defaultBCodes').subscribe(
                (dbc: DefaultBCode[]) => {
                    this.currentTeam.defaultBCodes = dbc;
                    this.subjectDefaultBCodes.next(dbc);
                }
            )
        }
        return this.subjectDefaultBCodes.asObservable();
    }

    getDefaultBCode(type: DefaultBCodeType) {
        if (!this.currentTeam.defaultBCodes) {
            console.error('No default BCodes, Are youu sure getDefaultBCodes was exceuted to get them?');
        }
        return this.currentTeam.defaultBCodes.find(o => o.type === type);
    }

    public isUber() {
        if (this.currentUser?.email === 'greg@coburn.ie') {
            return true;
        }
        return false;
    }

    getCurrentUser(): Observable<User | null> {
        return this.subjectUser.asObservable();
    }

    gettingCurrentUserNow() {
        this.gettingUser = true;
    }

    static returnMeTo(to: string) {
        window.localStorage.setItem(CurrentUserService.returnMe, JSON.stringify({ path: to, time: new Date().getTime() }));
    }

}
