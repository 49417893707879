/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Area } from 'src/app/model/area';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { AbstractObject, uuid } from 'src/app/model/abstract-object';

export class RequestAnalysis extends AbstractObject { // doesn't really
    period: string;
    opened = 0;
    closed = 0;
    daysToClose = 0; // arrives as string
    stillOpen = 0; // arrives as string
    previous: RequestAnalysis; // calced
    omcId: uuid;
    periodDesc: string; // calc
    workTime: number;
}
@Injectable({
    providedIn: 'root'
})
export class RequestAnalysisService extends AbstractHttpService {

    protected baseUrl = this.ajaxPath + 'crm/requestAnalysis';
    protected cache: Area[];
    protected typeString = 'RequestAnalysis';

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super();
    }

    /*
    enhanceList(data: RequestAnalysis[]) {
        //const prevMap: Map<string, RequestAnalysis> = new Map();
        let previous: RequestAnalysis = null;
        data.forEach( o => {
            if (previous) {
                previous.previous = o;
            }
            previous = o;
            o.daysToClose = +o.daysToClose;
            o.stillOpen = +o.stillOpen;
        })

        return data;
    }
    */
}
