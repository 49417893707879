/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { forkJoin } from "rxjs";
import { first, map } from "rxjs/operators";
import { BankAccount } from "src/app/model/bankAccount";
import { Billing, BillingItem } from "src/app/model/billing";
import { Field } from "src/app/shared/field/Field";
import { Period } from "src/app/model/period";
import { Txn } from "src/app/model/txn";
import { Unit } from "src/app/model/unit";
import { AbstractHttpService } from "src/app/shared/abstract-http.service";
import { PeriodService } from "src/app/modules/budget/period.service";
import { BankAccountService } from "src/app/modules/txn/bank-account.service";
import { UnitService } from "src/app/modules/unit/unit.service";
import { CurrentUserService } from "src/app/modules/user/current-user.service";
import { ImportProcessor } from '../import-page/Import-processor-interface';
import { ImportDoc } from "src/app/modules/importer/ImportDoc";
import { ImportRow } from "src/app/modules/importer/ImportRow";
import { uuid } from "src/app/model/abstract-object";

@Injectable({
    providedIn: 'root'
})
export class BM5ProcessorService implements ImportProcessor {
    importDoc: ImportDoc;
    units: Unit[];
    defaultBank: BankAccount;
    creditorsId: uuid;
    periods: Period[];

    importData: object;

    constructor(
        private unitSvc: UnitService,
        private bankAccountSvc: BankAccountService,
        private currentUserSvc: CurrentUserService,
        private periodSvc: PeriodService,
        private http: HttpClient) {
    }

    public setUp() {
        console.log('Setting up BM5 processor');
        this.units = [];
        this.defaultBank = null;
        this.creditorsId = null;
        this.periods = [];

        return forkJoin({
            units: this.unitSvc.get(true).pipe(first()),
            bankAccounts: this.bankAccountSvc.get(true).pipe(first()),
            currentUser: this.currentUserSvc.getCurrentUser().pipe(first()),
            periods: this.periodSvc.get(true).pipe(first()),
            defs: this.currentUserSvc.getDefaultBCodes(),
        }).pipe(map(result => {
            console.log('Got Required Data for BM5 Processor');
            this.periods = result.periods as Period[];
            this.units = result.units as Unit[];
            this.defaultBank = (result.bankAccounts as BankAccount[]).find(ba => ba.defaultAcct);
            if (!this.defaultBank) {
                throw ('Cannot continue without bank account');
            }
            this.creditorsId = this.currentUserSvc.getDefaultBCode('Creditors').bCodeId;
            return true;
        }));
    }

    public getData(importDoc: ImportDoc) {
        this.importDoc = importDoc;
        this.importDoc.setRows(this.sortData());

        let typeId = null;
        const receipts: Txn[] = [];
        const invoices: Txn[] = [];
        const cnotes: Txn[] = [];
        const billings: Billing[] = [];

        let receiptCrTotal = 0;
        let receiptDbTotal = 0;
        let invoiceDbTotal = 0;
        let invoiceCrTotal = 0;
        let cnCrTotal = 0;
        let cnDbTotal = 0;
        let billing: Billing;

        let lastInvoice: Txn = new Txn();
        let lastCN: Txn = new Txn();

        for (const i of this.importDoc.getRows()) {
            typeId = i.getNrValue('txnTypeId');

            if (typeId === Txn.TYPE.BANK_IN.id) {
                receipts.push(this.createReceipt(i));
            } else if (typeId === Txn.TYPE.INVOICE.id) {
                if (lastInvoice.unitId !== i.getValue('unitId') || lastInvoice.txnDate !== i.getValue('txnDate')) {
                    lastInvoice = this.createInvoice(i);
                    invoices.push(lastInvoice);
                    billing = this.getBilling(billings, i.getStringValue('billing'), lastInvoice.txnCycleId);
                    billing.bills.push(lastInvoice);
                }
                const newLine = this.addToInvoice(lastInvoice, i);
                billing = this.getBilling(billings, i.getStringValue('billing'), lastInvoice.txnCycleId);
                this.addToBilling(billing, newLine);
            } else if (typeId === Txn.TYPE.CREDIT_NOTE.id) {
                if (lastCN.unitId !== i.getValue('unitId') || lastCN.txnDate !== i.getValue('txnDate')) {
                    lastCN = this.createCN(i);
                    cnotes.push(lastCN);
                    billing = this.getBilling(billings, i.getStringValue('billing'), lastCN.txnCycleId);
                    billing.bills.push(lastCN);
                }
                const newLine = this.addToCN(lastCN, i);
                billing = this.getBilling(billings, i.getStringValue('billing'), lastCN.txnCycleId);
                this.addToBilling(billing, newLine);
            }
        }

        for (const t of invoices) {
            invoiceDbTotal += t.debit;
            for (const t2 of t.details) {
                invoiceCrTotal += t2.credit;
            }
        }

        for (const t of cnotes) {
            cnCrTotal += t.credit;
            for (const t2 of t.details) {
                cnDbTotal += t2.debit;
            }
        }

        for (const t of receipts) {
            receiptDbTotal += t.debit;
            for (const t2 of t.details) {
                receiptCrTotal += t2.credit;
            }
        }

        const realBillings: Billing[] = billings.filter(b => b.bills.length > 1);
        const oneOffs: Txn[] = [];
        const oneOffBills: Billing[] = billings.filter(b => b.bills.length === 1);
        for (const oob of oneOffBills) {
            oneOffs.push(oob.bills[0]);
        }

        this.importData = { realBillings, oneOffs, invoices, invoiceDbTotal, invoiceCrTotal, cnotes, cnCrTotal, cnDbTotal, receipts, receiptDbTotal, receiptCrTotal };
        console.log(this.importData);

    }

    public postData() {
        const httpOptions = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
        };
        this.http.post(AbstractHttpService.ajaxPath + 'bm/part5', this.importData, httpOptions).subscribe(
            success => { console.log(success); alert('Success') },
            failure => { console.log(failure); alert('Failed, check console') }
        )
    }

    private addToBilling(billing: Billing, line: Txn) {
        let bi = billing.items.find(bi => bi.scheduleId === line.scheduleId);
        if (bi === null || bi === undefined) {
            bi = new BillingItem();
            bi.scheduleId = line.scheduleId;
            bi.total = 0;
            billing.items.push(bi);
        }
        if (line.typeId === Txn.TYPE.INVOICE_ITEM.id) {
            bi.total += line.credit;
        } else {
            bi.total -= line.debit;
        }
    }

    private getBilling(billings: Billing[], name: string, cycleId: uuid) {
        const b = billings.find(b => b.name === name && b.cycleId === cycleId);
        if (b === null || b === undefined) {
            const newB = new Billing();
            newB.name = name;
            newB.cycleId = cycleId;
            billings.push(newB);
            return newB;
        } else {
            return b;
        }
    }

    private getPeriod(txnDate): Period {
        const p = this.periods.find(p => p.from <= txnDate && p.to >= txnDate);
        if (p === null || p === undefined) {
            throw ('Cannot find period for date ' + txnDate);
        } else if (p.statusId !== Period.STATUS.OPEN.id) {
            throw ('Period ' + p.name + ' for transaction date ' + txnDate + ' not open for transactions');
        }

        return p;
    }

    private createInvoice(i: ImportRow): Txn {
        const txnDate = i.getStringValue('txnDate');
        const period = this.getPeriod(txnDate);
        return {
            id: null,
            ledgerId: Txn.LEDGER.AR.id,
            typeId: Txn.TYPE.INVOICE.id,
            bCodeId: this.creditorsId,
            txnDate,
            txnPeriodId: period.id,
            txnCycleId: period.cycleId,
            unitId: i.getNrValue('unitId'),
            personId: i.getNrValue('ownerId'),
            debit: 0,
            outstanding: 0,
            notes: i.getStringValue('notes'),
            details: []
        }
    }

    private addToInvoice(invoice: Txn, line: ImportRow): Txn {
        const amt = line.getNrValue('debit') - line.getNrValue('credit');
        const schedule = line.getStringValue('schedule');
        invoice.debit += amt;
        invoice.outstanding += amt;

        const newTxn = {
            id: null,
            ledgerId: Txn.LEDGER.AR.id,
            typeId: Txn.TYPE.INVOICE_ITEM.id,
            bCodeId: this.getBCode(schedule),
            scheduleId: this.getSchedule(schedule),
            txnDate: invoice.txnDate,
            txnPeriodId: invoice.txnPeriodId,
            txnCycleId: invoice.txnCycleId,
            credit: amt,
            notes: line.getValue('schedule') + ' : ' + line.getValue('billing')
        }
        invoice.details.push(newTxn);

        return newTxn;
    }

    private createCN(i: ImportRow): Txn {
        const txnDate = i.getStringValue('txnDate');
        const period = this.getPeriod(txnDate);
        return {
            id: null,
            ledgerId: Txn.LEDGER.AR.id,
            typeId: Txn.TYPE.CREDIT_NOTE.id,
            bCodeId: this.creditorsId,
            txnDate,
            txnPeriodId: period.id,
            txnCycleId: period.cycleId,
            unitId: i.getNrValue('unitId'),
            personId: i.getNrValue('ownerId'),
            credit: 0,
            outstanding: 0,
            notes: i.getStringValue('notes'),
            details: []
        }
    }

    private addToCN(cNote: Txn, line: ImportRow) {
        const amt = line.getNrValue('credit') - line.getNrValue('debit');
        const schedule = line.getStringValue('schedule');
        cNote.credit += amt;
        cNote.outstanding -= amt;

        const newTxn = {
            id: null,
            ledgerId: Txn.LEDGER.AR.id,
            typeId: Txn.TYPE.CREDIT_NOTE_ITEM.id,
            bCodeId: this.getBCode(schedule),
            scheduleId: this.getSchedule(schedule),
            txnDate: cNote.txnDate,
            txnPeriodId: cNote.txnPeriodId,
            txnCycleId: cNote.txnCycleId,
            notes: line.getValue('schedule') + ' : ' + line.getValue('billing'),
            debit: amt,
        }
        cNote.details.push(newTxn);
        return newTxn;
    }
    private getBCode(schedule: string) {
        return this.getInput(schedule, 'Budget Code');
    }

    private getSchedule(schedule: string) {
        return this.getInput(schedule, 'Schedule');
    }

    private getInput(schedule: string, val: string) {
        const input = this.importDoc.getInput(schedule);
        for (const f of input.getFields()) {
            if (f.name === val) {
                return f.control.value;
            }
        }
        if (!Field.isEmpty(schedule)) {
            console.log('Did not find schedule ' + schedule, input);
        }
        return null;
    }

    private createReceipt(i: ImportRow) {

        const txnDate = i.getStringValue('txnDate');
        const period = this.getPeriod(txnDate);
        const amt = i.getNrValue('credit') - i.getNrValue('debit');
        const notes = i.getStringValue('notes');

        return {
            id: null,
            ledgerId: Txn.LEDGER.AR.id,
            typeId: Txn.TYPE.BANK_IN.id,
            bankAccountId: this.defaultBank.id,
            bCodeId: this.defaultBank.bCodeId,
            txnDate,
            txnPeriodId: period.id,
            txnCycleId: period.cycleId,
            debit: amt,
            notes,
            details: [{
                id: null,
                ledgerId: Txn.LEDGER.AR.id,
                typeId: Txn.TYPE.BANK_IN_ON_ACCT.id,
                bCodeId: this.creditorsId,
                txnDate,
                txnPeriodId: period.id,
                txnCycleId: period.cycleId,
                unitId: i.getNrValue('unitId'),
                personId: i.getNrValue('ownerId'),
                credit: amt,
                outstanding: 0 - amt,
                notes: 'Unallocated on account - ' + notes,
            }]
        }
    }


    private sortData() {
        /*
    this.chatItems = this.chatItems.sort (( comt1: ChatItem, comt2: ChatItem) => {
      if (comt1.id > comt2.id) {
        return 1;
      } else {
        return -1;
      }
    });
*/
        const d = this.importDoc.getRows();
        return d.sort((row1: ImportRow, row2: ImportRow) => {
            const key1 = this.getKey(row1);
            const key2 = this.getKey(row2);
            if (key1 < key2) {
                return -1;
            } else if (key1 > key2) {
                return 1;
            }
            return 0;
        })

    }

    private getKey(row: ImportRow) {
        return row.getStringValue('txnDate') + row.getStringValue('unit') + row.getValue('txnTypeId') as string;
    }
}
