/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*
*/
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { User } from 'src/app/model/user';
import { DashboardChart } from 'src/app/shared/chart/dashboard-chart/dashboard-chart';
import { IsNarrowService } from 'src/app/shared/is-narrow.service';
import { NavRoute } from 'src/app/shared/NavRoute';
import { CurrentUserService } from '../../user/current-user.service';
import { AboChart } from './AboChart';
import { AbuChart } from './AbuChart';
import { MoaAllChart } from './MoaAllChart';
import { MoaChart } from './MoaChart';
import { MoaOmcChart } from './MoaOmcChart';
import { RboChart } from './RboChart';
import { RbuChart } from './RbuChart';
import { SraAllChart } from './SraAllChart';
import { SraChart } from './SraChart';
import { SraOmcChart } from './SraOmcChart';
import { RboMyOmcChart } from './RboMyOmcChart';
import { RboMyRequestsChart } from './RboMyRequestsChart';
import { AboMyOmcChart } from './AboMyOmcChart';
import { AboMyRequestsChart } from './AboMyRequestsChart';
import { RbuMyOmcChart } from './RbuMyOmcChart';
import { AbuMyOmcChart } from './AbuMyOmcChart';
import { UnreadChart } from './UnreadChart';
import { UnreadActionsChart } from './UnreadActionsChart';
import { OverdueRequestsChart } from './OverdueRequestsChart';
import { OverdueActionsChart } from './OverdueActionsChart';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { DashboardChartComponent } from '../../../shared/chart/dashboard-chart/dashboard-chart.component';

export type AvailableDashboardChart = {
    code: string,
    name: string,
    chart: DashboardChart
    chartParms?: { [key: string]: unknown }
}
export type ChartChosen = {
    code: string,
    chartParms?: { [key: string]: unknown }
    widthWeight: number,
}

@Component({
    selector: 'app-agent-dashboard-page',
    templateUrl: './agent-dashboard-page.component.html',
    styleUrls: ['./agent-dashboard-page.component.scss'],
    standalone: true,
    imports: [DashboardChartComponent, MatButtonModule, MatMenuModule, MatIconModule]
})
export class AgentDashboardPageComponent implements OnInit, OnDestroy {

    currentUser: User;

    availCharts: AvailableDashboardChart[] = [];
    chartsToShow: ChartChosen[][] = [];

    chartHeight = 180;
    chartWidth = 180;

    autoRefreshIntervalId: number = null;
    refreshNumber = 0;

    subscriptions: Subscription[] = [];

    static readonly navRoute = new NavRoute('agent/dashboard', AgentDashboardPageComponent, 'grid_view')

    constructor(currentUserSvc: CurrentUserService, private router: Router, private isNarrowSvc: IsNarrowService,
        titleSvc: Title, private http: HttpClient, private myElement: ElementRef) {

        titleSvc.setTitle($localize`${AppComponent.appName} - Agent Dashboard`)

        this.subscriptions.push(currentUserSvc.getCurrentUser().subscribe(user => {
            this.currentUser = user;
            this.loadCharts();
            this.subscriptions.push(this.isNarrowSvc.resizeObservable$.subscribe(() => this.reDrawCharts()));
        }));
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    toggleAutoRefresh() {
        if (this.autoRefreshIntervalId) {
            this.stopRefresh()
        } else {
            this.autoRefreshIntervalId = window.setInterval(this.autoRefresh.bind(this), 1000 * 60 * 5);
        }
    }

    autoRefresh() {
        this.refreshNumber += 1;
        const charts = this.showingCharts();
        const iTo = this.refreshNumber % charts.length
        charts[iTo].chart.getData();
    }

    showingCharts() {
        const showingCharts: AvailableDashboardChart[] = [];
        this.chartsToShow.forEach(row => row.forEach(cc => showingCharts.push(this.getChart(cc.code))));
        return showingCharts;
    }



    stopRefresh() {
        if (this.autoRefreshIntervalId) {
            window.clearInterval(this.autoRefreshIntervalId);
        }
        this.autoRefreshIntervalId = null;
        this.refreshNumber = 0;
    }

    available(code: string, name: string, chart: DashboardChart) {
        this.availCharts.push({ code, name, chart });
    }

    loadCharts() {
        this.available('moa', 'My Open Actions', new MoaChart(this.router, this.http, this.currentUser, this.chartWidth, this.chartHeight));
        this.available('moaOmc', 'My OMC Open Actions', new MoaOmcChart(this.router, this.http, this.currentUser, this.chartWidth, this.chartHeight));
        this.available('moaAll', 'All Open Actions', new MoaAllChart(this.router, this.http, this.currentUser, this.chartWidth, this.chartHeight));

        this.available('unread', 'My Unread Requests', new UnreadChart(this.router, this.http, this.currentUser, this.chartWidth, this.chartHeight));
        this.available('unreadActions', 'My Unread Actions', new UnreadActionsChart(this.router, this.http, this.currentUser, this.chartWidth, this.chartHeight));


        this.available('overdueRequests', 'My Overdue Requests', new OverdueRequestsChart(this.router, this.http, this.currentUser, this.chartWidth, this.chartHeight));
        this.available('overdueActions', 'My Overdue Actions', new OverdueActionsChart(this.router, this.http, this.currentUser, this.chartWidth, this.chartHeight));


        this.available('sra', 'My Service Requests', new SraChart(this.router, this.http, this.currentUser, this.chartWidth, this.chartHeight));
        this.available('sraOmc', 'My OMC Open Service Requests', new SraOmcChart(this.router, this.http, this.currentUser, this.chartWidth, this.chartHeight));
        this.available('sraAll', 'All Open Service Requests', new SraAllChart(this.router, this.http, this.currentUser, this.chartWidth, this.chartHeight));

        this.available('abu', 'Action Creation By User', new AbuChart(this.router, this.http, this.currentUser, this.chartWidth, this.chartHeight));
        this.available('abuMyOmc', 'Action Creation By User For My OMCs', new AbuMyOmcChart(this.router, this.http, this.currentUser, this.chartWidth, this.chartHeight));

        this.available('abo', 'Top OMCs By Action', new AboChart(this.router, this.http, this.currentUser, this.chartWidth, this.chartHeight));
        this.available('aboMyOmc', 'My Top OMCs By Action', new AboMyOmcChart(this.router, this.http, this.currentUser, this.chartWidth, this.chartHeight));
        this.available('aboMyRequests', 'My Actions Top OMCs', new AboMyRequestsChart(this.router, this.http, this.currentUser, this.chartWidth, this.chartHeight));

        this.available('rbu', 'Requests Created', new RbuChart(this.router, this.http, this.currentUser, this.chartWidth, this.chartHeight));
        this.available('rbuMyOmcs', 'Requests Created for My OMCs', new RbuMyOmcChart(this.router, this.http, this.currentUser, this.chartWidth, this.chartHeight));

        this.available('rbo', 'Busiest OMCs by Requests', new RboChart(this.router, this.http, this.currentUser, this.chartWidth, this.chartHeight));
        this.available('rboMyOmc', 'My Busiest OMCs by Requests', new RboMyOmcChart(this.router, this.http, this.currentUser, this.chartWidth, this.chartHeight));
        this.available('rboMyRequests', 'My Requests Busiest OMCs', new RboMyRequestsChart(this.router, this.http, this.currentUser, this.chartWidth, this.chartHeight));

        this.getCharts();
    }

    ngOnInit(): void {
        this.reDrawCharts();
    }

    changeWidth(event: { chosen: ChartChosen, widthChange: number }) {
        event.chosen.widthWeight += event.widthChange;
        this.reDrawCharts();
    }

    changeChart() { // event: {to: AvailableDashboardChart}
        this.reDrawCharts();
    }


    removeChart(cc: ChartChosen, chartRow: ChartChosen[]) {
        chartRow.splice(chartRow.indexOf(cc), 1);
        this.reDrawCharts();
    }

    getChart(chartCode) {
        return this.availCharts.find(ca => ca.code === chartCode);
    }

    addChart(chartToShow: AvailableDashboardChart, row: ChartChosen[]) {
        row.push({ code: chartToShow.code, chartParms: chartToShow.chartParms, widthWeight: 1 });
        //window.setTimeout( () => this.reDrawCharts(), 1000);
        this.reDrawCharts();
    }

    widthUnits() {
        let maxUnits = 0;
        this.chartsToShow.map(
            row => {
                let rowUnits = 0;
                row.forEach(cc => rowUnits += cc.widthWeight);
                maxUnits = maxUnits > rowUnits ? maxUnits : rowUnits;
            }
        );
        return maxUnits;
    }

    canDeactivate() {
        this.stopRefresh();
        return true;
    }
    stillAvailCharts() {
        const stillAvail = this.availCharts.slice();
        this.chartsToShow.forEach(
            row => row.forEach(
                cShowing => {
                    stillAvail.splice(stillAvail.findIndex(o => o.code === cShowing.code), 1);
                }
            )
        )
        return stillAvail;
    }


    reDrawCharts() {
        const maxHeight = this.isNarrowSvc.screenHeight - 84 - ((this.chartsToShow.length) * 20); // The banner

        //this.chartWidth = (maxWidth / this.widthUnits());
        this.chartHeight = ((maxHeight / this.chartsToShow.length) - 20);

        //console.log({maxWidth, maxHeight, chartWidth: this.chartWidth, chartHeight: this.chartHeight, myElement: this.myElement, isNarrow: this.isNarrowSvc});

        this.chartsToShow.forEach(row => {
            const roomForMarginAndAddNew = 78 + (row.length * 10);
            // OffsetParent could be null if the native element is hidden... avoiding an error for now.
            const maxWidth = this.isNarrowSvc.screenWidth - this.myElement.nativeElement.offsetParent?.offsetLeft - roomForMarginAndAddNew;
            const totalWidth = row.reduce((accumulator, chartChosen) => accumulator + chartChosen.widthWeight, 0);
            const chartWidth = maxWidth / totalWidth;
            row.map(cc => this.getChart(cc.code).chart.reSize(chartWidth * cc.widthWeight, this.chartHeight));
        })

        this.saveCharts();
        //this.availCharts.forEach( ac => ac.chart.reSize(this.chartWidth, this.chartHeight));
    }

    saveCharts() {
        localStorage.setItem('agentDashBoardCharts', JSON.stringify(this.chartsToShow));
    }

    getCharts() {
        let chartChoice = null
        const choiceString = localStorage.getItem('agentDashBoardCharts');

        if (choiceString) {
            try {
                chartChoice = JSON.parse(choiceString);
                chartChoice.forEach(row => row.forEach(cc => {
                    if (!this.availCharts.find(ac => ac.code === cc.code)) {
                        throw 'Saved option ' + cc.code + ' does not exist';
                    }
                    if (cc.widthWeight <= 0 || cc.widthWeight >= 100) {
                        throw 'Saved option ' + cc.code + ' has rubbish weight';
                    }
                }));

            } catch (e) {
                console.error('Seems to be bad options saved...', e);
                chartChoice = null;
            }
        }

        if (chartChoice === null) {
            this.defaultCharts();
        } else {
            this.chartsToShow = chartChoice;
        }
    }

    defaultCharts() {
        this.chartsToShow = [
            [{ code: 'unreadActions', widthWeight: 1 }, { code: 'moaAll', widthWeight: 2 }, { code: 'abu', widthWeight: 2 }, { code: 'abo', widthWeight: 2 }, { code: 'overdueActions', widthWeight: 1 }],
            [{ code: 'unread', widthWeight: 1 }, { code: 'sraAll', widthWeight: 2 }, { code: 'rbu', widthWeight: 2 }, { code: 'rbo', widthWeight: 2 }, { code: 'overdueRequests', widthWeight: 1 }]
        ];
        this.reDrawCharts();
    }
}
