/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { FormTextComponent } from "../form/form-text/form-text.component";
import { Field, FieldType } from "./Field";

export class TrendField extends Field{

    type?: FieldType = 'trend';
    visible = Field.noShow;
    sendServer = false;

    public static make(label: string, value: string, options: Partial<Field>  = {}) {
        const field: Field = new TrendField ( { label, value }, options );
        field.formControlFactory = FormTextComponent.createComponent;
        return field;
    }
}