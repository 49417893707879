/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { PersonUnitRole } from "src/app/model/person-unit-role";

export class UnitRoleSorter {
    sort(a: PersonUnitRole, b: PersonUnitRole) {
        const aBeforeB = -1;
        const bBeforeA = 1;
        const equal = 0;

        if (a.endDate && !b.endDate) {
            return bBeforeA;
        } else if (!a.endDate && b.endDate) {
            return aBeforeB;
        } else if (a.endDate && b.endDate) {
            if (a.endDate > b.endDate) {
                return bBeforeA;
            } else if (b.endDate > a.endDate) {
                return aBeforeB;
            } else {
                return equal;
            }
        } else if (!a.endDate && !b.endDate) {
            if (a.startDate === a.endDate) {
                return equal;
            } else if (a.startDate && !b.startDate) {
                return bBeforeA;
            } else if (!a.startDate && b.startDate) {
                return aBeforeB;
            } else {
                if (a.startDate > b.startDate) {
                    return bBeforeA;
                } else if (a.startDate < b.startDate) {
                    return aBeforeB;
                }
            }
        }
    }
}
