/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Input } from '@angular/core';
import { uuid } from 'src/app/model/abstract-object';
import { Frequency } from 'src/app/model/frequency';
import { Unit } from 'src/app/model/unit';
import { GmtAgoPipe } from 'src/app/shared/pipes/gmt-ago.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
@Component({
    selector: 'app-unit-mandate',
    templateUrl: './unit-mandate.component.html',
    styleUrls: ['./unit-mandate.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, GmtAgoPipe]
})
export class UnitMandateComponent {

    @Input() unit: Unit

    getFrequency(id: uuid) {
        return Frequency.getFrequency(id)?.name;
    }
}
