<div style="display: flex; flex-wrap: wrap;  margin: 1% auto auto 1%;">
    <div style="margin-left:auto; margin-right:auto; max-width: 400px">
        @if(hasAccessCode) {
            <h1 class="mat-h1">Hello {{lps.profile?.firstName}} welcome to the demo room</h1>
            <div class="full-width">
                @if (!lps.profile?.codeSent) {
                    <app-ctl-holder [control]="emailField.control"></app-ctl-holder>
                }
                <app-ctl-holder [control]="accessCode.control"></app-ctl-holder>
                @if (lps.profile.codeSent) {
                    <p style="font-size:small; color: grey; text-align:center">
                        Code was sent to {{lps.profile.email}}
                        @if (lps.profile.codeSent) {
                            {{lps.profile.codeSent | GmtAgo}}
                        }
                    </p>
                }
                <div style="text-align: center; display:flex">
                    <button mat-raised-button color="primary" (click)="requestAccessAgain()">Request Code Again</button>
                    <button mat-raised-button color="primary" (click)="giveUp()">
                        Back to video list
                    </button>
                </div>
            </div>
        } @else {
            <h1 class="mat-h1">Welcome to the demo room</h1>
            <ng-container *ngTemplateOutlet="requestAccessTemplate"></ng-container>
        }
    </div>
</div>

<ng-template #requestAccessTemplate>
    @if(requestAgain) {
        <p>Check your email, incl. spam folder, for the mail we sent {{lps.profile.codeSent | GmtAgo}}
            or request again by completing the form below.
        </p>
    } @else {
        <p>
            Complete the form below and we will send you an access code by return mail
            so you can watch our videos content.
        </p>
    }

    <app-field-set [fieldSet]="profileFieldSet" [initComplete]="true"></app-field-set>

    <div style="text-align: center; margin-top: -25px; display:flex">
        <button mat-raised-button color="primary" (click)="requestAccess()" [disabled]="!profileFieldSet.getFormGroup().valid">
            Get your access code
        </button>
        <button mat-raised-button color="primary" (click)="giveUp()">
            Back to video list
        </button>
    </div>
</ng-template>
