/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { of } from 'rxjs';
import { Field } from 'src/app/shared/field/Field';
import { Privilege, Role } from 'src/app/model/role';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { FormCheckboxComponent } from 'src/app/shared/form/form-checkbox/form-checkbox.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { GridField } from 'src/app/shared/grid/grid-field';
import { required } from 'src/app/shared/validators';
import { RoleService } from './role.service';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { NavRoute } from 'src/app/shared/NavRoute';
import { FormPageComponent } from '../../shared/form/form-page/form-page.component';
import { FORUM_PRIVS } from 'src/app/model/forum';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { ForumComponent } from 'src/app/modules/social/forum/forum.component';

@Component({
    selector: 'app-role-page',
    templateUrl: './role-page.component.html',
    styleUrls: ['./role-page.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class RolePageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('settings/roles', RolePageComponent, 'groups');

    forumRowFactory() {
        const row = [
            FieldMaker.id({ visible: Field.noShow }),
            FormButtonComponent.makeNavDetailButton('Forum', 'forum.name', 'forumId', ForumComponent.navRoute),
        ]
        FORUM_PRIVS.forEach(p => {
            row.push(FormCheckboxComponent.make(p.name, p.id as string, { readonly: true }),);
        })
        return row;
    }

    forumGrid = new GridField({
        field: { value: 'forumRoles', formRow: 2, sendServer: false, cellOpts: { heading: 'Forums' }, visible: Field.formOnly },
        rowFactory: this.forumRowFactory.bind(this)
    });

    modelGrid = new GridField({
        field: { value: 'modelEdits', formRow: 2, sendServer: false, cellOpts: { heading: 'Permissions' }, visible: Field.formOnly },
        rowFactory: () => [
            FieldMaker.nameControl({ readonly: true }),
            FormCheckboxComponent.make($localize`View Items`, 'getIndex', { cellOpts: { width: '30px' } }),
            FormCheckboxComponent.make($localize`View Items Details`, 'get', { cellOpts: { width: '30px' } }),
            FormCheckboxComponent.make($localize`Create New`, 'post', { cellOpts: { width: '30px' } }),
            FormCheckboxComponent.make($localize`Edit Existing`, 'put', { cellOpts: { width: '30px' } }),
            FormCheckboxComponent.make($localize`Delete`, 'delete', { cellOpts: { width: '30px' } }),
            FieldMaker.notes(),
        ]
    });
    workflowGrid = new GridField({
        field: { value: 'workflowEdits', formRow: 2, sendServer: false, cellOpts: { heading: 'Workflows' }, visible: Field.formOnly },
        rowFactory: () => [
            FormTextComponent.make('Workflow', 'workflowName', { readonly: true }),
            FormTextComponent.make('Action', 'action', { hint: 'description', readonly: true }),
            FormTextComponent.make($localize`Description`, 'description', { cellOpts: { width: '70%' }, readonly: true }),
            FormCheckboxComponent.make($localize`Permitted`, 'allowed', { cellOpts: { width: '20px' } }),
        ]
    });
    /*
    userGrid = new GridField({
        field: { value: 'users', formRow: 2, sendServer: false, cellOpts: { heading: 'Users' }, visible: Field.formOnly },
        rowFactory: () => [
            FormTextComponent.make('Name', 'user.name', { readonly: true, sendServer: false, }),
            FormTextComponent.make('Email', 'user.email', { readonly: true, sendServer: false, }),
        ]
    })
    */
    privilegesField = FormTextComponent.make('privileges', 'privileges', { visible: Field.noShow, readonly: true, sendServer: true });
    config = new FormConfig({
        navRoute: RolePageComponent.navRoute,
        title: $localize`Roles`,
        help: $localize`The roles and privileges that a user may have`,
        fieldSet: new FieldSet({
            fields: [
                FieldMaker.id(),
                FieldMaker.rev(),
                FieldMaker.nameControl({ validators: [required] }),

                FormCheckboxComponent.make('Administration Rights', 'isAdmin', { disable: true, formColumn: 2 }),
                FormCheckboxComponent.make('Use Default Privileges', 'usesDefault', { formColumn: 2 }),
                FormCheckboxComponent.make('Unit Related', 'unitLinked', { formColumn: 2, disable: true }),
                FormPicklistComponent.make('Global Role', 'globalRoleId', 'globalRole', { items: Role.ROLES }, { disable: true }),
                FieldMaker.notes().override({ formColumn: 3 }),
                this.forumGrid,
                this.modelGrid,
                this.workflowGrid,
                // this.userGrid,
                this.privilegesField,
            ],
            formLayout: [
                { cells: [{ width: '40%' }, { width: '20%' }, { width: '40%' }] },
                { cells: [{ pageTab: 'yes', colspan: 3 }] }
            ],
        }),
        service: this.dataSvc,
        mode: 'list',
        objectFactory: () => of(new Role()),
        beforeEdit: this.beforeEdit.bind(this),
        beforeSave: this.beforeSave.bind(this),
    });

    constructor(public dataSvc: RoleService) {
        super();
    }

    beforeEdit(role: Role): Role {
        role.modelEdits = [];
        role.workflowEdits = [];
        role.privileges = role.privileges ? role.privileges : { models: {}, workflows: {} }
        role.privileges.models = role.privileges.models ? role.privileges.models : {};
        role.privileges.workflows = role.privileges.workflows ? role.privileges.workflows : {};

        Object.getOwnPropertyNames(role.allPrivileges.models).forEach((modelName) => {
            const modelValue = role.privileges.models[modelName];

            const modelEditor = {
                id: null,
                name: modelName,
                get: modelValue ? modelValue.get : false,
                getIndex: modelValue ? modelValue.getIndex : false,
                post: modelValue ? modelValue.post : false,
                put: modelValue ? modelValue.put : false,
                delete: modelValue ? modelValue.delete : false,
                notes: modelValue ? modelValue.notes : null,
            }
            role.modelEdits.push(modelEditor);
        });
        //  workflowEdits: {name: string, action: string, description: string, allowed: boolean}[];
        Object.getOwnPropertyNames(role.allPrivileges.workflows).forEach((workflowName) => {
            if (!role.privileges.workflows[workflowName]) {
                role.privileges.workflows[workflowName] = {};
            }
            Object.getOwnPropertyNames(role.allPrivileges.workflows[workflowName]).forEach((stepName) => {
                let allowed = false;
                if (role.privileges.workflows[workflowName][stepName]) {
                    allowed = true;
                }
                role.workflowEdits.push({
                    workflowName: workflowName,
                    action: stepName,
                    name: stepName + '-' + workflowName,
                    description: role.allPrivileges.workflows[workflowName][stepName],
                    allowed: allowed
                })
            });
        });

        return role;
    }
    beforeSave() {
        const modelEdits: Privilege[] = this.modelGrid.control.getFormValue() as Privilege[];
        console.log({ saving: true, modelEdits });
        const privileges = { models: {}, workflows: {} };
        for (const model of modelEdits) {
            privileges.models[model.name] = {
                getIndex: model.getIndex,
                get: model.get,
                post: model.post,
                put: model.put,
                delete: model.delete,
                notes: model.notes,
            }
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const workflowEdits: any[] = this.workflowGrid.control.getFormValue() as any[];

        for (const workflow of workflowEdits) {
            if (!privileges.workflows[workflow.workflowName]) {
                privileges.workflows[workflow.workflowName] = {}
            }
            privileges.workflows[workflow.workflowName][workflow.action] = workflow.allowed;
        }
        this.privilegesField.control.setValue(privileges, { emitEvent: false });
    }
}
