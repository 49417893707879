<!--
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*
* This could grow to be a monster...
* Likely will need to become seperate components for each type of column
-->
@if ((config && config.mode === 'list') || !config) {
<div tableHolder>
  <!-- div *ngFor="let c of config.charts" [id]="c">
    </div -->
  <!-- app-chart-analysis [config]="config"></app-chart-analysis -->
  <div class="ouromc-table-holder mat-elevation-z8" [style.max-height.px]="isPhone || isPrint || isPhoneLand ? null: tableHeight"
    style="overflow:auto;">
    <table mat-table matSort (matSortChange)="onSort($event)" [dataSource]="sortedData" class="ouromc-table"
      @StdAnimation>
      @for (field of columns; track field; let colIndex = $index) {
      <div>
        <ng-container [(matColumnDef)]="field.name">
          <th mat-header-cell *matHeaderCellDef [mat-sort-header]="field.value" [style]="field.cellOpts.thStyle"
            [attr.colspan]="field.cellOpts.thColspan" [width]="field.cellOpts.width">
            {{field.cellOpts.heading}}
          </th>
          <td mat-cell *matCellDef="let focusItem" [matTooltip]="field.toolTip" [style]="field.cellOpts.style"
            [width]="field.cellOpts.width" [attr.data-field]="field.name">
            <app-table-cell [field]="field" [focusItem]="focusItem"></app-table-cell>
          </td>
        </ng-container>
      </div>
      }
      <!-- These columns automatically added to start and end of tables to keep the padding correct! -->
      <ng-container matColumnDef="__firstCol">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let focusItem"></td>
      </ng-container>
      <ng-container matColumnDef="__lastCol">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let focusItem"></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnNames; sticky: true" data-table-headers></tr>
      <tr mat-row *matRowDef="let row; columns: columnNames; let rowNr = index; let even = even"
        (click)="onEdit(row, $event)" [attr.data-table-row]="rowNr" [ngClass]="{'highlight': row === focusItem,
            'boldened' : config.tableHighlighter ? config.tableHighlighter(row) : false,
            'even' : even,
            'odd' : !even
            }">
      </tr>
    </table>
  </div>
  <p style="text-align: right; margin-right: 32px; margin-top: -1em; font-size:  small; line-height: 1;">
    @if (!loadingData) {
    @if (data.length === sortedData.length) {
    <span>
      Showing <span data-table-record-count>{{data.length}}</span> records
    </span>
    }
    @if (data.length !== sortedData.length) {
    <span>
      Displaying first {{sortedData.length}}
      of <span data-table-record-count>{{data.length}}</span> records.
      Click <a href="./showAll" (click)="showAll($event)">here</a> to show all
    </span>
    }
    }
    @if (loadingData) {
    <span>Loading Data, please wait...</span>
    }
  </p>
</div>
}
