/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*
*/

import { HttpParams } from "@angular/common/http";
import { AboChart } from "./AboChart";

export class AboMyRequestsChart extends AboChart {
    title = "My Requests Top OMCs";
    dataParams = new HttpParams().set('week', 0).set('show', 'myrequests');
}
