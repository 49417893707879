/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
* Used to update attachment records in the database...
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { Attachment } from 'src/app/model/attachment';
@Injectable({
  providedIn: 'root'
})
export class AttachmentService extends AbstractHttpService {

  protected typeString = 'Attachments';
  protected baseUrl = this.ajaxPath + 'attachments';
  protected cache: Attachment[] = [];

  constructor(protected http: HttpClient,
    protected messageService: MessageService) {
    super();
  }
}
