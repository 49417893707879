
<div class="progress-cont">
  @if (!upFile?.failed) {
    <div class="progress" [style.width]="upFile?.progress + '%'">
    </div>
  }
  @if (upFile?.failed) {
    <div class="failure">
    </div>
  }
</div>
