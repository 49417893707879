/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Input } from '@angular/core';
import { ConfirmDialogService } from '../../dialogs/confirmDialog';
import { Field } from '../../field/Field';
import { FormConfig } from "../../form/FormConfig";
import { ChartHolder } from '../chart-holder';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-chart-holder',
    templateUrl: './chart-holder.component.html',
    styleUrls: ['./chart-holder.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, MatIconModule, MatDividerModule]
})
export class ChartHolderComponent {

    @Input() chartHolder: ChartHolder;
    @Input() config: FormConfig;

    constructor ( private cds: ConfirmDialogService) {}

    removeChart() {
        this.cds.open($localize `Remove Chart`, $localize `Remove ` + this.chartHolder.getName(), () => {
           this.config.charts.removeChart(this.chartHolder);
        });
    }
    isMetric(f: Field) {
        if (f.type === 'number') {
            if (f.value !== 'id' && f.value !== 'revision' && f.value !== 'refNr' && f.value !== 'teamId') {
                return true;
            }
        }
        return false;

    }

}
