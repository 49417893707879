/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { AbstractObject, uuid } from './abstract-object';
import { ActionTemplate } from './ActionTemplate';
import { OmcAgent } from './OmcAgent';
import { ServiceRequest } from './serviceRequest';
import { Omc } from './Omc';
import { User } from './user';

export class Action extends AbstractObject {
    title: string;
    description: string;
    requestId: uuid;
    request: ServiceRequest;
    unitId: uuid;
    personId: uuid;
    supplierId: uuid;
    templateId: uuid;
    fassetId: uuid;
    template: ActionTemplate;
    createdById: uuid;
    ownedById: uuid;
    ownedBy: User;
    omcId: uuid;
    omc: Omc;
    agentTeamId: uuid;
    agentTeam: OmcAgent;

    offset: number; //  not stored

    plannedDate?: string = '';
    completedAt?: number;
    ownerReadAt?: number;
    completedById?: uuid;
    completedBy: User;
    asEmail: boolean;

    timeSpent?: number = 15;

    constructor(o: Partial<Action> = {}) {
        super();
        // Defaults declared in this class override any values set in the partial by super(), need to override from here..
        this.overrideValues(o);
    }
}
