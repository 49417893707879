/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Period } from 'src/app/model/period';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PeriodService extends AbstractHttpService {

    protected baseUrl = this.ajaxPath + 'budgets/periods';
    protected cache: Period[];
    protected typeString = 'Periods';

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super();
    }

    block(period: Period): Observable<Period> {
        period.statusId = Period.STATUS.CLOSED.id;
        return this.put(period).pipe(first());
    }

    unblock(period: Period): Observable<Period> {
        period.statusId = Period.STATUS.OPEN.id;
        return this.put(period).pipe(first());
    }

    lock(period: Period): Observable<Period> {
        period.statusId = Period.STATUS.LOCKED.id;
        return this.put(period).pipe(first());
    }
}
