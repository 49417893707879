<div style="display: flex">
    <p class="label">{{label}}</p>
    @if (editor.observers.length > 0) {
        <mat-icon color="primary" (click)="editor.emit()">edit</mat-icon>
    }

    @if (deleter) {
        <mat-icon color="warn">delete</mat-icon>
    }
</div>
<p [innerHTML]="textValueToShow"></p>

