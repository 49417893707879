<div class="basePage">
  <h1 class="mat-headline-5">Data Imports for {{currentUser.currentTeam.name}}</h1>
  @if (importDoc.completed) {
    <ng-container [ngTemplateOutlet]="success" ></ng-container>
  } @else if (importDoc.getRows().length > 0 || importDoc.getInputs().length > 0) {
    <ng-container [ngTemplateOutlet]="ImportItems"></ng-container>
  } @else if (importDoc.getRows().length === 0 && importDoc.getInputs().length === 0) {
    <ng-container [ngTemplateOutlet]="chooseType"></ng-container>
  }
</div>

<ng-template #success>

    <div style="display:flex; width: 100%">
        <div style="flex:1">
            <button type="button" color="primary" mat-raised-button (click)="selectAgain()">
                <mat-icon>arrow_back</mat-icon>Select Different File
            </button>
        </div>
        <div style="flex:1; padding-top: 120px">
            <div style="text-align: center">
                <mat-icon color="primary" style="font-size: 288px; height: 288px; width:288px">person_celebrate</mat-icon>
            </div>
            <p style="text-align:center">Good Job - you successfully imported
                {{importDoc.completed}} {{currentOption?.name}} Items</p>
        </div>
        <div style="flex:1"></div>
    </div>


</ng-template>
<ng-template #ImportItems>
  <div>
    <button type="button" color="primary" mat-raised-button (click)="selectAgain()">
      <mat-icon>arrow_back</mat-icon>Select Different File
    </button>
    <ng-container [ngTemplateOutlet]="showNotes"></ng-container>
    <ng-container [ngTemplateOutlet]="showInputs"></ng-container>
    <ng-container [ngTemplateOutlet]="showStatus"></ng-container>
    <ng-container [ngTemplateOutlet]="showButtons"></ng-container>
    @if (importDoc.getRows().length > 0 ) {
      <table class="ouromc-table">
        <ng-container [ngTemplateOutlet]="showHeadings"></ng-container>
        @if (importDoc.hasErrors()) {
          <ng-container [ngTemplateOutlet]="showErrorsOnTop"></ng-container>
        }
        <ng-container [ngTemplateOutlet]="showRows"></ng-container>
      </table>
    }
  </div>
</ng-template>
<ng-template #showButtons>
  @if (showValidateButton()) {
    <div style="text-align:end">
      <button type="button" color="primary" [disabled]="working" mat-raised-button (click)="validateData()">
        Proceed importing to {{currentUser.currentTeam.name}}
      </button>
      @if (working) {
        <button type="button" color="primary" disabled mat-raised-button>
          <mat-icon>quiz</mat-icon>Uploading, please wait
        </button>
      }
    </div>
  }
  @if (!importDoc.hasErrors() && validated) {
    <div>
      <button type="button" color="primary" mat-raised-button (click)="postData()">
        <mat-icon>upload</mat-icon>Upload data into {{currentUser.currentTeam.name}}
      </button>
    </div>
  }
</ng-template>
<ng-template #showHeadings>
  <tr>
    @for (cell of importDoc.getGeneralRows()[0].getFields(); track cell) {
      <th class="cell">
        {{cell.getHeading()}}
      </th>
    }
  </tr>
</ng-template>
<ng-template #showRows>

    @for (row of importDoc.getRows(); track $index) {
        @if ($index <= showRowDetails) {
            <ng-container [ngTemplateOutlet]="showRow" [ngTemplateOutletContext]="{row:row}"></ng-container>
        }
    }
    @if (showRowDetails < importDoc.getRows().length) {
        <tr><td>
            There are {{importDoc.getRows().length}} only showing {{showRowDetails}}
            <button (click)="showRowDetails = showRowDetails + 100">Show More</button>
        </td></tr>
    }
</ng-template>
<ng-template #showRow let-row='row'>
  <tr [style]="row.skipRow ? 'text-decoration: line-through; font-weight:lighter' : ''">
    @for (cell of row.getFields(); track cell) {
      <td class="cell"
        [style]="cell.skipField ? 'text-decoration: line-through; font-weight:lighter' : ''">
        <div [innerHTML]="cell.getFormattedValue()"></div>
      </td>
    }
  </tr>
  @if (row.hasErrors) {
    <tr>
      <td class="cell" colspan="100">
        <ul>
          @for (msg of row.getErrors(); track $index) {
            <li><span style="color:red">Error : </span>{{msg}}</li>
          }
        </ul>
      </td>
    </tr>
  }
</ng-template>
<ng-template #showErrorsOnTop>
  <tr>
    <td colspan="100" style="color:red">
      <h1 class="mat-h1">Errors</h1>
    </td>
  </tr>
  @for (row of importDoc.getRows(); track row) {
    @if (row.hasErrors) {
      <ng-container [ngTemplateOutlet]="showRow" [ngTemplateOutletContext]="{row:row}"></ng-container>
    }
  }
  <tr>
    <td colspan="100">
      <h1 class="mat-h1">All Data</h1>
    </td>
  </tr>
</ng-template>
<ng-template #showInputs>
  @if (importDoc.getInputs().length > 0) {
    <table class="inputs" width="100%">
      <tr>
        <th>Import Configuration Required</th>
        <th>Input</th>
      </tr>
      @for (input of importDoc.getInputs(); track input) {
        <tr>
          <!-- td>
          {{input.getName()}}
          </td -->
          <td>
            <div style="opacity: 90%; text-align: right;" [innerHTML]="input.getHelp()"></div>
          </td>
          <td>
            <div style="display:flex; width: 100%">
                @for (ctl of input.getControls(); track ctl) {
                    <div class="input">
                        <app-ctl-holder [control]="ctl" [on]="'grid'"></app-ctl-holder>
                    </div>
                }
            </div>
          </td>
        </tr>
      }
    </table>
  }
  @if (importDoc.getInputs().length === 0) {
    <span>No Inputs Required</span>
  }
</ng-template>
<ng-template #showStatus>
  <div>
    <p> {{importDoc.getRows().length}} Rows, {{importDoc.getInputs().length}} Inputs needed,  {{importDoc.getErrorCount() }} errors encountered so far...</p>
  </div>
</ng-template>

<ng-template #showNote let-item="item">
    <li>
        {{item.note}}
        @if (item.deleteHandler) {
            <mat-icon color="warn" class="deleteNoteIcon" (click)="importDoc.deleteNote(item, true)">delete_forever</mat-icon>
        }
        @if (item.needsFix) {
            <span style="color:red">[needs fix]</span>
        }
        @if (item.childNotes?.length > 0) {
            <ul>
                @for (c of item.childNotes; track $index) {
                    <ng-container [ngTemplateOutlet]="showNote" [ngTemplateOutletContext]="{item:c}">
                    </ng-container>
                }
            </ul>
        }
    </li>
</ng-template>
<ng-template #showNotes>
  @if (importDoc.getRows().length > 0 ) {
    <ul>
      @for (item of importDoc.getNotes(); track $index) {
        <ng-container [ngTemplateOutlet]="showNote" [ngTemplateOutletContext]="{item}">
        </ng-container>
      }
    </ul>
  }
</ng-template>
<ng-template #chooseType>
  <div class="controlHolder">
    <app-ctl-holder [control]="importerControl"></app-ctl-holder>
  </div>
  @if (currentOption) {
    @if (parsing) {
        <p>Parsing input file...</p>
    } @else {
        <ng-container [ngTemplateOutlet]="chooseFile"></ng-container>
    }
    @if (currentOption.sampleFile) {
        <div style="width: 100%; text-align: center; margin-top: 20px">
            <a [href]="currentOption.sampleFile" download>
                <button mat-button color="primary">Download Sample File</button>
            </a>

        </div>
    }
    @if (currentOption.parser.batchType && importFiles?.length > 0) {
        <div style="display:flex">
            <div style="flex:1"></div>
            <div>
                <h2>Previous Imports</h2>
                <table>
                    <tr>
                        <th>Name</th>
                        <th>Result</th>
                        <th>Status</th>
                    </tr>
                    @for (i of importFiles; track $index) {
                    <tr>
                        <td>{{i.name}}</td>
                        <td>{{i.result}}</td>
                        <td>{{i.status}}</td>
                    </tr>
                    }
                </table>
            </div>
            <div style="flex:1"></div>
        </div>
    }
  }
</ng-template>
<ng-template #chooseFile>
  <div>
    <div class="container large-container" appUploadDragAndDrop (fileDropped)="onFileDropped($event)"
      style.="Width:300px">
      <!-- -->
      <input type="file" #fileDropRef id="fileDropRef" multiple
        (change)="fileBrowseHandler($event.target.files)" />
      <!--img src="assets/img/dnd/ic-upload-file.svg" alt=""-->
      <mat-icon class="upload">upload_file</mat-icon>
      <p>Drag and drop {{currentOption.name}} file here</p>
      <p>or</p>
      <label for="fileDropRef">Browse for file</label>
    </div>
    <div class="files-list">
      @for (file of files; track file; let i = $index) {
        <div class="single-file">
          <!--img src="assets/img/dnd/ic-file.svg" width="45px" alt="file"-->
          <mat-icon>attachment</mat-icon>
          <div class="info">
            @if (file.progress < 100) {
              <h4 class="name">
                {{ file?.name }}
              </h4>
            }
            @if (file.progress >= 100) {
              <h4 class="name">
                {{ file?.name }} - Uploaded
              </h4>
            }
            <p class="size">
              {{ file?.size | byteCount }} @if (file?.failed) {
              <span i18n>Upload has failed...</span>
            }
          </p>
          <app-upload-progress [upFile]="file"></app-upload-progress>
        </div>
      </div>
    }
  </div>
</div>
</ng-template>
