/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { MatDialog } from '@angular/material/dialog';

import { Action } from 'src/app/model/action';
import { RequestTemplate } from 'src/app/model/RequestTemplate';
import { ServiceRequest } from 'src/app/model/serviceRequest';
import { User } from 'src/app/model/user';

import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { EditDialogComponent } from 'src/app/shared/dialogs/edit-dialog/edit-dialog.component';
import { Field } from 'src/app/shared/field/Field';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { ActionColor, IFormAction } from 'src/app/shared/form/form.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { GridField } from 'src/app/shared/grid/grid-field';
import { required } from 'src/app/shared/validators';
import { RequestTemplatesService } from '../request-templates.service';

export class AddTemplateAction implements IFormAction {
    name = $localize`Apply Template`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'pattern';
    approvalNeeded = false;
    disabled = false;
    approvalText: string;
    tipText = "Use a predefined template to add standard set of actions to this request";

    sr: ServiceRequest;
    approvedBadDate = null;

    templateField = FormPicklistComponent.make('Template', 'templateId', 'template',
        { service: this.tmplSvc, refreshes: [this.refreshTemplate.bind(this)] }
    );

    actionGrid: GridField = new GridField({
        field: {
            label: 'To Do Checklist', name: 'actions', value: 'actions', sendServer: true,
        },
        rowFactory: () => [
            FieldMaker.id({ visible: Field.noShow }),
            FormTextComponent.make('Title', 'title', { validators: [required], cellOpts: { heading: 'Name' } }),
            FormDateComponent.make('Planned Date', 'plannedDate', {cellOpts: {maxWidth: '10em'}, validators: [required]}),

            FieldMaker.rev(),
            FieldMaker.idHolder('templateId'),
            FieldMaker.idHolder('createdById'),
            FieldMaker.deleteGridRow(),
            FieldMaker.idHolder('omcId'),
            FieldMaker.idHolder('teamId')
        ],
    });

    constructor(private tmplSvc: RequestTemplatesService, private dialog: MatDialog,
        private cds: ConfirmDialogService, private currentUser: User) { }

    refreshTemplate(tmpl: RequestTemplate) {
        console.warn(tmpl, this.sr);
        const actionsToRemove = this.sr.actions.filter( o => !o.id) as Action[];
        actionsToRemove.forEach ( atr => this.sr.actions.splice(this.sr.actions.findIndex( x => x === atr )));

        ServiceRequest.templateMapper(tmpl, this.sr, this.currentUser);
        this.actionGrid.setValue(this.sr, false);
        console.log(this.actionGrid.getFormValue());
    }

    action(sr: ServiceRequest, config: FormConfig) {

        this.sr = sr;
        sr.actions = [];

        const dialogFormConfig = new FormConfig(
            {
                title: $localize`Action`,
                fieldSet: new FieldSet(
                    {
                        fields: [
                            FieldMaker.idHolder('id'),
                            FieldMaker.rev(),
                            this.templateField,
                            this.actionGrid,
                        ],
                        formLayout: [{ cells: [{}] }]
                    }
                ),
                mode: 'edit',
                itemId: sr.id,
                customTitle: $localize`Apply Template To Request ${sr.refNr}`
            }
        );

        const dialogRef = this.dialog.open(EditDialogComponent,
            {
                data: {
                    config: dialogFormConfig,
                    service: config.service,
                    id: sr.id,
                    width: '660'
                }
            }
        );

        return dialogRef.afterClosed();
    }
    //  setup(serviceRequest: ServiceRequest) {
    setup(sr: ServiceRequest) {
        if (sr.srStatus === ServiceRequest.srOpen.id && !sr.templateId) {
            this.show = true;
            if (sr.subStatus === ServiceRequest.srUnplanned.id) {
                this.disabled = false;
            } else {
                this.disabled = true;
            }
        } else {
            this.show = false;
        }
    }
}
