<tr>
  @if (!isPhone) {
    <td class="avatar">
      <app-avatar [id]="chatItem?.authorId"></app-avatar>
    </td>
  }
  <td style="border: 1px; width:100%">
    <div [style.margin-left.px]="indent">
        @if (!isPhone) {
            @if (depth > 0 && isFirst) {
            <div style="float:left; padding-top: 8px;">
                <mat-icon style="transform: rotate(180deg)"><span>reply</span></mat-icon>
            </div>
            }
            @if (depth > 0 && isLast && !isFirst) {
            <div style="float:left;  padding-top: 28px;">
                <mat-icon style="transform: rotate(315deg);"><span>reply</span></mat-icon>
            </div>
            }
            @if ((depth === 0) || (!isFirst && !isLast)) {
            <div style="float:left;">
                <mat-icon></mat-icon>
            </div>
            }
        }
      <div class="chatItemContent">
        @if (chatItem.parentId) {
          <div><app-chat-reply [chatItem]="chatItem.parent"></app-chat-reply></div>
        }
        @if (chatItem.content !== '???') {
          <div [innerHTML]="chatItem.content" class="commentText"></div>
        }
        <div [title]="(chatItem.createdAt * 1000) | date">
          <span style="font-weight: lighter; font-size: small">
            Posted {{chatItem.createdAt | fromNow}}
            by: <app-user-name [id]="chatItem.authorId"></app-user-name>
          </span>
          <ng-container *ngTemplateOutlet="theReplyButton"></ng-container>
          <ng-container *ngTemplateOutlet="theExpandButton"></ng-container>
        </div>
        @if (chatItem.showReplies) {
          <div style="border-top:1px solid; margin-top:50px">
            <table style="width:100%">
              @for (ci of chatItem.replies; let isFirst = $first; track ci) {
                <div app-chat-entry
                  [depth]="1"
                  [allowReply]="allowReply"
                  (replyButton)="replyButton.emit({chatItem: ci})"
                [isFirst]="isFirst" [chatItem]="ci"></div>
              }
            </table>
          </div>
        }
      </div>
    </div>
    <!-- Icons not used any more.... -->
    @for (icon of getIcons(); track icon) {
      @if (icon.content === 'thumb_up') {
        <mat-icon color="primary"
        matTooltip="Created {{icon.createdAt | fromNow}}">thumb_up</mat-icon>
      }
      @if (icon.content === 'thumb_down') {
        <mat-icon color="warn" style="float:right"
        matTooltip="Created {{icon.createdAt | fromNow}}">thumb_down</mat-icon>
      }
    }
  </td>
</tr>
<ng-template #theReplyButton>
    @if (allowReply) {
        <button mat-button color="primary" style="padding-bottom: 2px; font-size: 16px" matTooltip="Reply to this comment"
            (click)="replyButton.emit({chatItem})">
            <mat-icon aria-label="reply">reply</mat-icon>
        </button>
    }
</ng-template>
<ng-template #theExpandButton>
  @if (chatItem.replies.length > 0 && !isPhone) {
    <button type="button" style="font-size: small; width: 24px; height: 24px; margin-left: -20px"
      mat-mini-fab color="accent"
      (click)="chatItem.showReplies = !chatItem.showReplies"
      matTooltip="Click this button to show or hide {{chatItem.replies.length}} response(s) to this comment">
      @if (!chatItem.showReplies) {
        <span>
          {{chatItem.replies.length}}
        </span>
      }
      @if (chatItem.showReplies) {
        <span>X</span>
      }
    </button>
  }
</ng-template>
