@if (on==='form') {
  <mat-form-field class="full-width" style="height: 74px">
    <mat-label>{{control.field.label}}</mat-label>
    <input matInput [formControl]="control" type="date" autocomplete="off">
    @if (control.field.hint) {
        <mat-icon matSuffix color="primary" [matTooltip]="control.field.hint">info</mat-icon>
    }    
    <!--input matInput [formControl]="control" [matDatepicker]="picker" MAT DATEPICKER WORKS IN Date() types -->
  <!-- mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker -->
    <mat-error style="margin-top:-5px">
      <app-form-error [(control)]="control"></app-form-error>
    </mat-error>
  </mat-form-field>
}
<!-- mat-form-field>
All Date fields are currently stored in ISO string format (YYYY-MM-DD)
All Java Script date formats are in fact date time, so a date entered as 2021-31-12 - could in fact be 2021-31-12 23:30:00 GMT,
i.e. stored with a timestamp... Which is then displayed with different timezone settings, e.g. CET could become 2022-01-01 00:30:00
See: https://github.com/angular/components/blob/9eeb4b5aa0473c0815a73bfe3c2ed6164d86a3b3/src/material/core/datetime/native-date-adapter.ts
And: https://material.angular.io/components/datepicker/overview#customizing-the-parse-and-display-formats
<mat-label>{{control.field.label}}</mat-label>
<input matInput [matDatepicker]="picker" [formControl]="control">
<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
<mat-datepicker #picker></mat-datepicker>
<mat-error>
  <app-form-error [(control)]="control"></app-form-error>
</mat-error>
</mat-form-field -->
@if (on!=='form') {
  <div>
    @if (!control.field.readonly && !control.field.disable) {
      <input  type="date" [formControl]="control"
        [ngClass]="{dateField : control.field.readonly, error: control.invalid && control.touched, isNull : !control.value}"
        [readonly]="control.field.readonly ? '' : null">
    }
    @if (control.field.readonly || control.field.disable) {
      <span>{{formatToLocal(control.value)}}</span>
    }
  </div>
}
