<mat-dialog-content style="max-width: 500px; max-height: 65vh">
    <mat-card-title style="margin-bottom: 20px">
        Create New Agency
    </mat-card-title>
    <app-ctl-holder [control]="newAgencyLegalCtl"></app-ctl-holder>
    <app-ctl-holder [control]="newAgencyNameCtl"></app-ctl-holder>
    <app-ctl-holder [control]="slugCtl"></app-ctl-holder>
    <button mat-raised-button color="primary" class="edit" (click)="createAgency()">
        Create Agency
    </button>
</mat-dialog-content>
