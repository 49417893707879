/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { of } from "rxjs";
import { MyInjector } from "src/app/app.module";
import { Unit } from "src/app/model/unit";
import { ActionColor } from "src/app/shared/form/form.component";
import { UnitService } from "../unit.service";
import { IFormAction } from "src/app/shared/form/form.component";


export class StatementPDFAction implements IFormAction {
    name = $localize`Print PDF Statement`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'picture_as_pdf';
    approvalNeeded = false;
    disabled = false;
    approvalText = 'Produce a PDF copy';
    tipText: string;
    unit: Unit;

    unitSvc = MyInjector.instance.get(UnitService)

    action(unit: Unit) {
        this.unitSvc.getPDF(unit);
        return of(this.unit)
    }

    setup(unit: Unit) {
        this.unit = unit;
        this.show = true;
    }
}
