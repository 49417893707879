/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Field } from 'src/app/shared/field/Field';
import { AbstractObject, uuid } from 'src/app/model/abstract-object';
import { AbstractHttpService } from '../abstract-http.service';
import { AppFormControl } from "../form/app-form-control";
import { UpFile } from "../form/file/file-upload/UpFile";
import { Attachment } from 'src/app/model/attachment';


export class FileOpts {
    service: AbstractHttpService;
    dragsTo?: string[];
    allowNew?: boolean = false;
    allowMove?: boolean = false;
    allowInvoice?: boolean = false;
    allowDelete?: boolean = false;
    smallAddBox?: boolean = false;
    onDrop?: (files: Array<UpFile>) => void;
    widthPx?: number;
    deferredFiles?: UpFile[] = [];
}

export class AttachmentControl extends AppFormControl {
    field: AttachmentField;
}

export class AttachmentField extends Field {

    type: 'fileUpload';

    attachToId: uuid;
    attachFromAttribute = 'id';

    fileOpts?: FileOpts;

    setValue(item: AbstractObject, readonly: boolean) {
        if (item) {
            this.attachToId = item[this.attachFromAttribute];
        } else {
            console.warn('Setting attachment value to nothing...', {t: this, c: this.control.parent});
        }

        this.fileOpts.deferredFiles.length = 0;
        return super.setValue(item, readonly);
    }

    removeAttachment(o: Attachment) {
        const index = this.control.value.indexOf(o);
        if (index >= 0) {
            this.control.value.splice(index, 1);
            this.control.setValue(this.control.value);
            console.warn('Removed attachment', o);
        } else {
            console.error('Could not remove attachment form list', { o, index, arry: this.control.value })
        }
    }

    getFormValue() {
        if (this.fileOpts.deferredFiles && this.fileOpts.deferredFiles.length > 0) {
            return this.fileOpts.deferredFiles;
        }
        return super.getFormValue();
    }
}
