<div style="display: flex; flex-wrap: wrap;  margin: 1% auto auto 1%;">
    <div style="margin-left:auto; margin-right:auto" [style.width.px]="screenWidth + 100">
        @if (lps.profile.firstName) {
            <h1 class="mat-h1">{{lps.profile.firstName}}, welcome to the OurOMC demo room</h1>
        } @else {
            <h1 class="mat-h1">Welcome to the OurOMC demo room</h1>
        }
        <p>Please click on the category below you are interested in, and then on the videos to learn more about OurOMC.</p>
        @if (isPhone) {
            <ng-container [ngTemplateOutlet]="onPhone"></ng-container>
        } @else {
            <ng-container [ngTemplateOutlet]="onComputer"></ng-container>            
        }
    </div>
</div>

<ng-template #onComputer>
    <mat-tab-group>
        @for (c of categories; track $index) {
            <mat-tab label="{{c.name}} ({{c.count}})">
                <div style="padding-top: 20px; padding-bottom: 10px; height: calc(100vh - 320px)">
                    @for (demo of demos; track $index) {
                        @if (demo.category.indexOf(c.name) >= 0) {
                            <ng-container [ngTemplateOutlet]="showVideoTmplt" [ngTemplateOutletContext]="{demo}"></ng-container>
                        }                    
                    }
                </div>
            </mat-tab>
        }
    </mat-tab-group>
</ng-template>

<ng-template #onPhone>
    <p>Videos are best played in full screen mode on a laptop or large monitor.</p>    
    <div><mat-card style="padding-top : 10px">
        <mat-accordion>
            @for (c of categories; track $index) {
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title><h3>{{c.name}} ({{c.count}})</h3></mat-panel-title>
                    </mat-expansion-panel-header>                    
                    <div>
                        @for (demo of demos; track $index) {
                            @if (demo.category.indexOf(c.name) >= 0) {
                                <ng-container [ngTemplateOutlet]="showVideoTmplt" [ngTemplateOutletContext]="{demo}"></ng-container>
                            }                    
                        }
                    </div>
                </mat-expansion-panel>
            }            
        </mat-accordion>
    </mat-card></div>
</ng-template>
            

<ng-template #showVideoTmplt let-demo="demo">
    <div class="demoItem" (click)="selectDemo(demo)">
        <div style="display:flex">
            <div style="align-content: center; padding-left:10px" [matTooltip]="getTooltip(demo)">
                <mat-icon>videocam</mat-icon>
                <mat-progress-bar mode="determinate" [value]="getProgress(demo)"></mat-progress-bar>
            </div>
            <div class="demoTitle">
                <p class="demo">
                    {{demo.title}} <span style="font-size:small; color:grey">({{math.round(demo.duration / 60)}}m
                        {{demo.duration %
                        60}}s)</span>
                </p>
                <p class="text" [style.width.px]="screenWidth">{{demo.text}}</p>
            </div>
        </div>
        @if (selectedDemo === demo && approval==='yes') {
        @if(isLoading) {
        <div style="align-items: center; width: 100%">
            <mat-spinner style="margin-left:auto; margin-right:auto"></mat-spinner>
        </div>
        }
        <div class="videoDiv">
            <video id="demoVideoElement" controls [width]="screenWidth - 40" autoplay (loadeddata)="isLoading = false"
                controlsList="nodownload" oncontextmenu="return false;">
                <source [src]="videoPath + demo.url" type="video/mp4">
            </video>
        </div>
        } @else if (selectedDemo === demo && approval!=='yes') {
            <div class="request-access">
                <p>If you request access, you will be granted access to all of our video content</p>
                <div style="display:flex">
                    <button mat-raised-button color="primary" (click)="requestAccessClick(false)">Request Access</button>
                    <button mat-raised-button color="primary" (click)="requestAccessClick(true)">Enter Access Code</button>
                </div>

            </div>
        }
    </div>
</ng-template>
