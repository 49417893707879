import { Component, inject } from '@angular/core';
import { NavRoute } from 'src/app/shared/NavRoute';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FormPageComponent } from 'src/app/shared/form/form-page/form-page.component';
import { FixedAsset, FixedAssetService } from '../fixed-asset.service';
import { FormConfig } from 'src/app/shared/form/FormConfig';
import { FieldSet, LAYOUT_OPTIONS } from 'src/app/shared/form/field-set/field-set.component';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { required } from 'src/app/shared/validators';
import { combineLatest, of } from 'rxjs';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { OmcAgent } from 'src/app/model/OmcAgent';
import { OmcAgentService } from '../../user/omc-agent-service';
import { Field } from 'src/app/shared/field/Field';
import { AreaService } from '../../unit/area.service';
import { FormTextAreaComponent } from 'src/app/shared/form/form-text-area/form-text-area.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { MemoField } from 'src/app/shared/memos/MemoField';
import { ActionGridComponent } from 'src/app/shared/action/action-grid.component';
import { GridField } from 'src/app/shared/grid/grid-field';
import { ServiceRequest } from 'src/app/model/serviceRequest';
import { ServiceRequestPageComponent } from '../../crm/service-request-page/service-request-page.component';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { AttachmentGridComponent } from 'src/app/shared/form/file/attachment-grid/attachment-grid.component';
import { FixedAssetType, FixedAssetTypeService } from '../fixed-asset-type.service';
import { SupplierService } from '../../supply/supplier.service';
import { Supplier } from 'src/app/model/supplier';

@Component({
  selector: 'app-fixed-assets-page',
  standalone: true,
  imports: [FormPageComponent],
  templateUrl: './fixed-assets-page.component.html',
  styleUrl: './fixed-assets-page.component.scss'
})
export class FixedAssetsPageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('fassets', FixedAssetsPageComponent, 'inventory');

    private dataSvc = inject(FixedAssetService);

    omcAgentSvc = inject(OmcAgentService);
    areaSvc = inject(AreaService);
    typeSvc = inject(FixedAssetTypeService);
    supplierSvc = inject(SupplierService);

    agentField = FormPicklistComponent.make('Agent', 'agentTeamId', 'agentTeam',
        { items: [], refreshes: [this.agentRefresh.bind(this)] },
        { validators: [required], visible: Field.noShow }
    );

    areaField = FormPicklistComponent.make('Area', 'areaId', 'area', { service: this.areaSvc });

    types: FixedAssetType[] = [];
    typePickField = FormPicklistComponent.make('Type', 'fassetTypeId', null, { items: [] }, {visible: Field.noShow});
    typeNameField = FormTextComponent.make('Type', 'typeName', {readonly: true});

    suppliers : Supplier[] = []
    supplierPickField = FormPicklistComponent.make('Supplier', 'supplierId', null, { items: []}, {
        visible: Field.noShow, formColumn: 2
    });
    supplierNameField = FormTextComponent.make('Supplier', 'supplierName', {
        readonly: true,
        visible: Field.showAll, formColumn: 2
    });

    attachmentGrid = AttachmentGridComponent.make(this.dataSvc);
    memoField = new MemoField(this.dataSvc);
    actionGrid = ActionGridComponent.make('FixedAsset');
    requestGrid = new GridField({
        field:
            { label: 'Requests', value: 'requests', sendServer: false, tabIcon: 'live_help' },
        rowFactory: (o: ServiceRequest) => [
            FormButtonComponent.makeLink('Ref', 'refNr', o ? ServiceRequestPageComponent.navRoute.getIdUrl(o.id) : '', {
                readonly: true, sendServer: false,
                cellOpts: { width: "5em", maxWidth: "5em" },
            }),
            FormTextComponent.make('Title', 'title', { readonly: true }),
            FormTextAreaComponent.make('Description', 'description', { readonly: true }),
            ServiceRequest.getStatusField(),
            ServiceRequest.getCreatedField(),
            ServiceRequest.getLastUpdatedField(),
        ]
    });

    config = new FormConfig({
        navRoute: FixedAssetsPageComponent.navRoute,
        title: $localize`Fixed Asset`,
        fieldSet: new FieldSet({
            fields: [
                FieldMaker.id(),
                FieldMaker.rev(),
                this.agentField,
                FieldMaker.nameControl({ validators: [required] }),
                this.areaField,
                this.typePickField,
                this.typeNameField,

                this.supplierPickField,
                this.supplierNameField,
                FormTextComponent.make('Make/Model', 'makeModel', { formColumn: 2 }),
                FormTextComponent.make('Dimensions', 'dimensions', { formColumn: 2 }),
                FormTextComponent.make('Serial Number', 'serialNr', { formColumn: 2 }),

                FormTextComponent.make('Description', 'description', { formColumn: 3 }),
                FormNumberComponent.make('Quantity', 'quantity').override({ formColumn: 3 }),
                FormDateComponent.make('Purchase Date', 'purchaseDt', { formColumn: 3 }),
                FormDateComponent.make('Warranty End', 'warrantyEndDt', { formColumn: 3 })


            ],
            formLayout: LAYOUT_OPTIONS.threeColOver1,
        }),
        service: this.dataSvc,
        mode: 'list',
        objectFactory: this.newFixedAsset.bind(this),
        beforeEdit: this.beforeEdit.bind(this),
        configReady: this.configReady,
        tabFields: [this.attachmentGrid, this.memoField, this.requestGrid, this.actionGrid]
    });

    constructor() {
        super();
        combineLatest([
            this.omcAgentSvc.get(true), // this.supplierSvc.get(true), this.typeSvc.get(true)
        ]).subscribe(([omcAgts /*, types, suppliers */]) => {
            this.agentField.setPicklistItems(omcAgts);
            if (omcAgts.length > 1) {
                this.agentField.visible = Field.showAll;
            }
            /* this.suppliers = suppliers as Supplier[];
            this.types = types as FixedAssetType[]; */
            this.configReady.next();
        })

    }
    newFixedAsset() {
        this.config.tabFields.forEach ( f => f.disableControl());
        this.editTypes();
        return of(new FixedAsset());
    }

    editTypes() {
        this.typeNameField.hide();
        this.typePickField.show();
        this.supplierNameField.hide();
        this.supplierPickField.show();
    }

    beforeEdit(o: FixedAsset) {
        this.config.tabFields.forEach(f => f.enableControl());
        this.agentRefresh({id: o.agentTeamId} as OmcAgent)
        this.editTypes();
        return o;
    }

    agentRefresh(agt: OmcAgent) {
        this.typeSvc.getAgentTypes(agt.id).subscribe(
            types => this.typePickField.setPicklistItems(types)
        )
        this.supplierSvc.getAgentTypes(agt.id).subscribe(
            suppliers => this.supplierPickField.setPicklistItems(suppliers)
        )
    }

}
