
<fieldset style="border: 1px solid; font-family: roboto-flex; border-radius: 5px; margin-right: 10px; min-height: 55px;">
  @if (!isPhone) {
    <legend style="font-size: small; font-weight: lighter">
      These users are following conversation and receive email updates
    </legend>
  }
  @if (isPhone) {
    <legend style="font-size: small; font-weight: lighter">Followers</legend>
  }
  <mat-chip-listbox aria-label="Followers">
    @for (follower of chatField.chat?.followers; track follower) {
      <mat-chip  (removed)="remove(follower)">
         <app-user-name [id]="follower.userId"></app-user-name>
        @if (allowRemove) {
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        }
      </mat-chip>
    }
  </mat-chip-listbox>
</fieldset>
