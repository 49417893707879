@if (initComplete && !isPhone) {
  <table width="100%" class="formGrid">
    @for (row of fieldSet.formLayout; track row; let rowN = $index) {
      @if (showRow(row)) {
        <tr>
          @for (cell of row.cells; track cell; let cellN = $index) {
            <td [attr.colSpan]="cell.colspan ? cell.colspan : null"
              [attr.rowSpan]="cell.rowspan ? cell.rowspan : null" [attr.width]="cell.width ? cell.width : null"
              [attr.style]="cell.style ? cell.style : null" [ngClass]="cell.class">
              @if (cell.pageTab && !isPrint) {
                <!-- div style="right: 0; position: absolute; z-index: 100; margin-right:15px">
                <button style="height: 18px; font-size: 18px" (click)="maximiseRow(row)">
                  <mat-icon>arrow_upward</mat-icon>
                </button>
                </div preserveContent -->
                <mat-tab-group [color]="'primary'" class="innerTabs" mat-align-tabs="start" mat-stretch-tabs="false"
                 animationDuration="0ms" preserveContent>
                  @for (field of fieldSet.fields; track field) {
                    @if (showField(field, rowN + 1, cellN + 1)) {
                        @if (field['lazyTab']) {
                            <mat-tab [label]="field.label">
                                <ng-template mat-tab-label>
                                    <ng-container *ngTemplateOutlet="tabLabel; context: {field}"></ng-container>
                                </ng-template>
                                <ng-template matTabContent>
                                    <app-ctl-holder [control]="field.control"></app-ctl-holder>
                                </ng-template>
                            </mat-tab>
                        } @else {
                            <mat-tab [label]="field.label">
                                <ng-template mat-tab-label>
                                    <ng-container *ngTemplateOutlet="tabLabel; context: {field}"></ng-container>
                                </ng-template>
                                <app-ctl-holder [control]="field.control"></app-ctl-holder>
                            </mat-tab>
                        }
                    }
                  }
                </mat-tab-group>
              }
              @if (!cell.pageTab || isPrint) {
                @for (field of fieldSet.fields; track field) {
                  @if (showField(field, rowN + 1, cellN + 1)) {
                    @if (isPrint && cell.pageTab) {
                        <h2 style="margin-top: 20px; page-break-after: avoid;">{{field.getTabLabel()}}</h2>
                    }                     
                    <app-ctl-holder [control]="field.control">
                    </app-ctl-holder>
                  }
                }
              }
            </td>
          }
        </tr>
      }
    }
  </table>
}
@if (isPhone && initComplete) {
  <table width="calc(100% - 60px)" class="formGrid">
    <tr>
      <td>
        @for (field of fieldSet.fields; track field) {
          @if (field['isGrid'] && field.visible.phone) {
            <h2 class="mat-h2">{{field.label}}</h2>
          }
          @if (field.visible.form) {
            <app-ctl-holder [control]="field.control"></app-ctl-holder>
          }
        }
      </td>
    </tr>
  </table>
}

<ng-template #tabLabel let-field="field">
    <div style="display: flex">
        <div style="flex:10">{{field.getTabLabel()}}</div>
        @if (field.getTabChip(field.control?.value)) {
        <div style="flex:1" class="our-tab-chip">
            {{field.getTabChip(field.control?.value)}}
        </div>
        }
    </div>
</ng-template>
