/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { AbstractObject } from 'src/app/model/abstract-object';
import { BankItem, BankRule } from 'src/app/model/bankAccount';
import { Unit } from 'src/app/model/unit';

class BankRec extends AbstractObject{

}

@Injectable({
    providedIn: 'root'
})
export class BankReconiliationService extends AbstractHttpService {

    // protected baseUrl = this.ajaxPath + 'txns?typeId=' + Txn.TYPE.SALE.id;
    protected http = inject(HttpClient);
    protected messageService = inject(MessageService);

    protected baseUrl = this.ajaxPath + 'txn/bankRec'
    protected cache: BankRec[];
    protected typeString = $localize `Bank Reconciliation`;

    createRule(item: BankItem, unit: Unit, teamId = null) {

        const options = this.getSimpleOptions(teamId);
        const rule = {
            bankId: item.bankId,
            unitId: unit.id,
            field: item.field,
            matches: item.matches
        }

        return this.http.post<BankRule>(this.baseUrl + '/rule', rule, options).subscribe(
            () => this.messageService.show('New Rule Created to Match ' + item.matches),
            error => this.handleError<BankRule>('Posting Bank Rule')(error)
        )

    }

}
