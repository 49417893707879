<div class="basePage">
    <div class="registerCard">
        <h1>
            Balance Sheet - {{cycle?.name}}
        </h1>
        <h2>
            {{companyName}}
        </h2>
        <table width="100%">
            <tr>
                <td>
                    <app-ctl-holder [control]="cycleField.control"></app-ctl-holder>
                </td>
                <td style="max-width: 10em">
                    <app-ctl-holder [control]="fromField.control"></app-ctl-holder>
                </td>
                <td style="max-width: 10em">
                    <app-ctl-holder [control]="toField.control"></app-ctl-holder>
                </td>
                <td>
                    <app-ctl-holder [control]="zerosField.control"></app-ctl-holder>
                </td>
            </tr>
        </table>
        <ng-container [ngTemplateOutlet]="details"></ng-container>
    </div>
</div>
<ng-template #details>
    <mat-card style="margin-bottom: 20px">
        <mat-card-content>
            <table width="100%">
                <ng-container [ngTemplateOutlet]="headers"></ng-container>
                @for (type of types; track type) {
                <ng-container [ngTemplateOutlet]="typeHeader" [ngTemplateOutletContext]="{type: type}"></ng-container>
                @for (bcode of type['bcodes']; track bcode) {
                <ng-container [ngTemplateOutlet]="lineItem" [ngTemplateOutletContext]="{bcode: bcode}">
                </ng-container>
                }
                <ng-container [ngTemplateOutlet]="typeFooter" [ngTemplateOutletContext]="{type: type}"></ng-container>
                }
                <ng-container [ngTemplateOutlet]="totals"></ng-container>
            </table>
        </mat-card-content>
    </mat-card>
</ng-template>
<ng-template #typeHeader let-type="type">
    <tr>
        <th colspan="99" style="text-align: left; border-bottom: 1px inset">
            {{type.name}}
        </th>
    </tr>
</ng-template>
<ng-template #typeFooter let-type="type">
    <tr>
        <td class="detail" style="text-align:right" colspan="3">
            {{type.name}}
        </td>
        @if (type.id === 3 || type.id === 2) {
        <td class="detail" style="text-align: right; border-bottom: 1px inset">
            {{( 0 - type.total) | currency}}
        </td>
        <td class="detail" style="text-align: right; border-bottom: 1px inset">
            {{( 0 - type.prevTotal) | currency}}
        </td>
        } @else {
        <td class="detail" style="text-align: right; border-bottom: 1px inset">
            {{type.total | currency}}
        </td>
        <td class="detail" style="text-align: right; border-bottom: 1px inset">
            {{type.prevTotal | currency}}
        </td>
        }
    </tr>
</ng-template>
<ng-template #totals>
    <tr>
        <td colspan="2" class="total">
            @if (profit > 0) {
            <span>Surplus</span>
            }
            @if (profit < 0) { <span class="bad">Deficit</span>
                }
        </td>
        <td class="total"></td>
        <td class="total" [ngClass]="{'bad': profit < 0}">{{profit | currency}}</td>
        <td class="total" [ngClass]="{'bad': profit < 0}">{{prevProfit | currency}}</td>
    </tr>
    <tr></tr>
    <tr>
        <td colspan="2" class="total">
            Totals
        </td>
        <td class="total"></td>
        <td class="total">{{total | currency}}</td>
        <td class="total">{{prevTotal | currency}}</td>
    </tr>
</ng-template>
<ng-template #headers>
    <tr>
        <th>
            Account
        </th>
        <th>
            Sort Code
        </th>
        <th>
            Count
        </th>
        <th>
            Total
        </th>
        <th>
            Previous
        </th>
    </tr>
</ng-template>
<ng-template #lineItem let-bcode="bcode">
    <tr class="detail">
        <td class="detail" style="padding-left: 20px">
            <a [routerLink]="getAccountLink(bcode)">{{bcode.name}}</a>
        </td>
        <td class="detail" style="text-align: right"> {{bcode.sort}} </td>
        <td class="detail" style="text-align: right">
            <a [routerLink]="getAccountTxn(bcode)">{{bcode.count}}</a>
        </td>
        @if (bcode.typeId === 3 || bcode.typeId === 2) {
        <td class="detail" style="text-align: right"> {{ (0 - bcode.total) | currency}} </td>
        <td class="detail" style="text-align: right"> {{ (0 - bcode.prevTotal) | currency}} </td>
        } @else {
        <td class="detail" style="text-align: right"> {{bcode.total | currency}} </td>
        <td class="detail" style="text-align: right"> {{bcode.prevTotal | currency}} </td>
        }
    </tr>
</ng-template>
