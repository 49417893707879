/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*
* Describes a field which may be presented as either an icon button or a hyperlink.
* Can also show informatinal icons, without an action
*/
import { AbstractControl } from "@angular/forms";
import { AbstractObject, uuid } from "src/app/model/abstract-object";
import { ActionColor } from "../form/form.component";
import { Field } from "./Field";
import { NavRoute } from "../NavRoute";
import { Params } from "@angular/router";
import { FormButtonComponent } from "../form/form-button/form-button.component";

export class BtnOpts {
    /** Style to be bound to the Button Style Attribute  */
    style?: string = '';

    /** Style to be bound to the mat-icon itself */
    iconStyle?: string = '';

    /** The method to be called if someone clicks on this event (only icons) */
    clickMethod?: (obj: AbstractControl, event: Event) => void;
    tableClickMethod?: (item: AbstractObject) => void;

    /** a URL Path to route, e.g. /myPath or /myPath/${id} */
    navigates?: string;

    /** The actual URL path to navigate to with values in curly braces resolved (against the current focusItem) */
    navigateRoute?: string;

    navItem?: NavRoute;
    navRouteCalculator?: (o: AbstractObject) => string;
    navParmCalculator?: (o: AbstractObject) => Params;
    navParms?: Params;

    color?: ActionColor = 'primary';

    /* hide function may need item being displayed to decide if needs to be hidden */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hide?(value: unknown) {
        return false;
    }
}

export class ButtonField extends Field {

    btnOpts = new BtnOpts();

    externalNav = false;

    formControlFactory = FormButtonComponent.createComponent;

    constructor(defaults: Partial<Field>, overrides: Partial<ButtonField> = {}) {
        super(defaults, overrides);
        if (overrides.btnOpts) {
            this.initFromPartial(overrides.btnOpts, this.btnOpts);
        }
    }

    setValue(item: AbstractObject, readonly: boolean) {
        if (this.btnOpts.navigates) {
            this.btnOpts.navigateRoute = this.getNavRoute(item);
        }
        if (this.btnOpts.navRouteCalculator) {
            this.btnOpts.navigateRoute = this.btnOpts.navRouteCalculator(item);
        }
        if (this.btnOpts.navParmCalculator) {
            this.btnOpts.navParms = this.btnOpts.navParmCalculator(item);
        } else if (!this.btnOpts.navParms) {
            this.btnOpts.navParms = {};
        }
        super.setValue(item, readonly);
    }

    getNavRoute(item: AbstractObject) {
        Field
        return this.resolveLink(this.btnOpts.navigates, item);
    }

    setNavTeam(teamId: uuid) {
        if (teamId) {
            this.btnOpts.navParmCalculator = () => { return { _forceTeam: teamId } };
        } else {
            this.btnOpts.navParmCalculator = () => { return {} };
        }
    }

    // Override method top style buttons on tables.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setTableIconStyle(newStyler: (o: AbstractObject) => string) {
        this.tableIconStyler = newStyler;
        return this;
    }

    defaultTableIconStyle = 'color: green; font-size: 20px; padding-top: 17px';

    // Override method top style buttons on tables.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    tableIconStyler(o: AbstractObject) {
        return this.defaultTableIconStyle;
    }

    private resolveLink(template: string, item: AbstractObject): string {
        let retVal = template;
        const regex = /\$\{([^}]+)\}/; // `\$\{([^}]+)\}`; // will match ${vars}
        const matches = template.match(regex);
        const replaceValue = matches ? this.resolveValue(matches[1], item) : null;

        if (matches && replaceValue) {
            // Simple replace: retVal = retVal.replace(matches[0], item[matches[1]]);
            retVal = retVal.replace(matches[0], replaceValue);
            retVal = this.resolveLink(retVal, item); // recurse incase more matches - Regex only returns first Variable
        }
        return retVal;
    }
}
