
/*
 * Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { Component, OnDestroy, ViewChild } from '@angular/core';
import { CanDeactivateGuard } from 'src/app/shared/guards/can-deactivate.guard';
import { FormPageComponent } from 'src/app/shared/form/form-page/form-page.component';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { User } from 'src/app/model/user';
import { Cycle } from 'src/app/model/cycle';
import { Period } from 'src/app/model/period';

import { FormConfig } from "./FormConfig";
import { DialogOptions } from '../dialogs/pick-dialog/pick-dialog.component';

@Component({
    template: ''
})
export abstract class AbstractPageComponent implements CanDeactivateGuard, OnDestroy {
    @ViewChild(FormPageComponent) page: FormPageComponent;

    currentUser: User;
    config: FormConfig;
    configReady = new ReplaySubject<null>(1);
    currentCycle: Cycle;

    currentPeriod: Period;
    subscriptions: Subscription[] = [];

    public dialogOptions: DialogOptions;

    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
        return this.page.canDeactivate();
    }

    canActivate() {
        return true;
    }

    ngOnDestroy() {
        for (const s of this.subscriptions) {
            s.unsubscribe();
        }
    }
}
