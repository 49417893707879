/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { HttpParams } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { switchMap } from "rxjs/operators";
import { MyInjector } from "src/app/app.module";
import { AbstractObject } from "src/app/model/abstract-object";
import { RecurringJob } from "src/app/model/recurring-job";
import { RequestTemplate } from "src/app/model/RequestTemplate";
import { ServiceRequest } from "src/app/model/serviceRequest";
import { Omc } from 'src/app/model/Omc';
import { DateHelper } from "src/app/shared/dateHelper";
import { ConfirmDialogService } from "src/app/shared/dialogs/confirmDialog";
import { GridDialogComponent } from "src/app/shared/dialogs/grid-dialog/grid-dialog.component";
import { FieldMaker } from "src/app/shared/field/FieldMaker";
import { FormDateComponent } from "src/app/shared/form/form-date/form-date.component";
import { FormTextAreaComponent } from "src/app/shared/form/form-text-area/form-text-area.component";
import { FormTextComponent } from "src/app/shared/form/form-text/form-text.component";
import { ActionColor, IFormAction } from "src/app/shared/form/form.component";
import { GridField } from "src/app/shared/grid/grid-field";
import { IsNarrowService } from "src/app/shared/is-narrow.service";
import { OmcService } from "../../agent/omc.service";
import { RequestTemplatesService } from "../request-templates.service";
import { RequestService } from "../request.service";
import { RecurringJobService } from "./recurring-job.service";
import { ScheduleJobsStepperComponent } from "../schedule-jobs-stepper/schedule-jobs-stepper.component";

export class ScheduleJobsAction implements IFormAction {
    name = 'Create Requests';
    color: ActionColor = 'primary';
    show = false;
    icon = 'calendar_month';
    approvalNeeded = false;
    disabled = false;
    approvalText = 'Not Required';
    tipText = $localize `Create a request for each planned job`

    template: RequestTemplate;

    omcs: Omc[] = [];

    dialog = MyInjector.instance.get(MatDialog);
    tmplService = MyInjector.instance.get(RequestTemplatesService);
    jobService = MyInjector.instance.get(RecurringJobService);
    omcService = MyInjector.instance.get(OmcService);
    requestService = MyInjector.instance.get(RequestService);
    isNarrowSvc = MyInjector.instance.get(IsNarrowService);
    cds = MyInjector.instance.get(ConfirmDialogService);

    rowFactory() {

        const nextDateField = FormDateComponent.make('Next Needs To Be Planned', 'plannedDate', {
            cellOpts: { width: '8em' },
        });

        return [
            FieldMaker.idHolder('teamId'),
            FieldMaker.idHolder('omcId'),
            FormTextComponent.make('OMC', 'omc.name', {readonly: true, sendServer: false}),
            FormTextComponent.make('Title', 'title', {readonly: true}),
            nextDateField,
            FormTextAreaComponent.make('Description', 'description')
        ];
    }

    gridField = new GridField({
        field: { value: 'requests', cellOpts: { heading: 'Requests' } },
        rowFactory: this.rowFactory.bind(this)
    });

    //action: (AbstractObject: any, FormConfig: any) => Observable<AbstractObject>;
    makeRequest(rj: RecurringJob) {

        const request = new ServiceRequest();
        request.teamId = rj.omc.teamId;
        request.title = rj.template.name + ' (' + (+rj.occurences_count + 1) + ')';
        request.omcId = rj.omcId;
        request.omc = rj.omc;
        request.plannedDate = rj.nextOccurence ? rj.nextOccurence : DateHelper.isoDate();
        request.description = rj.template.description;
        return request;
    }

    action() {
        return this.dialog.open(ScheduleJobsStepperComponent, {data: {template: this.template}}).afterClosed();

        const jobParms = new HttpParams().set('templateId', this.template.id);
        const newRequests = [];

        this.jobService.get(false, jobParms).subscribe(jobs => {
            const jobList = (jobs as RecurringJob[]);
            jobList.forEach(rj => {
                newRequests.push(this.makeRequest(rj));
            })
            this.gridField.setValue({ requests: newRequests } as unknown as AbstractObject, false);
            this.gridField.control.markAsDirty();
            console.log({ gf: this.gridField, newRequests, jobList, jobs });
        });

        const dConfig = {
            data: {
                gridField: this.gridField,
                saveFn: this.save.bind(this)
            },
            disableClose: true
        }

        return this.dialog.open(GridDialogComponent, dConfig).afterClosed();
    }

    setup(parent) {
        this.template = parent;
        this.show = true;
        return;
        const willCreate = +parent.recurring_jobs_count - +parent.open_requests_count;

        this.gridField.label = "Add " + parent.name + " recurring job to OMCs ";
        this.name = $localize`Create ${willCreate} requests`;

        if (+parent.open_requests_count > 0) {
            this.approvalNeeded = true;
            this.approvalText = $localize `There are ${parent.open_requests_count} open request(s).
                Normally, there should be ${parent.recurring_jobs_count} requests created for '${parent.name}'.
                You should complete the open requests before continuing. If you continue now,
                only ${willCreate} requests will be created.
                The ${parent.open_requests_count} OMCs/Jobs with open requests will not have an additional request created`;
        } else {
            this.approvalNeeded = false;
            this.approvalText = '';
        }

        if (!this.isNarrowSvc.isPhone() && +this.template.recurring_jobs_count > 0) {
            this.show = true;
        } else {
            this.show = false;
        }
        if (+parent.open_requests_count > parent.recurring_jobs_count) {
            this.disabled = true;
        }
    }

    save() {
        const items = this.gridField.getFormValue() as RecurringJob[];
        const itemsToSave = items.filter(o => o.frequencyId !== null);
        const cnt = itemsToSave.length;

        const title = $localize`Save ${cnt} Jobs`;
        const msg = $localize`Create a new recurring jobs for all jobs where a frequency has been entered `;

        return this.cds.openConfirm(title, msg).pipe(switchMap(response => {
            if (response === 'OK') {
                return this.requestService.postItems(itemsToSave).pipe(
                    switchMap(() => {
                        return this.tmplService.getOne(this.template.id, null);
                    })
                );
            }
        }));
    }
}
