@if (!customLogo) {
  <div class="primaryColor" style="width:230px; height:63px">
    <div id="our-logo" class="logo-redween" routerLink="/" style="cursor: pointer" >
      <div style="float: left">
        <mat-icon style="color:white; font-size: 48px; height: 48px; width: 48px;">webhook</mat-icon>
      </div>
      <div style="font-size: 26px;  padding-top: 2px; letter-spacing: 1.5px; color:white;">
        <span>Our</span><span style="opacity: .87;">OMC.ie</span>
      </div>
      <div class="strap-line">
        Building Communities
      </div>
    </div>
  </div>
}
@if (customLogo) {
  <div id="our-logo" style="width:230px; height:63px" routerLink="/" style="cursor: pointer" matTooltip="Go to home page">
    <img [src]="customLogo" style="width:163px; height: 53px; margin-top: 5px; margin-left: 33px;"/>
  </div>
}
