<div style="padding-top: 30px; padding-bottom: 10px; ">
    <p class="mat-headline-3">
        Terms of Use
    </p>
</div>
<div mat-dialog-content style="margin-left: 40px; margin-top: 0px" class="mat-typography" (scroll)="onScroll($event)">
    <p class="mat-headline-4">1. Acceptance of terms</p>
    <p mat-body>
        These Terms of Use ("Agreement") govern your access and use of OurOMC.ie ("Software"), provided by
        Coburn Social Enterprise ("Service Provider").
        By accessing or using the Software, you agree to be bound by this Agreement.
    </p>
    <p class="mat-body"><b>If you do not agree with these terms, do not use the Software.</b></p>
    <p class="mat-headline-4">2. License and Access</p>
    <p class="mat-body">The Service Provider grants you a non-exclusive, non-transferable, revocable license to access
        and use the Software
        solely for your internal business purposes. This license does not grant ownership rights to the Software.</p>
    <p class="mat-headline-4">3. Data Ownership and Responsibility</p>
    <p class="mat-headline-5">3.1 Data entered by any member of an OMC or by any agent acting on behalf of an OMC</p>
    <p class="mat-body">When an OMC has licensed the software, any data entered into the system by their authorised
        users becomes the exclusive property of the OMC.</p>
    <p class="mat-headline-5">3.2 Change of agent</p>
    <p class="mat-body">
        The OMC can at any time request that another agent have access to the data they own
        or for another agent to become their primary agent.
        The Service Provider may charge a fee of up to two months license fees in order to provide required migration.
    </p>
    <p class="mat-headline-5">3.3 OMCs without licenses</p>
    <p class="mat-body">If an agent has used the system to create data for an OMC who do not directly pay the license,
        then the OMC shall have the right to acquire ownership of the data.</p>
    <p class="mat-body">The agent who has created data on behalf of an OMC shall equally have a right to recover some of
        the costs they have incurred in the creation of such data.</p>
    <p class="mat-body">Any transfer of data outside of the system shall be a matter solely between the OMC and their
        respective agent.</p>
    <p class="mat-body">The service provider shall not facilitate the creation of a new license with access to existing data,
        or the transfer of existing data to a an OMC,
        without an explicit written request from the agent who created the data to do so,
        <b>unless a fee is paid</b> of 50% of the total licensing fees for the entire period
        when the original agent was actively updating the OMCs data.
        Any such fee collected by the service provider would be credited to the account of the original agent.
    </p>
    <p class="mat-body">The service provider reserves the right, from time to time, to remind unlicensed OMCs, via
        email, application banners, or whatever mechanism, of the status of their data and potential liability they are
        accumulating in respect to their data</p>

    <p class="mat-headline-5">
        3.4 Fair Usage Policy
    </p>
    <ul>
        <li>An OMC can store 100MB of files per residential unit within their license, additional storage charges apply thereafter</li>
        <li>An OMC can store 10,000 database records per residential unit within their license, additional charges apply thereafter</li>
        <li>An OMC can send one email per unit per day, i.e. an OMC could email every member every day. The service provider strongly encourage you not to send that many emails to your members, and will charge for additional emails sent above this limit</li>
        <li>All usage policy constraints are the average quantity per unit accross all units within the OMC</li>
    </ul>
    <p class="mat-body"></p>

    <p class="mat-headline-4">4. User Permissions and GDPR Compliance</p>
    <p class="mat-body">OMCs or their appointed agents shall set up user accounts and permissions within the Software in
        a manner that
        aligns
        with GDPR requirements. Users shall be granted access only to the extent necessary for their specific roles and
        responsibilities.</p>
    <p class="mat-headline-4">5. Payment</p>
    <p class="mat-body">Payment for the Software shall be made directly to the Service Provider or to their
        authorised distributor.
        If you are in any doubt about who you are making your license payment to,
        please ensure that you have received a confirmation email, from a coburn.ie email address,
        confirming that the party to whom you will make payment are an authorised distributor.
    </p>
    <p>There are two types of licenses:</p>
    <ul>
        <li>OMC License: This license is charged based on the number of units within the OMC.</li>
        <li>Agent License: This license is charged based on the number of users that the agent employs who can log in to
            the system</li>
        <li>An OMC that is self managed will also require the appropriate number of agent licenses, to access the
            necessary required functionality to self administer their development</li>
    </ul>
    <p class="mat-headline-4">
        6. Limitation of Liability
    </p>
    <p class="mat-body">
        The Service Provider and its affiliates shall not be liable for any direct, indirect, incidental, special, or
        consequential damages arising from or related to the use of the Software. This includes, but is not limited to,
        loss
        of
        revenue, profits, business, contracts, anticipated savings, data, goodwill, or wasted management or office time.
    </p>
    <p class="mat-headline-4">7. No Warranty</p>
    <p class="mat-body">
        The Software is provided on an "as-is" and "as-available" basis, without warranties of any kind, either express
        or
        implied, including, but not limited to, warranties of merchantability, fitness for a particular purpose, or
        non-infringement.
    </p>
    <p class="mat-headline-4">8. Termination</p>
    <p class="mat-body">
        The Service Provider reserves the right to terminate, suspend,
        or restrict your access to the Software at any
        time
        without notice, for any reason, including but not limited to violation of this Agreement.
    </p>
    <p class="mat-headline-4">9. Data Privacy</p>
    <p class="mat-body">The Service Provider will process personal data in accordance with its Privacy Policy, available
        on the Software's
        website.</p>
    <p class="mat-headline-4">10. Indemnification</p>
    <p class="mat-body">You agree to indemnify and hold the Service Provider harmless from any claims, damages,
        liabilities, costs, and
        expenses
        arising from your use of the Software or violation of this Agreement.
    </p>
    <p class="mat-headline-4">11. Governing Law, Jurisdiction & Dispute Resolution</p>
    <p class="mat-body">This Agreement shall be governed by and construed in accordance with the laws of Ireland.
        Any disputes arising out of or in connection with this Agreement shall be subject to the exclusive jurisdiction
        of the Irish courts.
    </p>
    <p class="mat-body">
        Any and all disputes that arise, must go through a good faith attempt at mediation prior
        with an agreed mediator before any legal action is commenced.
    </p>
    <p class="mat-body">
        If any provision of this Agreement shall be held or made invalid by a court decision, statute or rule,
        or shall be otherwise rendered invalid, the remainder of this Agreement shall not be affected thereby.
    </p>
    <p class="mat-headline-4">12. Amendments</p>
    <p class="mat-body">
        The Service Provider reserves the right to modify or amend this Agreement at its sole discretion. Updated terms
        will be posted on the Software's website.
    </p>
    <address>
        Coburn Social Enterprise,<br />
        t/a OurOMC, OurOMC.ie, CLG Systems, CLGSystems.ie<br />
        K2 Swords Enterprise Park,<br />
        Swords,<br />
        K67 V583<br />
        North Co. Dublin<br />
        <a href="https://clgsystems.ie">CLG Systems</a>
    </address>
    <p class="mat-body">
        For inquiries or concerns, please contact admin&#64;ouromc.ie
    </p>
    <p style="font-weight: bold;">
        By pressing accept button below, you acknowledge that you have read, understood, and agreed to these terms.
        If you are using the Software on behalf of a company or organization,
        you represent that you have the authority to bind them to this Agreement.
    </p>
    <p #lastUpdateElement>
        Last updated: {{lastUpdatedDate() | date: 'fullDate'}}
    </p>
</div>
@if (dialogRef) {
<mat-dialog-actions align="end">
    @if (simpleClose) {
    <button mat-raised-button color="primary" mat-dialog-close>Close</button>
    }
    @if (!simpleClose) {
    <button mat-raised-button color="warn"
        matTooltip="Click here if you do not accept our terms of service. You will not be able to use the software"
        mat-dialog-close (click)="logout()">Refuse & Logout</button>
    }
    @if (!allowAccept && !simpleClose) {
    <div style="display:inline" matTooltip="Please read and scroll to the end to accept terms of service">
        <button mat-raised-button color="primary" disabled>Accept</button>
    </div>
    }
    @if (allowAccept && !simpleClose) {
    <button matTooltip="Click here to confirm you accept our terms of service" mat-raised-button color="primary"
        [mat-dialog-close]="true" (click)="acceptTerms()">Accept</button>
    }
</mat-dialog-actions>
}
