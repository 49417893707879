/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*
* Create a pie chart to display above a table
*/
import { AbstractChart } from "./abstract-chart";

export class PieChart extends AbstractChart {

    height = 200;

    getTooltip() {
        return {
            pointFormat: '{series.name}: {point.y} <br/><b>{point.percentage:.1f}%</b>'
        };
    }

    protected createChartOptions() {

        const sglValue = this.series.length === 1;

        return {
            chart: {
                backgroundColor: this.chartBackgroundColor,
                borderRadius: this.chartBorderRadius,
                plotBorderWidth: null,
                type: 'pie',
                height: this.height,
            },
            title: this.getChartTitle(),
            subtitle: this.getSubTitle(),
            credits: { enabled: false },
            tooltip: this.getTooltip(),
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    events: {
                        click: this.filterData.bind(this)
                    },
                    dataLabels: {
                        enabled: true,
                        format: sglValue ? '{point.name}<br/>{point.y:,.0f}' : '{point.name}',
                        style: sglValue ? { fontSize: 14, textAlign: 'center' } : {},
                        distance: sglValue ? -70 : 0,
                        useHTML: true,
                    }
                }
            },
            series: [{
                name: this.getSeriesName(),
                colorByPoint: true,
                data: this.series
            }]
        }
    }
}
