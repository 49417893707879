<button mat-raised-button (click)="goToList()">
  <mat-icon>arrow_back</mat-icon>Back
</button>
<mat-card appearance="raised" class="basecard">
  @if (!formMode) {
    <mat-card-header>
        <div style="width: calc(100% - 32px)">
            <div style="display: flex; width:100%; margin-bottom : 20px">
                <div style="width: 52px">
                    <app-avatar [id]="focusItem?.authorId"></app-avatar>
                </div>
                <div style="flex-grow: 1">
                    <h1 style="margin-bottom: 0px">{{pageForm.controls.title.value}}</h1>
                    <mat-card-subtitle style="margin-top: 2px;">
                        Posted in
                        @if (focusItem?.areaName) { {{focusItem?.areaName}} }
                        @if (focusItem?.coreName) { ({{focusItem?.coreName}}) }
                        {{focusItem?.forum?.name}}<br />
                        {{focusItem?.createdAt | fromNow }}
                        @if (focusItem?.updatedAt) {
                          <span>(Updated {{focusItem?.updatedAt | fromNow}})</span>
                        }
                      </mat-card-subtitle>
                </div>
                @if (!screenSvc.isPhone()) {
                    <div style="max-width:70px">
                        @if (canEditPost() && focusItem?.mailsSent >= 0) {
                            <button (click)="onEdit()" style="width: 4em"
                              mat-raised-button color="primary" matTooltip="Click to edit this post">
                              Edit
                            </button>
                          }
                    </div><div style="max-width:112px">
                          @if (canEditPost() && focusItem?.mailsSent === 0) {
                            <button style="width: 8em"
                              (click)="notifyUsers()"
                              mat-raised-button color="primary"
                              matTooltip="Click here to send a notification email to all users about your post">
                              Notify Users
                            </button>
                          }
                          @if (focusItem?.mailsSent < 0) {
                            <button disabled="true"
                              mat-raised-button color="primary">
                              Sending Notifications...
                            </button>
                          }
                          @if (focusItem?.mailsSent > 0) {
                            <div matTooltip="{{focusItem?.mailsSent}} notifications where sent {{focusItem?.notificationSentAt | fromNow}}, {{focusItem?.notificationSentAt * 1000 | date}}">
                                <button disabled="true"
                                mat-raised-button color="primary">
                                Notifications sent
                              </button>
                            </div>
                          }
                    </div>
                }
            </div>
        </div>
    </mat-card-header>
  }
  @if (!formMode) {
    <mat-card-content>
      <div class="contentHolder">
        <div class="content">
            <div class="post-content" [innerHTML]="pageForm.controls.body.value"></div>
        </div>
      </div>
    </mat-card-content>
  }
  @if (formMode) {
    <mat-card-content>
      <div class="contentHolder">
        <div class="content">

            <div style="display:flex; flex-wrap:wrap;" class="formWidth">
                <div class="parmGrid">
                    <app-form-picklist [control]="pageForm.controls.forum"></app-form-picklist>
                </div>
                <!-- div class="parmGrid">
                    <app-form-picklist [control]="pageForm.controls.author"></app-form-picklist>
                </div -->
                @if (this.areas.length > 0) {
                    <div class="parmGrid">
                        <app-form-picklist [control]="pageForm.controls.areaId"></app-form-picklist>
                    </div>
                    <div class="parmGrid">
                        <app-form-picklist [control]="pageForm.controls.coreId"></app-form-picklist>
                    </div>
                }
            </div>
            <div class="formWidth">
                <mat-form-field>
                    <mat-label i18n>Post Title</mat-label>
                    <input matInput [formControl]="pageForm.controls.title">
                    <mat-error>
                        <app-form-error [(control)]="pageForm.controls.title"></app-form-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="formWidth">
                <editor [init]="editorOptions" [formControl]="pageForm.controls.body"
                placeholder="Tell us more about what you would like to discuss" tinyMatFormControl>
                </editor>
                <mat-error>
                <app-form-error [(control)]="pageForm.controls.body"></app-form-error>
                </mat-error>
            </div>

          <!-- app-form-actions [(pageForm)]="pageForm" (cancel)="cancelChanges()" (save)="saveChanges()"></app-form-actions -->
          <app-form-actions [(pageForm)]="pageForm" (cancel)="cancelChanges()" (save)="saveChanges()"
            (delete)="deleteObject()" hideSaveAndNew="true" [readonly]="false" [allowDelete]="false">
          </app-form-actions>
        </div>
      </div>
    </mat-card-content>
  }
  @if (!formMode) {
    <mat-card-footer>
    <!--app-comments *ngIf="focusItem" [post]="focusItem"></app-comments-->
    <!-- app-chat [control]="chatControl"></app-chat -->
     <div style="padding-left:20px; padding-right: 20px; margin-left: 30px; max-width: 400px">
        <app-ctl-holder [control]="attachmentControl"></app-ctl-holder>
     </div>


    <div style="width:calc(100% - 70px); margin: 20px 20px 20px 50px">
      <app-ctl-holder [control]="chatControl"></app-ctl-holder>
    </div>
  </mat-card-footer>
}
</mat-card>
