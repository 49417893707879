import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-currency-item',
  standalone: true,
  imports: [MatIconModule, CurrencyPipe],
  templateUrl: './currency-item.component.html',
  styleUrl: '../simple-fields.scss'
})
export class CurrencyItemComponent {
    @Input() label: string
    @Input() amount: number;
    @Output() editor = new EventEmitter<null>();
}
