/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { FormConfig } from "src/app/shared/form/FormConfig";
import { FieldSet } from "src/app/shared/form/field-set/field-set.component";
import { UnitPageComponent } from "../../unit/unit-page/unit-page.component";
import { FormButtonComponent } from "src/app/shared/form/form-button/form-button.component";
import { FormTextComponent } from "src/app/shared/form/form-text/form-text.component";
import { FormNumberComponent } from "src/app/shared/form/form-number/form-number.component";
import { Unit } from 'src/app/model/unit';
import { Panel } from "./panel";
import { Txn } from "src/app/model/txn";
import { formatCurrency } from "@angular/common";
import { FormDateComponent } from "src/app/shared/form/form-date/form-date.component";

export const receiptPanel: Panel = new Panel({
    type: 'receipts',
    icon: 'call_made',
    takeAction(u: Unit) {
        console.warn(u);
    },
    setTitle() {
        this._title = this._items?.length + ' Unreconciled receipts this entry might match to'
    },
    setConfig() { return new FormConfig({
        fieldSet: new FieldSet({
            fields: [
                FormTextComponent.make('Bank', 'bankName'),
                FormDateComponent.make('Date', 'txnDate'),
                FormButtonComponent.makeDialogOpenButton('Payment', 'name', 'id', UnitPageComponent).override({
                    calculateValue: (o:Txn) => formatCurrency(o.debit, 'EN-ie', '€')
                }),
                FormNumberComponent.make('Invoices', 'invoices'),
                FormNumberComponent.makeCurrency('Outstanding', 'outstanding'),
                FormNumberComponent.makeCurrency('Difference', 'diffAmount'),
                FormButtonComponent.makeTableButton('Match', 'match', this.takeAction.bind(this)).override({
                    calculateValue: (o: Txn) => {
                        return o.bankAccountId === this.item.bankId ? 'Pay' : 'n/a'},
                }),
            ],
        }),
        mode: 'list'
    })}
})
