/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Input, ViewContainerRef } from '@angular/core';
import { ControlOn, AppFormControl } from 'src/app/shared/form/app-form-control';
import { ButtonField } from '../../field/ButtonField';
import { NavRoute } from '../../NavRoute';
import { MyInjector } from 'src/app/app.module';
import { Params, Router, RouterLink } from '@angular/router';
import { AbstractObject } from 'src/app/model/abstract-object';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgTemplateOutlet } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { AbstractPageComponent } from '../abstract-page.component';

export class ButtonControl extends AppFormControl {
    field: ButtonField;
}

@Component({
    selector: 'app-form-button',
    templateUrl: './form-button.component.html',
    styleUrls: ['./form-button.component.scss'],
    standalone: true,
    imports: [NgTemplateOutlet, MatFormFieldModule, MatButtonModule, MatTooltipModule, RouterLink,
                MatInputModule, FormsModule, ReactiveFormsModule, MatIconModule]
})
export class FormButtonComponent {

    @Input() control: ButtonControl;
    @Input() on: ControlOn = 'form';

    public static makePageButton(label: string, method: () => void, icon: string = null): AppFormControl {
        const fld = FormButtonComponent.make(label, 'none', { type: 'icon' });
        fld.btnOpts.clickMethod = method;

        const ctl = fld.makeControl() as AppFormControl;
        ctl.setValue(icon);
        return ctl;
    }

    public static makeLink(heading: string, value: string, navigates: string, options: Partial<ButtonField> = {}): ButtonField {
        options.type = 'link';
        const field = new ButtonField({ value, cellOpts: { heading }, sendServer: false }, options);

        field.btnOpts.navigates = navigates;
        if (options.externalNav) {
            field.externalNav = true;
        }
        field.formControlFactory = FormButtonComponent.createComponent;
        return field;
    }

    public static makeTextButton(label: string, value: string, method: (cell: AppFormControl) => void, options: Partial<ButtonField> = {}): ButtonField {
        const field = new ButtonField({ value, label, sendServer: false, type: 'link' }, options);
        field.btnOpts.clickMethod = method;
        field.formControlFactory = FormButtonComponent.createComponent;
        return field;
    }

    public static makeIconButton(name: string, icon: string | ((o: AbstractObject) => string), toolTip: string, method: (cell: AppFormControl) => void): ButtonField {
        const btn = FormButtonComponent.make('', '', {
            name: 'action', label: '', type: 'icon', sendServer: false,
            cellOpts: {},
            calculateValue: () => 'block',
            toolTip,
            btnOpts: { clickMethod: method }
        });
        if (typeof icon === 'function') {
            btn.calculateValue = icon;
        } else {
            btn.calculateValue = () => icon;
        }
        return btn;
    }

    public static makeTableButton(label: string, value: string, method: (item: AbstractObject) => void, options: Partial<ButtonField> = {}): ButtonField {
        const field = new ButtonField({ value, label, sendServer: false, type: 'link' }, options);
        field.btnOpts.tableClickMethod = method;
        field.formControlFactory = FormButtonComponent.createComponent;
        return field;
    }
    public static makeDialogOpenButton(label: string, value: string, dataValue: string | ((i:AbstractObject) => unknown),
    comp: ComponentType<AbstractPageComponent>,
    options: Partial<FormButtonComponent> = {}) {
        const defaultData = (i) => ({id: i[dataValue as string], forceTeamId: i.teamId, hideTabs: true});
        const data = typeof dataValue === 'string' ? defaultData : dataValue;

        const field = new ButtonField({ value, label, sendServer: false, type: 'link', ...options });
        field.btnOpts.tableClickMethod = (i: AbstractObject) => {
            MyInjector.instance.get(MatDialog).open(comp,
                {
                    data: data(i)
                }
            )
        }
        return field;
    }

    /*
    * Navigate to a details page... From a grid...
    */
    public static makeNavDetailButton(label: string, displayValue: string|((o:AbstractObject) => string), idValue: string, nav: NavRoute, forceTeam = false): ButtonField {
        let field;
        if (typeof displayValue === 'string') {
            field = new ButtonField({ value: displayValue, label, sendServer: false, type: 'link' });
        } else {
            field = new ButtonField({ value: label, label, sendServer: false, type: 'link', calculateValue: displayValue });
        }

        field.btnOpts.navItem = nav;

        field.btnOpts.navRouteCalculator = (o) => {
            const v = field.resolveValue(idValue, o);
            if (v) {
                return nav.getIdUrl(v);
            }
            return null; // Do not navigate if no id value on item provided
        };

        if (forceTeam) {
            field.btnOpts.navParmCalculator = (o) => { return { _forceTeam: o.teamId } };
        } else {
            field.btnOpts.navParmCalculator = () => { return {} };
        }
        field.formControlFactory = FormButtonComponent.createComponent;
        return field;
    }

    /*
    * Navigate to a list page with parameters
    */
    public static makeNavButton(label: string, nav: NavRoute,
        navParmCalculator: (o: AbstractObject) => Params, options: Partial<ButtonField> = {}): ButtonField {
        const field = new ButtonField({ value: label, label, sendServer: false, type: 'link' }, options);
        /* These parms drive navigation from table */
        field.btnOpts.navParmCalculator = navParmCalculator;
        field.btnOpts.navRouteCalculator = () => nav.url;

        if (!field.hint) {
            field.hint = $localize `Click to navigate to related ${field.label}`;
        }

        /** These parms will set it up when used on form */
        field.btnOpts.navItem = nav;
        // Actual params will be calculated when field value is set...
        field.btnOpts.clickMethod = () => {
            const router = MyInjector.instance.get(Router);
            router.navigate([nav.url, field.btnOpts.navParms])
        };
        field.formControlFactory = FormButtonComponent.createComponent;
        return field;
    }


    public static make(heading: string, value: string, options: Partial<ButtonField> = {}): ButtonField {
        if (options.btnOpts?.navigates) {
            options.type = 'link';
            console.warn('Use makeLink for this...', { heading, value, options });
        }
        if (options.type !== 'link' && options.type !== 'icon') {
            console.error('This component does not know how to deal with field data supplied', options);
        }

        const field = new ButtonField({ value, cellOpts: { heading }, sendServer: false }, options);
        field.formControlFactory = FormButtonComponent.createComponent;
        return field;
    }

    public static createComponent(vcr: ViewContainerRef, ctl: ButtonControl, on: ControlOn) {
        const componentRef = vcr.createComponent(FormButtonComponent);
        componentRef.instance.control = ctl;
        componentRef.instance.on = on;
    }

    onClick($event) {
        // Disabled buttons do not show tooltip, we need tooltip for budget icons... but do not allow click readonly button
        if (this.control.field.btnOpts.clickMethod && !this.control.field.disable) {
            this.control.field.btnOpts.clickMethod(this.control, $event);
        }
    }

    image() {
        let theReturn: string = null;
        if (this.control?.value) {
            const val: string = this.control.value;
            if (val.startsWith('image:')) {
                theReturn = val.substring(6);
            }
        }
        return theReturn;
    }
}
