import { JsonPipe } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-show-json',
  standalone: true,
  imports: [MatButtonModule, MatDialogModule, JsonPipe],
  templateUrl: './show-json.component.html',
  styleUrl: './show-json.component.scss'
})
export class ShowJSONComponent {
    @Input() title: string;
    @Input() object: unknown;
    //@Inject(MAT_DIALOG_DATA) public data: CDSData
    data = inject(MAT_DIALOG_DATA)
}
