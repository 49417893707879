

<div class="container" [ngClass]="control.field && control.field.fileOpts.smallAddBox ? 'small-container' : 'large-container'"
  appUploadDragAndDrop (fileDropped)="onFileDropped($event)" [style.width]="control.field.fileOpts.widthPx + 4">
  <!-- -->
  <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
  <!--img src="assets/img/dnd/ic-upload-file.svg" alt=""-->
  @if (!control.field.fileOpts.smallAddBox) {
    <mat-icon class="upload">upload_file</mat-icon>
    <p>Drag and drop file here</p>
    <p>or</p>
    <label for="fileDropRef">Browse for file</label>
  }
  @if (control.field.fileOpts.smallAddBox) {
    <div height="74px" style="text-align: left">
      <button mat-raised-button color="primary">
        <!-- mat-icon style="font-size: 18px;padding-bottom:0px">upload_file</mat-icon -->
        Add File(s)
      </button>
    </div>
  }
</div>
<div class="files-list">
  @for (file of files; track file; let i = $index) {
    <div class="single-file" matTooltip="Files not yet uploaded">
      <!--img src="assets/img/dnd/ic-file.svg" width="45px" alt="file"-->
      <mat-icon>attachment</mat-icon>
      <div class="info">
        @if (file.progress < 100) {
          <h4 class="name">
            {{ file?.name }}
          </h4>
        }
        @if (file.progress >= 100) {
          <h4 class="name" >
            {{ file?.name }} - Uploaded
          </h4>
        }
        <p class="size">
          {{ file?.size | byteCount }} @if (file?.failed) {
          <span i18n >Upload has failed...</span>
        }
      </p>
      <app-upload-progress [upFile]="file"></app-upload-progress>
    </div>
    <button (click)="deleteFile(i)" mat-icon-button><mat-icon>delete</mat-icon></button>
    <!--img src="assets/img/dnd/ic-delete-file.svg" class="delete" width="20px" alt="file" (click)="deleteFile(i)"-->
  </div>
}
</div>
