/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { Component } from '@angular/core';
import { RoleHomePage, RoleHomePageComponent } from '../role-home-page/role-home-page.component';

@Component({
  selector: 'app-owners-component',
  standalone: true,
  imports: [RoleHomePageComponent],
  templateUrl: './owners.component.html',
})
export class OwnersHomePageComponent {
    role: RoleHomePage = {
        title: 'Owners are the heart of the OMC',
        mainText: `<p>
            What makes an OMC different to any other type of company is the members.
            An OMC is not owned by a for profit company, it is run purely for the benefit of the members,
            the owners of the units within the development.
        </p>`,
        lottieUrl: 'https://clgassets.fra1.cdn.digitaloceanspaces.com/lottie-files/members.json',
        beforeText: `<p>
            In the bad old days, members got to "voice their opinions" once a year at the AGM.
            Once a year, members received a copy of the accounts and a "directors report".
            Most members did not even realise, they were actually the owners of the management company.
        </p>`,
        afterText: `<p> <b>OurOMC puts members at the heart of everything we do. </b>
        There are lots of accounting systems for companies, but none that allow members to decide
        how the company should be run. None that provide members with visibility into how their money is being spent.
    </p>`,
        benefitTitle: 'Benefits for Members',
        prevPageTitle: 'Residents',
        prevPageRoute: '/residents',
        nextPageTitle: 'Directors',
        nextPageRoute: '/directors',
        benefits: [{
            icon: 'forum',
            head: 'Communications & Voting',
            text: ' - The owning members have all the benefits of the posts, discussion, voting, document and request management features that can be offered to residents. But as members of the company, they have much broader visibility of data.',
        },{
            icon: 'business',
            head: 'Budget Details',
            text: `- Members have full visibility of all the details of the budget.
                    What the planned spend is by expense category and lease schedule.
                    What's increased or decreased on last year? How does it compare to last years actual spend?
                    Members know, before they approve the budget, how much their individual service charges will be.`,
        },{
            icon: 'account_balance',
            head: 'Detailed Unit Accounts',
            text: `Members can view their full account history back to the day they became an owner.
                    Every service charge levied, every payment made to the OMC.
                    They can see how much they need to setup their standing order to clear their balance.
                    Click a button to make immediate payments and/or setup direct debits`,
        },{
            icon: 'live_help',
            head: 'Request Management',
            text: `See something needs doing?
                    Create a request as quick as a snap of your camera and let the OMC know what needs doing`,
        },{
            icon: 'visibility_lock',
            head: 'Right people see the right data.',
            text: `OurOMC allows the system to be configured
                    so that residents, owners, directors and agents all see the data appropriate to them.
                    Directors can decide which audience any post, document or vote should be shared with.
                    Who can vote, who can comment, who can post, who can create requests can all be controlled.`,
        }]
    }
}
