<div style="height: 50px; width: 50px">
@if (myUser?.avatar) {
  <img class="user"
    src="/api/api/avatars/{{myUser.avatar}}" [matTooltip]="myUser.name"/>
} @else if (myUser) {
  <div>
    <img class="user"
        src="https://eu.ui-avatars.com/api/?background=e6fff9&color=004d3b&name={{myUser.name}}" [matTooltip]="myUser.name"/>
  </div>
}
</div>
