import { Component, inject } from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MyInjector } from 'src/app/app.module';
import { Billing } from 'src/app/model/billing';
import { Unit } from 'src/app/model/unit';
import { UnitService } from 'src/app/modules/unit/unit.service';
import { FormComboBoxComponent } from 'src/app/shared/form/form-combo-box/form-combo-box.component';
import { ActionColor, IFormAction } from 'src/app/shared/form/form.component';
import { FormConfig } from 'src/app/shared/form/FormConfig';
import { CtlHolderComponent } from "../../../../shared/form/ctl-holder/ctl-holder.component";
import { required } from 'src/app/shared/validators';
import { MatButtonModule } from '@angular/material/button';
import { BillingPageComponent } from '../billing-page.component';
import { map } from 'rxjs/operators';
import { CurrentUserService } from 'src/app/modules/user/current-user.service';
import { User } from 'src/app/model/user';

export class AddUnitAction implements IFormAction {
    name = $localize`Show Unit`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'add_circle';
    approvalNeeded = false;
    approvalText: string;
    tipText = $localize`Pick unit to show charges for`;

    billing: Billing;
    bpc: BillingPageComponent;
    currentUserSvc = MyInjector.instance.get(CurrentUserService);

    constructor(bpc: BillingPageComponent) {
        this.bpc = bpc;
    }

    action(billing: Billing, config: FormConfig) {
        console.log(billing, config);
        const dialog = MyInjector.instance.get(MatDialog);

        const dialogRef = dialog.open(ShowUnitBillingComponent);

        return dialogRef.afterClosed().pipe<Billing>( map((u: Unit) => {
            if (u) {
                this.bpc.units.push(u);
                this.bpc.scheduleGrid.control.setColumns(); // show the extra unit columns
                return this.billing;
            }
            return this.billing;
        }));
    }

    setup(billing: Billing) {
        this.billing = billing;
        const priv = User.privilege(this.currentUserSvc.currentUser, 'Unit')
        if ( priv?.getIndex ) {
            this.show = true;
        } else {
            this.show = false;
        }
    }
}

@Component({
  selector: 'app-show-unit-billing',
  standalone: true,
  imports: [MatDialogModule, CtlHolderComponent, MatButtonModule],
  templateUrl: './show-unit-billing.component.html',
  styleUrl: './show-unit-billing.component.scss'
})
export class ShowUnitBillingComponent {

    dialogRef = inject(MatDialogRef);
    unitField = FormComboBoxComponent.make('Unit', 'unitId', null,
        {
            items: [],
            optionDisplayValue: u => Unit.getFullName(u),
        }, {
            validators: [required],
        }
    ).setupControl();

    constructor(private unitSvc: UnitService) {
        unitSvc.get(true).subscribe(units => {
            this.unitField.picklist.items = units;
        })
    }

    addUnit() {
        this.unitSvc.getOne(this.unitField.control.value, null).subscribe( u => {
            this.dialogRef.close(u);
        })
    }

}
