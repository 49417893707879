/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { Component } from '@angular/core';
import { RoleHomePage } from '../role-home-page/role-home-page.component';
import { RoleHomePageComponent } from "../role-home-page/role-home-page.component";

@Component({
  selector: 'app-accountants-component',
  standalone: true,
  imports: [RoleHomePageComponent],
  templateUrl: './accountants.component.html',
})
export class AccountantsHomePageComponent {
    role: RoleHomePage = {
        title: `Accountants`,
        mainText: `<p>
            Every multi unit dwelling with 5 or more units is required by law to register as a company
            and make annual returns. The accounting functions are a critical part of running your OMC.
        </p>`,
        lottieUrl: 'https://clgassets.fra1.cdn.digitaloceanspaces.com/lottie-files/accountants.json',
        prevPageTitle: 'Agents',
        prevPageRoute: '/agents',
        nextPageTitle: 'Contractors/Caretakers',
        nextPageRoute: '//contractors',
        beforeText: `<p>.</p><p>
            In the bad old days, the accounts were produced somewhere off in the dark,
            where no one could see what was going on until everything was finished.
            Not because people were secretive, but because that was the nature of the accounting tools they used.
            After a lot of toing and froing between the agent and the accountant,
            the official report is produced. Eventually, it gets to the directors.
            Usually far too late to be of any use to them in decision making.
        </p>`,
        afterText: `<p>
            OurOMC allows everyone to collaborate online.
            The accountant, the agent, the directors and even the member
            if you want them to, can login and see the full accounting details, in real time!
        </p>`,
        benefitTitle: `Accounting Benefits`,
        benefits: [{
            icon: 'real_estate_agent',
            head: 'Full Service Charge Accounting',
            text: `
                Allows a budget to be produced, reviewed, approved and sent to the members.
                Full support for single or multi schedule charges.
                Receipts are recorded in the system, and allocated against charges.
            `,
        },{
            icon: 'account_balance',
            head: 'Automated Banking Feeds',
            text: ` OurOMC connects to all of the major Irish Banks, and pulls in transactions automatically.
                    You know that every financial transaction has been pulled into the accounts and reconciled.
                    `,
        },{
            icon: 'shopping_baskets',
            head: 'Purchases',
            text: `
                OurOMC allows all purchases to be entered against one or more budget codes and indeed
                against the correct lease schedules so that every purchase can be tied back to the annual budget.
                All purchases have scanned copies of the invoices attached for easy auditability.
                The system learns as you enter invoices, making it easier to code the next invoice from the same supplier.
            `,
        },{
            icon: 'receipt_long',
            head: 'Financial Reporting',
            text: `
                OurOMC provides full financial accounting.
                Trial balance, Profit/Loss and Balance Sheet are all instantly available.
                Accounting periods can be closed, whilst reports are being produced and evaluated.
                Corrections can easily, and auditably, be corrected until the accounting periods are fully locked.
            `,
        },{
            icon: 'groups',
            head: 'Collaborative Accounting ',
            text: `
                Accountants, Agents and Directors all have online access to the accounts.
            `,
        }
    ]
    }
}
