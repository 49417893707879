<div formHolder  [style.max-height.px]="formHeight" style="padding: 20px" class="limit-height">
    <div class="main">
        <div class="item" style="max-width:330px;">
            <mat-card style="padding:20px; margin-bottom:20px">
            <h1>Service Request Details</h1>
            <div style="width:100%; display:flex">
                <div style="flex:1; padding-right: 3px">
                    <p class="label">Reference</p>
                    <p>{{fullRequest?.refNr}}</p>
                    <p class="label">Status</p>
                    <p><span [ngClass]="fullRequest?.srStatus">{{fullRequest?.srStatus}}</span></p>
                </div>
                <div style="flex:1; padding-left: 3px">
                    <p class="label">Created</p>
                    <p>{{fullRequest?.createdAt | fromNow}}</p>
                    <p class="label">Last Updated</p>
                    <p>
                        @if (fullRequest?.updatedAt) {
                            {{fullRequest?.updatedAt | fromNow}}
                        }
                    </p>
                </div>
            </div>
            <p class="label">Title</p>
            <p>{{fullRequest?.title}}</p>
            <p class="label">Description</p>
            <p [innerHTML]="descriptionText"></p>

            @if (fullRequest?.attachments?.length > 0) {
                <p class="label">Attachments</p>
                <ul style="margin-top: 2px">
                    @for (a of fullRequest.attachments; track $index) {
                        <li>
                            <a [href]="'/api/api/crm/requests/attach/' + a.id">{{a.name}}</a>
                        </li>
                    }
                </ul>
            }
            <app-ctl-holder [control]="attachmentGrid.control"></app-ctl-holder>
            </mat-card>

            <ng-container [ngTemplateOutlet]="addPersonTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="reopenTemplate"></ng-container>

            @if (!isPhone) {
                <div style="margin-top:20px">
                    <ng-container [ngTemplateOutlet]="listRequests"></ng-container>
                </div>

            }

        </div>
        <div class="item">
            <div class="chatDiv">
                <div style="overflow: auto;" class="limit-height">
                    <app-ctl-holder [control]="chatField.control"></app-ctl-holder>
                </div>
            </div>

        </div>
            @if (isPhone) {
                <div style="margin-top:10px; margin-bottom: 20px; width: 330px; margin-left:auto; margin-right:auto">
                    <ng-container [ngTemplateOutlet]="listRequests"></ng-container>
                </div>
            }
    </div>

</div>
<ng-template #reopenTemplate>
    @if (fullRequest?.srStatus === 'resolved') {
    <mat-card class="action">
        <div class="action">
            <p>This request will automatically be accepted if not reopened before {{fullRequest.completedAt}}</p>
            <div>
            <button mat-raised-button color="accent" (click)="reopenRequest()">Reopen</button>
            <button mat-raised-button color="primary" style="margin-top: 5px"
            (click)="acceptRequest()">Accept</button>
            </div>

        </div>
    </mat-card>
    }
</ng-template>

<ng-template #addPersonTemplate>
    @if (isUnitOwnerRequest && fullRequest.srStatus === 'open') {
        <mat-card class="action">
            <div class="action">
                <p>Add {{fullRequest.data['person']['firstName']}}
                    {{fullRequest.data['person']['lastName']}} to Unit?</p>
                <button mat-raised-button color="primary" (click)="addToUnit()">Yes</button>
                <button mat-raised-button color="warn">No</button>
            </div>
        </mat-card>
    }
</ng-template>
<ng-template #listRequests>
    <mat-card style="margin-top: 10px; padding: 20px">
        <h3>Your Other Open Requests</h3>
        @if (openRequests.length > 1) {
            <ul style="margin-top: -10px">
                @for (r of openRequests; track $index) {
                    @if (r.id !== fullRequest.id) {
                        <li>
                            <a [routerLink]="'/myRequests/' + r.id">{{r.refNr}}</a> {{r.title}}
                        </li>
                    }
                }
            </ul>
        } @else {
            <p style="padding-left: 20px">This your only open request</p>
        }
    </mat-card>
</ng-template>
