/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormRichTextComponent } from 'src/app/shared/form/form-rich-text/form-rich-text.component';
import { RequestService } from '../../request.service';
import { MatButtonModule } from '@angular/material/button';
import { MessageService } from 'src/app/shared/message.service';
import { CtlHolderComponent } from "../../../../shared/form/ctl-holder/ctl-holder.component";
import { required } from 'src/app/shared/validators';

@Component({
  selector: 'app-reopen-request',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, CtlHolderComponent],
  templateUrl: './reopen-request.component.html',
  styleUrl: './reopen-request.component.scss'
})
export class ReopenRequestComponent {
    dialogRef = inject(MatDialogRef<ReopenRequestComponent>);
    sr = inject(MAT_DIALOG_DATA);
    srSvc = inject(RequestService);
    msgSvc = inject(MessageService);

    reasonField = FormRichTextComponent.make('Why do we need to reopen this request?', 'reason', {
        validators: [required]
    }).setupControl();

    reopenRequest() {
        const t = this.reasonField.control.value;
        this.srSvc.reopenRequest(this.sr, t).subscribe(response => {
            if (response) {
                this.dialogRef.close(response);
            }
        }, error => this.msgSvc.showError(error, 'Resolving Request'));
    }
}
