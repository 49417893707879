<div class="confirmDialog">
    <div style="padding: 10px">
        <h1 mat-dialog-title>{{data.title}}</h1>
        <mat-dialog-content>
            <pre>{{data.content | json}}</pre>
        </mat-dialog-content>
        <div mat-dialog-actions align="center">
            <button mat-button [mat-dialog-close]="true">close</button>
        </div>
    </div>
</div>
