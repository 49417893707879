@if (on === 'form') {
  <mat-form-field style="height: 74px">
    @if (control.field.hint) {
        <mat-icon matSuffix color="primary" [matTooltip]="control.field.hint">info</mat-icon>
    }
    <mat-label>{{control.field.label}}</mat-label>
    <input matInput [formControl]="control" [autocomplete]="control.field.autoComplete" type="email" email
       [readonly]="control.field.readonly ? '' : null">
    <mat-error style="margin-top: -5px">
      <app-form-error [(control)]="control"></app-form-error>
    </mat-error>
  </mat-form-field>
}
@if (on === 'grid') {
  <input matInput [formControl]="control" type="email" email
    [matTooltip]="control.getErrorMessage()"
    [ngClass]="{'error': control.invalid && control.touched}"
    [autocomplete]="control.field.autoComplete"
    [readonly]="control.field.readonly ? '' : null">
}
