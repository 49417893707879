<!--mat-card class="basecard"-->
@if (isMandate) {
  <mat-card appearance="raised">
    <h1 class="mat-headline-5">Confirmation of mandate for future payment</h1>
    @if (sessionId && !payzChecked) {
      <div class="inProgress">
        <p>Checking status of session '{{sessionId}}' please wait</p>
      </div>
    }
    @if (sessionId && payzChecked) {
      <div>
        @if (payzMandate && payzMandate.completed) {
          <div class="inProgress">
            <p>{{payzMandate.description}}</p>
          </div>
        }
        @if (payzMandate && !payzMandate.completed) {
          <div class="inProgress">
            <h2 class="mat-h2" i18n>Warning - Mandate was never completed</h2>
          </div>
        }
        @if (!payzMandate) {
          <div class="inProgress">
            <h2 class="mat-h2" i18n>Error - Unknown Session {{sessionId}}</h2>
          </div>
        }
      </div>
    }
  </mat-card>
}
@if (!isMandate) {
  <mat-card appearance="raised">
    <h1 class="mat-headline-5">Payment Confirmation</h1>
    @if (sessionId && !payzChecked) {
      <div class="inProgress">
        <p>Checking status of session '{{sessionId}}' please wait</p>
      </div>
    }
    @if (sessionId && payzChecked) {
      <div>
        @if (payz && payz.completed) {
          <div class="inProgress">
            <h2 class="mat-h2" i18n>Payment instruction received successfully - Once confirmed will be reflected in your account balance</h2>
          </div>
        }
        @if (payz && !payz.completed) {
          <div class="inProgress">
            <h2 class="mat-h2" i18n>Warning - Payment was never completed</h2>
          </div>
        }
        @if (!payz) {
          <div class="inProgress">
            <h2 class="mat-h2" i18n>Error - Unknown Session {{sessionId}}</h2>
          </div>
        }
      </div>
    }
  </mat-card>
}
