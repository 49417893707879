/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { HttpClient } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { NavRoute } from 'src/app/shared/NavRoute';
import { CurrentUserService } from '../../../user/current-user.service';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { Cycle } from 'src/app/model/cycle';
import { BCode } from 'src/app/model/bcode';
import { AbstractObject, uuid } from 'src/app/model/abstract-object';
import { BCodePageComponent } from '../../../budget/b-code-page/b-code-page.component';
import { TxnPageComponent } from '../../txn-page/txn-page.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { FormCheckboxComponent } from 'src/app/shared/form/form-checkbox/form-checkbox.component';
import { ActivatedRoute, Params, Router, RouterLink } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { NgTemplateOutlet, CurrencyPipe } from '@angular/common';
import { CtlHolderComponent } from '../../../../shared/form/ctl-holder/ctl-holder.component';
import { MatButtonModule } from '@angular/material/button';
import { TrialBalanceImportComponent } from '../trial-balance-import/trial-balance-import.component';

type Balance = { bCodeId: uuid, credits: number, debits: number, count: number }
export type TrialBalanceApiResponse = { cycle: Cycle, cycles: Cycle[], balances: Balance[], bcodes: BCode[], types: AbstractObject[] }

@Component({
    selector: 'app-trial-balance',
    templateUrl: './trial-balance.component.html',
    styleUrls: ['../balances.scss'],
    standalone: true,
    imports: [CtlHolderComponent, NgTemplateOutlet, MatCardModule, RouterLink, CurrencyPipe, MatButtonModule]
})
export class TrialBalanceComponent {

    static readonly navRoute = new NavRoute('txn/trialBalance', TrialBalanceComponent, 'balance');

    private http = inject(HttpClient);
    private currentUserSvc = inject(CurrentUserService);
    private activeRoute = inject(ActivatedRoute);
    private router = inject(Router);

    companyName: string
    cycle: Cycle = null;
    bcodes: BCode[] = [];
    balances: Balance[];
    types: AbstractObject[] = BCode.TYPES;
    ready = false;
    hideZero = true;
    myData: TrialBalanceApiResponse;

    totalDebits = 0;
    totalCredits = 0;
    totalCount = 0;

    displayedColumns: string[] = ['name', 'sort', 'count', 'credits', 'debits'];

    cycleField = FormPicklistComponent.make('Cycle', 'cycleId', null, {items: [],
        refreshes: [(cycle: Cycle) => {
            this.navigateTo(cycle.id);
        }]
    }).setupControl();

    fromField = FormDateComponent.make('From', 'from', {readonly: true, disable: true}).setupControl();
    toField = FormDateComponent.make('To', 'to', { readonly: true, disable: true }).setupControl();
    zerosField = FormCheckboxComponent.make('Hide Zeros', 'hideZeros', {
        valueChanges: (value) => {
            this.hideZero = value;
            this.refreshData();
        }
    }).setupControl()

    constructor() {
        this.activeRoute.params.subscribe(params => this.setUp(params));
        this.zerosField.control.setValue(true, {emitEvent: false});
        this.currentUserSvc.getCurrentUser().subscribe(user => {
            this.companyName = user.currentTeam.legalName;
            if (this.activeRoute?.snapshot?.params.cycleId) {
                this.getData(this.activeRoute.snapshot.params.cycleId);
            } else {
                this.getData(user.currentTeam.currentPeriod.cycleId);
            }
        })
    }

    private setUp(parms: Params) {
        if (parms.cycleId) {
            this.getData(parms.cycleId);
        }
    }
    private navigateTo(cycleId: uuid) {
        this.router.navigate([TrialBalanceComponent.navRoute.url, { cycleId }]);
    }

    gotoImport() {
        this.router.navigate([TrialBalanceImportComponent.navRoute.getIdUrl(this.myData.cycle.id)]);
    }

    canDeactivate() {
        return true;
    }

    getData(cycleId: uuid) {
        // URL reused in trialBalanceImport
        const url = AbstractHttpService.ajaxPath + 'txn/trialBalance/' + cycleId;

        this.http.get<TrialBalanceApiResponse>(url).subscribe(result => {
            this.myData = result;
            this.cycleField.setPicklistItems(this.myData.cycles);
            this.cycleField.control.setValue(this.myData.cycle.id, { emitEvent: false });
            this.fromField.control.setValue(this.myData.cycle.from, { emitEvent: false })
            this.toField.control.setValue(this.myData.cycle.to, { emitEvent: false })
            this.refreshData();
        })
    }

    refreshData() {
        this.cycle = this.myData.cycle
        Cycle.warnOpenPeriods(this.cycle);
        this.balances = this.myData.balances
        this.bcodes = [];

        this.totalDebits = 0;
        this.totalCredits = 0;
        this.totalCount = 0;

        this.balances.forEach(b => {
            if (+b.debits > +b.credits) {
                this.totalDebits += (+b.debits - +b.credits);
            } else {
                this.totalCredits += (+b.credits - +b.debits);
            }
            this.totalCount += b.count;
        })

        this.myData.bcodes.forEach((bc: BCode) => {
            const bal = this.balances.find(o => o.bCodeId === bc.id);
            bc.debits = 0;
            bc.credits = 0;
            bc.count = 0;
            if (bal) {
                if (+bal.debits > +bal.credits) {
                    bc.debits = bal.debits - bal.credits;
                } else {
                    bc.credits = bal.credits - bal.debits;
                }
                bc.count = bal.count;
            }
            bc.type = this.myData.types.find(o => o.id === bc.typeId);

            if (!this.hideZero || bc.count > 0) {
                this.bcodes.push(bc);
            }
        });

        this.types.forEach(type => {
            type['bcodes'] = this.bcodes.filter(o => o.typeId == type.id);
        })
    }

    getAccountTxn(bcode: BCode) {
        if (bcode && this.cycle) {
            return ['/' + TxnPageComponent.navRoute.url, { bCodeId: bcode.id, txnCycleId: this.cycle.id }]
        }
    }

    getAccountLink(bcode: BCode) {
        return BCodePageComponent.navRoute.getIdUrl(bcode.id);
    }

}
