/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent } from '@angular/material/dialog';
import { ChatItem } from 'src/app/model/chat-item';
import { Follower } from 'src/app/model/user';
import { Field } from 'src/app/shared/field/Field';
import { AppFormControl } from '../../form/app-form-control';
import { FormRichTextComponent } from '../../form/form-rich-text/form-rich-text.component';
import { IsNarrowService } from '../../is-narrow.service';
import { ChatField } from '../chat-field';
import { MatButtonModule } from '@angular/material/button';
import { ChatReplyComponent } from '../chat-reply/chat-reply.component';
import { ChatFollowersComponent } from '../chat-followers/chat-followers.component';
import { MatIconModule } from '@angular/material/icon';
import { CtlHolderComponent } from "../../form/ctl-holder/ctl-holder.component";

export interface ChatUpsertParameters {
    chatField: ChatField;
    replyTo: ChatItem;
    height?: number;
    width?: number;
}
@Component({
    selector: 'app-chat-upsert',
    templateUrl: './chat-upsert.component.html',
    styleUrls: ['./chat-upsert.component.scss'],
    standalone: true,
    imports: [MatDialogContent, MatIconModule, ChatFollowersComponent, ChatReplyComponent, FormRichTextComponent, MatButtonModule, CtlHolderComponent]
})
export class ChatUpsertComponent implements OnInit {

    width = 500;
    height = 300;

    constructor(@Inject(MAT_DIALOG_DATA) public data: ChatUpsertParameters,
        private isNarrowSvc: IsNarrowService,
        private dialogRef: MatDialogRef<ChatUpsertComponent>) {

    }

    commentControl: AppFormControl = FormRichTextComponent.make('Comment', 'comment').makeControl() as AppFormControl;

    getTitle() {
        return 'Add new comment';
    }

    ngOnInit(): void {
        this.width = this.data.width ? this.data.width : this.isNarrowSvc.screenWidth * .75;
        this.height = this.data.height ? this.data.height : this.isNarrowSvc.screenHeight * .65;
    }

    closeDialog() {
        console.log('Close Dialog');
        this.dialogRef.close(null);
    }

    saveComment() {
        const chat = this.data.chatField.chat;
        if (!Field.isEmpty(this.commentControl.value)) {
            const newItem = { content: this.commentControl.value };
            if (this.data.replyTo) {
                newItem['parentId'] = this.data.replyTo.id;
            }
            chat.service.postChatItem(newItem, chat.parentId, true, chat.teamId).subscribe(comment => {
                if (comment?.id) {
                    comment.replies = []; // new comment has no replies
                    if (this.data.replyTo) { // might need to push it into parent...
                        comment.parent = this.data.replyTo;
                        if (!Array.isArray(this.data.replyTo.replies)) {
                            this.data.replyTo.replies = [];
                        }
                        this.data.replyTo.replies.push(comment);
                    }
                    chat.hierarchyItems.push(comment);
                    chat.comments.push(comment);
                    if (!chat.followers.find((f) => f.userId === comment.authorId)) {
                        chat.followers.push({
                            userId: comment.authorId,
                        } as Follower);
                    }
                    this.closeDialog();
                }
            })
        }
    }
}
