/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { combineLatest, of } from 'rxjs';
import { Action } from 'src/app/model/action';
import { Field } from 'src/app/shared/field/Field';
import { RequestPriority } from 'src/app/model/RequestPriority';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { DateTimeField, FormDateTimeComponent } from 'src/app/shared/form/form-date-time/form-date-time.component';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { FormDurationComponent } from 'src/app/shared/form/form-duration/form-duration.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormTextAreaComponent } from 'src/app/shared/form/form-text-area/form-text-area.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { required } from 'src/app/shared/validators';
import { UserService } from '../../user/user.service';
import { ActionService } from '../action.service';
import { OmcService } from '../../agent/omc.service';
import { User } from 'src/app/model/user';
import { Omc } from 'src/app/model/Omc';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { TrendField } from 'src/app/shared/field/TrendField';
import { ActionTemplatesService } from '../action-templates.service';
import { CurrentUserService } from '../../user/current-user.service';
import { NavRoute } from 'src/app/shared/NavRoute';
import { charts, views } from './action-page-nav-config';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { ServiceRequestPageComponent } from '../service-request-page/service-request-page.component';
import { RequestTemplateComponent } from '../request-template/request-template.component';
import { FormRichTextComponent } from 'src/app/shared/form/form-rich-text/form-rich-text.component';
import { IsNarrowService } from 'src/app/shared/is-narrow.service';
import { ImageUploadRequestAction } from '../service-request-page/image-upload-request/image-upload-request.component';

@Component({
    selector: 'app-action-page',
    templateUrl: './action-page.component.html',
    styleUrls: ['./action-page.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class ActionPageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('crm/actions', ActionPageComponent, 'assignment_turned_in').setCharts(charts).setViews(views);

    currentUser: User;
    users: User[] = [];
    omcs: Omc[] = [];

    omcFieldSearch = FormPicklistComponent.make('Owner Management Company', 'omcId', 'omc',
        { items: this.omcs }, { readonly: true, visible: Field.noShow }
    );
    omcField = FormTextComponent.make('Owner Management Company', 'omcName', {readonly: true, allowFiltering: false});

    plannedField = FormPicklistComponent.make('Planned', 'planned', '',
        { items: [{ id: 0, name: 'No' }, { id: 1, name: 'Yes' }] },
        { visible: Field.noShow, calculateValue: (o) => (o as Action).plannedDate ? 'Yes' : 'No' }
    );

    doneField = FormPicklistComponent.make('Done', 'done', '',
        { items: [{ id: 0, name: 'No' }, { id: 1, name: 'Yes' }], blockFilterNone: true },
        { visible: Field.noShow, calculateValue: (o) => (o as Action).completedAt ? 'Yes' : 'No' }
    );

    titleField = FormTextComponent.make('Title', 'title', { validators: [required], cellOpts: { width: '25%' }, formColumn: 2 });

    createdAtField: DateTimeField = (FormDateTimeComponent.make('Created', 'createdAt',
        { visible: Field.formOnly, formColumn: 4, disable: true }) as DateTimeField
    );

    ownerReadAtField: DateTimeField = (FormDateTimeComponent.make('Owner Read', 'ownerReadAt',
        { visible: Field.formOnly, formColumn: 4, disable: true, sendServer: false }) as DateTimeField
    );

    createdByField = FormPicklistComponent.make('Created By', 'createdById', null, { items: this.users },
        { formColumn: 4, disable: true }
    );

    completedAtField: DateTimeField = (FormDateTimeComponent.make('Completed', 'completedAt',
        { visible: Field.formOnly, formColumn: 2, disable: true }) as DateTimeField
    );

    completedByField = FormPicklistComponent.make('Completed By', 'completedById', 'completedBy',
        { items: this.users },
        { formColumn: 4, cellOpts: { minWidth: '60px' } }
    );
    tableDoneField = FormButtonComponent.make('Done', 'completedAtTableShow', {
        type: 'icon',
        calculateValue: (action) => (action as Action).completedAt ? 'check_box_outline' : 'check_box_outline_blank',
        btnOpts: { tableClickMethod: this.markActionCompleteFromTable.bind(this)},
        visible: Field.noForm,
        allowFiltering: false,
    });

    formDoneField = FormButtonComponent.make('Done', 'completedAtFormShow', {
        type: 'icon',
        calculateValue: (action) => (action as Action).completedAt ? 'check_box_outline' : 'check_box_outline_blank',
        btnOpts: { clickMethod: this.markActionComplete.bind(this) },
        visible: Field.formOnly,
        formColumn: 4
    });

    propertyManagerField = FormPicklistComponent.make('Property Manager', 'omc.managerId', null,
        { items: this.users },
        { formColumn: 1, readonly: true, sendServer: false, calculateValue: o => (o as Action).omc?.managerId }
    );

    templateField = FormPicklistComponent.make('Template Action', 'templateId', null,
        { service: this.tmplService },
        { formColumn: 1, readonly: true, sendServer: false, visible: Field.noShow }
    );

    ownedByField = FormPicklistComponent.make('Owned By', 'ownedById', 'ownedBy', { items: this.users },
        { formColumn: 2, cellOpts: { minWidth: '60px' } }
    );

    plannedDateField = FormDateComponent.make('Planned Date', 'plannedDate', { formColumn: 2 });

    timeSpentField = FormDurationComponent.make('Time Spent', 'timeSpent', { formColumn: 4 });

    weekField = TrendField.make('Week Completed', 'weekCompleted', { cellOpts: { heading: 'Week' } });

    requestField = FormButtonComponent.makeNavDetailButton('Request',
        (o: Action) => o.request?.refNr + ' ' + o.request?.title.trim(),
        'requestId',
        ServiceRequestPageComponent.navRoute,
        true
    );

    templateLinkField = FormButtonComponent.makeNavDetailButton('Template',
        (o:Action) => (o.templateId ? o.template?.name : 'n/a'),
        'templateId',
        RequestTemplateComponent.navRoute
    );

    unitField = FormButtonComponent.makeNavDetailButton('Unit', 'unitName', 'unitId',
        RequestTemplateComponent.navRoute, true
    );

    personField = FormButtonComponent.makeNavDetailButton('Person', 'personFullName', 'personId',
        RequestTemplateComponent.navRoute, true
    );

    descriptionField = FormRichTextComponent.make('Description', 'description', { cellOpts: { width: '50%' },
        formColumn: 3, visible: Field.formOnly
    })

    tmplInstructionField = FormTextAreaComponent.make('Template Instructions', 'template.description',
        {
            cellOpts: { width: '50%' }, formColumn: 3, disable: true,
            sendServer: false, visible: Field.formOnly
        }
    );

    config = new FormConfig({
        navRoute: ActionPageComponent.navRoute,
        title: $localize`ToDo Actions`,
        help: $localize`Priority for dealing with requests`,
        allowNew: false,
        fieldSet: new FieldSet({
            fields: [
                FieldMaker.id(),
                FieldMaker.rev(),
                this.titleField,
                this.omcField,
                this.omcFieldSearch,
                this.requestField,

                this.timeSpentField,
                this.tableDoneField,
                this.formDoneField,
                this.doneField,

                this.descriptionField,
                this.tmplInstructionField,
                this.ownedByField,
                this.plannedDateField,
                this.completedAtField,

                this.createdAtField,
                this.createdByField,
                this.ownerReadAtField,

                this.completedByField,
                this.plannedField,
                this.weekField,
                this.templateField,
                this.templateLinkField,
                this.propertyManagerField,
                this.personField,
                this.unitField,
            ],
            formLayout: [{ cells: [{ width: '25%' }, { width: '25%' }, { width: '38%' }, { width: '12%' }] }],
        }),
        service: this.dataSvc,
        mode: 'list',
        objectFactory: () => of(new RequestPriority()),
        beforeEdit: this.beforeEdit.bind(this),
        configReady: this.configReady,
        tableHighlighter: (o) => (o as Action).ownerReadAt ? false : true,
        afterSave: this.afterSave.bind(this),
        actions: [new ImageUploadRequestAction(false)]
    });

    constructor(public dataSvc: ActionService, currentUserSvc: CurrentUserService,
        teamUserSvc: UserService, omcSvc: OmcService, private tmplService: ActionTemplatesService,
        private cds: ConfirmDialogService, private isNarrowSvc: IsNarrowService) {
        super();
        isNarrowSvc.detectVeryNarrow().subscribe( isPhone => {
            this.createdAtField.visible = isPhone ? Field.noShow : Field.showAll;
            this.createdByField.visible = isPhone ? Field.noShow : Field.showAll;
            this.ownerReadAtField.visible = isPhone ? Field.noShow : Field.showAll;
            this.templateLinkField.visible = isPhone ? Field.noShow : Field.showAll;
            this.completedByField.visible = isPhone ? Field.noShow : Field.showAll;
            //this.tableDoneField.visible = isPhone ? Field.noShow : Field.formOnly;
        })
        combineLatest([currentUserSvc.getCurrentUser(), teamUserSvc.get(), omcSvc.get()]).subscribe(
            ([currentUser, users, omcs]) => {
                this.currentUser = currentUser;
                for (const o of omcs) {
                    this.omcs.push(o as Omc);
                }
                for (const u of users as User[]) {
                    this.users.push(u);
                }

                this.configReady.next(null);
            }
        );
        //            ChartComponent.make('new', () => [], {} ),
        // ChartComponent.make('new 2', () => [], {} )
    }

    afterSave() {
        if (this.isNarrowSvc.isPhone()) {
            history.back();
        }
    }
    beforeEdit(a: Action) {
        if (a.completedAt) {
            this.formDoneField.visible = Field.noShow;
            this.config.allowEdit = false;
            this.config.readonly = true;
        } else {
            this.formDoneField.visible = Field.formOnly;
            this.config.allowEdit = true;
            this.config.readonly = false;
        }
        if (a.templateId) {
            this.templateLinkField.visible = Field.noShow;
            this.tmplInstructionField.visible = Field.noShow;
        } else {
            this.templateLinkField.visible = Field.formOnly;
            this.tmplInstructionField.visible = Field.formOnly;
        }
        return a;
    }

    markActionComplete() {
        this.cds.open('Confirm Completed',
            'Save action and mark completed? You cannot undo this action', () => {
                this.completedAtField.setToCurrentDateTime();
                this.completedByField.control.setValue(this.currentUser.id);
                this.page.form.doSaveChanges();
            });
    }
    
    markActionCompleteFromTable(action: Action) {
        if (!action.completedAt) {
            this.cds.open('Confirm Completed',
                'Mark this action completed? You cannot undo this action',
                () => {
                    this.dataSvc.complete(action, this.currentUser).subscribe(result => {
                        if (result) {
                            action.completedAt = result.completedAt;
                            action.completedById = result.completedById;
                        }
                    })
                }
            );
        } else {
            this.cds.alert('Cannot Mark Undone', 'Cannot undo completing an action, please add a comment to it or create a replacement action');
        }
    }

}
