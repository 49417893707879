/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { formatCurrency } from "@angular/common";
import { Injectable } from "@angular/core";
import { forkJoin } from "rxjs";
import { first, map } from "rxjs/operators";
import { BCode } from "src/app/model/bcode";
import { Schedule } from "src/app/model/schedule";
import { Txn } from "src/app/model/txn";
import { Unit } from "src/app/model/unit";
import { FormPicklistComponent } from "src/app/shared/form/form-picklist/form-picklist.component";
import { required } from "src/app/shared/validators";
import { BCodeService } from "../../budget/bcode.service";
import { ScheduleService } from "../../budget/schedule.service";
import { TxnImportChecker, TxnService } from "../../txn/txn.service";
import { UnitService } from "../../unit/unit.service";
import { BatchType, ImportParser } from '../import-page/Import-parser-interface';
import { ImportDoc, ImportDocNote } from "../ImportDoc";
import { ImportField } from "../ImportField";
import { ImportInput } from "../ImportInput";
import { ImportRow } from "../ImportRow";
import { MyInjector } from "src/app/app.module";
import { AbstractObjectList, uuid } from "src/app/model/abstract-object";
import { Period } from "src/app/model/period";
import { PeriodService } from "../../budget/period.service";
import { ConfirmDialogService } from "src/app/shared/dialogs/confirmDialog";
import { PicklistField } from "src/app/shared/field/PicklistField";
import { AbstractHttpService } from "src/app/shared/abstract-http.service";
import { HttpClient } from "@angular/common/http";

class LineItem {
    debit: number;
    credit: number;
    txnDate: string;
    msgCode: string;
    balance: string;
    description: string;
    prefix: string;
    comment: string;
    schedule: string;
    billing: string;
    txnTypeId: uuid;
    txnType: string;
}
type BillingMap = {name: string, billingId: string|null; txnDate: string, schedule: string, scheduleId: uuid; bCodeId?: uuid, txns: number, debits: number, credits: number, matchCount: boolean };

type ScheduleMap = {txns: number, debits: number, credits: number, rows: unknown[], schedulePL?: PicklistField, bcodePL?: PicklistField }

class CheckTotal {
    total = 0;
    toImport = 0;
    value = 0;
    valueToImport = 0;
    note = new ImportDocNote();

    constructor(private type: 'Invoices'|'Actions'|'Receipts'|'Credits') {
    }

    zero() {
        this.total = 0;
        this.toImport = 0;
        this.value = 0;
        this.valueToImport = 0;        
    }

    setNote() {
        if (this.type === 'Actions') {
            this.note.note = `${this.type} ${this.total} (${this.toImport} For Import)`;
        } else {
            const cValue = formatCurrency(this.value, 'EN-ie', '€');
            const iValue = formatCurrency(this.valueToImport, 'EN-ie', '€');
            this.note.note = `${this.type} ${this.total} - ${cValue} (${this.toImport} - ${iValue} For Import)`;
        }        
    }

}

@Injectable({
    providedIn: 'root'
})
export class BM5ParserService implements ImportParser {

    units: Unit[];
    schedules: Schedule[];
    bCodes: BCode[];
    periods: Period[];

    existingData: TxnImportChecker;
    batchType?: BatchType = 'BM5';

    parsed = false;

    checks = {
        billingEntries: 0,
        credits: new CheckTotal('Credits'),
        receipts: new CheckTotal('Receipts'),
        invoices: new CheckTotal('Invoices'),
        actions: new CheckTotal('Actions'),
        nettTransactions: 0,
        balance: 0,
        totalOutstanding: 0,
        adHocInvoices: 0,
    }

    ownerChanges = 0;
    plannedTxn = 0;

    adHocNotes = new ImportDocNote();
    billingNote = new ImportDocNote();
    periodNote = new ImportDocNote();
    badTxnDates : string[] = [];

    scheduleNote = new ImportDocNote();
    badSchedules : string[] = [];
    bCodeNote = new ImportDocNote();
    badBCodes: string[] = [];
    scheduleMap : Map<string, ScheduleMap>;
    billingMap: Map<string, BillingMap> = new Map();
    matchedBilling: Map<string, BillingMap> = new Map();

    importDoc: ImportDoc = new ImportDoc();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rowsIn: any[][] = [];

    constructor(
        private unitSvc: UnitService,
        private scheduleSvc: ScheduleService,
        private bCodeSvc: BCodeService,
        private txnSvc: TxnService,
        private periodSvc: PeriodService,
        private cds: ConfirmDialogService,
    ) {

    }
    setUp() {
        return forkJoin({
            units: this.unitSvc.get(true).pipe(first()),
            schedules: this.scheduleSvc.get(true).pipe(first()),
            bCodes: this.bCodeSvc.get(true).pipe(first()),
            importChecks: this.txnSvc.getImportChecks(),
            periods: this.periodSvc.get<Period>(true)
        }).pipe(map(result => {
            this.units = result.units as Unit[];
            this.schedules = result.schedules as Schedule[];
            this.bCodes = result.bCodes as BCode[];
            this.periods = result.periods,
            this.existingData = result.importChecks;
            return true;
        }));
    }

    parseOnceCheck() {
        if (this.parsed) {
            this.cds.alert('Need Reload', 'Need to refresh your browser to reset this import');
            throw "Needs Reload";
        }
        this.parsed = true;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseRows(rows: any[][]): ImportDoc {
        this.parseOnceCheck();
        this.rowsIn = rows;
        try {
            this.getHeaderRow().parse(this.rowsIn[0]);

            let startFrom = 1;
            let unitsDone = 0;
            while (startFrom < (rows.length - 1)) {
                startFrom = this.getUnitTxns(startFrom);
                unitsDone++;
                if (unitsDone > 10000) {
                    console.warn('ONLY Did ', {unitsDone});
                    break;
                }
            }
            this.getInputs();

        } catch (e) {
            console.error(e);
        }
        if (this.importDoc.getRows().length === 0) {
            this.importDoc.add(new ImportRow([new ImportField('No Data To Import').setValue('At All')]))
        }
        this.importDoc.finalise();

        return this.importDoc;
    }

    sortByDate(a, b) {
        return a.txnDate.localeCompare(b.txnDate)
    }
    getReceiptData(itemList) {
        return itemList.filter(f => f.txnTypeId === Txn.TYPE.BANK_IN.id).sort( this.sortByDate.bind(this));
    }
    getTxnData(itemList) {
        const filter = f => f.txnTypeId === Txn.TYPE.INVOICE.id || f.txnTypeId === Txn.TYPE.CREDIT_NOTE.id
        return itemList.filter(filter).sort(this.sortByDate.bind(this));
    }
    getActionData(itemList) {
        const filter = f => f.txnTypeId === 'ACTION'
        return itemList.filter(filter).sort(this.sortByDate.bind(this));
    }

    postToServer() {
        const rows = this.importDoc.getRows();
        const itemList = [];
        rows.forEach(uRow => {
            const unit = uRow.getDataAsItem();
            itemList.push(unit);
        });
        // JSON Stringify On Map returns empty object, need to translarte manually...
        const bms = {};
        this.matchedBilling.forEach( (v, k) => bms[k]=v);

        const postData = {
            checks: this.checks,
            billings: bms,
            receipts: this.getReceiptData(itemList),
            txns: this.getTxnData(itemList),
            actions: this.getActionData(itemList),
        }
        console.log(postData);

        const url = AbstractHttpService.ajaxPath + 'importFile/' + this.batchType;
        const http = MyInjector.instance.get(HttpClient);
        return http.post<AbstractObjectList>(url, postData);
    }


    getInputs() {
        const c = this.checks;
        c.invoices.setNote();
        c.credits.setNote();
        c.receipts.setNote();
        c.actions.setNote();  
        this.importDoc.addNoteItem(c.invoices.note)
        this.importDoc.addNoteItem(c.credits.note)
        this.importDoc.addNoteItem(c.receipts.note)
        this.importDoc.addNoteItem(c.actions.note)
        this.importDoc.addNoteItem(this.periodNote);
        this.importDoc.addNoteItem(this.scheduleNote);
        this.importDoc.addNoteItem(this.bCodeNote);
        this.importDoc.addNoteItem(this.billingNote);
        this.importDoc.addNoteItem(this.adHocNotes);
        this.checkSchedules();
        this.checkBillings();
        
        c.nettTransactions = c.invoices.valueToImport - c.credits.valueToImport - c.receipts.valueToImport;
        c.balance = c.totalOutstanding - this.existingData.outstanding - c.nettTransactions;

        console.log({ checks: c, previousOS: this.existingData.outstanding });

        const msg = `Owner Changes: ${this.ownerChanges} Reminders: ${c.actions.toImport}`;
        this.importDoc.addNote(msg);

        const fc = {
            newTotalOutstanding: formatCurrency(c.totalOutstanding, 'EN-ie', '€'),
            prevTotalOutstanding: formatCurrency(this.existingData.outstanding, 'EN-ie', '€'),
            balance: formatCurrency(c.balance, 'EN-ie', '€')
        }
        this.importDoc.addNote(`New total Outstanding Amounted to ${fc.newTotalOutstanding}`);
        this.importDoc.addNote(`Previous Total Outstanding Amounted to ${fc.prevTotalOutstanding}`);

        if (Math.abs(c.balance) > 0.001) {
            console.log(this.checks);
            this.importDoc.addNote(`Transaction Tally differs by ${fc.balance}`, null, true);
        } else {
            const tTxns = c.invoices.toImport + c.credits.toImport + c.receipts.toImport;
            if ((tTxns) === 0 && c.actions.toImport === 0) {
                this.importDoc.addNote(`No new data to import`,null, true);
            } else if (tTxns === 0) {
                this.importDoc.addNote(`No new transactions to import, only ${c.actions.toImport} actions`);
            } else {
                this.importDoc.addNote(`New transaction tally, good to import ${tTxns} transactions`);
            }
        }
        this.validateData();
    }

    checkSchedules() {
        this.scheduleMap = new Map();
        for (const row of this.importDoc.getRows()) {
            const schedule = row.getValue('schedule');
            if (schedule) {
                if (this.scheduleMap.has(schedule as string)) {
                    const map = this.scheduleMap.get(schedule as string);
                    map.txns += 1;
                    map.debits += row.getValue('debit') as number;
                    map.credits += row.getValue('credit') as number;
                    map.rows.push(row.getDataAsItem());
                } else {
                    this.scheduleMap.set(schedule as string, {
                        txns: 1,
                        debits: row.getValue('debit') as number,
                        credits: row.getValue('credit') as number,
                        rows: [row.getDataAsItem()]
                    }
                    );
                }
            }
        }
        
        let cnt = 0;
        this.scheduleMap.forEach((value, key) => {
            cnt++;
            const credits = formatCurrency(value.credits, 'EN-ie', '€');
            const debits = formatCurrency(value.debits, 'EN-ie', '€');
            //const hint = `<ul><li>${cnt} : ${key}: Includes ${value.txns} transactions</li><li>Debit: ${debits}</li><li>Credit: ${credits}</li></ul>`;
            //const hint = `<table style='width:100%'><tr><td style='width:100%; text-align:right'>${cnt} : ${key}: Includes ${value.txns} transactions</td><td>Debit: ${debits}</td><td>Credit: ${credits}</td></td></table>`;
            let hint = `${cnt} : ${key}: Includes ${value.txns} transactions`;
            if (value.debits) hint += ', debits: ' + debits;
            if (value.credits) hint += ', credits: ' + credits;

            //. ${debits}</li><li>Credit: ${credits}</li></ul>`;


            this.assignSchedulePickmaps(value);
            this.importDoc.addInput(new ImportInput(key as string, [value.schedulePL, value.bcodePL], hint));
        })
    }

    assignSchedulePickmaps(sm: ScheduleMap) {
        sm.bcodePL = FormPicklistComponent.make("Budget Code", "Budget Code", null,
            { items: this.getIncomeBCodes(), refreshes: [this.validateData.bind(this)] },
            { validators: [required] });

        sm.schedulePL = FormPicklistComponent.make("Schedule", "Schedule", null,
            {
                items: this.schedules, refreshes: [
                    (s: Schedule) => { sm.bcodePL.control.setValue(s.incomeBCodeId) },
                    this.validateData.bind(this)
                ]
            }
        );
    }

    private resetValidation() {
        this.badTxnDates = [];
        this.badSchedules = [];
        this.badBCodes = [];
    }
    private reportErrors() {
        this.reportPeriodErrors();
        this.reportScheduleErrors();
        this.reportBCodeErrors();
    }
    private reportPeriodErrors() {
        if (this.badTxnDates.length === 0) {
            this.periodNote.note = "All Periods are good";
            this.periodNote.needsFix = false;
        } else {
            this.periodNote.note = "Dates " + this.badTxnDates.join(', ') + " have closed or missing periods";
            this.periodNote.needsFix = true;
        }
    }
    private reportScheduleErrors() {
        if (this.badSchedules.length === 0) {
            this.scheduleNote.note = "All schedules are good";
            this.scheduleNote.needsFix = false;
        } else {
            this.scheduleNote.note = "Schedules " + this.badSchedules.join(', ') + " are not matched";
            this.scheduleNote.needsFix = true;
        }
    }
    private reportBCodeErrors() {
        if (this.badBCodes.length === 0) {
            this.bCodeNote.note = "All budget codes are good";
            this.bCodeNote.needsFix = false;
        } else {
            this.bCodeNote.note = "Budget Codes " + this.badBCodes.join(', ') + " are not matched";
            this.bCodeNote.needsFix = true;
        }
    }
    private validatePeriod(row: ImportRow) {
        const txnDate = row.getStringValue('txnDate');
        if ((this.badTxnDates.indexOf(txnDate) < 0) && !Period.getPeriod(txnDate, this.periods)) {
            this.badTxnDates.push(txnDate);
        }
    }
    private getScheduleId(sName: string) : string {
        const sm = this.scheduleMap.get(sName);
        return sm.schedulePL.control.value;
    }

    private getBCodeId(sName: string) : string {
        const sm = this.scheduleMap.get(sName);
        return sm.bcodePL.control.value;
    }

    private validateSchedule(row: ImportRow) {
        const sName = row.getStringValue('schedule');
        const tTypeId = row.getNrValue('txnTypeId');
        if (tTypeId === Txn.TYPE.INVOICE.id || tTypeId === Txn.TYPE.CREDIT_NOTE.id) {
            if (!this.scheduleMap.has(sName)) {
                if (this.badSchedules.indexOf(sName) < 0) {
                    this.badSchedules.push(sName);
                }
            } else {
                const smScheduleId = this.getScheduleId(sName);
                if (smScheduleId) {
                    row.getField('scheduleId').setValue(smScheduleId)
                } else {
                    if ((this.badSchedules.indexOf(sName) < 0)) {
                        this.badSchedules.push(sName);
                    }
                }
            }
        }
    }

    private validateBilling(row: ImportRow) {
        const sName = row.getStringValue('billing') + ':' + row.getValue('txnDate');
        const tTypeId = row.getNrValue('txnTypeId');
        if (tTypeId === Txn.TYPE.INVOICE.id || tTypeId === Txn.TYPE.CREDIT_NOTE.id) {
            const f = row.getField('billingId');
            const bm = this.billingMap.get(sName)
            if (bm && this.matchedBilling.has(bm.billingId)) {
                f.setValue(bm.billingId);
            } else {
                f.setValue(null);
            }
        }
    }    

    private validateBCode(row: ImportRow) {
        const sName = row.getStringValue('schedule');
        const tTypeId = row.getNrValue('txnTypeId');
        if (tTypeId === Txn.TYPE.INVOICE.id || tTypeId === Txn.TYPE.CREDIT_NOTE.id) {
            if (!this.scheduleMap.has(sName)) {
                if (this.badBCodes.indexOf(sName) < 0) {
                    this.badBCodes.push(sName);
                }
            } else {
                const sm = this.scheduleMap.get(sName);
                const bCodeId = sm.bcodePL.control.value;
                if (bCodeId) {
                    row.getField('bCodeId').setValue(bCodeId)
                } else {
                    if ((this.badBCodes.indexOf(sName) < 0)) {
                        this.badBCodes.push(sName);
                    }
                }
            }
        }
    }

    private summariseBillings() {
        
        this.matchedBilling = new Map();
        this.plannedTxn = 0;
        this.checks.adHocInvoices = 0;
        this.checks.billingEntries = 0;

        this.billingMap.forEach ( (bm) => {
            const smScheduleId = this.getScheduleId(bm.schedule);
            const bCodeId = this.getBCodeId(bm.schedule);
            const schedule = this.schedules.find( s => s.id === smScheduleId );
            if (schedule && schedule.portions_count === bm.txns) {
                bm.matchCount = true;
                bm.scheduleId = smScheduleId;
                bm.bCodeId = bCodeId;
                this.plannedTxn += bm.txns;
                this.matchedBilling.set(bm.billingId, bm);
                this.checks.billingEntries += 1;
            } else {
                this.checks.adHocInvoices += bm.txns;
            }
        })

        this.adHocNotes.note = `${this.checks.adHocInvoices} Ad Hoc Transactions`;
        let bTotal = 0;
        this.billingNote.clearChildren();
        this.matchedBilling.forEach((bm) => {
            bTotal += 1;
            const credits = formatCurrency(bm.credits, 'EN-ie', '€');
            const debits = formatCurrency(bm.debits, 'EN-ie', '€');
            const t = `Billing: ${bm.billingId} ${bm.name} - ${bm.txnDate}: ${bm.txns} txns, ${debits}, Credits ${credits}`
            this.billingNote.addChildNote(t);
        })
        this.billingNote.note = `${bTotal} Billing Entries totalling ${this.plannedTxn} will be generated`;        
    }

    private validateData() {
        this.resetValidation();
        this.importDoc.getGeneralRows().forEach(row => {
            this.validatePeriod(row);
            this.validateSchedule(row);
            this.validateBCode(row);
        });
        this.summariseBillings();
        this.importDoc.getGeneralRows().forEach(row => {
            this.validateBilling(row);
        });
        this.reportErrors();
    }

    private getIncomeBCodes() {
        return this.bCodes.filter(bc => bc.typeId === BCode.TYPE.INCOME.id);
    }

    checkBillings() {
        this.billingMap = new Map();
        for (const row of this.importDoc.getRows()) {
            const bName = row.getStringValue('billing');
            const tDate = row.getStringValue('txnDate');
            const billing = bName + ':' + tDate;
            if (bName) {
                if (this.billingMap.has(billing)) {
                    const map = this.billingMap.get(billing);
                    map.txns += 1;
                    map.debits += row.getValue('debit') as number;
                    map.credits += row.getValue('credit') as number;
                } else {
                    this.billingMap.set(billing, {
                        name: bName,
                        billingId: 'B' + this.billingMap.size,
                        txnDate: tDate,
                        txns: 1,
                        schedule: row.getValue('schedule') as string,
                        scheduleId: null,
                        debits: row.getValue('debit') as number,
                        credits: row.getValue('credit') as number,
                        matchCount: false
                    });
                }
            }
        }
    }

    readItem(data: ImportRow) : LineItem {
        const description = data.getValue('description') as string;
        const prefix = description;
        const comment = description;
        const breakPt = description.indexOf(':');

        return {
            debit: data.getValue('debit') as number,
            credit: data.getValue('credit') as number,
            txnDate: data.getValue('txn_date'),
            msgCode: data.getValue('Message Code'),
            balance: formatCurrency(data.getValue('balance') as number, 'EN-ie', '€'),
            description: data.getValue('description') as string,
            prefix: breakPt >= 0 ? description.substring(0, breakPt) : prefix,
            comment: breakPt >= 0 ? description.substring(breakPt + 2) : comment,
            schedule: null,
            billing: null,
            txnTypeId: null,
            txnType: '',
        } as LineItem;
    }

    getTxnDetails(row: number, unit: Unit) {
        const data = this.getTxnFields().parse(this.rowsIn[row]);
        const item = this.readItem(data);

        if (item.debit === 0 && item.credit === 0) {
            if (item.msgCode === 'POS') { // Previous Owner Statement
                this.createOwnerChange(unit, item);
            } else { // Reminders...
                this.createAction(unit, item);
            }
        } else if (item.description.substring(0, 3) === 'By ') {
            this.createReceipt(unit, item);
        } else if (item.debit > 0) {
            this.createInvoice(unit, item);
        } else if (item.credit > 0) {
            this.createCredit(unit, item);
        }
    }

    private createOwnerChange(unit: Unit, item: LineItem) {
        item.txnType = 'Previous Owner';
        item.txnTypeId = 'ACTION'; // For now create these as actions...
        item.credit = 0;
        item.debit = 0;
        item.description = item.comment;
        item.description = `${item.description} type ${item.msgCode} for balance ${item.balance}`;
        this.checks.actions.total += 1;
        this.addItemRow(unit, item);
        this.ownerChanges += 1;
    }

    private createAction(unit: Unit, item: LineItem) {
        item.txnType = 'ACTION';
        item.txnTypeId = 'ACTION';
        item.credit = 0;
        item.debit = 0;
        item.description = item.comment;
        item.description = `${item.description} type ${item.msgCode} for balance ${item.balance}`;
        this.checks.actions.total += 1;
        this.addItemRow(unit, item);
    }
    private createReceipt(unit: Unit, item: LineItem) {
        item.txnType = Txn.TYPE.BANK_IN.name;
        item.txnTypeId = Txn.TYPE.BANK_IN.id;
        item.credit = item.credit - item.debit;
        item.debit = 0;

        this.checks.receipts.total += 1;
        this.checks.receipts.value += item.credit;

        this.addItemRow(unit, item);
    }

    private createInvoice(unit: Unit, item: LineItem) {
        item.txnTypeId = Txn.TYPE.INVOICE.id;
        item.txnType = Txn.TYPE.INVOICE.name;
        item.schedule = item.prefix;
        item.billing = item.comment + ':' + item.prefix;
        item.description = item.billing;
        this.checks.invoices.total += 1;
        this.checks.invoices.value += item.debit;        
        this.addItemRow(unit, item);
    }

    private createCredit(unit: Unit, item: LineItem) {
        item.txnTypeId = Txn.TYPE.CREDIT_NOTE.id;
        item.txnType = Txn.TYPE.CREDIT_NOTE.name;
        item.schedule = item.prefix;
        item.billing = item.comment + ':' + item.prefix;
        item.description = item.billing;

        this.checks.credits.total += 1;
        this.checks.credits.value += item.credit;

        this.addItemRow(unit, item);
    }

    private addItemRow(unit: Unit, item: LineItem) {
        const newRow = new ImportRow([
            new ImportField('unitId').setValue(unit?.id),
            new ImportField('ownerId').setValue(unit?.ownerId),
            new ImportField('unit').setValue(unit?.name),
            new ImportField('txnDate').setValue(item.txnDate),
            new ImportField('txnType').setValue(item.txnType),
            new ImportField('txnTypeId').setValue(item.txnTypeId),
            new ImportField('schedule').setValue(item.schedule),
            new ImportField('scheduleId').setValue(null),
            new ImportField('bCode').setValue(null),
            new ImportField('bCodeId').setValue(null),
            new ImportField('billing').setValue(item.billing),
            new ImportField('billingId').setValue(null),
            new ImportField('debit').asCurrency().setValue(item.debit),
            new ImportField('credit').asCurrency().setValue(item.credit),
            new ImportField('amount').asCurrency().setValue(item.debit - item.credit),
            new ImportField('notes').setValue(item.description),
        ]);

        this.addNewLine(newRow);
    }

    private getUnitTxns(startFrom: number) {
        ImportRow.Blank(1).parse(this.rowsIn[startFrom++]);

        if (this.rowsIn[startFrom].length === 1) {
            return startFrom; // we are done!
        }
        this.getUnitHeaderLabelRow().parse(this.rowsIn[startFrom++]);

        ImportRow.Blank(1).parse(this.rowsIn[startFrom++]);

        const unitDtls = this.getUnitHeaderRow().parse(this.rowsIn[startFrom++]);
        const unitIn = unitDtls.getValue('Unit Code');
        const unit = this.units.find(u => u.name === unitIn);
        this.checks.totalOutstanding += unitDtls.getValue('Outstanding') as number;

        if (!unit) {
            unitDtls.addError('Unit code not found ' + unitIn);
            this.importDoc.add(unitDtls);
        }

        ImportRow.Blank(1).parse(this.rowsIn[startFrom++]);
        this.getTxnHeaderFields().parse(this.rowsIn[startFrom++]);

        ImportRow.Blank(1).parse(this.rowsIn[startFrom++]);
        while (this.rowsIn[startFrom].length > 1 && this.rowsIn[startFrom][1]) {
            this.getTxnDetails(startFrom, unit);
            startFrom++;
            //this.rowsOut.push(this.getTxnFields().parse(this.rowsIn[startFrom++]));
        }

        ImportRow.Blank(1).parse(this.rowsIn[startFrom++]);
        this.getSummaryFields().parse(this.rowsIn[startFrom++]);
        ImportRow.Blank(1).parse(this.rowsIn[startFrom++]);

        return startFrom;
    }

    private getHeaderRow(): ImportRow {
        const row = new ImportRow();

        row.add(new ImportField('date_generated'));
        row.add(new ImportField('file_type').allow(new Map(
            [
                ['Block Unit Data (Part 5 of 5)', 'blockman5_txn_file'],
                ['Block Unit Data (Part 5 of 5) Current Year', 'blockman5_txn_file']
            ])).require());
        row.add(new ImportField('team_name').require());
        row.add(new ImportField('legal_name').require());

        return row;
    }

    private getUnitHeaderLabelRow() {
        const row = new ImportRow();
        row.add(new ImportField('F/Y From').allow(new Map([['F/Y From', 'ok']])).require());
        row.add(new ImportField('ID').allow(new Map([['ID', 'ok']])).require());
        row.add(new ImportField('Unit Code').allow(new Map([['Unit Code', 'ok']])).require());
        row.add(new ImportField('Unit Name').allow(new Map([['Unit Name', 'ok']])).require());
        row.add(new ImportField('Owner Name').allow(new Map([['Owner Name', 'ok']])).require());
        row.add(new ImportField('Email').allow(new Map([['Email', 'ok']])).require());
        row.add(new ImportField('Phone Numbers').allow(new Map([['Phone Numbers', 'ok']])).require());
        row.add(new ImportField('Address').allow(new Map([['Address', 'ok']])).require());
        row.add(new ImportField('Outstanding').allow(new Map([['Outstanding', 'ok']])).require());
        return row;
    }

    private getUnitHeaderRow() {
        const row = new ImportRow();
        row.add(new ImportField('F/Y From').require());
        row.add(new ImportField('ID').require());
        row.add(new ImportField('Unit Code').require());
        row.add(new ImportField('Unit Name').require());
        row.add(new ImportField('Owner Name').require());
        row.add(new ImportField('Email'));
        row.add(new ImportField('Phone Numbers'));
        row.add(new ImportField('Address').require());
        row.add(new ImportField('Outstanding').asCurrency().require());
        return row;
    }

    getTxnHeaderFields(): ImportRow {
        const row = new ImportRow();

        row.add(new ImportField('Period').allow(new Map([['Period', 'ok']])).require());
        row.add(new ImportField('Date').allow(new Map([['Date', 'ok']])).require());
        row.add(new ImportField('Description').allow(new Map([['Description', 'ok']])).require());
        row.add(new ImportField('Message Code').allow(new Map([['Message Code', 'ok']])).require());
        row.add(new ImportField('Statement Number').allow(new Map([['Statement Number', 'ok']])).require());
        row.add(new ImportField('Debit').allow(new Map([['Debit', 'ok']])).require());
        row.add(new ImportField('Credit').allow(new Map([['Credit', 'ok']])).require());
        row.add(new ImportField('Balance').allow(new Map([['Balance', 'ok']])).require());

        return row;
    }

    getTxnFields(): ImportRow {
        const row = new ImportRow();

        row.add(new ImportField('Period'));
        row.add(new ImportField('txn_date').fromBMDate().require());
        row.add(new ImportField('description').require());
        row.add(new ImportField('Message Code'));
        row.add(new ImportField('Statement Number'));
        row.add(new ImportField('debit').asCurrency());
        row.add(new ImportField('credit').asCurrency());
        row.add(new ImportField('balance').asCurrency().require());

        return row;
    }

    getSummaryFields(): ImportRow {
        const row = new ImportRow();

        row.add(new ImportField('filler1').expectEmpty());
        row.add(new ImportField('filler2').expectEmpty());
        row.add(new ImportField('filler3').expectEmpty());
        row.add(new ImportField('filler4').expectEmpty());
        row.add(new ImportField('filler5').expectEmpty());
        row.add(new ImportField('total_debit').asCurrency().require());
        row.add(new ImportField('total_credit').asCurrency().require());

        return row;
    }

    private addNewLine(newRow: ImportRow) {

        const amount = newRow.getNrValue('amount');

        if (newRow.getValue('txnTypeId') === Txn.TYPE.BANK_IN.id) {
            if (!this.checkReceiptImported(newRow)) {
                this.checks.receipts.toImport += 1;
                this.checks.receipts.valueToImport -= amount;
                this.importDoc.add(newRow);
            }
        } else if (newRow.getValue('txnTypeId') === Txn.TYPE.INVOICE.id) {
            if (!this.checkInvoiceImported(newRow)) {
                this.checks.invoices.toImport += 1;
                this.checks.invoices.valueToImport += amount;
                this.importDoc.add(newRow);
            }
        } else if (newRow.getValue('txnTypeId') === Txn.TYPE.CREDIT_NOTE.id) {
            if (!this.checkCreditImported(newRow)) {
                this.checks.credits.toImport += 1;
                this.checks.credits.valueToImport -= amount;
                this.importDoc.add(newRow);
            }
        } else {
            if (!this.checkActionImport(newRow)) {
                this.checks.actions.toImport += 1;
                this.importDoc.add(newRow);
            }
        }
    }
    private checkActionImport(i: ImportRow) {
        const actions = this.existingData.actions;
        const exists = actions.find(a => {
            if (a.unitId === i.getValue('unitId')
                && a.plannedDate === i.getValue('txnDate')
                && a.title === 'Reminder Sent') {
                return true;
            } else {
                return false;
            }
        });
        if (exists) {
            return true;
        } else {
            return false;
        }        
    }
    private checkReceiptImported(i: ImportRow) {
        const txns = this.existingData.receipts;
        const exists = txns.find(t => {
            if (t.unitId === i.getValue('unitId')
                && t.txnDate === i.getValue('txnDate')
                && (Math.abs(t.credit - (i.getValue('credit') as number) + (i.getValue('debit') as number)) < .0001)) {
                return true;
            } else {
                return false;
            }
        });
        if (exists) {
            return true;
        } else {
            return false;
        }
    }

    private checkInvoiceImported(i: ImportRow) {
        const txns = this.existingData.invoices;
        const exists = txns.find(t => {
            if (t.typeId === Txn.TYPE.INVOICE_ITEM.id
                && t.unitId === i.getValue('unitId')
                && t.txnDate === i.getValue('txnDate')
                && (Math.abs(t.credit - (i.getValue('debit') as number) + (i.getValue('credit') as number)) < .0001)) {
                return true;
            } else {
                return false;
            }
        });
        if (exists) {
            return true;
        } else {
            return false;
        }
    }

    private checkCreditImported(i: ImportRow) {
        const txns = this.existingData.credits;
        const exists = txns.find(t => {
            if (t.typeId === Txn.TYPE.CREDIT_NOTE_ITEM.id
                && t.unitId === i.getValue('unitId')
                && t.txnDate === i.getValue('txnDate')
                && (Math.abs(t.debit - (i.getValue('credit') as number) + (i.getValue('debit') as number)) < .0001)) {
                return true;
            } else {
                return false;
            }
        });
        if (exists) {
            return true;
        } else {
            return false;
        }
    }
}
