/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { combineLatest } from 'rxjs';
import { ServiceRequest } from 'src/app/model/serviceRequest';

import { User } from 'src/app/model/user';
import { NavRoute } from 'src/app/shared/NavRoute';
import { CurrentUserService } from '../../user/current-user.service';
import { MyRequestService } from '../my-request.service';
import { MyRequestComponent } from '../my-request/my-request.component';
import { MyRequestsComponent } from '../my-requests/my-requests.component';
import { NewUserRequestDetailComponent } from '../new-user-request-detail/new-user-request-detail.component';
import { MatTabsModule } from '@angular/material/tabs';
import { IsNarrowService } from 'src/app/shared/is-narrow.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { NgTemplateOutlet } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-personal-requests',
    templateUrl: './personal-requests.component.html',
    styleUrls: ['./personal-requests.component.scss'],
    standalone: true,
    imports: [MatTabsModule, NewUserRequestDetailComponent, MyRequestsComponent,
        MyRequestComponent, MatExpansionModule, MatIconModule, NgTemplateOutlet,
        RouterLink, MatCardModule]
})
export class PersonalRequestsComponent {

    currentUser: User;
    tabIndex = 0;
    isPhone = false;
    selectedRequest: ServiceRequest;
    myRequests: ServiceRequest[] = [];
    openRequests: ServiceRequest[] = [];
    acceptRequests: ServiceRequest[] = [];
    closedRequests: ServiceRequest[] = [];
    currentPanel : 'new'|'open'|'toaccept'|'closed';

    static readonly navRoute = new NavRoute('myRequests', PersonalRequestsComponent, 'live_help')
        .setItemComponent(MyRequestComponent);

    constructor(private currentuserSvc: CurrentUserService, private myRequestService: MyRequestService,
    activeRoute: ActivatedRoute, private router: Router, isNarrowSvc: IsNarrowService) {
        isNarrowSvc.detectVeryNarrow().subscribe(result => { this.isPhone = result; });
        this.currentuserSvc.getCurrentUser().subscribe( currentUser => this.currentUser = currentUser);
        combineLatest([
            this.myRequestService.get(false),
            activeRoute.params
        ]).subscribe( ([requests, params]) => {
            this.myRequests = (requests as ServiceRequest[]);
            this.openRequests = this.myRequests.filter( o => o.srStatus === ServiceRequest.srOpen.id);
            this.acceptRequests = this.myRequests.filter(o => o.srStatus === ServiceRequest.srResolved.id);
            this.closedRequests = this.myRequests.filter(o => o.srStatus === ServiceRequest.srAccepted.id);
            if (params.itemId) {
                this.showRequest(this.myRequests.find( o => o.id === params.itemId));
            }
        })
    }

    canDeactivate() {
        return true;
    }
    
    selectTab($event) {
        this.tabIndex = $event.index;
    }

    showRequest(sr: ServiceRequest) {
        console.log({sr, srs: this.myRequests});
        this.selectedRequest = sr;
        this.tabIndex = 4;
    }

    selectRequest(sr: ServiceRequest) {
        const url = PersonalRequestsComponent.navRoute.getIdUrl(sr?.id);
        this.router.navigate([url]);
    }
}
