/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { AbstractObject, uuid } from './abstract-object';
import { Schedule } from './schedule';

export class BCodeSchedule extends AbstractObject {
  bCodeId: uuid;
  scheduleId: uuid;
  schedule: Schedule;
  percent = 1.000; // : number;

  constructor(o: Partial<BCodeSchedule> = {}) {
    super(o);
  }
}
