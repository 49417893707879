/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { formatDate, NgTemplateOutlet, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router, RouterLink } from '@angular/router';
import * as Highcharts from 'highcharts';
import moment from 'moment';
import { of, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Ballot, Option, Question, Vote } from 'src/app/model/ballot';
import { Field } from 'src/app/shared/field/Field';
import { Forum } from 'src/app/model/forum';
import { Person } from 'src/app/model/person';
import { Privilege } from 'src/app/model/role';
import { User } from 'src/app/model/user';
import { PersonService } from 'src/app/pages/person-page/person.service';
import { ChartComponent, ChartDataItem } from 'src/app/shared/chart/chart.component';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { EditDialogComponent } from 'src/app/shared/dialogs/edit-dialog/edit-dialog.component';
import { TableDialogComponent } from 'src/app/shared/dialogs/table-dialog/table-dialog.component';
import { AppFormControl } from 'src/app/shared/form/app-form-control';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { FormComboBoxComponent } from 'src/app/shared/form/form-combo-box/form-combo-box.component';
import { FormDateTimeComponent } from 'src/app/shared/form/form-date-time/form-date-time.component';
import { AppPicklistControl, FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormRichTextComponent } from 'src/app/shared/form/form-rich-text/form-rich-text.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { GridField } from 'src/app/shared/grid/grid-field';
import { IsNarrowService } from 'src/app/shared/is-narrow.service';
import { greaterThen, lessThen, maxChars, minChars, required } from 'src/app/shared/validators';
import { CurrentUserService } from '../../user/current-user.service';
import { UserService } from '../../user/user.service';
import { BallotService } from '../ballot-page/ballot.service';
import { QuestionService } from '../question-page/question.service';
import { VoteService } from '../vote.service';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag } from '@angular/cdk/drag-drop';
import { HistoryComponent } from 'src/app/shared/form/history/history.component';
import { uuid } from 'src/app/model/abstract-object';
import { BallotPageComponent } from '../ballot-page/ballot-page.component';
import { AttachmentGridComponent } from 'src/app/shared/form/file/attachment-grid/attachment-grid.component';
import { ChatComponent } from 'src/app/shared/chat/chat-component/chat.component';
import { FromNowPipe } from 'src/app/shared/pipes/from-now.pipe';
import { AvatarComponent } from '../../user/avatar/avatar.component';
import { FormGroup, FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { CtlHolderComponent } from '../../../shared/form/ctl-holder/ctl-holder.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommsTemplateService } from '../comms-template-page/comms-templates.service';
import { CommsTemplate } from 'src/app/model/CommsTemplate';
import { Area } from 'src/app/model/area';
import { AreaService } from '../../unit/area.service';
import { SimpleRequestComponent } from '../../crm/simple-request/simple-request.component';
import { ServiceRequestPageComponent } from '../../crm/service-request-page/service-request-page.component';
import { ServiceRequest } from 'src/app/model/serviceRequest';
import { MatTabsModule } from '@angular/material/tabs';
import { DateHelper } from 'src/app/shared/dateHelper';

@Component({
    selector: 'app-ballot-editor',
    templateUrl: './ballot-editor.component.html',
    styleUrls: ['./ballot-editor.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, MatCardModule, NgTemplateOutlet,
        CtlHolderComponent, MatExpansionModule, CdkDropList, CdkDrag, MatTooltipModule,
        MatRadioModule, FormsModule, AvatarComponent, DatePipe, FromNowPipe, RouterLink, MatTabsModule]
})
export class BallotEditorComponent implements OnInit {

    ballot: Ballot;
    ballotPrivs: Privilege = new Privilege();
    questionPrivs: Privilege = new Privilege();

    allowEdit = false;
    canComment = false;
    canVote = false;

    showRequests = true;
    addRequests = false;

    selectedQuestion: Question = null;
    isNarrow: boolean;
    currentUser: User = new User();
    users: User[] = [];
    Person = Person;
    DateNow = Date;

    forceTeamId: uuid;
    currentTeamName = "";

    commsTemplates : CommsTemplate[] = [];
    commsTemplate : CommsTemplate;
    commsTemplateId : uuid;

    attachmentGrid = AttachmentGridComponent.make(this.ballotSvc);
    attachmentControl = this.attachmentGrid.makeControl();

    questionAttachmentGrid = AttachmentGridComponent.make(this.questionSvc);
    questionAttachmentControl = this.questionAttachmentGrid.makeControl();

    areas: Area[] = [];
    areaField = FormPicklistComponent.make('Area', 'areaId', 'area',
        { refreshes: ['coreId'], items: this.areas, allowSelectNone: true }, { formColumn: 1 });
    coreField = FormPicklistComponent.make('Core', 'coreId', null, { items: [], allowSelectNone: true }, {
        formColumn: 1,
        refresh: (o: Area, control: AppPicklistControl) => {
            if (o) {
                control.field.setPicklistItems(o.cores);
                if (control.value && !o.cores.find(c => c.id === control.value)) {
                    control.setValue(null);
                }
            }
        }
    });

    dateHelper = DateHelper;

    constructor(private ballotSvc: BallotService, private questionSvc: QuestionService,
        private voteSvc: VoteService, private personSvc: PersonService,
        private activeRoute: ActivatedRoute,
        private router: Router, private dialog: MatDialog,
        private currentUserSvc: CurrentUserService,
        userSvc: UserService, areaSvc: AreaService,
        private cds: ConfirmDialogService,
        private commsTmplSvc : CommsTemplateService,
        private isNarrowService: IsNarrowService) {

        isNarrowService.detectVeryNarrow().subscribe(result => { this.isNarrow = result; });
        userSvc.getUsers().pipe(first()).subscribe(users => this.users = users as User[]);

        areaSvc.get<Area>(true).subscribe(areas => {
            this.areas = areas
            this.areaField.setPicklistItems(areas);
        })
        currentUserSvc.getCurrentUser().subscribe(user => {
            if (user) {
                this.currentUser = user;
                if (!this.forceTeamId) {
                    this.currentTeamName = user.currentTeam.name;
                }
                if (this.ballot) {
                    this.loadBallot(this.ballot);
                }
                this.setPrivs();
            }
        })
    }

    setPrivs() {
        if (this.forceTeamId) {
            const agentRole = User.getAgentRole(this.currentUser, this.forceTeamId);
            this.currentTeamName = agentRole.omcName;
            this.ballotPrivs = User.agentPrivilege(this.currentUser, 'Ballot', this.forceTeamId);
            this.questionPrivs = User.agentPrivilege(this.currentUser, 'Question', this.forceTeamId);

        } else {
            this.ballotPrivs = User.privilege(this.currentUser, 'Ballot');
            this.questionPrivs = User.privilege(this.currentUser, 'Question');
        }
    }

    ngOnInit(): void {
        this.activeRoute.params.subscribe(parms => {
            this.forceTeamId = parms._forceTeam
            this.setPrivs();
            if (parms.itemId === 'NEW') {
                this.commsTemplateId = this.activeRoute.snapshot.queryParams.template;
                this.loadBallot(new Ballot());
                this.editBallot();
            } else {
                this.ballotSvc.getOne(parms.itemId, null, this.forceTeamId).subscribe(ballot => {
                    this.loadBallot(ballot);
                })
            }
        })
    }

    ballotSubtitle() {
        if (this.ballot && this.ballot.opensAt && this.ballot.closesAt) {
            const start = new Date(this.ballot.opensAt * 1000);
            const end = new Date(this.ballot.closesAt * 1000);
            //const duration = this.ballot.closesAt - this.ballot.opensAt;
            //const minutes = duration % (3600);
            //const hours = Math.floor(duration / (3600));
            let ret = 'Scheduled ' + formatDate(start, 'medium', Field.locale)
                + ' to ' + formatDate(end, 'medium', Field.locale)
                + ' in the ' + this.ballot.forum.name;
            if (this.ballot.areaName) {
                ret += ' - ' + this.ballot.areaName;
                if (this.ballot.coreName) {
                    ret += ' - (' + this.ballot.coreName + ')';
                }
            }
            return ret;
        }
    }

    formatUnixtime(unixTime: number) {
        const m = moment(unixTime * 1000);
        return m.format('ddd MMM DD hh:mm a')
    }

    newBallot(): Observable<Ballot> {
        if (this.commsTemplateId) {
            return this.commsTmplSvc.get(true).pipe( map( (ts:CommsTemplate[]) => {
                const b = new Ballot();
                this.commsTemplates = ts;
                ts.forEach( t => {
                    if (t.id === this.commsTemplateId) {
                        this.commsTemplate = t as CommsTemplate;
                        b.title = t.title;
                        b.content = t.content;
                    }
                })
                return b;
            }))
        }
        const b = new Ballot();
        return of(b);
    }

    castVote($event, question) {

        if (!this.canVote) {
            console.error('Should not be able to get here, user not allowed vote');
            this.cds.alert('Error', 'You are not allowed vote in ' + this.ballot.forum.name + ' channel ');
            return;
        }
        const vote = new Vote();
        vote.ballotId = this.ballot.id;
        vote.optionId = $event.value;
        vote.questionId = question.id;
        this.voteSvc.postVote(vote, this.forceTeamId).pipe(first()).subscribe(votesCast => {
            if (votesCast && votesCast.length > 0) {
                if (this.ballotPrivs.put) {
                    window.location.reload();
                }

                question.votesCast = [];
                for (const vote of votesCast) {
                    this.rememberVote(vote, question);
                }

            } else {
                console.log('Error posting vote', votesCast);
            }

        })

    }

    canDeactivate() {
        return true;
    }

    sendNotices() {
        const f: Forum = this.ballot.forum;
        let msg = $localize`Send a notification email with a copy of your post to everyone in ${f.name} now?`;
        if (f.residents) {
            msg = $localize`Send a notification email with a copy of your post to all residents of ` + this.currentUser.currentTeam.name + ' now?';
        } else if (f.owners) {
            msg = $localize`Send a notification email with a copy of your post to all unit owners of ` + this.currentUser.currentTeam.name + ' now?';
        }

        this.cds.open($localize`Send Emails to everyone`, msg, () => {
            this.ballotSvc.notify(this.ballot, this.forceTeamId);
        })
    }
    goToList() {
        //this.locationRef.back();
        const options = {} as Params;
        if (this.forceTeamId) {
            options._forceTeam = this.forceTeamId;
        }
        this.router.navigate([BallotPageComponent.navRoute.url, options]);
    }
    goToItem(id: uuid) {
        //this.locationRef.back();
        const options = {} as Params;
        if (this.forceTeamId) {
            options._forceTeam = this.forceTeamId;
        }
        this.router.navigate([BallotPageComponent.navRoute.getIdUrl(id), options]);
    }

    createBallotFormConfig() {
        const fields = [];
        const meetingStart = FormDateTimeComponent.make('Meeting Start', 'opensAt', {
            validators: [required], formColumn: 2 });
        const meetingEnd = FormDateTimeComponent.make('Meeting End', 'closesAt',
            { validators: [required, greaterThen(meetingStart)], formColumn: 2 }
        );
        meetingStart.validators.push(lessThen(meetingEnd))

        const forumField = FormPicklistComponent.make('Channel where this vote will happen', 'forumId', 'forum',
            { items: [] }, { validators: [required] }
        )
        forumField.setPicklistItems(this.currentUserSvc.getForums());

        fields.push(FieldMaker.id());
        fields.push(FieldMaker.rev());
        fields.push(FormTextComponent.make('Title for this ballot', 'title', {
            validators: [required, minChars(3)], formColumn: 3
        }));
        fields.push(forumField);
        fields.push(FormPicklistComponent.make('Votes', 'perUnit', null,
            { items: Ballot.options }, {formColumn: 2}
        ));
        fields.push(this.areaField);
        fields.push(this.coreField);
        fields.push(meetingStart);
        fields.push(meetingEnd);
        fields.push(FormRichTextComponent.make('Details', 'content',
            { formColumn: 3, richTextHeight: '354px', validators: [required, minChars(10)] }),
        );
        const qHolder = FieldMaker.idHolder('questions');
        if (!this.ballot.id && this.commsTemplateId) {
            fields.push(qHolder);
        }

        return new FormConfig(
            {
                title: $localize`Ballot`,
                fieldSet: new FieldSet(
                    {
                        fields,
                        formLayout: [{ cells: [{ width: '16%' }, { width: '16%' }, { width: '66%' }] },
                        { cells: [{ colspan: 2 }] }]
                    }
                ),
                mode: this.ballot.id ? 'edit' : 'new',
                service: this.ballotSvc,
                objectFactory: this.newBallot.bind(this),
                beforeSave: this.beforeSave.bind(this, qHolder),
            }
        );

    }
    beforeSave(questionsField : Field, formGroup: FormGroup) {
        console.warn({ formGroup, questionsField });
        if (this.commsTemplateId) {
            const qs = [];
            this.commsTemplates.forEach( t => {
                if (t.parentId === this.commsTemplateId) {
                    const q = new Question();
                    q.opensAt = (formGroup.controls.opensAt as AppFormControl).field.getFormValue();
                    q.closesAt = (formGroup.controls.closesAt as AppFormControl).field.getFormValue();
                    q.question = t.title;
                    q.content = t.content;
                    qs.push(q);
                }
            });
            this.commsTemplateId = null; // do not add question again if we edit this...
            questionsField.control.setValue(qs);
        }
    }

    viewComments() {
        const chatField = ChatComponent.make(this.ballotSvc);
        chatField.makeControl();
        if (this.canComment) {
            chatField.chat.allowComments = true;
        } else {
            chatField.chat.allowComments = false;
        }
        chatField.setValue(this.ballot, false);
        let addNew = false;
        if (this.ballot.comments.length === 0) {
            addNew = true;
        }
        this.dialog.open(ChatComponent, { data: { chatField, addNew } });
        /*
        .afterClosed().subscribe( () =>{
            this.ballot.comments = chatField.chat.comments;
            console.log({b: this.ballot.comments.length, c: chatField.chat.comments.length, m: this.ballot.comments === chatField.chat.comments});
        });*/
    }

    editBallot() {

        const dialogRef = this.dialog.open(EditDialogComponent,
            {
                data:
                {
                    config: this.createBallotFormConfig(),
                    service: this.ballotSvc,
                    id: this.ballot.id,
                    hideTabs: true,
                    forceTeamId: this.forceTeamId
                }
            }
        );

        dialogRef.afterClosed().pipe(first()).pipe<Ballot>(map(o => {
            console.log('Got ballot back as ', o);
            if (o) {
                this.loadBallot(o);
            }
            if (!this.ballot.id) {
                /* New Ballot - Did not save */
                console.log('No ballot, get out of here');
                this.goToList();
            } else {
                /* New Ballot - Display it. */
                console.log('has a ballot', { p: this.activeRoute.snapshot.params.itemId, id: this.ballot.id });
                if (this.activeRoute.snapshot.params.itemId !== this.ballot.id) {
                    this.goToItem(this.ballot.id);
                }
            }
            return o;
        })).subscribe();
    }

    rememberVote(voteCast: Vote, question: Question) {
        if (voteCast) {
            if (voteCast.userId !== this.currentUser.id) {
                question.voteCastById = voteCast.userId;
            }
            question.voteCastOn = moment((voteCast.updatedAt ? voteCast.updatedAt : voteCast.createdAt) * 1000).fromNow();
            question.votesCast.push(voteCast);
            question.votedOptionId = voteCast.optionId;
        }
    }

    loadBallot(ballot: Ballot) {
        if (ballot === null) {
            ballot = new Ballot(); // avoid errors
        }
        for (const q of ballot.questions) {
            q.votesCast = [];
            q.voteCastById = null;
            q.votedOptionId = null;

            if (ballot.perUnit) {
                for (const ur of this.currentUser.activePositions) {
                    this.rememberVote(ballot.votes.find(v => v.unitId === ur.unitId && v.questionId === q.id), q);
                }
            } else {
                this.rememberVote(ballot.votes.find(v => v.userId === this.currentUser.id && v.questionId === q.id), q);
            }
        }

        if (User.hasForumPriv(this.currentUser, ballot.forumId, ballot.teamId, 'canComment')) {
            this.canComment = true;
        } else {
            this.canComment = false;
        }
        if (User.canHaveForumPriv(this.currentUser, ballot.forumId, ballot.teamId, 'canVote')) {
            this.canVote = true;
        } else {
            this.canVote = false;
        }

        this.attachmentGrid.showSimple = true;
        this.attachmentGrid.showGrid = false;
        this.questionAttachmentGrid.showSimple = true;
        this.questionAttachmentGrid.showGrid = false;

        if (this.ballotPrivs.put && (ballot.closesAt * 1000 > Date.now())
            && User.hasForumPriv(this.currentUser, ballot.forumId, ballot.teamId, 'canEdit')) {
            this.allowEdit = true;
            this.showRequests = true;
            this.attachmentGrid.readonly = false;
            this.attachmentGrid.show();
        } else {
            this.allowEdit = false;
            this.showRequests = ballot.requests.length !== 0
            if (ballot.attachments?.length <= 0) {
                this.attachmentGrid.hide();
            } else {
                this.attachmentGrid.show();
            }
            this.attachmentGrid.readonly = true;
        }

        this.attachmentGrid.setValue(ballot, this.attachmentGrid.readonly);

        this.ballot = ballot;
    }

    showQuestionHistory(question: Question): void {
        const dialogConfig = new MatDialogConfig();
        const config = this.createQuestionFormConfig(false, question);
        config.itemId = question.id;
        dialogConfig.data = { config };
        dialogConfig.minWidth = "80%"
        this.dialog.open(HistoryComponent, dialogConfig);
    }

    showHistory(): void {
        const dialogConfig = new MatDialogConfig();
        const config = this.createBallotFormConfig();
        config.itemId = this.ballot.id;
        dialogConfig.data = { config };
        dialogConfig.minWidth = "80%"
        this.dialog.open(HistoryComponent, dialogConfig);
    }

    loadResult(q: Question) {
        const cf = ChartComponent.make('Result of ' + q.question, this.createChartData.bind(this), this.createChartOptions(q));
        const ctl = cf.makeControl();
        cf.setValue(q, true);
        cf.chart.update(cf.control.value);

        q.resultControl = ctl as AppFormControl;
    }

    unselectQuestion(q: Question) {
        console.log('Unselecting Question');
        if (this.selectedQuestion === q) {
            this.selectedQuestion = null;
        }
    }
    selectQuestion(question: Question) {
        console.log('Selecting quetsions');
        this.selectedQuestion = question;
        const divId = 'results-' + question?.id;
        if (this.questionPrivs.put) {
            this.questionAttachmentGrid.readonly = false;
        } else {
            this.questionAttachmentGrid.readonly = true;
        }
        this.questionAttachmentGrid.setValue(question, this.questionAttachmentGrid.readonly);
        if (document.getElementById(divId)) {
            const options = this.createChartOptions(question);
            Highcharts.chart(divId, options);
        }
    }

    createChartOptions(question: Question): Highcharts.Options {
        return {
            chart: {
                backgroundColor: 'transparent',
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            credits: { enabled: false },
            title: null,

            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.y} vote(s) ({point.percentage:.1f} %)',
                        distance: 0,
                    }
                }
            },
            legend: {
                enabled: true
            },
            series: [{
                type: undefined,
                name: 'Votes',
                data: this.createChartData(question),
            }]
        }
    }

    getQuestionVotes(question: Question) {
        return this.ballot.votes.filter(v => v.questionId === question.id);
    }

    createChartData(question: Question): ChartDataItem[] {
        // Remember the Cycle has been through thius.enhanceCycle() since retrieved from server
        const series: ChartDataItem[] = [];
        const results = {}
        for (const option of question.options) {
            results[option.id] = 0;
        }
        const votes = this.getQuestionVotes(question);
        for (const vote of votes) {
            results[vote.optionId]++;
        }
        for (const option of question.options) {
            option.votes = results[option.id];
            if (results[option.id] > 0) {
                series.push({ name: option.name, y: results[option.id] })
            }
        }
        return series;
    }

    newQuestion(): Observable<Question> {
        const q = new Question();
        q.ballotId = this.ballot.id;
        q.forumId = this.ballot.forumId;
        q.opensAt = this.ballot.opensAt;
        q.closesAt = this.ballot.closesAt;
        return of(q);
    }

    showVotes($event, question: Question) {
        $event.stopPropagation();
        const items = [];
        const votesCast = this.getQuestionVotes(question);
        for (const vote of votesCast) {
            let userName = "User Id " + vote.userId;
            const user = this.users.find(u => u.id === vote.userId);
            if (user) {
                userName = user.name; // email no longer shared + ' (' + user.email + ')';
            }
            let option = 'Voted :' + vote.optionId;
            const o = question.options.find(opt => opt.id === vote.optionId);
            if (o) {
                option = o.name;
                if (o.description) {
                    option += ' - ' + o.description;
                }
            }
            items.push({
                userName,
                createdAt: vote.createdAt,
                updatedAt: vote.updatedAt,
                option
            })
        }
        console.log({ items, question });
        const fields = [
            FormTextComponent.make('User', 'userName'),
            FormDateTimeComponent.make('Created', 'createdAt'),
            FormDateTimeComponent.make('Updated', 'updatedAt'),
            FormTextComponent.make('Voted', 'option'),
        ];

        const config = new FormConfig(
            {
                title: $localize`Votes Cast`,
                fieldSet: new FieldSet(
                    {
                        fields,
                    }
                ),
                mode: 'list',
            }
        );

        const dialogRef = this.dialog.open(TableDialogComponent, { data: { config, items } });

        dialogRef.afterClosed().subscribe(() => console.log('closed table dialog'));

    }

    dropQuestion(event: CdkDragDrop<string[]>) {
        console.log(event);
        const newOrder = this.ballot.questions.slice();
        moveItemInArray(newOrder, event.previousIndex, event.currentIndex);
        this.ballotSvc.setSortOrder(this.ballot, newOrder, this.forceTeamId);
        //$event.stopPropagation();
    }

    createQuestionFormConfig(timeOnly: boolean, questionToEdit: Question) {
        const optionsField = new GridField({
            field: { value: 'options', label: 'Options', visible: Field.formOnly, formColumn:2 },
            rowFactory: () => [
                FieldMaker.id(),
                FieldMaker.rev(),
                FormTextComponent.make('Voting Option', 'name', { cellOpts: { width: '20em' } }),
                FormTextComponent.make('Description of voting option', 'description', { cellOpts: { width: "80%" } }),
                FieldMaker.deleteGridRow(),
            ],
            objFactory: () => of(new Option()),
            newOptionText: 'Add answer / voting option',
        });
        const startTime = FormDateTimeComponent.make('Voting Starts', 'opensAt', { formRow: 2, formColumn: 2 });
        const opts = { validators: [greaterThen(startTime)], formColumn: 2, formRow: 2,  };
        const endTime = FormDateTimeComponent.make('Voting Finishes At', 'closesAt', opts);
        startTime.validators.push(lessThen(endTime));

        const proposer = FormComboBoxComponent.make('Proposer', 'proposerId', 'proposer',
            { optionDisplayValue: (o: Person) => Person.fullName(o) }, { formColumn: 1, formRow: 2,  }
        );

        const seconder = FormComboBoxComponent.make('Seconder', 'seconderId', 'seconder',
            { optionDisplayValue: (o: Person) => Person.fullName(o) }, { formColumn: 1, formRow: 2,  }
        );

        this.personSvc.get(false, null, this.forceTeamId).subscribe(persons => {
            proposer.setPicklistItems(persons);
            seconder.setPicklistItems(persons);
        });


        const fields = [
            FieldMaker.id(),
            FieldMaker.rev(),
            FieldMaker.idHolder('ballotId'),
            FormTextComponent.make('Question', 'question', { formColumn: 1, validators: [required, maxChars(255)] }),
            startTime,
            endTime,
            //FormCheckboxComponent.make('Allow Freeform Comment', 'allowFreetext'),
            proposer,
            seconder,
            FormRichTextComponent.make('Details', 'content', { formColumn: 1, richTextHeight: "200" }),
            optionsField,
        ];

        const fieldTimeonly = [
            FieldMaker.id(),
            FieldMaker.rev(),
            FieldMaker.idHolder('ballotId'),
            startTime,
            endTime,
        ]

        return (new FormConfig(
            {
                title: $localize`Question`,
                fieldSet: new FieldSet(
                    {
                        fields: timeOnly ? fieldTimeonly : fields,
                        formLayout: [{ cells: [{ colspan: 2, }, { rowspan: 2, width: "50%" }] },
                        { cells: [{ }, { }] }]
                    }
                ),
                service: this.questionSvc,
                mode: questionToEdit ? 'edit' : 'new',
                objectFactory: this.newQuestion.bind(this),
            }
        ));
    }

    deleteQuestion($event, question: Question) {
        $event.preventDefault();
        $event.stopPropagation();

        this.cds.open($localize`Cnfirm deletion of : ${question.question}`,
            $localize` Delete Question: ${question.question} - The question, options and votes will all be removed`,
            () => {
                this.questionSvc.delete(question, this.forceTeamId).subscribe(() => {
                    const idx = this.ballot.questions.indexOf(question);
                    this.selectQuestion = null;
                    this.ballot.questions.splice(idx, 1);
                })
            })
    }

    addRequest() {
        const data = {ballots: [{ballotId: this.ballot.id}]};
        this.dialog.open(SimpleRequestComponent, {data}).afterClosed().subscribe( sr => {
            if (sr) {
                this.ballot.requests.push(sr);
            }
        })
    }

    getRequestRoute(sr: ServiceRequest) {
        return ServiceRequestPageComponent.navRoute.getIdUrl(sr.id)
    }

    editQuestion($event, questionToEdit: Question, timeOnly = false) {
        $event.stopPropagation();

        const dialogData = {
            config: this.createQuestionFormConfig(timeOnly, questionToEdit),
            service: this.questionSvc,
            id: questionToEdit?.id,
            hideTabs: true,
            forceTeamId: this.forceTeamId
        }

        if (timeOnly) {
            dialogData['height'] = 300;
            dialogData['width'] = 300;
        }

        const dialogRef = this.dialog.open(EditDialogComponent,
            {
                data: dialogData
            }
        );

        dialogRef.afterClosed().pipe(first()).pipe<Question>(map(o => {
            if (o && questionToEdit) {
                this.updateObject(questionToEdit, o);
            } else if (o) {
                o.votesCast = [];
                this.ballot.questions.push(o);
            }
            return o;
        })).subscribe();
    }

    updateObject(original: Question | Ballot, replacement: Question | Ballot) {
        /* A simple replace of object would lose the references to it!*/
        Object.getOwnPropertyNames(replacement).forEach(prop => {
            original[prop] = replacement[prop];
        })
    }

    openVoting($event, question: Question) {
        $event.stopPropagation();
        this.cds.open('Open Voting', 'Do you wish to open the vote now', () => {
            const oldStart = question.opensAt;
            question.opensAt = new Date().getTime() / 1000;
            this.questionSvc.put(question, this.forceTeamId).pipe(first()).subscribe(o => {
                if (!o) {
                    question.opensAt = oldStart;
                } else {
                    this.updateObject(question, o);
                }
            });
        });
    }
    closeVoting($event, question: Question) {
        $event.stopPropagation();
        this.cds.open('Close the voting', 'Do you wish to close the vote now', () => {
            const oldEnd = question.closesAt;
            question.closesAt = new Date().getTime() / 1000;
            this.questionSvc.put(question, this.forceTeamId).pipe(first()).subscribe(o => {
                if (!o) {
                    question.closesAt = oldEnd;
                } else {
                    this.updateObject(question, o);
                }
                this.selectQuestion(question);
            });
        });
    }

    voteStatus(q: Question): string {
        if (this.voteClosed(q)) {
            return 'Vote has been closed ' + moment(q.closesAt * 1000).fromNow();
        } else if (this.voteOpened(q)) {
            let ret = 'Voting is open ';
            if (q.closesAt) {
                ret += ', will close ' + moment(q.closesAt * 1000).fromNow() + ', ';
            }
            if (q.votesCast.length > 0) {
                ret += 'you can still change your vote ';
            } else {
                ret += 'please cast your vote';
            }

            return ret;
        } else if (q.opensAt) {
            // return 'Vote expected ' + formatDate(new Date(q.opensAt * 1000), 'medium', Field.locale);
            return 'Vote will be opened ' + moment(q.opensAt * 1000).fromNow();
        } else {
            return 'Voting not open yet';
        }

        return '';
    }
    voteOpened(q: Question) {
        if (q?.opensAt && (q.opensAt * 1000) < new Date().getTime()) {
            return true;
        } else {
            return false;
        }
    }

    voteClosed(q: Question) {
        if (q?.closesAt && (q.closesAt * 1000) < new Date().getTime()) {
            return true;
        } else {
            return false;
        }
    }

    voteIsOpen(q: Question) {
        if (this.voteOpened(q) && !this.voteClosed(q)) {
            return true;
        } else {
            return false;
        }
    }
}
