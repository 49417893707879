/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { RequestTemplate } from 'src/app/model/RequestTemplate';

@Injectable({
  providedIn: 'root'
})
export class RequestTemplatesService extends AbstractHttpService {

  protected baseUrl = this.ajaxPath + 'crm/requestTemplates';
  protected cache: RequestTemplate[];
  protected typeString = 'RequestTemplates';

  constructor(protected http: HttpClient, protected messageService: MessageService) {
    super();
  }
}
