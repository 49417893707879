/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Input } from '@angular/core';
import { ChatItem } from 'src/app/model/chat-item';
import { UserNameComponent } from 'src/app/modules/user/user-name/user-name.component';
import { FromNowPipe } from 'src/app/shared/pipes/from-now.pipe';
import { LimitToPipe } from 'src/app/shared/pipes/limit-to.pipe';

@Component({
    selector: 'app-chat-reply',
    templateUrl: './chat-reply.component.html',
    styleUrls: ['./chat-reply.component.scss'],
    standalone: true,
    imports: [LimitToPipe, FromNowPipe, UserNameComponent]
})
export class ChatReplyComponent {
    @Input()
    chatItem: ChatItem;
}
