/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { PostService } from '../post.service';

import { Post } from 'src/app/model/post';

import { UserService } from 'src/app/modules/user/user.service';
import { User } from 'src/app/model/user';
import { NewButtonService } from 'src/app/shared/new-button/new-button.service';
import { NavRoute } from 'src/app/shared/NavRoute';
import { FromNowPipe } from 'src/app/shared/pipes/from-now.pipe';
import { AvatarComponent } from '../../../user/avatar/avatar.component';
import { RouterLink } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { CurrentUserService } from 'src/app/modules/user/current-user.service';
import { CanComponentDeactivate } from 'src/app/shared/guards/can-deactivate.guard';
import { PostItemComponent } from '../post-item/post-item.component';

@Component({
    selector: 'app-post-list',
    templateUrl: './post-list.component.html',
    styleUrls: ['./post-list.component.scss'],
    standalone: true,
    imports: [
        MatCardModule,
        RouterLink,
        AvatarComponent,
        FromNowPipe,
    ],
})

export class PostListComponent implements OnInit, CanComponentDeactivate {

    static readonly navRoute = new NavRoute('social/posts', PostListComponent, 'forum')
        .setItemComponent(PostItemComponent)
        .setNewComponent(PostItemComponent);

    @ViewChild('bottom_marker', { static: false }) bottomMarker: ElementRef;

    posts: Post[] = [];
    gotAllPosts = false;
    postsPerPage = 10;
    currentPage = 0;

    selectedPost: Post;
    //   displayedColumns: string[] = ['title'];
    currentUser: User = new User();

    constructor(public postService: PostService, // Shared with image component
        private userService: UserService, currentUserSvc: CurrentUserService,
        nbs: NewButtonService) {
        if (currentUserSvc.getForums('canCreate').length > 0) {
            nbs.setupButton($localize`Post`, 'social/posts/NEW', true);
        }
    }

    canDeactivate(): boolean {
        return true;
    }

    ngOnInit(): void {
        /* Listen for scroll events to enable infinite scroll */
        //window.addEventListener('scroll', function(event) {
        //  this.scrollListener(event);
        //}.bind(this), true);
        //this.getMorePosts();

        this.postService.get<Post>(true).subscribe(posts => {
            this.posts = posts;
            this.gotAllPosts = true;
        });
    }

    getExcerpt(post: Post) {
        return Post.getExcerpt(post);
    }
    /*
        getPosts(): void {
            this.postService.getPosts().subscribe(posts => { this.loadPosts(posts); });
        }
    */
    getMorePosts(): void {

        if (!this.gotAllPosts) {
            this.currentPage++;
            //const gotSoFar = this.posts.length;
            //this.postService.getMorePosts(this.currentPage, this.postsPerPage).subscribe(posts => {
            //if (posts.length < gotSoFar + this.postsPerPage) {
            //  this.gotAllPosts = true;
            //}
            //this.posts = posts;
            //});
        }
    }

    scrollListener() {
        const bottom: Element = document.getElementById('bottom_marker');
        if (bottom) {
            const rect = bottom.getBoundingClientRect();
            if ((rect.top >= 0) && rect.bottom <= window.innerHeight) {
                this.getMorePosts();
            }
        }
    }
}
