/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { Field } from 'src/app/shared/field/Field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-table-settings',
  templateUrl: './table-settings.component.html',
  styleUrls: ['./table-settings.component.scss'],
  standalone: true,
  imports: [MatDialogContent, MatIconModule, MatButtonModule]
})
export class TableSettingsComponent {
  columns: Field[];
  columnsOriginal: Field[];
  refresh: () => void;

  constructor(
    private dialogRef: MatDialogRef<TableSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) data: { columns: Field[]; refresh: () => void }) {
    // this.columnsOriginal = data.columns;
    this.columns = data.columns; // .slice();
    this.refresh = data.refresh;
  }

  onClose() {
    this.dialogRef.close();
  }

  push(index, up) {
    /*
    if ( (index === 1 && up) || (index >= this.columns.length() && !up) ){
      return;
    }
    */

    let newIndex = index;
    if (up) {
      newIndex--;
    } else {
      newIndex++;
    }

    const holder = this.columns[newIndex];

    this.columns[newIndex] = this.columns[index];
    this.columns[index] = holder;
    this.refresh();

  }

}
