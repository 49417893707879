<div>
    <h1 style="white-space:nowrap; width:auto; overflow:hidden">Bank Reconciliation - {{bank?.name}}
        ({{bank?.team?.legalName}})</h1>
    <div style="display:flex; max-width: 100%; overflow: hidden">
        <div style="width:300px">
            <app-reconciliation-items [bank]="bank" (itemSelected)="selectItem($event)"></app-reconciliation-items>
        </div>

        <ng-container *ngTemplateOutlet="searchTemplate"></ng-container>

        <div style="flex-grow: 3; max-width: calc(100% - 400px)">
            <ng-container *ngTemplateOutlet="recAgainstTabs"></ng-container>
        </div>
    </div>
</div>
<ng-template #searchTemplate>
    <div style="width:250px; margin-top: 54px; margin-right: 10px">
        <mat-tab-group>
            <mat-tab>
                <ng-template mat-tab-label>Options</ng-template>
                <ng-template matTabContent>
                    <div style="height:calc(100vh - 250px);">
                        <ng-container *ngTemplateOutlet="paymentSearchTemplate"></ng-container>
                        <ng-container *ngTemplateOutlet="receiptSearchTemplate"></ng-container>
                        <ng-container *ngTemplateOutlet="invoiceSearchTemplate"></ng-container>
                        <ng-container *ngTemplateOutlet="purchaseSearchTemplate"></ng-container>

                        <ng-container *ngTemplateOutlet="personSearchTemplate"></ng-container>
                        <ng-container *ngTemplateOutlet="unitSearchTemplate"></ng-container>
                        <ng-container *ngTemplateOutlet="supplierSearchTemplate"></ng-container>

                        <button mat-raised-button class="searchButton" color="primary"
                            [disabled]="reconcilingItem?.transactionAmount > 0">Create Bank Charge</button>

                        <button mat-raised-button class="searchButton" color="primary" (click)="createPayment()"
                            [disabled]="reconcilingItem?.transactionAmount > 0">Create Payment</button>

                        <button mat-raised-button class="searchButton" color="primary"  (click)="createReceipt()"
                            [disabled]="reconcilingItem?.transactionAmount < 0">Create Receipt</button>
                    </div>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>

</ng-template>

<ng-template #paymentSearchTemplate>
    <button mat-raised-button class="searchButton" color="primary" (click)="paymentSearch()"
        [disabled]="!reconcilingItem || reconcilingItem?.transactionAmount > 0 || this.payments.length === 0">
        @if (this.payments.length === 0) {
        No unreconciled payments
        } @else {
        Show {{this.payments.length}} Bank Payments
        }
    </button>
</ng-template>

<ng-template #receiptSearchTemplate>
    <button mat-raised-button class="searchButton" color="primary" (click)="receiptSearch()"
        [disabled]="!reconcilingItem || reconcilingItem?.transactionAmount < 0 || this.receipts.length === 0">
        @if (this.receipts.length === 0) {
        No unreconciled receipts
        } @else {
        Show {{this.receipts.length}} Bank Receipts
        }
    </button>
</ng-template>

<ng-template #invoiceSearchTemplate>
    <button mat-raised-button class="searchButton" color="primary" (click)="invoiceSearch()"
        [disabled]="!reconcilingItem || reconcilingItem?.transactionAmount < 0 || this.invoices.length === 0">
        @if (this.invoices.length === 0) {
        No unpaid service charges
        } @else {
        Show {{this.invoices.length}} Unpaid service charges
        }
    </button>
</ng-template>

<ng-template #purchaseSearchTemplate>
    <button mat-raised-button class="searchButton" color="primary" (click)="purchaseSearch()"
        [disabled]="!reconcilingItem || reconcilingItem?.transactionAmount > 0 || this.purchases.length === 0">
        @if (this.purchases.length === 0) {
        No outstanding supplier invoices
        } @else {
        Show {{this.purchases.length}} Outstanding Purchase Invoices
        }
    </button>
</ng-template>

<ng-template #personSearchTemplate>
    <p class="searchItemLabel">
        @if (reconcilingItem?.transactionAmount > 0) { <span>Find person to log receipt against</span>}
        <br />
    </p>
    <p class="searchItem">
        <input [(ngModel)]="searchTermPerson" [matTooltip]="searchTooltip" placeholder="Person Search"
        [disabled]="!reconcilingItem || reconcilingItem?.transactionAmount < 0"
        class="searchItem" (ngModelChange)="personSearch()">
    </p>
</ng-template>

<ng-template #unitSearchTemplate>
    <p class="searchItemLabel">
        @if (reconcilingItem?.transactionAmount > 0) { <span>Find unit to log receipt against</span>}
        <br />
    </p>
    <p class="searchItem">
        <input [(ngModel)]="searchTermUnit" [matTooltip]="searchTooltip" placeholder="Unit Search"
            [disabled]="!reconcilingItem || reconcilingItem?.transactionAmount < 0" class="searchItem"
            (ngModelChange)="unitSearch()">
    </p>
</ng-template>

<ng-template #supplierSearchTemplate>
    <p class="searchItemLabel">
        @if (reconcilingItem?.transactionAmount < 0) { <span>Find supplier to log payment against</span>}
        <br />
    </p>
    <p class="searchItem">
        <input [(ngModel)]="searchTermSupplier" [matTooltip]="searchTooltip" placeholder="Supplier Search"
            [disabled]="!reconcilingItem || reconcilingItem?.transactionAmount > 0" class="searchItem"
            (ngModelChange)="supplierSearch()">
    </p>
</ng-template>

<ng-template #recAgainstTabs>
    <div style="margin-top: 6px; max-width: calc(100% - 142px); width: calc(100% - 142px)">
        <h2 style="white-space:nowrap; width:auto; overflow:hidden">
            @if (reconcilingItem) {
            Reconcile: {{reconcilingItem.transactionAmount | currency}} Dated: {{reconcilingItem.bookingDate}}
            {{reconcilingItem.description}}
            }
            <br />
        </h2>
        <mat-tab-group>
            @if (reconcilingItem && showPanel) {
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon>{{showPanel.icon}}</mat-icon>{{showPanel.title}}
                </ng-template>
                <ng-template matTabContent>
                    <div style="height:calc(100vh - 250px)">
                        <app-table [config]="showPanel.config" [drilldown]="false" [dataSet]="showPanel.items"
                            [displayHeight]="tableHeight()" maxRecords="10"></app-table>
                    </div>
                </ng-template>
            </mat-tab>
            }
            @if (!reconcilingItem || !showPanel) {
            <mat-tab>
                <ng-template mat-tab-label></ng-template>
                <ng-template matTabContent> <!-- Padding effects height calculation... -->
                    <div style="height:calc(100vh - 550px);text-align:center; padding-top: 300px;">
                        <p>Select item to reconcile on Left</p>
                        @if (showPanel) {
                        <p>Matching {{showPanel.type}} items will be shown when you select a reconciliation item</p>
                        }
                    </div>
                </ng-template>
            </mat-tab>
            }
        </mat-tab-group>
    </div>
</ng-template>

<ng-template #peoplePicker>
    <p>Some Content</p>
</ng-template>
<ng-template #invoicePicker>
    <p>Some Content</p>
</ng-template>
