@switch (field.type) {
    @case ('icon') {
        <app-icon-table-cell [field]="field" [focusItem]="focusItem"></app-icon-table-cell>
    }
    @case ('link') {
        <app-link-table-cell [field]="field" [focusItem]="focusItem"></app-link-table-cell>
    }
    @case ('picklist') {
        <app-picklist-table-cell [field]="field" [focusItem]="focusItem"></app-picklist-table-cell>
    }
    @case ('dateTime') {
        <app-date-time-table-cell [field]="field" [focusItem]="focusItem"></app-date-time-table-cell>
    }                     
    @case ('limitTo') {
        <div>
            {{field.getFormattedValue(focusItem) | limitTo:150}}
        </div>
    }
    @case ('boolean') {
        <div>
            @if (field.getValue(focusItem)) {
              <mat-icon color="primary">done</mat-icon>
            }
        </div>
    }
    @case ('email') {
        <div>
            <a href="mailto:{{field.getFormattedValue(focusItem)}}">
                {{field.getFormattedValue(focusItem)}}
            </a>
        </div>
    }
    @case ('url') {
        <div>
            <a [href]="field.getFormattedValue(focusItem)"
            target="_blank">{{field.getFormattedValue(focusItem)}}</a>
        </div>
    }                   
    @default {
        <div>
            {{field.getFormattedValue(focusItem)}}
        </div>
    }
}
