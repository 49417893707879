<p i18n>Use this option to setup a regular payment the account of unit {{unit.name}}
</p>
<p i18n>
    There is currently {{unit.outstanding | currency}} outstanding, which will be covered by this payment plan.
    However, payment plans will automatically roll on to the following year, adjusting the amount as required.
</p>

<mat-card-subtitle i18n>When you click the button, you will be brought to our payment partners site (stripe)
    Where you can enter your bank account details. You will need an IBAN number, name and address details for the
    account holder. A request for the funds will go directly to your bank. Usually for Irish bank it will show up within
    a day, but potentially it can take up to a few days before the request reaches your bank. After the funds leave your
    account, it can also potentially take several days before your account balance gets updated on OurOMC. You will
    however be able to come back to this page to see the latest status of the request.
    You can choose if you want your bank details to be stored to make future payments more easily. If you choose to do
    so, the information will be stored securely on stripe servers. OurOMC will never hold your payment details.
</mat-card-subtitle>
<table width="100%">
    <tr>
        <td>
            Direct Debit Options
        </td>
        <td>
            <app-form-picklist [control]="frequencyCtl"></app-form-picklist>
        </td>

    </tr>
    <tr>
        <td>Pay up to date + direct debit for remainder</td>
        <td>Pay {{unit.overdue | currency}} plus {{unit.paysLeft}}  {{unit.frequency.name}} payments of {{unit.oldPay | currency}}</td>
        <td>
            <button mat-raised-button color="primary" [disabled]="unit.payzMandate" i18n>Pay {{unit.overdue | currency}} Now</button>
        </td>
    </tr>
    <tr>
        <td>Regular Payment Only</td>
        <td>
            Clear balance with {{unit.paysLeft}} {{unit.frequency.name}} of {{unit.newPay | currency}}
        </td>
        <td>
            <button mat-raised-button color="primary" [disabled]="unit.payzMandate">Setup DD</button>
        </td>
    </tr>
</table>


