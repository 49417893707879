/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { AbstractObject, uuid } from 'src/app/model/abstract-object';
import { FormConfig } from "../../form/FormConfig";
import { IsNarrowService } from '../../is-narrow.service';
import { TableComponent } from '../../table/table.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
export interface TableDialogOptions {
    config: FormConfig;
    items: AbstractObject[];
    id?: uuid;
    width?: number;
    height?: number;
    showSearch?: boolean;
    addOnly?: boolean;
}

@Component({
    selector: 'app-table-dialog',
    templateUrl: './table-dialog.component.html',
    styleUrls: ['./table-dialog.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, MatDialogContent, TableComponent]
})
export class TableDialogComponent implements OnInit, AfterViewInit {

    @ViewChild(TableComponent) table: TableComponent;

    config: FormConfig;
    width = 500;

    private initialised: Subject<null> = new Subject();

    constructor(protected isNarrowSvc: IsNarrowService,
        public dialogRef: MatDialogRef<TableDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: TableDialogOptions) {

        this.config = data.config;

        if (!data.config.mode) {
            this.config.mode = 'list';
        }
        //this.dialogRef.disableClose = true; Close Button not showing, emergency fix until it does!
        //TODO - Override Params?....
    }

    ngOnInit(): void {
        this.width = this.isNarrowSvc.screenWidth * .75;
    }

    ngAfterViewInit() {
        /* Calling direct caused issue with ExpressionChangedAfterItHasBeenChecked */
        this.initialised.subscribe(() => this.loadTableData());
        setTimeout(() => {
            this.initialised.next(null);
            this.initialised.complete();
        }, 10);
    }

    loadTableData() {
        this.table.loadData(this.data.items);
    }

    closeDialog() {
        this.dialogRef.close(null);
    }

}
