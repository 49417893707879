/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { AbstractObject, uuid } from 'src/app/model/abstract-object';
import { Field } from 'src/app/shared/field/Field';
import { FieldSet, LAYOUT_OPTIONS } from 'src/app/shared/form/field-set/field-set.component';
import { FormCheckboxComponent } from 'src/app/shared/form/form-checkbox/form-checkbox.component';
import { FormEmailComponent } from 'src/app/shared/form/form-email/form-email.component';
import { FormPhoneComponent } from 'src/app/shared/form/form-phone/form-phone.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { required } from 'src/app/shared/validators';

class RoleInterest extends AbstractObject {
    omc?: boolean;
    agent?: boolean;
}

export class LeadProfile extends AbstractObject {
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
    readonly phone: string;
    readonly role: string;
    readonly otherInterest: string;
    readonly omcName: string;
    readonly omcUnits: uuid;
    readonly omcAgent: string;
    readonly agencyName: string;
    readonly agencyUnits: uuid;
    readonly marketingConsent: boolean;
    // Non user editable
    readonly code: string;
    readonly codeSent: string; // ISO Date String...
    readonly validated: boolean;
}

type LeadProfileEditor = {
    -readonly [K in keyof LeadProfile]: LeadProfile[K]
}
@Injectable({
    providedIn: 'root'
})
export class LeadProfileService {

    profile: LeadProfile = new LeadProfile();
    private storageItemKey = 'leadProfile';
    private editor: LeadProfileEditor;

    roles: RoleInterest[] = [
        { id: 'owner', name: 'Owner in a multi unit development', omc: true },
        { id: 'resident', name: 'Resident in a multi unit development', omc: true },
        { id: 'director', name: 'Director of a multi unit development', omc: true },
        { id: 'agent', name: 'Property Management Agent', agent: true },
        { id: 'proprietor', name: 'Owner/Director of property management agency', agent: true },
        { id: 'contractor', name: 'Contractor or Caretaker', agent: true },
        { id: 'accountant', name: 'Accountant', agent: true },
        { id: 'other', name: 'other' },
    ];

    currentRole: RoleInterest;

    fieldSet = new FieldSet({
        fields: [
            this.userFirstNameMaker(),
            this.userSurnameMaker(),
            this.emailMaker(),
            this.phoneMaker(),
            this.roleMaker(),
            this.otherMaker(),
            this.omcNameMaker(),
            this.omcUnitsMaker(),
            this.omcAgentMaker(),
            this.agencyNameMaker(),
            this.agencyUnitsMaker(),
            this.allowContactMaker(),
        ],
        formLayout: LAYOUT_OPTIONS.singleCol,
    });

    constructor() {
        this.loadProfile();
    }

    calcCode(email: string) {
        const s = email.charCodeAt(1);
        return `${s}${email.length}`
    }

    setCode() {
        this.editor.code = this.calcCode(this.profile.email);
        this.saveProfile();
    }

    markCodeSent() {
        this.editor.codeSent = new Date().toISOString();
        this.saveProfile();
    }

    saveUserData() {
        this.fieldSet.getFormValue() as LeadProfile
        this.fieldSet.fields.forEach( f => this.profile[f.value] = f.control.value);
        this.saveProfile();
    }

    loadProfile() {
        const e = localStorage.getItem(this.storageItemKey);
        if (e) {
            this.profile = JSON.parse(e);
        } else {
            this.profile = new LeadProfile()
        }
        this.editor = this.profile;
        this.fieldSet.setValue(this.profile);
        this.changeRole(this.roles.find( r => r.id === this.profile.role));
    }

    saveProfile() {
        localStorage.setItem(this.storageItemKey, JSON.stringify(this.profile));
    }

    markAccessValidated() {
        this.editor.validated = true;
        this.saveProfile();
    }

    userFirstNameMaker() {
        const f = FormTextComponent.make('Your First Name', 'firstName');
        f.override({ validators: [required] })
        return f;
    }

    userSurnameMaker() {
        const f = FormTextComponent.make('Your Last Name', 'lastName')
        f.override({ validators: [required] })
        return f;
    }

    emailMaker() {
        const f = FormEmailComponent.make('Your Email Address', 'email')
        f.override({ validators: [required] })
        return f;
    }

    phoneMaker() {
        const f = FormPhoneComponent.make('Phone Number', 'phone');
        f.override({ validators: [required] })
        return f;
    }

    roleMaker() {
        const f = FormPicklistComponent.make('What is your interest in OurOMC', 'role', null,
            { items: this.roles, refreshes: [this.changeRole.bind(this)] },
            { validators: [required] });
        return f;
    }

    otherMaker() {
        const f = FormTextComponent.make('Please tell us why you are interested in OurOMC', 'otherInterest');
        f.visible = Field.noShow;
        f.validators = [(ctl) => {
            if (this.currentRole && !this.currentRole.omc && !this.currentRole.agent && !ctl.value) {
                return { required: 'Required' };
            }
            return null;
        }];
        return f;
    }

    omcNameMaker() {
        const f = FormTextComponent.make('Name of your Owner Management Company', 'omcName');
        f.visible = Field.noShow;
        f.validators = [(ctl) => {
            if (this.currentRole?.omc && !ctl.value) {
                return { required: 'Required' };
            }
            return null;
        }];
        return f;
    }

    omcUnitsMaker() {
        //const f = FormNumberComponent.make('How many units are in your OMC', 'omcUnits');
        const f = FormPicklistComponent.make('How many units in your development', 'omcUnits', null, {
            items: [
                { id: 'Up to 10', name: 'Up to 10' },
                { id: '11 to 20', name: '11 to 20' },
                { id: '21 to 50', name: '21 to 50' },
                { id: '51 to 100', name: '51 to 100' },
                { id: '101 to 500', name: '101 to 500' },
                { id: '501+', name: 'Over 500' }
            ]
        })
        f.visible = Field.noShow;
        f.validators = [(ctl) => {
            if (this.currentRole?.omc && !ctl.value) {
                return { required: 'Required' };
            }
            return null;
        }];
        f.makeControl()
        f.control.setValue(0);
        return f;
    }

    omcAgentMaker() {
        const f = FormTextComponent.make('Who is your property management agent?', 'omcAgent');
        f.visible = Field.noShow;
        f.validators = [(ctl) => {
            if (this.currentRole?.omc && !ctl.value) {
                return { required: 'Required' };
            }
            return null;
        }];
        return f;
    }

    agencyNameMaker() {
        const f = FormTextComponent.make('Your company name', 'companyName');
        f.visible = Field.noShow;
        f.validators = [(ctl) => {
            if (this.currentRole?.agent && !ctl.value) {
                return { required: 'Required' };
            }
            return null;
        }];
        return f;
    }

    agencyUnitsMaker() {
        const f = FormPicklistComponent.make('How many units do you manage/work with', 'agencyUnits', null, {
            items: [
                { id: 'Up to 200', name: 'Up to 200' },
                { id: '201 to 500', name: '201 to 500' },
                { id: '501 to 1000', name: '501 to 1000' },
                { id: '1001 to 3000', name: '1001 to 300' },
                { id: '3000+', name: 'Over 3000' },
            ]
        })
        f.visible = Field.noShow;
        f.validators = [(ctl) => {
            if (this.currentRole?.omc && !ctl.value) {
                return { required: 'Required' };
            }
            return null;
        }];
        f.makeControl()
        f.control.setValue(0);
        return f;
    }

    changeRole(newRole: RoleInterest) {
        const fs = this.fieldSet;
        fs.get('omcName').visible = newRole?.omc ? Field.showAll : Field.noShow;
        fs.get('omcUnits').visible = newRole?.omc ? Field.showAll : Field.noShow;
        fs.get('omcAgent').visible = newRole?.omc ? Field.showAll : Field.noShow;
        fs.get('otherInterest').visible = newRole && !newRole.omc && !newRole.agent? Field.showAll : Field.noShow;
        fs.get('companyName').visible = newRole?.agent ? Field.showAll : Field.noShow;
        fs.get('agencyUnits').visible = newRole?.agent ? Field.showAll : Field.noShow;

        this.currentRole = newRole;
    }

    allowContactMaker() {
        const f = FormCheckboxComponent.make('Allow us store your details and contact you', 'marketingConsent')
        const hint = 'Do we have your permission to store your details and make contact with you in relation to your interest in OurOMC'
        const validators = [required]
        f.override({ hint, validators });
        return f;
    }
}
