/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { MyInjector } from "src/app/app.module";
import { ActionColor, IFormAction } from "src/app/shared/form/form.component";
import { CurrentUserService } from "../../user/current-user.service";
import { MatDialog } from "@angular/material/dialog";
import { BankAccount } from "src/app/model/bankAccount";
import { Field } from "src/app/shared/field/Field";
import { FormNumberComponent } from "src/app/shared/form/form-number/form-number.component";
import { FormDateComponent } from "src/app/shared/form/form-date/form-date.component";
import { FormConfig } from "src/app/shared/form/FormConfig";
import { of } from "rxjs";
import { Txn } from "src/app/model/txn";
import { FieldSet } from "src/app/shared/form/field-set/field-set.component";
import { EditDialogComponent } from "src/app/shared/dialogs/edit-dialog/edit-dialog.component";
import { BankChargesService } from "./bank-charges.service";
import { required } from "src/app/shared/validators";
import { FormTextComponent } from "src/app/shared/form/form-text/form-text.component";
import { FieldMaker } from "src/app/shared/field/FieldMaker";

export class BankChargesAction implements IFormAction {
    name = $localize`Add Bank Charge`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'money';
    approvalNeeded = false;
    disabled = false;
    approvalText = '';
    tipText: string;
    bankAccount: BankAccount;

    currentUser = MyInjector.instance.get(CurrentUserService).currentUser;
    dialog = MyInjector.instance.get(MatDialog);

    //constructor(private addFn: () => Observable<AbstractObject>, private linksTo: LinksTo) { }
    constructor() { }

    action() { // config: FormConfig
        return this.openDialog();
    }

    setup(item: BankAccount) {
        this.bankAccount = item;
        this.show = true;
        if (item.chargesBCodeId) {
            this.tipText = $localize `Click to enter a bank charge taken from this account`;
            this.disabled = false;
        } else {
            this.tipText = $localize`Specify a bank charge budget code to be able to add bank charges`;
            this.disabled = true;
        }
    }

    private getFields(): Field[] {
        return [
            FormTextComponent.make('IBAN', 'IBAN', {disable: true, sendServer: false}),
            FieldMaker.idHolder('bankAccountId'),
            FormDateComponent.make('Transaction Date', 'txnDate', {validators: [required]}),
            FormNumberComponent.makeCurrency('Amount', 'amount', { validators: [required] })
        ];
    }

    newTransaction() {
        const t = new Txn();
        t['IBAN'] = this.bankAccount.IBAN;
        t.bankAccountId = this.bankAccount.id;
        return of(t);
    }

    openDialog() {
        const dialogFormConfig = new FormConfig(
            {
                title: $localize`Bank Charge`,
                fieldSet: new FieldSet(
                    {
                        fields: this.getFields(),
                        formLayout: [{ cells: [{ width: '100%' }]}]
                    }
                ),
                mode: 'new',
                objectFactory: this.newTransaction.bind(this),
            }
        );

        const dialogRef = this.dialog.open(EditDialogComponent,
            {
                data:
                {
                    config: dialogFormConfig,
                    service: MyInjector.instance.get(BankChargesService),
                    hideTabs: true,
                    width: 400,
                    height: 400,
                }
            }
        );

        return dialogRef.afterClosed();
    }
}
