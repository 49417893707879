/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { AbstractObject, uuid } from './abstract-object';
export class RequestCategory extends AbstractObject {
    fileName?: string;
    parentId: uuid;
    parent: RequestCategory;
    questions: RequestQuestion[] = [];
    order = 0;
    subCategories: RequestCategory[] = [];
    userNotes?: string;

    constructor(o: Partial<RequestCategory> = {}) {
        super(o);
    }
    static getRequestIconUrl(fileName: string) {
        if (fileName) {
            return 'https://clgrequesticons.fra1.digitaloceanspaces.com/' + fileName;
        } else {
            return 'https://clgrequesticons.fra1.digitaloceanspaces.com/other-box.png';
        }

    }
}

export type QuestionType = 'text' | 'date' | 'currency' | 'number' | 'longText' | 'picklist';

export class RequestQuestion extends AbstractObject {
    dataType: QuestionType = 'text';
    order: number;
    options: string;
    subCategoryId: uuid;

    static QuestionTypes = [
        { id: 'text', name: 'text' },
        { id: 'date', name: 'date' },
        { id: 'currency', name: 'currency' },
        { id: 'number', name: 'number' },
        { id: 'longText', name: 'longText' },
        { id: 'picklist', name: 'picklist' },
    ]
}
