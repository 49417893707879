/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Input, ViewContainerRef } from '@angular/core';
import { AbstractObject } from 'src/app/model/abstract-object';
import { NumberField } from '../../field/NumberField';
import { AppFormControl, ControlOn } from '../app-form-control';
import { FormControlInterface } from '../form-control-interface';
import { FormErrorComponent } from '../form-error/form-error.component';
import { NgClass } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

/*
* Yes - There should be a configurable default unit but instead 'Hours' are hardcoded everywhere...
*
*/
type DurationUnit = 'Hours' | 'Minutes' | 'Days';
class DurationField extends NumberField {
    duration: { qty: number, unit: DurationUnit, minutes: number }

    formatValue(value) {
        if (value) {
            if (value < 60) {
                return value + 'm';
            } else {
                return value ? Math.round(value * 100 / 60) / 100 + 'h' : '';
            }
        } else {
            return '';
        }
    }
    /*
        public getFormValue() {
          return this.control.value * 60;
        }
    */

    setValue(item: AbstractObject, readonly: boolean) {
        const mins = this.getValue(item);
        this.duration.minutes = mins;
        if (mins < 60) {
            this.duration.qty = mins;
            this.duration.unit = 'Minutes'
        } else {
            this.duration.qty = mins / 60
            this.duration.unit = 'Hours'
        }
        if (readonly) {
            this.readonly = true;
        } else {
            this.readonly = this.defaultReadonly;
        }
        this.control.setValue(mins, { emitEvent: false });
        this.control.markAsPristine();
        this.control.markAsUntouched();
    }
}
@Component({
    selector: 'app-form-duration',
    templateUrl: './form-duration.component.html',
    styleUrls: ['./form-duration.component.scss'],
    standalone: true,
    imports: [MatFormFieldModule, FormsModule, MatInputModule, MatTooltipModule, NgClass, FormErrorComponent]
})
export class FormDurationComponent implements FormControlInterface {

    @Input() on: ControlOn = 'form';
    @Input() _control: AppFormControl;
    durationField: DurationField;

    @Input()
    set control(ctl: AppFormControl) {
        this._control = ctl;
        this.durationField = ctl.field as DurationField;
    }
    get control() {
        return this._control;
    }

    public static make(label: string, value: string, options: Partial<DurationField> = {}) {
        /* */
        const field: DurationField = new DurationField(
            { label, value, type: 'number' },
            options
        );
        field.duration = { qty: 0, unit: 'Hours', minutes: 0 }
        field.formControlFactory = FormDurationComponent.createComponent;
        return field;
    }

    public static createComponent(vcr: ViewContainerRef, ctl: AppFormControl, on: ControlOn) {
        const componentRef = vcr.createComponent(FormDurationComponent);
        componentRef.instance.control = ctl;
        componentRef.instance.on = on;
    }

    updateUnits(newValue) {

        const d = (this.control.field as DurationField).duration;
        let qty = d.qty;
        console.log({ old: d.unit, newValue, qty });
        /** this.control.field.unit still has old value until this event completes */
        if (d.unit === 'Hours') {
            qty = d.qty * 60;
        } else if (d.unit === 'Days') {
            qty = d.qty * 60 * 8;
        }

        if (newValue === 'Hours') {
            d.qty = Math.round(qty * 100 / 60) / 100;
        } else if (newValue === 'Days') {
            d.qty = Math.round(qty * 100 / 60 / 8) / 100;
        } else if (newValue === 'Minutes') {
            d.qty = Math.round(qty);
        }
        console.log({ qtyCalc: qty, qtyStore: d.qty });
        this.setNewValue(qty);
    }

    setNewValue(qty: number) {
        this.control.setValue(Math.round(qty));
        this.control.markAsTouched();
        this.control.markAsDirty();
    }

    updateQty(newValue: number) {
        const d = (this.control.field as DurationField).duration;
        console.log({ u: d.unit, q: d.qty, newQ: newValue });
        if (d.unit === 'Hours') {
            this.setNewValue(newValue * 60);
        } else if (d.unit === 'Days') {
            this.setNewValue(newValue * 60 * 8);
        } else if (d.unit === 'Minutes') {
            this.setNewValue(newValue);
        }
    }
}
