/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Inject, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { ReplaySubject, combineLatest, Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';
import { BankAccount } from 'src/app/model/bankAccount';
import { Cycle } from 'src/app/model/cycle';
import { Field } from 'src/app/shared/field/Field';
import { Supplier } from 'src/app/model/supplier';
import { Txn } from 'src/app/model/txn';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { AppFormControl } from 'src/app/shared/form/app-form-control';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { FormComboBoxComponent } from 'src/app/shared/form/form-combo-box/form-combo-box.component';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { FormError } from 'src/app/shared/form/form-error/form-error.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { AppPicklistControl, FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { GridControl } from 'src/app/shared/grid/grid-control';
import { GridField } from 'src/app/shared/grid/grid-field';
import { GridRow } from 'src/app/shared/grid/grid-row';
import { validateTxnDate, required } from 'src/app/shared/validators';
import { BCodeService } from '../../budget/bcode.service';
import { CycleService } from '../../budget/cycle.service';
import { PeriodService } from '../../budget/period.service';
import { PreferredSupplierService } from '../../supply/preferred-supplier.service';
import { UnitService } from '../../unit/unit.service';
import { CurrentUserService } from '../../user/current-user.service';
import { BankAccountService } from '../bank-account.service';
import { BankOutService } from '../bank-out.service';
import { PicklistField } from 'src/app/shared/field/PicklistField';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { NavRoute } from 'src/app/shared/NavRoute';
import { User } from 'src/app/model/user';
import { TxnService } from '../txn.service';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogOptions } from 'src/app/shared/dialogs/pick-dialog/pick-dialog.component';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';

@Component({
    selector: 'app-bank-out-page',
    templateUrl: './bank-out-page.component.html',
    styleUrls: ['./bank-out-page.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class BankOutPageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute(Txn.TYPE.BANK_OUT.code, BankOutPageComponent, 'call_made')
        .setViews((u: User) => {
            return [
                {
                    id: 'currentYear',
                    name: 'Current Year',
                    filterFields: {
                        txnCycleId: u.currentTeam.currentPeriod?.cycleId,
                        bankAccountId: 'All'
                    },
                }
            ]
        });

    readonly path = Txn.TYPE.BANK_OUT.code

    suppliers: Supplier[] = [];

    cycleField: PicklistField = FormPicklistComponent.make('Cycle', 'txnCycleId', 'txnCycle',
        { service: this.cycleSvc }, { formColumn: 2, visible: Field.noShow }
    );

    periodField: Field = FormPicklistComponent.make('Period', 'txnPeriodId', 'txnPeriod',
        { service: this.periodSvc },
        {
            formColumn: 2, visible: Field.noShow,
            refresh: (o: Cycle, control: AppPicklistControl) => { if (o) { control.field.picklist.items = o.periods; control.setValue(null); } },
        }
    );

    txnDateField: Field = FormDateComponent.make('Transaction Date', 'txnDate', {
        cellOpts: { width: '2%' },
        validators: [validateTxnDate(this.cycleField, this.periodField)],
        valueChanges: this.dateValueChanges.bind(this)
    });

    totalField = FormNumberComponent.make("Total", "credit",
        { format: 'currency', width: 9, formatParms: '1.2-2' },
        { formColumn: 2, readonly: true, validators: [required] }
    );

    invoices: Txn[];

    childTxn = new GridField({
        field:
        {
            label: Txn.TYPE.PURCHASE.name, value: 'details',
            visible: Field.formOnly, formRow: 2
        },
        rowFactory: (o: Txn) => [
            //FieldMaker.id({visible:Field.showAll, cellOpts:{width:"9em"}}),
            //FieldMaker.rev(),
            FieldMaker.id(),
            FormTextComponent.make('ledgerId', 'ledgerId', { visible: Field.noShow }),
            FormTextComponent.make('typeId', 'typeId', { visible: Field.noShow }),
            FormTextComponent.make('bCodeId', 'bCodeId', { visible: Field.noShow }),

            FormTextComponent.make('txnDate', 'txnDate', { visible: Field.noShow }),
            FormTextComponent.make('txnCycleId', 'txnCycleId', { visible: Field.noShow }),
            FormTextComponent.make('txnPeriodId', 'txnPeriodId', { visible: Field.noShow }),

            FormPicklistComponent.make('Type', 'related.typeId', 'related.type',
                { items: Txn.TYPES },
                { sendServer: false, readonly: true, disable: true, cellOpts: { minWidth: "9em" } }
            ),

            FormButtonComponent.makeLink('Ref', 'related.refNr', o ? Txn.getTxnLink(o.related) : '', {
                readonly: true, sendServer: false,
                cellOpts: { width: "6em", maxWidth: "10em" },
            }),
            FieldMaker.idHolder('relatedId'),

            /* Show supplier... FormNumberComponent.make("Supplier", "supplierId", {},
                { visible: Field.noShow }
            ),*/

            FormNumberComponent.make("Ref", "relatedRev", {},
                { visible: Field.noShow, cellOpts: { width: "3em", maxWidth: "3em" } }
            ),
            FormDateComponent.make('Date', 'related.txnDate',
                { sendServer: false, disable: true, cellOpts: { minWidth: "6em" } }
            ),
            FormPicklistComponent.make('Supplier', 'supplierId', null, {items: this.suppliers}, {readonly: true}),
            // Why Do we need it?
            FormTextComponent.make('Cycle', 'related.txnCycle.name',
                { cellOpts: { minWidth: "6em" }, sendServer: false, disable: true, visible: Field.noShow }
            ),

            FormNumberComponent.make("Amount", 'related.credit',
                { format: 'currency', width: 9, formatParms: '1.2-2' },
                { cellOpts: { minWidth: "6em", maxWidth: "6em" }, sendServer: false, disable: true, readonly: true }
            ),
            FormNumberComponent.make("Outstanding", "related.outstanding",
                { format: 'currency', width: 9, formatParms: '1.2-2' },
                { cellOpts: { minWidth: "6em", maxWidth: "6em" }, sendServer: false, disable: true, readonly: true }
            ),
            FormNumberComponent.make("Pay now", "debit",
                { format: 'currency', width: 9, formatParms: '1.2-2' },
                { valueChanges: this.totalValueChanged.bind(this) }
            ),
            FormButtonComponent.make('Pay', '', {
                name: 'action', label: '', type: 'icon', sendServer: false,
                cellOpts: { heading: '', width: '1%' },
                calculateValue: () => 'assignment_turned_in',
                toolTip: 'Click to pay this item in full',
                btnOpts: { clickMethod: this.allocatePayment.bind(this) }
            }),

            FieldMaker.notes({ cellOpts: { width: "50%" } }),
            FieldMaker.rev(),
            FieldMaker.deleteGridRow({ clickMethod: this.removeAllocationRow.bind(this) }),
        ]
    });

    configReady = new ReplaySubject<null>(1);
    configSetup = false;

    supplierCombo = FormComboBoxComponent.make('Supplier', 'supplierId', 'supplier', {
        items:this.suppliers, refreshes: [this.refreshInvoices.bind(this)]
    }, { formColumn: 3, sendServer: false, validators: [required] });

    config = new FormConfig({
        navRoute: BankOutPageComponent.navRoute,
        title: Txn.TYPE.BANK_OUT.name,
        help: $localize`Financial transactions`,
        readonly: true,
        fieldSet: new FieldSet({
            fields: [
                FieldMaker.id(),
                FieldMaker.rev(),
                FormNumberComponent.make('Reference', 'refNr', {}, { cellOpts: { heading: 'Ref' }, readonly: true, formColumn: 1 }),
                FormTextComponent.make('ledgerId', 'ledgerId', { visible: Field.noShow }),
                this.txnService.getTypeField(),
                FormPicklistComponent.make('Bank Account', 'bankAccountId', 'bankAccount',
                    { service: this.bankAccountSvc, refreshes: ['bCodeId'] },
                    { validators: [required] }
                ),
                FormPicklistComponent.make('Account', 'bCodeId', 'bCode', { service: this.bCodeSvc },
                    {
                        formColumn: 3, validators: [required], visible: Field.noShow,
                        refresh: (o: BankAccount, control) => { control.setValue(o.bCodeId); }
                    }
                ),

                /* txtDateField Validator overwrites period info, need this order! */
                this.cycleField,
                this.periodField,
                this.txnDateField,
                this.totalField,

                this.supplierCombo,
                FieldMaker.notes({ formColumn: 4 }),

                this.childTxn,
            ],
            formValidator: this.validatePayment.bind(this),
            formLayout: [
                { cells: [{ width: '25%' }, { width: '25%' }, { width: '25%' }, { width: '25%' }] },
                { cells: [{ colspan: 4, width: '90%', pageTab: 'yes' }] }
            ],
        }),
        service: this.dataSvc,
        mode: 'list',
        objectFactory: this.newFactory.bind(this),
        configReady: this.configReady,
        beforeEdit: this.enhanceObject.bind(this),
        pathEdit: (o: Txn) => Txn.getType(o.typeId).code + '/' + o.id
    });
    defaultBank: BankAccount;

    constructor(public dataSvc: BankOutService, public bCodeSvc: BCodeService,
        protected activeRoute: ActivatedRoute, private txnService: TxnService,
        protected unitSvc: UnitService, supplierSvc: PreferredSupplierService,
        protected cycleSvc: CycleService, protected periodSvc: PeriodService,
        protected bankAccountSvc: BankAccountService,
        private currentUserSvc: CurrentUserService, private cds: ConfirmDialogService,
        @Optional() public dialogRef: MatDialogRef<BankOutPageComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public dialogOptions: DialogOptions) {

        super();

        combineLatest([
            currentUserSvc.getCurrentUser(),
            this.bankAccountSvc.get(true).pipe(first()),
            supplierSvc.get(true),
            this.currentUserSvc.getDefaultBCodes(),
        ]).subscribe(
            ([currentUser, bankAccounts, suppliers]) => {
                for (const acct of (bankAccounts as BankAccount[])) {
                    if (acct.defaultAcct) {
                        this.defaultBank = acct;
                    }
                }
                this.currentUser = currentUser;
                this.suppliers = suppliers as Supplier[];
                this.supplierCombo.setPicklistItems(suppliers);
                this.configReady.next(null);
                this.configSetup = true;
            });
    }

    enhanceObject(o: Txn) {
        this.config.readonly = true;
        return o;
    }

    dateValueChanges(): void {
        const allocations = (this.childTxn.control as GridControl).gridRows();
        const txnDate = this.txnDateField.getFormValue();
        if (allocations) {
            for (const txnRow of allocations) {
                const invDt = txnRow.get('related_txnDate').value;
                const refNr = txnRow.get('related_refNr').value;
                txnRow.get('txnDate').setValue(this.txnDateField.control.value, { emitEvent: false });
                txnRow.get('txnCycleId').setValue(this.cycleField.control.value, { emitEvent: false });
                txnRow.get('txnPeriodId').setValue(this.periodField.control.value, { emitEvent: false });
                if (invDt > txnDate) {
                    this.warnDateTooEarly(txnRow.get('debit') as AppFormControl, txnDate, invDt, refNr);
                }
            }
        }
    }

    totalValueChanged(value): void {
        let total = 0;
        const txnDate = this.txnDateField.getFormValue()
        for (const gridRow of (this.childTxn.control as GridControl).gridRows()) {
            const invDt = gridRow.get('related_txnDate').value;
            const refNr = gridRow.get('related_refNr').value;
            const invValue = gridRow.get('debit').value;
            if (invDt > txnDate && value && invValue === value) {
                this.warnDateTooEarly(gridRow.get('debit') as AppFormControl, txnDate, invDt, refNr);
            }
            total += gridRow.get('debit').value;
        }
        this.totalField.control.setValue(total);
    }


    refreshInvoices(supplier: Supplier) {
        this.dataSvc.getOutstanding(supplier.id).subscribe(invoices => {
            console.log(invoices);
            const openTrans = invoices; //.sort(this.txnSorter); // Txn.TYPE.BANK_IN_ON_ACCT.id
            const credits = openTrans.filter(o => o.outstanding < 0);
            for (const inv of credits) {
                this.createAllocation(inv, supplier);
            }
            const debits = openTrans.filter(o => o.outstanding > 0);
            for (const inv of debits) {
                this.createAllocation(inv, supplier);
            }
        });
    }
    createAllocation(inv: Txn, supplier: Supplier) {
        console.log('Allocation ', inv, supplier);
        const newTxn = this.newAllocationTxn(supplier);
        newTxn.relatedId = inv.id;
        newTxn.relatedRev = inv.revision;
        newTxn.related = (inv as Txn);
        //const newRow: GridRow =
        this.childTxn.control.addRow(newTxn, true, false);
    }

    newAllocationTxn(supplier: Supplier): Txn {
        const newTxn = new Txn();
        newTxn.ledgerId = Txn.LEDGER.AP.id;
        newTxn.ledger = Txn.LEDGER.AP;
        newTxn.typeId = Txn.TYPE.BANK_OUT_ALLOC.id;
        newTxn.type = Txn.TYPE.BANK_OUT_ALLOC;
        newTxn.bCodeId = this.currentUserSvc.getDefaultBCode('Creditors').bCodeId;
        newTxn.txnDate = this.txnDateField.control.value;
        newTxn.txnCycleId = this.cycleField.control.value;
        newTxn.txnPeriodId = this.periodField.control.value;

        newTxn.supplierId = supplier.id;

        return newTxn;
    }

    allocatePaymentToRow(gridRow: GridRow) {
        const outstanding = gridRow.get('related_outstanding');
        const credit = gridRow.get('credit');
        const unallocated = this.page.form.formGroup.get('unallocated').value + credit.value;
        const actionBtn = (gridRow.get('action') as AppFormControl);

        if (actionBtn.value === 'backspace') {
            credit.setValue(0);
        } else {
            if (unallocated > 0 && outstanding.value > 0) {
                credit.setValue(Math.min(outstanding.value, unallocated));
            } else if (outstanding.value < 0) {
                credit.setValue(outstanding.value);
            } else if (gridRow.get('relatedId').value === null) {
                credit.setValue(unallocated);
            }
        }
    }

    warnDateTooEarly(debit: AppFormControl, txnDate, invDate, refNr) {
        const tDate = new Date(txnDate).toLocaleDateString();
        const iDate = new Date(invDate).toLocaleDateString();
        const options = {
            title: $localize `Payment Transaction Date Too Early?`,
            msg: $localize`The payment transaction date ${tDate} is before ${iDate} the transaction date
                of ${refNr} the invoice you are paying`,
            warning: true,
            options: [
                { name: $localize`Continue` },
                { name: $localize`Cancel`, action: () => {
                    debit.setValue(0.00);
                }}
            ]
        }
        this.cds.openChoice(options, false)
    }

    allocatePayment(ctl: AppFormControl): void {
        const gridRow = ctl.getRow()

        const outstanding = gridRow.get('related_outstanding');
        const debit = gridRow.get('debit') as AppFormControl;
        const actionBtn = (gridRow.get('action') as AppFormControl);

        console.log({ outstanding, debit, actionBtn });
        if (actionBtn.value === 'backspace') {
            debit.setValue(0);
            actionBtn.setValue('assignment_turned_in');
        } else {
            debit.setValue(outstanding.value);
            actionBtn.setValue('backspace');
        }
    }

    removeAllocationRow(ctl: AppFormControl): void {
        if (ctl.isGridCell) {
            ctl.getRow().delete();
            this.totalValueChanged(null);
        }
    }
    newFactory(): Observable<Txn> {
        const txn = new Txn();
        txn.ledgerId = Txn.LEDGER.AP.id;
        txn.ledger = Txn.LEDGER.AP;

        txn.typeId = Txn.TYPE.BANK_OUT.id;
        txn.type = Txn.TYPE.BANK_OUT;

        txn.bankAccountId = this.defaultBank.id;
        txn.bankAccount = this.defaultBank;

        txn.bCodeId = this.defaultBank.bCodeId;

        txn.txnDate = moment().toISOString().substring(0, 10);
        //validateTxnDate(this.cycleField, this.periodField)(this.)

        this.config.readonly = false;

        return of(txn);
    }

    validatePayment() {

        if (this.totalField?.control?.value === 0) {
            return FormError.reportError('paymentLineItems', $localize`Cannot record a payment of zero!`);
        }

        return null;
    }
}
