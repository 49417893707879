@if (!table) {
  <mat-card-actions align="center">
    @if (pageForm) {
      <div style="display:flex; width: calc(100% - 20px); text-align:left; padding: 0px 10px 0px; margin-bottom: 10px">
        @if (showSave && !readonly) {
          <div style="flex:1" >
            <button (click)="saveChanges()" mat-raised-button color="primary"
              data-save-button
              [disabled]="!pageForm.valid || pageForm.pristine">
              <mat-icon>save</mat-icon>Save
            </button>
          </div>
        }
        @if (showSaveAndNew && !readonly) {
          <div style="flex:1">
            <button (click)="saveChangesAndNew()" mat-raised-button
              color="primary" [disabled]="!pageForm.valid || pageForm.pristine">
              <mat-icon>save</mat-icon>Save<mat-icon>add</mat-icon>New
            </button>
          </div>
        }
        @if (!pageForm.pristine || myMode === 'new') {
          <div style="flex:1">
            <button (click)="cancelChanges()" mat-raised-button
              data-cancel-button
              color="accent">
              <mat-icon>cancel</mat-icon><span>Cancel</span>
            </button>
          </div>
        }
        @if (!pageForm.valid) {
          <div style="flex:10; text-align:center">
            @if (showTheErrors) {
              <mat-error>
                <app-form-error [(control)]="pageForm"></app-form-error>
              </mat-error>
            }
          </div>
        }
        <div style="flex:10; text-align:center">
          @if (!pageForm.valid) {
            <button mat-raised-button (click)="showErrors()" >
              <mat-icon>warning</mat-icon><span i18n>Show Errors</span>
            </button>
          }
        </div>
        @if (showDelete && allowDelete) {
          <div style="flex:1; text-align:right">
            <button (click)="deleteObject()" mat-raised-button color="warn" data-delete-button>
              <mat-icon>delete</mat-icon><span>Delete</span>
            </button>
          </div>
        }
      </div>
    }
  </mat-card-actions>
}
