<div class="basePage">
    <div class="registerCard">
        <h1>
            Trial Balance Import {{myData?.cycle?.name}}
        </h1>

        <h2>
            {{companyName}}
        </h2>
        <ng-container [ngTemplateOutlet]="getImportFile"></ng-container>
        <ng-container [ngTemplateOutlet]="importLinesTable"></ng-container>
        <ng-container [ngTemplateOutlet]="warningsList"></ng-container>
    </div>
</div>

<ng-template #getImportFile>
    @if (myData && !gotImportFile) {
        <input type="file" #fileDropRef id="fileDropRef" (change)="importFile($event.target.files)" />
    }
</ng-template>

<ng-template #importLinesTable>
    @if (importLines.length) {
        <table width="100%">
            <ng-container [ngTemplateOutlet]="headerRow"></ng-container>
            @for (i of importLines; track $index) {
                <ng-container [ngTemplateOutlet]="importLineRow" [ngTemplateOutletContext]="{row: i}"></ng-container>
            } 
            
            <ng-container [ngTemplateOutlet]="totalRow"></ng-container>
            <ng-container [ngTemplateOutlet]="showBCodeWithBalances"></ng-container>
            <ng-container [ngTemplateOutlet]="showBCodeWithoutBalances"></ng-container>
        </table>
    }
</ng-template>

<ng-template #headerRow>
    <tr>
        <th></th>
        <th></th>
        <th colspan="2">Import Values</th>
        <th></th>
        <th></th>
        <th colspan="2">Current Values</th>        
        <th colspan="2">Required Journal</th>
    </tr>    
    <tr>
        <th>Sort</th>
        <th>Description</th>
        <th>Debit</th>
        <th>Credit</th>
        <th></th>
        <th></th>
        <th>Debit</th>
        <th>Credit</th>
        <th>Debit</th>
        <th>Credit</th>                
    </tr>
</ng-template>

<ng-template #showBCodeWithBalances>
    <tr>
        <th colspan="99" style="padding-top: 20px; font-weight: bold;">
            The following Budget Codes have balances and need to be mapped to your trial balance.
            If you add an entry with the same name to your file, with zero value, an appropriate offset 
            journal will be created to cancel this balance
        </th>
    </tr>
    @for (bc of availBCodes; track $index) {
        @if (bc.debits || bc.credits) {
            <ng-container [ngTemplateOutlet]="showBCode" [ngTemplateOutletContext]="{bc}"></ng-container>
        }
    }
</ng-template>

<ng-template #showBCodeWithoutBalances>
    <tr>
        <th colspan="99" style="padding-top: 20px; font-weight: bold;">
            The following Budget Codes exist with zero balances
        </th>
    </tr>
    @for (bc of availBCodes; track $index) {
        @if (!bc.debits && !bc.credits) {
            <ng-container [ngTemplateOutlet]="showBCode" [ngTemplateOutletContext]="{bc}"></ng-container>
        }
    }
</ng-template>

<ng-template #showBCode let-bc="bc">
    <tr>
        <td>{{bc.sort}}</td>
        <td>
            {{bc.name}}
        </td>
        <td></td>
        <td></td>
        <td>{{bc.description}}</td>
        <td>{{bc.type.name}}</td>
        <td style="text-align: right;">
            @if (bc.debits) {
                {{bc.debits | currency}}
            }            
        </td>
        <td style="text-align: right;">
            @if (bc.credits) {
                {{bc.credits | currency}}
            }            
        </td>
        <td></td>
        <td></td>
    </tr>
</ng-template>

<ng-template #importLineRow let-row="row">
    <tr>
        <td>{{row.sort}}</td>
        <td>
            {{row.description}}
            @if (row.bCode) {
                <mat-icon style="font-size: 18px;height: 14px" color="primary">check</mat-icon>
            }
        </td>
        <td style="text-align: right;">@if (row.debit) {
                {{row.debit | currency}}
            }</td>
        <td style="text-align: right;">
            @if (row.credit) {
                {{row.credit | currency}}
            }
        </td>
        <td style="max-width: 30em;">
            @if (row.field) {
                <app-ctl-holder [control]="row.field.control" on="'grid'"></app-ctl-holder>
            }
        </td>
        <td>
            @if (row.field) {
                @if (row.description?.length < 30) {
                    <button mat-raised-button (click)="createBcode4Line(row)">Create</button>
                } @else {
                    Desc Too Long!
                }
            }
        </td>
        <td style="text-align: right;">
            @if (row.bCode?.debits) {
                {{row.bCode.debits | currency}}
            }            
        </td>
        <td style="text-align: right;">
            @if (row.bCode?.credits) {
                {{row.bCode.credits | currency}}
            }            
        </td>
        <td style="text-align: right;">
            @if (row.needsDebit) {
                {{row.needsDebit | currency}}
            }            
        </td>
        <td style="text-align: right;">
            @if (row.needsCredit) {
                {{row.needsCredit | currency}}
            }            
        </td>
    </tr>
</ng-template>

<ng-template #totalRow>
    <tr style="font-weight: bold;">
        <td></td>
        <td style="text-align: right;">Totals</td>
        <td>{{totalDebit | currency}}</td>
        <td>{{totalCredit | currency}}</td>
    </tr>
</ng-template>

<ng-template #warningsList>
    @if (warnings.length) {
        <p style="color:orange; margin-top: 20px">{{warnings.length}} Warnings</p>
        <ul>
            @for (w of warnings; track $index) {
                <li>{{w}}</li>
            }
        </ul>
    }

</ng-template>