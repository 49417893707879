/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { ConfirmDialogService } from "src/app/shared/dialogs/confirmDialog";
import { ScheduleService } from "../../budget/schedule.service";
import { CurrentUserService } from "../../user/current-user.service";
import { UserService } from "../../user/user.service";
import { BatchType, ImportParser } from '../import-page/Import-parser-interface';
import { ImportDoc } from "../ImportDoc";
import { ImportField } from "../ImportField";
import { ImportRow } from "../ImportRow";
import { AbstractObjectList } from "src/app/model/abstract-object";
import { BMUtils } from "./BMUtils";
import { MyInjector } from "src/app/app.module";
import { HttpClient } from "@angular/common/http";
import { AbstractHttpService } from "src/app/shared/abstract-http.service";
import { MessageService } from "src/app/shared/message.service";
@Injectable({
    providedIn: 'root'
})
export class BM4ParserService implements ImportParser {

    batchType: BatchType = 'BM4';

    rowsIn: unknown[][];
    importDoc: ImportDoc = new ImportDoc();

    headerRow: ImportRow;
    schedules: number;

    bmUtils = new BMUtils();

    constructor(private currentUserSvc: CurrentUserService, private scheduleSvc: ScheduleService,
        private userSvc: UserService, private cds: ConfirmDialogService) {
    }

    setUp() {
        return of(true);
    }

    parseRows(rows: unknown[][]): ImportDoc {
        this.importDoc = new ImportDoc();
        this.rowsIn = rows;
        // Row 0 Is Blank line!
        if (this.getHeaderRow(this.rowsIn[0])) {
            let nextRow = 1;
            while (nextRow < (rows.length - 1)) {
                const row = this.getLineItemFields().parse(this.rowsIn[nextRow]);
                this.importDoc.add(row);
                nextRow++;
            }
        }
        this.addFileNotes();
        this.addInputs();

        console.warn(this.importDoc);
        return this.importDoc;
    }

    private addInputs() {
        /* No inputs needed...
        const teamNameField = FormTextComponent.make('Team Name', 'teamName');
        this.importDoc.addInput(new ImportInput('teamName', [teamNameField], 'Enter the short name for the OMC'));

        const slugField = FormTextComponent.make('Slug', 'teamName', {hint: "The unique unchangeable identifier for the team"});
        this.importDoc.addInput(new ImportInput('slug', [slugField], 'Enter the short name for the OMC'));
        */
    }

    private getHeaderRow(data: unknown[]) {

        const header = this.getHeaderFields().parse(data);
        this.schedules = header.getNrValue('schedules');

        if (this.schedules <= 0 || this.schedules >= 11) {
            header.addError('File stated to have ' + this.schedules + 'At least 1 and less than 11 expected');
        }
        if (header.fullErrorCount() > 0) {
            this.importDoc.add(header);
            this.importDoc.addNote('Headers Invalid - File not importable');
            return false;
        } else {
            this.headerRow = header;
            return true;
        }
    }

    addFileNotes() {
        let msg = 'File contains ' + this.importDoc.getGeneralRows().length;
        msg += ' units for : ' + this.headerRow.getStringValue('companyName');
        this.importDoc.addNote(msg);
        this.importDoc.addNote('File contains ' + this.schedules + ' schedules');
    }

    getHeaderFields() {
        //1|80|5|0|0|Ardilaun CourtNo. 1 Sybil Hill Owners' Management Company Company Limited By Guarantee
        const row = new ImportRow();
        row.add(new ImportField('pos1').require().allow(new Map([['1', '1'], ['0', '0']])));
        row.add(new ImportField('units').require().asNumber());
        row.add(new ImportField('schedules').require().asNumber());
        row.add(new ImportField('pos4').require().asNumber());
        row.add(new ImportField('pos5').require().asNumber());
        row.add(new ImportField('companyName').require());
        return row;
    }

    getLineItemFields(): ImportRow {
        const row = new ImportRow();

        row.add(new ImportField('name').require());

        row.add(new ImportField('part_address').require());
        row.add(new ImportField('size').asNumber());
        row.add(new ImportField('type').allow(new Map([
            ['0', 'Apartment'], ['1', 'Duplex'], ['2', 'House'], ['3', 'Car Space'], ['4', 'Commercial Unit']
        ])));

        row.add(new ImportField('ownerOccupied').allow(new Map([['0', true], ['4', false]])));
        row.add(new ImportField('bedrooms').asNumber());
        row.add(new ImportField('floor').asNumber());

        row.add(new ImportField('typeDetail'));

        row.add(new ImportField('ownerName').require());
        row.add(new ImportField('ownerPhone'));
        row.add(new ImportField('notes').withBMLineFeeds());
        row.add(new ImportField('ownerEmail')); //SHould have this or phone
        row.add(new ImportField('mailingName'));
        row.add(new ImportField('salutation'));
        row.add(new ImportField('more_notes').withBMLineFeeds());
        row.add(new ImportField('position16').allow(new Map([['true', 'TRUE'], ['false', 'FALSE']])));
        row.add(new ImportField('position17').allow(new Map([['true', 'TRUE'], ['false', 'FALSE']])));

        row.add(new ImportField('car_park_spaces').asNumber());
        row.add(new ImportField('car_park_space_names'));
        row.add(new ImportField('membershipDate').fromBMDate());
        row.add(new ImportField('owner_from_date').fromBMDate());
        row.add(new ImportField('ownerAddress').withBMLineFeeds());

        row.add(new ImportField('payment_method').allow(new Map([['1', 'Cheque'], ['2', 'Standing Order'], ['3', 'Online Payment'], ['0', 'DirectDebit']])));
        row.add(new ImportField('paymentNotes').withBMLineFeeds());
        row.add(new ImportField('paymentNotes2').withBMLineFeeds());
        row.add(new ImportField('Position26').expectEmpty());
        row.add(new ImportField('onHoldReason').withBMLineFeeds());
        row.add(new ImportField('withSolicitor').allow(new Map([['false', 'FALSE'], ['true', 'TRUE']])));
        row.add(new ImportField('Position29').allow(new Map([['false', '']])));
        row.add(new ImportField('Position30').allow(new Map([['false', '']])));
        row.add(new ImportField('creditBalance').allow(new Map([['false', 'FALSE'], ['true', 'TRUE']])));
        row.add(new ImportField('withSolicitorDate').fromBMDate());
        row.add(new ImportField('Position33').allow(new Map([['NULL', '']])));
        row.add(new ImportField('Position34').allow(new Map([['NULL', '']])));
        row.add(new ImportField('Position35').allow(new Map([['NULL', '']])));
        row.add(new ImportField('onHoldDate').fromBMDate());

        /*
        row.add(new ImportField('keyholderName'));
        row.add(new ImportField('keyholderPhone'));
        row.add(new ImportField('keyholderEmail'));

        row.add(new ImportField('keyholder2Name'));
        row.add(new ImportField('keyholder2Phone'));
        row.add(new ImportField('keyholder2Email'));

        row.add(new ImportField('alarm_company'));
        row.add(new ImportField('alarm_company_phone'));

        row.add(new ImportField('tenantName'));
        row.add(new ImportField('tenantPhone'));
        row.add(new ImportField('tenantEmail'));

        row.add(new ImportField('tennantNotes').withBMLineFeeds());
        row.add(new ImportField('otherTenant'));
*/
        row.add(new ImportField('otherContactNotLoaded').withBMLineFeeds());
        row.add(new ImportField('otherContactDetails').withBMLineFeeds());
        row.add(new ImportField('position52').allow(new Map([['NULL', '']])));
        row.add(new ImportField('interestedParties').withBMLineFeeds());

        /** Need as many as asked for in header */
        for (let i = 1; i <= this.schedules; i++) {
            row.add(new ImportField('schedule_' + i).require().asNumber());
        }

        row.add(new ImportField('trailingDelim').expectEmpty());

        return row;
    }

    postToServer() {
        const rows = this.importDoc.getRows();
        const itemList = [];
        rows.forEach( uRow => {
            const unit = uRow.getDataAsItem();
            const people = [];
            unit['people'] = people;
            this.bmUtils.getOwnerDetails(uRow, people);
            //unit.notes = unit.people[0].notes;
/* APPEAR TO BE REMOVE - ESKER
            this.bmUtils.getKeyholderDetails(uRow, people);
            this.bmUtils.getKeyholder2Details(uRow, people);
            this.bmUtils.getTenantDetails(uRow, people);
*/
            itemList.push(unit);
        })
        const data = this.headerRow.getDataAsItem();
        data['itemList'] = itemList;

        return this.reallyPost(data);
    }

    private reallyPost(data) {
        const http = MyInjector.instance.get(HttpClient);
        const url = AbstractHttpService.ajaxPath + 'importFile/' + this.batchType;
        return http.post<AbstractObjectList>(url, data);
    }

    private downLoadData(data){
        const msgSvc = MyInjector.instance.get(MessageService);
        msgSvc.show('With over 250 units, you will need to download file and send it to us for processing');
        const jsonData = JSON.stringify(data);
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/json;charset=UTF-8,' + encodeURIComponent(jsonData));
        element.setAttribute('download', 'bm4.json');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click(); // simulate click
        document.body.removeChild(element);

        return of({ itemList: data } as AbstractObjectList)
    }
}
