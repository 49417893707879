<mat-dialog-content>
  <table>
    <tr>
      <th  class="mat-header-cell" colspan="20">
        Adjust Columns Displayed
      </th>
    </tr>
    <tr>
      <th class="mat-header-cell">Column</th>
      <th class="mat-header-cell">Computer</th>
      <th class="mat-header-cell">Phone</th>
      <th class="mat-header-cell">Move Up</th>
      <th class="mat-header-cell">Move Down</th>
    </tr>
    @for (col of columns; track col; let i = $index; let first = $first; let last = $last) {
      <tr>
        <td class="mat-cell">{{col.name}}</td>
        <td class="mat-cell">
          @if (col.visible.computer) {
            <mat-icon style="color:green" (click)="col.visible.computer=false; this.refresh()">toggle_on</mat-icon>
          }
          @if (!col.visible.computer) {
            <mat-icon style="color:darkred" (click)="col.visible.computer=true; this.refresh()">toggle_off</mat-icon>
          }
        </td>
        <td class="mat-cell">
          @if (col.visible.phone) {
            <mat-icon style="color:green" (click)="col.visible.phone=false; this.refresh()">toggle_on</mat-icon>
          }
          @if (!col.visible.phone) {
            <mat-icon style="color:darkred" (click)="col.visible.phone=true; this.refresh()">toggle_off</mat-icon>
          }
        </td>
        <td class="mat-cell">
          @if (!first) {
            <button mat-icon-button color="primary" class="small" (click)="push(i, true)">
              <mat-icon>arrow_upward</mat-icon>
            </button>
          }
        </td>
        <td class="mat-cell">
          @if (!last) {
            <button mat-icon-button color="primary" class="small" (click)="push(i, false)">
              <mat-icon>arrow_downward</mat-icon>
            </button>
          }
        </td>
      </tr>
    }
  </table>
</mat-dialog-content>
