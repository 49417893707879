
/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { AbstractObject } from 'src/app/model/abstract-object';
import { ServiceRequest } from 'src/app/model/serviceRequest';
import { ActionColor, IFormAction } from 'src/app/shared/form/form.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { MemoField } from "src/app/shared/memos/MemoField";
import { RequestService } from '../../request.service';
import { ResolveRequestComponent } from './resolve-request.component';
import { MyInjector } from 'src/app/app.module';
import { ReopenRequestComponent } from '../reopen-request/reopen-request.component';

export class ResolveRequestAction implements IFormAction {
    name = $localize`Close`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'done_all';
    approvalNeeded = false;
    disabled = false;
    approvalText: string;
    tipText: string;

    sr: ServiceRequest;

    constructor(private svc: RequestService, private dialog: MatDialog) { }

    action() {
        const dialogRef = this.dialog.open(ResolveRequestComponent, {data: this.sr});
        return dialogRef.afterClosed();
    }

    setup(sr: ServiceRequest) {
        this.sr = sr;
        if (sr.srStatus === ServiceRequest.srOpen.id) {
            this.show = true;
            if (sr.subStatus === ServiceRequest.srUnplanned.id) {
                this.disabled = false;
                this.tipText = $localize`Click to mark request as completed`;
            } else {
                this.disabled = true;
                this.tipText = $localize`Cannot mark request complete until actions are completed`;
            }
        } else {
            this.show = false;
        }
    }
}

export class ReOpenRequestAction implements IFormAction {
    name = $localize`Reopen`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'lock_open_right';
    approvalNeeded = false;
    disabled = false;
    approvalText = $localize`Reopen this request for further actions`;
    tipText: string;
    sr: ServiceRequest;

    action() {
        const dialog = MyInjector.instance.get(MatDialog);
        const dialogRef = dialog.open(ReopenRequestComponent, { data: this.sr });
        return dialogRef.afterClosed();
    }
    //  setup(serviceRequest: ServiceRequest) {
    setup(sr: ServiceRequest) {
        this.sr = sr;
        if (sr.srStatus === ServiceRequest.srResolved.id) {
            this.show = true;
        } else {
            this.show = false;
        }
    }
}

export class AcceptRequestAction implements IFormAction {
    name = $localize`Accept`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'thumb_up';
    approvalNeeded = true;
    disabled = false;
    approvalText = $localize`Are you sure you wish to accept this request? Once accepted it can not be reopened or modified. The original requestor should accept the request, or at least have been notified and given time to object before accepting`;
    tipText: string;

    action(sr: ServiceRequest) {
        const rs = MyInjector.instance.get(RequestService);
        return rs.acceptRequest(sr)
    }
    //  setup(serviceRequest: ServiceRequest) {
    setup(sr: ServiceRequest) {
        if (sr.srStatus === ServiceRequest.srResolved.id) {
            this.show = true;
        } else {
            this.show = false;
        }
    }
}
export class AddActionFormAction implements IFormAction {
    name = $localize`Add Action`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'format_list_bulleted_add';
    approvalNeeded = false;
    disabled = false;
    approvalText = '';
    tipText: string;

    constructor(private addFn: () => Observable<AbstractObject>) { }

    action(sr: ServiceRequest, config: FormConfig) {
        //const update = { id: sr.id, revision: sr.revision, srStatus: ServiceRequest.srOpen.id } as ServiceRequest;
        return this.addFn().pipe(
            mergeMap((action) => {
                return of(action).pipe(() => config.service.getOne(sr.id, null));
            }));
    }
    //  setup(serviceRequest: ServiceRequest) {
    setup(sr: ServiceRequest) {
        if (sr.srStatus === ServiceRequest.srOpen.id) {
            this.show = true;
        } else {
            this.show = false;
        }
    }
}
export class AddMemoAction implements IFormAction {
    name = $localize`Add Note`;
    color: ActionColor = 'primary';
    show = true;
    icon = 'add_notes';
    approvalNeeded = false;
    disabled = false;
    approvalText = '';
    tipText = 'Click to add note visible to agency staff';

    sr: ServiceRequest;

    constructor(private memoField: MemoField) { }

    action() {
        return this.memoField.createNewMemo().pipe(map(newNote => {
            if (newNote) {
                this.sr.memos.push(newNote);
                return this.sr;
            }
        }));
    }

    setup(sr: ServiceRequest) {
        this.sr = sr;
    }
}
