@if (on === 'form') {
  <mat-form-field style="height: 74px">
    <mat-label>{{control.field.label}}</mat-label>
    <input matInput  id="{{control.field.name}}-NumberField"
      [value]="control.field.formatValue(control.value)" [disabled]="control.disabled"
      (blur)="saveNumberValue($event)"
      [size]="size()" [style.width.em]="size()"
      (click)="$event.target.select()"
      [matTooltip]="control.invalid ? control.getErrorMessage() : ''"
      [ngClass]="{'error': control.invalid && control.touched}"
      [autocomplete]="control.field.autoComplete"
      [readonly]="control.field.readonly ? '' : null">
    <input  [formControl]="control" style="display:none">
    @if (control.field.hint) {
        <mat-icon matSuffix color="primary" [matTooltip]="control.field.hint">info</mat-icon>
    }
    <!-- mat-error style="margin-top: -5px" mat-error does not show, due to formControl not directly set on input-->
    <div style="position: absolute; font-size: smaller; margin-top: -13px; z-index: 99">
      <app-form-error [(control)]="control"></app-form-error>
    </div>
    <!-- /mat-error -->
    <!-- in tooltip mat-hint>{{control.field.hint}}</mat-hint -->
  </mat-form-field>
}
@if (on !== 'form' && !control.field.readonly) {
    <div>
<input matInput [value]="control.field.formatValue(control.value)" [disabled]="control.disabled"
    (blur)="saveNumberValue($event)" [size]="size()" [style.width.em]="size()" (click)="$event.target.select()"
    [matTooltip]="control.getErrorMessage() + ' ' + control.field.getToolTip()"
    [ngClass]="{'error': control.invalid && control.touched}" [autocomplete]="control.field.autoComplete"
    [readonly]="control.field.readonly ? '' : null">
    </div>
}
@if (on !== 'form' && control.field.readonly) {
  {{control.field.formatValue(control.value)}}
}
