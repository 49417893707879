/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Forum, ForumPriv } from 'src/app/model/forum';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { uuid } from 'src/app/model/abstract-object';

@Injectable({
  providedIn: 'root'
})
export class ForumService extends AbstractHttpService {

  protected baseUrl = this.ajaxPath + 'social/channels';
  protected cache: Forum[];
  protected typeString = 'Forums';

  constructor(protected http: HttpClient, protected messageService: MessageService) {
    super();
  }

  getPeople(forum: Forum, priv: ForumPriv, areaId: uuid, coreId: uuid) {
    const params : {[k:string]: uuid}= { privilege: priv.id}
    if (areaId) params.areaId = areaId;
    if (coreId) params.coreId = coreId;

    return this.http.get(this.baseUrl + '/' + forum.id + '/people', { params });
  }
}
