/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { BankAccount } from 'src/app/model/bankAccount';
import { uuid } from 'src/app/model/abstract-object';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

export type ManualRec = {
    bankAccountId: uuid,
    fromDate: string,
    openingBal: number,
    toDate: string,
    closingBal: number,
}
@Injectable({
    providedIn: 'root'
})
export class BankAccountService extends AbstractHttpService {

    // protected baseUrl = this.ajaxPath + 'txns?typeId=' + Txn.TYPE.SALE.id;
    protected baseUrl = this.ajaxPath + 'bank-accounts';
    protected cache: BankAccount[];
    protected typeString = this.ajaxPath + 'bank-accounts';

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super();
    }

    getBalance<AbstractObject>(id: uuid, date: string, includeDate = false): Observable<AbstractObject> {
        const httpParams = new HttpParams().set('bankAccountId', id).set('date', date).set('includeDate', includeDate);

        return this.http.get<AbstractObject>(this.baseUrl + '/getBalance', { params: httpParams })
            .pipe(
                catchError(this.handleOneError<AbstractObject>('getBankBalance', null))
            );
    }

    postReconciliation(match: ManualRec, teamId = null) {
        const options = this.getSimpleOptions(teamId);
        return this.http.post(this.baseUrl + '/reconcile', match, options);
    }


}
