<!--
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*
-->
@if (!isVeryNarrow && control?.displayMode === 'grid') {
<div #grid_holder [style.max-height.px]="gridHeight" style="overflow:auto">
  @if (label) {
  <span class="label">{{label}}</span>
  }
  <table mat-table matSort [dataSource]="control.dataSource" class="grid" @StdAnimation style="position:relative">
    <!-- ng-content></ng-content -->
    <!-- (matSortChange)="onSort($event)" -->
    <div *ngFor="let column of control.columns; let colIndex = index">
      <ng-container [(matColumnDef)]="column.name">
        <!-- [mat-sort-header]="column.name"  Disable header sort for now prevent align right-->
        <th mat-header-cell *matHeaderCellDef style="height:50px"
          [style]="column.cellOpts.style"
          [style.width]="column.cellOpts.width" [style.min-width]="column.cellOpts.minWidth"
          [style.max-width]="column.cellOpts.maxWidth">
          {{column.cellOpts.heading}}
        </th>
        <div [ngSwitch]="column.type">
          <div *ngSwitchCase="'primary'">
            <td mat-cell *matCellDef="let gridRow" [style]="column.cellOpts.style" [style.width]="column.cellOpts.width"
              [style.min-width]="column.cellOpts.minWidth" [style.max-width]="column.cellOpts.maxWidth">
              <button (click)="column.btnOpts.clickMethod(gridRow, $event)" mat-icon-button color="primary"
                [matTooltip]="gridRow.get(column.name).field.toolTip"
                [matTooltipDisabled]="!gridRow.get(column.name).field.toolTip">
                @if (gridRow.isPrimary()) {
                <mat-icon style="color:green">toggle_on
                </mat-icon>
                } @else {
                <mat-icon style="color:gainsboro">toggle_off</mat-icon>
                }
              </button>
            </td>
          </div>
          <div *ngSwitchCase="'limitTo_OLD_NOT_USED'">
            <td mat-cell *matCellDef="let gridRow" [style]="column.cellOpts.style" [style.width]="column.cellOpts.width"
              [style.min-width]="column.cellOpts.minWidth" [style.max-width]="column.cellOpts.maxWidth">
              @if (gridRow.deleted) {
              <del>
                {{gridRow.get(column.name).field.formatValue(gridRow.get(column.name).value)}}</del>
              }
              @if (!gridRow.deleted) {
              <input matInput [formControl]="gridRow.get(column.name)" [autocomplete]="column.autoComplete"
                [matTooltip]="gridRow.get(column.name).getErrorMessage()"
                [matTooltipDisabled]="!gridRow.get(column.name).invalid"
                [ngClass]="{'error': gridRow.get(column.name).invalid && gridRow.get(column.name).touched}">
              }
            </td>
          </div>
          <div *ngSwitchDefault>
            <td mat-cell *matCellDef="let gridRow" [style]="gridRow.get(column.name).field.cellOpts?.style" [style.width]="column.cellOpts.width"
              [style.min-width]="column.cellOpts.minWidth" [style.max-width]="column.cellOpts.maxWidth">
              <!-- del *ngIf=gridRow.deleted>{{gridRow.get(column.name).field.getValue(gridRow.focus)}}</del -->
              @if (gridRow.deleted) {
              <del>
                {{gridRow.get(column.name).field.formatValue(gridRow.get(column.name).value)}}</del>
              }
              @if (!gridRow.deleted) {
              <app-ctl-holder [control]="gridRow.get(column.name)" [name]="column.name" on='grid'></app-ctl-holder>
              }
            </td>
          </div>
        </div>
        @if (control.hasFooter) {
        @if (column.footer.text) {
        <div>
          <td mat-footer-cell *matFooterCellDef [style]="column.footer.style"> {{column.footer.text}}
          </td>
        </div>
        }
        @if (!column.footer.text && column.type === 'number') {
        <div>
          <td mat-footer-cell *matFooterCellDef [style]="column.footer.style"
            style="text-align: right; font-weight: bold;">
            {{column.formatValue(control.totals.get(column.name))}} </td>
        </div>
        }
        @if (!column.footer.text && column.type !== 'number') {
        <div>
          <td mat-footer-cell *matFooterCellDef [style]="column.footer.style"></td>
        </div>
        }
        }
      </ng-container>
    </div>
    @if (heads && !label) {
    <div>
      <tr mat-header-row *matHeaderRowDef="control.columnNames; sticky: true"></tr>
    </div>
    }
    <tr mat-row *matRowDef="let row; columns: control.columnNames;" (click)="onEdit(row)"
      [ngClass]="{'highlight': row === focusItem}"></tr>
    @if (control.hasFooter) {
    <div>
      <tr mat-footer-row *matFooterRowDef="control.columnNames"></tr>
    </div>
    }
  </table>
  @if (control.gridRows().length === 0 && control.field.config.noDataDisplayText) {
  <div class="emptyMessage">
    {{control.field.config.noDataDisplayText}}
  </div>
  }
  <ng-container *ngTemplateOutlet="newItemButton"></ng-container>
</div>
}
@if (isVeryNarrow || control?.displayMode === 'accordion') {
<div>
  <span class="accordionLabel primary-font-color">{{control.field.label}}</span>
  <mat-accordion>
    <mat-expansion-panel class="basePage" *ngFor="let row of control.gridRows(); let colIndex = index"
      (opened)="selectedRow=row" (closed)="selectedRow=null" [expanded]="selectedRow === row" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon color="primary">{{row === selectedRow ? 'chevron_right' : 'expand_more'}}</mat-icon>
          {{control.rowNomenclator(row)}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="gridPanel">
        <div *ngFor="let cellField of row.fields">
          @if (cellField.visible.form && cellField.visible.phone) {
          <app-ctl-holder [control]="cellField.control"></app-ctl-holder>
          }
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <div [ngClass]="{'gridPanel': !isVeryNarrow}">
    <ng-container *ngTemplateOutlet="newItemButton"></ng-container>
  </div>
</div>
}
<ng-template #newItemButton>
  @if (!isPrint && control.objFactory && !control.field.readonly && !control.newOptionText) {
  <button (click)="addNew()" mat-icon-button color="primary" matTooltip="Add new {{control.field.label}} item">
    <mat-icon>add_circle</mat-icon>
  </button>
  }
  @if (!isPrint && control.objFactory && !control.field.readonly && control.newOptionText ) {
  <button (click)="addNew()" mat-raised-button color="primary" matTooltip="Add new item"
    style="font-size: small; height: 24px; margin-top: 10px">
    @if (control.newOptionText) {
    <span class="small">{{control.newOptionText}}</span>
    }
    <!--span *ngIf="!control.newOptionText" class="small">Add {{control.field.label}}</span -->
  </button>
  }
</ng-template>
