/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { Txn } from 'src/app/model/txn';

@Injectable({
    providedIn: 'root'
})
export class InvoiceService extends AbstractHttpService {

    // protected baseUrl = this.ajaxPath + 'txns?typeId=' + Txn.TYPE.SALE.id;
    protected baseUrl = this.ajaxPath + Txn.TYPE.INVOICE.code;
    protected cache: Txn[];
    protected typeString = this.ajaxPath + Txn.TYPE.INVOICE.name;

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super();
    }

    getPDF(txn: Txn, mailIt = false) {
        const url = `${this.baseUrl}/${txn.id}/pdf`;
        if (!mailIt) {
            this.downloadFile(url, txn.teamId, 'application/pdf');
        } else {
            this.http.post(url, {}, this.getSimpleOptions(txn.teamId)).subscribe( response => {
                let owners = 'Owners Email Address';
                const mailedTo = response['mailedTo'];
                /*if (mailedTo && mailedTo.isArray()) {
                    owners = '';
                    response['mailedTo'].forEach( (to: {name: string, address: string}) => {
                        if (owners !== '') {
                            owners += ', ';
                        }
                        owners += to.address;
                    });
                }*/
               owners = mailedTo;
                this.messageService.show('PDF Sent by mail to ' + owners);
            }, failure => {
                console.log('failed');
                this.handleError('Mailing PDF', null)(failure);
            })
        }
    }

}
