/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from 'src/app/shared/message.service';
import { uuid } from 'src/app/model/abstract-object';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';

export type RequestIcon = { id: uuid, name: string }
@Injectable({
    providedIn: 'root'
})
export class RequestIconsService {

    httpOptions = {
        headers: new HttpHeaders().set('Content-Type', 'text/plain,application/xml')
    };

    protected cache: RequestIcon[];
    protected typeString = 'Document';

    readonly iconUrl = AbstractHttpService.ajaxPath + 'requestIcons';

    constructor(protected http: HttpClient, protected messageService: MessageService) {
    }

    getIcons(): Observable<RequestIcon[]> {
        if (!this.cache) {
            return this.http.get(this.iconUrl).pipe(map(icons => {
                console.log(icons);
                this.cache = icons as RequestIcon[];
                return this.cache;
            }));
        } else {
            return of(this.cache);
        }
    }
}