/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { of } from 'rxjs';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet, LAYOUT_OPTIONS } from 'src/app/shared/form/field-set/field-set.component';
import { FormRichTextComponent } from 'src/app/shared/form/form-rich-text/form-rich-text.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { required } from 'src/app/shared/validators';
import { DocumentService } from 'src/app/modules/crm/document.service';
import { Document } from 'src/app/model/document';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { NavRoute } from 'src/app/shared/NavRoute';
import { DocumentDetailPageComponent } from '../document-detail-page/document-detail-page.component';
import { NewButtonService } from 'src/app/shared/new-button/new-button.service';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { CurrentUserService } from '../../user/current-user.service';
import { RouterLink } from '@angular/router';
import { User } from 'src/app/model/user';
import { MatButtonModule } from '@angular/material/button';
import { Field } from 'src/app/shared/field/Field';

@Component({
    selector: 'app-document-page',
    templateUrl: './document-page.component.html',
    styleUrls: ['./document-page.component.scss'],
    standalone: true,
    imports: [FormPageComponent, RouterLink, MatButtonModule]
})
export class DocumentPageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('crm/documents', DocumentPageComponent, 'library_books').setItemComponent(DocumentDetailPageComponent)

    askSuggestions = false;

    config = new FormConfig({
        navRoute: DocumentPageComponent.navRoute,
        title: $localize`Document`,
        help: $localize`Documentation owned and managed by the OMC`,
        fieldSet: new FieldSet({
            fields: [
                FieldMaker.id(),
                FieldMaker.rev(),
                FormTextComponent.make('Title', 'title', { validators: [required], cellOpts: { width: '20%' } }),
                FormPicklistComponent.make('Channel with access to documents', 'forumId', 'forum',
                    { items: this.currentUserSvc.getForums() },
                    { validators: [required], cellOpts: { width: '10%' }, visible: Field.noPhone }
                ),
                FormTextComponent.make('Area', 'areaName'),
                FormTextComponent.make('Core', 'coreName'),
                FormNumberComponent.make('Items', 'child_docs_count', {}, { sendServer: false, disable: true, cellOpts: { width: '5%' } }),
                FormNumberComponent.make('Files', 'attachments_count', {}, { sendServer: false, disable: true, cellOpts: { width: '5%' } }),
                FormRichTextComponent.make('Content', 'content', { visible: Field.noPhone, cellOpts: {width:"80%"} } ),
            ],
            formLayout: LAYOUT_OPTIONS.singleCol,
        }),
        service: this.dataSvc,
        mode: 'list',
        objectFactory: () => of(new Document()),
        newOptions: [],
        beforeList: this.beforeList.bind(this),
    });

    constructor(public dataSvc: DocumentService, private nbs: NewButtonService, private currentUserSvc: CurrentUserService) {
        super();
    }

    beforeList(data: Document[]) {
        if (data.length < 3) {
            this.currentUserSvc.getCurrentUser().subscribe ( u => {
                if (User.canPostOwnSR(u)) {
                    this.askSuggestions = true;
                }
            })
        }
        return data;
    }

}
