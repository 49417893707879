/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Input, ViewContainerRef } from '@angular/core';
import { Field } from 'src/app/shared/field/Field';
import { AppFormControl, ControlOn } from '../app-form-control';
import { FormControlInterface } from '../form-control-interface';
import { NgClass } from '@angular/common';
import { FormErrorComponent } from '../form-error/form-error.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

export class UrlField extends Field {
  formatValue(val) {
    if (!val || (val as string).startsWith('http')) {
      return val;
    } else {
      return 'http://' + val;
    }
  }
}

@Component({
  selector: 'app-form-url',
  templateUrl: './form-url.component.html',
  styleUrls: ['./form-url.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, MatTooltipModule, FormErrorComponent, NgClass]
})
export class FormUrlComponent implements FormControlInterface {

  @Input() label: string;
  @Input() control: AppFormControl;
  @Input() on: ControlOn = 'form';

  public static make(label: string, value: string, options: Partial<Field> = {}) {
    const field: UrlField = new UrlField({ label, value, type: 'url' }, options);
    field.formControlFactory = FormUrlComponent.createComponent;
    return field;
  }

  public static createComponent(vcr: ViewContainerRef, ctl: AppFormControl, on: ControlOn) {
    const componentRef = vcr.createComponent(FormUrlComponent);
    componentRef.instance.control = ctl;
    componentRef.instance.on = on;
  }
}
