@if (on === 'form') {
  <div style="height:62px; padding-top: 12px; padding-left: 10px">
    <div style="float:left">
      @if (!this.control.value) {
        <mat-icon [style.opacity]="opacity" [color]="control.disabled ? '' : color" (click)="turnOn()">check_box_outline_blank</mat-icon>
      }
      @if (this.control.value) {
        <mat-icon [style.opacity]="opacity" [color]="control.disabled ? '' : color" (click)="turnOff()">check_box_outline</mat-icon>
      }
    </div>
    <div style="float:left; margin-left:5px" [matTooltip]="control.getErrorMessage()">
      <span [ngClass]="control.invalid && control.touched ? 'error' : null">{{control.field.label}}</span>
    </div>
    <div style="float:right; padding-right: 12px">
      @if (control.field.hint) {
        <mat-icon color="primary" [matTooltip]="control.field.hint">info</mat-icon>
      }
    </div>
  </div>
}
@if (on !== 'form') {
  <div >
    @if (!this.control.value) {
      <mat-icon [style.opacity]="opacity" [color]="control.disabled ? '' : color" (click)="turnOn()">check_box_outline_blank</mat-icon>
    }
    @if (this.control.value) {
      <mat-icon [style.opacity]="opacity" [color]="control.disabled ? '' : color" (click)="turnOff()">check_box_outline</mat-icon>
    }
  </div>
}
<!--
<mat-checkbox *ngIf="on !== 'form'" [formControl]="control"color="primary" (click)="clickIt($event)"></mat-checkbox>
<div *ngIf="on === 'form'" style="height: 38px; padding: 1.5em 1.25em 1em 1.25em">
  <mat-checkbox [formControl]="control" color="primary" (click)="clickIt($event)">{{control.field.label}}</mat-checkbox>
  <p><span style="font-size: smaller; color: rgba(0,0,0,0.6)">{{control.field.hint}}</span></p>
</div>
-->
