/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { MyInjector } from "src/app/app.module";
import { ConfirmDialogService } from "src/app/shared/dialogs/confirmDialog";

export class CSVImporter {

    /**
   * CSVToArray parses any String of Data including '\r' '\n' characters,
   * and returns an array with the rows of data.
   * @param {String} CSV_string - the CSV string you need to parse
   * @param {String} delimiter - the delimeter used to separate fields of data
   * @returns {Array} rows - rows of CSV where first row are column headers
   *
   * Thanks: https://stackoverflow.com/questions/36288375/how-to-parse-csv-data-that-contains-newlines-in-field-using-javascript
   * Thanks: https://www.bennadel.com/blog/1504-ask-ben-parsing-csv-strings-with-javascript-exec-regular-expression-command.htm
   *
   */
    csvToArray(CSV_string, delimiter = ',') {

        const pattern = new RegExp( // regular expression to parse the CSV values.
            ( // Delimiters:
                "(\\" + delimiter + "|\\r?\\n|\\r|^)" +
                // Quoted fields.
                "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
                // Standard fields.
                "([^\"\\" + delimiter + "\\r\\n]*))"
            ), "gi"
        );

        const rows = [[]];  // array to hold our data. First row is column headers.

        // array to hold our individual pattern matching groups:
        let matches: RegExpExecArray | null = null; // false if we don't find any matches

        // Loop until we no longer find a regular expression match
        // eslint-disable-next-line no-cond-assign
        while (matches = pattern.exec(CSV_string)) {
            const matched_delimiter = matches[1]; // Get the matched delimiter
            // Check if the delimiter has a length (and is not the start of string)
            // and if it matches field delimiter. If not, it is a row delimiter.
            if (matched_delimiter.length && matched_delimiter !== delimiter) {
                // Since this is a new row of data, add an empty row to the array.
                rows.push([]);
            }
            let matched_value;
            // Once we have eliminated the delimiter, check to see
            // what kind of value was captured (quoted or unquoted):
            if (matches[2]) { // found quoted value. unescape any double quotes.
                matched_value = matches[2].replace(
                    new RegExp("\"\"", "g"), "\""
                );
            } else { // found a non-quoted value
                matched_value = matches[3];
            }
            // Now that we have our value string, let's add
            // it to the data array.
            rows[rows.length - 1].push(matched_value);
        }
        return rows; // Return the parsed data Array
    }
    
    readFile($event: FileList, resultFn: (rows: unknown[][]) => void) {
        const upFile = $event[0];
        const reader = new FileReader();
        reader.onload = (pe: ProgressEvent) => {
            try {
                console.log('Parsing input file', pe);
                const csv = (pe.target as FileReader).result;
                const rows = this.csvToArray(csv);
                resultFn(rows);
            } catch (e) {
                const cds = MyInjector.instance.get(ConfirmDialogService);
                cds.alert('Fatal Error', 'Could not parse file');
                console.error(e);
            }
        }
        reader.readAsText(upFile);
    }

    parseFile(pe: ProgressEvent, resultFn: (rows: unknown[][]) => void) {
        try {
            console.log('Parsing input file', pe);
            const csv = (pe.target as FileReader).result;
            const rows = this.csvToArray(csv);
            resultFn(rows);
        } catch (e) {
            const cds = MyInjector.instance.get(ConfirmDialogService);
            cds.alert('Fatal Error', 'Could not parse file');
            console.error(e);
        }
    }    
}