/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Document } from 'src/app/model/document';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { uuid } from 'src/app/model/abstract-object';
import { Params } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class DocumentService extends AbstractHttpService {

    protected baseUrl = this.ajaxPath + 'crm/documents';
    protected cache: Document[];
    protected typeString = 'Document';

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super();
    }

    getAllDocs(teamId: uuid = null): Observable<Document[]> {
        let params: Params = {};
        if (teamId) {
            params = { _forceTeam: teamId }
        }

        return this.http.get<Document[]>(this.baseUrl + '/all', { params })
            .pipe(
                map((data: Document[]) => {
                    return data as Document[];
                }),
                catchError(this.handleError<Document[]>('get' + this.typeString, []))
            );
    }
}
