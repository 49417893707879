/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { Txn } from 'src/app/model/txn';
import { Field } from 'src/app/shared/field/Field';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { AppPicklistControl, FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { GridField } from 'src/app/shared/grid/grid-field';
import { CycleService } from 'src/app/modules/budget/cycle.service';
import { PeriodService } from 'src/app/modules/budget/period.service';
import { UnitService } from 'src/app/modules/unit/unit.service';
import { PersonService } from '../../pages/person-page/person.service';
import { BCodeService } from '../budget/bcode.service';
import { Cycle } from 'src/app/model/cycle';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { PersonPageComponent } from 'src/app/pages/person-page/person-page.component';
import { UnitPageComponent } from '../unit/unit-page/unit-page.component';

@Injectable({
    providedIn: 'root'
})
export class ArTxnService extends AbstractHttpService {

    // protected baseUrl = this.ajaxPath + 'txns?typeId=' + Txn.TYPE.SALE.id;
    protected baseUrl = this.ajaxPath + Txn.LEDGER.AR.code;
    protected cache: Txn[];
    protected typeString = 'Txn';

    constructor(protected http: HttpClient, protected messageService: MessageService,
        protected unitSvc: UnitService, public personSvc: PersonService,
        protected cycleSvc: CycleService, protected periodSvc: PeriodService,
        protected bCodeSvc: BCodeService) {
        super();
    }

    getFields(): Field[] {

        return [
            FieldMaker.id(),
            FormNumberComponent.make('Reference', 'refNr', {}, { cellOpts: { heading: 'Ref' }, readonly: true, formColumn: 1 }),
            FieldMaker.rev(),
            FormPicklistComponent.make('Type', 'typeId', 'type', { items: [Txn.TYPE.INVOICE, Txn.TYPE.CREDIT_NOTE, Txn.TYPE.BANK_IN] }),
            FormDateComponent.make('Transaction Date', 'txnDate', { cellOpts: { width: '2%' }, formColumn: 2 }),
            FormPicklistComponent.make('Cycle', 'txnCycleId', 'txnCycle', { service: this.cycleSvc }, { formColumn: 2 }),
            FormPicklistComponent.make('Period', 'txnPeriodId', 'txnPeriod', { service: this.periodSvc }, {
                formColumn: 2,
                refresh: (o: Cycle, control: AppPicklistControl) => {
                    if (o) {
                        control.field.picklist.items = o.periods;
                        control.setValue(null);
                    }
                }
            }),

            FormPicklistComponent.make('Account', 'bCodeId', 'bCode', { service: this.bCodeSvc }, { formColumn: 3 }),

            FormButtonComponent.makeNavDetailButton('Unit', 'unit.name','unitId',
                UnitPageComponent.navRoute, true
            ),
            FormButtonComponent.makeNavDetailButton('Owner', 'ownerName','personId',
                PersonPageComponent.navRoute, true).override({ calculateValue: (o: Txn) => o.person?.fullName }
            ),
            FormNumberComponent.make("Debit", "debit", { format: 'currency', width: 9, formatParms: '1.2-2' }, { formColumn: 4 }),
            FormNumberComponent.make("Credit", "credit", { format: 'currency', width: 9, formatParms: '1.2-2' }, { formColumn: 4 }),
            FormNumberComponent.make("Outstanding", "outstanding", { format: 'currency', width: 9, formatParms: '1.2-2' }, { formColumn: 4 }),

            new GridField({
                field:
                    { label: 'Details', value: 'details', visible: Field.formOnly, formRow: 2 },
                rowFactory: () => [
                    FieldMaker.id({ label: 'Ref', visible: { phone: true, computer: true, form: true }, footer: { text: 'Totals', style: 'text-align: right' } }),
                    FormPicklistComponent.make('Account', 'bCodeId', 'bCode',
                        { service: this.bCodeSvc },
                        { cellOpts: { width: '50%' } }
                    ),
                    FormNumberComponent.make("Debit", "debit", { format: 'currency', width: 9, formatParms: '1.2-2' }),
                    FormNumberComponent.make("Credit", "credit", { format: 'currency', width: 9, formatParms: '1.2-2' }),
                    FieldMaker.notes(),
                    FieldMaker.rev(),
                    FieldMaker.deleteGridRow(),
                ],
                hasFooter: true
            }),
        ];
    }

}
