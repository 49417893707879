<div style="overflow-y: hidden;" [style.width.px]="width - 40">
    <div class="dialogTitleBar primary-background-color ">
        <span>{{gridField.label}}</span>
    </div>
    <div formHolder style="padding-left: 10px; padding-right: 10px" [style.max-height.px]="height" [style.height.px]="height">
        <app-ctl-holder [control]="gridField.control"></app-ctl-holder>
    </div>
    <div style="text-align: right; padding-bottom: 10px; padding-right: 10px">
        <button color="accent" mat-raised-button (click)="cancel()">Cancel</button>
        <button color="primary" mat-raised-button [disabled]="isSaveable()" (click)="saveGrid()">Save</button>
    </div>
</div>
