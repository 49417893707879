import { NgTemplateOutlet } from '@angular/common';
import { Component, inject } from '@angular/core';
import { OmcSettings } from 'src/app/model/OmcSettings';
import { NavRoute } from 'src/app/shared/NavRoute';
import { Agency, OmcSettingsService } from './omc-settings.service';
import { maxChars, required } from 'src/app/shared/validators';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { FormPageComponent } from 'src/app/shared/form/form-page/form-page.component';
import { FormComponent } from 'src/app/shared/form/form.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { GridField } from 'src/app/shared/grid/grid-field';
import { RoleService } from '../role-page/role.service';
import { Role } from 'src/app/model/role';
import { MatCardModule } from '@angular/material/card';
import { TextEditComponent } from 'src/app/shared/simpleFields/text-edit/text-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { EditFieldDialogComponent, EditFieldDialogOptions } from 'src/app/shared/dialogs/edit-field-dialog/edit-field-dialog.component';
import { Field } from 'src/app/shared/field/Field';
import { FormEmailComponent } from 'src/app/shared/form/form-email/form-email.component';
import { FormTextAreaComponent } from 'src/app/shared/form/form-text-area/form-text-area.component';

@Component({
  selector: 'app-omc-settings',
  standalone: true,
  imports: [NgTemplateOutlet, FormPageComponent, FormComponent, MatCardModule, TextEditComponent],
  templateUrl: './omc-settings.component.html',
  styleUrl: './omc-settings.component.scss'
})
export class OmcSettingsComponent {

    static readonly navRoute = new NavRoute('omcSettings', OmcSettingsComponent, 'settings_applications');
    omsSettingsSvc = inject(OmcSettingsService)
    roleSvc = inject(RoleService);
    dialog = inject(MatDialog);

    roles: Role[] = [];
    // Do not disable until picklist set correctly
    agencyPicklist = FormPicklistComponent.make('Primary Agent', 'primaryAgentId', null, {items: []});
    team = new OmcSettings();

    agentGrid = new GridField({
        field: { value: 'agents', cellOpts: { heading: 'Agents' }},
        rowFactory: this.agentFactory.bind(this),
        hasFooter: true
    });

      constructor() {
          this.omsSettingsSvc.getOne('omcSettings', null).subscribe(team => this.team = team)
        // This should be done pre-edit and only allow agencies with permissions...
        this.omsSettingsSvc.getAgencies().subscribe( (agencies: Agency[]) => {
            const agtTeams = [];
            agencies.forEach( a => agtTeams.push( {id: a.agentTeamId, name: a.name}))
            this.agencyPicklist.setPicklistItems(agtTeams);
        });
        this.roleSvc.get(true).subscribe( roles => {
            roles.forEach( r => this.roles.push(r as Role));
        })
    }

    editName() {
        this.editField(FieldMaker.nameControl({ validators: [required] }));
    }

    editAddress() {
        this.editField(FormTextAreaComponent.make('Partial Unit Address', 'defaultAddress', {
            validators: [required, maxChars(255)],
        }))
    }

    editLegalName() {
        const field = FormTextComponent.make('Legal Company Name', 'legalName', { validators: [required] })
        this.editField(field);
    }

    editFromEmail() {
        const field = FormEmailComponent.make('Email Address to send mails from', 'fromEmail',
            { validators: [required],
                hint: 'Please note, you need to contact OurOMC and go through the setup process for this email so that we have permission to send messages from this email address. If you do not go through this process, mail will be sent as coming from this address, however most will go to SPAM as google, yahoo, microsoft etc. will see that we are not an authorised sender.' })
        this.editField(field);
    }

    editPaymentTerms() {
        this.editField(FormTextAreaComponent.make('Payment Terms', 'paymentTerms',
            { validators: [required, maxChars(255)] }))
    }

    editBillingAddress() {
        this.editField(FormTextAreaComponent.make('Billing Address', 'billingAddress', {
            validators: [required, maxChars(255)],
         }))
    }

    editField(field: Field) {
        const data = new EditFieldDialogOptions({
            field,
            id: 'omcSettings',
            service: this.omsSettingsSvc,
        });
        this.dialog.open(EditFieldDialogComponent,{data}).afterClosed().subscribe((updated) => {
            if (updated) {
                this.team = updated;
            }
        })
    }

    beforeEdit(o: OmcSettings) {
        const agtTeams = [];
        o.agents.forEach( a => {
            if (!agtTeams.find( at => at.id === a.agentTeamId)) {
                agtTeams.push({id: a.agentTeamId, name: a.agentName})
            }
            this.agencyPicklist.setPicklistItems(agtTeams);
        });
        return o;
    }

    private agentFactory() {
        return [
            FormTextComponent.make('Agency Name', 'agentName', {disable: true}),
            FormTextComponent.make('Agency Personnel With Role', 'agentRoleName', {disable: true}),
            FormPicklistComponent.make('Are Granted', 'omcRoleId', null, {items: this.roles}, {disable: true}),
            //FieldMaker.spacer()
        ];
    }
}
