/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*
*/

import { HttpParams } from "@angular/common/http";
import { RboChart } from "./RboChart";

export class RboMyRequestsChart extends RboChart {
    title = "My Requests Top OMCs";
    dataParams = new HttpParams().set('week', 0).set('show', 'myrequests');
}
