<app-form-page [config]="config" [drillDown]="false"></app-form-page>
<div style="margin: 20px">
    <p>
        The Debtors analysis report analyses the outstanding invoices & Credit Notes
    </p>

    <p> It calculates the percentage outstanding for the entire transaction, and uses this to apportion the outstanding
        amount.
        For example:
    </p>
    <ul>
        <li>Basic Service Charge Amount = €1500</li>
        <li>Sinking fund contribution = €500</li>
        <li>Total Service charge = €2,000</li>
    </ul>
    <p>If the member has paid €500 against this service charge, i.e. 25%</p>
    <ul>
        <li>Basic Service Charge Outstanding = 75% of €1500 = €1,125</li>
        <li>Sinking fund contribution outstanding = 75% of €500 = €375</li>
    </ul>
    <p>
        This shows us, by budget code, how much is outstanding of what was billed.
        Many OMCs will use this to calculate the expected contribution to the sinking fund,
        as funds for members who have not paid their service charges are not expected to be transferred before they make
        payment. It is especially useful when sinking funds are allocated accross multiple schedules.
    </p>
    <p>
        The report can also be used to assess the amount of outstanding charges attributable to interest charges.
    </p>
</div>
