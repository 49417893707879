/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { MyInjector } from "src/app/app.module";
import { ActionColor, IFormAction } from "src/app/shared/form/form.component";
import { CurrentUserService } from "src/app/modules/user/current-user.service";
import { ActionService } from "src/app/modules/crm/action.service";
import { MatDialog } from "@angular/material/dialog";
import { BankAccount } from "src/app/model/bankAccount";
import { ManualReconciliationComponent } from "./manual-reconciliation.component";
import { IsNarrowService } from "src/app/shared/is-narrow.service";
import { AbstractObject, uuid } from "src/app/model/abstract-object";

export class ManualReconciliation extends AbstractObject {
    bankId: uuid;

    fromDate: string;
    openingBal: number;
    openingBalCalc: number;

    toDate: string;
    closingBal: number;
    closingBalCalc: number;

}
export class ManualReconciliationAction implements IFormAction {
    name = $localize`Manual Reconciliation`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'balance';
    approvalNeeded = false;
    disabled = false;
    approvalText = '';
    tipText: string;
    bankAccount: BankAccount;

    currentUser = MyInjector.instance.get(CurrentUserService).currentUser;
    actionService = MyInjector.instance.get(ActionService);
    dialog = MyInjector.instance.get(MatDialog);
    screenSvc = MyInjector.instance.get(IsNarrowService);

    //constructor(private addFn: () => Observable<AbstractObject>, private linksTo: LinksTo) { }
    constructor() { }

    action() { // config: FormConfig
        return this.openDialog();
    }

    setup(item: BankAccount) {
        this.bankAccount = item;
        if (this.screenSvc.isPhone()) {
            this.show = false;
        } else {
            this.show = true;
        }

    }

    openDialog() {

        const dialogRef = this.dialog.open(ManualReconciliationComponent,
            {
                data:
                {
                    bankAccount: this.bankAccount
                }
            }
        );

        return dialogRef.afterClosed();
    }
}
