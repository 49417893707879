<mat-dialog-content width="100%">
  <table width="100%">
    <tr>
      <th  class="mat-header-cell" colspan="20">
        History of {{config.title}} @if (config.mode === 'edit') {
        <span>: {{config.itemId}} </span>
      }
    </th>
  </tr>
  <tr>
    <th class="mat-header-cell">User</th>
    <th class="mat-header-cell">Date</th>
    <th class="mat-header-cell">Action</th>
    <th class="mat-header-cell">Object</th>
    <th class="mat-header-cell">Child Object</th>
    <th class="mat-header-cell">Change</th>
  </tr>
  @for (auditEntry of auditTrail; track auditEntry) {
    <tr>
      <td class="mat-cell">{{auditEntry.user ? auditEntry.user.name : 'unknown'}}</td>
      <td class="mat-cell" [matTooltip]="moment(auditEntry.date * 1000)">{{ auditEntry.date | fromNow }}</td>
      <td class="mat-cell">{{auditEntry.action}}-{{auditEntry.model}}</td>
      <td class="mat-cell">{{auditEntry.object}}</td>
      <td class="mat-cell">{{auditEntry.model}}</td>
      <td class="mat-cell"><div [innerHtml]="auditEntry.change"></div></td>
    </tr>
  }
</table>
</mat-dialog-content>
