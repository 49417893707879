/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { DateHelper } from '../shared/dateHelper';
import { FieldSet, LAYOUT_OPTIONS } from '../shared/form/field-set/field-set.component';
import { FormTextComponent } from '../shared/form/form-text/form-text.component';
import { FormConfig } from "../shared/form/FormConfig";
import { AbstractObject, uuid } from './abstract-object';
import { Action } from './action';
import { Area } from './area';
import { Attachment } from './attachment';
import { BillingItem } from './billing';
import { Core } from './core';
import { Cycle } from './cycle';

import { Frequency } from './frequency';
import { MailMerge } from './mailMerge';
import { Memo } from './memo';
import { Payz } from './Payz';
import { PayzMandate } from './PayzMandate';
import { Person } from './person';
import { PersonUnitRole } from './person-unit-role';
import { SchedulePortion } from './schedule-portion';
import { ServiceRequest } from './serviceRequest';
import { Txn } from './txn';
import { UnitCategory } from './unit-category';
import { UnitType } from './unit-type';

export class Unit extends AbstractObject {
    title: string;
    ownerId: uuid;
    owner: Person;
    ownerOccupied: boolean;
    ownerFullName: string;

    numberCarSpaces: number;
    carSpaces: string;

    typeId: uuid;
    type: UnitType;
    areaId: uuid;
    area: Area;
    coreId: uuid;
    core: Core;
    floor: number;
    bedrooms: number;
    categoryId: uuid;
    category: UnitCategory;
    fireSafetyCheck: string; // Date...
    frequencyId: uuid = 1;
    frequency: Frequency;
    size = 1;
    address: string;
    people: PersonUnitRole[] = [];
    transactions: Txn[] = [];
    portions: SchedulePortion[] = [];
    /** Calculated fields */
    txnWithPayments: Txn[] = [];
    actions: Action[] = [];
    mailMerges?: MailMerge [] = [];
    memos: Memo[] = [];
    billingItems: BillingItem[] = [] //myUnit Only - If proposed budget available
    proposedBillingTotal: number; //calculated
    myProposedBillingTotal: number; // calculated
    outstanding: number;
    arrears: number;
    fullPayDiscount: number;
    fullPayAmount: number;

    invoices: number;

    mandateDiscount: number;
    mandateFullPayAmount: number;

    overdue: number;
    yearStart: number;
    currentPays: number;
    oldPay: number;
    newPay: number;
    paysLeft: number;
    inProgressPayz: Payz[] = [];
    payzMandate: PayzMandate;

    requests: ServiceRequest[] = [];
    attachments?: Attachment[] = [];
    currentTxn?: Txn[];

    billingAddress?: string;

    constructor(o: Partial<Unit> = {}) {
        super(o);
    }

    static getFullName(o: Unit): string {
        let retValue = o && o.name ? o.name : '';
        if (o?.address) {
            retValue += ' - ' + o.address.split('\n')[0];
        } else {
            if (o?.type) {
                retValue += ' - ' + o.type.name;
            }
        }
        return retValue;
    }


    static calculatePaymentSchedule(o: Unit, currentCycle: Cycle) {
        let outstanding = 0;
        let currentPays = 0;
        let arrears = 0;

        for (const t of o.transactions) {
            outstanding += t.outstanding;
            if (t.txnDate < currentCycle.from) {
                arrears += t.outstanding;
            }
            if (t.txnCycleId === currentCycle.id) {
                if (t.typeId === Txn.TYPE.BANK_IN_ALLOC.id || t.typeId === Txn.TYPE.BANK_IN_ON_ACCT.id) {
                    currentPays += t.credit;
                }
            }
        }

        const daysIn = DateHelper.isoDaysSince(currentCycle.from);
        const daysTo = DateHelper.isoDaysTo(currentCycle.to);

        let paysExpected = o.frequency.perAnnum;
        if (daysTo > 0) {
            paysExpected = 1 + Math.trunc(daysIn / (daysIn + daysTo) * o.frequency.perAnnum);
        }

        const paysLeft = o.frequency.perAnnum - paysExpected;

        o.outstanding = outstanding;
        o.arrears = arrears;

        const currentDate = new Date().toISOString().slice(0, 10);

        if (currentDate <= currentCycle.fullPayBy) {
            o.fullPayDiscount = Math.round(((outstanding - arrears) * currentCycle.fullPayDiscount * 100) / 100);
        } else {
            o.fullPayDiscount = 0;
        }
        o.fullPayAmount = outstanding - o.fullPayDiscount;

        o.mandateDiscount = Math.round(((outstanding - arrears) * currentCycle.mandateDiscount * 100) / 100);
        o.mandateFullPayAmount = outstanding - o.mandateDiscount;


        o.yearStart = outstanding + currentPays;
        o.oldPay = o.yearStart / o.frequency.perAnnum;
        o.currentPays = currentPays;
        o.overdue = Math.max(0, (paysExpected * o.oldPay) - currentPays);
        o.newPay = paysLeft ? o.outstanding / paysLeft : 0;
        o.paysLeft = paysLeft;

        return o;
    }

    static pickDialogFormConfig(): FormConfig {
        return new FormConfig({
            fieldSet: new FieldSet({
                fields: [
                    FormTextComponent.make('Name', 'name'),
                    FormTextComponent.make('Address', 'address'),
                ],
                formLayout: LAYOUT_OPTIONS.singleCol,
            }),
            title: $localize`Units`,
            mode: 'list',
        });
    }
}
