<!-- ng-container *ngTemplateOutlet="recItemHeaderTemplate"></ng-container -->
<p style="margin-bottom: 7px">
    Order: <select [(ngModel)]="sortItems" (ngModelChange)="updateItems()">
        <option value="dateUp">Date (ascending)</option>
        <option value="dateDown">Date (descending)</option>
        <option value="amountUp">Amount (ascending)</option>
        <option value="amountDown">Amount (descending)</option>
        <option value="description">Description</option>
    </select>
</p>
<p style="margin-bottom: 7px">
    Show: <select [(ngModel)]="includeItems" (ngModelChange)="updateItems()">
        <option value="all">All</option>
        <option value="debit">Deposits Only</option>
        <option value="credit">Withdrawals Only</option>
    </select>
</p>
<mat-tab-group>
    <mat-tab>
        <ng-template mat-tab-label>
            {{bank?.openRecItems?.length}} Items To Reconcile
            @if (bank?.openRecItems?.length > itemsToShow.length) {
            ({{itemsToShow.length}} showing)
            }
        </ng-template>
        <ng-template matTabContent>
            <div style="height:calc(100vh - 250px); overflow-y: auto; overflow-x: hidden;">
                @for (recItem of itemsToShow; track $index) {
                <!-- p>{{recItem.bookingDate}} - {{recItem.transactionAmount | currency}} - {{getDescription(recItem)}}</p -->
                <ng-container *ngTemplateOutlet="recItemTemplate; context: {recItem}"></ng-container>
                }
            </div>
        </ng-template>
    </mat-tab>
</mat-tab-group>

<ng-template #recItemTemplate let-recItem="recItem">
    <div class="rec-item" [ngClass]="recItem === selectedItem ? 'selected' : 'unselected'"
        (click)="selectItem(recItem)">
        <table style="width:100%">
            <tr>
                <td style="text-align:left">
                    Date: {{recItem.bookingDate}}
                </td>
                <td style="text-align: right">
                    {{recItem.transactionAmount | currency}}
                    <mat-icon style="margin-bottom:-6px" color="primary" (click)="itemDetails(recItem, $event)"
                    matTooltip="Click for full details">info</mat-icon>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    {{recItem.description}}
                </td>
            </tr>
        </table>
    </div>
</ng-template>

<ng-template #recItemHeaderTemplate>
    <div style="width:250px; line-height: 1em; margin-left: 10px">
        <p>
            {{bank?.openRecItems?.length}} Items To Reconcile
            @if (bank?.openRecItems?.length > itemsToShow.length) {
            ({{itemsToShow.length}} showing)
            }
        </p>
        <p>
            Order: <select [(ngModel)]="sortItems" (ngModelChange)="updateItems()">
                <option value="dateUp">Date (ascending)</option>
                <option value="dateDown">Date (descending)</option>
                <option value="amountUp">Amount (ascending)</option>
                <option value="amountDown">Amount (descending)</option>
                <option value="description">Description</option>
            </select>
        </p>
        <p>
            Show: <select [(ngModel)]="includeItems" (ngModelChange)="updateItems()">
                <option value="all">All</option>
                <option value="debit">Deposits Only</option>
                <option value="credit">Withdrawals Only</option>
            </select>
        </p>
    </div>
</ng-template>
