@if (on === 'form') {
  <mat-form-field>
    <mat-label>{{control.field.label}}</mat-label>
    @if (!control.field.readonly && !control.field.disable) {
      <select class="units" (ngModelChange)="updateUnits($event)" [(ngModel)]="durationField.duration.unit">
        <option>Minutes</option>
        <option>Hours</option>
        <option>Days</option>
      </select>
    }
    <input matInput  id="{{control.field.name}}-durations" type="number"
      (ngModelChange)="updateQty($event)" [(ngModel)]="durationField.duration.qty"
      [disabled]="control.disabled"
      [matTooltip]="control.invalid ? control.getErrorMessage() + ' (' + control.field.hint + ')': control.field.hint"
      [ngClass]="{'error': control.invalid}"
      [autocomplete]="control.field.autoComplete"
      [readonly]="control.field.readonly ? '' : null"
      class="combo">
    @if (control.field.readonly || control.field.disable) {
      <span>{{durationField.duration.unit}}</span>
    }
    <mat-error>
      <app-form-error [(control)]="control"></app-form-error>
    </mat-error>
    <!-- in tooltip mat-hint>{{control.field.hint}}</mat-hint -->
  </mat-form-field>
}
@if (on !== 'form') {
  @if (!control.disabled && !control.field.readonly) {
    <select class="units" (ngModelChange)="updateUnits($event)" [(ngModel)]="durationField.duration.unit">
      <option>Minutes</option>
      <option>Hours</option>
      <option>Days</option>
    </select>
  }
  @if (!control.disabled && !control.field.readonly) {
    <input
      matInput  id="{{control.field.name}}-durations" type="number"
      (ngModelChange)="updateQty($event)" [(ngModel)]="durationField.duration.qty"
      style="width: 3em;"
      [disabled]="control.disabled"
      [matTooltip]="control.invalid ? control.getErrorMessage() + ' (' + control.field.hint + ')': control.field.hint"
      [ngClass]="{'error': control.invalid}"
      [autocomplete]="control.field.autoComplete"
      [readonly]="control.field.readonly ? '' : null"
      class="combo">
  }
  @if (control.disabled || control.field.readonly) {
    <span >
      {{durationField.duration.qty}}{{durationField.duration.unit.substring(0,1).toLowerCase()}}
    </span>
  }
}
