<app-form-page [config]="config"></app-form-page>

@if (askSuggestions) {
    <div style="margin:40px">
        <p>OMC's have many important documents.
            These documentation pages are intended to allow OMCs to store key documents securely, and share them
            with residents, members and/or directors as appropriate.
        </p>
        <p>
            Key documents for an OMC can include, but are not limited to
        </p>
        <ul>
            <li>OMC Consitution or memorandum and articles of association, the company rule book!</li>
            <li>Contract with Agent / letter of engagement - What the agent does or does not do</li>
            <li>House Rules - What owners and residents should do or not do</li>
            <li>Debt Collection Policy</li>
            <li>Development Agreement / Contract from when complex was built</li>
            <li>The management company agreement from when the OMC was first established</li>
            <li>The head or main lease under which individual owners are granted rights and obligations</li>
            <li>Deed of transfer of common areas from the developer to the OMC</li>
            <li>Taking in charge agreement</li>
            <li>Fire safety documentation, planning documentation</li>
        </ul>
        <p>If there are important documents that you think the OMC should be sharing here
            you can create a request for the document to be shared</p>
        <div style="text-align: center">
            <button mat-raised-button [routerLink]="'/myRequests'" color="primary">Request Document</button>
        </div>
    </div>
}
