<div style="display: flex; width: 100%" class="primary-background-color">
    <div style="flex: 50; padding-top:10px; padding-left:10px">
        <h1 class="mat-h1" style="margin-bottom: 4px">Manual Bank Reconciliation</h1>
    </div>
    <div style="flex: 1">
        <div class="dialog-close-button" (click)="this.dialogRef.close(null)" matTooltip="Click to close">
            <mat-icon class="dialog-close-icon">close</mat-icon>
        </div>
    </div>
</div>

<mat-dialog-content style="width: 800px; height: 650px;">
    <table width="100%">
        <tr>
            <td class="cell">
                <app-ctl-holder [control]="fromField.control"></app-ctl-holder>
            </td>
            <td class="cell">
                <app-ctl-holder [control]="openingBalField.control"></app-ctl-holder>
            </td>
        </tr>
        <tr>
            <td colspan="2" style="text-align:center; height: 74px">
                @if(toDate && !closingBalanceDiff) {
                    Your closing balance matches the system entries
                }
                @if (closingBalanceDiff && toDate) {
                    You need to reconcile a difference of {{closingBalanceDiff | currency}}
                    <a (click)="closeDialog()" [routerLink]="getAfterNav()">
                        click here
                    </a> to see system transactions
                }
            </td>
        </tr>
        <tr>
            <td class="cell">
                <app-ctl-holder [control]="toField.control"></app-ctl-holder>
            </td>
            <td class="cell">
                <app-ctl-holder [control]="closingBalField.control"></app-ctl-holder>
            </td>
        </tr>

        <tr>
            <td colspan="2" style="padding-top: 40px; text-align: center;">
                @if (toDate && !closingBalanceDiff) {
                    <button mat-raised-button color="primary" (click)="postReconciliation()">
                        Save and mark transactions reconciled for period
                    </button>
                } @else if (toDate) {
                    <button mat-raised-button color="primary">
                        Check Balance
                    </button>
                }
            </td>
        </tr>
    </table>
    @if(!fromDate) {
    <p style="padding-top: 20px" class="help">
        The manual bank reconciliation is a simple utility to allow you to verify that the transactions
        that you have entered in the system match those on your bank statement. <br />
    </p>
    <p class="help">
        You should get a copy of the bank statement, in PDF format, and attach it to the bank account.
    </p>
    <p class="help">
        The system uses the day after your last reconciliation as the start date for this reconciliation.
        The last reconciled balance will be your opening balance for this reconciliation.
        If you have never performed a reconciliatopn the system enters Jan 1st 1999
        with balance of zero as your starting point.
    </p>
    <p class="help">
        Enter closing dates and balances for the statement, if they match what is in the system already,
        you will be able to save and mark all transactions for the period as reconciled.
    </p>
    <p class="help">
        If the closing balance does not match the system, you will need to check off the transactions
        in the system against your bank statement and enter any missing transactions.
    </p>
    }
</mat-dialog-content>
