/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'limitTo',
    standalone: true,
})
export class LimitToPipe implements PipeTransform {

    transform(value: string, max: number): string {
        if (value) {
            if (value.includes('<')) {
                const div = document.createElement('div');
                div.innerHTML = value;
                value = (div.textContent || div.innerText);
            }
            return value.length > max ? value.substring(0, max) + '...' : value;
        } else {
            return '';
        }
    }

}
