/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*
*/

import { HttpParams } from "@angular/common/http";
import { SraChart } from "./SraChart";

export class SraAllChart extends SraChart {
    title = "All Requests";
    dataParams = new HttpParams().set('week', 0).set('owner', '');
}
