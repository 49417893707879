/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { CurrencyPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MyInjector } from 'src/app/app.module';
import { BankAccount, BankItem } from 'src/app/model/bankAccount';
import { ShowJSONComponent } from 'src/app/shared/dialogs/show-json/show-json.component';

@Component({
  selector: 'app-reconciliation-items',
  standalone: true,
  imports: [FormsModule, CurrencyPipe, NgTemplateOutlet, NgClass, MatTabsModule, MatIconModule, MatTooltipModule],
  templateUrl: './reconciliation-items.component.html',
  styleUrl: './reconciliation-items.component.scss'
})
export class ReconciliationItemsComponent implements OnChanges {

    @Input() bank: BankAccount;
    @Output() itemSelected = new EventEmitter<BankItem>();

    includeItems: 'debit'|'credit'|'all' = 'all';
    sortItems: 'dateUp' | 'dateDown' | 'amountUp' | 'amountDown' | 'description'= 'dateUp';

    itemsToShow: BankItem[] = [];
    selectedItem: BankItem;

    ngOnChanges() {
        if (this.bank) {
            this.getDescriptions();
            this.updateItems();
        }
    }

    updateItems() {
        let sorter: (a, b) => number = (a: BankItem, b: BankItem) => a.bookingDate > b.bookingDate ? -1 : 1;
        if (this.sortItems === 'dateUp') {
            sorter = (a: BankItem, b: BankItem) => a.bookingDate > b.bookingDate ? 1 : -1;
        } else if (this.sortItems === 'dateDown') {
            sorter = (a: BankItem, b: BankItem) => a.bookingDate < b.bookingDate ? 1 : -1;
        } else if (this.sortItems === 'amountUp') {
            sorter = (a: BankItem, b: BankItem) => Math.abs(a.transactionAmount) > Math.abs(b.transactionAmount) ? 1 : -1;
        } else if (this.sortItems === 'amountDown') {
            sorter = (a: BankItem, b: BankItem) => Math.abs(a.transactionAmount) < Math.abs(b.transactionAmount) ? 1 : -1;
        } else if (this.sortItems === 'description') {
            sorter = (a: BankItem, b: BankItem) => a.description.localeCompare(b.description)
        }

        if (this.includeItems === 'credit') {
            this.itemsToShow = this.bank.openRecItems.filter( bi => bi.transactionAmount < 0).sort(sorter);
        } else if (this.includeItems === 'debit') {
            this.itemsToShow = this.bank.openRecItems.filter( bi => bi.transactionAmount > 0).sort(sorter);
        } else {
            this.itemsToShow = this.bank.openRecItems.sort(sorter)
        }
    }

    getDescriptions() {
        this.bank.openRecItems.forEach (bi => BankItem.assignDescription(bi));
    }

    removeItem(item: BankItem) { //  Called from the panel after reconciling item...
        const idx = this.bank.openRecItems.indexOf(item);
        this.bank.openRecItems.splice(idx, 1);
        this.updateItems();
    }

    selectItem(item: BankItem) {
        this.selectedItem = item;
        this.itemSelected.emit(item);
    }

    itemDetails(showItem: BankItem, $evt: Event) {
        $evt.preventDefault();
        $evt.stopPropagation();
        MyInjector.instance.get(MatDialog).open(ShowJSONComponent,
            {data: {title: 'Transaction Details : ' + showItem.description, content: showItem.data}})
    }

}
