/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { of } from "rxjs";
import { ActionColor, IFormAction } from "../form/form.component";
import { ActionGridField } from "./action-grid-field";

export class AddActionFormAction implements IFormAction {
    name = $localize`Add Action`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'format_list_bulleted_add';
    approvalNeeded = false;
    disabled = false;
    approvalText = '';
    tipText: string;

    //constructor(private addFn: () => Observable<AbstractObject>, private linksTo: LinksTo) { }
    constructor(private actionGrid: ActionGridField) { }

    action() { //item: AbstractObject, config: FormConfig
        this.actionGrid.upsertAction().subscribe(action => {
            if (action) {
                this.actionGrid.control.addRow(action, true, true);
            }
        });
        return of(null);
        /*return this.addFn().pipe(
            mergeMap((action) => {
                const id = this.linksTo === 'Person' ? 'personId' : 'unitId';
                return of(action).pipe(() => config.service.getOne(id, null));
            }));
            */
    }
    //  setup(serviceRequest: ServiceRequest) {
    setup() {
        this.show = true;
    }
}
