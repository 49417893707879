/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { NavRoute } from 'src/app/shared/NavRoute';
import { CurrentUserService } from '../../user/current-user.service';
import { LoginComponent } from '../../user/login/login.component';
import { PersonalRequestsComponent } from '../personal-requests/personal-requests.component';
import { FormErrorComponent } from '../../../shared/form/form-error/form-error.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { NgTemplateOutlet, NgOptimizedImage } from '@angular/common';
import { User } from 'src/app/model/user';

@Component({
    selector: 'app-new-user-request',
    templateUrl: './new-user-request.component.html',
    styleUrls: ['./new-user-request.component.scss'],
    standalone: true,
    imports: [NgTemplateOutlet, MatCardModule, MatButtonModule, NgOptimizedImage, MatFormFieldModule,
            MatInputModule, FormsModule, ReactiveFormsModule, FormErrorComponent]
})
export class NewUserRequestComponent {
    static readonly navRoute = new NavRoute('newUserRequest', NewUserRequestComponent, 'live_help');
    emailCtl = new UntypedFormControl('');

    whoami: 'noidea'|'newuser'|'passwordNeeded'|'existing'|'requestedPassword' = 'noidea';
    requesting = false;

    constructor(private router: Router, currentUser: CurrentUserService,
    private http: HttpClient, private cds: ConfirmDialogService) {
        currentUser.getCurrentUser().subscribe ( (user) => {
            if (user !== null) {
                //this.router.navigate(['/' + PersonalRequestsComponent.navRoute.openRoute.path]);
                if (user.currentTeam) {
                    if (User.canPostOwnSR(user)) {
                        this.router.navigate(['/myRequests']);
                    } else {
                        this.router.navigate(['/']);
                    }

                } else {
                    this.router.navigate(['/unknownUser']);
                }

            }
        })
    }

    logMeIn() {
        const path = [LoginComponent.navRoute.openRoute.path];
        const parms = {queryParams: {returnUrl: 'unknownUser'}};
        console.log({path, parms});
        this.router.navigate( path, parms);
    }

    createNew() {
        this.whoami = 'newuser';
    }

    passwordNeeded() {
        this.whoami = 'passwordNeeded';
    }

    requestPassword() {
        const httpOptions = {
            headers: new HttpHeaders().set('Content-Type', 'application/json;charset=UTF-8')
        };
        this.requesting = true;

        this.http.post<Response>(AbstractHttpService.ajaxPath + 'reset/please',
            { email: this.emailCtl.value }, httpOptions
        ).subscribe(
            () => {
                this.whoami = 'requestedPassword';
            },
            loginError => {
                console.log('loginFailed', loginError);
                this.requesting = false;
            }
        );
    }

    knownUser() {
        return this.emailCtl.value === '';
    }

    oAuthLogin(provider: string) {
        CurrentUserService.returnMeTo(PersonalRequestsComponent.navRoute.listRoute.path);
        window.location.href = `/api/oauth/redirect/${provider}`;
    }

}
