@if (on==='form') {
  <mat-form-field class="full-width" style="height: 74px;">
    <mat-label>{{control.field.label}}</mat-label>
    <input matInput [value]="control.value"
      type="datetime-local"
      [formControl]="control"
      [readonly]="control.field.readonly ? '' : null"
      (blur)="saveDateTimeValue($event)">
    <mat-error style="margin-top: -5px">
      <app-form-error [(control)]="control"></app-form-error>
    </mat-error>
  </mat-form-field>
}
@if (on!=='form') {
  @if (!control.field.readonly) {
    <input matInput type="datetime-local" [formControl]="control"
      [ngClass]="{dateField : control.value === null}">
  }
  @if (control.field.readonly) {
    <span [matTooltip]="getCurrentFullDate()">{{getCurrentFromNowValue()}}</span>
  }
}
<!--
<div style="display:flex" matSuffix>
  <div style="flex: 1"><mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle></div>
  <div style="flex: 1; ">
    <p style="font-size:8px">Hour</p>
    <select matInput *ngIf="!control.field.readonly && !control.field.disable">
      <option *ngFor="let h of hrs" value="h.val">{{h.display}}</option>
    </select>
  </div>
  <div style="flex: 1; margin-left: 1em">
    <p style="font-size:8px">Mins</p>
    <select matInput *ngIf="!control.field.readonly && !control.field.disable">
      <option *ngFor="let m of mins" value="m.val">{{m.display}}</option>
    </select>
  </div>
  <div style="flex: 1; margin-left: 1em">
    <p style="font-size:8px">am/pm</p>
    <select matInput *ngIf="!control.field.readonly && !control.field.disable">
      <option>AM</option>
      <option>PM</option>
    </select>
  </div>
</div>
-->
