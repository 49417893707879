<div class="new-button-container hidden-print">
  @if (state?.showButton && state.options.length === 0) {
    <button mat-raised-button class="new-item-button" [attr.data-button-new]="state?.link"
      [routerLink]="routerLink">
      <mat-icon color="primary" style="margin-top: 4px">add_box</mat-icon>
      @if ((isHandset$ | async) === false) {
        <span class="button-text"
        i18n>{{state.actionName}}</span>
      }
    </button>
  }
  <!-- Multiple new options! -->
  @if (state?.showButton && state.options.length > 0) {
    <button mat-raised-button class="new-item-button"
      [mat-menu-trigger-for]="newOptions">
      <mat-icon color="primary">add_box</mat-icon>@if ((isHandset$ | async) === false) {
      <span class="button-text"
      i18n>{{state.actionName}}</span>
    }
  </button>
}
<mat-menu #newOptions="matMenu">
  @for (navOption of state?.options; track $index) {
    <a [routerLink]="navOption.basePath" [queryParams]="navOption.params">
      <button mat-menu-item [title]="navOption.name" [attr.data-button-new]="navOption.basePath">
        <mat-icon>{{navOption.icon}}</mat-icon>{{navOption.name}}
      </button>
    </a>
  }
</mat-menu>
</div>
