<div style="display:flex; min-width:186px; align-items: center;">
  @if (currentUser) {
    <div style="flex: 1; padding-top: 12px; text-align: center">
      @if (currentUser) {
        <app-avatar [id]="currentUser.id" [mat-menu-trigger-for]="userMenu"></app-avatar>
      }
    </div>
  }
  @if (currentUser?.currentTeam) {
    <div style="flex: 3; align-items: center; text-align:center">
      @if (currentUser && currentUser?.activePositions.length > 0) {
        <button mat-raised-button class="button-user" color=""
          [mat-menu-trigger-for]="userMenu">
          <span data-current-team-name>{{currentTeamName}}</span>
        </button>
      }
    </div>
  }
</div>
<mat-menu #userMenu="matMenu">
  @for (position of activePositions; track position) {
    <div>
      @if (position.teamId === currentUser.current_team_id && position.roleId === currentUser.currentRoleId) {
        <div style="display:flex">
            <div style="flex: 1">
                <button mat-menu-item class="selectedTeamMenuItem" disabled="true">
                    <span>{{position.team?.name}} ({{position.role?.name}})</span>
                  </button>                
            </div>
            <div style="flex: 0 0 32px">
                <mat-icon style="color:green">check</mat-icon>
            </div>            
        </div>
      } @else {
        <button mat-menu-item (click)="selectCurrentTeam(position)">
            {{position.team?.name}} ({{position.role?.name}})
        </button>
      }
    </div>
  }
  <mat-divider></mat-divider>
  <a mat-menu-item [routerLink]="[profilePath]">My Profile</a>
  @if(currentUserSvc.isUber()) {
    <a mat-menu-item (click)="impersonate()">Impersonate</a>
  }
  <mat-divider></mat-divider>
  @if (omcs.length > 0) {
    <button mat-menu-item [matMenuTriggerFor]="omcMenu">Switch OMC</button>
  }
  @if (omcs.length > 0) {
    <button mat-menu-item [matMenuTriggerFor]="mgrMenu">Switch By Manager</button>
  }
  <mat-divider></mat-divider>
  <button mat-menu-item (click)="logoutCurrentUser()">Logout</button>
  <mat-divider></mat-divider>
  <a mat-menu-item [href]="getIssueUrl()" target="_blank">Log Issue</a>
  <a mat-menu-item [href]="getIssueLogUrl()" target="_blank">View Current Issues</a>
</mat-menu>
<mat-menu #omcMenu="matMenu" class="omcSubmenu">
  @for (omc of this.omcs; track omc) {
    <button [disabled]="omc.omcTeamId === null"
      mat-menu-item (click)="selectCurrentOmc(omc)">
      {{omc.name}}
    </button>
  }
</mat-menu>
<mat-menu #mgrMenu="matMenu">
  @for (mgr of mgrList; track mgr) {
    <button mat-menu-item [matMenuTriggerFor]="mgrSubMenu">{{mgr.name}}</button>
    <mat-menu #mgrSubMenu="matMenu">
      @for (omc of mgr.omcs; track omc) {
        <button  [disabled]="omc.omcTeamId === null"
          mat-menu-item (click)="selectCurrentOmc(omc)">{{omc.name}}
        </button>
      }
    </mat-menu>
  }
</mat-menu>
