<div class="filters" style=" display:flex; flex-wrap: wrap; margin-top: 8px; border-radius: 1em; text-align: end; width:100%; align-items: center;">
  <ng-container *ngTemplateOutlet="picker"></ng-container>
  <div style="flex:20"></div>
  @for (field of config.fieldSet.fields; track field) {
    @if (field.filter.active) {
      <div class="filters" style="flex:1">
        <p class="filterLabel">{{field.label}}
          @if (field.type === 'date' || field.type === 'dateTime') {
            <span> yyyy-mm-dd</span>
          }
        </p>
        <!-- Picklist single select -->
        @if (field.type === 'picklist') {
          <ng-container
            *ngTemplateOutlet="isMultiPL(field) ? pickListMultiSelect : pickListNormal; context: {field}">
          </ng-container>
        }
        <!-- Picklist multi select-->
        @if (field.type === 'text' || field.type === 'email') {
          <input value="{{field.filter.value}}" (change)="itemChanges($event, field)"
            style="font-size: 16px; padding: 4px; margin-right: 1em; border-width: 0px; width: 10em"
            placeholder="{{field.label}}" matTooltip="{{filterToolTip(field)}}" id="{{field.name}}-search-input"/>
        }
        <!-- type="date" missing on dates, needs comparators -->
        @if (field.type === 'date') {
          <input value="{{field.filter.value}}" (change)="itemChanges($event, field)"
            style="font-size: 16px; padding-left: 4px; margin-right: 1em; border-width: 0px; width: 10em; margin-top: -5px"
            placeholder="{{field.label}}" matTooltip="{{filterToolTip(field)}}"  id="{{field.name}}-search-input"/>
        }
        @if (field.type === 'dateTime') {
          <input (change)="itemChanges($event, field)"
            value="{{field.filter.value}}"
            style="font-size: 16px; padding-left: 4px; margin-right: 1em; border-width: 0px; width: 10em"
            placeholder="{{field.label}}" matTooltip="{{filterToolTip(field)}}"  id="{{field.name}}-search-input"/>
        }
        @else if (field.type === 'number') {
          <input (change)="itemChanges($event, field)"
            value="{{field.filter.value}}"
            style="font-size: 16px; padding-left: 4px; margin-right: 1em; border-width: 0px; width: 10em"
            placeholder="{{field.label}}" matTooltip="{{filterToolTip(field)}}"  id="{{field.name}}-search-input"/>
        }
      </div>
    }
  }

<!-- /div -->
<div style="float:right">
  <table>
    <tr>
    </tr>
  </table>
</div>
</div>
<ng-template #picker>
    <div style="flex:1">
        <mat-menu #queryMenu="matMenu">
            @for (field of config.fieldSet.fields; track field) {
            @if (filterable(field)) {
            <button mat-menu-item (click)="toggleFilter(field)" id="{{field.name}}-search-enable">
                {{field.label}}@if (field.filter.active) {
                <span style="font-size: 20px; color: green"> ✓</span>
                }
            </button>
            }
            }
        </mat-menu>
        <button mat-icon-button color="primary" [mat-menu-trigger-for]="queryMenu" data-search-fields
        matTooltip="Select Fields to use to search data">
            <mat-icon>search</mat-icon>
        </button>
    </div>
</ng-template>
<ng-template #pickListNormal let-field="field">
  <select (change)="itemChanges($event, field)"
    style="font-size: 16px; padding-left: 4px; margin-right: 1em; border-width: 0px"
    matTooltip="{{filterToolTip(field)}}">
    <option value="any" [selected]="field.filter.value ? null : 'checked'">All</option>
    @if (!field.picklist.blockFilterNone) {
      <option value="" [selected]="field.filter.value ? null : 'checked'">None
      </option>
    }
    <option disabled>------</option>
    @for (item of field.picklist.items; track item) {
      <option [value]="field.picklist.optionValue(item)"
        [selected]="field.filter.value === field.picklist.optionValue(item) ? 'checked' : null">
        {{field.picklist.optionDisplayValue(item)}}
      </option>
    }
  </select>
</ng-template>
<ng-template #pickListMultiSelect let-field="field">
  <input value="{{field.filter.value}}" (change)="itemChanges($event, field)"
    style="font-size: 16px; padding-left: 4px; margin-right: 1em; border-width: 0px; width: 10em"
    placeholder="{{field.label}}" matTooltip="{{filterToolTip(field)}}" />
</ng-template>
