/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { AbstractObject, uuid } from './abstract-object';
import { Attachment } from './attachment';

export type MergeType = 'Billing'|'Unit'|'Person'|'Invoice';
export type MergeStatus = 'New'|'Submitted'|'Working'|'Complete';
export type MergeDestination = 'PDF'|'Email'|'NoEmailPDF';

export class MailMerge extends AbstractObject {
    content: string;
    modelId: uuid;
    params: {[k:string]:string|number|boolean};
    type: MergeType;
    status?: MergeStatus;
    destination: MergeDestination;

    todo?: number;
    done?: number;

    createdById?: uuid;
    updatedById?: uuid;

    attachments?: Attachment[] = [];

    unitEmails? = 0;
    unitPDFs? = 0;
    emails? = 0;

}
