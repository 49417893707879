/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractObject } from 'src/app/model/abstract-object';
import { ServiceRequest } from 'src/app/model/serviceRequest';
import { Field } from 'src/app/shared/field/Field';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { GridControl } from 'src/app/shared/grid/grid-control';
import { GridField } from 'src/app/shared/grid/grid-field';
import { IsNarrowService } from 'src/app/shared/is-narrow.service';
import { CtlHolderComponent } from '../../../shared/form/ctl-holder/ctl-holder.component';

@Component({
    selector: 'app-my-requests',
    templateUrl: './my-requests.component.html',
    styleUrls: ['./my-requests.component.scss'],
    standalone: true,
    imports: [CtlHolderComponent]
})
export class MyRequestsComponent implements OnChanges {

    @Input() showRequests: 'open'|'closed' = 'open';
    @Input() requests: ServiceRequest[] = [];
    @Output() selectRequest = new EventEmitter<ServiceRequest>();

    myRequests: ServiceRequest[] = [];

    formHeight = this.getFormHeight();

    requestControl: GridControl;
    requestGrid: GridField = new GridField(
        {
            field: { value: 'requests', readonly: true, cellOpts: { heading: 'Requests' },
            sendServer: false, visible: Field.noShow, },
            rowFactory: (request) => [
                FieldMaker.id(),
                ServiceRequest.getRefField(),
                FormButtonComponent.makeTextButton('Details', 'requestDetails',
                    () => { this.selectRequest.emit(request as ServiceRequest);}, {calculateValue: () => 'Details'}),
                FormTextComponent.make('Title', 'title', {readonly: true}),
                ServiceRequest.getDescriptionField().override({readonly: true}),
                ServiceRequest.getCreatedField(),
                ServiceRequest.getLastUpdatedField(),
            ],
        }
    );

    constructor(isNarrowSvc: IsNarrowService) {
        //this.requestGrid.makeControl();

        isNarrowSvc.resizeObservable$.subscribe(() => {
            this.formHeight = this.getFormHeight();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.requestControl = this.requestGrid.makeControl() as GridControl;
        this.setGridValue(changes.requests.currentValue);
    }

    setGridValue(requests) {
        this.requestGrid.setValue({ requests } as unknown as AbstractObject, true);
    }

    getFormHeight() {
        return window.innerHeight - 200;
    }

}
