<mat-dialog-content style="padding: 10px; width: calc(min(80vw - 40px, 530px))">
    @if(dialogOptions.field.hint) {
        <p style="font-size: small; line-height: 1.25em; margin: 10px;">{{dialogOptions.field.hint}}</p>
    }
    <app-ctl-holder [control]="dialogOptions.field.control"></app-ctl-holder>

    <div style="display:flex; margin-top: 10px">
        <button mat-raised-button color="primary" (click)="save()">Save</button>
        <div style="flex: 1"></div>
        <button mat-raised-button color="accent" [mat-dialog-close]="null">Cancel</button>
    </div>

</mat-dialog-content>
