/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*
* Parent Class for Chart Objects to be displayed above tables
*/
import * as Highcharts from 'highcharts';

import { AbstractObject } from "src/app/model/abstract-object";
import { NumberField } from "../../field/NumberField";
import { PicklistField } from "../../field/PicklistField";
import { TrendField } from '../../field/TrendField';
import { AppFilterNavigator } from '../chart-analysis';
// pct is custom...
type SeriesData = { name: string, y?: number, pct?: number, customdata?: number | string, type?: string, data?: number[] };

type AppChartOptions = {
    data: AbstractObject[],
    divId: string,
    picklist?: PicklistField,
    numberField?: NumberField,
    trendField?: TrendField,
    refresher: AppFilterNavigator,
    excludeZero?: boolean
}

export abstract class AbstractChart {

    protected data: AbstractObject[] = [];
    protected series: SeriesData[];

    height: number;
    width: number;

    protected picklist: PicklistField;
    protected numberField: NumberField;
    protected trendField: TrendField;

    chartBackgroundColor = 'white'; //'rgba(240, 239, 238, 1)';
    chartBorderRadius = 20;

    refresher: AppFilterNavigator

    private excludeZero = false;

    protected abstract getTooltip();

    protected abstract createChartOptions();

    getChartTitle() {
        return { text: this.picklist.label, style: { fontSize: '14px', fontWeight: 'bold' } };
    }

    getSeriesName() {
        return this.numberField ? this.numberField.label : 'Count';
    }

    getSubTitle() {
        //return { text: this.getSeriesName(), style: {fontSize: '12px'} }
        return { text: this.getSeriesName() };
    }

    filterData($event) {
        const value = $event.point.customdata;
        this.picklist.filter.value = value ? value : '';

        this.picklist.filter.active = true;
        this.refresher();
    }

    setupData(options: AppChartOptions) {

        this.picklist = options.picklist;
        this.data = options.data;
        this.numberField = options.numberField;
        this.trendField = options.trendField;
        this.refresher = options.refresher;
        this.excludeZero = options.excludeZero;

        this.analyseData();

        Highcharts.chart(options.divId, (this.createChartOptions() as Highcharts.Options));
    }

    protected analyseData() {
        const mapper: Map<number, { name: string, total: number }> = new Map();
        let grandTotal = this.numberField ? 0 : this.data.length;
        const type = this.picklist.cellOpts.heading;

        this.series = [];

        this.data.map(o => {
            const idValue = this.picklist.getValue(o);
            let displayValue = 'Not Entered';

            if (!mapper.has(idValue)) {
                if (idValue) {
                    displayValue = this.picklist.getDisplayValueFromId(idValue);
                }
                mapper.set(idValue, { name: displayValue, total: 0 })
            }
            const oMap = mapper.get(idValue);
            if (this.numberField) {
                const t = this.numberField.getValue(o);
                oMap.total = oMap.total + t;
                grandTotal += t;
            } else {
                oMap.total = oMap.total + (this.numberField ? this.numberField.getValue(o) : 1);
            }
        });
        for (const k of mapper.keys()) {
            const oMap = mapper.get(k);
            // Pct this.series.push({name: oMap.name, y: (oMap.total / total * 100), id: k, type})
            if (oMap.total !== 0 || !this.excludeZero) {
                this.series.push({ name: oMap.name, y: oMap.total, pct: (oMap.total / grandTotal * 100), customdata: k, type });
            }
        }
    }

}

