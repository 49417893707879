/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Area } from 'src/app/model/area';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { Core } from 'src/app/model/core';

@Injectable({
  providedIn: 'root'
})
export class AreaService extends AbstractHttpService {

  protected baseUrl = this.ajaxPath + 'units/areas';
  protected cache: Area[];
  protected typeString = 'Areas';

  constructor(protected http: HttpClient, protected messageService: MessageService) {
    super();
  }
  
  getCores(areas : Area[] = this.cache) : Core[] {
    const cores: Core[] = [];
    areas.forEach( a => {
        a.cores.forEach( c => {
            cores.push(c);
        })
    })
    return cores;
  }
}
