/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Input, ViewContainerRef } from '@angular/core';
import { FormControlInterface } from 'src/app/shared/form/form-control-interface';
import { ControlOn, AppFormControl } from 'src/app/shared/form/app-form-control';
import { Field } from 'src/app/shared/field/Field';
import { LimitToPipe } from 'src/app/shared/pipes/limit-to.pipe';
import { NgClass } from '@angular/common';
import { FormErrorComponent } from '../form-error/form-error.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-form-text-area',
  templateUrl: './form-text-area.component.html',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatIconModule,
    MatTooltipModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    FormErrorComponent,
    NgClass,
    LimitToPipe,
  ],
})
export class FormTextAreaComponent implements FormControlInterface {

  @Input() maxRows = 4;
  @Input() minRows = 4;
  @Input() control: AppFormControl;
  @Input() on: ControlOn = 'form';

  public static make(label: string, value: string, options: Partial<Field> = {}) {
    const field: Field = new Field({ label, value, type: 'limitTo' }, options);
    field.formControlFactory = FormTextAreaComponent.createComponent;
    return field;
  }

  public static createComponent(vcr: ViewContainerRef, ctl: AppFormControl, on: ControlOn) {
    const componentRef = vcr.createComponent(FormTextAreaComponent);
    componentRef.instance.control = ctl;
    componentRef.instance.on = on;
  }
}
