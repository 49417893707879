/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { ChartParms } from 'src/app/model/sys/nav-item';
import { Field } from 'src/app/shared/field/Field';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet, LAYOUT_OPTIONS } from 'src/app/shared/form/field-set/field-set.component';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormConfig } from 'src/app/shared/form/FormConfig';
import { NavRoute } from 'src/app/shared/NavRoute';
import { OmcService } from '../../agent/omc.service';
import { RequestAnalysis, RequestAnalysisService } from './request-analysis.service';
import { ServiceRequestPageComponent } from '../service-request-page/service-request-page.component';
import { ServiceRequest } from 'src/app/model/serviceRequest';
import { AbstractObject } from 'src/app/model/abstract-object';
import { FormDurationComponent } from 'src/app/shared/form/form-duration/form-duration.component';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';

export const WEEKLY_TYPE = { id: 'week', name: 'Weekly', openField: 'openWeek', closeField: 'closeWeek'};
export const MONTHLY_TYPE = {id: 'month', name: 'Monthly', openField: 'openMonth', closeField: 'closeMonth'};
export const PERIOD_TYPES = [WEEKLY_TYPE, MONTHLY_TYPE];

@Component({
    selector: 'app-request-analysis',
    templateUrl: './request-analysis.component.html',
    styleUrls: ['./request-analysis.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class RequestAnalysisComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('crm/requestAnalysis', RequestAnalysisComponent, 'summarize').setCharts([
        //{ type: 'pie', picklist: 'omc_managerId' } as ChartParms,
        { type: 'bar', picklist: 'period', trend: 'period', number: 'opened' } as ChartParms,
        { type: 'bar', picklist: 'period', trend: 'period', number: 'closed' } as ChartParms,
        { type: 'trend', picklist: 'periodType', trend: 'period', number: 'daysToClose' } as ChartParms,
    ]).setViews( () => [
        {
            id: 'monthly',
            name: $localize`Monthly Analysis`,
            filterFields: { periodType: MONTHLY_TYPE.id, omcId: 'All', period: 'All' },
        },
        {
            id: 'weekly',
            name: $localize`Weekly Analysis`,
            filterFields: { periodType: WEEKLY_TYPE.id, omcId: 'All', period: 'All' },
        }
    ]);

    detailsLinkField = FormButtonComponent.makeNavButton('Details',
        RequestAnalysisComponent.navRoute, this.getPeriodFilter.bind(this),
        { calculateValue: () => 'Details'}
    );

    requestsOpenedLink = FormButtonComponent.makeNavButton('Requests Opened',
        ServiceRequestPageComponent.navRoute, this.getOpenedFilter.bind(this),
        { value: 'opened', cellOpts: {style: 'text-align: right; padding-right: 0px', thColspan: 2}}
    );

    requestsClosedLink = FormButtonComponent.makeNavButton('Requests Closed',
        ServiceRequestPageComponent.navRoute, this.getClosedFilter.bind(this),
        { value: 'closed', cellOpts: {style: 'text-align: right; padperiodding-right: 0px', thColspan: 2 } }
    );

    requestsStillOpenLink = FormButtonComponent.makeNavButton('Still Open',
        ServiceRequestPageComponent.navRoute, this.getStillOpenFilter.bind(this),
        { value: 'stillOpen', cellOpts: {style: 'text-align: center' } }
    );

    workTimeField = FormDurationComponent.make('Time Logged', 'workTime', {
        readonly: true, cellOpts: { style: 'text-align: right', thColspan: 2 }
    });

    openedIndicator = FormButtonComponent.make('', '', {
        name: 'openedIndicator', type: 'icon', label: '',
        calculateValue: (o: RequestAnalysis) => {
            o = (o as RequestAnalysis)
            if (!o.previous) {
                return '';
            } else if (o.opened > o.previous.opened) {
                return 'north'
            } else if (o.opened < o.previous.opened) {
                return 'south';
            }
        }
    }).setTableIconStyle((o) => {
        const x = (o as RequestAnalysis);
        if (!x.previous) {
            return '';
        } else if (x.opened > x.previous.opened) {
            return 'color: green; font-size: 20px; padding-top: 17px';
        } else if (x.opened < x.previous.opened) {
            return 'color: red; font-size: 20px; padding-top: 17px';
        }
    }).override({cellOpts: {thStyle: 'display:none', style: 'padding-left: 0px'}});

    closedIndicator = FormButtonComponent.make('', '', {
        name: 'closedIndicator', type: 'icon', label: '',
        calculateValue: (o: RequestAnalysis) => {
            o = (o as RequestAnalysis)
            if (!o.previous) {
                return '';
            } else if (o.closed > o.previous.closed) {
                return 'north'
            } else if (o.closed < o.previous.closed) {
                return 'south';
            }
        }
    }).setTableIconStyle((o) => {
        const x = (o as RequestAnalysis);
        if (!x.previous) {
            return '';
        } else if (x.closed > x.previous.closed) {
            return 'color: green; font-size: 20px; padding-top: 17px';
        } else if (x.closed < x.previous.closed) {
            return 'color: red; font-size: 20px; padding-top: 17px';
        }
    }).override({cellOpts: {thStyle: 'display:none', style: 'padding-left: 0px'}});

    daysToCloseField = FormNumberComponent.make('Average Days To Close', 'daysToClose',
        {formatParms: '1.0-2'},{cellOpts: {thColspan: 2, style: 'text-align: right; padding-right: 0px'}}
    );

    daysToCloseIndicator = FormButtonComponent.make('', '', {
        name: 'daysToCloseIndicator', type: 'icon', label: '',
        calculateValue: (o: RequestAnalysis) => {
            o = (o as RequestAnalysis)
            if (!o.previous) {
                return '';
            } else if (o.daysToClose < o.previous.daysToClose) {
                return 'north'
            } else if (o.daysToClose > o.previous.daysToClose) {
                return 'south';
            }
        }
    }).setTableIconStyle((o) => {
        const x = (o as RequestAnalysis);
        if (!x.previous) {
            return '';
        } else if (x.daysToClose < x.previous.daysToClose) {
            return 'color: green; font-size: 20px; padding-top: 17px';
        } else if (x.daysToClose > x.previous.daysToClose) {
            return 'color: red; font-size: 20px; padding-top: 17px';
        }
    }).override({cellOpts: {thStyle: 'display:none', style: 'padding-left: 0px'}});

    workedIndicator = FormButtonComponent.make('', '', {
        name: 'workedIndicator', type: 'icon', label: '',
        calculateValue: (o: RequestAnalysis) => {
            o = (o as RequestAnalysis)
            if (!o.previous) {
                return '';
            } else if (o.workTime > o.previous.workTime) {
                return 'north'
            } else if (o.workTime < o.previous.workTime) {
                return 'south';
            }
        }
    }).setTableIconStyle((o) => {
        const x = (o as RequestAnalysis);
        if (!x.previous) {
            return '';
        } else if (x.workTime > x.previous.workTime) {
            return 'color: green; font-size: 20px; padding-top: 17px';
        } else if (x.workTime < x.previous.workTime) {
            return 'color: red; font-size: 20px; padding-top: 17px';
        }
    }).override({ cellOpts: { thStyle: 'display:none', style: 'padding-left: 0px' } });

    omcField = FormPicklistComponent.make('OMC', 'omcId', null, { service:this.omcService });
    periodField = FormPicklistComponent.make('Period', 'period', null, {items: []})

    config = new FormConfig({
        navRoute: RequestAnalysisComponent.navRoute,
        title: $localize`Analysis`,
        help: $localize`Analysis of requests`,
        fieldSet: new FieldSet({
            fields: [
                FormPicklistComponent.make('Period Type', 'periodType', null, {
                    items: PERIOD_TYPES
                }, { visible: Field.noShow }),
                this.omcField,
                this.detailsLinkField,
                this.periodField,
                FormTextComponent.make('Period', 'periodDesc', {cellOpts: {style: 'min-width: 20em'}}),
                //FormTextAreaComponent.make('Period', 'period'),
                //FormNumberComponent.make('Requests Opened', 'opened'),
                this.requestsOpenedLink,
                this.openedIndicator,
                //FormNumberComponent.make('Requests Closed', 'closed'),
                this.requestsClosedLink,
                this.closedIndicator,
                this.daysToCloseField,
                this.daysToCloseIndicator,
                this.requestsStillOpenLink,
                this.workTimeField,
                this.workedIndicator,
            ],
            formLayout: LAYOUT_OPTIONS.singleCol,
        }),
        service: this.dataSvc,
        mode: 'list',
        beforeList: this.beforeList.bind(this),
    });

    constructor(public dataSvc: RequestAnalysisService, private omcService: OmcService) {
        super();
    }

    private getStillOpenFilter(o : RequestAnalysis) {
        const stillOpen = this.getOpenedFilter(o);
        stillOpen['srStatus'] = ServiceRequest.srOpen.id

        return stillOpen
    }

    private getOpenedFilter(o : RequestAnalysis) {
        const opened = {};
        if (o.omcId) {
            opened['omcId'] = o.omcId;
        }
        const type = this.getPeriodsType(o.period);
        opened[type.openField] = o.period;
        return opened;
    }

    private getClosedFilter(o : RequestAnalysis) {
        const closed = {};
        if (o.omcId) {
            closed['omcId'] = o.omcId;
        }
        const type = this.getPeriodsType(o.period);
        closed[type.closeField] = o.period;
        return closed
    }

    private getPeriodFilter(o : RequestAnalysis) {

        const type = this.getPeriodsType(o.period);
        return {periodType: type.id, period: o.period}

    }

    getPeriodDesc(o: RequestAnalysis) : string {
        const year = +o.period.substring(0, 4);
        const type = o.period.substring(4, 5);
        const prd = +o.period.substring(5, 7);

        if (type === '-') {
            return new Date(year, prd -1).toLocaleString('default', { month: 'long' }) + ' ' + year;
        } else {
            return this.getWeekStart(year, prd);
        }
    }
    getWeekStart(year: number, week: number) : string {
        const jan4th = new Date(year, 0, 4); // Jan 4th is always in first week


        const week1Monday = new Date(jan4th); // Is the previous monday
        week1Monday.setDate(week1Monday.getDate() - jan4th.getDay() + 1);

        const weekStart = new Date(week1Monday);
        weekStart.setDate(weekStart.getDate() + ((week - 1) * 7));
        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekEnd.getDate() + 6);

        if (weekStart.getMonth() === weekEnd.getMonth()) {
            return weekStart.getDate() + '-' + weekEnd.getDate() + ' ' + weekStart.toLocaleString('default', { month: 'short' }) + ' ' + year;
        }

        return weekStart.getDate() + ' ' + weekStart.toLocaleString('default', { month: 'short' })
                + ' - ' + weekEnd.getDate() + ' ' + weekEnd.toLocaleString('default', { month: 'short' });


    }


//console.log(getDateRangeOfWeek(52, 2015)); //12-21-2015 to 12-27-2015

    /* https://en.wikipedia.org/wiki/ISO_week_date
        The weeks start on Monday
        The first week of the year is the week containing the first Thursday of the year.

        An ISO week-numbering year (also called ISO year informally) has 52 or 53 full weeks.
        That is 364 or 371 days instead of the usual 365 or 366 days.

        These 53 week years occur on all years that have Thursday as the 1st of January
        AND on leap years that start on Wednesday the 1st.

        The extra week is sometimes referred to as a leap week, although ISO 8601 does not use this term.

        to test: 2009, 2015, 2020, 2026 are 53 week years.
    */

    weeksInYear(year: number) {
        const jan1st = new Date(year, 0, 1);
        const jan1stDayOfWeek = jan1st.getDay();
        const thursday = 4;
        const wednesday = 3;

        if (jan1stDayOfWeek === thursday ) {
            return 53;
        } else if ((year % 4) === 0 && jan1stDayOfWeek === wednesday) {
            return 53
        } else {
            return 52;
        }
    }

    readonly zeroPad = (num, places) => String(num).padStart(places, '0')

    private getPriorPeriodName(period: string) {
        const year = +period.substring(0,4);
        const prd = +period.substring(5,7);
        const type = period.substring(4, 5);

        if (prd > 1) {
            return String(year) + type + this.zeroPad(prd - 1, 2);
        } else {
            if (this.getPeriodsType(period) === MONTHLY_TYPE) {
                return String(year - 1) + '-12'
            } else {
                return String(year - 1) + type + this.weeksInYear(year-1);
            }

        }
    }

    getPeriodsType(s: string) {
        if (s.indexOf('w') > 0) {
            return WEEKLY_TYPE;
        } else {
            return MONTHLY_TYPE;
        }
    }

    showOmcField(parms: HttpParams) {
        if (!parms.has('omcId') && parms.has('period')) {
            this.omcField.visible = Field.showAll;
        } else {
            this.omcField.visible = Field.noShow;
        }
    }

    parseAsMap(input) : Map<string, RequestAnalysis>{
        const map: Map<string, RequestAnalysis> = new Map();

        const openData: RequestAnalysis[] = input['openData'];
        const closeData: RequestAnalysis[] = input['closeData'];
        const workData: RequestAnalysis[] = input['workData'];

        openData.forEach( (open) => {
            map.set(open.period + open.omcId, { ...new RequestAnalysis(), ...open});
        });

        closeData.forEach((closed) => {
            let item = { ...new RequestAnalysis(), ...closed};
            if (!map.has(closed.period+closed.omcId)) {
                map.set(closed.period+closed.omcId, item);
            } else {
                item = map.get(closed.period+closed.omcId);
                item.closed = closed.closed;
                item.daysToClose = closed.daysToClose;
            }
        });

        workData.forEach((worked) => {
            worked.workTime = +worked.workTime;
            let item = { ...new RequestAnalysis(), ...worked };
            if (!map.has(worked.period + worked.omcId)) {
                map.set(worked.period + worked.omcId, item);
            } else {
                item = map.get(worked.period + worked.omcId);
                item.workTime = worked.workTime;
            }
        });



        map.forEach((value) => {
            value.daysToClose = +value.daysToClose;
            value.stillOpen = +value.stillOpen;
            value.periodDesc = this.getPeriodDesc(value);
        });

        return map;
    }

    mapPreviousPeriods(mapItems: Map<string, RequestAnalysis>, mapPrev: Map<string, RequestAnalysis>) {
        mapItems.forEach((value) => {
            const ppName = this.getPriorPeriodName(value.period);
            if (mapPrev.has(ppName + value.omcId)) {
                value.previous = mapPrev.get(ppName+value.omcId);
            }
        });
    }

    beforeList(input: RequestAnalysis[], parms: HttpParams) {
        const map = this.parseAsMap(input);

        this.showOmcField(parms);

        if (parms.has('period')) {
            this.detailsLinkField.visible = Field.noShow;
            const pp = this.getPriorPeriodName(parms.get('period'));
            const newParms = parms.set('period', pp);
            this.dataSvc.get(false, newParms).subscribe ( (result) => {
                const prevMap = this.parseAsMap(result);
                this.mapPreviousPeriods(map, prevMap);
            })
        } else {
            this.detailsLinkField.visible = Field.showAll;
            this.mapPreviousPeriods(map, map);
        }
        const ary = Array.from(map.values());

        this.resetPeriodPicklist(ary);

        return ary.sort((a, b) => a.period.localeCompare(b.period)).reverse();
    }

    resetPeriodPicklist(ary: RequestAnalysis[]) {
        const newList: AbstractObject[] = [];
        ary.forEach( o => {
            if (!newList.find( a => a.id === o.period)) {
                newList.push({ id: o.period, name: o.period})
            }
        });
        this.mergeList(newList);

        if (newList.length === 1) {
            const pp = this.getPriorPeriodName(newList[0].id as string);
            //newList.push({id: pp, name: this.getPeriodDesc({period: pp} as RequestAnalysis)});
            newList.push({id: pp, name: pp});
        }

        const sortedItems = newList.sort( (a,b) => a.name.localeCompare(b.name)).reverse();
        this.periodField.setPicklistItems(sortedItems);

    }

    mergeList(newList: AbstractObject[]) {
        if (newList.length === 0) {
            return;
        }
        const periodType = this.getPeriodsType(newList[0].id as string);
        const items = this.periodField.picklist.items;
        if (items && items[0]) { // If there are existing items of same period type...
            if (this.getPeriodsType(items[0].id as string) === periodType) {
                items.forEach( o => {
                    if (!newList.find( ni => ni.id === o.id)) {
                        newList.push(o);
                    }
                });
            }
        }
    }
}
